<template>
  <div class="empty-result d-flex justify-center align-center">
    <div class="d-flex flex-column justify-center align-center">
      <img width="100%" src="~assets/img/empty.png" />
      <p>{{ $t('emptyResult') }}</p>
    </div>
  </div>
</template>
<script>

import i18n from './i18n.js'

export default {
  name: 'MagicBoxAlgoliaNoResult',
  i18n,
  data () {
    return {}
  }
}
</script>
<style lang="scss" scoped>
.empty-result {
  margin: auto;
  width: 50%;
  height: 100%;
}
</style>
