const i18n = {
  messages: {
    fr: {

      required: 'Texte requis'
    },
    en: {
      required: 'Require text'
    }
  }
}
module.exports = i18n
