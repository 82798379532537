<template>
  <v-shape
    :config="{
      sceneFunc: canvasConfig.fn,
      ...shapeConfig,
      shapeId
    }"
    @dragend="handleDragEnd"
    @transformend="handleTransformEnd"
  />
</template>

<script>

export default {
  props: {
    config: {
      type: Object,
      default: () => {}
    }
  },
  data () {
    return {
      shapeId: this.config.id,
      customType: this.config.customType
    }
  },
  computed: {
    shapeConfig () {
      return this.config
    },
    canvasConfig () {
      return this.$customShapes.find(e => e.name === this.customType)
    }
  },
  methods: {
    handleTransformEnd (e) {
      this.transformShape(e.target.attrs)
    },
    transformShape (config) {
      this.$emit('transformShape', config)
    },
    handleDragEnd (e) {
      const x = e.target.attrs.x
      const y = e.target.attrs.y
      this.editShapePosition({ x, y })
    },
    editShapePosition (config) {
      this.$emit('editShapePosition', config)
    }
  }
}
</script>
