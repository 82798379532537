import Rainbow from 'rainbowvis.js'
import _ from 'lodash'

function getColorMap (ranges) {
  let colors = []
  _.forEach(ranges, (range) => {
    const rainbow = new Rainbow()
    rainbow.setNumberRange(range.min, range.max)
    rainbow.setSpectrum(...range.spectrum)
    const rangeColors = []
    for (let i = range.min; i < range.max; i++) {
      rangeColors.push('#' + rainbow.colourAt(i))
    }
    colors = [...colors, ...rangeColors]
  })
  return colors
}

export default {
  getColorMap
}
