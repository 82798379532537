<template>
  <div>
    <div class="responses-title">
      {{ $t('responseOptionsTitle') }}
    </div>
    <div v-if="!options.length" class="text-left py-3">
      <span
        class="primary--text font-weight-medium cursor"
        @click="addOption(actionId)"
      >
        {{ $t('addResponseOption') }}
      </span>
    </div>
    <v-simple-table id="response-data-table" class="mt-2">
      <template #default>
        <tbody>
          <tr v-for="option in options" :key="option._id">
            <td style="width:65%;" class="response-background mb-5 py-4 pl-6 no-border">
              <EditableField
                :value="option.label"
                type="textarea"
                :edit-right="true"
                :placeholder="$t('header.response')"
                rows="4"
                large
                @input="editOptionLabel({data: $event, actionId, optionId: option._id})"
              />
            </td>
            <td style="width:5%;" class="response-background text-right pr-0 mb-5 py-4 no-border">
              <span style="min-width: max-content">
                <v-icon :color="option.goToScene ? 'primary' : 'transparent'" small>mdi-call-split mdi-rotate-90</v-icon>
              </span>
            </td>
            <td style="width:25%;" class="response-background mb-5 py-4 no-border">
              <EditableField
                :value="option.goToScene"
                type="list"
                position="bottom"
                autocomplete
                sort-by-order
                :edit-right="true"
                :options="scenesOptions"
                clearable
                :max-width="maxWidth"
                :clear-value="undefined"
                :placeholder="$t('addRedirection')"
                large
                @input="editOptionRedirection({data: $event, actionId, optionId: option._id})"
              />
            </td>
            <td style="width:5%;" class="response-background mb-5 py-4 no-border">
              <edtk-button-icon
                color="secondary"
                icon="delete"
                icon-size="xl"
                @click="deleteOption({actionId, optionId: option._id})"
              >
              </edtk-button-icon>
            </td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
    <div v-if="options.length" class="text-left py-4">
      <span
        class="primary--text font-weight-medium cursor"
        @click="addOption(actionId)"
      >
        {{ $t('addResponseOption') }}
      </span>
    </div>
  </div>
</template>

<script>
import _ from 'lodash'

export default {
  props: {
    options: {
      type: Array,
      defaukt: () => []
    },
    scenes: {
      type: Array,
      default: () => []
    },
    actionId: {
      type: String,
      default: () => undefined
    },
    currentSceneId: {
      type: String
    }
  },
  data () {
    return {
    }
  },
  i18n: {
    messages: {
      fr: {
        header: {
          response: 'Renseigner une réponse'
        },
        addRedirection: 'Ajouter une redirection',
        addResponseOption: '+ Ajouter une réponse',
        responseOptionsTitle: 'Réponses'
      },
      en: {
        header: {
          response: 'Fill in a response'
        },
        addRedirection: 'Add redirection',
        addResponseOption: 'Add response',
        responseOptionsTitle: 'Responses'
      }
    }
  },
  computed: {
    scenesOptions () {
      const scenes = this.scenes.filter(scene => scene._id !== this.currentSceneId)
      const res = _.map(scenes, (scene) => { return { id: scene._id, label: `${scene.order} - ${scene.properties.title}`, order: scene.order } })
      return res
    },
    maxWidth () {
      switch (this.$vuetify.breakpoint.name) {
        case 'xs': return 80
        case 'sm': return 100
        case 'md': return 150
        case 'lg': return 200
        default: return 150
      }
    }
  },
  methods: {
    addOption (actionId) {
      this.$emit('addOption', actionId)
    },
    editOptionLabel ({ data, actionId, optionId }) {
      this.$emit('editOptionLabel', { data, actionId, optionId })
    },
    editOptionRedirection ({ data, actionId, optionId }) {
      this.$emit('editOptionRedirection', { data, actionId, optionId })
    },
    deleteOption ({ actionId, optionId }) {
      this.$emit('deleteOption', { actionId, optionId })
    }
  }
}
</script>

<style scoped>
.cursor {
  cursor: pointer;
}
.responses-title {
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  color: #00558A;
}
.no-border {
  border-top: none;
  border-bottom: none !important;
}
.response-background {
  background-color: #F2F6F9;
}
#response-data-table :deep(div table) {
  border-spacing: 0 8px;
}
</style>
