<template>
  <v-dialog
    v-model="mutableModel"
    :persistent="persistent"
    :fullscreen="fullscreen"
    scrollable
    :width="width"
    style="z-index:2050;"
    @input="$emit('close')"
  >
    <v-card class="ma-0 pa-0">
      <v-card-title>
        <slot name="dialog-title"></slot>
        <span class="text-h3 blue--text">{{ title }}</span>
        <edtkButtonIcon
          data-test="button-dialog-close"
          class="ml-auto"
          icon="close"
          icon-size="4xl"
          color="secondary"
          @click="$emit('close')"
        />
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text class="position-relative bg-white-smoke" :class="{'pa-0': noPadding}">
        <slot name="dialog-content"></slot>
      </v-card-text>
      <v-divider v-if="displayActions"></v-divider>
      <v-card-actions v-if="displayActions" class="justify-center my-2">
        <slot name="dialog-actions"></slot>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'Dialog',
  props: {
    value: {
      type: Boolean,
      required: true
    },
    title: {
      type: String,
      required: true
    },
    persistent: {
      type: Boolean,
      required: false,
      default: false
    },
    fullscreen: {
      type: Boolean,
      required: false,
      default: false
    },
    width: {
      type: String,
      required: false,
      default: '800'
    },
    noPadding: {
      type: Boolean,
      default: () => false
    }
  },
  data () {
    return {
      displayActions: undefined
    }
  },
  computed: {
    mutableModel: {
      get () {
        return this.value
      },
      set () {
        return this.value
      }
    }
  },
  watch: {
    title (n) {
      if (n) {
        this.hasActions()
      }
    }
  },
  mounted () {
    this.hasActions()
  },
  methods: {
    hasActions () {
      this.displayActions = !!this.$slots['dialog-actions']
    }
  }
}
</script>
