<template lang="html">
  <v-app>
    <v-app-bar
      clipped-left
      flat
      fixed
      app
      color="white"
      z-index="2000"
    >
      <nuxt-link :to="`/admin`">
        <img
          class="logo ml-4 mr-8"
          src="~assets/img/logo-edtake.svg"
        />
      </nuxt-link>
      <NuxtLink to="/admin/workspace">
        <span class="h4 mx-5">Workspace</span>
      </NuxtLink>
      <v-divider vertical inset></v-divider>
      <NuxtLink to="/admin/mission">
        <span class="h4 mx-5">Mission</span>
      </NuxtLink>
      <v-divider vertical inset></v-divider>
      <NuxtLink to="/admin/freelance">
        <span class="h4 mx-5">Freelance</span>
      </NuxtLink>
      <v-divider vertical inset></v-divider>
      <NuxtLink to="/admin/general">
        <span class="h4 mx-5">Général</span>
      </NuxtLink>
      <NuxtLink to="/" class="d-flex ml-auto">
        <edtk-icon :icon="$enums.materialIcons.arrowBack" icon-color="primary"></edtk-icon>
        <span class="h4 ml-2 mr-5">Retour sur edtake</span>
      </NuxtLink>
    </v-app-bar>
    <v-main data-layout="admin">
      <transition
        name="nuxtTransition"
        appear
        appear-active-class="component-enter-active"
        leave-active-class="component-leave-active"
      >
        <nuxt />
      </transition>
    </v-main>
  </v-app>
</template>

<script lang="js">
import { mapGetters } from 'vuex'

export default {
  name: 'AdminLayout',
  middleware: ['admin'],
  data () {
    return {
    }
  },
  computed: {
    ...mapGetters({
      user: 'user/get'
    })
  }
}
</script>

<style scoped lang="scss">
.logo {
  height: 28px;
  object-fit: contain;
  text-align: center;
  margin-top: 18px;
  margin-bottom: 18px;
}
</style>
