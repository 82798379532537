/* eslint-disable no-console */

export const state = () => ({
  current: undefined
})

export const getters = {
  getCurrent (state) {
    return state.current
  }
}

export const actions = {
  fetchOne ({ dispatch, commit }, { id }) {
    return new Promise((resolve, reject) => {
      this.$edtake.workspaceCustomer.getOne({
        id
      }).then((d) => {
        commit('SET_CURRENT', d)
        resolve(d)
      }).catch((err) => {
        console.error('ERROR', err)
        reject(err)
      })
    })
  },
  update ({ state, commit }, { body }) {
    return new Promise((resolve, reject) => {
      this.$edtake.workspaceCustomer.update({
        id: state.current._id,
        body
      }).then((d) => {
        commit('SET_CURRENT', d)
        resolve(d)
      }).catch((err) => {
        reject(err)
      })
    })
  },
  remove ({ state }) {
    return new Promise((resolve, reject) => {
      this.$edtake.workspaceCustomer.remove({ id: state.current._id }).then((d) => {
        resolve(d)
      }).catch((err) => {
        reject(err)
      })
    })
  }
}
export const mutations = {
  SET_CURRENT (state, value) {
    state.current = value
  }
}
