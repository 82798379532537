<template>
  <div class="bg-white-smoke pr-4 pl-1 overviewGrid" style="height:calc(100vh - 170px); overflow-y:auto;">
    <div class="pt-0 one">
      <h2 class="page-subtitle">
        {{ $t('Visuel') }}
      </h2>
      <v-hover v-slot="{ hover }" :disabled="!editRight">
        <v-img
          data-test="img-scene-image-display"
          class=" rounded elevation-1 white align-center justify-center"
          width="100%"
          :aspect-ratio="1.41"
          cover
          :src="sceneProperties.image"
        >
          <div class="d-flex justify-center">
            <edtk-button
              v-if="hover && !sceneProperties.image"
              small
              data-test="button-create-a-visual-hover"
              @click="openSceneImageEditor"
            >
              {{ $t('createImageBtn') }}
            </edtk-button>
          </div>
          <v-fade-transition>
            <v-overlay
              v-if="sceneProperties.image && hover"
              absolute
              color="grey lighten-2"
            >
              <div class="d-flex flex-column justify-center align-center">
                <edtk-button
                  v-if="sceneProperties.image"
                  class="ma-1"
                  data-test="button-scene-image-edit"
                  @click="openSceneImageEditor"
                >
                  {{ $t('editImageBtn') }}
                </edtk-button>
                <edtk-button
                  v-if="sceneProperties.image"
                  data-test="button-scene-image-delete"
                  color="white"
                  class=" ma-1 primary--text"
                  style="width: fit-content"
                  @click="deleteSceneImage"
                >
                  {{ $t('deleteImageBtn') }}
                </edtk-button>
              </div>
            </v-overlay>
          </v-fade-transition>
        </v-img>
      </v-hover>
    </div>
    <div class="pt-0 two">
      <h2 class="page-subtitle">
        {{ $t('description') }}
      </h2>
      <div class="white rounded elevation-1 pa-5" style="min-height:215px">
        <EditableField
          data-test="input-scene-description"
          type="rich-editor-bubble"
          object-id="description"
          :edit-right="editRight"
          :value="sceneProperties.description"
          :placeholder="$t('sceneDescriptionPlaceholder')"
          large
          @input="editSceneDescription"
        />
      </div>
    </div>
    <div class="three">
      <h2 class="page-subtitle">
        {{ $t('graphicNotes') }}
      </h2>
      <div class="white rounded elevation-1 pa-5" style="min-height:200px">
        <EditableField
          type="rich-editor-bubble"
          object-id="graphicNotes"
          :edit-right="editRight"
          :value="sceneProperties.graphicNotes"
          :placeholder="$t('graphicNotesPlaceholder')"
          large
          @input="editSceneGraphicNotes"
        />
      </div>
    </div>
    <div class="four">
      <h2 class="page-subtitle">
        {{ $t('editingNotes') }}
      </h2>
      <div class="white rounded elevation-1 pa-5" style="min-height:200px">
        <EditableField
          type="rich-editor-bubble"
          object-id="editingNotes"
          :edit-right="editRight"
          :value="sceneProperties.editingNotes"
          :placeholder="$t('editingNotesPlaceholder')"
          large
          @input="editSceneEditingNotes"
        />
      </div>
    </div>
  </div>
</template>

<script>
import EditableField from '~/components/common/EditableField'
export default {
  components: { EditableField },
  props: {
    sceneProperties: {
      type: Object,
      default: () => {}
    },
    editRight: {
      type: Boolean,
      required: true,
      default: false
    }
  },
  data () {
    return {
      transparent: 'rgba(0, 85, 138, 0)'
    }
  },
  i18n: {
    messages: {
      fr: {
        description: 'Description',
        graphicNotes: 'Notes graphiques',
        editingNotes: 'Notes montages',
        sceneDescriptionPlaceholder: 'Ajouter une description ici...',
        createImageBtn: 'Créer un visuel',
        editImageBtn: 'Modifier le visuel',
        deleteImageBtn: 'Supprimer',
        graphicNotesPlaceholder: 'Ajouter des notes graphiques ici...',
        editingNotesPlaceholder: 'Ajouter des notes montages ici...',
        deleteConfirm: 'Voulez-vous vraiment supprimer le visuel de cette scène ? ',
        btn: {
          cancel: 'Annuler',
          delete: 'Supprimer'
        }
      },
      en: {
        description: 'Description',
        graphicNotes: 'Graphic notes',
        editingNotes: 'Editing notes',
        sceneDescriptionPlaceholder: 'Add description here...',
        createImageBtn: 'Create image',
        editImageBtn: 'Edit image',
        deleteImageBtn: 'Delete image',
        graphicNotesPlaceholder: 'Add graphic notes here...',
        editingNotesPlaceholder: 'Add editing notes here...',
        deleteConfirm: 'Do you really want to remove the image of this scene ? ',
        btn: {
          cancel: 'Cancel',
          delete: 'Delete'
        }
      }
    }
  },
  methods: {
    openSceneImageEditor () {
      this.$emit('openSceneImageEditor')
    },
    editSceneDescription (e) {
      this.$emit('editSceneProperty', { property: 'description', data: e })
    },
    editSceneGraphicNotes (e) {
      this.$emit('editSceneProperty', { property: 'graphicNotes', data: e })
    },
    editSceneEditingNotes (e) {
      this.$emit('editSceneProperty', { property: 'editingNotes', data: e })
    },
    deleteSceneImage () {
      this.$swal({
        title: '',
        html: this.$t('deleteConfirm'),
        icon: 'question',
        showCancelButton: true,
        confirmButtonColor: '#4bca81',
        cancelButtonColor: '#c2c5cb',
        confirmButtonText: this.$t('btn.delete'),
        cancelButtonText: this.$t('btn.cancel')
      }).then((result) => {
        if (result.isConfirmed) {
          this.$emit('deleteSceneImage')
        }
      })
    }
  }
}

</script>

<style scoped>
.overviewGrid {
  padding-bottom: 30px;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 10px 25px;
  grid-auto-rows: min(200px, auto);
  }
  .one {
  max-width: 300px;
  grid-column: 1;
  grid-row: 1/3;
}
.two {
  grid-column: 2/5;
  grid-row: 1/3;
}
.three {
  grid-column: 1/3;
  grid-row: 3/5;
}
.four {
  grid-column: 3/5;
  grid-row: 3/5;
}
.show-btns {
  color: rgba(0, 85, 138, 1) !important;
}
</style>
