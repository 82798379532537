<template>
  <edtk-dialog
    :value="value"
    size="lg"
    :title="$t('newBlock')"
    @close="close"
  >
    <template slot="content">
      <section v-if="!newLearningObject.__t" class="types-selector_container">
        <div
          v-for="t in resourceTypes"
          :key="t.id"
          class="edtk-clickable-card flex-column align-start justify-start"
          @click="updateNewLearningObject({key: '__t', value: t.id})"
        >
          <div class="title">
            <edtk-icon
              :icon="$enums.materialIcons[t.id]"
              icon-size="3xl"
              icon-color="primary"
            ></edtk-icon>
            <h4 class="edtk-text-primary edtk-title-small">
              {{ $t(`${t.id}.label`) }}
            </h4>
          </div>
          <div class="description">
            {{ $t(`${t.id}.description`) }}
          </div>
        </div>
      </section>
      <section v-else class="w-100">
        <div class="creation-container">
          <div>
            <edtk-text
              data-tour-id="lo-creation-name"
              :value="newLearningObject.name"
              :label="$t(`${newLearningObject.__t}.placeholder`)"
              :placeholder="$t(`${newLearningObject.__t}.placeholder`)"
              :editable="true"
              :on-error="tryValidate && !$v.newLearningObject.name.required"
              :error-message="$t('errors.input.REQUIRED')"
              @input="(e) => updateNewLearningObject({key: 'name', value: e})"
            />
            <edtk-rich-text-editor
              data-tour-id="lo-creation-description"
              :value="newLearningObject.description"
              :label="$t('course.DESCRIPTION')"
              :placeholder="$t('newLearningObject.description')"
              :editable="true"
              object-id="lo-creation-description"
              @input="(e) => updateNewLearningObject({key: 'description', value: e})"
            ></edtk-rich-text-editor>
          </div>
          <div>
            <edtk-select
              data-tour-id="lo-creation-language"
              :value="newLearningObject.language"
              :options="workspaceLanguages.map((e) => ({ value: e, label: $t(`languages.${e}`) }))"
              :label="$t('buttons.LANG')"
              :placeholder="$t('newLearningObject.language')"
              :editable="true"
              class="mb-3"
              @input="(e) => updateNewLearningObject({key: 'language', value: e})"
            />
            <edtk-select
              v-if="newLearningObject.__t === 'module' || newLearningObject.__t === 'sequence'"
              data-tour-id="lo-creation-type"
              :value="newLearningObject.type"
              :options="loTypeOptions"
              :label="$t('typeOf', { name: $t(`${newLearningObject.__t}.label`).toLowerCase() })"
              :placeholder="$t('newLearningObject.type')"
              :editable="true"
              :on-error="tryValidate && !$v.newLearningObject.type.required"
              :error-message="$t('errors.input.REQUIRED')"
              class="mb-3"
              @input="(e) => updateNewLearningObject({key: 'type', value: e})"
            />
            <edtk-select
              v-if="newLearningObject.__t === 'module' || newLearningObject.__t === 'sequence'"
              data-tour-id="lo-creation-activityType"
              :value="newLearningObject.activityType"
              :options="translatedActivityTypeOptions"
              :label="$t('activityType')"
              :placeholder="$t('activityType')"
              :editable="true"
              class="mb-3"
              @input="(e) => updateNewLearningObject({key: 'activityType', value: e})"
            />
          </div>
        </div>
      </section>
    </template>
    <template v-if="newLearningObject.__t" slot="actions">
      <div class="buttons-container">
        <edtk-button
          data-tour-id="btn-lo-creation-cancel"
          :disabled="onload"
          :label="$t('buttons.CANCEL')"
          type="outline"
          @click="close"
        ></edtk-button>
        <edtk-button
          data-tour-id="btn-lo-creation-submit"
          :loading="onload"
          :disabled="onload"
          :label="$t('submit')"
          type="filled"
          @click="submit"
        ></edtk-button>
      </div>
    </template>
  </edtk-dialog>
</template>
<script>
import { mapGetters, mapActions } from 'vuex'
import { required, requiredIf } from 'vuelidate/lib/validators'
import i18n from './i18n'

export default {
  props: {
    value: {
      type: Boolean,
      default: false
    },
    loType: {
      type: String,
      default: () => undefined
    },
    loLanguage: {
      type: String,
      default: () => undefined
    },
    fromMechanism: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      onload: false,
      newLearningObject: {
        __t: this.loType,
        name: undefined,
        description: undefined,
        type: undefined,
        activityType: undefined,
        language: this.loLanguage
      },
      tryValidate: false
    }
  },
  i18n,
  validations: {
    newLearningObject: {
      __t: {
        required
      },
      name: {
        required
      },
      type: {
        required: requiredIf(function () {
          return this.newLearningObject.__t === 'module' || this.newLearningObject.__t === 'sequence'
        })
      }
    }
  },
  computed: {
    ...mapGetters({
      currentModuleTypes: 'settings/getSettingsModuleType',
      currentSequenceTypes: 'settings/getSettingsSequenceType',
      workspaceLanguages: 'workspace/getSettingsLanguages',
      currentWorkspaceId: 'workspace/getCurrentId'
    }),
    resourceTypes () {
      let o = []
      if (!this.fromMechanism && this.$userRightsHelper.hasFeatureAccess('mechanism')) {
        o.push({ id: 'mechanism' })
      }
      if (this.$userRightsHelper.hasFeatureAccess('course')) {
        o.push({ id: 'course' })
      }
      o = [
        ...o,
        { id: 'module' },
        { id: 'sequence' }
      ]
      return o
    },
    loTypeOptions () {
      let res = []
      if (this.newLearningObject.__t === 'module') {
        res = this.$_.map(this.currentModuleTypes, (e) => {
          return { value: e, label: this.$nuxt.$te(`course.module.type.${e}`) ? this.$t(`course.module.type.${e}`) : e }
        })
      } else if (this.newLearningObject.__t === 'sequence') {
        res = this.$_.map(this.currentSequenceTypes, (e) => {
          return { value: e, label: this.$nuxt.$te(`course.module.type.${e}`) ? this.$t(`course.module.type.${e}`) : e }
        })
      }
      return this.$_.sortBy(res, [r => r.label.normalize('NFD').replace(/[\u0300-\u036F]/g, '').toLowerCase()])
    },
    translatedActivityTypeOptions () {
      let res = []
      if (this.newLearningObject.__t === 'module' || this.newLearningObject.__t === 'sequence') {
        res = this.$_.map(this.$enums.learningObject[this.newLearningObject.__t].activityTypes, (e) => {
          return { value: e, label: this.$t(`activityTypes.${e}`) }
        })
      }
      return this.$_.sortBy(res, [r => r.label.normalize('NFD').replace(/[\u0300-\u036F]/g, '').toLowerCase()])
    }
  },
  watch: {
    value (n) {
      if (n) {
        this.newLearningObject.__t = this.loType
      }
    }
  },
  methods: {
    ...mapActions({
      courseCreate: 'course/create',
      moduleCreate: 'module/create',
      sequenceCreate: 'sequence/create',
      mechanismCreate: 'mechanism/create'
    }),
    updateNewLearningObject ({ key, value }) {
      this.newLearningObject[key] = value
      if ((key === '__t' && value === 'module') && this.currentModuleTypes.length === 1) {
        this.newLearningObject.type = this.currentModuleTypes[0]
      }
      if ((key === '__t' && value === 'sequence') && this.currentSequenceTypes.length === 1) {
        this.newLearningObject.type = this.currentSequenceTypes[0]
      }
    },
    submit () {
      this.$v.$touch()
      if (this.$v.$invalid) {
        this.tryValidate = true
      } else {
        this.tryValidate = false
        if (this.fromMechanism) {
          this.$emit('submit', { newLearningObject: this.newLearningObject })
          this.close()
        } else {
          const self = this
          this.onload = true
          this[`${this.newLearningObject.__t}Create`]({ body: this.newLearningObject }).then((newLO) => {
            self.onload = false
            this.$router.push(`/${this.$enums.routePrefix.workspace}/${this.currentWorkspaceId}/${this.$enums.appPrefix.mapping[this.newLearningObject.__t]}/${this.newLearningObject.__t}/${newLO._id}`)
            this.close()
          }).catch((err) => {
            self.onload = false
            this.$swal({
              icon: 'error',
              title: this.$t('errors.OUPS'),
              html: this.$t('errors.' + err)
            })
          })
        }
      }
    },
    close () {
      this.$emit('close')
      this.newLearningObject = {
        __t: undefined,
        name: undefined,
        description: undefined,
        type: undefined,
        activityType: undefined,
        language: undefined
      }
      this.tryValidate = false
    }
  }
}
</script>

<style lang="scss" scoped>
  .types-selector_container {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-gap: 16px;

      .title {
        display: flex;
        align-items: center;
        gap: 8px;
        color: var(--primary);
      }

      .description {
        color: var(--secondary);
      }
    }

  .creation-container {
    display: grid;
    grid-template-columns: 3fr 2fr;
    grid-gap: 24px;
    width: 100%;
  }
</style>
