import _ from 'lodash'

export default ctx => ({
  /**
   * Calculate access based on input and user workspace role
   * @param {*} item
   * @param {*} allowedRoles
   * @returns Boolean
   */
  userHasRoleOnItem (item, allowedRoles = ['accountable', 'responsible', 'consulted', 'informed'], authoriseGuests = false) {
    const user = ctx.store.getters['user/get']
    const workspace = ctx.store.getters['workspace/getWorkspace']
    /* if current member is a guest */
    const userAsMember = workspace.members.find(member => member.user._id === user._id || member.user === user._id)
    if (!authoriseGuests && userAsMember.guest) {
      return false
    }
    /* if current member is part workspace superUser */
    if (_.some(workspace.members, m => m.superUser && m.user._id.toString() === user._id.toString())) {
      return true
    }
    if (!(item && item.members)) {
      return false
    }
    return _.some(item.members, (m) => {
      if (m.user && m.user._id && m.user._id.toString() === user._id.toString()) {
        return _.some(allowedRoles, role => m[role])
      }
    })
  },
  /**
   * Get active user roles on item
   * @param {*} item
   * @returns Array
   */
  userRolesOnItem (item) {
    const user = ctx.store.getters['user/get']
    const workspace = ctx.store.getters['workspace/getWorkspace']
    /* if current member is part workspace superUser */
    if (_.some(workspace.members, m => m.superUser && m.user._id.toString() === user._id.toString())) {
      return ['accountable', 'responsible', 'consulted', 'informed']
    }
    if (!item?.members) {
      return ''
    }
    const roles = []
    const member = _.find(item.members, member => member.user?._id && member.user._id.toString() === user._id.toString())

    if (member?.responsible) { roles.push('responsible') }
    if (member?.accountable) { roles.push('accountable') }
    if (member?.consulted) { roles.push('consulted') }
    if (member?.informed) { roles.push('informed') }
    return roles
  },
  /**
    * Calculate user workspace role
    *
    * @return { String } role
    */
  getUserRoleByWorkspace () {
    const ws = ctx.store.getters['workspace/getWorkspace']
    if (!ws) {
      return ''
    }
    const user = ctx.store.getters['user/get']
    const u = _.find(ws.members, m => m.user._id === user._id || m.user === user._id)
    if (u.admin) { return 'admin' } else if (u.guest) { return 'guest' } else { return 'member' }
    // return u.permission
  },
  /**
    * Calculate user display team
    *
    * @return { Boolean }
    */
  userAccessTeam () {
    const user = ctx.store.getters['user/get']
    const workspace = ctx.store.getters['workspace/getWorkspace']
    const find = workspace.members.find((member) => { return user._id === member.user._id })
    return find.superUser || find.admin || ctx.store.getters['team/getTeams'].length !== 0
  },
  hasFeatureAccess (feature) {
    return ctx.store.getters['workspace/isFeatureActivated'](feature) && ctx.store.getters['user/isFeatureAllowedForUser'](feature)
  }
})
