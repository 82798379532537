export default ctx => ({
  updateState: (tabType, tab, opts = {}) => {
    let currentUrl
    if (opts.clearOthers) {
      currentUrl = new URL(window.location.href.split('?')[0])
    } else {
      currentUrl = new URL(window.location)
    }
    if (opts.clearSpecific) {
      opts.clearSpecific.forEach(item => currentUrl.searchParams.delete(item))
      currentUrl.searchParams.set(tabType, tab)
    } else {
      currentUrl.searchParams.set(tabType, tab)
    }
    window.history.replaceState({}, '', currentUrl)
    ctx.$evt.log('TabView', { tabType, tabValue: tab })
  }
})
