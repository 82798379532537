<template>
  <v-dialog
    v-model="dialog"
    fullscreen
    transition="dialog-bottom-transition"
  >
    <template #activator="{ on, attrs }">
      <edtk-button
        type="outline"
        small
      >
        <span
          v-bind="attrs"
          v-on="on"
        >
          {{ $t('displaySlides') }}
        </span>
      </edtk-button>
    </template>

    <Slides v-if="dialog" :storyboard="storyboard" @close="close" />
  </v-dialog>
</template>

<script>
import Slides from './index'

export default {
  components: { Slides },
  props: {
    storyboard: {
      type: Object,
      default: () => {}
    }
  },
  data () {
    return {
      dialog: false
    }
  },
  i18n: {
    messages: {
      fr: {
        displaySlides: 'Présentation'
      },
      en: {
        displaySlides: 'Display'
      }
    }
  },
  methods: {
    close () {
      this.$evt.log('StoryboardOpeningPresentation')
      this.dialog = false
    }
  }
}
</script>
