<template>
  <v-dialog
    v-model="opened"
    fullscreen
    :title="$t('documentEdtake')"
    persistent
    no-click-animation
    @input="close"
  >
    <v-card v-if="!currentMutable" class="ma-0">
      <div style="min-height: 100vh;" class="d-flex align-center justify-center">
        <v-progress-circular
          :size="50"
          color="primary"
          indeterminate
        ></v-progress-circular>
      </div>
    </v-card>
    <div v-else>
      <Storyboard
        v-if="currentMutable.__t === 'storyboard'"
        ref="storyboard"
        :data.sync="currentMutable.contentData"
        :edtake-document-id="currentMutable._id"
        :document-name="currentMutable.name"
        :edit-right="true"
        :upload-picture="uploadPicture"
        :save-storyboard="saveStoryboard"
        @updateDocumentTitle="(e) => updateDocumentTitle(e)"
        @closeStoryboard="close"
      />
      <RichEditor
        v-if="currentMutable.__t === 'richDocument'"
        :data.sync="currentMutable"
        :upload-picture="uploadPicture"
        :save-rich-editor="saveRichEditor"
        @updateDocumentTitle="(e) => updateDocumentTitle(e)"
        @close="close"
      />
      <section v-if="currentMutable.__t === 'tiersDocument'">
        <IframeEditor
          v-if="['draft.io', 'figma', 'canva', 'whimsical'].includes(currentMutable.contentData.application)"
          :data.sync="currentMutable"
          @updateDocumentTitle="(e) => updateDocumentTitle(e)"
          @close="close"
        />
      </section>
    </div>
  </v-dialog>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
import i18n from './i18n.js'

import Storyboard from '~/components/storyboard/index'
import RichEditor from '~/components/richEditor/index'
import IframeEditor from '~/components/iframeEditor/index'

export default {
  name: 'EdtakeDocumentViewer',
  components: {
    Storyboard,
    RichEditor,
    IframeEditor
  },
  data () {
    return {
      opened: false,
      currentId: undefined,
      currentMutable: undefined
    }
  },
  computed: {
    ...mapGetters({
      get: 'edtakeDocument/get'
    }),
    current () {
      return this.get.find(o => o._id === this.currentId && !!o.contentData)
    }
  },
  watch: {
    current: {
      deep: true,
      handler (n) {
        this.currentMutable = n ? Object.assign({}, n) : undefined
      }
    }
  },
  created () {
    this.$nuxt.$on('open-edtake-document-viewer', e => this.open(e))
  },
  beforeDestroy () {},
  methods: {
    ...mapActions({
      upload: 'edtakeDocument/uploadImage',
      updateContentData: 'edtakeDocument/updateContentData',
      updateDocument: 'edtakeDocument/update',
      fetchEdtakeDocument: 'edtakeDocument/fetchOne'
    }),
    async open (e) {
      this.currentMutable = undefined
      this.opened = true
      this.currentId = e
      await this.fetchEdtakeDocument({ id: e })
    },
    close () {
      this.currentId = undefined
      this.currentMutable = undefined
      this.opened = false
    },
    toggle () {
      this.opened ? this.close() : this.open()
    },
    /* STORYBOARD */
    async uploadPicture (e) {
      return await this.upload({ image: e, type: this.currentMutable.__t, id: this.currentMutable._id })
    },
    saveStoryboard ({ storyboard = {} }) {
      return this.updateContentData({ id: this.currentMutable._id, body: storyboard, type: this.currentMutable.__t })
    },
    saveRichEditor ({ richEditor = {} }) {
      return this.updateContentData({ id: this.currentMutable._id, body: richEditor, type: this.currentMutable.__t })
    },
    async updateDocumentTitle (name) {
      await this.updateDocument({ id: this.currentMutable._id, body: { name }, type: this.currentMutable.__t }).then(() => {
      })
        .catch((error) => {
          this.$swal({
            icon: 'error',
            titleText: error
          })
        })
    }
  },
  i18n
}
</script>
<style css scoped>
</style>
