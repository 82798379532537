<template>
  <section v-if="pastDueInvoiceUrl">
    <div class="d-flex justify-center align-center white--text band">
      {{ $t('waitingForPayment') }}
      <edtkButton
        :label="$t('accessInvoice')"
        type="filled"
        class="band-button"
        small
        :href="pastDueInvoiceUrl"
        target="_blank"
      />
    </div>
  </section>
</template>
<script>
import { mapGetters, mapActions } from 'vuex'
import i18n from './i18n'
export default {
  i18n,
  data () {
    return {
      dialog: true,
      pastDueInvoiceUrl: undefined
    }
  },
  computed: {
    ...mapGetters({
      workspace: 'workspace/getCurrent'
    })
  },
  watch: {
    $route () {
      this.dialog = true
    },
    dialog (n) {
      if (!n) { this.displayInvoice = false }
    }
  },
  mounted () {
    if (this.workspace.license.subscriptionStatus === this.$enums.stripe.subscriptionStatus.waiting_for_payment) {
      this.fetchPastDueInvoice().then((pastDue) => {
        this.pastDueInvoiceUrl = pastDue.url
      })
    }
  },
  methods: {
    ...mapActions({
      fetchPastDueInvoice: 'stripe/getPastDueInvoice'
    })
  }
}
</script>
<style scoped>
.no-uppercase {
  text-transform: unset !important;
}

.band {
  width: 100%;
  height: 29px;
  position: relative;
  font-size: 15px;
  background-color: var(--primary);
}

.band-button {
  right: 0;
  position: absolute;
}

</style>
