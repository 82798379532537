import Quill from 'quill'
import parchment from 'parchment'
import ProcLink from './quill-insertButton.js'
const Embed = Quill.import('blots/embed')
Quill.register('formats/proc-link', ProcLink)

const Parchment = Quill.import('parchment')

const Module = Quill.import('core/module')

export default class Button extends Module {
  constructor (quill, options) {
    super(quill, options)
    this.container = quill.addContainer('ql-button')

    const toolbar = quill.getModule('toolbar')
    /* toolbar.addHandler('btn', () => {
      const index = quill.getSelection(true).index// quill is the reference to my instantiated quill obj
      const cObj = { text: 'Test', value: 'non', url: 'www.google.com' }
      quill.insertEmbed(index + 1, 'proc-link', cObj)
    }) */

    quill.on(Quill.events.TEXT_CHANGE, () => {
      const text = quill.getText()
      const char = text.replace(/\s/g, '')
      this.container.innerHTML = `<button type="button">yes</button> Current char count: ${char.length}`
    })

    console.log('quill:', quill)
    console.log('options:', options)
  }

  test (test) {
    console.log('test :', test)
  }
}

Button.DEFAULTS = {
  buttonIcon: '<svg viewbox="0 0 18 18"><circle class="ql-fill" cx="7" cy="7" r="1"></circle><circle class="ql-fill" cx="11" cy="7" r="1"></circle><path class="ql-stroke" d="M7,10a2,2,0,0,0,4,0H7Z"></path><circle class="ql-stroke" cx="9" cy="9" r="6"></circle></svg>'
}
