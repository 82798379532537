/* eslint-disable no-console */
import _ from 'lodash'

export const state = () => ({
  ressources: [],
  members: [],
  avatarDialogOpen: undefined,
  avatarDialogObject: undefined
})

export const getters = {
  getMembershipMembers: state => (id) => {
    return state.members
  },
  getMember: state => (id) => {
    return id ? state.members.find(o => o._id === id) : state.members
  },
  getMembershipRessources: state => (id) => {
    return id ? state.ressources.find(o => o.objectId === id) : state.ressources
  },
  getMembership: state => (id) => {
    const members = { accountable: [], responsible: [], consulted: [], informed: [] }
    const r = _.find(state.ressources, o => o.objectId === id)
    if (!r) {
      return members
    }
    members.accountable = _.map(r.members.accountable, r => _.find(state.members, (m) => { return m._id === r }) || []) || []
    members.responsible = _.map(r.members.responsible, r => _.find(state.members, (m) => { return m._id === r }) || []) || []
    members.consulted = _.map(r.members.consulted, r => _.find(state.members, (m) => { return m._id === r }) || []) || []
    members.informed = _.map(r.members.informed, r => _.find(state.members, (m) => { return m._id === r }) || []) || []

    return members
  },
  getObjectMembers: state => (id) => {
    const members = { accountable: [], responsible: [], consulted: [], informed: [] }
    const result = _.find(state.ressources, o => o.objectId === id)
    if (!result) {
      return []
    } else {
      members.accountable = _.filter(_.map(result.members.accountable, r => _.find(state.members, (m) => { return m._id === r })) || [], (m) => { return m !== undefined })
      members.responsible = _.filter(_.map(result.members.responsible, r => _.find(state.members, (m) => { return m._id === r })) || [], (m) => { return m !== undefined })
      members.consulted = _.filter(_.map(result.members.consulted, r => _.find(state.members, (m) => { return m._id === r })) || [], (m) => { return m !== undefined })
      members.informed = _.filter(_.map(result.members.informed, r => _.find(state.members, (m) => { return m._id === r })) || [], (m) => { return m !== undefined })

      const r = {}
      _.forEach(members.accountable, (u) => {
        if (!r[u._id]) {
          r[u._id] = { ...u, roles: [] }
        }
        r[u._id].roles.push('accountable')
      })

      _.forEach(members.responsible, (u) => {
        if (!r[u._id]) {
          r[u._id] = { ...u, roles: [] }
        }
        r[u._id].roles.push('responsible')
      })

      _.forEach(members.consulted, (u) => {
        if (!r[u._id]) {
          r[u._id] = { ...u, roles: [] }
        }
        r[u._id].roles.push('consulted')
      })

      _.forEach(members.informed, (u) => {
        if (!r[u._id]) {
          r[u._id] = { ...u, roles: [] }
        }
        r[u._id].roles.push('informed')
      })

      return _.map(Object.keys(r), (k) => { return r[k] })
    }
  },
  getAvatarDialogOpen: (state) => {
    return state.avatarDialogOpen
  },
  getAvatarDialogObject: (state) => {
    return state.avatarDialogObject
  }
}

export const actions = {
  clear ({ commit }) {
    commit('CLEAR')
  },
  openAvatarDialog ({ commit }, payload) {
    commit('RESET')
    commit('SET_OPENED', payload)
  },
  updateAvatarDialog ({ commit }, payload) {
    commit('SET_OPENED', payload)
  },
  avatarDialogClose ({ commit }) {
    commit('RESET')
  },
  // Store refactor - Should commit
  /**
   * Add a specific grant permission for a user on an object
   * Grant accountable automaticly replace the old accountable user
   *
   * @param {*} { commit, dispatch }
   * @param { Object } parameters - All parameters
   * @param { String } parameters.objectId - The id of the object
   * @param { String } parameters.objectType - The type of the object
   * @param { 'responsible'| 'accountable'| 'consulted'| 'informed' } parameters.permission - The permission of the member
   * @param { String } parameters.editUserId - The id of the user
   */
  grantPermission ({ commit, dispatch }, { ids, permission, memberType, editUserId, memberId, objectType }) {
    return new Promise((resolve, reject) => {
      const modelType = ['mechanism', 'course', 'module', 'sequence'].includes(objectType) ? 'learningObject' : 'projectItem'
      this.$edtake[modelType].updateMembersPermission({ body: { ids, editUserId, permission, memberType, memberId, actionType: 'grant' } })
        .then((d) => {
          resolve(d)
          dispatch(`${objectType}/setMembers`, { ids, newObj: d }, { root: true })
        })
        .catch((err) => {
          console.error('ERROR', err)
          reject(err)
        })
    })
  },
  // Store refactor - Should commit
  /**
   * Revoke a specific grant permission for a user on an object
   *
   * @param {*} { commit, dispatch }
   * @param { Object } parameters - All parameters
   * @param { String } parameters.objectId - The id of the object
   * @param { String } parameters.objectType - The type of the object
   * @param { 'responsible'| 'consulted'| 'informed' } parameters.permission - The permission of the member
   * @param { String } parameters.editUserId - The id of the user
   */
  revokePermission ({ commit, dispatch }, { ids, permission, memberType, editUserId, memberId, objectType }) {
    return new Promise((resolve, reject) => {
      const modelType = ['mechanism', 'course', 'module', 'sequence'].includes(objectType) ? 'learningObject' : 'projectItem'
      this.$edtake[modelType].updateMembersPermission({ body: { ids, editUserId, permission, memberType, memberId, actionType: 'revoke' } })
        .then((d) => {
          resolve(d)
          dispatch(`${objectType}/setMembers`, { ids, newObj: d }, { root: true })
        })
        .catch((err) => {
          console.error('ERROR', err)
          reject(err)
        })
    })
  }
}

export const mutations = {
  SET_MEMBERSHIP (state, value) {
    state.ressources = value.ressources
    state.members = value.members
  },
  CLEAR (state) {
    state.ressources = []
    state.members = []
  },
  SET_OPENED (state, payload) {
    state.avatarDialogOpen = true
    state.avatarDialogObject = payload.obj
  },
  RESET (state) {
    state.avatarDialogOpen = undefined
    state.avatarDialogObject = undefined
  }
}
