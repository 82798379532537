<template>
  <v-popover
    :disabled="!editRight"
    trigger="manual"
    :open="isOpen"
    :placement="position"
    :container="containerData"
    @hide="close"
  >
    <section
      v-if="editRight"
      class="tooltip-target pointer"
      :data-test="dataTest"
      @click="isOpen =! isOpen"
    >
      <slot v-if="!isOpen" name="default" :component-data="value">
        <div class="hover-highlight d-flex align-center">
          {{ optionType === 'object' ? optionValue ? optionValue.label : placeholder : translateIfKeyExist(mutableModel) }}
          <v-icon v-if="displayIcon" :color="iconColor" small class="ml-2">
            mdi-chevron-down
          </v-icon>
        </div>
      </slot>
      <div v-else class="hover-highlight">
        {{ optionType === 'object' ? optionValue ? optionValue.label : placeholder : translateIfKeyExist(mutableModel) }}
      </div>
    </section>

    <slot v-else name="default" :component-data="value">
      {{ optionType === 'object' ? optionValue ? optionValue.label : '' : translateIfKeyExist(mutableModel) }}
    </slot>
    <span
      v-if="$v.mutableModel.$error"
      class="form_error"
    >
      {{ $t('errors.input.INVALID') }} - {{ attrsInformation }}
    </span>
    <template slot="popover">
      <section class="popover-section">
        <v-list class="popover-list">
          <v-list-item
            v-for="item in optionsList"
            :key="item.id"
            @click="selectValue(item.id)"
          >
            <v-list-item-content>
              <v-list-item-title>
                <span>{{ translateIfKeyExist(item.label) }}</span>
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </section>
    </template>
  </v-popover>
</template>

<script>
import { required } from 'vuelidate/lib/validators'
export default {
  name: 'EditableList',
  props: {
    dataTest: {
      type: String
    },
    editRight: {
      type: Boolean,
      required: true,
      default: false
    },
    options: {
      type: Array,
      default: () => [true, false]
    },
    value: {
      type: Boolean,
      required: true
    },
    containerRef: {
      type: HTMLElement
    },
    parentContainer: {
      type: Boolean,
      default: () => false
    },
    translationKey: {
      type: String,
      default: () => undefined
    },
    position: {
      type: String,
      default: () => 'auto'
    },
    placeholder: {
      type: String,
      default: () => ''
    },
    customValidation: {
      type: Object,
      default: () => {}
    },
    displayIcon: {
      type: Boolean,
      default: true
    },
    iconColor: {
      type: String,
      required: false
    }
  },
  validations () {
    return {
      mutableModel: {
        // Adding all custom validations to the mutableModel
        ...this.customValidation ? Object.keys(this.customValidation.controls).map((key) => { return this.customValidation.controls[key] }) : {},
        required: this.$attrs.required || this.$attrs.required === '' ? required : {}
      }
    }
  },
  data () {
    return {
      mutableModel: this.value,
      isOpen: false,
      containerData: undefined
    }
  },
  computed: {
    /* TODO refaire le composant */
    attrsInformation () {
      const errorMessage = []
      if ((this.$attrs.required || this.$attrs.required === '') && !this.$v.mutableModel.required) {
        errorMessage.push(`${this.$t('required')}`)
      }
      Object.keys(this.customValidation ? this.customValidation.controls : {}).forEach((key) => {
        if (!this.$v.mutableModel[key]) {
          errorMessage.push(this.customValidation.errors[key])
        }
      })

      return errorMessage.join(' - ')
    },
    optionType () {
      return this.options.length > 0 ? typeof this.options[0] : undefined
    },
    optionValue () {
      return this.$_.find(this.options, o => o.id === this.translateIfKeyExist(this.mutableModel))
    },
    optionsList () {
      return this.options.map((option) => {
        return typeof option === 'object' ? option : { id: option, label: this.translateIfKeyExist(option) }
      })
    }
  },
  watch: {
    value (n) {
      this.mutableModel = n
    },
    containerRef (n) {
      this.containerData = n
    }
  },
  mounted () {
    if (this.parentContainer) {
      this.containerData = this.$parent.$el
    }
  },
  methods: {
    selectValue (e) {
      this.mutableModel = e
      this.$v.mutableModel.$touch()
      if (!this.$v.mutableModel.$error) { this.$emit('input', e) }
      this.close()
    },
    translateIfKeyExist (item) {
      return this.translationKey ? this.$t(`${this.translationKey}.${item}`) : item
    },
    close () {
      this.$v.mutableModel.$touch()
      this.isOpen = false
    }
  }
}
</script>
<style lang="scss" scoped>
.tooltip-target {
  min-width: 20px;
}
.hover-highlight {
  display: block;
  border-bottom: dashed transparent 2px;
  &:hover {
    cursor: pointer;
    border-bottom: dashed $blue 2px;
  }
}
.popover-section {
  margin: -5px;
}
.popover-list {
  padding: 0;
  max-height: 300px;
  overflow: auto;
}
</style>
