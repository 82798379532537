<template>
  <section>
    <span v-if="!editRight">
      <slot name="default" :componentData="value">
        <tr
          v-for="(t,$index) in mutableModel"
          :key="`${$index}_${t}`"
          class="edtake-table-tr"
        >
          <td style="width: 30px;" class="text-center text-middle">
            <span class="brownish-grey--text">{{ $index + 1 }}</span>
          </td>
          <td>
            <span>{{ t }}</span>
          </td>
          <td style="width: 30px;" class="text-center text-middle">
          </td>
        </tr>
      </slot>
    </span>
    <span v-else>
      <draggable
        v-model="mutableModel"
        group="trainingInformations.objectives"
        handle=".handleDragObjective"
        tag="tbody"
        ghost-class="ghost"
        @end="emit(mutableModel)"
      >
        <v-hover v-for="(t,$index) in mutableModel" :key="`${$index}_${t}`" v-slot="{ hover }">
          <tr
            class="edtake-table-tr"
          >
            <td style="width: 30px;" class="text-center text-middle">
              <i v-if="hover" class="mdi mdi-drag-vertical brownish-grey--text blue--text-hover  pointer handleDragObjective" />
            </td>
            <td>
              <component
                :is="tagType"
                :ref="`tagText-${$index}`"
                :style="{'min-width': minWidth}"
                :class="{ 'hover-highlight': true, 'active': states[$index] === 'edit' }"
                contenteditable="true"
                @click="states[$index] = states[$index] === 'creation' ? 'creation' : 'edit'"
                @blur="(e) => updateValue(e, $index)"
                @keyup="(e) => inputKeyup(e, $index)"
                @keypress="preventBreakLine"
              >
                {{ mutableModel[$index] }}
              </component>
            </td>
            <td style="width: 30px;" class="text-center text-middle">
              <i v-if="hover" class="mdi mdi-trash-can-outline brownish-grey--text dull-red--text-hover pointer" @click="removeObjective($index)" />
            </td>
          </tr>
        </v-hover>
      </draggable>
      <br>
        <edtkButton
          :label="newRowLabel"
          type="outline"
          x-small
          class="my-2"
          @click="addObjective"
        />
    </span>
    <span
      v-if="$v.mutableModel.$error"
      class="form_error"
    >
      {{ $t('errors.input.INVALID') }} - {{ attrsInformation }}
    </span>
  </section>
</template>

<script>
import draggable from 'vuedraggable'
import { required } from 'vuelidate/lib/validators'
import i18n from './i18n.js'

export default {
  name: 'EditableDraggableList',
  components: {
    draggable
  },
  props: {
    dataTest: {
      type: String
    },
    editRight: {
      type: Boolean,
      required: true,
      default: false
    },
    tagType: {
      type: String,
      default: () => 'span'
    },
    value: {
      type: Array,
      default: () => []
    },
    customValidation: {
      type: Object,
      default: () => {}
    },
    newRowLabel: {
      type: String,
      default () { return this.$t('newLine') }
    },
    minWidth: {
      type: String,
      default: () => { return '50px' }
    }
  },
  validations () {
    return {
      mutableModel: {
        ...this.customValidation ? Object.keys(this.customValidation.controls).map((key) => { return this.customValidation.controls[key] }) : {},
        required: this.$attrs.required || this.$attrs.required === '' ? required : {}
      }
    }
  },
  data () {
    return {
      states: [],
      mutableModel: this.value
    }
  },
  i18n,
  computed: {
    attrsInformation () {
      const errorMessage = []
      if ((this.$attrs.required || this.$attrs.required === '') && !this.$v.mutableModel.required) {
        errorMessage.push(`${this.$t('required')}`)
      }
      Object.keys(this.customValidation ? this.customValidation.controls : {}).forEach((key) => {
        if (!this.$v.mutableModel[key]) {
          errorMessage.push(this.customValidation.errors[key])
        }
      })
      return errorMessage.join(' - ')
    }
  },
  watch: {
    value (n) {
      this.mutableModel = n
    }
  },
  methods: {
    emit (el) {
      if (!this.$v.mutableModel.$error) { this.$emit('input', [...el]) }
    },
    updateValue (e, i) {
      const inputValue = this.$_.trim(e.target.innerText)
      if (!inputValue) {
        this.removeObjective(i)
        return
      }
      this.states[i] = 'read'
      if (this.mutableModel[i] !== inputValue) {
        this.mutableModel[i] = inputValue
        this.mutableModel = this.$_.filter(this.mutableModel, el => el !== '')
        this.$v.mutableModel.$touch()
        this.emit(this.mutableModel)
      }
    },
    inputKeyup (e, i) {
      const isEnter = e.key === 'Enter' || e.keyCode === 13
      const isEscape = e.key === 'Escape' || e.keyCode === 27
      if (isEnter || isEscape) {
        this.states[i] = 'read'
        this.$nextTick(() => {
          this.$refs[`tagText-${i}`][0].blur()
          if (isEnter) { this.addObjective() }
        })
      }
    },
    preventBreakLine (e) {
      if (e.key === 'Enter' || e.keyCode === 13) {
        e.preventDefault()
      }
    },
    addObjective () {
      if (!this.mutableModel) { this.mutableModel = [] }
      this.mutableModel.push('')
      this.states[this.mutableModel.length - 1] = 'creation'
      this.$nextTick(() => {
        this.$refs[`tagText-${this.mutableModel.length - 1}`][0].focus()
      })
    },
    removeObjective (i) {
      const r = this.$_.clone(this.mutableModel)
      this.$_.remove(r, (v, index) => index === i)
      this.mutableModel = r
      if (this.states[i] !== 'creation') { this.emit(this.mutableModel) }
    }
  }
}
</script>
<style lang="scss" scoped>
.hover-highlight {
  display: block;
  border-bottom: dashed transparent 2px;
  &:hover {
    cursor: text;
    border-bottom: dashed $blue 2px;
  }
  &.active {
    border-bottom: solid $blue 1px;
  }
}
</style>
