<template>
  <div class="d-flex align-center">
    <span
      :class="[mutableModel === (optionsAreObject ? options[0].id : options[0]) ? 'primary--text font-weight-medium': 'brownish-grey--text', editRight ? 'pointer' : '']"
      class="mx-2 h6"
      @click="editRight ? assignValue(options[0]) : ''"
    >
      {{ optionsAreObject ? options[0].label : options[0] }}
    </span>
    <span
      :class="[editRight ? 'pointer' : '', 'toggle-wrapper']"
      role="checkbox"
      :aria-checked="value"
      tabindex="0"
      @click="toggle"
    >
      <span
        class="toggle-background"
        :class="backgroundStyles"
      />
      <span
        class="toggle-indicator"
        :style="indicatorStyles"
        @click.stop="clearValue"
      />
    </span>
    <span
      :class="[mutableModel === (optionsAreObject ? options[1].id : options[1]) ? 'primary--text font-weight-medium': 'brownish-grey--text', editRight ? 'pointer' : '']"
      class="mx-2 h6"
      @click="editRight ? assignValue(options[1]): ''"
    >
      {{ optionsAreObject ? options[1].label : options[1] }}
    </span>
  </div>
</template>

<script>
import { required } from 'vuelidate/lib/validators'
import i18n from './i18n.js'
export default {
  name: 'EditableSwitch',
  props: {
    dataTest: {
      type: String
    },
    editRight: {
      type: Boolean,
      required: true,
      default: false
    },
    value: {
      type: String,
      default: () => undefined
    },
    customValidation: {
      type: Object,
      default: () => {}
    },
    options: {
      type: Array,
      default: () => []
    },
    clearable: {
      type: Boolean,
      default: false
    },
    clearableValue: {
      type: String,
      defaut: () => ''
    }
  },
  validations () {
    return {
      mutableModel: {
        // Adding all custom validations to the mutableModel
        ...this.customValidation ? Object.keys(this.customValidation.controls).map((key) => { return this.customValidation.controls[key] }) : {},
        required: this.$attrs.required || this.$attrs.required === '' ? required : {}
      }
    }
  },
  data () {
    return {
      mutableModel: this.value
    }
  },
  i18n,
  computed: {
    attrsInformation () {
      const errorMessage = []
      if ((this.$attrs.required || this.$attrs.required === '') && !this.$v.mutableModel.required) {
        errorMessage.push(`${this.$t('required')}`)
      }
      Object.keys(this.customValidation ? this.customValidation.controls : {}).forEach((key) => {
        if (!this.$v.mutableModel[key]) {
          errorMessage.push(this.customValidation.errors[key])
        }
      })

      return errorMessage.join(' - ')
    },
    optionsAreObject () {
      return typeof this.options[0] === 'object'
    },
    backgroundStyles () {
      return {
        primary: this.value,
        'gray-lighter': !this.value
      }
    },
    indicatorStyles () {
      return { transform: this.value ? this.value === (this.optionsAreObject ? this.options[0].id : this.options[0]) ? 'translateX(0)' : 'translateX(14px)' : 'translateX(7px)' }
    },
    valueToDisplay () {
      if (this.value && this.optionsAreObject) {
        return this.options.find(o => o.id === this.value).label
      } else if (this.value && !this.optionsAreObject) {
        return this.value
      } else {
        return ''
      }
    }
  },
  watch: {
    value (n, o) {
      this.mutableModel = this.value
    }
  },
  methods: {
    toggle () {
      if (!this.editRight) { return }
      if (this.optionsAreObject) {
        if (!this.value) {
          this.mutableModel = this.options[0].id
        } else {
          this.mutableModel = this.value === this.options[0].id ? this.options[1].id : this.options[0].id
        }
      } else {
        // eslint-disable-next-line no-lonely-if
        if (!this.value) {
          this.mutableModel = this.options[0]
        } else {
          this.mutableModel = this.value === this.options[0] ? this.options[1] : this.options[0]
        }
      }
      this.$emit('input', this.mutableModel)
    },
    clearValue () {
      if (this.editRight && this.clearable) {
        this.mutableModel = this.clearableValue
        this.$emit('input', this.mutableModel)
      } else if (this.editRight) {
        this.toggle()
      }
    },
    assignValue (value) {
      if (this.optionsAreObject) {
        this.mutableModel = value.id
      } else {
        this.mutableModel = value
      }
      this.$emit('input', this.mutableModel)
    }
  }
}
</script>
<style lang="scss" scoped>
.primary{
  background-color: #00558a;
}

.gray-lighter{
  background-color: #c2c2c2;
}

.toggle-wrapper {
  display: inline-block;
  position: relative;
  width: 32px;
  height: 18px;
  border-radius: 9999px;
}

.toggle-wrapper:focus {
  outline: 0;
}

.toggle-background {
  display: inline-block;
  border-radius: 9999px;
  height: 100%;
  width: 100%;
  box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: background-color .4s ease;
}

.toggle-indicator {
  position: absolute;
  height: 14px;
  width: 14px;
  left: 2px;
  bottom: 2px;
  background-color: #ffffff;
  border-radius: 9999px;
  box-shadow:  0 2px 4px rgba(0, 0, 0, 0.1);
  transition: transform .4s ease;
}
</style>
