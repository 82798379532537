import { productFruits } from 'product-fruits'

export default ({ store, app }, inject) => {
  const csp = {}
  csp.init = function () {
    try {
      window.addEventListener('productfruits_ready', function () {
        window.productFruits.api.analytics.forwardEvent((event, data) => {
          app.$evt.log(event, { eventData: data })
        })
      })

      if (!window.productFruitsIsReady && store.state.user.user?.email) {
        if (!store.state.remoteConfig.config) {
          return
          // throw new Error('Configuration not loaded')
        }
        if (!store.state.remoteConfig.config.CSP_ID && store.state.remoteConfig.config.CSP_ID === 'none') {
          return
          // throw new Error('No CSP_ID found in configuration')
        }
        const user = store.state.user.user
        const freeTrialOfferWorkspace = store.getters['workspace/getFreeTrialOfferWorkspace']

        productFruits.init(store.state.remoteConfig.config.CSP_ID, app.i18n.locale, {
          username: user.email,
          email: user.email,
          firstname: user.firstname,
          lastname: user.lastname,
          signUpAt: user.createdAt,
          props: {
            internal_id: user._id,
            isEdtaker: user.isEdtaker,
            freeTrialOfferWorkspace: (freeTrialOfferWorkspace && !['ACTIVE', 'TRIALING'].includes(freeTrialOfferWorkspace.subscriptionStatus)) ? freeTrialOfferWorkspace._id : null
          }
        })
        csp.initialized = true
      }
    } catch (error) {
      console.error(error)
    }
  }

  csp.startTour = async function (tourId) {
    if (csp.initialized) {
      await window.productFruits.api.tours.tryStartTour(tourId)
    }
  }

  inject('csp', csp)
}
