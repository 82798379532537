const i18n = {
  messages: {
    fr: {
      saved: 'Vos modifications ont bien été enregistrées.'
    },
    en: {
      saved: 'Your changes have been saved.'
    }
  }
}
module.exports = i18n
