const i18n = {
  messages: {
    fr: {
      days: 'jours',
      update: 'Modifier'
    },
    en: {
      days: 'days',
      update: 'Update'
    }
  }
}

export default i18n
