/* eslint-disable no-console */
import _ from 'lodash'

export const state = () => ({
  lms: []
})

export const getters = {
  getOne: state => (id) => {
    return state.lms.find(activitie => activitie._id === id)
  },
  get: (state, getters, rootState, rootGetters) => {
    const wsId = rootGetters['workspace/getCurrentId']
    return wsId ? _.filter(state.lms, (s) => { return s.workspace === wsId }) : state.lms
  }
}

export const actions = {
  getLink ({ commit, rootGetters }, body) {
    const workspace = rootGetters['workspace/getCurrentId']
    body.workspace = workspace
    return new Promise((resolve, reject) => {
      this.$edtake.lms.getLink({ body })
        .then((d) => {
          resolve(d)
        })
        .catch((err) => {
          console.error('ERROR', err)
          reject('AN_ERROR_OCCURED')
        })
    })
  },
  sync ({ commit, rootGetters }, body) {
    return new Promise((resolve, reject) => {
      const workspace = rootGetters['workspace/getCurrentId']
      this.$edtake.lms.sync({ $queryParameters: { workspace, lms: body.lms } })
        .then((d) => {
          resolve(d)
        })
        .catch((err) => {
          console.error('ERROR', err)
          reject('AN_ERROR_OCCURED')
        })
    })
  },
  bulkOperation ({ commit, rootGetters }, { body, lms }) {
    return new Promise((resolve, reject) => {
      const workspace = rootGetters['workspace/getCurrentId']
      this.$edtake.lms.bulkOperation({ body, $queryParameters: { workspace, lms } })
        .then((d) => {
          resolve(d)
        })
        .catch((err) => {
          console.error('ERROR', err)
        })
    })
  },
  fetch ({ commit, rootGetters }, { resourceType }) {
    return new Promise((resolve, reject) => {
      const workspace = rootGetters['workspace/getCurrentId']
      this.$edtake.lms.get({ $queryParameters: { workspace, resourceType } })
        .then((d) => {
          resolve(d)
          commit('SET_LMS', d)
        })
        .catch((err) => {
          console.error('ERROR', err)
          reject('AN_ERROR_OCCURED')
        })
    })
  },
  update ({ dispatch, commit, rootGetters }, { id, body }) {
    return new Promise((resolve, reject) => {
      this.$edtake.lms.update({
        id,
        body
      })
        .then((d) => {
          commit('SET_LMS_SINGLE', d)
          if (d && d.__t) {
            dispatch(`${d.__t}/_update`, d, { root: true })
          }
          resolve(d)
        })
        .catch((err) => {
          console.error('ERROR', err)
          reject('AN_ERROR_OCCURED')
        })
    })
  }
}
export const mutations = {
  SET_LMS (state, value) {
    state.lms = value
  },
  SET_LMS_SINGLE (state, value) {
    const lms = state.lms.find(l => l._id.toString() === value._id.toString())
    if (lms) { Object.assign(lms, value) }
  },
  UPDATE_ONE (state, value) {
    const item = state.lms.find(item => (item._id && item._id.toString()) === (value._id && value._id.toString()))
    if (item) {
      Object.assign(item, value)
      state.lms = [...state.lms] // Force refresh
    } else {
      state.lms = [...state.lms, value]
    }
  }
}
