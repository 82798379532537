<template>
  <!-- TODO: REPLACE BY AVATAR WORKSPACE COMPONENT -->
  <div>
    <v-tooltip top>
      <template #activator="{ on, attrs }">
        <v-avatar
          v-bind="attrs"
          :size="size"
          :color="workspace.avatar ? 'white' : 'primary'"
          class="hover-front elevation-2"
          v-on="on"
        >
          <img
            v-if="workspace.avatar"
            :src="workspace.avatar"
            :alt="`${workspace.name}`"
          >
          <span v-else class="white--text text-uppercase headline">{{ workspace.name[0] }}</span>
        </v-avatar>
      </template>
      <span>{{ workspace.name }}</span>
    </v-tooltip>
  </div>
</template>
<script>
export default {
  name: 'WorkspaceAvatar',
  props: {
    workspace: {
      type: Object,
      required: true
    },
    size: {
      type: [Number, String],
      default: () => { return 30 }
    }
  }
}
</script>
