<template>
  <div>
    <v-tabs v-model="templateCategory">
      <v-tab key="internal" href="#internal">
        <WorkspaceAvatar size="24" class="mr-2" :workspace="currentWorkspace" />
        {{ $t('internal') }}
      </v-tab>
      <v-tab v-if="!workspaceTemplateOnly" key="external" href="#external">
        <v-icon class="mr-1">
          mdi-atom
        </v-icon>
        {{ $t('external') }}
      </v-tab>
      <v-tabs-items v-model="templateCategory">
        <v-tab-item key="internal" value="internal" :style="{maxHeight: maxHeight + 'vh', overflowY: 'auto'}">
          <section v-if="Object.keys(internalTemplateOptions).length" class="px-4">
            <div v-if="categoriesOptions.length > 1" class="d-flex flex-row mt-2">
              <v-spacer></v-spacer>
              <edtk-select
                v-model="categoryFilter"
                :options="categoriesOptions"
                :placeholder="$t('category')"
                :label="$t('category')"
                :editable="true"
                class="ml-4"
              />
            </div>
            <div
              v-for="(templatesData, key) in filteredInternalTemplates"
              :key="key"
            >
              <div class="categoryTitle">
                {{ key }}
              </div>
              <TemplateCard :templates-data="templatesData" :check-picked="checkPicked" :picked-item="pickedItem" @pick="pick" />
            </div>
          </section>
          <section v-else class="text-center pt-16">
            <h4 class="blue--text font-weight-bold mb-5">
              {{ $t('noTemplate') }}
            </h4>
            <img src="~/assets/img/template-emptyState.svg" alt="no templates" style="max-width: 30%">
          </section>
        </v-tab-item>
        <v-tab-item v-if="!workspaceTemplateOnly" key="external" value="external" :style="{maxHeight: maxHeight + 'vh', overflowY: 'auto'}">
          <section v-if="publicTemplates.length" class="px-4">
            <div v-if="workspacesOptions.length > 1" class="d-flex flex-row mt-2">
              <v-spacer></v-spacer>
              <edtk-select
                v-model="publicTemplateCategoryFilter"
                :options="publicTemplateCategoriesOptions"
                :placeholder="$t('category')"
                :label="$t('category')"
                :editable="true"
                class="mx-4"
              />
              <edtk-select
                v-model="workspaceFilter"
                :options="workspacesOptions"
                :placeholder="$t('contributor')"
                :label="$t('contributor')"
                :editable="true"
                class="ml-4"
              />
            </div>
            <section v-if="Object.keys(filteredPublicTemplates).length">
              <div
                v-for="(templatesData, key) in filteredPublicTemplates"
                :key="key"
              >
                <div class="categoryTitle">
                  {{ $nuxt.$te(`deliverable.category.${key}`) ? $t('deliverable.category.' + key) : key }}
                </div>
                <TemplateCard public-template :templates-data="templatesData" :check-picked="checkPicked" :picked-item="pickedItem" @pick="pick" />
              </div>
            </section>
            <section v-else>
              <div class="categoryTitle">
                {{ publicTemplateCategoryFilter }}
              </div>
              <div class="d-flex justify-center align-center pa-10">
                <img src="~/assets/img/template-emptyState.svg" alt="no templates" style="max-width: 15%">
                <h4 class="blue--text font-weight-medium ml-4">
                  {{ $t('emptyCategory') }}
                </h4>
              </div>
            </section>
          </section>
          <section v-else class="text-center pt-16">
            <h4 class="blue--text font-weight-bold mb-5">
              {{ $t('noTemplate') }}
            </h4>
            <img src="~/assets/img/template-emptyState.svg" alt="no templates" style="max-width: 30%">
          </section>
        </v-tab-item>
      </v-tabs-items>
    </v-tabs>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import TemplateCard from '../templateCard'
import i18n from './i18n.js'
import WorkspaceAvatar from '~/components/common/workspaceAvatar'

export default {
  name: 'TemplatePicker',
  components: {
    WorkspaceAvatar, TemplateCard
  },
  props: {
    value: {
      type: [Object, String]
    },
    type: {
      type: Array,
      required: false,
      default: () => []
    },
    checkPicked: {
      type: Boolean,
      default: () => true
    },
    workspaceTemplateOnly: {
      type: Boolean,
      default: () => false
    },
    returnId: {
      type: Boolean,
      default: () => false
    },
    maxHeight: {
      type: Number
    }
  },
  i18n,
  data () {
    return {
      templateOptionsType: this.type || 'all',
      workspaceFilter: 'all',
      categoryFilter: 'all',
      publicTemplateCategoryFilter: 'all',
      pickedItem: this.value ? this.value : undefined,
      tOptions: [
        { value: 'all', label: this.$t('tOption.all') },
        { value: 'project', label: this.$t('tOption.project') },
        { value: 'deliverable', label: this.$t('tOption.deliverable') },
        { value: 'task', label: this.$t('tOption.task') },
        { value: 'richDocument', label: this.$t('tOption.richDocument') },
        { value: 'storyboard', label: this.$t('tOption.storyboard') }
      ],
      templateCategory: 'internal'
    }
  },
  fetch () {
    this.templatesFetch()
  },
  computed: {
    ...mapGetters({
      templates: 'template/get',
      currentWorkspace: 'workspace/getCurrent'
    }),
    templatesByTypes () {
      return this.$_.filter(this.templates, t => this.templateOptionsType === 'all' ? t : this.templateOptionsType.includes(t.type))
    },
    internalTemplateOptions () {
      const internalTemplates = this.$_.filter(this.templatesByTypes, r => !r.isPublic)
      const res = this.$_.map(internalTemplates, (t) => {
        if (!t.category) {
          return Object.assign(t, { category: this.$t('uncategorized') })
        } else {
          return t
        }
      })
      return this.$_.groupBy(res, 'category')
    },
    filteredInternalTemplates () {
      return this.categoryFilter === 'all'
        ? this.internalTemplateOptions
        : this.$_.pick(this.internalTemplateOptions, this.categoryFilter)
    },
    publicTemplates () {
      return this.$_.filter(this.templatesByTypes, r => r.isPublic)
    },
    filteredPublicTemplates () {
      const res = this.$_.filter(this.publicTemplates, (template) => {
        const filteredByWorkspace = this.workspaceFilter !== 'all' ? template.workspace?._id === this.workspaceFilter : true
        const filteredByCategory = this.publicTemplateCategoryFilter !== 'all' ? template.category === this.publicTemplateCategoryFilter : true
        return filteredByWorkspace && filteredByCategory
      })
      return this.$_.groupBy(res, 'category')
    },
    categoriesOptions () {
      const o = this.$_.reduce(Object.keys(this.internalTemplateOptions), (acc, category) => {
        return [...acc, { value: category, label: category }]
      }, [])
      return this.$_.uniqWith([{ value: 'all', label: this.$t('tOption.all') }, ...o], this.$_.isEqual)
    },
    publicTemplateCategoriesOptions () {
      const o = this.$_.reduce(this.publicTemplates, (acc, template) => {
        return template.category ? [...acc, { value: template.category, label: this.$nuxt.$te(`deliverable.category.${template.category}`) ? this.$t('deliverable.category.' + template.category) : template.category }] : acc
      }, [])
      return this.$_.uniqWith([{ value: 'all', label: this.$t('tOption.all') }, ...o], this.$_.isEqual)
    },
    workspacesOptions () {
      const externalTemplates = this.$_.filter(this.templatesByTypes, r => r.isPublic)
      return this.$_.uniqWith([{ value: 'all', label: this.$t('tOption.all') }, ...this.$_.map(externalTemplates, t => ({ value: t.workspace?._id, label: t.workspace?.name }))], this.$_.isEqual)
    }
  },
  watch: {
    value (n) {
      this.pickedItem = n || undefined
    }
  },
  methods: {
    ...mapActions({
      templatesFetch: 'template/fetch'
    }),
    pick (item) {
      this.pickedItem = item
      this.$emit('input', this.returnId ? item._id : item)
    }
  }
}
</script>

<style scoped>
.categoryTitle {
    color: #00558a;
    font-size: 14px;
    font-weight: 600;
    padding: .6rem 0px;
    margin: 0.38rem 0 1rem 0;
    border-bottom: 2px solid #E1E1E4 !important;
  }
</style>
