<template>
  <section>
    <div class="background10 pa-4 width-100 edtk-rounded-md">
      <h5 v-if="!mutableModel.length && !editNewTheme" class="font-italic text-center">
        {{ $t('noData') }}
      </h5>
      <slot v-if="!editRight" name="default" :component-data="value">
        <div
          v-for="(t,$index) in mutableModel"
          :key="`${$index}_${t}`"
        >
          <div
            class="d-flex align-center my-3"
          >
            <div class="d-flex white pa-2 width-100">
              <div
                class="width-100 mr-2"
              >
                {{ mutableModel[$index] }}
              </div>
            </div>
          </div>
        </div>
      </slot>
      <section v-else>
        <draggable
          v-model="mutableModel"
          handle=".handleDragTheme"
          @end="emit(mutableModel)"
        >
          <div
            v-for="(t,$index) in mutableModel"
            :key="`${$index}_${t}`"
          >
            <v-hover v-slot="{ hover }">
              <div
                class="d-flex align-center my-3"
              >
                <i v-if="mutableModel.length > 1" class="mdi mdi-drag-vertical mr-1 brownish-grey--text blue--text-hover handle handleDragTheme" />
                <div class="d-flex white pa-2 width-100">
                  <div
                    :ref="`tagText-${$index}`"
                    :key="value[$index]"
                    :class="{'active': states[$index] === 'edit'}"
                    class="width-100 mr-2 hover-highlight"
                    contenteditable="true"
                    @click="states[$index] = 'edit'"
                    @blur="(e) => updateValue(e, $index)"
                    @keyup="(e) => inputKeyup(e, $index)"
                    @keypress="preventBreakLine"
                  >
                    {{ mutableModel[$index] }}
                  </div>
                  <v-icon v-show="hover" small class="brownish-grey--text dull-red--text-hover pointer" @click="removeTheme($index)">
                    mdi-trash-can-outline
                  </v-icon>
                </div>
              </div>
            </v-hover>
            <span
              v-if="formError && $v.mutableModel.$each.$iter[$index] && $v.mutableModel.$each.$iter[$index].$invalid"
              class="form_error"
            >
              {{ $t('errors.input.INVALID') }}
            </span>
          </div>
        </draggable>
        <div v-if="editNewTheme" class="my-3">
          <div class="d-flex align-center">
            <div class="d-flex white pa-2 width-100">
              <div
                ref="tagTextNewObj"
                class="mr-2 hover-highlight width-100"
                :class="{'active': newThemeEditState}"
                contenteditable="true"
                @blur="(e) => updateNewObjValue(e)"
                @click="() => newThemeEditState = true"
                @keypress="preventBreakLine"
              >
                {{ newTheme.text }}
              </div>
            </div>
            <edtkButton
              :label="$t('save')"
              type="filled"
              class="mx-2"
              small
              @click="addTheme"
            />
            <edtkButtonIcon
              icon="close"
              icon-size="4xl"
              color="secondary"
              @click="closeNewTheme"
            />
          </div>
          <span
            v-if="formError && $v.newTheme.$error"
            class="form_error"
          >
            {{ $t('errors.input.INVALID') }}
          </span>
        </div>
      </section>
    </div>
    <edtkButton
      v-if="editRight && !editNewTheme && underMaxLength"
      :label="`+ ${newRowLabel}`"
      type="text"
      x-small
      class="my-2"
      @click="toEditNewTheme"
    />
  </section>
</template>

<script>
import draggable from 'vuedraggable'
import { required } from 'vuelidate/lib/validators'
import i18n from './i18n.js'

export default {
  name: 'EditableThemesList',
  components: {
    draggable
  },
  props: {
    dataTest: {
      type: String
    },
    editRight: {
      type: Boolean,
      required: true,
      default: false
    },
    value: {
      type: Array,
      default: () => []
    },
    newRowLabel: {
      type: String,
      default () { return this.$t('newLine') }
    },
    max: {
      type: Number,
      default: () => null
    },
    displayCategories: {
      type: Boolean,
      default: true
    }
  },
  validations: {
    newTheme: { required },
    mutableModel: {
      $each: { required }
    }
  },
  data () {
    return {
      search: '',
      states: [],
      isOpen: false,
      mutableModel: [],
      editNewTheme: false,
      newTheme: '',
      newThemeEditState: false,
      formError: false
    }
  },
  i18n,
  computed: {
    classifiedValue () {
      if (this.useTaxonomyVerbs) {
        const res = []
        const r = this.$taxonomyHelper.classifyAll(this.value, this.taxonomySettings.verbMapping)
        r.forEach((i) => {
          if (i.bloomLabels.length) {
            const color = this.taxonomySettings.bloomColors[`level${i.bloomLabels[0].mainLevel}`]
            res.push({ verb: i.bloomLabels[0].verb, text: i.bloomLabels[0].text, color, mainLevel: i.bloomLabels[0].mainLevel })
          } else {
            res.push({ verb: null, text: i.inputString })
          }
        })
        return res
      } else {
        return []
      }
    },
    underMaxLength () {
      return this.max ? this.mutableModel.length < this.max : true
    }
  },
  watch: {
    value: {
      deep: true,
      handler () {
        this.mutableModel = this.value
      }
    },
    isOpen (n) {
      if (n) {
        this.search = ''
        setTimeout(function () {
          this.focus()
        }.bind(this), 150)
      }
    }
  },
  mounted () {
    this.mutableModel = this.value
  },
  methods: {
    close () {
      this.isOpen = false
      this.search = ''
    },
    closeNewTheme () {
      this.editNewTheme = false
      this.newTheme = ''
      this.newThemeEditState = false
      this.formError = false
      this.isOpen = false
    },
    emit (el) {
      this.$emit('input', el)
    },
    updateValue (e, i) {
      const inputValue = this.$_.trim(e.target.innerText)
      if (!inputValue) {
        this.removeTheme(i)
        return
      }
      this.states[i] = 'read'
      if (this.mutableModel[i] !== inputValue) {
        this.mutableModel[i] = inputValue
        this.emit(this.mutableModel)
      }
      this.isOpen = false
    },
    inputKeyup (e, i) {
      const isEnter = e.key === 'Enter' || e.keyCode === 13
      const isEscape = e.key === 'Escape' || e.keyCode === 27
      if (isEnter || isEscape) {
        this.states[i] = 'read'
        this.$nextTick(() => {
          this.$refs[`tagText-${i}`][0].blur()
        })
      }
    },
    preventBreakLine (e) {
      if (e.key === 'Enter' || e.keyCode === 13) {
        e.preventDefault()
      }
    },
    updateNewObjValue (item) {
      this.newTheme = item.target.innerText
      this.newThemeEditState = false
    },
    toEditNewTheme () {
      this.editNewTheme = true
      this.isOpen = 'newTheme'
      setTimeout(function () {
        this.focus()
      }.bind(this), 500)
    },
    addTheme () {
      this.$v.newTheme.$touch()
      if (this.$v.newTheme.$invalid) {
        this.formError = true
      } else {
        if (!this.mutableModel) { this.mutableModel = [] }
        this.mutableModel.push(this.newTheme)
        this.states[this.mutableModel.length - 1] = 'read'
        this.emit(this.mutableModel)
        this.closeNewTheme()
      }
    },
    removeTheme (i) {
      const r = this.$_.cloneDeep(this.mutableModel)
      r.splice(i, 1)
      this.mutableModel = r
      this.emit(this.mutableModel)
    },
    focus () {
      if (this.isOpen === 'newTheme') {
        this.$nextTick(() => this.$refs.tagTextNewObj.focus())
      } else if (typeof this.isOpen === 'number') {
        this.$nextTick(() => this.$refs[`searchInputBox-${this.isOpen}`][0].focus())
      }
    },
    focusTextNewObj () {
      this.$nextTick(() => this.$refs.tagTextNewObj.focus())
    }
  }
}
</script>
<style lang="scss" scoped>
.tooltip-target {
  min-width: max-content;
  width: max-content;
}
.hover-highlight {
  display: block;
  border-bottom: dashed transparent 2px;
  &:hover {
    cursor: text;
    border-bottom: dashed $blue 2px;
  }
  &.active {
    border-bottom: solid $blue 1px;
  }
}
.search-input {
  border: none;
}
.popover-section {
  margin: -5px;
}
.popover-list {
  padding: 0;
  max-height: 300px;
  overflow: auto;
}
.popover-header {
  max-height: 100px;
  padding: 0 12px;
}
.background10 {
  background-color: #00558a10;
}
.width-100 {
  width: 100%;
}
</style>
