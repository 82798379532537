const removePunctuation = (line) => {
  const regex = /[!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~]/g
  return line.replace(regex, '')
}

const numberOfWords = (script, toPronounce) => {
  return script.reduce((acc, curr) => {
    // if toPronounce = true > count only words pronounced by character and reject questions/dialogues without asset
    if (curr.type !== 'note' && (toPronounce ? curr.actionData.assetId : true)) {
      let payload = removePunctuation(curr.actionData.description)
      if (curr.type === 'question') {
        curr.actionData.options.forEach((o) => {
          if (o.label) {
            const optionLabel = removePunctuation(o.label)
            payload = payload.concat(' ', optionLabel)
          }
        })
      }
      const splitLine = payload.split(' ').filter(word => word !== '')
      return acc + splitLine.length
    } else {
      return acc
    }
  }, 0)
}

export default {
  removePunctuation,
  numberOfWords
}
