import { GrowthBook } from '@growthbook/growthbook'

export default ({ store }, inject) => {
  const ab = {
    initialized: false
  }

  let gb

  ab.init = async function () {
    if (!gb) {
      if (!store.state.remoteConfig.config) {
        return
        // throw new Error('Configuration not loaded')
      }
      if (!store.state.remoteConfig.config.AB_KEY) {
        return
        // throw new Error('No event log id found in configuration')
      }

      if (!store.state.remoteConfig.config.AB_HOST) {
        return
        // throw new Error('No event log id found in configuration')
      }

      gb = new GrowthBook({
        apiHost: store.state.remoteConfig.config.AB_HOST,
        clientKey: store.state.remoteConfig.config.AB_KEY,
        enableDevMode: true,
        attributes: {
          id: store.state.user.user._id,
          email: store.state.user.user.email || '',
          isEdtaker: store.state.user.user.isEdtaker || false,
          workspace: store.state.workspace.current ? store.state.workspace.current._id : undefined
        },
        trackingCallback: (experiment, result) => {
          /* console.log("Viewed Experiment", {
              experimentId: experiment.key,
              variationId: result.key
            }); */
        }
      })
      ab.initialized = true
    }

	  await gb.loadFeatures({ autoRefresh: true })
  }

  ab.isOn = function (feature) {
    if (!ab.initialized) {
      return false
    }

    gb.setAttributes({
      id: store.state.user.user._id,
      email: store.state.user.user.email || '',
      isEdtaker: store.state.user.user.isEdtaker || false,
      workspace: store.state.workspace.current ? store.state.workspace.current._id : undefined
    })

    return gb.isOn(feature)
  }

  ab.getFeature = function (feature) {
    if (!ab.initialized) {
      return false
    }

    gb.setAttributes({
      id: store.state.user.user._id,
      email: store.state.user.user.email || '',
      isEdtaker: store.state.user.user.isEdtaker || false,
      workspace: store.state.workspace.current ? store.state.workspace.current._id : undefined
    })

    return gb.getFeatureValue(feature)
  }

  ab.reset = async function () {
    if (!ab.initialized) {
      await ab.init()
    }

    gb.setAttributes({
      id: store.state.user.user._id,
      email: store.state.user.user.email || '',
      isEdtaker: store.state.user.user.isEdtaker || false,
      workspace: store.state.workspace.current ? store.state.workspace.current._id : undefined
    })

    return true
  }

  inject('ab', ab)
}
