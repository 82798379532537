const i18n = {
  messages: {
    fr: {
      title: 'Titre du document',
      closeWithoutSave: 'Fermer sans sauvegarder ?',
      close: 'Fermer',
      saveAndClose: 'Sauvegarder et fermer'
    },
    en: {
      title: 'Document title',
      closeWithoutSave: 'Close without saving ?',
      close: 'Close',
      saveAndClose: 'Save and close'
    }
  }
}
module.exports = i18n
