<template>
  <section class="edtk-navigation-container" style="z-index: 9997">
    <span
      class="edtk-navigation-toggler d-inline d-md-none material-symbols-rounded edtk-text-2xl edtk-text-secondary50"
      :class="{'edtk-navigation-toggler-mini': isDrawerOpen}"
      @click="isDrawerOpen = !isDrawerOpen"
    >{{ isDrawerOpen ? 'close' : 'menu' }}</span>
    <div :class="{'edtk-drawer-shifter': ['md', 'lg', 'xl', 'xxl', 'xxxl'].includes($mq), 'edtk-drawer-shifter-mini': isDrawerMinimized}"></div>
    <nav style="z-index: 9998" class="edtk-drawer" :class="{'edtk-drawer-open': isDrawerOpen || ['md', 'lg', 'xl', 'xxl', 'xxxl'].includes($mq), 'edtk-drawer-mini': isDrawerMinimized && !isDrawerMinimizedAndHovered}" @mouseenter="userHoverEnter()" @mouseleave="userHoverLeave()">
      <div class="edtk-drawer-top">
        <nuxt-link to="/" style="height: 23px;">
          <svg
            width="115"
            height="27"
            viewBox="0 4 115 23"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path class="edtk-drawer-nowrap" d="M74.9714 16.5631C74.9692 14.4653 74.3055 12.4213 73.0746 10.7209C71.8436 9.02052 70.1077 7.74985 68.1128 7.08896C66.118 6.42807 63.9653 6.41048 61.9599 7.03869C59.9544 7.6669 58.1979 8.90905 56.9392 10.5891C55.6805 12.2692 54.9835 14.302 54.9468 16.3995C54.9102 18.497 55.5358 20.5528 56.7351 22.2756C57.9344 23.9985 59.6465 25.301 61.6287 25.9985C63.611 26.6961 65.763 26.7534 67.7797 26.1623V20.7849C66.9428 21.3469 65.9567 21.6464 64.948 21.6452C62.1404 21.6452 59.8561 19.3652 59.8561 16.5631C59.8561 13.7609 62.1404 11.4813 64.948 11.4813C67.7556 11.4813 70.0397 13.7609 70.0397 16.5631C70.0397 16.6289 70.0385 16.6942 70.036 16.7591V25.1848L70.0397 25.1828V26.4871H74.9714V16.5631V16.5631Z" fill="#00629E" />
            <path class="edtk-drawer-nowrap" d="M87.531 6.55994L83.1836 12.8983V0.431641H78.252V26.568H83.1836V14.2018L87.8705 26.568H93.1425L88.388 14.0241L93.5075 6.55994H87.531Z" fill="#00629E" />
            <path class="edtk-drawer-nowrap" d="M114.732 14.2623C114.229 12.134 113.042 10.2284 111.353 8.83641C109.664 7.44442 107.564 6.64256 105.375 6.55328C103.186 6.464 101.028 7.0922 99.2303 8.34197C97.4327 9.59173 96.0943 11.3944 95.4195 13.4746C94.7447 15.5548 94.7706 17.7983 95.4932 19.8624C96.2159 21.9266 97.5956 23.6979 99.4216 24.906C101.248 26.1141 103.42 26.6925 105.606 26.5529C107.792 26.4132 109.873 25.5633 111.53 24.1328L108.634 20.0958C108.16 20.5864 107.592 20.9765 106.963 21.2429C106.335 21.5092 105.659 21.6463 104.976 21.646C102.168 21.646 99.8843 19.3661 99.8843 16.5639C99.8843 13.7618 102.168 11.4821 104.976 11.4821C105.915 11.4832 106.836 11.7434 107.637 12.234C108.438 12.7246 109.087 13.4266 109.513 14.2623H105.415C104.905 14.2623 104.417 14.4643 104.057 14.8238C103.696 15.1834 103.494 15.6711 103.494 16.1796C103.494 16.6881 103.696 17.1758 104.057 17.5354C104.417 17.8949 104.905 18.0969 105.415 18.0969H113.465C113.872 18.0969 114.261 17.9358 114.549 17.6491C114.836 17.3625 114.998 16.9737 114.998 16.5682V16.5639C114.999 15.789 114.909 15.0166 114.732 14.2623V14.2623Z" fill="#00629E" />
            <path class="edtk-drawer-nowrap" d="M49.0391 0.431641H44.1074V19.0452C44.1074 20.0331 44.3024 21.0113 44.6812 21.9241C45.06 22.8368 45.6152 23.6661 46.3151 24.3646C47.015 25.0632 47.8459 25.6173 48.7604 25.9954C49.6749 26.3734 50.655 26.568 51.6449 26.568V21.6459C50.9541 21.645 50.2918 21.3707 49.8033 20.8832C49.3148 20.3956 49.04 19.7347 49.0391 19.0452V13.9583H51.6449V9.03616H49.0391V0.431641Z" fill="#00629E" />
            <path class="edtk-drawer-nowrap" d="M40.8273 0.431641H35.8956V16.5638C35.8956 16.6436 35.8938 16.7228 35.8901 16.8016C35.7651 19.4939 33.5315 21.6459 30.8039 21.6459C27.996 21.6459 25.7119 19.366 25.7119 16.5638C25.7119 13.7617 27.996 11.482 30.8039 11.482C31.8143 11.4808 32.8019 11.7814 33.6396 12.3451V6.96516C31.6191 6.36911 29.4617 6.42322 27.4737 7.11979C25.4857 7.81637 23.7679 9.12005 22.5641 10.8459C21.3603 12.5717 20.7315 14.6321 20.7671 16.7347C20.8026 18.8372 21.5005 20.8753 22.762 22.5596C24.0234 24.244 25.7842 25.4891 27.7946 26.1184C29.805 26.7477 31.9629 26.7292 33.9622 26.0656C35.9615 25.4019 37.7006 24.1267 38.9329 22.421C40.1651 20.7153 40.8279 18.6656 40.8273 16.5627V0.431641Z" fill="#00629E" />
            <path d="M13.3987 20.3702C12.468 21.1933 11.2674 21.6475 10.0239 21.6468C7.21629 21.6468 4.93195 19.3669 4.93195 16.5647C4.93195 13.7626 7.21629 11.4829 10.0239 11.4829C11.2674 11.4821 12.4681 11.9363 13.3987 12.7595C13.8881 13.1925 14.5299 13.4136 15.1827 13.3743C15.5059 13.3548 15.8221 13.272 16.1133 13.1305C16.4045 12.9891 16.6649 12.7918 16.8797 12.5499C17.0944 12.308 17.2594 12.0263 17.3651 11.7208C17.4707 11.4153 17.5151 11.092 17.4956 10.7694C17.4761 10.4468 17.3931 10.1312 17.2514 9.84056C17.1097 9.54995 16.912 9.29005 16.6696 9.07569C15.2242 7.79797 13.4398 6.96409 11.5309 6.67431C9.62206 6.38452 7.66994 6.65116 5.90926 7.44217C4.14858 8.23318 2.65427 9.51489 1.60597 11.1332C0.557669 12.7516 0 14.6376 0 16.5647C0 18.4918 0.557669 20.3779 1.60597 21.9962C2.65427 23.6146 4.14858 24.8963 5.90926 25.6873C7.66994 26.4783 9.62206 26.7449 11.5309 26.4551C13.4398 26.1654 15.2242 25.3315 16.6696 24.0538C16.914 23.84 17.1137 23.5801 17.2571 23.2891C17.4005 22.998 17.4849 22.6816 17.5054 22.3579C17.5258 22.0342 17.482 21.7096 17.3763 21.4029C17.2707 21.0962 17.1054 20.8133 16.8898 20.5706C16.6743 20.3278 16.4128 20.13 16.1204 19.9885C15.828 19.847 15.5104 19.7645 15.186 19.7459C14.8616 19.7273 14.5366 19.7729 14.2299 19.88C13.9232 19.9871 13.6407 20.1537 13.3987 20.3702Z" fill="#00629E" />
            <path d="M15.5896 14.6474H11.9533C11.4438 14.6474 10.9552 14.8494 10.5949 15.209C10.2346 15.5685 10.0322 16.0562 10.0322 16.5647C10.0322 17.0732 10.2346 17.5609 10.5949 17.9205C10.9552 18.2801 11.4438 18.4821 11.9533 18.4821H15.5896C16.099 18.4821 16.5877 18.2801 16.9479 17.9205C17.3082 17.5609 17.5106 17.0732 17.5106 16.5647C17.5106 16.0562 17.3082 15.5685 16.9479 15.209C16.5877 14.8494 16.099 14.6474 15.5896 14.6474Z" fill="#00629E" />
          </svg>
        </nuxt-link>
      </div>
      <div class="edtk-drawer-user px-2 py-4">
        <div class="edtk-drawer-user-menu d-flex align-center px-2 mt-2">
          <Avatar :user="user" :size="24" />
          <edtk-tooltip :content="`${user.firstname} ${user.lastname}`" target-selector=".edtk-drawer-nowrap" class="menu-title">
            <h3 class="edtk-title-small edtk-drawer-nowrap">
              {{ user.firstname }} {{ user.lastname }}
            </h3>
          </edtk-tooltip>
          <edtkDropdownButton
            class="ml-auto edtk-mini-hide"
            :items="userDropDownItems"
            icon="more_horiz"
            icon-size="2xl"
            :disabled="false"
            @select="selectOption"
          />
        </div>
        <div class="edtk-drawer-user-list d-flex flex-column mt-3">
          <nuxt-link
            :to="`/`"
            class="edtk-drawer-user-list-item d-flex align-center mb-1 px-2"
          >
            <edtk-icon icon="home" icon-color="secondary-50" icon-size="2xl" class="flex-shrink-0" />
            <h4 class="edtk-body-medium edtk-drawer-nowrap">
              {{ $t('menu.home') }}
            </h4>
          </nuxt-link>
          <nuxt-link
            v-if="isEdtaker"
            :to="`/proposal`"
            class="edtk-drawer-user-list-item d-flex align-center mb-1 px-2"
            @click="selectApp('proposal')"
          >
            <edtk-icon icon="card_travel" icon-color="secondary-50" icon-size="2xl" class="flex-shrink-0" />
            <h4 class="edtk-body-medium edtk-drawer-nowrap">
              {{ $t('menu.myMissions') }}
            </h4>
          </nuxt-link>
          <nuxt-link
            v-if="false"
            :to="`/shared-with-me`"
            class="edtk-drawer-user-list-item d-flex align-center mb-1 px-2"
          >
            <edtk-icon icon="groups" icon-color="secondary-50" icon-size="2xl" class="flex-shrink-0" />
            <h4 class="edtk-body-medium edtk-drawer-nowrap">
              {{ $t('menu.sharedWithMe') }}
            </h4>
          </nuxt-link>
        </div>
      </div>
      <div v-if="currentWorkspace" class="edtk-drawer-workspace px-2 py-4">
        <div class="edtk-drawer-workspace-menu d-flex align-center px-2 mt-2">
          <AvatarWorkspace :workspace="currentWorkspace" :size="24" />
          <edtk-tooltip :content="currentWorkspace?.name" target-selector=".edtk-drawer-nowrap" class="menu-title">
            <h3 class="edtk-title-small edtk-drawer-nowrap">
              {{ currentWorkspace?.name }}
            </h3>
          </edtk-tooltip>
          <edtkDropdownButton
            v-if="workspaceDropDownItems.length"
            class="ml-auto edtk-mini-hide"
            :items="workspaceDropDownItems"
            icon="more_horiz"
            icon-size="2xl"
            :disabled="false"
            @select="selectOption"
          />
        </div>
        <div class="edtk-drawer-workspace-list d-flex flex-column mt-3">
          <nuxt-link class="edtk-drawer-workspace-list-item d-flex align-center mb-1 px-2" :to="`/${$enums.routePrefix.workspace}/${currentWorkspaceId}`">
            <edtk-icon icon="dashboard" icon-color="secondary-50" icon-size="2xl" class="flex-shrink-0" />
            <h4 class="edtk-body-medium edtk-drawer-nowrap">
              {{ $t('menu.dashboard') }}
            </h4>
          </nuxt-link>
          <nuxt-link
            v-if="false"
            :to="`/${$enums.routePrefix.workspace}/${currentWorkspaceId}/favorites`"
            class="edtk-drawer-workspace-list-item d-flex align-center mb-1 px-2"
          >
            <edtk-icon icon="star" icon-color="secondary-50" icon-size="2xl" class="flex-shrink-0" />
            <h4 class="edtk-body-medium edtk-drawer-nowrap">
              {{ $t('menu.favorites') }}
            </h4>
          </nuxt-link>
        </div>
        <div class="edtk-drawer-app-list d-flex flex-column mt-3">
          <div
            v-if="currentWorkspace.hasAccessToLearningDesign"
            class="edtk-drawer-app-list-item d-flex align-center mb-1 px-2"
            @click="selectApp('ld')"
          >
            <edtk-icon icon="home_storage" icon-color="navigation-one" icon-size="2xl" container-color="navigation-one-15" class="flex-shrink-0" />
            <h4 class="edtk-body-medium edtk-drawer-nowrap">
              {{ $t('menu.catalog') }}
            </h4>
            <edtk-icon
              class="edtk-drawer-app-list-arrow ml-auto d-none edtk-mini-hide"
              :class="{'edtk-drawer-app-list-arrow-open': selectedApp === 'ld'}"
              icon="expand_more"
              icon-color="secondary-50"
              icon-size="2xl"
            />
          </div>
          <section v-if="currentWorkspace.hasAccessToLearningDesign && selectedApp === 'ld'" class="edtk-drawer-app-list-item-sub d-flex flex-column mb-1">
            <nuxt-link
              v-if="settingsLearningDesign.activateMechanismsManagement"
              :to="`/${$enums.routePrefix.workspace}/${currentWorkspaceId}/${$enums.appPrefix.learningDesign}/mechanism`"
              class="edtk-drawer-app-list-item d-flex align-center mb-1 px-2"
              :class="{'px-9': !isDrawerMinimized || isDrawerMinimizedAndHovered, 'active-page-link': activePageName === 'mechanism'}"
            >
              <edtk-icon icon="snippet_folder" icon-color="secondary-50" icon-size="2xl" class="flex-shrink-0" />
              <h4 class="edtk-body-medium edtk-drawer-nowrap">
                {{ $t('menu.mechanisms') }}
              </h4>
            </nuxt-link>
            <nuxt-link
              v-if="settingsLearningDesign.activateCoursesManagement"
              :to="`/${$enums.routePrefix.workspace}/${currentWorkspaceId}/${$enums.appPrefix.learningDesign}/course`"
              class="edtk-drawer-app-list-item d-flex align-center mb-1 px-2"
              :class="{'px-9': !isDrawerMinimized || isDrawerMinimizedAndHovered, 'active-page-link': activePageName === 'course'}"
            >
              <edtk-icon icon="conversion_path" icon-color="secondary-50" icon-size="2xl" class="flex-shrink-0" />
              <h4 class="edtk-body-medium edtk-drawer-nowrap">
                {{ $t('menu.courses') }}
              </h4>
            </nuxt-link>
            <nuxt-link
              :to="`/${$enums.routePrefix.workspace}/${currentWorkspaceId}/${$enums.appPrefix.learningDesign}/module`"
              class="edtk-drawer-app-list-item d-flex align-center mb-1 px-2"
              :class="{'px-9': !isDrawerMinimized || isDrawerMinimizedAndHovered, 'active-page-link': activePageName === 'module'}"
            >
              <edtk-icon icon="inventory_2" icon-color="secondary-50" icon-size="2xl" class="flex-shrink-0" />
              <h4 class="edtk-body-medium edtk-drawer-nowrap">
                {{ $t('menu.modules') }}
              </h4>
            </nuxt-link>
            <!-- <nuxt-link
              :to="`/${$enums.routePrefix.workspace}/${currentWorkspaceId}/${$enums.appPrefix.learningDesign}/sequence`"
              class="edtk-drawer-app-list-item d-flex align-center mb-1 px-2"
              :class="{'px-9': !isDrawerMinimized || isDrawerMinimizedAndHovered, 'active-page-link': activePageName === 'sequence'}"
            >
              <edtk-icon icon="widgets" icon-color="secondary-50" icon-size="2xl" class="flex-shrink-0" />
              <h4 class="edtk-body-medium edtk-drawer-nowrap">
                {{ $t('menu.sequences') }}
              </h4>
            </nuxt-link> -->
          </section>
          <div
            class="edtk-drawer-app-list-item d-flex align-center mb-1 px-2"
            @click="selectApp('pm')"
          >
            <edtk-icon icon="view_timeline" icon-color="navigation-two" icon-size="2xl" container-color="navigation-two-15" class="flex-shrink-0" />
            <h4 class="edtk-body-medium edtk-drawer-nowrap">
              {{ $t('menu.projectManagement') }}
            </h4>
            <edtk-icon
              class="edtk-drawer-app-list-arrow ml-auto d-none edtk-mini-hide"
              :class="{'edtk-drawer-app-list-arrow-open': selectedApp === 'pm'}"
              icon="expand_more"
              icon-color="secondary-50"
              icon-size="2xl"
            />
          </div>
          <section v-if="selectedApp === 'pm'" class="edtk-drawer-app-list-item-sub d-flex flex-column mb-1">
            <nuxt-link
              :to="`/${$enums.routePrefix.workspace}/${currentWorkspaceId}/${$enums.appPrefix.projectManagement}/project`"
              class="edtk-drawer-app-list-item d-flex align-center mb-1 px-2"
              :class="{'px-9': !isDrawerMinimized || isDrawerMinimizedAndHovered, 'active-page-link': activePageName === 'project'}"
            >
              <edtk-icon icon="contract" icon-color="secondary-50" icon-size="2xl" class="flex-shrink-0" />
              <h4 class="edtk-body-medium edtk-drawer-nowrap">
                {{ $t('menu.myProjects') }}
              </h4>
            </nuxt-link>
            <nuxt-link
              v-if="!isGuest"
              :to="`/${$enums.routePrefix.workspace}/${currentWorkspaceId}/${$enums.appPrefix.projectManagement}/customer`"
              class="edtk-drawer-app-list-item d-flex align-center mb-1 px-2"
              :class="{'px-9': !isDrawerMinimized || isDrawerMinimizedAndHovered, 'active-page-link': activePageName === 'customer'}"
            >
              <edtk-icon icon="handshake" icon-color="secondary-50" icon-size="2xl" class="flex-shrink-0" />
              <h4 class="edtk-body-medium edtk-drawer-nowrap">
                {{ $t('menu.myWorkspaceCustomers') }}
              </h4>
            </nuxt-link>
          </section>
          <div
            class="edtk-drawer-app-list-item d-flex align-center mb-1 px-2"
            @click="selectApp('mp')"
          >
            <edtk-icon icon="storefront" icon-color="navigation-three" icon-size="2xl" container-color="navigation-three-15" class="flex-shrink-0" />
            <h4 class="edtk-body-medium edtk-drawer-nowrap">
              {{ $t('menu.marketplace') }}
            </h4>
            <edtk-icon
              class="edtk-drawer-app-list-arrow ml-auto d-none edtk-mini-hide"
              :class="{'edtk-drawer-app-list-arrow-open': selectedApp === 'mp'}"
              icon="expand_more"
              icon-color="secondary-50"
              icon-size="2xl"
            />
          </div>
          <section v-if="selectedApp === 'mp'" class="edtk-drawer-app-list-item-sub d-flex flex-column mb-1">
            <nuxt-link
              :to="`/${$enums.routePrefix.workspace}/${currentWorkspaceId}/${$enums.appPrefix.marketplace}/mission`"
              class="edtk-drawer-app-list-item d-flex align-center mb-1 px-2"
              :class="{'px-9': !isDrawerMinimized || isDrawerMinimizedAndHovered, 'active-page-link': activePageName === 'mission'}"
            >
              <edtk-icon icon="assignment" icon-color="secondary-50" icon-size="2xl" class="flex-shrink-0" />
              <h4 class="edtk-body-medium edtk-drawer-nowrap">
                {{ $t('menu.missions') }}
              </h4>
            </nuxt-link>
            <nuxt-link
              :to="`/${$enums.routePrefix.workspace}/${currentWorkspaceId}/${$enums.appPrefix.marketplace}/favorite-edtaker`"
              class="edtk-drawer-app-list-item d-flex align-center mb-1 px-2"
              :class="{'px-9': !isDrawerMinimized || isDrawerMinimizedAndHovered, 'active-page-link': activePageName === 'favorite-edtaker'}"
            >
              <edtk-icon icon="diversity_1" icon-color="secondary-50" icon-size="2xl" class="flex-shrink-0" />
              <h4 class="edtk-body-medium edtk-drawer-nowrap">
                {{ $t('menu.favoriteEdtaker') }}
              </h4>
            </nuxt-link>
          </section>
        </div>
      </div>
      <div v-else-if="noWorkspace" class="px-2 py-4"></div>
      <div v-else class="edtk-drawer-workspace px-2 py-4">
        <div class="d-flex mb-1 px-2" style="height: 32px;">
          <v-skeleton-loader type="heading" width="54"></v-skeleton-loader>
          <v-skeleton-loader type="text" width="100" style="top: 7px;" class="mr-4"></v-skeleton-loader>
        </div>
        <div class="d-flex mb-1 px-2" style="height: 32px;">
          <v-skeleton-loader type="heading" width="54"></v-skeleton-loader>
          <v-skeleton-loader type="text" width="100" style="top: 7px;" class="mr-4"></v-skeleton-loader>
        </div>
        <div class="d-flex mb-1 px-2" style="height: 32px;">
          <v-skeleton-loader type="heading" width="54"></v-skeleton-loader>
          <v-skeleton-loader type="text" width="100" style="top: 7px;" class="mr-4"></v-skeleton-loader>
        </div>
        <div class="d-flex mb-1 px-2" style="height: 32px;">
          <v-skeleton-loader type="heading" width="54"></v-skeleton-loader>
          <v-skeleton-loader type="text" width="100" style="top: 7px;" class="mr-4"></v-skeleton-loader>
        </div>
      </div>
      <div
        v-if="currentWorkspace?.license?.subscriptionStatus === $enums.stripe.subscriptionStatus.trialing"
        class="edtk-buy-cta px-4 pb-4"
      >
        <span class="edtk-body-medium edtk-text-secondary40">
          {{ $t('trialing', {days: -$dayjs().diff(currentWorkspace.license.endDate, 'day')}) }}
        </span>
        <edtk-button
          class="edtk-label-medium"
          icon="add"
          :href="`/${$enums.routePrefix.workspace}/${currentWorkspaceId}/subscribe`"
          target="_self"
          small
          full-width
        >
          {{ $t('startSubscription') }}
        </edtk-button>
      </div>
      <!--div class="edtk-drawer-help px-2 mt-auto">
        <div class="edtk-drawer-help-list d-flex flex-column my-2">
          <div class="edtk-drawer-help-list-item d-flex align-center mb-1 px-2" @click="$nuxt.$emit('openResourceCenter')">
            <edtk-icon icon="help" icon-color="secondary-50" icon-size="2xl" class="flex-shrink-0" />
            <h4 class="edtk-body-medium edtk-drawer-nowrap">
              {{ $t('menu.help') }}
            </h4>
          </div>
        </div>
      </div-->
    </nav>
    <main class="w-100" :class="{'edtk-drawer-backdrop': isDrawerOpen && ['xs', 'sm'].includes($mq)}">
      <nav class="edtk-navigation" :class="{'edtk-navigation-large': ['md', 'lg', 'xl', 'xxl', 'xxxl'].includes($mq), 'edtk-navigation-drawer-mini': isDrawerMinimized}">
        <Breadcrumb class="mr-auto" />
        <MagicBoxAlgolia class="d-none d-sm-flex" />
        <edtk-icon v-show="false" class="pointer" icon="notifications" icon-color="neutral-60" icon-size="2xl" />
      </nav>
      <div class="main-container" :class="{'edtk-drawer-mini': isDrawerMinimized}">
        <slot name="default">
        </slot>
      </div>
    </main>
    <!--ResourceCenterDialog /-->
  </section>
</template>

<script lang="js">
import { mapGetters, mapActions } from 'vuex'
import i18n from './i18n.js'
// import InstallButton from '~/components/partials/InstallButton'
import Breadcrumb from '~/components/common/Breadcrumb'
import MagicBoxAlgolia from '~/components/widget/MagicBoxAlgolia'

export default {
  name: 'Navigation',
  components: {
    // InstallButton,
    Breadcrumb,
    MagicBoxAlgolia
  },
  props: {
    noNews: {
      type: Boolean,
      default: false
    }
  },
  i18n,
  data () {
    return {
      notificationsCount: 0,
      isDrawerOpen: false,
      isDrawerMinimizedAndHovered: false,
      selectedApp: this.$edtkRouter.calculateApp(this.$route),
      activePageName: this.$edtkRouter.getPageNameFromRoutePath(this.$route.fullPath)
    }
  },
  computed: {
    ...mapGetters({
      user: 'user/get',
      workspaces: 'workspace/getWorkspaces',
      currentWorkspace: 'workspace/getCurrent',
      noWorkspace: 'workspace/getNoWorkspace',
      userLang: 'user/getLocale',
      isEdtaker: 'user/isEdtaker',
      isGuest: 'user/isGuest',
      userSettings: 'userSettings/get',
      settingsLearningDesign: 'settings/getSettingsLearningDesign'
    }),
    currentWorkspaceId () {
      return this.currentWorkspace?._id
    },
    isDrawerMinimized () {
      return ['md', 'lg', 'xl'].includes(this.$mq)
    },
    workspaceDropDownItems () {
      let items = []
      const workspacesNotCurrent = this.workspaces.filter(workspace => workspace._id.toString() !== this.currentWorkspace._id.toString() && workspace.license.subscriptionStatus !== this.$enums.stripe.subscriptionStatus.inactive)
      if (workspacesNotCurrent.length) {
        const workspaces = workspacesNotCurrent.map(workspace => ({ text: `&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;${workspace.name}`, value: `/${this.$enums.routePrefix.workspace}/${workspace._id}` }))
        items.push({ text: this.$t('selectWorkspace'), value: `/${this.$enums.routePrefix.workspace}/${this.currentWorkspaceId}`, disabled: true })
        items = items.concat(workspaces)
      }
      if (this.$userRightsHelper.hasFeatureAccess('workspaceConfigurationAccess')) {
        items.push({ separator: true })
        items.push({ text: this.$t('workspace.configuration'), value: `/${this.$enums.routePrefix.workspace}/${this.currentWorkspaceId}/configuration` })
      }
      return items
    },
    userDropDownItems () {
      let items = [{ text: this.$t('workspace.configuration'), value: '/account' }]
      items = items.concat({ separator: true })
      items = items.concat({ text: this.$t('disconnect'), value: '/logout' })
      return items
    }
  },
  watch: {
    $route (n) {
      this.activePageName = this.$edtkRouter.getPageNameFromRoutePath(n.fullPath)
    }
  },
  methods: {
    ...mapActions({
      academyToken: 'user/academyToken',
      setUserSettings: 'userSettings/set'
    }),
    selectApp (app) {
      this.selectedApp = this.selectedApp === app ? false : app
    },
    userHoverEnter () {
      if (this.isDrawerMinimized) {
        this.isDrawerMinimizedAndHovered = true
      }
    },
    userHoverLeave () {
      if (this.isDrawerMinimized) {
        this.isDrawerMinimizedAndHovered = false
      }
    },
    selectOption (path) {
      this.$router.push(path)
    },
    goToNeo () {
      window.open(
        'https://app.edtake.ai/',
        '_blank'
      )
    },
    async changeLang (lang) {
      // mutate 'locale' in store
      if (lang !== this.userLang) {
        await this.$store.dispatch('user/updateProfile', { lang })
        this.$nextTick(() => window.location.reload())
      }
    },
    handleCommand (c) {
      switch (c) {
        case 'profile':
          this.$router.push('/account')
          break
        case 'company':
          this.$router.push('/company/management')
          break
        case 'academy':
          this.goToAcademy()
          break
        case 'disconnect':
          this.disconnect()
          break
        default:

          break
      }
    },
    async goToAcademy () {
      const tok = await this.academyToken()
      if (tok) {
        window.open(
          'https://edtake-academy.edflex.com/?bearer=' + tok,
          '_blank'
        )
      } else {
        this.$swal({
          icon: 'error',
          title: this.$t('errors.OUPS'),
          html: this.$t('errors.NO_ACADEMY_ACCESS')
        })
      }
    },
    disconnect () {
      this.$router.push('/logout')
    }
  }
}
</script>
<style lang="scss" scoped>
$sidebarWidth: 260px;
$sidebarWidthMini: 56px;
$topbarHeight: 56px;
$transitionDefaults: .3s cubic-bezier(0.2, 0, 0, 1) 0s;

.edtk-navigation-container {
  position: relative;
  align-items: stretch;
  display: flex;
  flex: 1 1 0;
  height: 100%;

  h3 {
    color: var(--neutral-20)
  }

  h4 {
    color: var(--secondary-40)
  }

  .edtk-navigation-toggler {
    cursor: pointer;
    position: fixed;
    left: 32px;
    top: 18px;
    z-index: 9999;
    transition: left $transitionDefaults;

    &-mini {
      left: calc(#{$sidebarWidth} - 42px);
    }
  }

  .edtk-navigation {
    position: fixed;
    z-index: 9997;
    width: 100%;
    height: $topbarHeight;
    display: flex;
    align-items: center;
    padding: 0 32px 0 64px;
    background: var(--primary-100);
    border-bottom: solid 1px var(--neutral-90);
    transition: width $transitionDefaults;

    + .main-container {
      position: relative;
      top: $topbarHeight;
      overflow-x: hidden;
      scrollbar-gutter: stable both-edges;
      height: calc(100vh - #{$topbarHeight});
      width: 100vw;
      transition: width $transitionDefaults;

      &.edtk-drawer-mini {
        width: calc(100vw - #{$sidebarWidthMini});
      }
    }

    &-large {
      width: calc(100% - #{$sidebarWidth});
      padding: 0 32px;

      + .main-container {
        width: calc(100vw - #{$sidebarWidth});
      }
    }

    &-drawer-mini {
      width: calc(100% - #{$sidebarWidthMini});
    }
  }

  .edtk-drawer {
    display: flex;
    flex-flow: column nowrap;
    position: fixed;
    overflow: hidden;
    left: 0;
    top: 0;
    width: 0px;
    height: 100%;
    background-color: var(--primary-99);
    border-right: solid 1px var(--neutral-90);
    transition: width $transitionDefaults;

    + main {
      &.edtk-drawer-backdrop::after {
        content: '';
        position: fixed;
        left: $sidebarWidth;
        top: 0px;
        width: 100%;
        height: 100%;
        background-color: rgba(0,0,0,0.5);
      }
    }

    .edtk-drawer-nowrap {
      white-space: nowrap;
      margin-left: 8px;
      margin-right: 8px;
      transition: opacity $transitionDefaults;
    }

    &-open {
      width: $sidebarWidth;
    }

    &-mini {
      width: $sidebarWidthMini;

      .edtk-mini-hide {
        display: none !important;
      }

      .edtk-buy-cta {
        opacity: 0;
        transition-delay: 0s;
        max-height: 10px;
      }

      .edtk-drawer-nowrap {
        opacity: 0;
      }
    }

    .edtk-drawer-top {
      height: $topbarHeight;
      display: flex;
      align-items: center;
      position: relative;
      border-bottom: solid 1px var(--neutral-90);
      padding-left: 20px;
      flex: 0 0 auto;
    }

    .edtk-drawer-user {
      border-bottom: solid 1px var(--neutral-90);

      &-menu {
        height: 32px;

        h3,
        .menu-title {
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }
      }

      &-list {
        &-item {
          cursor: pointer;
          border-radius: 4px;
          height: 32px;
          color: inherit;

          &.exact-active-link {
            background-color: var(--primary-95);

            h4 {
              color: var(--primary-40);
              font-weight: 600;
            }

            span {
              color: var(--primary-50) !important;
            }
          }

          &:hover {
            background-color: var(--neutral-95);
          }
        }
      }
    }

    .edtk-drawer-workspace {
      flex: 1 1 auto;
      overflow-x: hidden;
      overflow-y: auto;
      -webkit-scrollbar-width: none;
      -moz-scrollbar-width: none;
      -ms-scrollbar-width: none;
      scrollbar-width: none;
      -webkit-backface-visibility: hidden;
      backface-visibility: hidden;

      &::-webkit-scrollbar {
        width: 0 !important;
      };

      &-menu {
        height: 32px;

        h3,
        .menu-title {
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }
      }

      &-list {
        &-item {
          cursor: pointer;
          border-radius: 4px;
          height: 32px;
          color: inherit;

          &.exact-active-link {
            background-color: var(--primary-95);

            h4 {
              color: var(--primary-40);
              font-weight: 600;
            }

            span {
              color: var(--primary-50) !important;
            }
          }

          &:hover {
            background-color: var(--neutral-95);
          }
        }
      }
    }

    .edtk-drawer-help {
      border-top: solid 1px var(--neutral-90);

      &-menu {
        cursor: pointer;
      }

      &-list {
        &-item {
          cursor: pointer;
          border-radius: 4px;
          height: 32px;
          color: inherit;

          &.exact-active-link {
            background-color: var(--primary-95);

            h4 {
              color: var(--primary-40);
              font-weight: 600;
            }

            span {
              color: var(--primary-50) !important;
            }
          }

          &:hover {
            background-color: var(--neutral-95);
          }
        }
      }
    }

    .edtk-drawer-app {
      &-list {
        >.edtk-drawer-app-list-item {
          &:nth-of-type(1) {

            +.edtk-drawer-app-list-item-sub {
              .edtk-drawer-app-list-item.active-page-link {
                background-color: $navigation-one-15;
              }
            }
          }

          &:nth-of-type(2) {
            +.edtk-drawer-app-list-item-sub {
              .edtk-drawer-app-list-item.active-page-link {
                background-color: $navigation-two-15;
              }
            }
          }

          &:nth-of-type(3) {
            +.edtk-drawer-app-list-item-sub {
              .edtk-drawer-app-list-item.active-page-link {
                background-color: $navigation-three-15;
              }
            }
          }
        }

        &-item {
          cursor: pointer;
          border-radius: 4px;
          height: 32px;
          color: inherit;

          &-sub {
            .edtk-drawer-app-list-item {
              transition: padding-left $transitionDefaults, padding-right $transitionDefaults;

              &:hover {
                background-color: var(--neutral-95);
              }
            }
          }

          &:hover {
            background-color: var(--neutral-95);

            .edtk-drawer-app-list-arrow {
              display: flex !important;
              transition: transform $transitionDefaults;

              &-open {
                transform: rotate(180deg);
              }
            }
          }
        }
      }
    }
  }

  .edtk-drawer-shifter {
    flex: 0 0 auto;
    width: $sidebarWidth;
    height: 100%;
    transition: width $transitionDefaults;

    &-mini {
      width: $sidebarWidthMini;
    }
  }

  .edtk-buy-cta {
    display: flex;
    padding: 0px 8px;
    flex-direction: column;
    align-items: flex-start;
    max-height: 100px;
    gap: 8px;
    align-self: stretch;
    transition-delay: 0.2s;
  }
}

</style>
