<template>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'FormErrorDialog',
  data () {
    return {

    }
  },
  computed: {
    ...mapGetters({
      errors: 'errorForm/getErrorList'
    })
  },
  watch: {
    errors (n) {
      if (n.length > 0) { this.triggerError() }
    }
  },
  methods: {
    triggerError () {
      const stringifyTab = this.errors.toString().replace(/,/gm, '</br>')
      this.$swal({
        icon: 'error',
        title: this.$t('errors.OUPS'),
        html: '<p style="text-align: left; ">' + stringifyTab + '</p>'
      })
    }
  }
}
</script>
