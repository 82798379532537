<template>
  <v-dialog
    v-model="dialog"
    width="500"
  >
    <template #activator="{ on, attrs }">
      <edtk-button
        type="outline"
        small
      >
        <span
          v-bind="attrs"
          v-on="on"
        >
          {{ $t('diagram') }}
        </span>
      </edtk-button>
    </template>
    <div>
      <v-card class=" relative pa-0 ma-0" height="90vh" width="90vw">
        <edtk-button-icon
          icon="close"
          icon-size="3xl"
          color="secondary"
          style="position: absolute; top: 10px; right: 10px; z-index: 1000;"
          @click="dialog = false"
        ></edtk-button-icon>
        <Network v-if="dialog" :scenes="scenes" />
      </v-card>
    </div>
  </v-dialog>
</template>

<script>
import Network from './index.vue'
export default {
  components: { Network },
  props: {
    scenes: {
      type: Array,
      default: () => []
    }
  },
  data () {
    return {
      dialog: false
    }
  },
  i18n: {
    messages: {
      fr: {
        diagram: 'Diagramme'
      },
      en: {
        diagram: 'Diagram'
      }
    }
  }
}
</script>
