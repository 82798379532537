<template>
  <v-hover v-slot="{ hover }" class="result-item d-flex align-center py-4 px-6">
    <div @click="openItem(item)">
      <div>
        <v-tooltip top>
          <template #activator="{ on, attrs }">
            <edtk-icon
              :icon="filter ? $enums.materialIcons[filter] : edtakeDocumentTypeIcons[item.edtakeDocumentType]"
              icon-size="3xl"
              :icon-color="hover ? 'primary' : 'brownish-grey'"
              class="pointer ml-auto mr-4"
              v-bind="attrs"
              v-on="on"
            />
          </template>
          <span>{{ $t(`objectType.${filter || item.edtakeDocumentType}`) }}</span>
        </v-tooltip>
      </div>
      <div class="flex-grow-1 overflow-hidden">
        <span class="item-title text-h5 brownish-gray--text" :class="{hover: 'primary--text'}">{{ item.name || item.title }}</span>
        <div v-if="item.edtakeDocumentType === 'mission'">
          <v-chip
            x-small
            label
            color="brownish-grey"
            class="font-weight-bold tooltip-target"
            outlined
            elevation="0"
          >
            {{ $t(`mission.status.${item.status}`) }}
          </v-chip>
        </div>
        <StatusButton
          v-else
          x-small
          :type="item.__t"
          :status="item.status"
          :editable="false"
          :object="item"
        ></StatusButton>
      </div>
      <div v-show="hover" class="flex-shrink-0 ml-4">
        <v-tooltip top>
          <template #activator="{ on, attrs }">
            <v-icon
              small
              color="primary"
              class="icon-border pointer ml-auto mr-1"
              v-bind="attrs"
              v-on="on"
              @click.stop="itemCopyLink(item)"
            >
              mdi-link
            </v-icon>
          </template>
          <span>{{ $t('tooltip.link') }}</span>
        </v-tooltip>
        <v-tooltip top>
          <template #activator="{ on, attrs }">
            <v-icon
              small
              color="primary"
              class="icon-border pointer ml-auto mr-1"
              v-bind="attrs"
              v-on="on"
              @click.stop="itemOpenInNewTab(item)"
            >
              mdi-open-in-new
            </v-icon>
          </template>
          <span>{{ $t('tooltip.openInNew') }}</span>
        </v-tooltip>
      </div>
    </div>
  </v-hover>
</template>
<script>

import { mapActions } from 'vuex'
import i18n from './i18n.js'
import StatusButton from '~/components/common/StatusButton'

export default {
  name: 'MagicBoxAlgoliaListItem',
  components: {
    StatusButton
  },
  i18n,
  props: {
    item: {
      type: Object,
      required: true,
      default: () => {}
    },
    filter: {
      type: String,
      required: false,
      default: () => undefined
    }
  },
  data () {
    return {
      edtakeDocumentTypeIcons: {
        projectItem: 'view_timeline',
        learningObject: 'home_storage',
        mission: 'storefront'
      }
    }
  },
  methods: {
    ...mapActions({
      openSnackbar: 'snackbar/setSnackbarOpen'
    }),
    getLinkFromItem (item) {
      switch (item.edtakeDocumentType) {
        case 'projectItem':
          return `/${this.$enums.routePrefix.workspace}/${item.workspace}/${this.$enums.appPrefix.projectManagement}/${item.__t}/${item._id}`
        case 'learningObject':
          return `/${this.$enums.routePrefix.workspace}/${item.workspace}/${this.$enums.appPrefix.learningDesign}/${item.__t}/${item._id}`
        case 'mission':
          return `/${this.$enums.routePrefix.workspace}/${item.workspace}/${this.$enums.appPrefix.marketing}/mission/${item._id}`
      }
    },
    openItem (item) {
      this.$router.push(this.getLinkFromItem(item))
    },
    itemOpenInNewTab (item) {
      window.open(this.getLinkFromItem(item), '_blank')
    },
    itemCopyLink (item) {
      navigator.clipboard.writeText(`${window.location.host}${this.getLinkFromItem(item)}`).then(() => {
        console.log('Async: Copying to clipboard was successful!')
        this.openSnackbar({ text: this.$t('copiedToClipboard'), status: true })
      }, function (err) {
        console.error('Async: Could not copy text: ', err)
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.result-item {
  height: 70px;
  &:hover {
    color: $primary;
    cursor: pointer;
    background: $blue-5;
  }

  .item-title {
    display: block;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    font-weight: 500 !important;
  }

  .icon-border {
    padding: 4px;
    border: solid $primary 1px;
    border-radius: 3px;
  }
}
</style>
