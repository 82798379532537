import _ from 'lodash'
import dayjs from 'dayjs'

export default ctx => ({
  calculateState: ({ type, status, id, startDate, dueDate, parentObj, milestones }) => {
    const currentStage = ctx.store.getters['settings/getCurrentStageByType'](type, status)
    const currentStatus = ctx.store.getters['settings/getCurrentStatusByType'](type)

    if (currentStage && currentStage.label === status && currentStage.isDone) {
      return 'done'
    }
    if (dayjs().isAfter(dueDate)) {
      return 'late'
    }
    if (currentStatus && currentStage && milestones && milestones.length > 0) {
      const milestoneStage = _.filter(currentStatus, c => _.some(milestones, m => (!dayjs().isAfter(m.date) && m.expectedStatus === c._id)))
      if (_.some(milestoneStage, o => currentStage.order < o.order)) {
        return 'late'
      }
    }
    return 'ras'
  }
})
