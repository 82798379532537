/* eslint-disable no-console */
import _ from 'lodash'

// TODO: Store refactor - remove store
export const state = () => ({
})

export const getters = {
}

export const actions = {
  getModalityRecommandations ({ commit }, constraints) {
    return new Promise((resolve, reject) => {
      this.$edtake.modalityRecommandations.getModalityRecommandations({ body: constraints })
        .then((d) => {
          resolve(d)
        })
        .catch((err) => {
          console.error('ERROR', err)
          reject(err)
        })
    })
  }
}

export const mutations = {
}
