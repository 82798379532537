/* eslint-disable */
import '@mdi/font/css/materialdesignicons.css'

import current from './sdk/index.js'

const sdkName = 'edtake'
export default (ctx, inject) => {
  // inject the sdk in the context (ctx.app.$sdk)
  // And in the Vue instances (this.$sdk in your components)
  const sdk = {}
  sdk.apiHostDocuments = process.env.API_HOST_DOCUMENTS;

  (Object.keys(current)).forEach(m => { sdk[m] = current[m](ctx.$axios) });

  inject(sdkName, sdk)
}