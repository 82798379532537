<template>
  <v-sheet class="editionCard">
    <v-row class="align-baseline">
      <v-col cols="12" md="7" class="d-flex align-baseline">
        <span class="name mr-2">
          {{ deliverable.name }}
        </span>
        <v-icon x-small class="pointer" @click="deleteDeliverable">
          mdi-trash-can-outline
        </v-icon>
      </v-col>
      <v-col cols="12" md="5" class="text-right pl-0">
        <span class="category">
          {{ $t(`deliverable.category.${deliverable.category}`) }}
        </span>
      </v-col>
    </v-row>
  </v-sheet>
</template>

<script>
export default {
  props: {
    deliverable: {
      type: Object,
      default: () => { return { name: undefined, category: undefined } }
    }
  },
  data () {
    return {
    }
  },
  methods: {
    deleteDeliverable () {
      this.$emit('deleteDeliverable')
    }
  }
}
</script>

<style scoped>
.editionCard {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 20px;
  gap: 1px;
  height: 100%;
  min-height: 64px;
  background: #FCFCFF;
  border: 1px solid #E2E2E5;
  border-radius: 4px;
}
.category {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 24px;
  color: #76777A;
}
.name {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #1A1C1E;
}
</style>
