<template>
  <v-row width="100%" :style="{height:height}" class="ma-0 bg-white">
    <v-col v-if="step < 5" cols="4" class="leftColumn pa-0 d-flex flex-column">
      <div :style="content">
        <div class="leftTextContainer mt-14 mx-12">
          <span class="leftTitle">
            {{ $t(`step${step}.title`) }}
          </span>
          <span v-if="step < 5" class="text-lato-14 leftDescription">
            {{ $t(`step${step}.description`) }}
          </span>
        </div>
        <img
          v-if="step < 5"
          :src="require(`@/assets/img/mission-creation-step${step}.svg`)"
          class="mt-16"
          alt="edtake"
        />
      </div>
      <stepper v-if="step < 5" :current="step" :max="submitStep" class="footer flex-column" />
    </v-col>
    <v-col v-if="step < 5" cols="8" class="pa-0 d-flex flex-column">
      <section :style="content" class="pa-8">
        <div v-if="!iframeDisplay" class="text-right">
          <v-icon size="15" style="color: #aaabae;" @click="$emit('close')">
            mdi-close
          </v-icon>
        </div>
        <!--  -----STEP 1----- -->
        <section v-if="step === 1">
          <div class="questionTitle">
            {{ $t('jobQuestion') }}
          </div>
          <item-group
            v-model="professionRequested"
            :items="professions"
            with-description
            :cols="6"
          />
          <section v-show="professionRequested">
            <div class="mt-8 questionTitle">
              {{ $t('missionTypeQuestion') }}
            </div>
            <MissionTypeToggle :deliverable-disabled="deliverableDisabled" :mission-type="mission.type" :project="project" @updateMissionType="updateMissionType" />
          </section>
        </section>
        <!--  -----  STEP 2  ----- -->
        <section v-if="step === 2">
          <section v-if="mission.type === 'DELIVERABLE'">
            <!--  -----STEP 2 DELIVERABLE FROM PROJECT  ----- -->
            <section v-if="project">
              <div class="questionTitle">
                {{ $t("pickFromCurrentProject") }}
              </div>
              <item-group :items="projectChildrenNotDone" :value="deliverables" multiple :cols="4" @input="addProjectDeliverable" />
            </section>
            <section v-else>
              <!--  -----STEP 2 DELIVERABLE & OTHER  ----- -->
              <section v-if="professionRequested === 'Other'">
                <div class="questionTitle">
                  {{ $t('deliverableTypeQuestion') }}
                </div>
                <v-row style="min-height: 64px;">
                  <v-col cols="12" md="6">
                    <CreationCard type="deliverable" @submit="addCustomDeliverable" />
                  </v-col>
                  <v-col
                    v-for="(deliverable, index) in deliverables"
                    :key="index"
                    cols="12"
                    md="6"
                  >
                    <DeliverableCard :deliverable="deliverable" removable @deleteDeliverable="deleteDeliverable(index)" />
                  </v-col>
                </v-row>
              </section>
              <!--  -----STEP 2 DELIVERABLE ----- -->
              <section v-else>
                <div class="questionTitle">
                  {{ $t('deliverableTypeQuestion') }}
                </div>
                <item-group :items="deliverablesOptions" :value="deliverables" multiple :cols="4" @input="updateDeliverableList" />
              </section>
            </section>
          </section>
          <section v-if="mission.type === 'TIME'">
            <!--  -----STEP 2 TIME & OTHER ----- -->
            <section v-if="professionRequested === 'Other'">
              <div class="questionTitle">
                {{ $t('aboutJob') }}
              </div>
              <div class="text-lato-14 question mb-4">
                {{ $t('primarySkillAndLevel') }}
              </div>
              <v-row>
                <v-col>
                  <SkillCard
                    :value="primarySkill"
                    @updateSkill="updatePrimarySkill"
                    @updateExperience="updatePrimarySkillExperience"
                  />
                </v-col>
              </v-row>
              <section v-show="primarySkill.skill && primarySkill.skillExperience">
                <div class="text-lato-14 question mb-4">
                  {{ $t('secondarySkills') }}
                </div>
                <v-row>
                  <v-col cols="12" md="6">
                    <CreationCard type="time" @submit="addSecondarySkill" />
                  </v-col>
                  <v-col v-for="(skill, index) in secondarySkills" :key="index" cols="12" md="6">
                    <SkillCard :value="skill" :display-experience="false" :edit-right="false" />
                  </v-col>
                </v-row>
              </section>
            </section>
            <!--  -----STEP 2 TIME ----- -->
            <section v-else>
              <div class="questionTitle">
                {{ $t('aboutJob') }}
              </div>
              <div class="text-lato-14 question mb-4">
                {{ $t('skillLevel') }}
              </div>
              <item-group
                :value="primarySkill.skillExperience"
                :items="translatedSkillExperienceOptions"
                :cols="3"
                @input="updatePrimarySkillExperience"
              />
              <div class="text-lato-14 question mb-4">
                {{ $t('secondarySkills') }}
              </div>
              <item-group v-model="secondarySkills" :items="secondarySkillsOptions" multiple :cols="4" />
            </section>
          </section>
          <!--  -----STEP 2 ADDITIONAL QUESTIONS ----- -->
          <section>
            <div class="mt-8 questionTitle">
              {{ $t('additionalQuestions') }}
            </div>
            <v-row>
              <v-col v-if="mission.type === 'TIME'" cols="12" md="6">
                <additionalInformation
                  v-model="mission.startDate"
                  field-type="datepicker"
                  icon="mdi-calendar-range"
                  :label="$t('startDate')"
                  :min-value="$dayjs().toDate()"
                />
              </v-col>
              <v-col cols="12" md="6">
                <additionalInformation
                  v-model="mission.endDate"
                  field-type="datepicker"
                  icon="mdi-calendar-range"
                  :label="mission.type === 'DELIVERABLE' ? $t('deliveryDate') : $t('endDate')"
                  :min-value="endDateMinValue"
                />
              </v-col>
              <v-col v-if="mission.type === 'DELIVERABLE'" cols="12" md="6">
                <additionalInformation
                  v-model="mission.numberOfRevisionsByDeliverable"
                  field-type="number"
                  icon="mdi-reiterate"
                  :min-value="1"
                  :label="$t('numberOfRevisionsByDeliverable')"
                  :placeholder="$t('addNumberOfRevisionsByDeliverable')"
                />
              </v-col>
              <v-col v-if="mission.type === 'TIME'" cols="12" md="6">
                <additionalInformation
                  v-model="mission.duration"
                  field-type="number"
                  icon="mdi-weight-lifter"
                  :label="$t('duration')"
                  :placeholder="$t('addDuration')"
                  :max-value="businessDay"
                />
              </v-col>
              <v-col v-if="mission.type === 'TIME'" cols="12" md="6">
                <additionalInformation
                  v-model="mission.location"
                  field-type="list"
                  :options="translatedLocationOptions"
                  icon="mdi-map-marker-outline"
                  :placeholder="$t('addLocation')"
                  :label="$t('location')"
                />
              </v-col>
            </v-row>
            <v-row v-if="mission.type === 'DELIVERABLE' && deliverableQuestions">
              <v-col>
                <div v-for="question in deliverableQuestions" :key="question.id">
                  <div class="text-lato-14 question">
                    {{ question.label }}
                  </div>
                  <EditableField
                    :value="deliverableAnswers[question.id].data"
                    type="text"
                    large
                    :required="question.required"
                    :edit-right="true"
                    :placeholder="$t('addAnswer')"
                    @input="(e) => addAnswer(e, question.id)"
                  />
                </div>
              </v-col>
            </v-row>
          </section>
        </section>
        <!--  -----STEP 3 ----- -->
        <section v-if="step === 3">
          <section v-if="!iframeDisplay">
            <div class="questionTitle">
              {{ $t('missionTitle') }}
            </div>
            <EditableField
              v-model="mission.title"
              type="text"
              large
              :placeholder="$t('missionTitlePlaceholder')"
              :edit-right="true"
            />
          </section>
          <div :class="[iframeDisplay ? '' : 'mt-8', 'questionTitle']">
            {{ $t('briefingTitle') }}
          </div>
          <v-sheet class="briefingContainer">
            <EditableField
              v-model="mission.briefing"
              type="richEditorBubble"
              large
              :placeholder="$t('briefingPlaceholder')"
              :edit-right="true"
            />
          </v-sheet>
          <div class="questionTitle mt-8">
            {{ mission.type === 'TIME' ? $t('budget.titleDailyRate') : $t('budget.title') }}
          </div>
          <div class="d-flex align-baseline">
            <EditableField
              v-model="mission.isBudgetFromEdtaker"
              type="booleanList"
              :options="[{id: true, label: 'Non'}, {id: false, label: 'Oui'}]"
              class="primary--text font-weight-bold"
              icon-color="primary"
              :edit-right="true"
              parent-container
            />
            <div class="ml-2 d-flex">
              <span class="text-lato-14 sentence-grey-font mr-2">
                {{ mission.isBudgetFromEdtaker ? $t('budget.askProposals') : $t('budget.sentence') }}
              </span>
              <EditableField
                v-show="!mission.isBudgetFromEdtaker"
                v-model="mission.budget"
                placeholder="____ €"
                type="number"
                step="1"
                currency
                :min="1"
                :edit-right="!mission.isBudgetFromEdtaker"
              />
            </div>
          </div>
          <!-- -----LANGUAGES, LCMS, AUTHORING TOOLS---------- -->
          <div class="mt-8 questionTitle">
            {{ $t('additionalQuestions') }}
          </div>
          <v-row>
            <v-col class="pb-0">
              <additionalInformation
                v-model="mission.languages"
                field-type="language-multiple"
                :options="languages"
                icon="mdi-translate"
                :label="$t('mission.LANGUAGES')"
                :placeholder="$t('addLanguage')"
              />
            </v-col>
            <v-col class="pb-0">
              <additionalInformation
                v-model="mission.lcms"
                field-type="list"
                :options="lmsOptions"
                icon="mdi-school-outline"
                :label="$t('mission.LCMS')"
                :placeholder="$t('addLCMS')"
              />
            </v-col>
            <v-col class="pb-0">
              <additionalInformation
                v-model="mission.authoringTools"
                field-type="tags"
                :options="authoringToolsOptions"
                icon="mdi-text-box-outline"
                :label="$t('mission.TOOLS')"
                :placeholder="$t('addAuthoringTool')"
              />
            </v-col>
          </v-row>
          <!-- ADD DOCUMENTS -->
          <section v-if="!newUser">
            <div class="questionTitle mt-8">
              {{ $t('addDocuments') }}
            </div>
            <div class="vfa-demo bord">
              <VueFileAgent
                ref="vfaDemoRef"
                v-model="files"
                :multiple="true"
                deletable
                :meta="true"
                :theme="'list'"
                :max-files="10"
                :max-size="'20MB'"
                :error-text="{
                  type: $t('errors.invalidType'),
                  size: $t('errors.maxSize'),
                }"
                @beforedelete="onBeforeDelete($event)"
              >
                <template slot="file-preview-new">
                  <div key="new" class="d-flex align-center justify-center py-2">
                    <v-icon size="84" :color="'primary'" dense>
                      $vuetify.icons.upload
                    </v-icon>
                    <span class="ml-5">
                      <span class="blue--text">
                        {{ $t('importDoc') }}
                      </span>
                      {{ $t('dropOrBrowse') }} <span class="blue--text"> {{ $t('browse') }}</span> {{ $t('files') }}
                    </span>
                  </div>
                </template>
                <template slot="after-outer">
                  <div title="after-outer">
                    <div class="drop-help-text">
                      <p>{{ $t('dropHere') }}</p>
                    </div>
                  </div>
                </template>
              </VueFileAgent>
            </div>
          </section>
        </section>
        <!-- -------- STEP 4 ----------- -->
        <section v-if="step === 4">
          <div class="questionTitle">
            {{ $t('aboutCompany') }}
          </div>
          <v-row>
            <v-col cols="12" md="4">
              <additionalInformation
                v-model="workspace.company.name"
                field-type="text"
                :label="$t('name')"
                :placeholder="$t('addCompanyName')"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" md="4">
              <additionalInformation
                v-model="workspace.type"
                field-type="list"
                :options="companiesTypesOptions"
                :label="$t('companyType')"
                :placeholder="$t('addCompanyType')"
              />
            </v-col>
            <v-col cols="12" md="4">
              <additionalInformation
                v-model="workspace.company.employeesNumber"
                field-type="list"
                :options="employeesNumberOptions"
                :label="$t('employeesNumber')"
                :placeholder="$t('addEmployeesNumber')"
              />
            </v-col>
          </v-row>
          <div class="questionTitle mt-8">
            {{ $t('aboutUser') }}
          </div>
          <v-row>
            <v-col cols="12" md="4">
              <additionalInformation
                v-model="user.firstname"
                field-type="text"
                :label="$t('firstname')"
                :placeholder="$t('addFirstname')"
              />
            </v-col>
            <v-col cols="12" md="4">
              <additionalInformation
                v-model="user.lastname"
                field-type="text"
                :label="$t('name')"
                :placeholder="$t('addName')"
              />
            </v-col>
            <v-col cols="12" md="4">
              <additionalInformation
                v-model="user.job"
                field-type="text"
                :label="$t('job')"
                :placeholder="$t('addJob')"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" md="4">
              <additionalInformation
                v-model="user.email"
                field-type="text"
                :label="$t('email')"
                :placeholder="$t('addEmail')"
                :edit-right="false"
              />
            </v-col>
            <v-col cols="12" md="4">
              <additionalInformation
                v-model="user.phone"
                field-type="text"
                :label="$t('phoneNumber')"
                :placeholder="$t('addPhoneNumber')"
              />
            </v-col>
          </v-row>
          <v-sheet width="100%" class="generalTerms">
            <v-checkbox
              v-model="user.acceptedCgu"
              on-icon="mdi-checkbox-marked-outline"
              color="#1A1C1E"
            >
              <template #label>
                <div class="text-lato-14 sentence-grey-font">
                  {{ $t('generalTerms.start') }}
                  <a
                    class="text-lato-14 sentence-grey-font font-weight-bold text-decoration-underline"
                    target="_blank"
                    href="https://www.edtake.com/fr/cgs-clients"
                    @click.stop
                  >
                    {{ $t('generalTerms.link') }}
                  </a>
                </div>
              </template>
            </v-checkbox>
          </v-sheet>
        </section>
      </section>
      <!--  -----FOOTER ----- -->
      <section class="footer justify-space-between border-top">
        <edtk-button
          v-show="step > 1"
          type="outline"
          :label="$t('buttons.PREVIOUS')"
          :disabled="loadingAction"
          class="mx-8 my-4"
          @click="changeStep(step, step - 1)"
        ></edtk-button>
        <div class="mx-8 my-4 ml-auto">
          <edtk-button
            v-if="step === submitStep"
            :label="$t('buttons.SUBMIT')"
            :loading="loadingAction"
            :disabled="loadingAction"
            @click="submit"
          ></edtk-button>
          <edtk-button
            v-else
            :label="$t('buttons.NEXT')"
            :loading="loadingAction"
            :disabled="nextButtonDisabled"
            @click="changeStep(step, step + 1)"
          ></edtk-button>
        </div>
      </section>
    </v-col>
    <v-col v-else-if="step === 5">
      <!-- -------- STEP 5 ----------- -->
      <v-row style="height: 95%" width="100%">
        <v-col class="d-flex flex-column align-center justify-center">
          <img
            :src="require(`@/assets/img/mission-creation-step5.png`)"
            alt="edtake"
          />
          <div v-if="iframeDisplay" class="text-center mt-16">
            <span class="confirmTitle">
              {{ $t('confirmPage.iframeTitle') }}
            </span>
            <div class="text-lato-14 leftDescription mt-4 mx-16" v-html="$t('confirmPage.iframeParagraph')"></div>
          </div>
          <div v-else class="text-center mt-16">
            <span class="confirmTitle">
              {{ $t('confirmPage.missionSaved') }}
            </span>
            <div class="text-lato-14 leftDescription mt-4 mx-16" v-html="$t('confirmPage.toContinue')"></div>
          </div>
          <!-- TODO : handle iframe display in workspace -->
          <!-- {{ iframeDisplay ? $t('close') : $t('seeMission') }} -->
          <edtk-button
            :label="$t('close')"
            class="mt-8"
            @click="close"
          ></edtk-button>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import Vue from 'vue'
import VueFileAgent from 'vue-file-agent'
import { Promise } from 'bluebird'

import data from './data.json'
import i18n from './i18n.js'
import validations from './validations.js'
import additionalInformation from './additionalInformation'
import CreationCard from './creationCard'
import DeliverableCard from './deliverableCard'
import SkillCard from './skillCard'
import stepper from './stepper'
import MissionTypeToggle from './missionTypeToggle'
import itemGroup from '~/components/common/itemGroup'
Vue.use(VueFileAgent)

export default {
  name: 'CreateMission',
  components: {
    itemGroup,
    additionalInformation,
    CreationCard,
    DeliverableCard,
    SkillCard,
    stepper,
    MissionTypeToggle
  },
  props: {
    height: {
      type: String,
      default: () => '90vh'
    },
    project: {
      type: Object,
      required: false,
      default: () => undefined
    },
    iframeDisplay: {
      type: Boolean,
      default: false
    },
    newUser: {
      type: Boolean,
      default: false
    },
    lmsOptions: {
      type: Array,
      default: function () { return this.$enums.lms }
    },
    authoringToolsOptions: {
      type: Array,
      default: function () { return this.$enums.authoring }
    },
    currentWorkspaceId: {
      type: String,
      default: () => ''
    },
    statuses: {
      type: Function,
      required: false,
      default: () => { return { options: [] } }
    },
    languages: {
      type: Array,
      default: function () { return this.$enums.languages }
    },
    initData: {
      type: Object,
      default: () => { return {} }
    },
    hintEmail: {
      type: String,
      default: () => undefined
    }
  },
  i18n,
  validations,
  data () {
    return {
      content: {
        height: `calc(${this.height} - 60px)`,
        maxHeight: `calc(${this.height} - 60px)`,
        overflowY: 'auto'
      },
      loadingAction: false,
      step: 1,
      tryValidate: false,
      files: [],
      workspace: {
        type: undefined,
        company: {
          name: undefined,
          employeesNumber: undefined
        }
      },
      user: {
        firstname: undefined,
        lastname: undefined,
        job: undefined,
        email: this.hintEmail || undefined,
        phone: undefined,
        acceptedCgu: false
      },
      primarySkill: {
        skill: undefined,
        skillLevel: undefined,
        skillExperience: undefined
      },
      secondarySkills: [],
      deliverables: [],
      deliverableAnswers: {},
      deliverableQuestions: [],
      deliverablesFromProject: [],
      mission: {
        title: undefined,
        briefing: undefined,
        type: undefined /* String DELIVERABLE ou TIME */,
        location: undefined,
        duration: undefined /* Number positive */,
        languages: [],
        authoringTools: [],
        numberOfRevisionsByDeliverable: 1,
        lcms: undefined,
        startDate: undefined,
        endDate: undefined,
        budget: undefined,
        isBudgetFromEdtaker: false
      },
      newMission: undefined,
      professionRequested: undefined
    }
  },
  computed: {
    ...mapGetters({
      translatedSkillsOptions: 'configuration/getSkillsAsOptions',
      translatedSkillExperienceOptions: 'configuration/getSkillsExperienceAsOptions',
      translatedLocationOptions: 'configuration/getLocationsAsOptions',
      getNumberOfRevisionsByDeliverable: 'settings/getNumberOfRevisionsByDeliverable'
    }),
    professions () {
      return data.jobs.map((job) => {
        return { id: job.id, label: this.$t(`jobOptions.${job.id}.title`), description: this.$t(`jobOptions.${job.id}.description`) }
      })
    },
    deliverableDisabled () {
      return this.project ? !this.projectChildrenNotDone.length : (!this.deliverablesOptions.length && this.professionRequested !== 'Other')
    },
    professionData () {
      return data.jobs.find(o => o.id === this.professionRequested)
    },
    secondarySkillsOptions () {
      return this.professionData.secondarySkills.map((s) => { return { id: s, name: this.$t(`skills.${s}`) } })
    },
    deliverablesOptions () {
      if (this.professionRequested) {
        return this.professionData.deliverables.map((d) => {
          return { id: d.name, name: this.$t(`deliverables.${d.name}`), category: d.category }
        })
      } else {
        return []
      }
    },
    projectChildrenNotDone () {
      const childrenNotDone = this.project ? this.$_.filter(this.project.children, c => c.__t === 'deliverable' && !this.projectDoneStatuses.includes(c.status)) : []
      return childrenNotDone.map((c) => {
        return { id: c._id, name: c.name, category: c.category }
      })
    },
    projectDoneStatuses () {
      return this.$_.reduce(this.statuses('project').options, (acc, o) => {
        return o.isDone ? [...acc, o.label] : acc
      }, [])
    },
    businessDay () {
      if (this.mission.endDate && this.mission.startDate) {
        const diff = this.$dayjs(this.mission.endDate).businessDiff(this.$dayjs(this.mission.startDate))
        return diff + 1
      } else {
        return undefined
      }
    },
    endDateMinValue () {
      return this.mission.startDate ? this.$dayjs(this.mission.startDate).toDate() : this.$dayjs().toDate()
    },
    companiesTypesOptions () {
      return data.companyTypes.map((type) => {
        return { id: type, label: this.$t(`${type}`) }
      })
    },
    employeesNumberOptions () {
      return data.employeesNumberOptions
    },
    nextButtonDisabled () {
      switch (this.step) {
        case 1:
          return !this.professionRequested || !this.mission.type
        case 2:
          return false
        case 3:
          return this.newUser ? false : this.loadingAction
        case 4:
          return this.loadingAction
        default:
          return false
      }
    },
    submitStep () {
      return this.newUser ? 4 : 3
    },
    missionToSave () {
      const m = Object.assign({}, this.mission)
      if (this.mission.type === 'DELIVERABLE') {
        const deliverables = this.project ? this.deliverablesFromProject : this.deliverables
        m.deliverables = deliverables.map((d) => {
          if (typeof d === 'object') {
            return { deliverableId: d.id, name: d.name, category: d.category }
          } else {
            const deliverable = this.deliverablesOptions.find(o => o.id === d)
            return { deliverableId: undefined, name: deliverable.name, category: deliverable.category }
          }
        })
        if (this.deliverableQuestions.length) {
          const deliverablesQuestionsAnswers = this.deliverableQuestions.map((d) => {
            const question = d.label
            const answer = this.deliverableAnswers[d.id].data || ''
            return question.concat(' ', answer)
          })
          m.briefing = this.mission.briefing.concat('</br>', deliverablesQuestionsAnswers.join(' </br>'))
        }
      } else if (this.mission.type === 'TIME') {
        const secondarySkills = this.secondarySkills.map((s) => {
          return typeof s === 'object' ? s : { skill: s, skillExperience: 1, skillLevel: 1 }
        })
        m.skills = [this.primarySkill, ...secondarySkills]
      }
      if (this.project) {
        m.parentProject = this.project._id
      }
      if (this.iframeDisplay) {
        if (this.mission.type === 'DELIVERABLE') {
          if (m.deliverables.length === 1) {
            m.title = `${this.$t(`jobOptions.${this.professionRequested}.title`)} (${m.deliverables[0].name})`
          } else {
            m.title = `${this.$t(`jobOptions.${this.professionRequested}.title`)} (${m.deliverables.length} ${this.$tc('deliverable', m.deliverables.length)})`
          }
        } else {
          m.title = this.$t(`skills.${this.primarySkill.skill}`)
        }
      }
      if (this.iframeDisplay && !this.newUser) {
        m.status = 'EDTAKE_TO_VALIDATE'
      } else {
        m.status = 'DRAFT'
      }
      return m
    }
  },
  watch: {
    'mission.startDate' (newValue, oldValue) {
      if (
        this.$dayjs(newValue, 'YYYY-MM-DD').isAfter(
          this.$dayjs(this.mission.endDate, 'YYYY-MM-DD')
        )
      ) {
        this.mission.endDate = null
      }
    },
    'mission.isBudgetFromEdtaker' (n, o) {
      if (n) {
        this.mission.budget = undefined
      }
    },
    professionRequested (n, o) {
      if (this.mission.type === 'TIME') {
        this.updatePrimarySkill(this.professionRequested)
      } else if (this.deliverableDisabled) {
        this.mission.type = undefined
      }
    }
  },
  mounted () {
    if (this.languages.length === 1) {
      this.mission.languages = [this.languages[0]]
    }
    if (this.authoringToolsOptions.length === 1) {
      this.mission.authoringTools = [this.authoringToolsOptions[0]]
    }
    if (this.lmsOptions.length === 1) {
      this.mission.lcms = this.lmsOptions[0]
    }

    if (Object.keys(this.initData).length > 0) {
      this.mission.authoringTools = this.initData.authoringTool && this.initData.authoringTool !== '' ? [this.initData.authoringTool] : this.mission.authoringTools
      this.mission.lcms = this.initData.lcms && this.initData.lcms !== '' ? this.initData.lcms : this.mission.lcms
      if (this.initData.source) {
        this.mission.source = this.initData.source
      }
      if (this.initData.edtakerWish) {
        this.mission.edtakerWish = this.initData.edtakerWish
      }
    }

    this.mission.numberOfRevisionsByDeliverable = this.getNumberOfRevisionsByDeliverable || 3
  },
  methods: {
    ...mapActions({
      missionOfferCreate: 'mission/create',
      missionOfferCreateForNewUser: 'mission/createForNewUser',
      missionOfferUpdate: 'mission/update',
      upload: 'edtakeDocument/uploadDoc',
      checkErrors: 'errorForm/generateErrorList'
    }),
    updateMissionType (type) {
      if (type === 'TIME') {
        this.mission.type = type
        this.updatePrimarySkill(this.professionRequested)
      } else if (!this.deliverableDisabled) {
        this.mission.type = type
      }
    },
    updatePrimarySkill (e) {
      if (e !== 'Other') {
        this.primarySkill.skill = e
      } else {
        this.primarySkill.skill = undefined
      }
    },
    updatePrimarySkillExperience (e) {
      this.primarySkill.skillExperience = e
      this.primarySkill.skillLevel = e
    },
    addSecondarySkill (e) {
      this.secondarySkills.push({ skill: e, skillExperience: 1, skillLevel: 1 })
    },
    addCustomDeliverable (payload) {
      this.deliverables.push(payload)
    },
    addProjectDeliverable (payload) {
      const deliverablesFromProject = payload.map((d) => {
        return this.projectChildrenNotDone.find(c => c.id === d)
      })
      this.deliverablesFromProject = deliverablesFromProject
      this.deliverables = payload
    },
    updateDeliverableList (payload) {
      this.deliverables = payload
      this.updateDeliverableQuestions()
    },
    deleteDeliverable (index) {
      this.deliverables.splice(index, 1)
    },
    updateDeliverableQuestions () {
      if (this.professionRequested !== 'Other' && this.deliverables.length) {
        const questions = []
        const questionsToDisplay = []
        this.deliverableAnswers = {}
        const self = this
        this.deliverables.forEach((deliverable) => {
          const deliverableObject = self.professionData.deliverables.find(d => d.name === deliverable)
          deliverableObject.questions.forEach((question) => {
            if (!questions.some(e => e.key === question.key)) {
              questions.push(question)
            }
          })
        })
        questions.forEach((q) => {
          questionsToDisplay.push({ id: q.key, label: this.$t(`deliverables.questions.${q.key}.label`), required: q.required })
          this.deliverableAnswers = Object.assign({}, this.deliverableAnswers, { [q.key]: { data: '', required: q.required } })
        })
        this.deliverableQuestions = questionsToDisplay
      } else {
        return null
      }
    },
    addAnswer (payload, questionId) {
      const required = this.deliverableAnswers[questionId].required
      this.$set(this.deliverableAnswers, questionId, { data: payload, required })
    },
    onBeforeDelete (fileRecord) {
      this.$refs.vfaDemoRef.deleteFileRecord(fileRecord)
    },
    submit () {
      const self = this

      this.$v.$touch()
      const validationStep = this.newUser ? this.$v.step4 : this.$v.step3
      if (validationStep.$invalid) {
        const $t = this.$t.bind(this)
        this.checkErrors({ validationObject: validationStep, translation: $t, translationPath: 'formLabel', usePopin: true })
        this.tryValidate = true
      } else {
        self.loadingAction = true
        if (this.newUser) {
          const body = {
            mission: this.missionToSave,
            workspace: this.workspace,
            user: this.user
          }
          this.missionOfferCreateForNewUser(body)
            .then((m) => {
              this.newMission = m.mission
              this.step = 5
              this.loadingAction = false
            })
            .catch((err) => {
              this.$swal({
                icon: 'error',
                title: this.$t('errors.OUPS'),
                html: this.$t('errors.' + err)
              })
              self.loadingAction = false
            })
        } else {
          this.missionOfferCreate({ ...this.missionToSave, fromSdk: this.iframeDisplay })
            .then(async (m) => {
              if (this.files.length > 0) {
                this.files.forEach((f) => { if (f.error.size === true || f.error.type === true) { this.$refs.vfaDemoRef.deleteFileRecord(f) } })
                const urlFiles = await Promise.mapSeries(this.files, async (f) => {
                  const url = await this.upload({ file: f, type: 'mission', id: m._id })
                  return { url, name: f.file.name, type: f.type, size: f.size }
                })
                await this.missionOfferUpdate({ id: m._id, body: { documents: urlFiles } })
              }
              this.newMission = m
              this.step = 5
              this.loadingAction = false
              this.$evt.log('MissionSubmitted')
            })
            .catch((err) => {
              self.loadingAction = false
              this.$swal({
                icon: 'error',
                title: this.$t('errors.OUPS'),
                html: this.$t('errors.' + err)
              })
            })
        }
      }
    },
    changeStep (oldValue, newValue) {
      if (newValue > oldValue) {
        if (oldValue === 2 && this.$v.step2.$invalid) {
          const $t = this.$t.bind(this)
          this.checkErrors({ validationObject: this.$v.step2, translation: $t, translationPath: 'formLabel', usePopin: true })
          this.tryValidate = true
          return
        }
        if (oldValue === 3 && this.$v.step3.$invalid) {
          const $t = this.$t.bind(this)
          this.checkErrors({ validationObject: this.$v.step3, translation: $t, translationPath: 'formLabel', usePopin: true })
          this.tryValidate = true
          return
        }

        try {
          switch (oldValue) {
            case 1:
              this.$evt.log('MissionCreation', { step: 'MissionType', professionRequested: this.professionRequested, type: this.mission.type })
              break
            case 2:
              if (this.mission.type === 'TIME') {
                this.$evt.log('MissionCreation', { step: 'MissionNeeds', primarySkill: this.primarySkill, secondarySkills: this.secondarySkills })
              } else {
                this.$evt.log('MissionCreation', { step: 'MissionNeeds', deliverables: this.missionToSave.deliverables })
              }
              break
            case 3:
              this.$evt.log('MissionCreation', {
                step: 'MissionDetails',
                type: this.mission.type,
                location: this.mission.location,
                duration: this.mission.duration,
                languages: this.mission.languages,
                authoringTools: this.mission.authoringTools,
                numberOfRevisionsByDeliverable: this.mission.numberOfRevisionsByDeliverable,
                lcms: this.mission.lcms,
                daysToStart: this.$dayjs(this.mission.startDate).diff(this.$dayjs(), 'day'),
                budget: this.mission.budget,
                isBudgetFromEdtaker: this.mission.isBudgetFromEdtaker
              })
              break

            default:
              break
          }
        } catch (error) {

        }
      }
      this.tryValidate = false
      if (newValue === 1) {
        this.mission.type = undefined
        this.primarySkill = {
          skill: undefined,
          skillLevel: undefined,
          skillExperience: undefined
        }
        this.secondarySkills = []
        this.deliverables = []
        this.deliverableQuestions = []
        this.deliverableAnswers = {}
        this.deliverablesFromProject = []
      }
      this.step = newValue
    },
    close () {
      this.$emit('close', { action: 'mission-creation', mission: { _id: this.newMission._id, title: this.newMission.title }, workspace: this.newMission.workspace })
      if (!this.iframeDisplay) {
        this.$edtkRouter.navigate({ $route: this.$route, $router: this.$router, workspaceId: this.currentWorkspaceId, targetApp: this.$enums.appPrefix.marketplace, targetPath: `/mission/${this.newMission._id}${this.iframeDisplay ? '?iframeDisplay' : ''}` })
      } else {
        this.$router.push('/')
      }
    }
  }
}
</script>
<style lang="scss" scoped>
  .text-lato-14 {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
  }
  .leftColumn {
    border-right: solid 1px #e2e2e5;
    background-color: #fcfcff;
  }
  .leftTextContainer {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    gap: 16px;
  }
  .leftTitle {
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 30px;
    color: #00558A;
  }
  .leftDescription {
    line-height: 24px;
    letter-spacing: 0.01em;
    color: #76777A;
  }
  .questionTitle {
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    color: #00558A;
    margin-bottom: 16px;
  }
  .question {
    line-height: 17px;
    color: #00558A;
    margin-bottom: 8px;
    margin-top: 16px;
  }
  .footer {
    display: flex;
    align-items: center;
    padding: 0 32px;
    height: 60px;
    &.border-top {
      border-top: 1px solid #E2E2E5;
    }
  }
  .briefingContainer {
    min-height: 259px;
    border: solid 1px #e2e2e5;
    padding: 20px;
    border-radius: 4px;
  }
  .sentence-grey-font {
    line-height: 24px;
    color: #76777A;
    width: max-content;
  }
  .confirmTitle {
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 30px;
    text-align: center;
    color: #00558A;
  }
  .bord {
    background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='4' ry='4' stroke='%2300000066' stroke-width='1' stroke-dasharray='14%2c 14' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
    border-radius: 4px;
  }
  .vfa-demo {
    background-color: #ffffff;
    position: relative;
  }
  .vfa-demo :deep(.vue-file-agent) {
    border: 2px dashed transparent !important;
    box-shadow: none !important;
  }
  .vfa-demo .is-drag-over .drop-help-text {
    display: flex;
  }
  .vfa-demo .drop-help-text {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin: 2px;
    background: rgba(255, 255, 255, 1);
    z-index: 1200;
    font-size: 32px;
    font-weight: 600;
    color: #888;
    align-items: center;
    justify-content: center;
    display: none;
  }
  .generalTerms {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 20px;
    gap: 12px;
    height: 64px;
    background: #FCFCFF;
    border: 1px solid #E2E2E5;
    border-radius: 4px;
    margin-top: 32px;
  }
  :deep(.v-input--selection-controls__input i) {
    font-size: 14px;
  }
  :deep(.v-input--selection-controls__ripple) {
    display: none;
  }
</style>
