<template>
  <section>
    <div
      v-if="!options"
      class="d-flex justify-space-between align-center"
    >
      <v-text-field
        v-model="search"
        style="max-width: 30%;"
        :placeholder="$t('search')"
        prepend-inner-icon="mdi-magnify"
      ></v-text-field>
      <div v-if="!type" class="d-flex">
        <edtk-select
          v-model="learningObjectOptionsType"
          :options="tOptions"
          :label="$t('display')"
          :placeholder="$t('display')"
          :editable="true"
        />
      </div>
    </div>
    <div class="object-container">
      <v-card
        v-for="(l) in learningObjectOptions"
        :key="l.id"
        class=" hoverable blue--text d-flex flex-column justify-center"
        :class="[(multiple && pickedItem.includes(l.id)) || (!multiple && pickedItem === l.id) ? 'activeBackground' : '']"
        @click="pick(l.id)"
      >
        <v-lazy
          :options="{
            threshold: .1
          }"
          min-height="100"
          transition="fade-transition"
        >
          <div>
            <div class="font-weight-semibold mb-2 d-flex justify-space-between align-center">
              <div class="d-flex">
                {{ l.label }}
                <div class="ml-2 d-flex align-center">
                  <v-popover trigger="hover" :container="$parent.$el">
                    <v-icon
                      v-if="(l.themes && l.themes.length > 0) || l.description || (l.objectives && l.objectives.length > 0)"
                      color="primary"
                      small
                    >
                      mdi-information-outline
                    </v-icon>
                    <template slot="popover">
                      <div class="pa-4">
                        <div v-if="l.objectives && l.objectives.length > 0">
                          <span class="blue--text blue--text font-weight-bold">{{ $t('tOption.objectives') }} :</span>
                          <div v-for="objective in l.objectives" :key="objective" class="ml-2">
                            <span>{{ objective }}</span>
                          </div>
                        </div>
                        <div v-if="l.themes && l.themes.length > 0" class="mt-2">
                          <span class="blue--text blue--text font-weight-bold">{{ $t('tOption.themes') }} :</span>
                          <EditableField
                            :value="l.themes"
                            type="rich-editor-bubble"
                            class="ml-2"
                            :edit-right="false"
                          />
                        </div>
                        <div v-if="l.description" class="mt-2 width-350px ">
                          <span class="blue--text font-weight-bold">{{ $t('description') }} :</span>
                          <EditableField
                            :value="l.description"
                            type="rich-editor-bubble"
                            class="ml-2"
                            :edit-right="false"
                          />
                        </div>
                      </div>
                    </template>
                  </v-popover>
                </div>
              </div>
              <v-icon v-if="(multiple && pickedItem.includes(l.id)) || (!multiple && pickedItem === l.id)" small color="primary">
                mdi-check
              </v-icon>
            </div>
            <div v-if="l.trainingDuration">
              {{ $t('durationOfLearningObject.'+ l.type) }} : {{ l.trainingDuration }} min
            </div>
            <br v-else>
            <div class="text-rem-11 mb-2">
              <span v-if="l.objectType">
                {{ l.objectType }} :
              </span>
              {{ l.activityType }}
            </div>
            <div class="d-flex justify-space-between align-end" :style="'height:32px'">
              <status-button v-if="l.status" :type="l.type" :status="l.status" :object="l"></status-button>
              <div class="ml-2">
                <v-tooltip bottom>
                  <template #activator="{ on, attrs }">
                    <span v-bind="attrs" v-on="on">
                      <v-progress-circular
                        :rotate="270"
                        :value="l.score"
                        :color="l.score >= 75 ? 'green' : l.score >= 50 ? 'primary' : l.score > 0 ? 'warning' : 'grey'"
                      >
                        <span v-if="!l.score">-</span>
                        <span
                          v-else
                          :class="[l.score >= 75 ? 'green--text' : l.score >= 50 ? 'primary--text' : l.score > 0 ? 'warning--text' : 'brownish-grey--text','text-rem-06 font-weight-bold']"
                        >
                          {{ l.score }} %
                        </span>
                      </v-progress-circular>
                    </span>
                  </template>
                  <span v-if="!l.score">{{ $t('noData') }}</span>
                  <span v-else>{{ $t('percentage') }} : {{ l.score }}%</span>
                </v-tooltip>
              </div>
            </div>
          </div>
        </v-lazy>
      </v-card>
    </div>
  </section>
</template>

<script>
import { mapGetters } from 'vuex'
import i18n from './i18n.js'
import statusButton from '~/components/common/StatusButton'

export default {
  name: 'LearningObjectPicker',
  components: {
    statusButton
  },
  props: {
    value: {
      type: [String, Array, undefined],
      required: true,
      default: () => 'undefined'
    },
    currentRessource: {
      type: Array,
      required: false,
      default: () => []
    },
    type: {
      type: String,
      required: false,
      default: () => undefined
    },
    options: {
      type: Array,
      required: false
    },
    multiple: {
      type: Boolean,
      default: false
    },
    fromMechanism: {
      type: Boolean,
      default: false
    }
  },
  i18n,
  data () {
    return {
      search: undefined,
      loading: false,
      isLazyActive: false,
      learningObjectOptionsType: this.type || 'all',
      pickedItem: this.value ? this.value : undefined,
      tOptions: [
        { value: 'all', label: this.$t('tOption.all') },
        { value: 'module', label: this.$t('tOption.module') },
        { value: 'sequence', label: this.$t('tOption.sequence') }
      ]
    }
  },
  computed: {
    ...mapGetters({
      courses: 'course/get',
      modules: 'module/get',
      sequences: 'sequence/get',
      mechanisms: 'mechanism/get'
    }),
    learningObjectOptions () {
      let result = []
      const tKey = {
        module: 'course.module',
        sequence: 'sequence'
      }
      if (this.options) {
        result = [...this.options]
      } else if (this.learningObjectOptionsType === 'all') {
        result = [...this.modules, ...this.sequences]
        if (this.$userRightsHelper.hasFeatureAccess('course')) {
          result.push(...this.courses)
        }
        if (!this.fromMechanism && this.$userRightsHelper.hasFeatureAccess('mechanism')) {
          result.push(...this.mechanisms)
        }
      } else {
        result = this[`${this.learningObjectOptionsType}s`]
      }
      result = result.filter(r => !this.currentRessource.some(cr => r._id === cr._id))
      if (this.search) {
        const r = this.$_.filter(result, (m) => {
          return (m.name.toLowerCase().includes(this.search.toLowerCase()))
        })
        return this.$_.map(r, (e) => {
          return {
            id: e._id,
            label: e.name,
            status: e.status,
            type: e.__t,
            objectType: this.$nuxt.$te(`${tKey[e.type]}.type.${e.type}`) ? this.$t(`${tKey[e.type]}.type.${e.type}`) : e.type,
            activityType: e.activityType,
            themes: e.themes,
            description: e.description,
            trainingDuration: e.trainingDuration,
            objectives: e.objectives,
            score: e.lms ? Number(e.lms.averageScore) : 0
          }
        })
      } else {
        return this.$_.map(result, (e) => {
          return {
            id: e._id,
            label: e.name,
            status: e.status,
            type: e.__t,
            objectType: this.$nuxt.$te(`${tKey[e.__t]}.type.${e.type}`) ? this.$t(`${tKey[e.__t]}.type.${e.type}`) : e.type,
            activityType: this.$nuxt.$te(`activityTypes.${e.activityType}`) ? this.$t(`activityTypes.${e.activityType}`) : e.activityType,
            themes: e.themes,
            description: e.description,
            trainingDuration: e.trainingDuration,
            objectives: e.objectives,
            score: e.lms ? Number(e.lms.averageScore) : 0
          }
        })
      }
    }
  },
  watch: {
    value (n) {
      this.pickedItem = n || undefined
    }
  },
  mounted () {
    if (this.$userRightsHelper.hasFeatureAccess('course')) {
      this.tOptions.push({ value: 'course', label: this.$t('tOption.course') })
    }
    if (!this.fromMechanism && this.$userRightsHelper.hasFeatureAccess('mechanism')) {
      this.tOptions.push({ value: 'mechanism', label: this.$t('tOption.mechanism') })
    }
  },
  methods: {
    pick (id) {
      if (this.multiple) {
        if (this.$_.includes(this.pickedItem, id)) {
          this.pickedItem = this.$_.filter(this.pickedItem, i => i !== id)
        } else {
          this.pickedItem.push(id)
        }
      } else {
        this.pickedItem = id
      }
      this.$emit('input', this.pickedItem)
    }
  }
}
</script>
<style lang="scss" scoped>
.object-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 6px;
}

.sticky-container {
  background-color: inherit;
  height: 80px;
  align-self: center;
  position: sticky;
  top: 0;
  z-index: 2;
}
.activeBackground {
  background-color: rgb(0, 85, 138, 0.1);
}
</style>
