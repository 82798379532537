<template>
  <section id="quill-container" class="rich-editor">
    <quill-editor
      ref="quillEditor"
      v-model="value"
      :options="editorOption"
      class="hover-highlight active"
      @change="updateValue($event)"
      @input="onInput"
    >
    </quill-editor>
  </section>
</template>

<script>
import Vue from 'vue'
import { mapGetters } from 'vuex'
import VueQuillEditor, { Quill } from 'vue-quill-editor'
// import Delta from 'quill-delta'
import ImageUploader from 'quill-image-uploader'
import ImageResize from 'quill-image-resize' // ne pas utiliser le quill-image-resize-module ne marche pas // exist pour drop dans le rich editor
import i18n from './i18n.js'
import QuillButton from '~/plugins/quill-button.js'

import ProcLink from '~/plugins/quill-insertButton.js'
import ImgAdd from '~/plugins/quill-addImage.js'

import 'quill-mention'
import 'quill/dist/quill.core.css' // import styles
import 'quill/dist/quill.snow.css' // for snow theme
import 'quill/dist/quill.bubble.css'

Quill.register('formats/proc-link', ProcLink, true) // for bubble theme
Quill.register('formats/img-add', ImgAdd)
Quill.register('modules/imageUploader', ImageUploader)
Quill.register('modules/imageResize', ImageResize)
Quill.register('modules/quillButton', QuillButton)

Vue.use(VueQuillEditor /* { default global options } */)

export default {
  name: 'QuillRichEditor',
  props: {
    placeholder: {
      type: String,
      default: () => ''
    },
    data: {
      type: Object,
      default: () => {}
    },
    saveRichEditor: {
      // attend une fonction 'promisify' pour valider l'enregistrement dans la db
      type: Function,
      default: () => () => {
        alert('Missing "saveRichEditor" function')
      }
    },
    uploadPicture: {
      // attend une fonction 'promisify' qui retourne l'URL de l'image aprés son storage
      type: Function,
      default: () => () => {
        alert('Missing "uploadPicture" function')
      }
    }
  },
  data () {
    return {
      url: '',
      value: this.htmlValue,
      syncDelta: undefined,
      interval: null,
      previousDelta: null,
      delta: null,
      quillRich: {},
      editorOption: {
        theme: 'snow',
        modules: {
          toolbar: {
            container: [
              ['bold', 'italic', 'underline', 'strike'], // toggled buttons
              ['blockquote', 'code-block'],

              [{ header: 1 }, { header: 2 }], // custom button values
              [{ list: 'ordered' }, { list: 'bullet' }],
              [{ script: 'sub' }, { script: 'super' }], // superscript/subscript
              [{ indent: '-1' }, { indent: '+1' }], // outdent/indent
              [{ direction: 'rtl' }], // text direction

              [{ size: ['small', false, 'large', 'huge'] }], // custom dropdown
              [{ header: [1, 2, 3, 4, 5, 6, false] }],

              [{ color: [] }, { background: [] }], // dropdown with defaults from theme
              [{ font: [] }],
              [{ align: [] }],

              ['clean'],
              ['image']
            ],
            handlers: {}
          },
          imageResize: {},
          imageUploader: {
            upload: (file) => {
              return new Promise((resolve, reject) => {
                this.uploadPicture(file).then((result) => {
                  resolve(result)
                })
              })
            }
          }
        },
        bounds: '#quill-container'
      }
    }
  },
  i18n,
  computed: {
    ...mapGetters({
      currentMembers: 'workspace/getCurrentMembers',
      currentTeams: 'team/getTeams'
    }),
    editor () {
      return this.$refs.quillEditor.quill
    }
  },
  beforeMount () {
  },
  mounted () {
    this.init()
  },
  beforeDestroy () {
    if (this.editor) {
      clearInterval(this.interval)
      this.editor.getModule('toolbar').container.removeEventListener('mousedown', (e) => {
        e.preventDefault()
      })
    }
  },
  methods: {
    async init () {
      if (this.editor) {
        if (this.data && this.data.contentData) {
          await this.editor.setContents(this.data.contentData)

          this.previousDelta = this.editor.getContents()
        }
        const autoSaveTimer = 5000
        this.interval = setInterval(this.isChanges, autoSaveTimer)
        this.editor.getModule('toolbar').container.addEventListener('mousedown', (e) => {
          e.preventDefault()
        })
      }
    },
    _formatImage (delta) {
      this.$_.forEach(delta.ops, (d, index) => {
        if (typeof d.insert === 'object' && d.insert.image && typeof d.insert.image === 'object') {
          const image = d.insert.image.url
          const alt = d.insert.image.alt
          delta.ops.splice(index, 1, { insert: { image }, attributes: { alt } }) // add id to attributes
        }
      })
    },
    async isChanges () {
      const self = this
      if (self.delta && !self.$_.isEqual(self.previousDelta, self.delta)) {
        this._formatImage({ ...self.delta })
        await this.saveRichEditor({ docId: self.data._id, richEditor: { ...self.delta } }).then(() => {})
          .catch((error) => {
            this.$swal({
              icon: 'error',
              titleText: error
            })
          })
        self.previousDelta = self.delta
      }
    },
    async save () {
      await this.isChanges()
    },
    updateValue ({ quill, html, text }) {
      this.delta = this.editor.getContents()
    },
    onInput (e) {
    },
    chose (item) {
      window.alert(JSON.stringify(item, null, 4))
    }
  }
}
</script>
<style scoped>
/* :deep(.ql-container.ql-snow) {
  border: none
}
:deep(.ql-toolbar.ql-snow) {
  background-color: white;
} */
:deep(.ql-container.ql-snow) {
  border: none
}
:deep(.ql-toolbar.ql-snow) {
  background-color: white;
}
.ql-mention-list-container {
width: 270px;
border: 1px solid #f0f0f0;
border-radius: 4px;
background-color: #fff;
box-shadow: 0 2px 12px 0 rgba(30, 30, 30, .08);
z-index: 9001;
overflow: auto
}

.ql-mention-loading {
line-height: 44px;
padding: 0 20px;
vertical-align: middle;
font-size: 16px
}

.ql-mention-list {
list-style: none;
margin: 0;
padding: 0;
overflow: hidden
}

.ql-mention-list-item {
cursor: pointer;
line-height: 44px;
font-size: 16px;
padding: 0 20px;
vertical-align: middle
}

.ql-mention-list-item.disabled {
cursor: auto
}

.ql-mention-list-item.selected {
background-color: #d3e1eb;
text-decoration: none
}

.mention {
height: 24px;
width: 65px;
border-radius: 6px;
background-color: #d3e1eb;
padding: 3px 0;
margin-right: 2px;
user-select: all
}

.mention>span {
margin: 0 3px
}

</style>
