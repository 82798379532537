import Vue from 'vue'

// state format is { [objectType]: {[objectId]: [alerts]} }
export const state = () => ({
  project: {},
  deliverable: {},
  task: {},
  phase: {},
  course: {},
  module: {},
  sequence: {},
  mechanism: {},
  learningActivity: {}
})

export const getters = {
  get (state) {
    return state
  }
}

export const actions = {
  fetchForObjectId ({ commit }, { id, type }) {
    return new Promise((resolve, reject) => {
      this.$edtake.alert.getForObject({ id, type })
        .then((d) => {
          commit('SET', { id, type, alerts: d })
          resolve(d)
        })
        .catch((err) => {
          console.error('ERROR', err)
          reject(err)
        })
    })
  },
  wsUpdate ({ commit }, payload) {
    const { obj, insert, remove } = payload.alerts
    insert.forEach((alert) => {
      commit('WS_ADDONE', { id: obj._id, type: obj.__t, alert })
    })
    remove.forEach((alert) => {
      commit('WS_REMOVEONE', { id: obj._id, type: obj.__t, alert })
    })
  }
}

export const mutations = {
  SET (state, { id, type, alerts }) {
    if (!state[type]) {
      state[type] = {}
    }
    // Vue reactive set so we do not need to reassign the whole object
    Vue.set(state[type], id, alerts)
  },
  WS_ADDONE (state, { id, type, alert }) {
    state[type][id] = [...state[type][id], alert]
  },
  WS_REMOVEONE (state, { id, type, alert }) {
    state[type][id] = state[type][id].filter(a => a._id !== alert)
  }
}
