const i18n = {
  messages: {
    fr: {
      search: 'Rechercher',
      required: 'Champs requis'
    },
    en: {
      search: 'Search',
      required: 'Required field'
    }
  }
}
module.exports = i18n
