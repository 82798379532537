/* eslint-disable prefer-promise-reject-errors */
/* eslint-disable no-console */
/* eslint-disable camelcase */
import _ from 'lodash'

const taskSchema = {
  _id: String,
  priority: String,
  deleted: Boolean,
  name: String,
  status: String,
  archived: Boolean,
  tags: Array,
  parent: Object,
  parentType: String,
  workspace: String,
  members: Array,
  predecessors: Array,
  createdAt: String,
  __t: String,
  checkList: Array,
  milestones: Array,
  __v: Number,
  id: String
}

export const state = () => ({
  tasks: [],
  lastUpdate: undefined
})

export const getters = {
  get (state) {
    return state.tasks
  },
  lastUpdate (state) {
    return state.lastUpdate
  },
  getOne: state => (id) => {
    return _.find(state.tasks, (task) => { return task._id === id })
  }
}

export const actions = {
  fetch ({ dispatch, commit }, { $queryParameters = {} } = { $queryParameters: {} }) {
    return new Promise((resolve, reject) => {
      this.$edtake.task.get({ $queryParameters })
        .then((d) => {
          commit('SET', d)
          if (d.children) {
            dispatch('projectItem/emitUpdateMutation', { arr: d.children }, { root: true })
          }
          resolve(d)
        })
        .catch((err) => {
          console.error('ERROR', err)
          reject('AN_ERROR_OCCURED')
        })
    })
  },
  fetchOne ({ dispatch, commit }, { id, $queryParameters = {} }) {
    return new Promise((resolve, reject) => {
      this.$edtake.task.getOne({ id, $queryParameters })
        .then((d) => {
          resolve(d)
          if (d.children) {
            dispatch('projectItem/emitUpdateMutation', { arr: d.children }, { root: true })
          }
          commit('UPDATE_ONE', d)
        })
        .catch((err) => {
          console.error('ERROR', err)
          reject('AN_ERROR_OCCURED')
        })
    })
  },
  create ({ dispatch, commit, rootGetters }, { body, parentId }) {
    return new Promise((resolve, reject) => {
      const workspace = rootGetters['workspace/getCurrentId']
      body.workspace = workspace
      this.$edtake.task.create({ body, parentId })
        .then((d) => {
          commit('CREATE', d)
          dispatch('project/_createdChildren', d, { root: true })
          resolve(d)
        })
        .catch((err) => {
          console.error('ERROR', err)
          reject('AN_ERROR_OCCURED')
        })
    })
  },
  fetchChildren ({ dispatch }, { id }) {
    return new Promise((resolve, reject) => {
      this.$edtake.task.getChildren({ id })
        .then(async (d) => {
          await dispatch('projectItem/emitUpdateMutation', { arr: d }, { root: true })
          resolve(d)
        })
        .catch((err) => {
          console.error('ERROR', err)
          reject('AN_ERROR_OCCURED')
        })
    })
  },
  setStatus ({ commit, dispatch }, { id, parentType, status }) {
    return new Promise((resolve, reject) => {
      this.$edtake.task.setStatus({
        id,
        body: { status }
      })
        .then((d) => {
          commit('UPDATE_ONE', d)
          if (d.parentProject) {
            dispatch(`${d.parentProject.__t}/_updatedChildren`, d, { root: true })
          }
          resolve(d)
        })
        .catch((err) => {
          console.error('ERROR', err)
          reject(err)
        })
    })
  },
  update ({ commit, dispatch }, { id, body }) {
    return new Promise((resolve, reject) => {
      this.$edtake.task.update({ id, body })
        .then((d) => {
          commit('UPDATE_ONE', d)
          if (d.parentProject) {
            dispatch(`${d.parentProject.__t}/_updatedChildren`, d, { root: true })
          }
          resolve(d)
        })
        .catch((err) => {
          console.error('ERROR', err)
          reject('AN_ERROR_OCCURED')
        })
    })
  },
  remove ({ dispatch, commit }, { id, item }) {
    return new Promise((resolve, reject) => {
      this.$edtake.task.delete({ id })
        .then((d) => {
          commit('REMOVE', id)
          if (item.learningObject) {
            dispatch(`${item.learningObject.__t}/_removeProject`, item, { root: true })
          }
          resolve(d)
        })
        .catch((err) => {
          console.error('ERROR', err)
          reject('AN_ERROR_OCCURED')
        })
    })
  },
  byParent ({ commit }, { id, idMilestone, body }) {
    return new Promise((resolve, reject) => {
      this.$edtake.task.byParent({ id })
        .then((d) => {
          commit('SET', d) // Store refactor - not sure of this one, need to be checked
          resolve(d)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  setMembers ({ commit }, d) {
    commit('SET_MEMBERS', d)
  },
  /* Dispatch from other store */
  _addDocument ({ commit }, d) {
    commit('ADD_DOCUMENT', d)
  },
  _updateDocument ({ commit }, d) {
    commit('UPDATE_DOCUMENT', d)
  },
  _removeDocument ({ commit }, d) {
    commit('REMOVE_DOCUMENT', d)
  },
  _updatedChildren ({ commit }, d) {
    if (Array.isArray(d)) {
      _.forEach(d, e => commit('UPDATED_CHILDREN', e))
    } else {
      commit('UPDATED_CHILDREN', d)
    }
  },
  _update ({ commit }, d) {
    if (Array.isArray(d)) {
      _.forEach(d, e => commit('UPDATE_ONE', e))
    } else {
      commit('UPDATE_ONE', d)
    }
  },
  _fetchIfNotExist ({ state, dispatch }, a) {
    const alreadyExist = a.every(d => state.tasks.some(s => s._id === d._id))
    if (!alreadyExist) {
      dispatch('fetch')
    }
  }
}

export const mutations = {
  SET (state, value) {
    state.tasks = value
    state.lastUpdate = Date.now()
  },
  SET_MEMBERS (state, value) {
    _.forEach(value.ids, (id, $index) => {
      const item = state.tasks.find(item => item._id.toString() === id.toString())
      if (item) {
        Object.assign(item, { ...item, members: value.newObj[$index].members })
        state.tasks = [...state.tasks]
      }
    })
  },
  UPDATE_ONE (state, value) {
    const item = state.tasks.find(item => (item._id && item._id.toString()) === (value._id && value._id.toString()))
    if (item) {
      Object.assign(item, value)
      state.tasks = [...state.tasks] // Force refresh
    } else {
      state.tasks = [...state.tasks, value]
    }
  },
  UPDATE_MANY (state, arr) {
    const expectedIds = _.map(arr, o => o._id)
    const filteredState = _.filter(state.tasks, o => !expectedIds.includes(o._id))
    arr = _.map(arr, (updatedPI) => {
      let item = state.tasks.find(item => (item._id && item._id.toString()) === (updatedPI._id && updatedPI._id.toString()))
      if (item) {
        Object.assign(item, updatedPI)
      } else {
        item = updatedPI
      }
      return item
    })
    state.tasks = [...filteredState, ...arr] // Force refresh
  },
  REMOVE (state, value) {
    state.tasks = state.tasks.filter(task => task._id !== value)
  },
  CREATE (state, value) {
    state.tasks = [...state.tasks, value]
  },
  CLEAR (state) {
    state.tasks = []
    state.lastUpdate = Date.now()
  },
  UPDATED_CHILDREN (state, value) {
    if (value.parentProject) {
      const item = _.find(state.tasks, item => item._id.toString() === value.parentProject._id.toString())
      if (item) {
        const children = _.find(item.children, child => child._id.toString() === value._id.toString())
        if (children) {
          Object.assign(children, value)
          state.tasks = [...state.tasks]
        }
      }
    }
  },
  ADD_DOCUMENT (state, value) {
    const item = state.tasks.find(item => item._id.toString() === value.parent._id.toString())
    console.log('🚀 ~ file: task.js:265 ~ ADD_DOCUMENT ~ item:', item, item.documents)
    if (item) {
      item.documents = [...item.documents, value]
    }
  },
  UPDATE_DOCUMENT (state, value) {
    const item = state.tasks.find((item) => {
      return item._id.toString() === value.parent._id.toString()
    })
    if (item) {
      const documentIdx = item.documents.findIndex(doc => doc._id === value._id)
      item.documents[documentIdx] = Object.assign({}, item.documents[documentIdx], value)
    }
  },
  REMOVE_DOCUMENT (state, value) {
    const item = state.tasks.find(item => item._id.toString() === value.parent._id.toString())
    if (item) {
      item.documents = item.documents.filter(d => d._id !== value._id)
    }
  }
}
