<template>
  <component
    :is="`editable-${type}`"
    ref="editableField"
    :data-tour-id="`${dataTourId}-field`"
    :class="size"
    v-bind="$attrs"
    :edit-right="editRight"
    @input="changeValue"
    @click="$emit('click')"
    @change="onChange"
  >
    <template #default="slotProps">
      <slot name="default" :component-data="slotProps.componentData">
      </slot>
    </template>
  </component>
</template>

<script>
import EditableColor from '~/components/common/EditableFields/EditableColor'
import EditableDatepicker from '~/components/common/EditableFields/EditableDatepicker'
import EditableDatepickerRange from '~/components/common/EditableFields/EditableDatePickerRange'
import EditableNumber from '~/components/common/EditableFields/EditableNumber'
import EditableText from '~/components/common/EditableFields/EditableText'
import EditableRichEditor from '~/components/common/EditableFields/EditableRichEditor'
import EditableRichEditorBubble from '~/components/common/EditableFields/EditableRichEditorBubble'
import EditableList from '~/components/common/EditableFields/EditableList'
import EditableLanguage from '~/components/common/EditableFields/EditableLanguage'
import EditableLanguageMultiple from '~/components/common/EditableFields/EditableLanguageMultiple'
import EditableDraggableList from '~/components/common/EditableFields/EditableDraggableList'
import EditableTags from '~/components/common/EditableFields/EditableTags'
import EditableRadio from '~/components/common/EditableFields/EditableRadio'
import EditableTextarea from '~/components/common/EditableFields/EditableTextarea'
import EditableBoolean from '~/components/common/EditableFields/EditableBoolean'
import EditableBooleanList from '~/components/common/EditableFields/EditableBooleanList'
import EditableCountry from '~/components/common/EditableFields/EditableCountry'
import EditableRating from '~/components/common/EditableFields/EditableRating'
import EditableObjectivesList from '~/components/common/EditableFields/EditableObjectivesList'
import EditableThemesList from '~/components/common/EditableFields/EditableThemesList'
import EditableSwitch from '~/components/common/EditableFields/EditableSwitch'
export default {
  name: 'EditableField',
  components: {
    EditableColor,
    EditableDatepicker,
    EditableDatepickerRange,
    EditableNumber,
    EditableText,
    EditableRichEditor,
    EditableList,
    EditableLanguage,
    EditableLanguageMultiple,
    EditableDraggableList,
    EditableTags,
    EditableRadio,
    EditableTextarea,
    EditableRichEditorBubble,
    EditableBoolean,
    EditableBooleanList,
    EditableCountry,
    EditableRating,
    EditableObjectivesList,
    EditableThemesList,
    EditableSwitch
  },
  props: {
    editRight: {
      type: Boolean,
      required: true
    },
    type: {
      type: String,
      required: true
    },
    hasError: {
      type: Boolean,
      required: false
    },
    inline: {
      type: Boolean
    },
    small: {
      type: Boolean
    },
    medium: {
      type: Boolean
    },
    large: {
      type: Boolean
    },
    dataTourId: {
      type: String
    }
  },
  data () {
    return {
      i18n: undefined
    }
  },
  computed: {
    size () {
      let size = 'small'
      if (this.small) {
        size = 'small'
      } else if (this.inline) {
        size = 'inline'
      } else if (this.medium) {
        size = 'medium'
      } else if (this.large) {
        size = 'large'
      }
      return size
    }
  },
  methods: {
    changeValue (newValue) {
      this.$emit('input', newValue)
    },
    onChange (e) {
      this.$emit('change', e)
    },
    focus () {
      this.$refs.editableField.focus()
    }
  }
}
</script>

<style lang="scss" scoped>
.inline {
  width: max-content;
  max-width: 100%;

  &::after {
    content: "\a0";
  }
}
.small {
  min-width: 50px;
  // width: max-content;
  max-width: 100%;
}
.medium {
  min-width: 100px;
  // width: max-content;
  max-width: 100%;
}
.large {
  min-width: 100px;
  width: 100%;
  max-width: 100%;
}
</style>
