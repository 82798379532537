/* eslint-disable no-console */
// TODO: Store refactor - remove store
export const state = () => ({
  subscription: undefined,
  customerPortal: undefined,
  prices: undefined
})

export const getters = {
  getSubscription (state) {
    return state.subscription
  },
  customerPortalUrl (state) {
    return state.customerPortal
  },
  prices (state) {
    return state.prices
  }
}

export const actions = {
  fetchPrices ({ commit }) {
    return new Promise((resolve, reject) => {
      this.$edtake.stripe.getPrices()
        .then((d) => {
          commit('SET_PRICES', d)
          resolve(d)
        })
        .catch((err) => {
          console.error('ERROR', err)
          reject(err)
        })
    })
  },
  fetchSubscription ({ commit }) {
    return new Promise((resolve, reject) => {
      this.$edtake.stripe.getSubscription()
        .then((d) => {
          commit('SET_SUBSCRIPTION', d)
          resolve(d)
        })
        .catch((err) => {
          console.error('ERROR', err)
          reject(err)
        })
    })
  },
  createSubscription (context, payload) {
    return new Promise((resolve, reject) => {
      this.$edtake.stripe.createSubscription({ body: payload })
        .then((d) => {
          resolve(d)
        })
        .catch((err) => {
          console.error('ERROR', err)
          reject(err)
        })
    })
  },
  previewSubscription (context, payload) {
    return new Promise((resolve, reject) => {
      this.$edtake.stripe.previewSubscription({ body: payload })
        .then((d) => {
          resolve(d)
        })
        .catch((err) => {
          console.error('ERROR', err)
          reject(err)
        })
    })
  },
  getPastDueInvoice () {
    return new Promise((resolve, reject) => {
      this.$edtake.stripe.getPastDueInvoice()
        .then((d) => {
          resolve(d)
        })
        .catch((err) => {
          console.error('ERROR', err)
          reject(err)
        })
    })
  },
  checkoutSuccess (context, sessionId) {
    return new Promise((resolve, reject) => {
      this.$edtake.stripe.checkoutSuccess({ body: { session_id: sessionId } })
        .then((d) => {
          resolve(d)
        })
        .catch((err) => {
          console.error('ERROR', err)
          reject(err)
        })
    })
  },
  checkout (context, { items }) {
    return new Promise((resolve, reject) => {
      this.$edtake.stripe.checkout({ body: { items } })
        .then((d) => {
          this.$evt.log('SubscriptionCheckout')
          resolve(d)
        })
        .catch((err) => {
          console.error('ERROR', err)
          reject(err)
        })
    })
  },
  updateSubscription (context, payload) {
    return new Promise((resolve, reject) => {
      this.$edtake.stripe.updateSubscription({ body: payload })
        .then((d) => {
          this.$evt.log('SubscriptionUpdate')
          resolve(d)
        })
        .catch((err) => {
          console.error('ERROR', err)
          reject(err)
        })
    })
  },
  cancelSubscription (context, payload) {
    return new Promise((resolve, reject) => {
      this.$edtake.stripe.cancelSubscription({ body: payload })
        .then((d) => {
          this.$evt.log('SubscriptionCancel')
          resolve(d)
        })
        .catch((err) => {
          console.error('ERROR', err)
          reject(err)
        })
    })
  },
  previewPrice (context, payload) {
    return new Promise((resolve, reject) => {
      this.$edtake.stripe.previewPrice({ body: payload })
        .then((d) => {
          resolve(d)
        })
        .catch((err) => {
          console.error('ERROR', err)
          reject(err)
        })
    })
  },
  fetchCustomerPortal ({ commit }, $queryParameters) {
    return new Promise((resolve, reject) => {
      this.$edtake.stripe.customerPortal({ $queryParameters })
        .then((d) => {
          commit('SET_CUSTOMER_PORTAL', d.url)
          resolve(d)
        })
        .catch((err) => {
          console.error('ERROR', err)
          reject(err)
        })
    })
  }
}

export const mutations = {
  SET_SUBSCRIPTION (state, subscription) {
    state.subscription = subscription
  },
  SET_CUSTOMER_PORTAL (state, customerPortal) {
    state.customerPortal = customerPortal
  },
  SET_PRICES (state, prices) {
    state.prices = prices
  }
}
