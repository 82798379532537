import {
  OpenIDConnectScheme,
  IdToken,
  ConfigurationDocument,
  ExpiredAuthSessionError
} from '@nuxtjs/auth-next/dist/runtime.js'

function encodeQuery (queryObject) {
  return Object.entries(queryObject).filter(([_key, value]) => typeof value !== 'undefined').map(([key, value]) => encodeURIComponent(key) + (value != null ? '=' + encodeURIComponent(value) : '')).join('&')
}

function removeTokenPrefix (token, tokenType) {
  if (!token || !tokenType || typeof token !== 'string') {
    return token
  }
  return token.replace(tokenType + ' ', '')
}

const CUSTOM_DEFAULTS = {
  name: 'customOpenIDConnect',
  responseType: 'code',
  grantType: 'authorization_code',
  scope: '',
  idToken: {
    property: 'id_token',
    maxAge: 1800,
    prefix: '_id_token.',
    expirationPrefix: '_id_token_expiration.'
  },
  codeChallengeMethod: 'S256'
}

export default class CustomOpenIDConnectScheme extends OpenIDConnectScheme {
  constructor ($auth, options, ...defaults) {
    super($auth, options, ...defaults, CUSTOM_DEFAULTS)
    this.idToken = new IdToken(this, this.$auth.$storage)
    this.configurationDocument = new ConfigurationDocument(this, this.$auth.$storage)
  }

  async refreshTokens () {
    // Get refresh token
    const refreshToken = this.refreshToken.get()

    // Refresh token is required but not available
    if (!refreshToken) {
      return
    }

    // Get refresh token status
    const refreshTokenStatus = this.refreshToken.status()

    // Refresh token is expired. There is no way to refresh. Force reset.
    if (refreshTokenStatus.expired()) {
      this.$auth.reset()

      throw new ExpiredAuthSessionError()
    }

    // Delete current token from the request header before refreshing
    this.requestHandler.clearHeader()

    const response = await this.$auth
      .request({
        method: 'post',
        url: this.options.endpoints.token,
        baseURL: '',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        },
        data: encodeQuery({
          refresh_token: removeTokenPrefix(
            refreshToken,
            this.options.token.type
          ),
          scopes: this.scope,
          client_id: this.options.clientId + '',
          redirect_uri: this.redirectURI,
          grant_type: 'refresh_token'
        })
      })
      .catch((error) => {
        this.$auth.callOnError(error, { method: 'refreshToken' })
        return Promise.reject(error)
      })

    this.updateTokens(response)

    return response
  }
}
