<template lang="html">
  <v-app>
    <Maintenance />
    <Navigation>
      <WaitingForPayment v-if="WAITING_FOR_PAYMENT_BAND" />
      <TrialBand :workspace="workspace" />
      <v-main data-layout="workspace">
        <div
          v-if="workspace?.license?.subscriptionStatus && (workspace?.license?.subscriptionStatus !== $enums.stripe.subscriptionStatus.inactive)"
        >
          <FormErrorDialog />
          <transition
            name="nuxtTransition"
            appear
            appear-active-class="component-enter-active"
            leave-active-class="component-leave-active"
          >
            <nuxt />
          </transition>
          <Snackbar />
          <CreationBox ref="CreationBox" />
          <ApplicationUpdate />
          <EdtakeDocumentViewer />
          <ProposeChangePassword />
          <DisableSmallDevices />
        </div>
        <featureNotActivated v-else />
      </v-main>
    </Navigation>
  </v-app>
</template>

<script lang="js">
import { mapGetters, mapActions } from 'vuex'
import Navigation from '~/components/partials/Navigation'
import CreationBox from '~/components/widget/CreationBox'
import ApplicationUpdate from '~/components/applicationUpdate'
import EdtakeDocumentViewer from '~/components/widget/EdtakeDocumentViewer'
import featureNotActivated from '~/components/featureNotActivated/workspace'
import ProposeChangePassword from '~/components/common/ProposeChangePassword'
import WaitingForPayment from '~/components/workspace/waitingForPaymentBand'
import TrialBand from '~/components/workspace/trialBand'
import Maintenance from '~/components/common/Maintenance'

export default {
  name: 'WorkspaceLayout',
  components: {
    Navigation,
    CreationBox,
    ApplicationUpdate,
    EdtakeDocumentViewer,
    featureNotActivated,
    ProposeChangePassword,
    WaitingForPayment,
    TrialBand,
    Maintenance
  },
  middleware: ['workspace', 'authorization'],
  data () {
    return {
      socket: null,
      socketStatus: {}
    }
  },
  head () {
    return {
      title: this.$nuxt.$te(`headTitle.${this.$nuxt.$route.name}`) ? this.$nuxt.$t(`headTitle.${this.$nuxt.$route.name}`) : 'edtake'
    }
  },
  computed: {
    ...mapGetters({
      user: 'user/get',
      workspace: 'workspace/getCurrent',
      WAITING_FOR_PAYMENT_BAND: 'remoteConfig/getWaitingForPaymentBand',
      apiToken: 'authorization/getJWT'
    })
  },
  watch: {
    getLocale () {
      window.location.reload()
    }
  },
  beforeMount () {
    this.init()
  },
  mounted () {
    this.$socketManager.getSocket('edtake')
    this.$hotkeys(this.$t('hotkeys.creationBox'), (event) => {
      event.preventDefault()
      this.$nuxt.$emit('open-creationBox')
    })
    this.$hotkeys(this.$t('hotkeys.home'), (event) => {
      event.preventDefault()
      this.$router.push(`/${this.$enums.routePrefix.workspace}/${this.$route.params.workspace}`)
    })
  },
  methods: {
    ...mapActions({
      fetchTeams: 'team/fetch',
      fetchInokufuLicenses: 'inokufu/fetchLicenses'
    }),
    init () {
      this.fetchTeams()
      this.fetchInokufuLicenses()
    }
  }
}
</script>
