const i18n = {
  messages: {
    fr: {
      maxLengthAlert: 'Nombre de caractères limité à ',
      required: 'Texte requis'
    },
    en: {
      maxLengthAlert: 'Number of characters limited to ',
      required: 'Require Text'
    }
  }
}
module.exports = i18n
