<template>
  <div class="px-10">
    <v-row v-if="editRight" class="d-flex justify-space-between align-baseline mx-1">
      <v-switch
        :value="onDrag"
        inset
        :label="$t('drag')"
        @change="draggableEnd"
      ></v-switch>
      <section class="d-flex align-center" style="gap: 8px">
        <edtk-button
          data-test="button-new-scene-overview"
          :disabled="onDrag"
          small
          @click="addNewScene"
        >
          {{ $t('createScene') }}
        </edtk-button>
        <Presentation :storyboard="storyboard" />
        <Diagram :scenes="storyboard.scenes" />
      </section>
    </v-row>
    <v-row class="justify-center pb-5" style="height:calc(100vh - 190px); overflow-y: auto; overflow-x:hidden;">
      <draggable
        v-model="cloneScenes"
        :disabled="!onDrag"
        class="overviewGrid"
      >
        <div
          v-for="scene in cloneScenes"
          :key="scene._id"
          data-test="list-edit-scene-overview"
        >
          <v-hover v-slot="{ hover }" :disabled="onDrag">
            <v-card
              width="285"
              height="275"
              class="ma-0 pa-0"
              data-test="card-hover-scene-overview"
            >
              <v-img
                height="200"
                :src="scene.properties.image"
              >
              </v-img>
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title class="font-weight-bold primary--text ma-0" style="height: 25px">
                    <span v-if="displaySceneNumbering">
                      {{ scene.order }}
                    </span>
                    <span v-if="displaySceneNumbering && scene.properties.title" class="mx-2">-</span>
                    <span>
                      {{ scene.properties.title }}
                    </span>
                  </v-list-item-title>
                  <v-list-item-subtitle style="height: 25px">
                    {{ calculateKpis('dialogue', scene.script ) }}
                    <v-icon class="brownish-grey mr-4 ml-1">
                      mdi-chat-outline
                    </v-icon>
                    {{ calculateKpis('note', scene.script ) }}
                    <v-icon class="brownish-grey ml-1">
                      mdi-lightbulb-on-outline
                    </v-icon>
                    <v-icon v-if="displayDuration" class="brownish-grey mr-1" style="margin-left: 84px;">
                      mdi-clock-outline
                    </v-icon>
                    <span v-if="displayDuration">
                      {{ calculateDuration(scene.properties.duration) }}
                    </span>
                    <!-- {{ calculateKpis('interaction', scene.script ) }}
                    <v-icon class="brownish-grey mr-4 ml-1">
                      mdi-gesture-tap
                    </v-icon> -->
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <v-overlay
                absolute
                :value="onDrag"
                opacity="0.6"
                class="handle"
              >
                <div class="text-center">
                  <h4>
                    {{ $t('dragOverlay') }}
                  </h4>
                </div>
              </v-overlay>
              <v-expand-transition>
                <v-card v-if="hover" class="pa-0 ma-0 v-card--reveal" height="275">
                  <v-card-title class="primary--text font-weight-bold pt-2" style="height:35px; word-break: normal;">
                    <span v-if="displaySceneNumbering">
                      {{ scene.order }}
                    </span>
                    <span v-if="displaySceneNumbering && scene.properties.title" class="mx-2">-</span>
                    <span>
                      {{ scene.properties.title }}
                    </span>
                  </v-card-title>
                  <v-card-text
                    class="my-2"
                    style="height:180px; overflow-y: auto;"
                    v-html="scene.properties.description"
                  ></v-card-text>
                  <v-card-actions class="d-flex justify-space-between" style="height:40px">
                    <edtk-button
                      class="ma-2"
                      data-test="button-edit-scene-overview"
                      small
                      rounded
                      color="primary"
                      @click="editScene(scene._id)"
                    >
                      {{ $t('editScene') }}
                    </edtk-button>
                    <div class="d-flex align-center">
                      <v-tooltip bottom>
                        <template #activator="{ on, attrs }">
                          <span
                            v-bind="attrs"
                            v-on="on"
                          >
                            <edtk-button-icon
                              data-test="button-duplicate-scene-overview"
                              icon="content_copy"
                              icon-size="2xl"
                              :disabled="!editRight"
                              color="secondary"
                              @click="dialogDuplicateScene(scene._id)"
                            ></edtk-button-icon>
                          </span>
                        </template>
                        <span>{{ $t('duplicateScene') }}</span>
                      </v-tooltip>
                      <v-tooltip bottom>
                        <template #activator="{ on, attrs }">
                          <span
                            v-bind="attrs"
                            v-on="on"
                          >
                            <edtk-button-icon
                              data-test="button-delete-scene-overview"
                              icon="delete"
                              icon-size="2xl"
                              :disabled="!editRight"
                              color="secondary"
                              class="mr-2"
                              @click="deleteScene(scene._id, scene.properties.title)"
                            ></edtk-button-icon>
                          </span>
                        </template>
                        <span>{{ $t('deleteScene') }}</span>
                      </v-tooltip>
                    </div>
                  </v-card-actions>
                </v-card>
              </v-expand-transition>
            </v-card>
          </v-hover>
        </div>
      </draggable>
    </v-row>
    <v-dialog
      v-model="dialog"
      persistent
      width="500px"
      @click:outside="closeDialog"
    >
      <v-card class="bg-white-smoke pa-0 ma-0">
        <v-card-title class="white d-flex justify-space-between align-center">
          <span class="page-title font-weight-semibold ma-0 pa-0">{{ $t('duplicateScene') }}</span>
          <edtk-button-icon
            icon="close"
            icon-size="3xl"
            color="secondary"
            @click="closeDialog"
          ></edtk-button-icon>
        </v-card-title>
        <v-card-text class="pt-4">
          <EditableField
            v-model="sceneToDuplicate.properties.title"
            :edit-right="true"
            :placeholder="$t('sceneTitle')"
            large
            tag-type="h4"
            type="text"
          />
          <h2 class="page-subtitle">
            {{ $t('duplicateChoice') }} ?
          </h2>

          <v-list>
            <v-list-item>
              <v-list-item-action>
                <v-checkbox
                  v-model="duplicateVisual"
                />
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>
                  {{ $t('visual') }}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item>
              <v-list-item-action>
                <v-checkbox
                  v-model="duplicateDialogs"
                />
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>
                  {{ $t('dialog') }}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item>
              <v-list-item-action>
                <v-checkbox
                  v-model="duplicateNotes"
                />
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>
                  {{ $t('note') }}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <!-- <v-list-item>
              <v-list-item-action>
                <v-checkbox
                  v-model="duplicateActivities"
                />
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>
                  {{ $t('activity') }}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item> -->
          </v-list>
        </v-card-text>
        <v-card-actions class="justify-center">
          <edtk-button
            @click="duplicateScene"
          >
            {{ $t('duplicateScene') }}
          </edtk-button>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import draggable from 'vuedraggable'
import _ from 'lodash'
import ObjectID from 'bson-objectid'
import Presentation from '../slides/Displayer'
import Diagram from '../diagram/Displayer.vue'

export default {
  components: { draggable, Presentation, Diagram },
  props: {
    storyboard: {
      type: Object,
      default: () => {}
    },
    currentSceneId: {
      type: String,
      default: () => ''
    },
    editRight: {
      type: Boolean,
      required: true,
      default: false
    }
  },
  data () {
    return {
      onDrag: false,
      dialog: false,
      duplicateVisual: true,
      duplicateDialogs: true,
      duplicateNotes: true,
      // duplicateActivities: true,
      sceneToDuplicate: { properties: { title: '' } },
      cloneScenes: this.storyboard.scenes
    }
  },
  i18n: {
    messages: {
      fr: {
        overviewTab: 'Storyboard',
        summaryTab: 'Synthèse',
        catalogTab: 'Catalogue',
        createScene: 'Créer une scène',
        editScene: 'Détails',
        deleteScene: 'Supprimer la scène',
        duplicateScene: 'Dupliquer la scène',
        sceneTitle: 'Titre de la scène',
        duplicateChoice: 'Que souhaitez-vous dupliquer ',
        visual: 'Le visuel',
        dialog: 'Les dialogues',
        note: 'Les notes',
        activity: 'Les activités',
        drag: 'Organiser les scènes',
        dragOverlay: 'Glisser pour organiser',
        displaySlides: 'Présentation'
      },
      en: {
        overviewTab: 'Storyboard',
        summaryTab: 'Summary',
        catalogTab: 'Catalog',
        createScene: 'Create scene',
        editScene: 'Details',
        deleteScene: 'Delete the scene',
        duplicateScene: 'Duplicate scene',
        sceneTitle: 'Scene title',
        duplicateChoice: 'What do you want to duplicate ',
        visual: 'the visual',
        dialog: 'dialogs',
        note: 'notes',
        activity: 'activities',
        drag: 'Update scenes order',
        dragOverlay: 'Drag and drop',
        displaySlides: 'Display'
      }
    }
  },
  computed: {
    displayDuration () {
      return this.storyboard?.settings?.duration?.displayDuration
    },
    displaySceneNumbering () {
      return this.storyboard?.settings?.sceneNumbering
    }
  },
  watch: {
    storyboard: {
      deep: true,
      handler (n, o) {
        this.cloneScenes = this.storyboard.scenes
      }
    }
  },
  methods: {
    draggableEnd () {
      this.onDrag = !this.onDrag
      if (!this.onDrag && !_.isEqual(this.cloneScenes, this.storyboard.scenes)) {
        this.$emit('updateSceneOrder', this.cloneScenes)
      }
    },
    calculateKpis (type, script) {
      const actionType = _.filter(script, (action) => { return action.type === type })
      return actionType.length
    },
    calculateDuration (duration) {
      return this.$dayjs.duration(duration, 'seconds').format('mm:ss')
    },
    editScene (sceneId) {
      this.$emit('update:currentSceneId', sceneId)
    },
    addNewScene () {
      this.$emit('addNewScene')
    },
    deleteScene (sceneId, sceneTitle) {
      this.$emit('deleteScene', { sceneId, sceneTitle })
    },
    dialogDuplicateScene (sceneId) {
      const sceneToDuplicate = this.storyboard.scenes.find(scene => scene._id === sceneId)
      this.sceneToDuplicate = _.cloneDeep(sceneToDuplicate)
      this.dialog = true
    },
    closeDialog () {
      this.dialog = false
      this.duplicateVisual = true
      this.duplicateDialogs = true
      this.duplicateNotes = true
      // this.duplicateActivities = true
      this.sceneToDuplicate = { properties: { title: '' } }
    },
    duplicateScene () {
      const newId = ObjectID().toHexString()
      const newOrder = this.storyboard.scenes.length > 0 ? this.storyboard.scenes.map(e => e.order).reduce((a, b) => Math.max(a, b)) : 0
      const payload = _.cloneDeep(this.sceneToDuplicate)
      payload.order = newOrder + 1
      payload._id = newId
      payload.script = _.map(payload.script, (p) => {
        const newActionId = ObjectID().toHexString()
        return { ...p, _id: newActionId }
      })

      if (!this.duplicateDialogs) {
        payload.script = _.filter(payload.script, function (o) { return o.type !== 'dialogue' })
      }
      if (!this.duplicateNotes) {
        payload.script = _.filter(payload.script, function (o) { return o.type !== 'note' })
      }
      // if (!this.duplicateActivities) {
      //   payload.script = _.filter(payload.script, function (o) { return o.type !== 'interaction' })
      // }

      if (this.duplicateVisual) {
        payload.canvas = _.map(payload.canvas, (p) => {
          const newShapeId = ObjectID().toHexString()
          const newName = p.type === 'custom' ? `${p.config.customType}_${newId}` : `${p.type}_${newId}`
          const newConfig = { ...p.config, id: newShapeId, name: newName }
          return { ...p, config: newConfig }
        })
      } else if (!this.duplicateVisual) {
        payload.properties.canvas = []
        payload.properties.image = null
      }

      this.$emit('duplicateScene', payload)
      this.closeDialog()
    }
  }
}
</script>

<style scoped>
.v-card--reveal {
  align-items: center;
  bottom: 0;
  justify-content: center;
  position: absolute;
  width: 100%;

}
@media screen and (min-width: 700px) {
  .overviewGrid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 16px 26px;
    grid-auto-rows: minmax(100px, auto);
  }
}
@media screen and (min-width: 1000px) {
  .overviewGrid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 16px 26px;
    grid-auto-rows: minmax(100px, auto);
  }
}
@media screen and (min-width: 1300px) {
  .overviewGrid {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 16px 26px;
    grid-auto-rows: minmax(100px, auto);
  }
}
@media screen and (min-width: 1650px) {
  .overviewGrid {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    grid-gap: 16px 26px;
    grid-auto-rows: minmax(100px, auto);
  }
}
@media screen and (min-width: 1920px) {
  .overviewGrid {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    grid-gap: 10px;
    grid-auto-rows: minmax(100px, auto);
  }
}
</style>
