<template>
  <section>
    <span v-if="!editRight">
      <slot name="default" :componentData="value">
        <v-tooltip v-if="tooltipLevel" bottom>
          <template #activator="{ on, attrs }">
            <div
              v-bind="attrs"
              v-on="on"
            >
              <v-rating
                :value="value"
                color="primary"
                length="4"
                size="14"
                readonly
              ></v-rating>
            </div>
          </template>
          <span>{{ tooltipLevel[mutableModel] }}</span>
        </v-tooltip>
        <v-rating
          v-else
          :value="value"
          color="primary"
          length="4"
          size="14"
          readonly
        >
        </v-rating>
      </slot>
    </span>
    <span
      v-else
    >
      <v-rating
        :value="mutableModel"
        hover
        length="4"
        size="14"
        @input="updateValue"
      >
        <template v-if="tooltipLevel" #item="props">
          <v-tooltip bottom>
            <template #activator="{ on, attrs }">
              <span v-bind="attrs" v-on="on">
                <v-icon
                  color="primary"
                  size="14"
                  @click="props.click"
                >
                  {{ props.isFilled ? 'mdi-star' : 'mdi-star-outline' }}
                </v-icon>
              </span>
            </template>
            <span>{{ tooltipLevel[props.index+1] }}</span>
          </v-tooltip>
        </template>
        <template v-else #items="props">
          <v-icon
            color="primary"
            @click="props.click"
          >
            {{ props.isFilled ? 'mdi-star' : 'mdi-star-outline' }}
          </v-icon>
        </template>
      </v-rating>
    </span>
    <span
      v-if="$v.mutableModel.$error"
      class="form_error"
    >
      {{ $t('errors.input.INVALID') }} - {{ attrsInformation }}
    </span>
  </section>
</template>

<script>
import { required } from 'vuelidate/lib/validators'
import i18n from './i18n.js'
export default {
  name: 'EditableRating',
  props: {
    editRight: {
      type: Boolean,
      required: true,
      default: false
    },
    value: {
      type: Number,
      default: () => undefined
    },
    options: {
      type: Array,
      default: () => []
    },
    tooltipLevel: {
      type: Array
    },
    customValidation: {
      type: Object,
      default: () => {}
    }
  },
  i18n,
  validations () {
    return {
      mutableModel: {
        // Adding all custom validations to the mutableModel
        ...this.customValidation ? Object.keys(this.customValidation.controls).map((key) => { return this.customValidation.controls[key] }) : {},
        required: this.$attrs.required || this.$attrs.required === '' ? required : {}
      }
    }
  },
  data () {
    return {
      mutableModel: this.value,
      mutableOptions: Object.assign({}, this.options)
    }
  },
  computed: {
    attrsInformation () {
      const errorMessage = []
      if ((this.$attrs.required || this.$attrs.required === '') && !this.$v.mutableModel.required) {
        errorMessage.push(`${this.$t('required')}`)
      }
      Object.keys(this.customValidation ? this.customValidation.controls : {}).forEach((key) => {
        if (!this.$v.mutableModel[key]) {
          errorMessage.push(this.customValidation.errors[key])
        }
      })

      return errorMessage.join(' - ')
    }
  },
  watch: {
    value (n, o) {
      this.mutableModel = this.value
    }
  },
  methods: {
    updateValue (e) {
      this.mutableModel = e
      this.$v.mutableModel.$touch()
      if (!this.$v.mutableModel.$error) {
        this.$emit('input', this.mutableModel)
      }
    }
  }
}
</script>
