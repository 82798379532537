<template>
  <div>
    <edtk-button
      small
      rounded
      @click="displayDialog()"
    >
      {{ $t('download') }}
    </edtk-button>
    <v-dialog v-model="dialog" persistent width="450px" @click:outside="closeDialog">
      <v-card class="ma-0 pa-0">
        <v-card-title>
          <span class="page-title font-weight-semibold ma-0 pa-0">
            {{ $t(`export${exportType}`) }}
          </span>
          <edtk-button-icon icon="close" icon-size="4xl" color="secondary" class="ml-auto" @click="closeDialog">
          </edtk-button-icon>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text class="bg-white-smoke pt-2">
          <v-row v-if="exportType === 'pptx'">
            <v-col cols="12">
              <h4 class="primary--text my-2">
                {{ $t('fileName') }}
              </h4>
              <EditableField
                v-model="fileName"
                type="text"
                tag-type="h4"
                :edit-right="true"
                :placeholder="$t('fileName')"
                large
              />
            </v-col>
            <v-col cols="12">
              <v-switch
                v-model="displayScriptInPptx"
                inset
                :label="$t('displayScriptInPptx')"
              ></v-switch>
            </v-col>
          </v-row>
          <section v-else>
            <v-row>
              <v-col>
                <h4 class="primary--text mb-2">
                  {{ $t('fileName') }}
                </h4>
                <EditableField
                  v-model="fileName"
                  type="text"
                  tag-type="h4"
                  :edit-right="true"
                  :placeholder="$t('fileName')"
                  large
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <h4 class="primary--text mb-2">
                  {{ $t('exportSize') }} ?
                </h4>
                <v-radio-group
                  v-model="exportSize"
                >
                  <v-radio
                    :label="$t('allScenes')"
                    value="fullStoryboard"
                  />
                  <v-radio
                    value="selectedScenes"
                  >
                    <template #label>
                      <div class="d-flex align-center" style="width:350px;">
                        <span style="width:80px">
                          {{ $t('selection') }} :
                        </span>
                        <div style="width:270px; max-height:130px; overflow-y: auto; border-bottom: 0.5px solid grey;">
                          <EditableField
                            v-model="selectedScenes"
                            type="tags"
                            :options="scenes"
                            :edit-right="true"
                            :placeholder="exportSize === 'selectedScenes' ? $t('sceneToExport') : ''"
                            parent-container
                            large
                          />
                        </div>
                      </div>
                    </template>
                  </v-radio>
                </v-radio-group>
              </v-col>
            </v-row>
            <v-row v-if="exportType === 'pdf'">
              <v-col>
                <h4 class="primary--text">
                  {{ $t('sceneElementsToExport') }} :
                </h4>
                <v-row>
                  <v-col cols="6">
                    <v-checkbox
                      v-model="sceneElementsToExport"
                      :label="$t('title')"
                      value="title"
                      hide-details
                    />
                    <v-checkbox
                      v-model="sceneElementsToExport"
                      :label="$t('visual')"
                      value="visual"
                      hide-details
                    />
                    <v-checkbox
                      v-model="sceneElementsToExport"
                      :disabled="scenePerPage > 1"
                      :label="$t('script')"
                      value="script"
                      hide-details
                    />
                  </v-col>
                  <v-col cols="6">
                    <v-checkbox
                      v-model="sceneElementsToExport"
                      :label="$t('description')"
                      value="description"
                      hide-details
                    />
                    <v-checkbox
                      v-model="sceneElementsToExport"
                      :disabled="scenePerPage > 1"
                      :label="$t('graphicNotes')"
                      value="graphicNotes"
                      hide-details
                    />
                    <v-checkbox
                      v-model="sceneElementsToExport"
                      :disabled="scenePerPage > 1"
                      :label="$t('editingNotes')"
                      value="editingNotes"
                      hide-details
                    />
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="6">
                    <h4 class="primary--text mb-2">
                      {{ $t('layout') }} :
                    </h4>
                    <v-radio-group v-model="pdfLayout">
                      <v-radio value="portrait" :label="$t('portrait')" />
                      <v-radio value="landscape" :label="$t('landscape')" />
                    </v-radio-group>
                  </v-col>
                  <v-col
                    cols="6"
                    class="d-flex flex-column align-space-between h4"
                  >
                    <h4 class="primary--text mb-2">
                      {{ $t('scenePerPage') }} :
                    </h4>
                    <div style="border-bottom: 0.5px solid grey;">
                      <EditableField
                        v-model="scenePerPage"
                        type="list"
                        :edit-right="true"
                        :options="optionsScenePerPage"
                        :placeholder="$t('scenePerPage')"
                        large
                        parent-container
                      />
                    </div>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
            <v-row v-if="exportType === 'xlsx'">
              <v-col cols="12">
                <h4 class="primary--text mb-2">
                  {{ $t('actionTypeToExport') }} :
                </h4>
                <div class="d-flex">
                  <v-checkbox
                    v-model="actionTypeToExport"
                    class="mr-4"
                    :label="$t('dialogue')"
                    value="dialogue"
                    hide-details
                  />
                  <v-checkbox
                    v-model="actionTypeToExport"
                    class="mr-4"
                    :label="$t('question')"
                    value="question"
                    hide-details
                  />
                  <v-checkbox
                    v-model="actionTypeToExport"
                    class="mr-4"
                    :label="$t('note')"
                    value="note"
                    hide-details
                  />
                </div>
              </v-col>
            </v-row>
          </section>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions class="justify-center">
          <edtk-button @click="download">
            {{ $t('download') }}
          </edtk-button>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-card
      v-for="scene in storyboard.scenes"
      v-show="false"
      :key="scene._id"
    >
      <CanvasDisplayer ref="canvasDisplayer" :scene="scene" :temporary-catalog="storyboard.catalog" @updateSceneImage="updateSceneImage" />
    </v-card>
    <Diagram
      v-if="exportType === 'pdf'"
      :to-export="true"
      pdf-type="portrait"
      :scenes="storyboard.scenes"
      @getDiagramImage="getDiagramImage"
    />
    <Diagram
      v-if="exportType === 'pdf'"
      :to-export="true"
      pdf-type="landscape"
      :scenes="storyboard.scenes"
      @getDiagramImage="getDiagramImage"
    />
    <div v-if="dialog" id="lineToMeasure">
    </div>
  </div>
</template>
<script>
import { Aigle } from 'aigle'
import _ from 'lodash'
import XLSX from 'xlsx'
import JsPDF from 'jspdf'
import 'jspdf-autotable'
import sanitizeHtml from 'sanitize-html'
import Pptxgen from 'pptxgenjs'
import CanvasDisplayer from '../components/canvas/components/Displayer'
import summaryHelper from '../_utilities/summary-helper'
import Diagram from '../diagram/index.vue'

Aigle.mixin(_)

export default {
  components: { CanvasDisplayer, Diagram },
  props: {
    storyboard: {
      type: Object,
      default: () => {}
    },
    exportType: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      menu: false,
      dialog: false,
      fileName: undefined,
      exportSize: 'fullStoryboard',
      selectedScenes: [],
      pdfLayout: 'portrait',
      sceneElementsToExport: ['title', 'description', 'visual', 'script', 'graphicNotes', 'editingNotes'],
      optionsScenePerPage: [1, 2, 4, 6],
      scenePerPage: 1,
      actionTypeToExport: ['dialogue', 'note', 'question'],
      exportIds: true,
      logo: '',
      data: undefined,
      displayScriptInPptx: true,
      diagram: {
        portrait: undefined,
        landscape: undefined
      }
    }
  },
  i18n: {
    messages: {
      fr: {
        description: 'Description',
        graphicNotes: 'Notes graphiques',
        editingNotes: 'Notes de montage',
        fileName: 'Nom du fichier',
        exportxlsx: 'Export Excel',
        exportpdf: 'Export PDF',
        exportpptx: 'Export PowerPoint',
        exportSize: 'Que souhaitez-vous exporter',
        allScenes: 'Toutes les scènes',
        selection: 'Sélection',
        sceneElementsToExport: 'Eléments à exporter',
        sceneToExport: 'Scènes à exporter',
        actionTypeToExport: 'Eléments du script à exporter',
        dialogue: 'Dialogues',
        question: 'Questions',
        note: 'Notes',
        interaction: 'Activités',
        title: 'Titre',
        visual: 'Visuel',
        script: 'Script',
        layout: 'Mise en page',
        portrait: 'Portrait',
        landscape: 'Paysage',
        scenePerPage: 'Scènes par page',
        download: 'Télécharger',
        displayScriptInPptx: 'Afficher les dialogues dans la présentation (une réplique par slide)',
        spokenWords: 'Mots prononcés',
        duration: 'Durée estimée',
        budget: 'Budget estimé',
        scenesNumber: 'Nombre de scènes',
        characters: 'Personnages',
        summary: 'SYNTHESE',
        redirectTo: 'Redirection vers'
      },
      en: {
        description: 'Description',
        graphicNotes: 'Graphic notes',
        editingNotes: 'Editing notes',
        fileName: 'File name',
        exportxlsx: 'Excel export',
        exportpdf: 'PDF export',
        exportpptx: 'PowerPoint export',
        exportSize: 'What would you like to export',
        allScenes: 'All scenes',
        selection: 'Selection',
        sceneElementsToExport: 'Elements to export',
        sceneToExport: 'Scenes to export',
        actionTypeToExport: 'Action type to export',
        dialogue: 'Dialogues',
        question: 'Questions',
        note: 'Notes',
        interaction: 'Activities',
        title: 'Title',
        visual: 'Visual',
        script: 'Script',
        layout: 'Orientation',
        portrait: 'Portrait',
        landscape: 'Landscape',
        scenePerPage: 'Scenes per page',
        download: 'Download',
        displayScriptInPptx: 'Display dialogues in the presentation (one line per slide)',
        spokenWords: 'Spoken words',
        duration: 'Estimated duration',
        budget: 'Estimated budget',
        scenesNumber: 'Number of scenes',
        characters: 'Characters',
        summary: 'SUMMARY',
        redirectTo: 'Redirect to'
      }
    }
  },
  computed: {
    scenes () {
      return this.storyboard.scenes.map((s) => { return { label: s.properties.title, id: s._id } })
    },
    displayDuration () {
      return this.storyboard?.settings?.duration?.displayDuration
    },
    displayVoiceOverBudget () {
      return this.storyboard?.settings?.voiceOver?.displayVoiceOverBudget
    },
    sceneNumbering () {
      return this.storyboard?.settings?.sceneNumbering
    },
    storyboardDuration () {
      if (this.displayDuration && this.storyboard.duration) {
        const minutes = Math.floor(this.storyboard.duration / 60)
        const seconds = Math.round(this.storyboard.duration - minutes * 60)
        return `${this.$t('duration')} : ${('0' + minutes).slice(-2)} min ${('0' + seconds).slice(-2)} sec`
      } else {
        return false
      }
    },
    storyboardBudget () {
      if (this.displayVoiceOverBudget && this.storyboard.voiceOverBudget) {
        return `${this.$t('budget')} :\n\t- Voix-off : ${this.$n(parseFloat(this.storyboard.voiceOverBudget), 'currency')}`
      } else {
        return false
      }
    },
    scenesLength () {
      return `${this.$t('scenesNumber')} : ${this.storyboard.scenes.length}`
    }
  },
  beforeMount () {
    this.convertLogo()
  },
  methods: {
    getSceneTitle (id) {
      const scene = this.storyboard.scenes.find(s => s._id === id)
      if (this.sceneNumbering) {
        return `${scene.order} - ${scene.properties.title}`
      } else {
        return scene.properties.title
      }
    },
    getOptions (options) {
      const res = options.map((o) => {
        let label = `\n\t- ${o.label}\n`
        if (o.goToScene) {
          const sceneTitle = this.getSceneTitle(o.goToScene)
          label = label.concat('', `\t( ${this.$t('redirectTo')} ${sceneTitle} )\n`)
        }
        return label
      })
      return res
    },
    characterList () {
      const res = []
      const list = summaryHelper.assetSummary(this.storyboard.assets, [], this.storyboard.scenes, false)
      Object.keys(list).forEach((asset) => {
        if (this.displayVoiceOverBudget) {
          const budget = (list[asset].numberOfWords * this.storyboard.settings.voiceOver.costPerWord) + this.storyboard.settings.voiceOver.costPerCharacter
          res.push(`${asset} :\n\t- ${this.$t('spokenWords')} : ${list[asset].numberOfWords}\n\t- ${this.$t('budget')} : ${this.$n(parseFloat(budget), 'currency')}`)
        } else {
          res.push(`${asset} :\n\t- ${this.$t('spokenWords')} : ${list[asset].numberOfWords}\n`)
        }
      })
      return `${this.$t('characters')} :\n${res.join('\n')}`
    },
    getImageBase64 (src, scaleX, scaleY) {
      return new Promise((resolve) => {
        const img = new Image()
        img.setAttribute('crossOrigin', 'anonymous')
        img.onload = () => {
          const width = img.width * scaleX
          const height = img.height * scaleY
          const canvas = document.createElement('canvas')
          canvas.width = width
          canvas.height = height
          const ctx = canvas.getContext('2d')
          ctx.drawImage(img, 0, 0, width, height)
          resolve({ data: canvas.toDataURL(), width, height })
        }
        img.onerror = () => {
          resolve(undefined)
        }
        img.src = src
      })
    },
    getCustomShapeImg (customType, scaleX, scaleY, strokeStyle, fillStyle, lineWidth, width, height) {
      const canvas = document.createElement('canvas')
      canvas.width = width + lineWidth * 2
      canvas.height = height + lineWidth * 2
      const ctx = canvas.getContext('2d')
      const config = this.$customShapes.find(e => e.name === customType)
      const drawShape = config.scaledPoints
      ctx.strokeStyle = strokeStyle
      ctx.fillStyle = fillStyle
      ctx.lineWidth = lineWidth
      ctx.beginPath()
      drawShape(ctx, scaleX, scaleY, lineWidth)
      ctx.fill()
      if (lineWidth) {
        ctx.stroke()
      }
      const imgData = canvas.toDataURL()
      return imgData
    },
    getNotes (idx, script) {
      const res = { index: null, notes: [] }
      for (let i = idx; i < script.length; i++) {
        if (script[i].type !== 'note') {
          break
        } else {
          res.index = i
          res.notes.push(script[i].actionData.description)
        }
      }
      return res
    },
    getLineheight (line, padding, width, fontSize) {
      const div = document.getElementById('lineToMeasure')
      div.style.padding = padding
      div.style.width = width
      div.style.fontSize = fontSize
      const data = line.replace(/\n/g, '<br />')
      div.innerHTML = data
      const res = div.offsetHeight
      div.innerHTML = ''
      return res
    },
    async generateStagePreview () {
      await Aigle.forEach(this.$refs.canvasDisplayer, async (item, i) => {
        await this.$refs.canvasDisplayer[i].setSaveStage()
      })
    },
    updateSceneImage (payload) {
      const sceneIndex = this.data.scenes.findIndex(s => s._id === payload.sceneId)
      this.data.scenes[sceneIndex].properties.image = payload.image
    },
    displayDialog () {
      this.fileName = this.storyboard.name
      this.dialog = true
    },
    closeDialog () {
      this.dialog = false
      this.fileName = undefined
      this.exportSize = 'fullStoryboard'
      this.selectedScenes = []
      this.sceneElementsToExport = ['title', 'description', 'visual', 'script', 'graphicNotes', 'editingNotes']
      this.pdfLayout = 'portrait'
      this.scenePerPage = 1
      this.actionTypeToExport = ['dialogue', 'note', 'question']
      this.exportIds = true
      this.displayScriptInPptx = true
      this.diagram = { portrait: undefined, landscape: undefined }
    },
    convertLogo () {
      const img = new Image()
      img.src = require('../../../assets/img/logo-edtake.svg')
      this.logo = img
    },
    cleanHtmlTags (payload) {
      let sanitized = sanitizeHtml(payload, {
        allowedTags: ['p', 'li'],
        allowedAttributes: {},
        transformTags: {
          h1: 'p',
          h2: 'p',
          h3: 'p',
          h4: 'p',
          h5: 'p',
          h6: 'p'
        }
      })
      sanitized = sanitized.replace(/<li[^>]*>/g, ' \n - ')
      sanitized = sanitized.replace(/<p[^>]*>/g, '')
      sanitized = sanitized.replace(/<\/[^>]*>/g, ' \n')
      return sanitized
    },
    sceneDuration (scene) {
      return this.$dayjs.duration(scene.properties.duration, 'seconds').format('mm:ss')
    },
    getDiagramImage ({ pdfType, data }) {
      const img = new Image()
      img.src = data
      this.diagram[pdfType] = img
    },
    async download () {
      this.$evt.log('StoryboardExport', { type: this.exportType })
      const data = _.cloneDeep(this.storyboard)
      if (this.exportType === 'pdf' || this.exportType === 'pptx') {
        const scenes = []
        _.forEach(data.scenes, (scene) => {
          const description = this.cleanHtmlTags(scene.properties.description)
          const graphicNotes = this.cleanHtmlTags(scene.properties.graphicNotes)
          const editingNotes = this.cleanHtmlTags(scene.properties.editingNotes)
          let title = scene.properties.title
          if (this.sceneNumbering) {
            const numbering = `${scene.order} -`
            title = numbering.concat(' ', title)
          }
          if (this.displayDuration) {
            title = title.concat(' ', `(${this.sceneDuration(scene)})`)
          }
          const properties = { ...scene.properties, title, description, graphicNotes, editingNotes }
          scenes.push({ ...scene, properties })
        })
        this.data = { ...data, scenes }
      }
      if (this.exportType === 'pdf') {
        if (_.includes(this.sceneElementsToExport, 'visual')) {
          await this.generateStagePreview()
        }
        const payloadToExport = this.exportSize === 'fullStoryboard' ? this.data.scenes : this.dataToExport()
        if (this.pdfLayout === 'portrait') {
          this.generatePortraitPDF(payloadToExport)
        } else {
          this.generateLandscapePDF(payloadToExport)
        }
      } else if (this.exportType === 'xlsx') {
        this.exportIds = false
        this.data = data
        this.onexport()
      } else if (this.exportType === 'pptx') {
        if (this.displayScriptInPptx) {
          await this.generateStagePreview()
        }
        this.generatePptx()
      }
    },
    async generatePptx () {
      const pres = new Pptxgen()
      pres.defineLayout({ name: 'storyboard', width: 8.125, height: 5.729 })
      pres.layout = 'storyboard'

      pres.defineSlideMaster({
        title: 'Branding',
        objects: [{ image: { x: 0.3, y: 5.3, w: 0.8, h: 0.20, path: require('../../../assets/img/logo-edtake.svg') } }]
      })
      const inchUnit = 0.0104166667 // to convert px to inch
      const titleSlide = pres.addSlide({ masterName: 'Branding' })
      titleSlide.addText(this.storyboard.name, { x: 0, y: 0, h: '100%', w: '100%', color: '#00558A', bold: true, fontSize: 24, align: 'center' })

      let notes = `${this.$t('summary')}\n\n`
      if (this.storyboardDuration) { notes = notes.concat('', `${this.storyboardDuration}\n\n`) }
      if (this.storyboardBudget) { notes = notes.concat('', `${this.storyboardBudget}\n\n`) }
      titleSlide.addNotes(notes.concat('', `${this.scenesLength}\n\n${this.characterList()}`))

      await Aigle.resolve(this.data.scenes).eachSeries(async (scene, sceneIdx) => {
        const propertiesSlide = pres.addSlide()
        propertiesSlide.addText(scene.properties.title, { fontSize: 14, x: 0.3, y: 0.5, w: 7.525, h: 0.5, valign: 'top', align: 'left', margin: 5, color: '#00558A', bold: true })
        propertiesSlide.addText([{ text: 'Description :', options: { fontSize: 12, color: '#00558A', breakLine: true, lineSpacing: 30 } }, { text: scene.properties.description, options: { fontSize: 12 } }], { x: 0.3, y: 1, w: 7.525, h: '60%', valign: 'top', align: 'left', margin: 5 })
        propertiesSlide.addNotes(`${this.$t('graphicNotes')} : ${scene.properties.graphicNotes}\n${this.$t('editingNotes')} : ${scene.properties.editingNotes}`)

        const visualSlide = pres.addSlide()
        const shapes = scene.properties.canvas.sort((a, b) => { return a.order - b.order })

        await Aigle.resolve(shapes).eachSeries(async (shape, i) => {
          const s = shape.config
          const width = s.width * s.scaleX
          if (shape.type === 'image') {
            const imgValue = this.storyboard.catalog.find(i => i._id === s.image)
            if (imgValue) {
              const imageb64 = await this.getImageBase64(imgValue.value, s.scaleX, s.scaleY)
              if (imageb64) {
                visualSlide.addImage({ data: imageb64.data, x: (s.x * inchUnit), y: (s.y * inchUnit), w: (imageb64.width * inchUnit), h: (imageb64.height * inchUnit) })
              }
            }
          }
          if (shape.type === 'text') {
            const height = this.getLineheight(s.text, '0px', `${width}px`, `${s.fontSize}px`)
            visualSlide.addText(s.text, {
              x: (s.x * inchUnit),
              y: (s.y * inchUnit),
              w: (width * inchUnit),
              h: (height * inchUnit),
              fontFace: s.fontFamily,
              fontSize: s.fontSize * 0.75,
              color: s.fill,
              bold: s.fontStyle === 'bold',
              italic: s.fontStyle === 'italic',
              underline: s.textDecoration === 'underline',
              align: s.align,
              valign: 'top',
              rotate: s.rotation
            })
          }
          if (shape.type === 'rect') {
            const height = s.height * s.scaleY
            visualSlide.addShape(pres.shapes.RECTANGLE, {
              x: (s.x * inchUnit),
              y: (s.y * inchUnit),
              w: (width * inchUnit),
              h: (height * inchUnit),
              fill: s.fill,
              rotate: s.rotation,
              line: { color: s.stroke, width: s.strokeWidth }
            })
          }
          if (shape.type === 'circle') {
            const radiusY = s.radius * s.scaleY
            const radiusX = s.radius * s.scaleX

            visualSlide.addShape(pres.shapes.OVAL, {
              x: (s.x - radiusX) * inchUnit,
              y: (s.y - radiusY) * inchUnit,
              w: ((s.radius * 2 * s.scaleX) * inchUnit),
              h: ((s.radius * 2 * s.scaleY) * inchUnit),
              fill: s.fill,
              line: { color: s.stroke, width: s.strokeWidth }
            })
          }
          if (shape.type === 'arrow') {
            visualSlide.addShape(pres.shapes.LINE, {
              x: (s.x * inchUnit),
              y: (s.y * inchUnit),
              w: (width * inchUnit),
              h: 0,
              rotate: s.rotation,
              line: { color: s.stroke, width: s.strokeWidth, endArrowType: 'arrow' }
            })
          }
          if (shape.type === 'custom') {
            const height = s.height * s.scaleY
            const customImage = await this.getCustomShapeImg(s.customType, s.scaleX, s.scaleY, s.stroke, s.fill, s.strokeWidth, width, height)
            visualSlide.addImage({
              data: customImage,
              x: (s.x * inchUnit),
              y: (s.y * inchUnit),
              w: width,
              h: height,
              sizing: { type: 'contain', w: width * inchUnit, h: height * inchUnit }
            })
          }
        })

        if (scene.script.length && !this.displayScriptInPptx) {
          _.forEach(scene.script, (line) => {
            if (line.type === 'note') {
              visualSlide.addNotes(`${line.actionData.description}\n`)
            } else {
              const asset = _.find(this.storyboard.assets, a => a._id === line.actionData.assetId)
              if (line.type === 'question') {
                const options = this.getOptions(line.actionData.options)
                visualSlide.addNotes(`${asset ? asset.name : ''} : ${line.actionData.description}\n${options.join('')}`)
              } else {
                visualSlide.addNotes(`${asset ? asset.name : ''} : ${line.actionData.description}\n`)
              }
            }
          })
        } else if (scene.script.length && this.displayScriptInPptx) {
          const sceneImage = scene.properties.image
          const startNotes = this.getNotes(0, scene.script)
          let currentSlide
          let idx
          if (startNotes.index !== null) {
            visualSlide.addNotes(startNotes.notes.join('\n'))
            idx = startNotes.index + 1
          } else { idx = 0 }

          for (let i = idx; i < scene.script.length; i++) {
            if (scene.script[i].type === 'note') {
              const prevSlide = pres.getSlide(currentSlide._slideNum)
              prevSlide.addNotes(`${scene.script[i].actionData.description}\n`)
            } else {
              const line = scene.script[i]
              const asset = _.find(this.storyboard.assets, a => a._id === line.actionData.assetId)
              const options = this.getOptions(line.actionData.options)
              const scriptSlide = pres.addSlide()
              currentSlide = scriptSlide
              const heightPx = this.getLineheight(`${asset ? asset.name : ''}\n${line.actionData.description}\n${options.join('')}`, '36px 16px 16px', '396px', '12px')
              const height = heightPx * inchUnit
              const y = (5.729 - height) / 2
              scriptSlide.background = { data: sceneImage }
              scriptSlide.addText([
                { text: asset ? asset.name : '', options: { fontSize: 14, color: '#00558A', align: 'left', breakLine: true } },
                { text: line.actionData.description, options: { breakLine: true } },
                { text: options.join('') }
              ], { x: 2, y, w: 4.125, h: height, fontSize: 10, shape: pres.shapes.ROUNDED_RECTANGLE, rectRadius: 0.2, fill: { color: '#FFFFFF' } })
            }
          }
        }
      })

      pres.writeFile({ fileName: this.fileName || this.storyboard.name })
      this.closeDialog()
    },
    dataToExport () {
      const result = []
      this.selectedScenes.forEach((i) => {
        const s = this.data.scenes.find(s => s._id === i)
        result.push(s)
      })
      result.sort((a, b) => { return a.order - b.order })
      return result
    },
    sceneByAction () {
      const result = []
      const scenes = this.exportSize === 'fullStoryboard' ? _.cloneDeep(this.storyboard.scenes) : this.dataToExport()
      scenes.forEach((scene) => {
        let script = _.cloneDeep(scene.script)
        if (this.actionTypeToExport.length !== 3) {
          if (!_.includes(this.actionTypeToExport, 'dialogue')) {
            script = _.filter(script, function (o) { return o.type !== 'dialogue' })
          }
          if (!_.includes(this.actionTypeToExport, 'note')) {
            script = _.filter(script, function (o) { return o.type !== 'note' })
          }
          if (!_.includes(this.actionTypeToExport, 'question')) {
            script = _.filter(script, function (o) { return o.type !== 'question' })
          }
        }

        script.forEach((action) => {
          const assetObject = this.storyboard.assets.find(i => i._id === action.actionData.assetId)
          if (!this.exportIds) {
            let description = action.actionData.description
            if (action.type === 'question' && action.actionData.options.length) {
              description = description.concat('', this.getOptions(action.actionData.options).join(''))
            }
            result.push({
              sceneOrder: scene.order,
              sceneTitle: scene.properties.title,
              actionType: action.type,
              asset: assetObject ? assetObject.name : undefined,
              description
            })
          } else if (action.type === 'question' && action.actionData.options.length) {
            result.push({
              sceneId: scene._id,
              sceneTitle: scene.properties.title,
              actionId: action._id,
              actionType: action.type,
              assetId: assetObject ? assetObject._id : undefined,
              asset: assetObject ? assetObject.name : undefined,
              optionId: undefined,
              description: action.actionData.description
            })
            action.actionData.options.forEach((o) => {
              result.push({
                sceneId: scene._id,
                sceneTitle: scene.properties.title,
                actionId: action._id,
                actionType: action.type,
                assetId: undefined,
                asset: undefined,
                optionId: o._id,
                description: o.label
              })
            })
          } else {
            result.push({
              sceneId: scene._id,
              sceneTitle: scene.properties.title,
              actionId: action._id,
              actionType: action.type,
              assetId: assetObject ? assetObject._id : undefined,
              asset: assetObject ? assetObject.name : undefined,
              optionId: undefined,
              description: action.actionData.description
            })
          }
        })
      })
      return result
    },
    onexport () {
      const list = this.sceneByAction()
      const worksheet = XLSX.utils.json_to_sheet(list) // export json to Worksheet of Excel, only array possible
      const wb = XLSX.utils.book_new() // make Workbook(excel file) of Excel
      // Workbook contains one or more worksheets
      XLSX.utils.book_append_sheet(wb, worksheet, 'Export') // add Worksheet to Workbook
      // export Excel file
      XLSX.writeFile(wb, `${this.fileName || this.storyboard.name}.xlsx`) // name of the file
      // this.$tracking.setItem('exportXlsxGlobal')
      this.closeDialog()
    },
    generateData (payload) {
      // to display script in jspdf autotable
      const result = []
      payload.forEach((i) => {
        const type = i.type
        const typeToDisplay = type[0].toUpperCase() + type.substring(1)
        const assetObject = this.storyboard.assets.find(asset => asset._id === i.actionData.assetId)
        const assetName = assetObject ? assetObject.name : ''
        let description = i.actionData.description
        if (type === 'question' && i.actionData.options.length) {
          description = description.concat('', this.getOptions(i.actionData.options).join(''))
        }
        const data = [typeToDisplay, assetName, description]
        result.push(data)
      })
      return result
    },
    generateLandscapePDF (scenes) {
      const doc = new JsPDF('landscape')
      doc.setProperties({
        title: this.fileName || this.storyboard.name
      })
      doc.setFontSize(40)
      doc.text(this.storyboard.name, 140, 105, { align: 'center' })
      doc.setFontSize(10)
      doc.text('generated on', 126, 203)
      doc.addImage(this.logo, 'png', 148, 200, 15, 3)
      // display multiple scenes per page
      if (this.scenePerPage > 1) {
        let x = this.scenePerPage === 2 ? 35 : 21
        let y = true
        scenes.forEach((scene, index) => {
          // add page each time the number of scenes per page is reached
          if (index % this.scenePerPage === 0) {
            doc.addPage()
            doc.setFontSize(10)
            doc.text('generated on', 126, 203)
            doc.addImage(this.logo, 'png', 148, 200, 15, 3)
          }
          if (_.includes(this.sceneElementsToExport, 'visual') && scene.properties.image) {
            if (this.scenePerPage === 2) {
              doc.addImage(scene.properties.image, 'PNG', x, 10, 88, 62)
            } else {
              doc.addImage(scene.properties.image, 'PNG', x, y ? 10 : 105, 70, 50)
            }
          }

          const body = [] // body of autotable to display description and title
          if (_.includes(this.sceneElementsToExport, 'title') && scene.properties.title.length > 0) {
            body.push([scene.properties.title])
          }
          if (_.includes(this.sceneElementsToExport, 'description') && scene.properties.description.length > 0) {
            const string = scene.properties.description.replace(/\r?\n|\r/g, ' ') // remove line breaks
            let length
            if (_.includes(this.sceneElementsToExport, 'visual')) {
              length = this.scenePerPage === 4 ? 500 : this.scenePerPage === 6 ? 200 : 1200
            } else {
              length = this.scenePerPage === 4 ? 800 : this.scenePerPage === 6 ? 500 : 1500
            }
            // limit length of description to fit pdf layout
            const trimmedString = string.length > length ? string.substring(0, length - 3) + '...' : string
            body.push([trimmedString])
          }

          let startY
          if (_.includes(this.sceneElementsToExport, 'visual')) {
            startY = this.scenePerPage === 2 ? 80 : y ? 62 : 158
          } else {
            startY = this.scenePerPage === 2 ? 30 : y ? 30 : 115
          }

          let margin
          if (x <= 35) {
            margin = { left: 21, right: this.scenePerPage === 6 ? 206 : 160 }
          } else if (x === 112) {
            margin = { left: 112, right: 115 }
          } else {
            margin = { left: this.scenePerPage === 6 ? 203 : 160, right: 21 }
          }
          doc.autoTable({
            head: [['Data']],
            body,
            theme: 'grid',
            startY,
            margin,
            showHead: 'never',
            bodyStyles: {
              textColor: 0,
              fontSize: this.scenePerPage === 2 ? 11 : 10,
              cellPadding: 2
            },
            columnStyles: { 0: { lineWidth: {} } }, // remove column lines
            didParseCell: (data) => {
              if (_.includes(this.sceneElementsToExport, 'title') && data.row.index === 0) {
                data.cell.styles.fontStyle = 'bold'
                data.cell.styles.textColor = [0, 85, 138]
              }
            }
          })

          // To align scenes on several rows & columns:
          // at first y = true, scene is displayed on the top of the page
          // value of y is inverted at the end of the row (when x = 203 if 6 scenes per page, when x=160 if 4 scenes per page)
          // when y = false, scene is displayed on the bottom of the page
          if (this.scenePerPage > 2) {
            if (x === 203 || x === 160) {
              y = !y
            }
            if (this.scenePerPage === 6) {
              x === 21 ? x = 112 : x === 112 ? x = 203 : x = 21
            } else if (this.scenePerPage === 4) {
              x === 21 ? x = 160 : x = 21
            }
          } else {
            x === 35 ? x = 168 : x = 35
            y = !y
          }
        })
      } else {
        // display 1 scene per page
        scenes.forEach((scene) => {
          if (_.includes(this.sceneElementsToExport, 'visual') && scene.properties.image) {
            doc.addPage()
            doc.setFontSize(10)
            doc.text('generated on', 126, 203)
            doc.addImage(this.logo, 'png', 148, 200, 15, 3)
            doc.addImage(scene.properties.image, 'PNG', 51, 20, 195, 138)
          }

          doc.addPage()
          doc.setFontSize(10)
          doc.text('generated on', 126, 203)
          doc.addImage(this.logo, 'png', 148, 200, 15, 3)

          const body = []
          if (_.includes(this.sceneElementsToExport, 'title')) {
            body.push([scene.properties.title])
          }
          if (_.includes(this.sceneElementsToExport, 'description')) {
            body.push([this.$t('description')])
            body.push([scene.properties.description])
          }
          if (_.includes(this.sceneElementsToExport, 'graphicNotes')) {
            body.push([this.$t('graphicNotes')])
            body.push([scene.properties.graphicNotes])
          }
          if (_.includes(this.sceneElementsToExport, 'editingNotes')) {
            body.push([this.$t('editingNotes')])
            body.push([scene.properties.editingNotes])
          }
          doc.autoTable({
            head: [['Data']],
            body,
            theme: 'plain',
            startY: 20,
            startX: 25,
            showHead: 'never',
            bodyStyles: {
              textColor: 0,
              fontSize: 11,
              cellPadding: 3
            },
            didParseCell: (data) => {
              if (_.includes(this.sceneElementsToExport, 'title') && data.row.index === 0) {
                data.cell.styles.fontSize = 14
                data.cell.styles.fontStyle = 'bold'
                data.cell.styles.textColor = [0, 85, 138]
              }
              if (data.cell.text[0] === this.$t('description') || data.cell.text[0] === this.$t('graphicNotes') || data.cell.text[0] === this.$t('editingNotes')) {
                data.cell.styles.fontStyle = 'italic'
              }
            }
          })
          doc.setFontSize(10)
          doc.text('generated on', 126, 203)
          doc.addImage(this.logo, 'png', 148, 200, 15, 3)
          if (_.includes(this.sceneElementsToExport, 'script') && scene.script.length > 0) {
            doc.autoTable({
              head: [['Action', 'Elément', 'Réplique / Description']],
              body: this.generateData(scene.script),
              theme: 'grid',
              startY: doc.lastAutoTable.finalY + 10,
              startX: 25,
              bodyStyles: {
                textColor: 0,
                fontSize: 11,
                cellPadding: 3
              },
              headStyles: {
                textColor: 255,
                fontSize: 11,
                cellPadding: 2,
                fillColor: '#00558A'
              },
              columnStyles: {
                0: { lineColor: '#00558A', lineWidth: { bottom: 0.1 } }, 1: { lineColor: '#00558A', lineWidth: { bottom: 0.1 } }, 2: { lineColor: '#00558A', lineWidth: { bottom: 0.1 } }
              }
            })
            doc.setFontSize(10)
            doc.text('generated on', 126, 203)
            doc.addImage(this.logo, 'png', 148, 200, 15, 3)
          }
        })
      }
      doc.addPage()
      const body = [[this.$t('summary')]]
      if (this.storyboardDuration) {
        body.push([this.storyboardDuration])
      }
      if (this.storyboardBudget) {
        body.push([this.storyboardBudget])
      }
      const sceneNumber = this.scenesLength
      body.push([sceneNumber])
      const characters = `${this.characterList()}`
      body.push([characters])
      doc.autoTable({
        head: [['Data']],
        body,
        theme: 'grid',
        startY: 40,
        margin: 30,
        showHead: 'never',
        bodyStyles: {
          textColor: 0,
          fontSize: 11,
          cellPadding: 2
        },
        columnStyles: { 0: { lineWidth: {} } }, // remove column lines
        didParseCell: (data) => {
          if (data.row.index === 0) {
            data.cell.styles.fontStyle = 'bold'
            data.cell.styles.textColor = [0, 85, 138]
          }
        }
      })
      doc.setFontSize(10)
      doc.text('generated on', 126, 203)
      doc.addImage(this.logo, 'png', 148, 200, 15, 3)
      doc.addPage()
      doc.addImage(this.diagram.landscape, 'PNG', 0, 0, 297, 203)
      doc.setFontSize(10)
      doc.text('generated on', 126, 203)
      doc.addImage(this.logo, 'png', 148, 200, 15, 3)
      doc.output('save', `${this.fileName || this.storyboard.name}.pdf`)
      this.closeDialog()
    },
    generatePortraitPDF (scenes) {
      const doc = new JsPDF()
      doc.setProperties({
        title: this.fileName || this.storyboard.name
      })
      // displays the title vertically and horizontally centered
      doc.autoTable({
        margin: { top: 0, right: 10, left: 10, bottom: 0 },
        head: [['Data']],
        body: [[this.storyboard.name]],
        theme: 'grid',
        startY: 0,
        startX: 0,
        showHead: 'never',
        bodyStyles: {
          textColor: 0,
          fontSize: 32,
          minCellWidth: 190,
          minCellHeight: 287,
          halign: 'center',
          valign: 'middle'
        },
        columnStyles: { 0: { lineWidth: {} } }
      })
      doc.setFontSize(10)
      doc.text('generated on', 83, 290)
      doc.addImage(this.logo, 'png', 105, 287, 15, 3)
      // display multiple scenes per page
      if (this.scenePerPage > 1) {
        let x = true
        let y = 20
        scenes.forEach((scene, index) => {
          // add page each time the number of scenes per page is reached
          if (index % this.scenePerPage === 0) {
            doc.addPage()
            doc.setFontSize(10)
            doc.text('generated on', 83, 290)
            doc.addImage(this.logo, 'png', 105, 287, 15, 3)
          }
          if (_.includes(this.sceneElementsToExport, 'visual') && scene.properties.image) {
            if (this.scenePerPage === 6) {
              doc.addImage(scene.properties.image, 'PNG', x ? 23 : 116, y, 70, 50)
            } else {
              doc.addImage(scene.properties.image, 'PNG', x ? 17 : 110, y, 88, 62)
            }
          }

          const body = [] // body of autotable to display description and title
          if (_.includes(this.sceneElementsToExport, 'title') && scene.properties.title.length > 0) {
            body.push([scene.properties.title])
          }
          if (_.includes(this.sceneElementsToExport, 'description') && scene.properties.description.length > 0) {
            const string = scene.properties.description.replace(/\r?\n|\r/g, ' ') // remove line breaks of description
            let length
            if (_.includes(this.sceneElementsToExport, 'visual')) {
              length = this.scenePerPage === 4 ? 500 : this.scenePerPage === 6 ? 200 : 1200
            } else {
              length = this.scenePerPage === 4 ? 800 : this.scenePerPage === 6 ? 500 : 1500
            }
            // limit length of description to fit pdf layout
            const trimmedString = string.length > length ? string.substring(0, length - 3) + '...' : string
            body.push([trimmedString])
          }

          let startY
          if (_.includes(this.sceneElementsToExport, 'visual')) {
            startY = this.scenePerPage === 2 ? y + 70 : this.scenePerPage === 4 ? y + 67 : y + 55
          } else {
            startY = y
          }

          let margin
          if (x) {
            margin = { left: this.scenePerPage === 6 ? 23 : 17, right: this.scenePerPage === 6 ? 116 : 110 }
          } else {
            margin = { right: this.scenePerPage === 6 ? 23 : 17, left: this.scenePerPage === 6 ? 116 : 110 }
          }
          doc.autoTable({
            head: [['Data']],
            body,
            theme: 'grid',
            startY,
            margin,
            showHead: 'never',
            bodyStyles: {
              textColor: 0,
              fontSize: this.scenePerPage === 2 ? 11 : 10,
              cellPadding: this.scenePerPage === 6 ? 1 : 2
            },
            columnStyles: { 0: { lineWidth: {} } }, // remove column lines
            didParseCell: (data) => {
              if (_.includes(this.sceneElementsToExport, 'title') && data.row.index === 0) {
                data.cell.styles.fontStyle = 'bold'
                data.cell.styles.textColor = [0, 85, 138]
              }
            }
          })

          // To align scenes on several rows :
          // at first x = true, scene is displayed on the left of the page
          // value of x is inverted at each scene
          // so when x = false, scene is displayed on the right & need break line -> y value is changed : 20 -> 105 -> 190
          if (!x && this.scenePerPage > 2) {
            if (this.scenePerPage === 6) {
              y === 20 ? y = 105 : y === 105 ? y = 190 : y = 20
            } else if (this.scenePerPage === 4) {
              y === 20 ? y = 148 : y = 20
            }
          }
          x = !x
        })
      } else {
        scenes.forEach((scene) => {
          doc.addPage()
          doc.setFontSize(10)
          doc.text('generated on', 83, 290)
          doc.addImage(this.logo, 'png', 105, 287, 15, 3)
          if (_.includes(this.sceneElementsToExport, 'visual') && scene.properties.image) {
            doc.addImage(scene.properties.image, 'PNG', 17, 20, 175, 124)
          }

          const body = []
          if (_.includes(this.sceneElementsToExport, 'title')) {
            body.push([scene.properties.title])
          }
          if (_.includes(this.sceneElementsToExport, 'description')) {
            body.push([this.$t('description')])
            body.push([scene.properties.description])
          }
          if (_.includes(this.sceneElementsToExport, 'graphicNotes')) {
            body.push([this.$t('graphicNotes')])
            body.push([scene.properties.graphicNotes])
          }
          if (_.includes(this.sceneElementsToExport, 'editingNotes')) {
            body.push([this.$t('editingNotes')])
            body.push([scene.properties.editingNotes])
          }
          doc.autoTable({
            head: [['Data']],
            body,
            theme: 'grid',
            startY: _.includes(this.sceneElementsToExport, 'visual') && scene.properties.image ? 155 : 20,
            startX: 25,
            showHead: 'never',
            bodyStyles: {
              textColor: 0,
              fontSize: 11,
              cellPadding: 3
            },
            columnStyles: { 0: { lineWidth: {} } },
            didParseCell: (data) => {
              if (_.includes(this.sceneElementsToExport, 'title') && data.row.index === 0) {
                data.cell.styles.fontSize = 14
                data.cell.styles.fontStyle = 'bold'
                data.cell.styles.textColor = [0, 85, 138]
              }
              if (data.cell.text[0] === this.$t('description') || data.cell.text[0] === this.$t('graphicNotes') || data.cell.text[0] === this.$t('editingNotes')) {
                data.cell.styles.fontStyle = 'italic'
              }
            }
          })
          if (_.includes(this.sceneElementsToExport, 'script') && scene.script.length > 0) {
            doc.autoTable({
              head: [['Action', 'Elément', 'Réplique / Description']],
              body: this.generateData(scene.script),
              theme: 'grid',
              startY: doc.lastAutoTable.finalY + 10,
              startX: 10,
              bodyStyles: {
                textColor: 0,
                fontSize: 11,
                cellPadding: 3
              },
              headStyles: {
                textColor: 255,
                fontSize: 11,
                cellPadding: 2,
                fillColor: '#00558A'
              },
              columnStyles: {
                0: { lineColor: '#00558A', lineWidth: { bottom: 0.1 } }, 1: { lineColor: '#00558A', lineWidth: { bottom: 0.1 } }, 2: { lineColor: '#00558A', lineWidth: { bottom: 0.1 } }
              }
            })
            doc.setFontSize(10)
            doc.text('generated on', 83, 290)
            doc.addImage(this.logo, 'png', 105, 287, 15, 3)
          }
        })
      }
      doc.addPage()
      const body = [[this.$t('summary')]]
      if (this.storyboardDuration) {
        body.push([this.storyboardDuration])
      }
      if (this.storyboardBudget) {
        body.push([this.storyboardBudget])
      }
      const sceneNumber = this.scenesLength
      body.push([sceneNumber])
      const characters = `${this.characterList()}`
      body.push([characters])
      doc.autoTable({
        head: [['Data']],
        body,
        theme: 'grid',
        startY: 30,
        margin: 30,
        showHead: 'never',
        bodyStyles: {
          textColor: 0,
          fontSize: 11,
          cellPadding: 2
        },
        columnStyles: { 0: { lineWidth: {} } }, // remove column lines
        didParseCell: (data) => {
          if (data.row.index === 0) {
            data.cell.styles.fontStyle = 'bold'
            data.cell.styles.textColor = [0, 85, 138]
          }
        }
      })
      doc.setFontSize(10)
      doc.text('generated on', 83, 290)
      doc.addImage(this.logo, 'png', 105, 287, 15, 3)
      doc.addPage()
      doc.addImage(this.diagram.portrait, 'PNG', 0, 0, 210, 290)
      doc.setFontSize(10)
      doc.text('generated on', 83, 290)
      doc.addImage(this.logo, 'png', 105, 287, 15, 3)
      doc.output('save', `${this.fileName || this.storyboard.name}.pdf`)
      this.closeDialog()
    }
  }
}
</script>
