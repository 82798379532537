<template>
  <div v-if="user">
    <v-tooltip top>
      <template #activator="{ on, attrs }">
        <v-avatar
          v-bind="attrs"
          :size="size"
          :color="user.avatar ? 'white' : 'primary'"
          class="hover-front elevation-2"
          v-on="on"
        >
          <img
            v-if="user.avatar"
            :src="user.avatar"
            :alt="`${user.name}`"
          >
          <span v-else class="white--text text-uppercase headline">{{ user.firstname && user.lastname ? user.firstname[0] + user.lastname[0] : user.name[0] + user.name[1] }}</span>
        </v-avatar>
      </template>
      <span>{{ user.name || `${user.firstname} ${user.lastname}` }}</span>
    </v-tooltip>
  </div>
  <v-icon v-else>
    mdi-alert-circle-outline
  </v-icon>
</template>
<script>
export default {
  props: {
    user: {
      type: Object,
      required: true
    },
    size: {
      type: Number,
      default: () => { return 30 }
    }
  }
}
</script>
