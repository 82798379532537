/* eslint-disable */
/* EDTAKE SDK API-FUNCTIONS */
import qs from 'qs'

function logFormData(form) {
  if (form) {
    console.warn('A form parameter is passed to the request, you should add configration to the corresponding Azure Function in order to manage FormData')
  }
}

function generateRequest(method, url, body, queryParameters, form, config) {
  method = method.toLowerCase()
  let keys = Object.keys(queryParameters)
  let queryUrl = url
  if (keys.length > 0) {
    queryUrl = url + '?' + qs.stringify(queryParameters)
  }
  const r = {
    method: method,
    url: queryUrl,
    opts: '',
    config: config
  }
  if (body) {
    r.opts = body
  } else if (method === 'get') {
    r.opts = {
      params: form
    }
  } else {
    r.opts = form
  }
  return r
}

/* admin */
const admin = $axios => ({
  activateWorkspace: (parameters = {}) => {
    const config = parameters.$config
    let path = 'api/v1/admin/workspace/{id}'
    let body
    let queryParameters = {}
    let form = {}
    logFormData(parameters.form)
    if (parameters['body'] !== undefined) {
      body = parameters['body']
    }
    if (parameters['id'] === undefined) {
      return Promise.reject(new Error('Missing required parameter: id'))
    }
    path = path.replace('{id}', `${parameters['id']}`)
    if (parameters.$queryParameters) {
      Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
        queryParameters[parameterName] = parameters.$queryParameters[parameterName]
      });
    }
    const r = generateRequest('put', path, body, queryParameters, form, config)
    return $axios.$put(r.url, r.opts, r.config)
  },
  addStaffWorkspace: (parameters = {}) => {
    const config = parameters.$config
    let path = 'api/v1/admin/workspace/staff'
    let body
    let queryParameters = {}
    let form = {}
    logFormData(parameters.form)
    if (parameters['body'] !== undefined) {
      body = parameters['body']
    }
    if (parameters.$queryParameters) {
      Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
        queryParameters[parameterName] = parameters.$queryParameters[parameterName]
      });
    }
    const r = generateRequest('post', path, body, queryParameters, form, config)
    return $axios.$post(r.url, r.opts, r.config)
  },
  addUserToStaff: (parameters = {}) => {
    const config = parameters.$config
    let path = 'api/v1/admin/user/staff'
    let body
    let queryParameters = {}
    let form = {}
    logFormData(parameters.form)
    if (parameters['body'] !== undefined) {
      body = parameters['body']
    }
    if (parameters.$queryParameters) {
      Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
        queryParameters[parameterName] = parameters.$queryParameters[parameterName]
      });
    }
    const r = generateRequest('post', path, body, queryParameters, form, config)
    return $axios.$post(r.url, r.opts, r.config)
  },
  cancelReview: (parameters = {}) => {
    const config = parameters.$config
    let path = 'api/v1/admin/mission/{missionId}/delivery/{deliveryId}/cancel'
    let body
    let queryParameters = {}
    let form = {}
    logFormData(parameters.form)
    if (parameters['missionId'] === undefined) {
      return Promise.reject(new Error('Missing required parameter: missionId'))
    }
    path = path.replace('{missionId}', `${parameters['missionId']}`)
    if (parameters['deliveryId'] === undefined) {
      return Promise.reject(new Error('Missing required parameter: deliveryId'))
    }
    path = path.replace('{deliveryId}', `${parameters['deliveryId']}`)
    if (parameters.$queryParameters) {
      Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
        queryParameters[parameterName] = parameters.$queryParameters[parameterName]
      });
    }
    const r = generateRequest('get', path, body, queryParameters, form, config)
    return $axios.$get(r.url, r.config)
  },
  createProposals: (parameters = {}) => {
    const config = parameters.$config
    let path = 'api/v1/admin/create-proposals'
    let body
    let queryParameters = {}
    let form = {}
    logFormData(parameters.form)
    if (parameters['body'] !== undefined) {
      body = parameters['body']
    }
    if (parameters.$queryParameters) {
      Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
        queryParameters[parameterName] = parameters.$queryParameters[parameterName]
      });
    }
    const r = generateRequest('post', path, body, queryParameters, form, config)
    return $axios.$post(r.url, r.opts, r.config)
  },
  createWorkspaces: (parameters = {}) => {
    const config = parameters.$config
    let path = 'api/v1/admin/workspace'
    let body
    let queryParameters = {}
    let form = {}
    logFormData(parameters.form)
    if (parameters['body'] !== undefined) {
      body = parameters['body']
    }
    if (parameters.$queryParameters) {
      Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
        queryParameters[parameterName] = parameters.$queryParameters[parameterName]
      });
    }
    const r = generateRequest('post', path, body, queryParameters, form, config)
    return $axios.$post(r.url, r.opts, r.config)
  },
  deleteProposals: (parameters = {}) => {
    const config = parameters.$config
    let path = 'api/v1/admin/mission/{id}/reset'
    let body
    let queryParameters = {}
    let form = {}
    logFormData(parameters.form)
    if (parameters['body'] !== undefined) {
      body = parameters['body']
    }
    if (parameters['id'] === undefined) {
      return Promise.reject(new Error('Missing required parameter: id'))
    }
    path = path.replace('{id}', `${parameters['id']}`)
    if (parameters.$queryParameters) {
      Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
        queryParameters[parameterName] = parameters.$queryParameters[parameterName]
      });
    }
    const r = generateRequest('put', path, body, queryParameters, form, config)
    return $axios.$put(r.url, r.opts, r.config)
  },
  getMissions: (parameters = {}) => {
    const config = parameters.$config
    let path = 'api/v1/admin/mission'
    let body
    let queryParameters = {}
    let form = {}
    logFormData(parameters.form)
    if (parameters.$queryParameters) {
      Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
        queryParameters[parameterName] = parameters.$queryParameters[parameterName]
      });
    }
    const r = generateRequest('get', path, body, queryParameters, form, config)
    return $axios.$get(r.url, r.config)
  },
  getMissionsByAssignee: (parameters = {}) => {
    const config = parameters.$config
    let path = 'api/v1/admin/mission/user/{id}'
    let body
    let queryParameters = {}
    let form = {}
    logFormData(parameters.form)
    if (parameters['id'] === undefined) {
      return Promise.reject(new Error('Missing required parameter: id'))
    }
    path = path.replace('{id}', `${parameters['id']}`)
    if (parameters.$queryParameters) {
      Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
        queryParameters[parameterName] = parameters.$queryParameters[parameterName]
      });
    }
    const r = generateRequest('get', path, body, queryParameters, form, config)
    return $axios.$get(r.url, r.config)
  },
  getOneMission: (parameters = {}) => {
    const config = parameters.$config
    let path = 'api/v1/admin/mission/{id}'
    let body
    let queryParameters = {}
    let form = {}
    logFormData(parameters.form)
    if (parameters['id'] === undefined) {
      return Promise.reject(new Error('Missing required parameter: id'))
    }
    path = path.replace('{id}', `${parameters['id']}`)
    if (parameters.$queryParameters) {
      Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
        queryParameters[parameterName] = parameters.$queryParameters[parameterName]
      });
    }
    const r = generateRequest('get', path, body, queryParameters, form, config)
    return $axios.$get(r.url, r.config)
  },
  getOneUser: (parameters = {}) => {
    const config = parameters.$config
    let path = 'api/v1/admin/user/{id}'
    let body
    let queryParameters = {}
    let form = {}
    logFormData(parameters.form)
    if (parameters['id'] === undefined) {
      return Promise.reject(new Error('Missing required parameter: id'))
    }
    path = path.replace('{id}', `${parameters['id']}`)
    if (parameters.$queryParameters) {
      Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
        queryParameters[parameterName] = parameters.$queryParameters[parameterName]
      });
    }
    const r = generateRequest('get', path, body, queryParameters, form, config)
    return $axios.$get(r.url, r.config)
  },
  getOneWorkspace: (parameters = {}) => {
    const config = parameters.$config
    let path = 'api/v1/admin/workspace/{id}'
    let body
    let queryParameters = {}
    let form = {}
    logFormData(parameters.form)
    if (parameters['id'] === undefined) {
      return Promise.reject(new Error('Missing required parameter: id'))
    }
    path = path.replace('{id}', `${parameters['id']}`)
    if (parameters.$queryParameters) {
      Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
        queryParameters[parameterName] = parameters.$queryParameters[parameterName]
      });
    }
    const r = generateRequest('get', path, body, queryParameters, form, config)
    return $axios.$get(r.url, r.config)
  },
  getProposals: (parameters = {}) => {
    const config = parameters.$config
    let path = 'api/v1/admin/proposal/{ownerId}'
    let body
    let queryParameters = {}
    let form = {}
    logFormData(parameters.form)
    if (parameters['ownerId'] === undefined) {
      return Promise.reject(new Error('Missing required parameter: ownerId'))
    }
    path = path.replace('{ownerId}', `${parameters['ownerId']}`)
    if (parameters.$queryParameters) {
      Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
        queryParameters[parameterName] = parameters.$queryParameters[parameterName]
      });
    }
    const r = generateRequest('get', path, body, queryParameters, form, config)
    return $axios.$get(r.url, r.config)
  },
  getStaff: (parameters = {}) => {
    const config = parameters.$config
    let path = 'api/v1/admin/staff'
    let body
    let queryParameters = {}
    let form = {}
    logFormData(parameters.form)
    if (parameters.$queryParameters) {
      Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
        queryParameters[parameterName] = parameters.$queryParameters[parameterName]
      });
    }
    const r = generateRequest('get', path, body, queryParameters, form, config)
    return $axios.$get(r.url, r.config)
  },
  getUserByCriteria: (parameters = {}) => {
    const config = parameters.$config
    let path = 'api/v1/admin/user-by-criteria'
    let body
    let queryParameters = {}
    let form = {}
    logFormData(parameters.form)
    if (parameters['body'] !== undefined) {
      body = parameters['body']
    }
    if (parameters.$queryParameters) {
      Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
        queryParameters[parameterName] = parameters.$queryParameters[parameterName]
      });
    }
    const r = generateRequest('post', path, body, queryParameters, form, config)
    return $axios.$post(r.url, r.opts, r.config)
  },
  getUsers: (parameters = {}) => {
    const config = parameters.$config
    let path = 'api/v1/admin/user'
    let body
    let queryParameters = {}
    let form = {}
    logFormData(parameters.form)
    if (parameters.$queryParameters) {
      Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
        queryParameters[parameterName] = parameters.$queryParameters[parameterName]
      });
    }
    const r = generateRequest('get', path, body, queryParameters, form, config)
    return $axios.$get(r.url, r.config)
  },
  getWorkspace: (parameters = {}) => {
    const config = parameters.$config
    let path = 'api/v1/admin/workspace'
    let body
    let queryParameters = {}
    let form = {}
    logFormData(parameters.form)
    if (parameters.$queryParameters) {
      Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
        queryParameters[parameterName] = parameters.$queryParameters[parameterName]
      });
    }
    const r = generateRequest('get', path, body, queryParameters, form, config)
    return $axios.$get(r.url, r.config)
  },
  isAdmin: (parameters = {}) => {
    const config = parameters.$config
    let path = 'api/is-admin'
    let body
    let queryParameters = {}
    let form = {}
    logFormData(parameters.form)
    if (parameters.$queryParameters) {
      Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
        queryParameters[parameterName] = parameters.$queryParameters[parameterName]
      });
    }
    const r = generateRequest('get', path, body, queryParameters, form, config)
    return $axios.$get(r.url, r.config)
  },
  removeUserFromStaff: (parameters = {}) => {
    const config = parameters.$config
    let path = 'api/v1/admin/user/{id}/staff'
    let body
    let queryParameters = {}
    let form = {}
    logFormData(parameters.form)
    if (parameters['id'] === undefined) {
      return Promise.reject(new Error('Missing required parameter: id'))
    }
    path = path.replace('{id}', `${parameters['id']}`)
    if (parameters.$queryParameters) {
      Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
        queryParameters[parameterName] = parameters.$queryParameters[parameterName]
      });
    }
    const r = generateRequest('delete', path, body, queryParameters, form, config)
    return $axios.$delete(r.url, r.config)
  },
  updateMission: (parameters = {}) => {
    const config = parameters.$config
    let path = 'api/v1/admin/mission/{id}'
    let body
    let queryParameters = {}
    let form = {}
    logFormData(parameters.form)
    if (parameters['body'] !== undefined) {
      body = parameters['body']
    }
    if (parameters['id'] === undefined) {
      return Promise.reject(new Error('Missing required parameter: id'))
    }
    path = path.replace('{id}', `${parameters['id']}`)
    if (parameters.$queryParameters) {
      Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
        queryParameters[parameterName] = parameters.$queryParameters[parameterName]
      });
    }
    const r = generateRequest('put', path, body, queryParameters, form, config)
    return $axios.$put(r.url, r.opts, r.config)
  },
  updateProposal: (parameters = {}) => {
    const config = parameters.$config
    let path = 'api/v1/admin/proposal/{id}'
    let body
    let queryParameters = {}
    let form = {}
    logFormData(parameters.form)
    if (parameters['body'] !== undefined) {
      body = parameters['body']
    }
    if (parameters['id'] === undefined) {
      return Promise.reject(new Error('Missing required parameter: id'))
    }
    path = path.replace('{id}', `${parameters['id']}`)
    if (parameters.$queryParameters) {
      Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
        queryParameters[parameterName] = parameters.$queryParameters[parameterName]
      });
    }
    const r = generateRequest('put', path, body, queryParameters, form, config)
    return $axios.$put(r.url, r.opts, r.config)
  },
  updateWorkspace: (parameters = {}) => {
    const config = parameters.$config
    let path = 'api/v1/admin/workspace/{id}/update'
    let body
    let queryParameters = {}
    let form = {}
    logFormData(parameters.form)
    if (parameters['body'] !== undefined) {
      body = parameters['body']
    }
    if (parameters['id'] === undefined) {
      return Promise.reject(new Error('Missing required parameter: id'))
    }
    path = path.replace('{id}', `${parameters['id']}`)
    if (parameters.$queryParameters) {
      Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
        queryParameters[parameterName] = parameters.$queryParameters[parameterName]
      });
    }
    const r = generateRequest('put', path, body, queryParameters, form, config)
    return $axios.$put(r.url, r.opts, r.config)
  },
})
/* alert */
const alert = $axios => ({
  getForObject: (parameters = {}) => {
    const config = parameters.$config
    let path = 'api/alert/{type}/{id}'
    let body
    let queryParameters = {}
    let form = {}
    logFormData(parameters.form)
    if (parameters['type'] === undefined) {
      return Promise.reject(new Error('Missing required parameter: type'))
    }
    path = path.replace('{type}', `${parameters['type']}`)
    if (parameters['id'] === undefined) {
      return Promise.reject(new Error('Missing required parameter: id'))
    }
    path = path.replace('{id}', `${parameters['id']}`)
    if (parameters.$queryParameters) {
      Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
        queryParameters[parameterName] = parameters.$queryParameters[parameterName]
      });
    }
    const r = generateRequest('get', path, body, queryParameters, form, config)
    return $axios.$get(r.url, r.config)
  },
})
/* apiCredential */
const apiCredential = $axios => ({
  generate: (parameters = {}) => {
    const config = parameters.$config
    let path = 'api/apiCredential/generate'
    let body
    let queryParameters = {}
    let form = {}
    logFormData(parameters.form)
    if (parameters['body'] !== undefined) {
      body = parameters['body']
    }
    if (parameters.$queryParameters) {
      Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
        queryParameters[parameterName] = parameters.$queryParameters[parameterName]
      });
    }
    const r = generateRequest('post', path, body, queryParameters, form, config)
    return $axios.$post(r.url, r.opts, r.config)
  },
  getOne: (parameters = {}) => {
    const config = parameters.$config
    let path = 'api/apiCredential/getOne'
    let body
    let queryParameters = {}
    let form = {}
    logFormData(parameters.form)
    if (parameters['body'] !== undefined) {
      body = parameters['body']
    }
    if (parameters.$queryParameters) {
      Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
        queryParameters[parameterName] = parameters.$queryParameters[parameterName]
      });
    }
    const r = generateRequest('post', path, body, queryParameters, form, config)
    return $axios.$post(r.url, r.opts, r.config)
  },
})
/* authoringGeneration */
const authoringGeneration = $axios => ({
  generateSequence: (parameters = {}) => {
    const config = parameters.$config
    let path = 'api/authoring-generation/generate-sequence'
    let body
    let queryParameters = {}
    let form = {}
    logFormData(parameters.form)
    if (parameters['body'] !== undefined) {
      body = parameters['body']
    }
    if (parameters.$queryParameters) {
      Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
        queryParameters[parameterName] = parameters.$queryParameters[parameterName]
      });
    }
    const r = generateRequest('post', path, body, queryParameters, form, config)
    return $axios.$post(r.url, r.opts, r.config)
  },
  getGenerativeObjects: (parameters = {}) => {
    const config = parameters.$config
    let path = 'api/authoring-generation/get-generative-objects'
    let body
    let queryParameters = {}
    let form = {}
    logFormData(parameters.form)
    if (parameters['body'] !== undefined) {
      body = parameters['body']
    }
    if (parameters.$queryParameters) {
      Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
        queryParameters[parameterName] = parameters.$queryParameters[parameterName]
      });
    }
    const r = generateRequest('post', path, body, queryParameters, form, config)
    return $axios.$post(r.url, r.opts, r.config)
  },
})
/* authorization */
const authorization = $axios => ({
  algoliaSecureKey: (parameters = {}) => {
    const config = parameters.$config
    let path = 'api/authorization/get-algolia-secure-key'
    let body
    let queryParameters = {}
    let form = {}
    logFormData(parameters.form)
    if (parameters.$queryParameters) {
      Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
        queryParameters[parameterName] = parameters.$queryParameters[parameterName]
      });
    }
    const r = generateRequest('get', path, body, queryParameters, form, config)
    return $axios.$get(r.url, r.config)
  },
  connect: (parameters = {}) => {
    const config = parameters.$config
    let path = 'api/authorization/connect'
    let body
    let queryParameters = {}
    let form = {}
    logFormData(parameters.form)
    if (parameters['body'] !== undefined) {
      body = parameters['body']
    }
    if (parameters.$queryParameters) {
      Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
        queryParameters[parameterName] = parameters.$queryParameters[parameterName]
      });
    }
    const r = generateRequest('post', path, body, queryParameters, form, config)
    return $axios.$post(r.url, r.opts, r.config)
  },
  validationEmbedSDK: (parameters = {}) => {
    const config = parameters.$config
    let path = 'api/authorization/validation-embed-sdk'
    let body
    let queryParameters = {}
    let form = {}
    logFormData(parameters.form)
    if (parameters['body'] !== undefined) {
      body = parameters['body']
    }
    if (parameters.$queryParameters) {
      Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
        queryParameters[parameterName] = parameters.$queryParameters[parameterName]
      });
    }
    const r = generateRequest('post', path, body, queryParameters, form, config)
    return $axios.$post(r.url, r.opts, r.config)
  },
})
/* breadcrumb */
const breadcrumb = $axios => ({
  getElements: (parameters = {}) => {
    const config = parameters.$config
    let path = 'api/breadcrumb/get-elements'
    let body
    let queryParameters = {}
    let form = {}
    logFormData(parameters.form)
    if (parameters['body'] !== undefined) {
      body = parameters['body']
    }
    if (parameters.$queryParameters) {
      Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
        queryParameters[parameterName] = parameters.$queryParameters[parameterName]
      });
    }
    const r = generateRequest('post', path, body, queryParameters, form, config)
    return $axios.$post(r.url, r.opts, r.config)
  },
})
/* calendar */
const calendar = $axios => ({
  get: (parameters = {}) => {
    const config = parameters.$config
    let path = 'api/calendar'
    let body
    let queryParameters = {}
    let form = {}
    logFormData(parameters.form)
    if (parameters.$queryParameters) {
      Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
        queryParameters[parameterName] = parameters.$queryParameters[parameterName]
      });
    }
    const r = generateRequest('get', path, body, queryParameters, form, config)
    return $axios.$get(r.url, r.config)
  },
})
/* course */
const course = $axios => ({
  addModule: (parameters = {}) => {
    const config = parameters.$config
    let path = 'api/course/{id}/addModule'
    let body
    let queryParameters = {}
    let form = {}
    logFormData(parameters.form)
    if (parameters['body'] !== undefined) {
      body = parameters['body']
    }
    if (parameters['id'] === undefined) {
      return Promise.reject(new Error('Missing required parameter: id'))
    }
    path = path.replace('{id}', `${parameters['id']}`)
    if (parameters.$queryParameters) {
      Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
        queryParameters[parameterName] = parameters.$queryParameters[parameterName]
      });
    }
    const r = generateRequest('post', path, body, queryParameters, form, config)
    return $axios.$post(r.url, r.opts, r.config)
  },
  archive: (parameters = {}) => {
    const config = parameters.$config
    let path = 'api/course/{id}/archive'
    let body
    let queryParameters = {}
    let form = {}
    logFormData(parameters.form)
    if (parameters['body'] !== undefined) {
      body = parameters['body']
    }
    if (parameters['id'] === undefined) {
      return Promise.reject(new Error('Missing required parameter: id'))
    }
    path = path.replace('{id}', `${parameters['id']}`)
    if (parameters.$queryParameters) {
      Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
        queryParameters[parameterName] = parameters.$queryParameters[parameterName]
      });
    }
    const r = generateRequest('put', path, body, queryParameters, form, config)
    return $axios.$put(r.url, r.opts, r.config)
  },
  clearLmsData: (parameters = {}) => {
    const config = parameters.$config
    let path = 'api/course/{id}/clearLms'
    let body
    let queryParameters = {}
    let form = {}
    logFormData(parameters.form)
    if (parameters['body'] !== undefined) {
      body = parameters['body']
    }
    if (parameters['id'] === undefined) {
      return Promise.reject(new Error('Missing required parameter: id'))
    }
    path = path.replace('{id}', `${parameters['id']}`)
    if (parameters.$queryParameters) {
      Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
        queryParameters[parameterName] = parameters.$queryParameters[parameterName]
      });
    }
    const r = generateRequest('put', path, body, queryParameters, form, config)
    return $axios.$put(r.url, r.opts, r.config)
  },
  create: (parameters = {}) => {
    const config = parameters.$config
    let path = 'api/course'
    let body
    let queryParameters = {}
    let form = {}
    logFormData(parameters.form)
    if (parameters['body'] !== undefined) {
      body = parameters['body']
    }
    if (parameters.$queryParameters) {
      Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
        queryParameters[parameterName] = parameters.$queryParameters[parameterName]
      });
    }
    const r = generateRequest('post', path, body, queryParameters, form, config)
    return $axios.$post(r.url, r.opts, r.config)
  },
  delete: (parameters = {}) => {
    const config = parameters.$config
    let path = 'api/course/{id}'
    let body
    let queryParameters = {}
    let form = {}
    logFormData(parameters.form)
    if (parameters['id'] === undefined) {
      return Promise.reject(new Error('Missing required parameter: id'))
    }
    path = path.replace('{id}', `${parameters['id']}`)
    if (parameters.$queryParameters) {
      Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
        queryParameters[parameterName] = parameters.$queryParameters[parameterName]
      });
    }
    const r = generateRequest('delete', path, body, queryParameters, form, config)
    return $axios.$delete(r.url, r.config)
  },
  get: (parameters = {}) => {
    const config = parameters.$config
    let path = 'api/course'
    let body
    let queryParameters = {}
    let form = {}
    logFormData(parameters.form)
    if (parameters.$queryParameters) {
      Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
        queryParameters[parameterName] = parameters.$queryParameters[parameterName]
      });
    }
    const r = generateRequest('get', path, body, queryParameters, form, config)
    return $axios.$get(r.url, r.config)
  },
  getChildren: (parameters = {}) => {
    const config = parameters.$config
    let path = 'api/course/{id}/getChildren'
    let body
    let queryParameters = {}
    let form = {}
    logFormData(parameters.form)
    if (parameters['id'] === undefined) {
      return Promise.reject(new Error('Missing required parameter: id'))
    }
    path = path.replace('{id}', `${parameters['id']}`)
    if (parameters.$queryParameters) {
      Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
        queryParameters[parameterName] = parameters.$queryParameters[parameterName]
      });
    }
    const r = generateRequest('get', path, body, queryParameters, form, config)
    return $axios.$get(r.url, r.config)
  },
  getOne: (parameters = {}) => {
    const config = parameters.$config
    let path = 'api/course/{id}'
    let body
    let queryParameters = {}
    let form = {}
    logFormData(parameters.form)
    if (parameters['id'] === undefined) {
      return Promise.reject(new Error('Missing required parameter: id'))
    }
    path = path.replace('{id}', `${parameters['id']}`)
    if (parameters.$queryParameters) {
      Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
        queryParameters[parameterName] = parameters.$queryParameters[parameterName]
      });
    }
    const r = generateRequest('get', path, body, queryParameters, form, config)
    return $axios.$get(r.url, r.config)
  },
  getRecommendations: (parameters = {}) => {
    const config = parameters.$config
    let path = 'api/course/{id}/recommendations/{recommendationType}'
    let body
    let queryParameters = {}
    let form = {}
    logFormData(parameters.form)
    if (parameters['id'] === undefined) {
      return Promise.reject(new Error('Missing required parameter: id'))
    }
    path = path.replace('{id}', `${parameters['id']}`)
    if (parameters['recommendationType'] === undefined) {
      return Promise.reject(new Error('Missing required parameter: recommendationType'))
    }
    path = path.replace('{recommendationType}', `${parameters['recommendationType']}`)
    if (parameters.$queryParameters) {
      Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
        queryParameters[parameterName] = parameters.$queryParameters[parameterName]
      });
    }
    const r = generateRequest('get', path, body, queryParameters, form, config)
    return $axios.$get(r.url, r.config)
  },
  lmsDataStatus: (parameters = {}) => {
    const config = parameters.$config
    let path = 'api/course/{id}/lmsDataStatus'
    let body
    let queryParameters = {}
    let form = {}
    logFormData(parameters.form)
    if (parameters['id'] === undefined) {
      return Promise.reject(new Error('Missing required parameter: id'))
    }
    path = path.replace('{id}', `${parameters['id']}`)
    if (parameters.$queryParameters) {
      Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
        queryParameters[parameterName] = parameters.$queryParameters[parameterName]
      });
    }
    const r = generateRequest('get', path, body, queryParameters, form, config)
    return $axios.$get(r.url, r.config)
  },
  removeModule: (parameters = {}) => {
    const config = parameters.$config
    let path = 'api/course/{id}/removeModule'
    let body
    let queryParameters = {}
    let form = {}
    logFormData(parameters.form)
    if (parameters['body'] !== undefined) {
      body = parameters['body']
    }
    if (parameters['id'] === undefined) {
      return Promise.reject(new Error('Missing required parameter: id'))
    }
    path = path.replace('{id}', `${parameters['id']}`)
    if (parameters.$queryParameters) {
      Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
        queryParameters[parameterName] = parameters.$queryParameters[parameterName]
      });
    }
    const r = generateRequest('post', path, body, queryParameters, form, config)
    return $axios.$post(r.url, r.opts, r.config)
  },
  setStatus: (parameters = {}) => {
    const config = parameters.$config
    let path = 'api/course/{id}/status'
    let body
    let queryParameters = {}
    let form = {}
    logFormData(parameters.form)
    if (parameters['body'] !== undefined) {
      body = parameters['body']
    }
    if (parameters['id'] === undefined) {
      return Promise.reject(new Error('Missing required parameter: id'))
    }
    path = path.replace('{id}', `${parameters['id']}`)
    if (parameters.$queryParameters) {
      Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
        queryParameters[parameterName] = parameters.$queryParameters[parameterName]
      });
    }
    const r = generateRequest('put', path, body, queryParameters, form, config)
    return $axios.$put(r.url, r.opts, r.config)
  },
  update: (parameters = {}) => {
    const config = parameters.$config
    let path = 'api/course/{id}'
    let body
    let queryParameters = {}
    let form = {}
    logFormData(parameters.form)
    if (parameters['body'] !== undefined) {
      body = parameters['body']
    }
    if (parameters['id'] === undefined) {
      return Promise.reject(new Error('Missing required parameter: id'))
    }
    path = path.replace('{id}', `${parameters['id']}`)
    if (parameters.$queryParameters) {
      Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
        queryParameters[parameterName] = parameters.$queryParameters[parameterName]
      });
    }
    const r = generateRequest('put', path, body, queryParameters, form, config)
    return $axios.$put(r.url, r.opts, r.config)
  },
  updateLmsData: (parameters = {}) => {
    const config = parameters.$config
    let path = 'api/course/{id}/lms'
    let body
    let queryParameters = {}
    let form = {}
    logFormData(parameters.form)
    if (parameters['body'] !== undefined) {
      body = parameters['body']
    }
    if (parameters['id'] === undefined) {
      return Promise.reject(new Error('Missing required parameter: id'))
    }
    path = path.replace('{id}', `${parameters['id']}`)
    if (parameters.$queryParameters) {
      Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
        queryParameters[parameterName] = parameters.$queryParameters[parameterName]
      });
    }
    const r = generateRequest('put', path, body, queryParameters, form, config)
    return $axios.$put(r.url, r.opts, r.config)
  },
})
/* deliverable */
const deliverable = $axios => ({
  archive: (parameters = {}) => {
    const config = parameters.$config
    let path = 'api/deliverable/{id}/archive'
    let body
    let queryParameters = {}
    let form = {}
    logFormData(parameters.form)
    if (parameters['body'] !== undefined) {
      body = parameters['body']
    }
    if (parameters['id'] === undefined) {
      return Promise.reject(new Error('Missing required parameter: id'))
    }
    path = path.replace('{id}', `${parameters['id']}`)
    if (parameters.$queryParameters) {
      Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
        queryParameters[parameterName] = parameters.$queryParameters[parameterName]
      });
    }
    const r = generateRequest('put', path, body, queryParameters, form, config)
    return $axios.$put(r.url, r.opts, r.config)
  },
  bulkCreate: (parameters = {}) => {
    const config = parameters.$config
    let path = 'api/deliverable/bulk/{parentProject?}'
    let body
    let queryParameters = {}
    let form = {}
    logFormData(parameters.form)
    if (parameters['body'] !== undefined) {
      body = parameters['body']
    }
    if (parameters['parentProject']) {
      path = path.replace('{parentProject?}', `${parameters['parentProject']}`)
    } else {
      path = path.replace('{parentProject?}', '')
    }
    if (parameters.$queryParameters) {
      Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
        queryParameters[parameterName] = parameters.$queryParameters[parameterName]
      });
    }
    const r = generateRequest('post', path, body, queryParameters, form, config)
    return $axios.$post(r.url, r.opts, r.config)
  },
  delete: (parameters = {}) => {
    const config = parameters.$config
    let path = 'api/deliverable/{id}'
    let body
    let queryParameters = {}
    let form = {}
    logFormData(parameters.form)
    if (parameters['id'] === undefined) {
      return Promise.reject(new Error('Missing required parameter: id'))
    }
    path = path.replace('{id}', `${parameters['id']}`)
    if (parameters.$queryParameters) {
      Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
        queryParameters[parameterName] = parameters.$queryParameters[parameterName]
      });
    }
    const r = generateRequest('delete', path, body, queryParameters, form, config)
    return $axios.$delete(r.url, r.config)
  },
  get: (parameters = {}) => {
    const config = parameters.$config
    let path = 'api/deliverable'
    let body
    let queryParameters = {}
    let form = {}
    logFormData(parameters.form)
    if (parameters.$queryParameters) {
      Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
        queryParameters[parameterName] = parameters.$queryParameters[parameterName]
      });
    }
    const r = generateRequest('get', path, body, queryParameters, form, config)
    return $axios.$get(r.url, r.config)
  },
  getChildren: (parameters = {}) => {
    const config = parameters.$config
    let path = 'api/deliverable/{id}/getChildren'
    let body
    let queryParameters = {}
    let form = {}
    logFormData(parameters.form)
    if (parameters['id'] === undefined) {
      return Promise.reject(new Error('Missing required parameter: id'))
    }
    path = path.replace('{id}', `${parameters['id']}`)
    if (parameters.$queryParameters) {
      Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
        queryParameters[parameterName] = parameters.$queryParameters[parameterName]
      });
    }
    const r = generateRequest('get', path, body, queryParameters, form, config)
    return $axios.$get(r.url, r.config)
  },
  getOne: (parameters = {}) => {
    const config = parameters.$config
    let path = 'api/deliverable/{id}'
    let body
    let queryParameters = {}
    let form = {}
    logFormData(parameters.form)
    if (parameters['id'] === undefined) {
      return Promise.reject(new Error('Missing required parameter: id'))
    }
    path = path.replace('{id}', `${parameters['id']}`)
    if (parameters.$queryParameters) {
      Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
        queryParameters[parameterName] = parameters.$queryParameters[parameterName]
      });
    }
    const r = generateRequest('get', path, body, queryParameters, form, config)
    return $axios.$get(r.url, r.config)
  },
  setStatus: (parameters = {}) => {
    const config = parameters.$config
    let path = 'api/deliverable/{id}/status'
    let body
    let queryParameters = {}
    let form = {}
    logFormData(parameters.form)
    if (parameters['body'] !== undefined) {
      body = parameters['body']
    }
    if (parameters['id'] === undefined) {
      return Promise.reject(new Error('Missing required parameter: id'))
    }
    path = path.replace('{id}', `${parameters['id']}`)
    if (parameters.$queryParameters) {
      Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
        queryParameters[parameterName] = parameters.$queryParameters[parameterName]
      });
    }
    const r = generateRequest('put', path, body, queryParameters, form, config)
    return $axios.$put(r.url, r.opts, r.config)
  },
  toTemplate: (parameters = {}) => {
    const config = parameters.$config
    let path = 'api/deliverable/{id}/toTemplate'
    let body
    let queryParameters = {}
    let form = {}
    logFormData(parameters.form)
    if (parameters['body'] !== undefined) {
      body = parameters['body']
    }
    if (parameters['id'] === undefined) {
      return Promise.reject(new Error('Missing required parameter: id'))
    }
    path = path.replace('{id}', `${parameters['id']}`)
    if (parameters.$queryParameters) {
      Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
        queryParameters[parameterName] = parameters.$queryParameters[parameterName]
      });
    }
    const r = generateRequest('post', path, body, queryParameters, form, config)
    return $axios.$post(r.url, r.opts, r.config)
  },
  update: (parameters = {}) => {
    const config = parameters.$config
    let path = 'api/deliverable/{id}'
    let body
    let queryParameters = {}
    let form = {}
    logFormData(parameters.form)
    if (parameters['body'] !== undefined) {
      body = parameters['body']
    }
    if (parameters['id'] === undefined) {
      return Promise.reject(new Error('Missing required parameter: id'))
    }
    path = path.replace('{id}', `${parameters['id']}`)
    if (parameters.$queryParameters) {
      Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
        queryParameters[parameterName] = parameters.$queryParameters[parameterName]
      });
    }
    const r = generateRequest('put', path, body, queryParameters, form, config)
    return $axios.$put(r.url, r.opts, r.config)
  },
})
/* edtakeDocument */
const edtakeDocument = $axios => ({
  create: (parameters = {}) => {
    const config = parameters.$config
    let path = 'api/edtake-document'
    let body
    let queryParameters = {}
    let form = {}
    logFormData(parameters.form)
    if (parameters['body'] !== undefined) {
      body = parameters['body']
    }
    if (parameters.$queryParameters) {
      Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
        queryParameters[parameterName] = parameters.$queryParameters[parameterName]
      });
    }
    const r = generateRequest('post', path, body, queryParameters, form, config)
    return $axios.$post(r.url, r.opts, r.config)
  },
  createFromTemplate: (parameters = {}) => {
    const config = parameters.$config
    let path = 'api/edtake-document/createFromTemplate/{id}'
    let body
    let queryParameters = {}
    let form = {}
    logFormData(parameters.form)
    if (parameters['body'] !== undefined) {
      body = parameters['body']
    }
    if (parameters['id'] === undefined) {
      return Promise.reject(new Error('Missing required parameter: id'))
    }
    path = path.replace('{id}', `${parameters['id']}`)
    if (parameters.$queryParameters) {
      Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
        queryParameters[parameterName] = parameters.$queryParameters[parameterName]
      });
    }
    const r = generateRequest('post', path, body, queryParameters, form, config)
    return $axios.$post(r.url, r.opts, r.config)
  },
  delete: (parameters = {}) => {
    const config = parameters.$config
    let path = 'api/edtake-document/{id}'
    let body
    let queryParameters = {}
    let form = {}
    logFormData(parameters.form)
    if (parameters['id'] === undefined) {
      return Promise.reject(new Error('Missing required parameter: id'))
    }
    path = path.replace('{id}', `${parameters['id']}`)
    if (parameters.$queryParameters) {
      Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
        queryParameters[parameterName] = parameters.$queryParameters[parameterName]
      });
    }
    const r = generateRequest('delete', path, body, queryParameters, form, config)
    return $axios.$delete(r.url, r.config)
  },
  get: (parameters = {}) => {
    const config = parameters.$config
    let path = 'api/edtake-document'
    let body
    let queryParameters = {}
    let form = {}
    logFormData(parameters.form)
    if (parameters.$queryParameters) {
      Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
        queryParameters[parameterName] = parameters.$queryParameters[parameterName]
      });
    }
    const r = generateRequest('get', path, body, queryParameters, form, config)
    return $axios.$get(r.url, r.config)
  },
  getOne: (parameters = {}) => {
    const config = parameters.$config
    let path = 'api/edtake-document/{id}'
    let body
    let queryParameters = {}
    let form = {}
    logFormData(parameters.form)
    if (parameters['id'] === undefined) {
      return Promise.reject(new Error('Missing required parameter: id'))
    }
    path = path.replace('{id}', `${parameters['id']}`)
    if (parameters.$queryParameters) {
      Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
        queryParameters[parameterName] = parameters.$queryParameters[parameterName]
      });
    }
    const r = generateRequest('get', path, body, queryParameters, form, config)
    return $axios.$get(r.url, r.config)
  },
  toTemplate: (parameters = {}) => {
    const config = parameters.$config
    let path = 'api/edtake-document/{id}/toTemplate'
    let body
    let queryParameters = {}
    let form = {}
    logFormData(parameters.form)
    if (parameters['body'] !== undefined) {
      body = parameters['body']
    }
    if (parameters['id'] === undefined) {
      return Promise.reject(new Error('Missing required parameter: id'))
    }
    path = path.replace('{id}', `${parameters['id']}`)
    if (parameters.$queryParameters) {
      Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
        queryParameters[parameterName] = parameters.$queryParameters[parameterName]
      });
    }
    const r = generateRequest('post', path, body, queryParameters, form, config)
    return $axios.$post(r.url, r.opts, r.config)
  },
  update: (parameters = {}) => {
    const config = parameters.$config
    let path = 'api/edtake-document/{id}'
    let body
    let queryParameters = {}
    let form = {}
    logFormData(parameters.form)
    if (parameters['body'] !== undefined) {
      body = parameters['body']
    }
    if (parameters['id'] === undefined) {
      return Promise.reject(new Error('Missing required parameter: id'))
    }
    path = path.replace('{id}', `${parameters['id']}`)
    if (parameters.$queryParameters) {
      Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
        queryParameters[parameterName] = parameters.$queryParameters[parameterName]
      });
    }
    const r = generateRequest('put', path, body, queryParameters, form, config)
    return $axios.$put(r.url, r.opts, r.config)
  },
  updateContentData: (parameters = {}) => {
    const config = parameters.$config
    let path = 'api/edtake-document/{id}/content-data'
    let body
    let queryParameters = {}
    let form = {}
    logFormData(parameters.form)
    if (parameters['body'] !== undefined) {
      body = parameters['body']
    }
    if (parameters['id'] === undefined) {
      return Promise.reject(new Error('Missing required parameter: id'))
    }
    path = path.replace('{id}', `${parameters['id']}`)
    if (parameters.$queryParameters) {
      Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
        queryParameters[parameterName] = parameters.$queryParameters[parameterName]
      });
    }
    const r = generateRequest('put', path, body, queryParameters, form, config)
    return $axios.$put(r.url, r.opts, r.config)
  },
})
/* favoriteEdtaker */
const favoriteEdtaker = $axios => ({
  bulkCreate: (parameters = {}) => {
    const config = parameters.$config
    let path = 'api/favorite-edtaker/{workspace}/bulk'
    let body
    let queryParameters = {}
    let form = {}
    logFormData(parameters.form)
    if (parameters['body'] !== undefined) {
      body = parameters['body']
    }
    if (parameters['workspace'] === undefined) {
      return Promise.reject(new Error('Missing required parameter: workspace'))
    }
    path = path.replace('{workspace}', `${parameters['workspace']}`)
    if (parameters.$queryParameters) {
      Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
        queryParameters[parameterName] = parameters.$queryParameters[parameterName]
      });
    }
    const r = generateRequest('post', path, body, queryParameters, form, config)
    return $axios.$post(r.url, r.opts, r.config)
  },
  create: (parameters = {}) => {
    const config = parameters.$config
    let path = 'api/favorite-edtaker/{workspace}'
    let body
    let queryParameters = {}
    let form = {}
    logFormData(parameters.form)
    if (parameters['body'] !== undefined) {
      body = parameters['body']
    }
    if (parameters['workspace'] === undefined) {
      return Promise.reject(new Error('Missing required parameter: workspace'))
    }
    path = path.replace('{workspace}', `${parameters['workspace']}`)
    if (parameters.$queryParameters) {
      Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
        queryParameters[parameterName] = parameters.$queryParameters[parameterName]
      });
    }
    const r = generateRequest('post', path, body, queryParameters, form, config)
    return $axios.$post(r.url, r.opts, r.config)
  },
  get: (parameters = {}) => {
    const config = parameters.$config
    let path = 'api/favorite-edtaker'
    let body
    let queryParameters = {}
    let form = {}
    logFormData(parameters.form)
    if (parameters.$queryParameters) {
      Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
        queryParameters[parameterName] = parameters.$queryParameters[parameterName]
      });
    }
    const r = generateRequest('get', path, body, queryParameters, form, config)
    return $axios.$get(r.url, r.config)
  },
  getAlreadyWorkedWith: (parameters = {}) => {
    const config = parameters.$config
    let path = 'api/favorite-edtaker/already-worked-with'
    let body
    let queryParameters = {}
    let form = {}
    logFormData(parameters.form)
    if (parameters.$queryParameters) {
      Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
        queryParameters[parameterName] = parameters.$queryParameters[parameterName]
      });
    }
    const r = generateRequest('get', path, body, queryParameters, form, config)
    return $axios.$get(r.url, r.config)
  },
  getMissionWithEdtaker: (parameters = {}) => {
    const config = parameters.$config
    let path = 'api/favorite-edtaker/mission-with-edtaker/{edtakerId}'
    let body
    let queryParameters = {}
    let form = {}
    logFormData(parameters.form)
    if (parameters['edtakerId'] === undefined) {
      return Promise.reject(new Error('Missing required parameter: edtakerId'))
    }
    path = path.replace('{edtakerId}', `${parameters['edtakerId']}`)
    if (parameters.$queryParameters) {
      Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
        queryParameters[parameterName] = parameters.$queryParameters[parameterName]
      });
    }
    const r = generateRequest('get', path, body, queryParameters, form, config)
    return $axios.$get(r.url, r.config)
  },
  getOne: (parameters = {}) => {
    const config = parameters.$config
    let path = 'api/favorite-edtaker/{id}'
    let body
    let queryParameters = {}
    let form = {}
    logFormData(parameters.form)
    if (parameters['id'] === undefined) {
      return Promise.reject(new Error('Missing required parameter: id'))
    }
    path = path.replace('{id}', `${parameters['id']}`)
    if (parameters.$queryParameters) {
      Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
        queryParameters[parameterName] = parameters.$queryParameters[parameterName]
      });
    }
    const r = generateRequest('get', path, body, queryParameters, form, config)
    return $axios.$get(r.url, r.config)
  },
  remove: (parameters = {}) => {
    const config = parameters.$config
    let path = 'api/favorite-edtaker/{id}'
    let body
    let queryParameters = {}
    let form = {}
    logFormData(parameters.form)
    if (parameters['id'] === undefined) {
      return Promise.reject(new Error('Missing required parameter: id'))
    }
    path = path.replace('{id}', `${parameters['id']}`)
    if (parameters.$queryParameters) {
      Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
        queryParameters[parameterName] = parameters.$queryParameters[parameterName]
      });
    }
    const r = generateRequest('delete', path, body, queryParameters, form, config)
    return $axios.$delete(r.url, r.config)
  },
  update: (parameters = {}) => {
    const config = parameters.$config
    let path = 'api/favorite-edtaker/{id}'
    let body
    let queryParameters = {}
    let form = {}
    logFormData(parameters.form)
    if (parameters['body'] !== undefined) {
      body = parameters['body']
    }
    if (parameters['id'] === undefined) {
      return Promise.reject(new Error('Missing required parameter: id'))
    }
    path = path.replace('{id}', `${parameters['id']}`)
    if (parameters.$queryParameters) {
      Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
        queryParameters[parameterName] = parameters.$queryParameters[parameterName]
      });
    }
    const r = generateRequest('put', path, body, queryParameters, form, config)
    return $axios.$put(r.url, r.opts, r.config)
  },
})
/* inokufu */
const inokufu = $axios => ({
  LOSearchByType: (parameters = {}) => {
    const config = parameters.$config
    let path = 'api/inokufu/LOSearchByType'
    let body
    let queryParameters = {}
    let form = {}
    logFormData(parameters.form)
    if (parameters.$queryParameters) {
      Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
        queryParameters[parameterName] = parameters.$queryParameters[parameterName]
      });
    }
    const r = generateRequest('get', path, body, queryParameters, form, config)
    return $axios.$get(r.url, r.config)
  },
  getLicenses: (parameters = {}) => {
    const config = parameters.$config
    let path = 'api/inokufu/licenses'
    let body
    let queryParameters = {}
    let form = {}
    logFormData(parameters.form)
    if (parameters.$queryParameters) {
      Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
        queryParameters[parameterName] = parameters.$queryParameters[parameterName]
      });
    }
    const r = generateRequest('get', path, body, queryParameters, form, config)
    return $axios.$get(r.url, r.config)
  },
})
/* integration */
const integration = $axios => ({
  get: (parameters = {}) => {
    const config = parameters.$config
    let path = 'api/integration'
    let body
    let queryParameters = {}
    let form = {}
    logFormData(parameters.form)
    if (parameters.$queryParameters) {
      Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
        queryParameters[parameterName] = parameters.$queryParameters[parameterName]
      });
    }
    const r = generateRequest('get', path, body, queryParameters, form, config)
    return $axios.$get(r.url, r.config)
  },
})
/* learningActivity */
const learningActivity = $axios => ({
  update: (parameters = {}) => {
    const config = parameters.$config
    let path = 'api/learningActivity/{id}'
    let body
    let queryParameters = {}
    let form = {}
    logFormData(parameters.form)
    if (parameters['body'] !== undefined) {
      body = parameters['body']
    }
    if (parameters['id'] === undefined) {
      return Promise.reject(new Error('Missing required parameter: id'))
    }
    path = path.replace('{id}', `${parameters['id']}`)
    if (parameters.$queryParameters) {
      Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
        queryParameters[parameterName] = parameters.$queryParameters[parameterName]
      });
    }
    const r = generateRequest('put', path, body, queryParameters, form, config)
    return $axios.$put(r.url, r.opts, r.config)
  },
})
/* learningContent */
const learningContent = $axios => ({
  create: (parameters = {}) => {
    const config = parameters.$config
    let path = 'api/learningContent'
    let body
    let queryParameters = {}
    let form = {}
    logFormData(parameters.form)
    if (parameters['body'] !== undefined) {
      body = parameters['body']
    }
    if (parameters.$queryParameters) {
      Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
        queryParameters[parameterName] = parameters.$queryParameters[parameterName]
      });
    }
    const r = generateRequest('post', path, body, queryParameters, form, config)
    return $axios.$post(r.url, r.opts, r.config)
  },
  delete: (parameters = {}) => {
    const config = parameters.$config
    let path = 'api/learningContent/{id}'
    let body
    let queryParameters = {}
    let form = {}
    logFormData(parameters.form)
    if (parameters['id'] === undefined) {
      return Promise.reject(new Error('Missing required parameter: id'))
    }
    path = path.replace('{id}', `${parameters['id']}`)
    if (parameters.$queryParameters) {
      Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
        queryParameters[parameterName] = parameters.$queryParameters[parameterName]
      });
    }
    const r = generateRequest('delete', path, body, queryParameters, form, config)
    return $axios.$delete(r.url, r.config)
  },
  get: (parameters = {}) => {
    const config = parameters.$config
    let path = 'api/learningContent/{parentId}'
    let body
    let queryParameters = {}
    let form = {}
    logFormData(parameters.form)
    if (parameters['parentId'] === undefined) {
      return Promise.reject(new Error('Missing required parameter: parentId'))
    }
    path = path.replace('{parentId}', `${parameters['parentId']}`)
    if (parameters.$queryParameters) {
      Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
        queryParameters[parameterName] = parameters.$queryParameters[parameterName]
      });
    }
    const r = generateRequest('get', path, body, queryParameters, form, config)
    return $axios.$get(r.url, r.config)
  },
  getByCustomId: (parameters = {}) => {
    const config = parameters.$config
    let path = 'api/learningContent/custom/{customId}'
    let body
    let queryParameters = {}
    let form = {}
    logFormData(parameters.form)
    if (parameters['customId'] === undefined) {
      return Promise.reject(new Error('Missing required parameter: customId'))
    }
    path = path.replace('{customId}', `${parameters['customId']}`)
    if (parameters.$queryParameters) {
      Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
        queryParameters[parameterName] = parameters.$queryParameters[parameterName]
      });
    }
    const r = generateRequest('get', path, body, queryParameters, form, config)
    return $axios.$get(r.url, r.config)
  },
  update: (parameters = {}) => {
    const config = parameters.$config
    let path = 'api/learningContent/{id}'
    let body
    let queryParameters = {}
    let form = {}
    logFormData(parameters.form)
    if (parameters['body'] !== undefined) {
      body = parameters['body']
    }
    if (parameters['id'] === undefined) {
      return Promise.reject(new Error('Missing required parameter: id'))
    }
    path = path.replace('{id}', `${parameters['id']}`)
    if (parameters.$queryParameters) {
      Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
        queryParameters[parameterName] = parameters.$queryParameters[parameterName]
      });
    }
    const r = generateRequest('put', path, body, queryParameters, form, config)
    return $axios.$put(r.url, r.opts, r.config)
  },
  updateOrder: (parameters = {}) => {
    const config = parameters.$config
    let path = 'api/learningContent/updateOrder'
    let body
    let queryParameters = {}
    let form = {}
    logFormData(parameters.form)
    if (parameters['body'] !== undefined) {
      body = parameters['body']
    }
    if (parameters.$queryParameters) {
      Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
        queryParameters[parameterName] = parameters.$queryParameters[parameterName]
      });
    }
    const r = generateRequest('put', path, body, queryParameters, form, config)
    return $axios.$put(r.url, r.opts, r.config)
  },
  uploadFile: (parameters = {}) => {
    const config = parameters.$config
    let path = 'api/learningContent/{id}/uploadFile'
    let body
    let queryParameters = {}
    if (parameters.form === undefined) {
      return Promise.reject(new Error('Missing required  parameter: form'))
    }
    let form = parameters.form
    if (parameters['body'] !== undefined) {
      body = parameters['body']
    }
    if (parameters['id'] === undefined) {
      return Promise.reject(new Error('Missing required parameter: id'))
    }
    path = path.replace('{id}', `${parameters['id']}`)
    if (parameters.$queryParameters) {
      Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
        queryParameters[parameterName] = parameters.$queryParameters[parameterName]
      });
    }
    const r = generateRequest('put', path, body, queryParameters, form, config)
    return $axios.$put(r.url, r.opts, r.config)
  },
})
/* learningObject */
const learningObject = $axios => ({
  bulkUpdate: (parameters = {}) => {
    const config = parameters.$config
    let path = 'api/learningObject/bulk-update'
    let body
    let queryParameters = {}
    let form = {}
    logFormData(parameters.form)
    if (parameters['body'] !== undefined) {
      body = parameters['body']
    }
    if (parameters.$queryParameters) {
      Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
        queryParameters[parameterName] = parameters.$queryParameters[parameterName]
      });
    }
    const r = generateRequest('put', path, body, queryParameters, form, config)
    return $axios.$put(r.url, r.opts, r.config)
  },
  setChildOrder: (parameters = {}) => {
    const config = parameters.$config
    let path = 'api/learningObject/setChildOrder/{id}'
    let body
    let queryParameters = {}
    let form = {}
    logFormData(parameters.form)
    if (parameters['body'] !== undefined) {
      body = parameters['body']
    }
    if (parameters['id'] === undefined) {
      return Promise.reject(new Error('Missing required parameter: id'))
    }
    path = path.replace('{id}', `${parameters['id']}`)
    if (parameters.$queryParameters) {
      Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
        queryParameters[parameterName] = parameters.$queryParameters[parameterName]
      });
    }
    const r = generateRequest('put', path, body, queryParameters, form, config)
    return $axios.$put(r.url, r.opts, r.config)
  },
  setChildrenOrder: (parameters = {}) => {
    const config = parameters.$config
    let path = 'api/learningObject/setChildrenOrder/{id}'
    let body
    let queryParameters = {}
    let form = {}
    logFormData(parameters.form)
    if (parameters['body'] !== undefined) {
      body = parameters['body']
    }
    if (parameters['id'] === undefined) {
      return Promise.reject(new Error('Missing required parameter: id'))
    }
    path = path.replace('{id}', `${parameters['id']}`)
    if (parameters.$queryParameters) {
      Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
        queryParameters[parameterName] = parameters.$queryParameters[parameterName]
      });
    }
    const r = generateRequest('put', path, body, queryParameters, form, config)
    return $axios.$put(r.url, r.opts, r.config)
  },
  updateMembersPermission: (parameters = {}) => {
    const config = parameters.$config
    let path = 'api/learningObject/updateMembersPermission'
    let body
    let queryParameters = {}
    let form = {}
    logFormData(parameters.form)
    if (parameters['body'] !== undefined) {
      body = parameters['body']
    }
    if (parameters.$queryParameters) {
      Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
        queryParameters[parameterName] = parameters.$queryParameters[parameterName]
      });
    }
    const r = generateRequest('put', path, body, queryParameters, form, config)
    return $axios.$put(r.url, r.opts, r.config)
  },
})
/* lms */
const lms = $axios => ({
  bulkOperation: (parameters = {}) => {
    const config = parameters.$config
    let path = 'api/lms/bulk-operation'
    let body
    let queryParameters = {}
    let form = {}
    logFormData(parameters.form)
    if (parameters['body'] !== undefined) {
      body = parameters['body']
    }
    if (parameters.$queryParameters) {
      Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
        queryParameters[parameterName] = parameters.$queryParameters[parameterName]
      });
    }
    const r = generateRequest('post', path, body, queryParameters, form, config)
    return $axios.$post(r.url, r.opts, r.config)
  },
  get: (parameters = {}) => {
    const config = parameters.$config
    let path = 'api/lms'
    let body
    let queryParameters = {}
    let form = {}
    logFormData(parameters.form)
    if (parameters.$queryParameters) {
      Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
        queryParameters[parameterName] = parameters.$queryParameters[parameterName]
      });
    }
    const r = generateRequest('get', path, body, queryParameters, form, config)
    return $axios.$get(r.url, r.config)
  },
  getLink: (parameters = {}) => {
    const config = parameters.$config
    let path = 'api/lms/link'
    let body
    let queryParameters = {}
    let form = {}
    logFormData(parameters.form)
    if (parameters['body'] !== undefined) {
      body = parameters['body']
    }
    if (parameters.$queryParameters) {
      Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
        queryParameters[parameterName] = parameters.$queryParameters[parameterName]
      });
    }
    const r = generateRequest('post', path, body, queryParameters, form, config)
    return $axios.$post(r.url, r.opts, r.config)
  },
  sync: (parameters = {}) => {
    const config = parameters.$config
    let path = 'api/lms/sync'
    let body
    let queryParameters = {}
    let form = {}
    logFormData(parameters.form)
    if (parameters.$queryParameters) {
      Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
        queryParameters[parameterName] = parameters.$queryParameters[parameterName]
      });
    }
    const r = generateRequest('get', path, body, queryParameters, form, config)
    return $axios.$get(r.url, r.config)
  },
  update: (parameters = {}) => {
    const config = parameters.$config
    let path = 'api/lms/{id}'
    let body
    let queryParameters = {}
    let form = {}
    logFormData(parameters.form)
    if (parameters['body'] !== undefined) {
      body = parameters['body']
    }
    if (parameters['id'] === undefined) {
      return Promise.reject(new Error('Missing required parameter: id'))
    }
    path = path.replace('{id}', `${parameters['id']}`)
    if (parameters.$queryParameters) {
      Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
        queryParameters[parameterName] = parameters.$queryParameters[parameterName]
      });
    }
    const r = generateRequest('put', path, body, queryParameters, form, config)
    return $axios.$put(r.url, r.opts, r.config)
  },
})
/* mechanism */
const mechanism = $axios => ({
  addLearningObjectToTarget: (parameters = {}) => {
    const config = parameters.$config
    let path = 'api/mechanism/{id}/addLearningObjectToTarget'
    let body
    let queryParameters = {}
    let form = {}
    logFormData(parameters.form)
    if (parameters['body'] !== undefined) {
      body = parameters['body']
    }
    if (parameters['id'] === undefined) {
      return Promise.reject(new Error('Missing required parameter: id'))
    }
    path = path.replace('{id}', `${parameters['id']}`)
    if (parameters.$queryParameters) {
      Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
        queryParameters[parameterName] = parameters.$queryParameters[parameterName]
      });
    }
    const r = generateRequest('post', path, body, queryParameters, form, config)
    return $axios.$post(r.url, r.opts, r.config)
  },
  addTarget: (parameters = {}) => {
    const config = parameters.$config
    let path = 'api/mechanism/{id}/addTarget'
    let body
    let queryParameters = {}
    let form = {}
    logFormData(parameters.form)
    if (parameters['body'] !== undefined) {
      body = parameters['body']
    }
    if (parameters['id'] === undefined) {
      return Promise.reject(new Error('Missing required parameter: id'))
    }
    path = path.replace('{id}', `${parameters['id']}`)
    if (parameters.$queryParameters) {
      Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
        queryParameters[parameterName] = parameters.$queryParameters[parameterName]
      });
    }
    const r = generateRequest('post', path, body, queryParameters, form, config)
    return $axios.$post(r.url, r.opts, r.config)
  },
  archive: (parameters = {}) => {
    const config = parameters.$config
    let path = 'api/mechanism/{id}/archive'
    let body
    let queryParameters = {}
    let form = {}
    logFormData(parameters.form)
    if (parameters['body'] !== undefined) {
      body = parameters['body']
    }
    if (parameters['id'] === undefined) {
      return Promise.reject(new Error('Missing required parameter: id'))
    }
    path = path.replace('{id}', `${parameters['id']}`)
    if (parameters.$queryParameters) {
      Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
        queryParameters[parameterName] = parameters.$queryParameters[parameterName]
      });
    }
    const r = generateRequest('put', path, body, queryParameters, form, config)
    return $axios.$put(r.url, r.opts, r.config)
  },
  create: (parameters = {}) => {
    const config = parameters.$config
    let path = 'api/mechanism'
    let body
    let queryParameters = {}
    let form = {}
    logFormData(parameters.form)
    if (parameters['body'] !== undefined) {
      body = parameters['body']
    }
    if (parameters.$queryParameters) {
      Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
        queryParameters[parameterName] = parameters.$queryParameters[parameterName]
      });
    }
    const r = generateRequest('post', path, body, queryParameters, form, config)
    return $axios.$post(r.url, r.opts, r.config)
  },
  delete: (parameters = {}) => {
    const config = parameters.$config
    let path = 'api/mechanism/{id}'
    let body
    let queryParameters = {}
    let form = {}
    logFormData(parameters.form)
    if (parameters['id'] === undefined) {
      return Promise.reject(new Error('Missing required parameter: id'))
    }
    path = path.replace('{id}', `${parameters['id']}`)
    if (parameters.$queryParameters) {
      Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
        queryParameters[parameterName] = parameters.$queryParameters[parameterName]
      });
    }
    const r = generateRequest('delete', path, body, queryParameters, form, config)
    return $axios.$delete(r.url, r.config)
  },
  get: (parameters = {}) => {
    const config = parameters.$config
    let path = 'api/mechanism'
    let body
    let queryParameters = {}
    let form = {}
    logFormData(parameters.form)
    if (parameters.$queryParameters) {
      Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
        queryParameters[parameterName] = parameters.$queryParameters[parameterName]
      });
    }
    const r = generateRequest('get', path, body, queryParameters, form, config)
    return $axios.$get(r.url, r.config)
  },
  getChildren: (parameters = {}) => {
    const config = parameters.$config
    let path = 'api/mechanism/{id}/getChildren'
    let body
    let queryParameters = {}
    let form = {}
    logFormData(parameters.form)
    if (parameters['id'] === undefined) {
      return Promise.reject(new Error('Missing required parameter: id'))
    }
    path = path.replace('{id}', `${parameters['id']}`)
    if (parameters.$queryParameters) {
      Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
        queryParameters[parameterName] = parameters.$queryParameters[parameterName]
      });
    }
    const r = generateRequest('get', path, body, queryParameters, form, config)
    return $axios.$get(r.url, r.config)
  },
  getOne: (parameters = {}) => {
    const config = parameters.$config
    let path = 'api/mechanism/{id}'
    let body
    let queryParameters = {}
    let form = {}
    logFormData(parameters.form)
    if (parameters['id'] === undefined) {
      return Promise.reject(new Error('Missing required parameter: id'))
    }
    path = path.replace('{id}', `${parameters['id']}`)
    if (parameters.$queryParameters) {
      Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
        queryParameters[parameterName] = parameters.$queryParameters[parameterName]
      });
    }
    const r = generateRequest('get', path, body, queryParameters, form, config)
    return $axios.$get(r.url, r.config)
  },
  removeLearningObjectToTarget: (parameters = {}) => {
    const config = parameters.$config
    let path = 'api/mechanism/{id}/target/{targetId}/removeLearningObjectToTarget/{learningObjectId}'
    let body
    let queryParameters = {}
    let form = {}
    logFormData(parameters.form)
    if (parameters['body'] !== undefined) {
      body = parameters['body']
    }
    if (parameters['id'] === undefined) {
      return Promise.reject(new Error('Missing required parameter: id'))
    }
    path = path.replace('{id}', `${parameters['id']}`)
    if (parameters['targetId'] === undefined) {
      return Promise.reject(new Error('Missing required parameter: targetId'))
    }
    path = path.replace('{targetId}', `${parameters['targetId']}`)
    if (parameters['learningObjectId'] === undefined) {
      return Promise.reject(new Error('Missing required parameter: learningObjectId'))
    }
    path = path.replace('{learningObjectId}', `${parameters['learningObjectId']}`)
    if (parameters.$queryParameters) {
      Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
        queryParameters[parameterName] = parameters.$queryParameters[parameterName]
      });
    }
    const r = generateRequest('put', path, body, queryParameters, form, config)
    return $axios.$put(r.url, r.opts, r.config)
  },
  removeTarget: (parameters = {}) => {
    const config = parameters.$config
    let path = 'api/mechanism/{id}/target/{targetId}/removeTarget'
    let body
    let queryParameters = {}
    let form = {}
    logFormData(parameters.form)
    if (parameters['body'] !== undefined) {
      body = parameters['body']
    }
    if (parameters['id'] === undefined) {
      return Promise.reject(new Error('Missing required parameter: id'))
    }
    path = path.replace('{id}', `${parameters['id']}`)
    if (parameters['targetId'] === undefined) {
      return Promise.reject(new Error('Missing required parameter: targetId'))
    }
    path = path.replace('{targetId}', `${parameters['targetId']}`)
    if (parameters.$queryParameters) {
      Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
        queryParameters[parameterName] = parameters.$queryParameters[parameterName]
      });
    }
    const r = generateRequest('put', path, body, queryParameters, form, config)
    return $axios.$put(r.url, r.opts, r.config)
  },
  setStatus: (parameters = {}) => {
    const config = parameters.$config
    let path = 'api/mechanism/{id}/status'
    let body
    let queryParameters = {}
    let form = {}
    logFormData(parameters.form)
    if (parameters['body'] !== undefined) {
      body = parameters['body']
    }
    if (parameters['id'] === undefined) {
      return Promise.reject(new Error('Missing required parameter: id'))
    }
    path = path.replace('{id}', `${parameters['id']}`)
    if (parameters.$queryParameters) {
      Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
        queryParameters[parameterName] = parameters.$queryParameters[parameterName]
      });
    }
    const r = generateRequest('put', path, body, queryParameters, form, config)
    return $axios.$put(r.url, r.opts, r.config)
  },
  update: (parameters = {}) => {
    const config = parameters.$config
    let path = 'api/mechanism/{id}'
    let body
    let queryParameters = {}
    let form = {}
    logFormData(parameters.form)
    if (parameters['body'] !== undefined) {
      body = parameters['body']
    }
    if (parameters['id'] === undefined) {
      return Promise.reject(new Error('Missing required parameter: id'))
    }
    path = path.replace('{id}', `${parameters['id']}`)
    if (parameters.$queryParameters) {
      Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
        queryParameters[parameterName] = parameters.$queryParameters[parameterName]
      });
    }
    const r = generateRequest('put', path, body, queryParameters, form, config)
    return $axios.$put(r.url, r.opts, r.config)
  },
  updateTarget: (parameters = {}) => {
    const config = parameters.$config
    let path = 'api/mechanism/{id}/target/{targetId}/updateTarget'
    let body
    let queryParameters = {}
    let form = {}
    logFormData(parameters.form)
    if (parameters['body'] !== undefined) {
      body = parameters['body']
    }
    if (parameters['id'] === undefined) {
      return Promise.reject(new Error('Missing required parameter: id'))
    }
    path = path.replace('{id}', `${parameters['id']}`)
    if (parameters['targetId'] === undefined) {
      return Promise.reject(new Error('Missing required parameter: targetId'))
    }
    path = path.replace('{targetId}', `${parameters['targetId']}`)
    if (parameters.$queryParameters) {
      Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
        queryParameters[parameterName] = parameters.$queryParameters[parameterName]
      });
    }
    const r = generateRequest('put', path, body, queryParameters, form, config)
    return $axios.$put(r.url, r.opts, r.config)
  },
})
/* mission */
const mission = $axios => ({
  acceptEdtakerProposal: (parameters = {}) => {
    const config = parameters.$config
    let path = 'api/mission/{missionId}/proposals/{proposalId}/accept'
    let body
    let queryParameters = {}
    let form = {}
    logFormData(parameters.form)
    if (parameters['body'] !== undefined) {
      body = parameters['body']
    }
    if (parameters['missionId'] === undefined) {
      return Promise.reject(new Error('Missing required parameter: missionId'))
    }
    path = path.replace('{missionId}', `${parameters['missionId']}`)
    if (parameters['proposalId'] === undefined) {
      return Promise.reject(new Error('Missing required parameter: proposalId'))
    }
    path = path.replace('{proposalId}', `${parameters['proposalId']}`)
    if (parameters.$queryParameters) {
      Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
        queryParameters[parameterName] = parameters.$queryParameters[parameterName]
      });
    }
    const r = generateRequest('put', path, body, queryParameters, form, config)
    return $axios.$put(r.url, r.opts, r.config)
  },
  cancel: (parameters = {}) => {
    const config = parameters.$config
    let path = 'api/mission/{id}/cancel'
    let body
    let queryParameters = {}
    let form = {}
    logFormData(parameters.form)
    if (parameters['id'] === undefined) {
      return Promise.reject(new Error('Missing required parameter: id'))
    }
    path = path.replace('{id}', `${parameters['id']}`)
    if (parameters.$queryParameters) {
      Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
        queryParameters[parameterName] = parameters.$queryParameters[parameterName]
      });
    }
    const r = generateRequest('get', path, body, queryParameters, form, config)
    return $axios.$get(r.url, r.config)
  },
  createByWorkspace: (parameters = {}) => {
    const config = parameters.$config
    let path = 'api/mission/{workspace}'
    let body
    let queryParameters = {}
    let form = {}
    logFormData(parameters.form)
    if (parameters['body'] !== undefined) {
      body = parameters['body']
    }
    if (parameters['workspace'] === undefined) {
      return Promise.reject(new Error('Missing required parameter: workspace'))
    }
    path = path.replace('{workspace}', `${parameters['workspace']}`)
    if (parameters.$queryParameters) {
      Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
        queryParameters[parameterName] = parameters.$queryParameters[parameterName]
      });
    }
    const r = generateRequest('post', path, body, queryParameters, form, config)
    return $axios.$post(r.url, r.opts, r.config)
  },
  createForNewUser: (parameters = {}) => {
    const config = parameters.$config
    let path = 'api/mission/forNewUser'
    let body
    let queryParameters = {}
    let form = {}
    logFormData(parameters.form)
    if (parameters['body'] !== undefined) {
      body = parameters['body']
    }
    if (parameters.$queryParameters) {
      Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
        queryParameters[parameterName] = parameters.$queryParameters[parameterName]
      });
    }
    const r = generateRequest('post', path, body, queryParameters, form, config)
    return $axios.$post(r.url, r.opts, r.config)
  },
  declineEdtakerProposal: (parameters = {}) => {
    const config = parameters.$config
    let path = 'api/mission/{missionId}/proposals/{proposalId}/decline'
    let body
    let queryParameters = {}
    let form = {}
    logFormData(parameters.form)
    if (parameters['body'] !== undefined) {
      body = parameters['body']
    }
    if (parameters['missionId'] === undefined) {
      return Promise.reject(new Error('Missing required parameter: missionId'))
    }
    path = path.replace('{missionId}', `${parameters['missionId']}`)
    if (parameters['proposalId'] === undefined) {
      return Promise.reject(new Error('Missing required parameter: proposalId'))
    }
    path = path.replace('{proposalId}', `${parameters['proposalId']}`)
    if (parameters.$queryParameters) {
      Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
        queryParameters[parameterName] = parameters.$queryParameters[parameterName]
      });
    }
    const r = generateRequest('put', path, body, queryParameters, form, config)
    return $axios.$put(r.url, r.opts, r.config)
  },
  delete: (parameters = {}) => {
    const config = parameters.$config
    let path = 'api/mission/{id}'
    let body
    let queryParameters = {}
    let form = {}
    logFormData(parameters.form)
    if (parameters['id'] === undefined) {
      return Promise.reject(new Error('Missing required parameter: id'))
    }
    path = path.replace('{id}', `${parameters['id']}`)
    if (parameters.$queryParameters) {
      Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
        queryParameters[parameterName] = parameters.$queryParameters[parameterName]
      });
    }
    const r = generateRequest('delete', path, body, queryParameters, form, config)
    return $axios.$delete(r.url, r.config)
  },
  deliveryIterate: (parameters = {}) => {
    const config = parameters.$config
    let path = 'api/mission/{missionId}/delivery/{deliveryId}/iterate'
    let body
    let queryParameters = {}
    if (parameters.form === undefined) {
      return Promise.reject(new Error('Missing required  parameter: form'))
    }
    let form = parameters.form
    if (parameters['body'] !== undefined) {
      body = parameters['body']
    }
    if (parameters['missionId'] === undefined) {
      return Promise.reject(new Error('Missing required parameter: missionId'))
    }
    path = path.replace('{missionId}', `${parameters['missionId']}`)
    if (parameters['deliveryId'] === undefined) {
      return Promise.reject(new Error('Missing required parameter: deliveryId'))
    }
    path = path.replace('{deliveryId}', `${parameters['deliveryId']}`)
    if (parameters.$queryParameters) {
      Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
        queryParameters[parameterName] = parameters.$queryParameters[parameterName]
      });
    }
    const r = generateRequest('put', path, body, queryParameters, form, config)
    return $axios.$put(r.url, r.opts, r.config)
  },
  deliveryReview: (parameters = {}) => {
    const config = parameters.$config
    let path = 'api/mission/{missionId}/delivery/{deliveryId}/review'
    let body
    let queryParameters = {}
    if (parameters.form === undefined) {
      return Promise.reject(new Error('Missing required  parameter: form'))
    }
    let form = parameters.form
    if (parameters['body'] !== undefined) {
      body = parameters['body']
    }
    if (parameters['missionId'] === undefined) {
      return Promise.reject(new Error('Missing required parameter: missionId'))
    }
    path = path.replace('{missionId}', `${parameters['missionId']}`)
    if (parameters['deliveryId'] === undefined) {
      return Promise.reject(new Error('Missing required parameter: deliveryId'))
    }
    path = path.replace('{deliveryId}', `${parameters['deliveryId']}`)
    if (parameters.$queryParameters) {
      Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
        queryParameters[parameterName] = parameters.$queryParameters[parameterName]
      });
    }
    const r = generateRequest('put', path, body, queryParameters, form, config)
    return $axios.$put(r.url, r.opts, r.config)
  },
  deliveryValidate: (parameters = {}) => {
    const config = parameters.$config
    let path = 'api/mission/{missionId}/delivery/{deliveryId}/validate'
    let body
    let queryParameters = {}
    let form = {}
    logFormData(parameters.form)
    if (parameters['body'] !== undefined) {
      body = parameters['body']
    }
    if (parameters['missionId'] === undefined) {
      return Promise.reject(new Error('Missing required parameter: missionId'))
    }
    path = path.replace('{missionId}', `${parameters['missionId']}`)
    if (parameters['deliveryId'] === undefined) {
      return Promise.reject(new Error('Missing required parameter: deliveryId'))
    }
    path = path.replace('{deliveryId}', `${parameters['deliveryId']}`)
    if (parameters.$queryParameters) {
      Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
        queryParameters[parameterName] = parameters.$queryParameters[parameterName]
      });
    }
    const r = generateRequest('put', path, body, queryParameters, form, config)
    return $axios.$put(r.url, r.opts, r.config)
  },
  get: (parameters = {}) => {
    const config = parameters.$config
    let path = 'api/mission'
    let body
    let queryParameters = {}
    let form = {}
    logFormData(parameters.form)
    if (parameters.$queryParameters) {
      Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
        queryParameters[parameterName] = parameters.$queryParameters[parameterName]
      });
    }
    const r = generateRequest('get', path, body, queryParameters, form, config)
    return $axios.$get(r.url, r.config)
  },
  getDocument: (parameters = {}) => {
    const config = parameters.$config
    let path = 'api/mission/{missionId}/document/{documentId}'
    let body
    let queryParameters = {}
    let form = {}
    logFormData(parameters.form)
    if (parameters['missionId'] === undefined) {
      return Promise.reject(new Error('Missing required parameter: missionId'))
    }
    path = path.replace('{missionId}', `${parameters['missionId']}`)
    if (parameters['documentId'] === undefined) {
      return Promise.reject(new Error('Missing required parameter: documentId'))
    }
    path = path.replace('{documentId}', `${parameters['documentId']}`)
    if (parameters.$queryParameters) {
      Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
        queryParameters[parameterName] = parameters.$queryParameters[parameterName]
      });
    }
    const r = generateRequest('get', path, body, queryParameters, form, config)
    return $axios.$get(r.url, r.config)
  },
  getOne: (parameters = {}) => {
    const config = parameters.$config
    let path = 'api/mission/{id}'
    let body
    let queryParameters = {}
    let form = {}
    logFormData(parameters.form)
    if (parameters['id'] === undefined) {
      return Promise.reject(new Error('Missing required parameter: id'))
    }
    path = path.replace('{id}', `${parameters['id']}`)
    if (parameters.$queryParameters) {
      Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
        queryParameters[parameterName] = parameters.$queryParameters[parameterName]
      });
    }
    const r = generateRequest('get', path, body, queryParameters, form, config)
    return $axios.$get(r.url, r.config)
  },
  submit: (parameters = {}) => {
    const config = parameters.$config
    let path = 'api/mission/{id}/submit'
    let body
    let queryParameters = {}
    let form = {}
    logFormData(parameters.form)
    if (parameters['id'] === undefined) {
      return Promise.reject(new Error('Missing required parameter: id'))
    }
    path = path.replace('{id}', `${parameters['id']}`)
    if (parameters.$queryParameters) {
      Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
        queryParameters[parameterName] = parameters.$queryParameters[parameterName]
      });
    }
    const r = generateRequest('get', path, body, queryParameters, form, config)
    return $axios.$get(r.url, r.config)
  },
  update: (parameters = {}) => {
    const config = parameters.$config
    let path = 'api/mission/{id}'
    let body
    let queryParameters = {}
    let form = {}
    logFormData(parameters.form)
    if (parameters['body'] !== undefined) {
      body = parameters['body']
    }
    if (parameters['id'] === undefined) {
      return Promise.reject(new Error('Missing required parameter: id'))
    }
    path = path.replace('{id}', `${parameters['id']}`)
    if (parameters.$queryParameters) {
      Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
        queryParameters[parameterName] = parameters.$queryParameters[parameterName]
      });
    }
    const r = generateRequest('put', path, body, queryParameters, form, config)
    return $axios.$put(r.url, r.opts, r.config)
  },
})
/* modalityRecommandations */
const modalityRecommandations = $axios => ({
  getModalityRecommandations: (parameters = {}) => {
    const config = parameters.$config
    let path = 'api/modality/getAdvices'
    let body
    let queryParameters = {}
    let form = {}
    logFormData(parameters.form)
    if (parameters['body'] !== undefined) {
      body = parameters['body']
    }
    if (parameters.$queryParameters) {
      Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
        queryParameters[parameterName] = parameters.$queryParameters[parameterName]
      });
    }
    const r = generateRequest('post', path, body, queryParameters, form, config)
    return $axios.$post(r.url, r.opts, r.config)
  },
})
/* module */
const module = $axios => ({
  addSequence: (parameters = {}) => {
    const config = parameters.$config
    let path = 'api/module/{id}/addSequence'
    let body
    let queryParameters = {}
    let form = {}
    logFormData(parameters.form)
    if (parameters['body'] !== undefined) {
      body = parameters['body']
    }
    if (parameters['id'] === undefined) {
      return Promise.reject(new Error('Missing required parameter: id'))
    }
    path = path.replace('{id}', `${parameters['id']}`)
    if (parameters.$queryParameters) {
      Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
        queryParameters[parameterName] = parameters.$queryParameters[parameterName]
      });
    }
    const r = generateRequest('post', path, body, queryParameters, form, config)
    return $axios.$post(r.url, r.opts, r.config)
  },
  archive: (parameters = {}) => {
    const config = parameters.$config
    let path = 'api/module/{id}/archive'
    let body
    let queryParameters = {}
    let form = {}
    logFormData(parameters.form)
    if (parameters['body'] !== undefined) {
      body = parameters['body']
    }
    if (parameters['id'] === undefined) {
      return Promise.reject(new Error('Missing required parameter: id'))
    }
    path = path.replace('{id}', `${parameters['id']}`)
    if (parameters.$queryParameters) {
      Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
        queryParameters[parameterName] = parameters.$queryParameters[parameterName]
      });
    }
    const r = generateRequest('put', path, body, queryParameters, form, config)
    return $axios.$put(r.url, r.opts, r.config)
  },
  clearAuthoringToolData: (parameters = {}) => {
    const config = parameters.$config
    let path = 'api/module/{id}/clear-authoring-tool'
    let body
    let queryParameters = {}
    let form = {}
    logFormData(parameters.form)
    if (parameters['body'] !== undefined) {
      body = parameters['body']
    }
    if (parameters['id'] === undefined) {
      return Promise.reject(new Error('Missing required parameter: id'))
    }
    path = path.replace('{id}', `${parameters['id']}`)
    if (parameters.$queryParameters) {
      Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
        queryParameters[parameterName] = parameters.$queryParameters[parameterName]
      });
    }
    const r = generateRequest('put', path, body, queryParameters, form, config)
    return $axios.$put(r.url, r.opts, r.config)
  },
  clearLmsData: (parameters = {}) => {
    const config = parameters.$config
    let path = 'api/module/{id}/clearLms'
    let body
    let queryParameters = {}
    let form = {}
    logFormData(parameters.form)
    if (parameters['body'] !== undefined) {
      body = parameters['body']
    }
    if (parameters['id'] === undefined) {
      return Promise.reject(new Error('Missing required parameter: id'))
    }
    path = path.replace('{id}', `${parameters['id']}`)
    if (parameters.$queryParameters) {
      Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
        queryParameters[parameterName] = parameters.$queryParameters[parameterName]
      });
    }
    const r = generateRequest('put', path, body, queryParameters, form, config)
    return $axios.$put(r.url, r.opts, r.config)
  },
  create: (parameters = {}) => {
    const config = parameters.$config
    let path = 'api/module'
    let body
    let queryParameters = {}
    let form = {}
    logFormData(parameters.form)
    if (parameters['body'] !== undefined) {
      body = parameters['body']
    }
    if (parameters.$queryParameters) {
      Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
        queryParameters[parameterName] = parameters.$queryParameters[parameterName]
      });
    }
    const r = generateRequest('post', path, body, queryParameters, form, config)
    return $axios.$post(r.url, r.opts, r.config)
  },
  delete: (parameters = {}) => {
    const config = parameters.$config
    let path = 'api/module/{id}'
    let body
    let queryParameters = {}
    let form = {}
    logFormData(parameters.form)
    if (parameters['id'] === undefined) {
      return Promise.reject(new Error('Missing required parameter: id'))
    }
    path = path.replace('{id}', `${parameters['id']}`)
    if (parameters.$queryParameters) {
      Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
        queryParameters[parameterName] = parameters.$queryParameters[parameterName]
      });
    }
    const r = generateRequest('delete', path, body, queryParameters, form, config)
    return $axios.$delete(r.url, r.config)
  },
  generateProductionFile: (parameters = {}) => {
    const config = parameters.$config
    let path = 'api/module/{id}/generateProductionFile'
    let body
    let queryParameters = {}
    let form = {}
    logFormData(parameters.form)
    if (parameters['id'] === undefined) {
      return Promise.reject(new Error('Missing required parameter: id'))
    }
    path = path.replace('{id}', `${parameters['id']}`)
    if (parameters.$queryParameters) {
      Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
        queryParameters[parameterName] = parameters.$queryParameters[parameterName]
      });
    }
    const r = generateRequest('get', path, body, queryParameters, form, config)
    return $axios.$get(r.url, r.config)
  },
  get: (parameters = {}) => {
    const config = parameters.$config
    let path = 'api/module'
    let body
    let queryParameters = {}
    let form = {}
    logFormData(parameters.form)
    if (parameters.$queryParameters) {
      Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
        queryParameters[parameterName] = parameters.$queryParameters[parameterName]
      });
    }
    const r = generateRequest('get', path, body, queryParameters, form, config)
    return $axios.$get(r.url, r.config)
  },
  getChildren: (parameters = {}) => {
    const config = parameters.$config
    let path = 'api/module/{id}/getChildren'
    let body
    let queryParameters = {}
    let form = {}
    logFormData(parameters.form)
    if (parameters['id'] === undefined) {
      return Promise.reject(new Error('Missing required parameter: id'))
    }
    path = path.replace('{id}', `${parameters['id']}`)
    if (parameters.$queryParameters) {
      Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
        queryParameters[parameterName] = parameters.$queryParameters[parameterName]
      });
    }
    const r = generateRequest('get', path, body, queryParameters, form, config)
    return $axios.$get(r.url, r.config)
  },
  getOne: (parameters = {}) => {
    const config = parameters.$config
    let path = 'api/module/{id}'
    let body
    let queryParameters = {}
    let form = {}
    logFormData(parameters.form)
    if (parameters['id'] === undefined) {
      return Promise.reject(new Error('Missing required parameter: id'))
    }
    path = path.replace('{id}', `${parameters['id']}`)
    if (parameters.$queryParameters) {
      Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
        queryParameters[parameterName] = parameters.$queryParameters[parameterName]
      });
    }
    const r = generateRequest('get', path, body, queryParameters, form, config)
    return $axios.$get(r.url, r.config)
  },
  getRecommendations: (parameters = {}) => {
    const config = parameters.$config
    let path = 'api/module/{id}/recommendations/{recommendationType}'
    let body
    let queryParameters = {}
    let form = {}
    logFormData(parameters.form)
    if (parameters['id'] === undefined) {
      return Promise.reject(new Error('Missing required parameter: id'))
    }
    path = path.replace('{id}', `${parameters['id']}`)
    if (parameters['recommendationType'] === undefined) {
      return Promise.reject(new Error('Missing required parameter: recommendationType'))
    }
    path = path.replace('{recommendationType}', `${parameters['recommendationType']}`)
    if (parameters.$queryParameters) {
      Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
        queryParameters[parameterName] = parameters.$queryParameters[parameterName]
      });
    }
    const r = generateRequest('get', path, body, queryParameters, form, config)
    return $axios.$get(r.url, r.config)
  },
  lmsDataStatus: (parameters = {}) => {
    const config = parameters.$config
    let path = 'api/module/{id}/lmsDataStatus'
    let body
    let queryParameters = {}
    let form = {}
    logFormData(parameters.form)
    if (parameters['id'] === undefined) {
      return Promise.reject(new Error('Missing required parameter: id'))
    }
    path = path.replace('{id}', `${parameters['id']}`)
    if (parameters.$queryParameters) {
      Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
        queryParameters[parameterName] = parameters.$queryParameters[parameterName]
      });
    }
    const r = generateRequest('get', path, body, queryParameters, form, config)
    return $axios.$get(r.url, r.config)
  },
  removeSequence: (parameters = {}) => {
    const config = parameters.$config
    let path = 'api/module/{id}/removeSequence'
    let body
    let queryParameters = {}
    let form = {}
    logFormData(parameters.form)
    if (parameters['body'] !== undefined) {
      body = parameters['body']
    }
    if (parameters['id'] === undefined) {
      return Promise.reject(new Error('Missing required parameter: id'))
    }
    path = path.replace('{id}', `${parameters['id']}`)
    if (parameters.$queryParameters) {
      Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
        queryParameters[parameterName] = parameters.$queryParameters[parameterName]
      });
    }
    const r = generateRequest('post', path, body, queryParameters, form, config)
    return $axios.$post(r.url, r.opts, r.config)
  },
  setStatus: (parameters = {}) => {
    const config = parameters.$config
    let path = 'api/module/{id}/status'
    let body
    let queryParameters = {}
    let form = {}
    logFormData(parameters.form)
    if (parameters['body'] !== undefined) {
      body = parameters['body']
    }
    if (parameters['id'] === undefined) {
      return Promise.reject(new Error('Missing required parameter: id'))
    }
    path = path.replace('{id}', `${parameters['id']}`)
    if (parameters.$queryParameters) {
      Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
        queryParameters[parameterName] = parameters.$queryParameters[parameterName]
      });
    }
    const r = generateRequest('put', path, body, queryParameters, form, config)
    return $axios.$put(r.url, r.opts, r.config)
  },
  update: (parameters = {}) => {
    const config = parameters.$config
    let path = 'api/module/{id}'
    let body
    let queryParameters = {}
    let form = {}
    logFormData(parameters.form)
    if (parameters['body'] !== undefined) {
      body = parameters['body']
    }
    if (parameters['id'] === undefined) {
      return Promise.reject(new Error('Missing required parameter: id'))
    }
    path = path.replace('{id}', `${parameters['id']}`)
    if (parameters.$queryParameters) {
      Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
        queryParameters[parameterName] = parameters.$queryParameters[parameterName]
      });
    }
    const r = generateRequest('put', path, body, queryParameters, form, config)
    return $axios.$put(r.url, r.opts, r.config)
  },
  updateAuthoringToolData: (parameters = {}) => {
    const config = parameters.$config
    let path = 'api/module/{id}/authoring-tool'
    let body
    let queryParameters = {}
    let form = {}
    logFormData(parameters.form)
    if (parameters['body'] !== undefined) {
      body = parameters['body']
    }
    if (parameters['id'] === undefined) {
      return Promise.reject(new Error('Missing required parameter: id'))
    }
    path = path.replace('{id}', `${parameters['id']}`)
    if (parameters.$queryParameters) {
      Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
        queryParameters[parameterName] = parameters.$queryParameters[parameterName]
      });
    }
    const r = generateRequest('put', path, body, queryParameters, form, config)
    return $axios.$put(r.url, r.opts, r.config)
  },
  updateLmsData: (parameters = {}) => {
    const config = parameters.$config
    let path = 'api/module/{id}/lms'
    let body
    let queryParameters = {}
    let form = {}
    logFormData(parameters.form)
    if (parameters['body'] !== undefined) {
      body = parameters['body']
    }
    if (parameters['id'] === undefined) {
      return Promise.reject(new Error('Missing required parameter: id'))
    }
    path = path.replace('{id}', `${parameters['id']}`)
    if (parameters.$queryParameters) {
      Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
        queryParameters[parameterName] = parameters.$queryParameters[parameterName]
      });
    }
    const r = generateRequest('put', path, body, queryParameters, form, config)
    return $axios.$put(r.url, r.opts, r.config)
  },
})
/* objectRepository */
const objectRepository = $axios => ({
  getOne: (parameters = {}) => {
    const config = parameters.$config
    let path = 'api/objectRepository/getOne'
    let body
    let queryParameters = {}
    let form = {}
    logFormData(parameters.form)
    if (parameters.$queryParameters) {
      Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
        queryParameters[parameterName] = parameters.$queryParameters[parameterName]
      });
    }
    const r = generateRequest('get', path, body, queryParameters, form, config)
    return $axios.$get(r.url, r.config)
  },
})
/* phase */
const phase = $axios => ({
  create: (parameters = {}) => {
    const config = parameters.$config
    let path = 'api/phase/{id}'
    let body
    let queryParameters = {}
    let form = {}
    logFormData(parameters.form)
    if (parameters['body'] !== undefined) {
      body = parameters['body']
    }
    if (parameters['id'] === undefined) {
      return Promise.reject(new Error('Missing required parameter: id'))
    }
    path = path.replace('{id}', `${parameters['id']}`)
    if (parameters.$queryParameters) {
      Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
        queryParameters[parameterName] = parameters.$queryParameters[parameterName]
      });
    }
    const r = generateRequest('post', path, body, queryParameters, form, config)
    return $axios.$post(r.url, r.opts, r.config)
  },
  delete: (parameters = {}) => {
    const config = parameters.$config
    let path = 'api/phase/{id}'
    let body
    let queryParameters = {}
    let form = {}
    logFormData(parameters.form)
    if (parameters['id'] === undefined) {
      return Promise.reject(new Error('Missing required parameter: id'))
    }
    path = path.replace('{id}', `${parameters['id']}`)
    if (parameters.$queryParameters) {
      Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
        queryParameters[parameterName] = parameters.$queryParameters[parameterName]
      });
    }
    const r = generateRequest('delete', path, body, queryParameters, form, config)
    return $axios.$delete(r.url, r.config)
  },
  get: (parameters = {}) => {
    const config = parameters.$config
    let path = 'api/phase'
    let body
    let queryParameters = {}
    let form = {}
    logFormData(parameters.form)
    if (parameters.$queryParameters) {
      Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
        queryParameters[parameterName] = parameters.$queryParameters[parameterName]
      });
    }
    const r = generateRequest('get', path, body, queryParameters, form, config)
    return $axios.$get(r.url, r.config)
  },
  getChildren: (parameters = {}) => {
    const config = parameters.$config
    let path = 'api/phase/{id}/getChildren'
    let body
    let queryParameters = {}
    let form = {}
    logFormData(parameters.form)
    if (parameters['id'] === undefined) {
      return Promise.reject(new Error('Missing required parameter: id'))
    }
    path = path.replace('{id}', `${parameters['id']}`)
    if (parameters.$queryParameters) {
      Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
        queryParameters[parameterName] = parameters.$queryParameters[parameterName]
      });
    }
    const r = generateRequest('get', path, body, queryParameters, form, config)
    return $axios.$get(r.url, r.config)
  },
  getOne: (parameters = {}) => {
    const config = parameters.$config
    let path = 'api/phase/{id}'
    let body
    let queryParameters = {}
    let form = {}
    logFormData(parameters.form)
    if (parameters['id'] === undefined) {
      return Promise.reject(new Error('Missing required parameter: id'))
    }
    path = path.replace('{id}', `${parameters['id']}`)
    if (parameters.$queryParameters) {
      Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
        queryParameters[parameterName] = parameters.$queryParameters[parameterName]
      });
    }
    const r = generateRequest('get', path, body, queryParameters, form, config)
    return $axios.$get(r.url, r.config)
  },
  update: (parameters = {}) => {
    const config = parameters.$config
    let path = 'api/phase/{id}'
    let body
    let queryParameters = {}
    let form = {}
    logFormData(parameters.form)
    if (parameters['body'] !== undefined) {
      body = parameters['body']
    }
    if (parameters['id'] === undefined) {
      return Promise.reject(new Error('Missing required parameter: id'))
    }
    path = path.replace('{id}', `${parameters['id']}`)
    if (parameters.$queryParameters) {
      Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
        queryParameters[parameterName] = parameters.$queryParameters[parameterName]
      });
    }
    const r = generateRequest('put', path, body, queryParameters, form, config)
    return $axios.$put(r.url, r.opts, r.config)
  },
})
/* pinned */
const pinned = $axios => ({
  create: (parameters = {}) => {
    const config = parameters.$config
    let path = 'api/pinned'
    let body
    let queryParameters = {}
    let form = {}
    logFormData(parameters.form)
    if (parameters['body'] !== undefined) {
      body = parameters['body']
    }
    if (parameters.$queryParameters) {
      Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
        queryParameters[parameterName] = parameters.$queryParameters[parameterName]
      });
    }
    const r = generateRequest('post', path, body, queryParameters, form, config)
    return $axios.$post(r.url, r.opts, r.config)
  },
  delete: (parameters = {}) => {
    const config = parameters.$config
    let path = 'api/pinned/{resourceId}'
    let body
    let queryParameters = {}
    let form = {}
    logFormData(parameters.form)
    if (parameters['resourceId'] === undefined) {
      return Promise.reject(new Error('Missing required parameter: resourceId'))
    }
    path = path.replace('{resourceId}', `${parameters['resourceId']}`)
    if (parameters.$queryParameters) {
      Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
        queryParameters[parameterName] = parameters.$queryParameters[parameterName]
      });
    }
    const r = generateRequest('delete', path, body, queryParameters, form, config)
    return $axios.$delete(r.url, r.config)
  },
  getOne: (parameters = {}) => {
    const config = parameters.$config
    let path = 'api/pinned/{resourceId}'
    let body
    let queryParameters = {}
    let form = {}
    logFormData(parameters.form)
    if (parameters['resourceId'] === undefined) {
      return Promise.reject(new Error('Missing required parameter: resourceId'))
    }
    path = path.replace('{resourceId}', `${parameters['resourceId']}`)
    if (parameters.$queryParameters) {
      Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
        queryParameters[parameterName] = parameters.$queryParameters[parameterName]
      });
    }
    const r = generateRequest('get', path, body, queryParameters, form, config)
    return $axios.$get(r.url, r.config)
  },
})
/* profile */
const profile = $axios => ({
  get: (parameters = {}) => {
    const config = parameters.$config
    let path = 'api/profile'
    let body
    let queryParameters = {}
    let form = {}
    logFormData(parameters.form)
    if (parameters.$queryParameters) {
      Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
        queryParameters[parameterName] = parameters.$queryParameters[parameterName]
      });
    }
    const r = generateRequest('get', path, body, queryParameters, form, config)
    return $axios.$get(r.url, r.config)
  },
  getSdkInfo: (parameters = {}) => {
    const config = parameters.$config
    let path = 'api/profile/get-sdk-info'
    let body
    let queryParameters = {}
    let form = {}
    logFormData(parameters.form)
    if (parameters.$queryParameters) {
      Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
        queryParameters[parameterName] = parameters.$queryParameters[parameterName]
      });
    }
    const r = generateRequest('get', path, body, queryParameters, form, config)
    return $axios.$get(r.url, r.config)
  },
  update: (parameters = {}) => {
    const config = parameters.$config
    let path = 'api/profile'
    let body
    let queryParameters = {}
    let form = {}
    logFormData(parameters.form)
    if (parameters['body'] !== undefined) {
      body = parameters['body']
    }
    if (parameters.$queryParameters) {
      Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
        queryParameters[parameterName] = parameters.$queryParameters[parameterName]
      });
    }
    const r = generateRequest('put', path, body, queryParameters, form, config)
    return $axios.$put(r.url, r.opts, r.config)
  },
})
/* project */
const project = $axios => ({
  archive: (parameters = {}) => {
    const config = parameters.$config
    let path = 'api/project/{id}/archive'
    let body
    let queryParameters = {}
    let form = {}
    logFormData(parameters.form)
    if (parameters['body'] !== undefined) {
      body = parameters['body']
    }
    if (parameters['id'] === undefined) {
      return Promise.reject(new Error('Missing required parameter: id'))
    }
    path = path.replace('{id}', `${parameters['id']}`)
    if (parameters.$queryParameters) {
      Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
        queryParameters[parameterName] = parameters.$queryParameters[parameterName]
      });
    }
    const r = generateRequest('put', path, body, queryParameters, form, config)
    return $axios.$put(r.url, r.opts, r.config)
  },
  createProject: (parameters = {}) => {
    const config = parameters.$config
    let path = 'api/project/create/{templateId?}'
    let body
    let queryParameters = {}
    let form = {}
    logFormData(parameters.form)
    if (parameters['body'] !== undefined) {
      body = parameters['body']
    }
    if (parameters['templateId']) {
      path = path.replace('{templateId?}', `${parameters['templateId']}`)
    } else {
      path = path.replace('{templateId?}', '')
    }
    if (parameters.$queryParameters) {
      Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
        queryParameters[parameterName] = parameters.$queryParameters[parameterName]
      });
    }
    const r = generateRequest('post', path, body, queryParameters, form, config)
    return $axios.$post(r.url, r.opts, r.config)
  },
  delete: (parameters = {}) => {
    const config = parameters.$config
    let path = 'api/project/{id}'
    let body
    let queryParameters = {}
    let form = {}
    logFormData(parameters.form)
    if (parameters['id'] === undefined) {
      return Promise.reject(new Error('Missing required parameter: id'))
    }
    path = path.replace('{id}', `${parameters['id']}`)
    if (parameters.$queryParameters) {
      Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
        queryParameters[parameterName] = parameters.$queryParameters[parameterName]
      });
    }
    const r = generateRequest('delete', path, body, queryParameters, form, config)
    return $axios.$delete(r.url, r.config)
  },
  get: (parameters = {}) => {
    const config = parameters.$config
    let path = 'api/project'
    let body
    let queryParameters = {}
    let form = {}
    logFormData(parameters.form)
    if (parameters.$queryParameters) {
      Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
        queryParameters[parameterName] = parameters.$queryParameters[parameterName]
      });
    }
    const r = generateRequest('get', path, body, queryParameters, form, config)
    return $axios.$get(r.url, r.config)
  },
  getChildren: (parameters = {}) => {
    const config = parameters.$config
    let path = 'api/project/{id}/getChildren'
    let body
    let queryParameters = {}
    let form = {}
    logFormData(parameters.form)
    if (parameters['id'] === undefined) {
      return Promise.reject(new Error('Missing required parameter: id'))
    }
    path = path.replace('{id}', `${parameters['id']}`)
    if (parameters.$queryParameters) {
      Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
        queryParameters[parameterName] = parameters.$queryParameters[parameterName]
      });
    }
    const r = generateRequest('get', path, body, queryParameters, form, config)
    return $axios.$get(r.url, r.config)
  },
  getGlowblMeeting: (parameters = {}) => {
    const config = parameters.$config
    let path = 'api/project/{id}/meeting/glowbl'
    let body
    let queryParameters = {}
    let form = {}
    logFormData(parameters.form)
    if (parameters['id'] === undefined) {
      return Promise.reject(new Error('Missing required parameter: id'))
    }
    path = path.replace('{id}', `${parameters['id']}`)
    if (parameters.$queryParameters) {
      Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
        queryParameters[parameterName] = parameters.$queryParameters[parameterName]
      });
    }
    const r = generateRequest('get', path, body, queryParameters, form, config)
    return $axios.$get(r.url, r.config)
  },
  getOne: (parameters = {}) => {
    const config = parameters.$config
    let path = 'api/project/{id}'
    let body
    let queryParameters = {}
    let form = {}
    logFormData(parameters.form)
    if (parameters['id'] === undefined) {
      return Promise.reject(new Error('Missing required parameter: id'))
    }
    path = path.replace('{id}', `${parameters['id']}`)
    if (parameters.$queryParameters) {
      Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
        queryParameters[parameterName] = parameters.$queryParameters[parameterName]
      });
    }
    const r = generateRequest('get', path, body, queryParameters, form, config)
    return $axios.$get(r.url, r.config)
  },
  getProjectAvgEstimations: (parameters = {}) => {
    const config = parameters.$config
    let path = 'api/project/getProjectAvgEstimations/{id}'
    let body
    let queryParameters = {}
    let form = {}
    logFormData(parameters.form)
    if (parameters['id'] === undefined) {
      return Promise.reject(new Error('Missing required parameter: id'))
    }
    path = path.replace('{id}', `${parameters['id']}`)
    if (parameters.$queryParameters) {
      Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
        queryParameters[parameterName] = parameters.$queryParameters[parameterName]
      });
    }
    const r = generateRequest('get', path, body, queryParameters, form, config)
    return $axios.$get(r.url, r.config)
  },
  getProjectFromCustomer: (parameters = {}) => {
    const config = parameters.$config
    let path = 'api/project/getProjectFromCustomer/{id}'
    let body
    let queryParameters = {}
    let form = {}
    logFormData(parameters.form)
    if (parameters['id'] === undefined) {
      return Promise.reject(new Error('Missing required parameter: id'))
    }
    path = path.replace('{id}', `${parameters['id']}`)
    if (parameters.$queryParameters) {
      Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
        queryParameters[parameterName] = parameters.$queryParameters[parameterName]
      });
    }
    const r = generateRequest('get', path, body, queryParameters, form, config)
    return $axios.$get(r.url, r.config)
  },
  getRecursiveChildren: (parameters = {}) => {
    const config = parameters.$config
    let path = 'api/project/{id}/getRecursiveChildren'
    let body
    let queryParameters = {}
    let form = {}
    logFormData(parameters.form)
    if (parameters['id'] === undefined) {
      return Promise.reject(new Error('Missing required parameter: id'))
    }
    path = path.replace('{id}', `${parameters['id']}`)
    if (parameters.$queryParameters) {
      Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
        queryParameters[parameterName] = parameters.$queryParameters[parameterName]
      });
    }
    const r = generateRequest('get', path, body, queryParameters, form, config)
    return $axios.$get(r.url, r.config)
  },
  reschedule: (parameters = {}) => {
    const config = parameters.$config
    let path = 'api/project/{id}/reschedule'
    let body
    let queryParameters = {}
    let form = {}
    logFormData(parameters.form)
    if (parameters['body'] !== undefined) {
      body = parameters['body']
    }
    if (parameters['id'] === undefined) {
      return Promise.reject(new Error('Missing required parameter: id'))
    }
    path = path.replace('{id}', `${parameters['id']}`)
    if (parameters.$queryParameters) {
      Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
        queryParameters[parameterName] = parameters.$queryParameters[parameterName]
      });
    }
    const r = generateRequest('put', path, body, queryParameters, form, config)
    return $axios.$put(r.url, r.opts, r.config)
  },
  setStatus: (parameters = {}) => {
    const config = parameters.$config
    let path = 'api/project/{id}/status'
    let body
    let queryParameters = {}
    let form = {}
    logFormData(parameters.form)
    if (parameters['body'] !== undefined) {
      body = parameters['body']
    }
    if (parameters['id'] === undefined) {
      return Promise.reject(new Error('Missing required parameter: id'))
    }
    path = path.replace('{id}', `${parameters['id']}`)
    if (parameters.$queryParameters) {
      Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
        queryParameters[parameterName] = parameters.$queryParameters[parameterName]
      });
    }
    const r = generateRequest('put', path, body, queryParameters, form, config)
    return $axios.$put(r.url, r.opts, r.config)
  },
  toTemplate: (parameters = {}) => {
    const config = parameters.$config
    let path = 'api/project/{id}/toTemplate'
    let body
    let queryParameters = {}
    let form = {}
    logFormData(parameters.form)
    if (parameters['body'] !== undefined) {
      body = parameters['body']
    }
    if (parameters['id'] === undefined) {
      return Promise.reject(new Error('Missing required parameter: id'))
    }
    path = path.replace('{id}', `${parameters['id']}`)
    if (parameters.$queryParameters) {
      Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
        queryParameters[parameterName] = parameters.$queryParameters[parameterName]
      });
    }
    const r = generateRequest('post', path, body, queryParameters, form, config)
    return $axios.$post(r.url, r.opts, r.config)
  },
  update: (parameters = {}) => {
    const config = parameters.$config
    let path = 'api/project/{id}'
    let body
    let queryParameters = {}
    let form = {}
    logFormData(parameters.form)
    if (parameters['body'] !== undefined) {
      body = parameters['body']
    }
    if (parameters['id'] === undefined) {
      return Promise.reject(new Error('Missing required parameter: id'))
    }
    path = path.replace('{id}', `${parameters['id']}`)
    if (parameters.$queryParameters) {
      Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
        queryParameters[parameterName] = parameters.$queryParameters[parameterName]
      });
    }
    const r = generateRequest('put', path, body, queryParameters, form, config)
    return $axios.$put(r.url, r.opts, r.config)
  },
})
/* projectItem */
const projectItem = $axios => ({
  addMilestone: (parameters = {}) => {
    const config = parameters.$config
    let path = 'api/projectItem/{id}/milestone'
    let body
    let queryParameters = {}
    let form = {}
    logFormData(parameters.form)
    if (parameters['body'] !== undefined) {
      body = parameters['body']
    }
    if (parameters['id'] === undefined) {
      return Promise.reject(new Error('Missing required parameter: id'))
    }
    path = path.replace('{id}', `${parameters['id']}`)
    if (parameters.$queryParameters) {
      Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
        queryParameters[parameterName] = parameters.$queryParameters[parameterName]
      });
    }
    const r = generateRequest('post', path, body, queryParameters, form, config)
    return $axios.$post(r.url, r.opts, r.config)
  },
  bulkUpdate: (parameters = {}) => {
    const config = parameters.$config
    let path = 'api/projectItem/bulk-update'
    let body
    let queryParameters = {}
    let form = {}
    logFormData(parameters.form)
    if (parameters['body'] !== undefined) {
      body = parameters['body']
    }
    if (parameters.$queryParameters) {
      Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
        queryParameters[parameterName] = parameters.$queryParameters[parameterName]
      });
    }
    const r = generateRequest('put', path, body, queryParameters, form, config)
    return $axios.$put(r.url, r.opts, r.config)
  },
  bulkUpdateOrder: (parameters = {}) => {
    const config = parameters.$config
    let path = 'api/projectItem/bulk-update-order'
    let body
    let queryParameters = {}
    let form = {}
    logFormData(parameters.form)
    if (parameters['body'] !== undefined) {
      body = parameters['body']
    }
    if (parameters.$queryParameters) {
      Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
        queryParameters[parameterName] = parameters.$queryParameters[parameterName]
      });
    }
    const r = generateRequest('put', path, body, queryParameters, form, config)
    return $axios.$put(r.url, r.opts, r.config)
  },
  delete: (parameters = {}) => {
    const config = parameters.$config
    let path = 'api/projectItem/{id}'
    let body
    let queryParameters = {}
    let form = {}
    logFormData(parameters.form)
    if (parameters['id'] === undefined) {
      return Promise.reject(new Error('Missing required parameter: id'))
    }
    path = path.replace('{id}', `${parameters['id']}`)
    if (parameters.$queryParameters) {
      Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
        queryParameters[parameterName] = parameters.$queryParameters[parameterName]
      });
    }
    const r = generateRequest('delete', path, body, queryParameters, form, config)
    return $axios.$delete(r.url, r.config)
  },
  deleteMilestone: (parameters = {}) => {
    const config = parameters.$config
    let path = 'api/projectItem/{id}/milestone/{idMilestone}'
    let body
    let queryParameters = {}
    let form = {}
    logFormData(parameters.form)
    if (parameters['id'] === undefined) {
      return Promise.reject(new Error('Missing required parameter: id'))
    }
    path = path.replace('{id}', `${parameters['id']}`)
    if (parameters['idMilestone'] === undefined) {
      return Promise.reject(new Error('Missing required parameter: idMilestone'))
    }
    path = path.replace('{idMilestone}', `${parameters['idMilestone']}`)
    if (parameters.$queryParameters) {
      Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
        queryParameters[parameterName] = parameters.$queryParameters[parameterName]
      });
    }
    const r = generateRequest('delete', path, body, queryParameters, form, config)
    return $axios.$delete(r.url, r.config)
  },
  getAllByTeam: (parameters = {}) => {
    const config = parameters.$config
    let path = 'api/projectItem/getAllByTeam/{id}'
    let body
    let queryParameters = {}
    let form = {}
    logFormData(parameters.form)
    if (parameters['id'] === undefined) {
      return Promise.reject(new Error('Missing required parameter: id'))
    }
    path = path.replace('{id}', `${parameters['id']}`)
    if (parameters.$queryParameters) {
      Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
        queryParameters[parameterName] = parameters.$queryParameters[parameterName]
      });
    }
    const r = generateRequest('get', path, body, queryParameters, form, config)
    return $axios.$get(r.url, r.config)
  },
  getNoiselessPi: (parameters = {}) => {
    const config = parameters.$config
    let path = 'api/projectItem/getNoiselessPi/{id}'
    let body
    let queryParameters = {}
    let form = {}
    logFormData(parameters.form)
    if (parameters['id'] === undefined) {
      return Promise.reject(new Error('Missing required parameter: id'))
    }
    path = path.replace('{id}', `${parameters['id']}`)
    if (parameters.$queryParameters) {
      Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
        queryParameters[parameterName] = parameters.$queryParameters[parameterName]
      });
    }
    const r = generateRequest('get', path, body, queryParameters, form, config)
    return $axios.$get(r.url, r.config)
  },
  getState: (parameters = {}) => {
    const config = parameters.$config
    let path = 'api/projectItem/getState'
    let body
    let queryParameters = {}
    let form = {}
    logFormData(parameters.form)
    if (parameters.$queryParameters) {
      Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
        queryParameters[parameterName] = parameters.$queryParameters[parameterName]
      });
    }
    const r = generateRequest('get', path, body, queryParameters, form, config)
    return $axios.$get(r.url, r.config)
  },
  updateMembersPermission: (parameters = {}) => {
    const config = parameters.$config
    let path = 'api/projectItem/updateMembersPermission'
    let body
    let queryParameters = {}
    let form = {}
    logFormData(parameters.form)
    if (parameters['body'] !== undefined) {
      body = parameters['body']
    }
    if (parameters.$queryParameters) {
      Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
        queryParameters[parameterName] = parameters.$queryParameters[parameterName]
      });
    }
    const r = generateRequest('put', path, body, queryParameters, form, config)
    return $axios.$put(r.url, r.opts, r.config)
  },
  updateMilestone: (parameters = {}) => {
    const config = parameters.$config
    let path = 'api/projectItem/{id}/milestone/{idMilestone}'
    let body
    let queryParameters = {}
    let form = {}
    logFormData(parameters.form)
    if (parameters['body'] !== undefined) {
      body = parameters['body']
    }
    if (parameters['id'] === undefined) {
      return Promise.reject(new Error('Missing required parameter: id'))
    }
    path = path.replace('{id}', `${parameters['id']}`)
    if (parameters['idMilestone'] === undefined) {
      return Promise.reject(new Error('Missing required parameter: idMilestone'))
    }
    path = path.replace('{idMilestone}', `${parameters['idMilestone']}`)
    if (parameters.$queryParameters) {
      Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
        queryParameters[parameterName] = parameters.$queryParameters[parameterName]
      });
    }
    const r = generateRequest('put', path, body, queryParameters, form, config)
    return $axios.$put(r.url, r.opts, r.config)
  },
})
/* proposal */
const proposal = $axios => ({
  accept: (parameters = {}) => {
    const config = parameters.$config
    let path = 'api/proposal/{proposalId}/accept'
    let body
    let queryParameters = {}
    let form = {}
    logFormData(parameters.form)
    if (parameters['body'] !== undefined) {
      body = parameters['body']
    }
    if (parameters['proposalId'] === undefined) {
      return Promise.reject(new Error('Missing required parameter: proposalId'))
    }
    path = path.replace('{proposalId}', `${parameters['proposalId']}`)
    if (parameters.$queryParameters) {
      Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
        queryParameters[parameterName] = parameters.$queryParameters[parameterName]
      });
    }
    const r = generateRequest('put', path, body, queryParameters, form, config)
    return $axios.$put(r.url, r.opts, r.config)
  },
  decline: (parameters = {}) => {
    const config = parameters.$config
    let path = 'api/proposal/{proposalId}/decline'
    let body
    let queryParameters = {}
    let form = {}
    logFormData(parameters.form)
    if (parameters['body'] !== undefined) {
      body = parameters['body']
    }
    if (parameters['proposalId'] === undefined) {
      return Promise.reject(new Error('Missing required parameter: proposalId'))
    }
    path = path.replace('{proposalId}', `${parameters['proposalId']}`)
    if (parameters.$queryParameters) {
      Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
        queryParameters[parameterName] = parameters.$queryParameters[parameterName]
      });
    }
    const r = generateRequest('put', path, body, queryParameters, form, config)
    return $axios.$put(r.url, r.opts, r.config)
  },
  get: (parameters = {}) => {
    const config = parameters.$config
    let path = 'api/proposal'
    let body
    let queryParameters = {}
    let form = {}
    logFormData(parameters.form)
    if (parameters.$queryParameters) {
      Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
        queryParameters[parameterName] = parameters.$queryParameters[parameterName]
      });
    }
    const r = generateRequest('get', path, body, queryParameters, form, config)
    return $axios.$get(r.url, r.config)
  },
  getDocument: (parameters = {}) => {
    const config = parameters.$config
    let path = 'api/proposal/{proposalId}/document/{documentId}'
    let body
    let queryParameters = {}
    let form = {}
    logFormData(parameters.form)
    if (parameters['proposalId'] === undefined) {
      return Promise.reject(new Error('Missing required parameter: proposalId'))
    }
    path = path.replace('{proposalId}', `${parameters['proposalId']}`)
    if (parameters['documentId'] === undefined) {
      return Promise.reject(new Error('Missing required parameter: documentId'))
    }
    path = path.replace('{documentId}', `${parameters['documentId']}`)
    if (parameters.$queryParameters) {
      Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
        queryParameters[parameterName] = parameters.$queryParameters[parameterName]
      });
    }
    const r = generateRequest('get', path, body, queryParameters, form, config)
    return $axios.$get(r.url, r.config)
  },
  getOne: (parameters = {}) => {
    const config = parameters.$config
    let path = 'api/proposal/{id}'
    let body
    let queryParameters = {}
    let form = {}
    logFormData(parameters.form)
    if (parameters['id'] === undefined) {
      return Promise.reject(new Error('Missing required parameter: id'))
    }
    path = path.replace('{id}', `${parameters['id']}`)
    if (parameters.$queryParameters) {
      Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
        queryParameters[parameterName] = parameters.$queryParameters[parameterName]
      });
    }
    const r = generateRequest('get', path, body, queryParameters, form, config)
    return $axios.$get(r.url, r.config)
  },
  update: (parameters = {}) => {
    const config = parameters.$config
    let path = 'api/proposal/{id}'
    let body
    let queryParameters = {}
    let form = {}
    logFormData(parameters.form)
    if (parameters['body'] !== undefined) {
      body = parameters['body']
    }
    if (parameters['id'] === undefined) {
      return Promise.reject(new Error('Missing required parameter: id'))
    }
    path = path.replace('{id}', `${parameters['id']}`)
    if (parameters.$queryParameters) {
      Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
        queryParameters[parameterName] = parameters.$queryParameters[parameterName]
      });
    }
    const r = generateRequest('put', path, body, queryParameters, form, config)
    return $axios.$put(r.url, r.opts, r.config)
  },
  uploadOrderLineBill: (parameters = {}) => {
    const config = parameters.$config
    let path = 'api/proposal/{proposalId}/order/{orderId}/line/{lineId}/bill'
    let body
    let queryParameters = {}
    if (parameters.form === undefined) {
      return Promise.reject(new Error('Missing required  parameter: form'))
    }
    let form = parameters.form
    if (parameters['body'] !== undefined) {
      body = parameters['body']
    }
    if (parameters['proposalId'] === undefined) {
      return Promise.reject(new Error('Missing required parameter: proposalId'))
    }
    path = path.replace('{proposalId}', `${parameters['proposalId']}`)
    if (parameters['orderId'] === undefined) {
      return Promise.reject(new Error('Missing required parameter: orderId'))
    }
    path = path.replace('{orderId}', `${parameters['orderId']}`)
    if (parameters['lineId'] === undefined) {
      return Promise.reject(new Error('Missing required parameter: lineId'))
    }
    path = path.replace('{lineId}', `${parameters['lineId']}`)
    if (parameters.$queryParameters) {
      Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
        queryParameters[parameterName] = parameters.$queryParameters[parameterName]
      });
    }
    const r = generateRequest('put', path, body, queryParameters, form, config)
    return $axios.$put(r.url, r.opts, r.config)
  },
})
/* publicLink */
const publicLink = $axios => ({
  get: (parameters = {}) => {
    const config = parameters.$config
    let path = 'api/public-link/{key}'
    let body
    let queryParameters = {}
    let form = {}
    logFormData(parameters.form)
    if (parameters['key'] === undefined) {
      return Promise.reject(new Error('Missing required parameter: key'))
    }
    path = path.replace('{key}', `${parameters['key']}`)
    if (parameters.$queryParameters) {
      Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
        queryParameters[parameterName] = parameters.$queryParameters[parameterName]
      });
    }
    const r = generateRequest('get', path, body, queryParameters, form, config)
    return $axios.$get(r.url, r.config)
  },
  getByResource: (parameters = {}) => {
    const config = parameters.$config
    let path = 'api/public-link/{resourceType}/{resourceId}'
    let body
    let queryParameters = {}
    let form = {}
    logFormData(parameters.form)
    if (parameters['resourceType'] === undefined) {
      return Promise.reject(new Error('Missing required parameter: resourceType'))
    }
    path = path.replace('{resourceType}', `${parameters['resourceType']}`)
    if (parameters['resourceId'] === undefined) {
      return Promise.reject(new Error('Missing required parameter: resourceId'))
    }
    path = path.replace('{resourceId}', `${parameters['resourceId']}`)
    if (parameters.$queryParameters) {
      Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
        queryParameters[parameterName] = parameters.$queryParameters[parameterName]
      });
    }
    const r = generateRequest('get', path, body, queryParameters, form, config)
    return $axios.$get(r.url, r.config)
  },
  remove: (parameters = {}) => {
    const config = parameters.$config
    let path = 'api/public-link/{key}'
    let body
    let queryParameters = {}
    let form = {}
    logFormData(parameters.form)
    if (parameters['key'] === undefined) {
      return Promise.reject(new Error('Missing required parameter: key'))
    }
    path = path.replace('{key}', `${parameters['key']}`)
    if (parameters.$queryParameters) {
      Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
        queryParameters[parameterName] = parameters.$queryParameters[parameterName]
      });
    }
    const r = generateRequest('delete', path, body, queryParameters, form, config)
    return $axios.$delete(r.url, r.config)
  },
  upsert: (parameters = {}) => {
    const config = parameters.$config
    let path = 'api/public-link'
    let body
    let queryParameters = {}
    let form = {}
    logFormData(parameters.form)
    if (parameters['body'] !== undefined) {
      body = parameters['body']
    }
    if (parameters.$queryParameters) {
      Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
        queryParameters[parameterName] = parameters.$queryParameters[parameterName]
      });
    }
    const r = generateRequest('post', path, body, queryParameters, form, config)
    return $axios.$post(r.url, r.opts, r.config)
  },
})
/* remoteConfig */
const remoteConfig = $axios => ({
  getConfig: (parameters = {}) => {
    const config = parameters.$config
    let path = 'api/remoteConfig'
    let body
    let queryParameters = {}
    let form = {}
    logFormData(parameters.form)
    if (parameters.$queryParameters) {
      Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
        queryParameters[parameterName] = parameters.$queryParameters[parameterName]
      });
    }
    const r = generateRequest('get', path, body, queryParameters, form, config)
    return $axios.$get(r.url, r.config)
  },
})
/* sequence */
const sequence = $axios => ({
  addActivity: (parameters = {}) => {
    const config = parameters.$config
    let path = 'api/sequence/{id}/addActivity'
    let body
    let queryParameters = {}
    let form = {}
    logFormData(parameters.form)
    if (parameters['body'] !== undefined) {
      body = parameters['body']
    }
    if (parameters['id'] === undefined) {
      return Promise.reject(new Error('Missing required parameter: id'))
    }
    path = path.replace('{id}', `${parameters['id']}`)
    if (parameters.$queryParameters) {
      Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
        queryParameters[parameterName] = parameters.$queryParameters[parameterName]
      });
    }
    const r = generateRequest('post', path, body, queryParameters, form, config)
    return $axios.$post(r.url, r.opts, r.config)
  },
  archive: (parameters = {}) => {
    const config = parameters.$config
    let path = 'api/sequence/{id}/archive'
    let body
    let queryParameters = {}
    let form = {}
    logFormData(parameters.form)
    if (parameters['body'] !== undefined) {
      body = parameters['body']
    }
    if (parameters['id'] === undefined) {
      return Promise.reject(new Error('Missing required parameter: id'))
    }
    path = path.replace('{id}', `${parameters['id']}`)
    if (parameters.$queryParameters) {
      Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
        queryParameters[parameterName] = parameters.$queryParameters[parameterName]
      });
    }
    const r = generateRequest('put', path, body, queryParameters, form, config)
    return $axios.$put(r.url, r.opts, r.config)
  },
  clearAuthoringToolData: (parameters = {}) => {
    const config = parameters.$config
    let path = 'api/sequence/{id}/clear-authoring-tool'
    let body
    let queryParameters = {}
    let form = {}
    logFormData(parameters.form)
    if (parameters['body'] !== undefined) {
      body = parameters['body']
    }
    if (parameters['id'] === undefined) {
      return Promise.reject(new Error('Missing required parameter: id'))
    }
    path = path.replace('{id}', `${parameters['id']}`)
    if (parameters.$queryParameters) {
      Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
        queryParameters[parameterName] = parameters.$queryParameters[parameterName]
      });
    }
    const r = generateRequest('put', path, body, queryParameters, form, config)
    return $axios.$put(r.url, r.opts, r.config)
  },
  clearLmsData: (parameters = {}) => {
    const config = parameters.$config
    let path = 'api/sequence/{id}/clearLms'
    let body
    let queryParameters = {}
    let form = {}
    logFormData(parameters.form)
    if (parameters['body'] !== undefined) {
      body = parameters['body']
    }
    if (parameters['id'] === undefined) {
      return Promise.reject(new Error('Missing required parameter: id'))
    }
    path = path.replace('{id}', `${parameters['id']}`)
    if (parameters.$queryParameters) {
      Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
        queryParameters[parameterName] = parameters.$queryParameters[parameterName]
      });
    }
    const r = generateRequest('put', path, body, queryParameters, form, config)
    return $axios.$put(r.url, r.opts, r.config)
  },
  create: (parameters = {}) => {
    const config = parameters.$config
    let path = 'api/sequence'
    let body
    let queryParameters = {}
    let form = {}
    logFormData(parameters.form)
    if (parameters['body'] !== undefined) {
      body = parameters['body']
    }
    if (parameters.$queryParameters) {
      Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
        queryParameters[parameterName] = parameters.$queryParameters[parameterName]
      });
    }
    const r = generateRequest('post', path, body, queryParameters, form, config)
    return $axios.$post(r.url, r.opts, r.config)
  },
  delete: (parameters = {}) => {
    const config = parameters.$config
    let path = 'api/sequence/{id}'
    let body
    let queryParameters = {}
    let form = {}
    logFormData(parameters.form)
    if (parameters['id'] === undefined) {
      return Promise.reject(new Error('Missing required parameter: id'))
    }
    path = path.replace('{id}', `${parameters['id']}`)
    if (parameters.$queryParameters) {
      Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
        queryParameters[parameterName] = parameters.$queryParameters[parameterName]
      });
    }
    const r = generateRequest('delete', path, body, queryParameters, form, config)
    return $axios.$delete(r.url, r.config)
  },
  get: (parameters = {}) => {
    const config = parameters.$config
    let path = 'api/sequence'
    let body
    let queryParameters = {}
    let form = {}
    logFormData(parameters.form)
    if (parameters.$queryParameters) {
      Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
        queryParameters[parameterName] = parameters.$queryParameters[parameterName]
      });
    }
    const r = generateRequest('get', path, body, queryParameters, form, config)
    return $axios.$get(r.url, r.config)
  },
  getChildren: (parameters = {}) => {
    const config = parameters.$config
    let path = 'api/sequence/{id}/getChildren'
    let body
    let queryParameters = {}
    let form = {}
    logFormData(parameters.form)
    if (parameters['id'] === undefined) {
      return Promise.reject(new Error('Missing required parameter: id'))
    }
    path = path.replace('{id}', `${parameters['id']}`)
    if (parameters.$queryParameters) {
      Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
        queryParameters[parameterName] = parameters.$queryParameters[parameterName]
      });
    }
    const r = generateRequest('get', path, body, queryParameters, form, config)
    return $axios.$get(r.url, r.config)
  },
  getOne: (parameters = {}) => {
    const config = parameters.$config
    let path = 'api/sequence/{id}'
    let body
    let queryParameters = {}
    let form = {}
    logFormData(parameters.form)
    if (parameters['id'] === undefined) {
      return Promise.reject(new Error('Missing required parameter: id'))
    }
    path = path.replace('{id}', `${parameters['id']}`)
    if (parameters.$queryParameters) {
      Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
        queryParameters[parameterName] = parameters.$queryParameters[parameterName]
      });
    }
    const r = generateRequest('get', path, body, queryParameters, form, config)
    return $axios.$get(r.url, r.config)
  },
  getRecommendations: (parameters = {}) => {
    const config = parameters.$config
    let path = 'api/sequence/{id}/recommendations/{recommendationType}'
    let body
    let queryParameters = {}
    let form = {}
    logFormData(parameters.form)
    if (parameters['id'] === undefined) {
      return Promise.reject(new Error('Missing required parameter: id'))
    }
    path = path.replace('{id}', `${parameters['id']}`)
    if (parameters['recommendationType'] === undefined) {
      return Promise.reject(new Error('Missing required parameter: recommendationType'))
    }
    path = path.replace('{recommendationType}', `${parameters['recommendationType']}`)
    if (parameters.$queryParameters) {
      Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
        queryParameters[parameterName] = parameters.$queryParameters[parameterName]
      });
    }
    const r = generateRequest('get', path, body, queryParameters, form, config)
    return $axios.$get(r.url, r.config)
  },
  lmsDataStatus: (parameters = {}) => {
    const config = parameters.$config
    let path = 'api/sequence/{id}/lmsDataStatus'
    let body
    let queryParameters = {}
    let form = {}
    logFormData(parameters.form)
    if (parameters['id'] === undefined) {
      return Promise.reject(new Error('Missing required parameter: id'))
    }
    path = path.replace('{id}', `${parameters['id']}`)
    if (parameters.$queryParameters) {
      Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
        queryParameters[parameterName] = parameters.$queryParameters[parameterName]
      });
    }
    const r = generateRequest('get', path, body, queryParameters, form, config)
    return $axios.$get(r.url, r.config)
  },
  removeActivity: (parameters = {}) => {
    const config = parameters.$config
    let path = 'api/sequence/{id}/removeActivity'
    let body
    let queryParameters = {}
    let form = {}
    logFormData(parameters.form)
    if (parameters['body'] !== undefined) {
      body = parameters['body']
    }
    if (parameters['id'] === undefined) {
      return Promise.reject(new Error('Missing required parameter: id'))
    }
    path = path.replace('{id}', `${parameters['id']}`)
    if (parameters.$queryParameters) {
      Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
        queryParameters[parameterName] = parameters.$queryParameters[parameterName]
      });
    }
    const r = generateRequest('post', path, body, queryParameters, form, config)
    return $axios.$post(r.url, r.opts, r.config)
  },
  setStatus: (parameters = {}) => {
    const config = parameters.$config
    let path = 'api/sequence/{id}/status'
    let body
    let queryParameters = {}
    let form = {}
    logFormData(parameters.form)
    if (parameters['body'] !== undefined) {
      body = parameters['body']
    }
    if (parameters['id'] === undefined) {
      return Promise.reject(new Error('Missing required parameter: id'))
    }
    path = path.replace('{id}', `${parameters['id']}`)
    if (parameters.$queryParameters) {
      Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
        queryParameters[parameterName] = parameters.$queryParameters[parameterName]
      });
    }
    const r = generateRequest('put', path, body, queryParameters, form, config)
    return $axios.$put(r.url, r.opts, r.config)
  },
  update: (parameters = {}) => {
    const config = parameters.$config
    let path = 'api/sequence/{id}'
    let body
    let queryParameters = {}
    let form = {}
    logFormData(parameters.form)
    if (parameters['body'] !== undefined) {
      body = parameters['body']
    }
    if (parameters['id'] === undefined) {
      return Promise.reject(new Error('Missing required parameter: id'))
    }
    path = path.replace('{id}', `${parameters['id']}`)
    if (parameters.$queryParameters) {
      Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
        queryParameters[parameterName] = parameters.$queryParameters[parameterName]
      });
    }
    const r = generateRequest('put', path, body, queryParameters, form, config)
    return $axios.$put(r.url, r.opts, r.config)
  },
  updateAuthoringToolData: (parameters = {}) => {
    const config = parameters.$config
    let path = 'api/sequence/{id}/authoring-tool'
    let body
    let queryParameters = {}
    let form = {}
    logFormData(parameters.form)
    if (parameters['body'] !== undefined) {
      body = parameters['body']
    }
    if (parameters['id'] === undefined) {
      return Promise.reject(new Error('Missing required parameter: id'))
    }
    path = path.replace('{id}', `${parameters['id']}`)
    if (parameters.$queryParameters) {
      Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
        queryParameters[parameterName] = parameters.$queryParameters[parameterName]
      });
    }
    const r = generateRequest('put', path, body, queryParameters, form, config)
    return $axios.$put(r.url, r.opts, r.config)
  },
  updateLmsData: (parameters = {}) => {
    const config = parameters.$config
    let path = 'api/sequence/{id}/lms'
    let body
    let queryParameters = {}
    let form = {}
    logFormData(parameters.form)
    if (parameters['body'] !== undefined) {
      body = parameters['body']
    }
    if (parameters['id'] === undefined) {
      return Promise.reject(new Error('Missing required parameter: id'))
    }
    path = path.replace('{id}', `${parameters['id']}`)
    if (parameters.$queryParameters) {
      Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
        queryParameters[parameterName] = parameters.$queryParameters[parameterName]
      });
    }
    const r = generateRequest('put', path, body, queryParameters, form, config)
    return $axios.$put(r.url, r.opts, r.config)
  },
})
/* settings */
const settings = $axios => ({
  get: (parameters = {}) => {
    const config = parameters.$config
    let path = 'api/settings/{type}'
    let body
    let queryParameters = {}
    let form = {}
    logFormData(parameters.form)
    if (parameters['type'] === undefined) {
      return Promise.reject(new Error('Missing required parameter: type'))
    }
    path = path.replace('{type}', `${parameters['type']}`)
    if (parameters.$queryParameters) {
      Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
        queryParameters[parameterName] = parameters.$queryParameters[parameterName]
      });
    }
    const r = generateRequest('get', path, body, queryParameters, form, config)
    return $axios.$get(r.url, r.config)
  },
  update: (parameters = {}) => {
    const config = parameters.$config
    let path = 'api/settings/{type}'
    let body
    let queryParameters = {}
    let form = {}
    logFormData(parameters.form)
    if (parameters['body'] !== undefined) {
      body = parameters['body']
    }
    if (parameters['type'] === undefined) {
      return Promise.reject(new Error('Missing required parameter: type'))
    }
    path = path.replace('{type}', `${parameters['type']}`)
    if (parameters.$queryParameters) {
      Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
        queryParameters[parameterName] = parameters.$queryParameters[parameterName]
      });
    }
    const r = generateRequest('post', path, body, queryParameters, form, config)
    return $axios.$post(r.url, r.opts, r.config)
  },
  updateSettingStatuses: (parameters = {}) => {
    const config = parameters.$config
    let path = 'api/settings/statuses'
    let body
    let queryParameters = {}
    let form = {}
    logFormData(parameters.form)
    if (parameters['body'] !== undefined) {
      body = parameters['body']
    }
    if (parameters.$queryParameters) {
      Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
        queryParameters[parameterName] = parameters.$queryParameters[parameterName]
      });
    }
    const r = generateRequest('put', path, body, queryParameters, form, config)
    return $axios.$put(r.url, r.opts, r.config)
  },
})
/* snapshot */
const snapshot = $axios => ({
  create: (parameters = {}) => {
    const config = parameters.$config
    let path = 'api/snapshot'
    let body
    let queryParameters = {}
    let form = {}
    logFormData(parameters.form)
    if (parameters['body'] !== undefined) {
      body = parameters['body']
    }
    if (parameters.$queryParameters) {
      Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
        queryParameters[parameterName] = parameters.$queryParameters[parameterName]
      });
    }
    const r = generateRequest('post', path, body, queryParameters, form, config)
    return $axios.$post(r.url, r.opts, r.config)
  },
  delete: (parameters = {}) => {
    const config = parameters.$config
    let path = 'api/snapshot/{id}'
    let body
    let queryParameters = {}
    let form = {}
    logFormData(parameters.form)
    if (parameters['id'] === undefined) {
      return Promise.reject(new Error('Missing required parameter: id'))
    }
    path = path.replace('{id}', `${parameters['id']}`)
    if (parameters.$queryParameters) {
      Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
        queryParameters[parameterName] = parameters.$queryParameters[parameterName]
      });
    }
    const r = generateRequest('delete', path, body, queryParameters, form, config)
    return $axios.$delete(r.url, r.config)
  },
  get: (parameters = {}) => {
    const config = parameters.$config
    let path = 'api/snapshot'
    let body
    let queryParameters = {}
    let form = {}
    logFormData(parameters.form)
    if (parameters.$queryParameters) {
      Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
        queryParameters[parameterName] = parameters.$queryParameters[parameterName]
      });
    }
    const r = generateRequest('get', path, body, queryParameters, form, config)
    return $axios.$get(r.url, r.config)
  },
  getChanges: (parameters = {}) => {
    const config = parameters.$config
    let path = 'api/snapshot/{id}/changes'
    let body
    let queryParameters = {}
    let form = {}
    logFormData(parameters.form)
    if (parameters['id'] === undefined) {
      return Promise.reject(new Error('Missing required parameter: id'))
    }
    path = path.replace('{id}', `${parameters['id']}`)
    if (parameters.$queryParameters) {
      Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
        queryParameters[parameterName] = parameters.$queryParameters[parameterName]
      });
    }
    const r = generateRequest('get', path, body, queryParameters, form, config)
    return $axios.$get(r.url, r.config)
  },
  getOne: (parameters = {}) => {
    const config = parameters.$config
    let path = 'api/snapshot/{id}'
    let body
    let queryParameters = {}
    let form = {}
    logFormData(parameters.form)
    if (parameters['id'] === undefined) {
      return Promise.reject(new Error('Missing required parameter: id'))
    }
    path = path.replace('{id}', `${parameters['id']}`)
    if (parameters.$queryParameters) {
      Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
        queryParameters[parameterName] = parameters.$queryParameters[parameterName]
      });
    }
    const r = generateRequest('get', path, body, queryParameters, form, config)
    return $axios.$get(r.url, r.config)
  },
})
/* stats */
const stats = $axios => ({
  count: (parameters = {}) => {
    const config = parameters.$config
    let path = 'api/stats/count/{objectType}'
    let body
    let queryParameters = {}
    let form = {}
    logFormData(parameters.form)
    if (parameters['objectType'] === undefined) {
      return Promise.reject(new Error('Missing required parameter: objectType'))
    }
    path = path.replace('{objectType}', `${parameters['objectType']}`)
    if (parameters.$queryParameters) {
      Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
        queryParameters[parameterName] = parameters.$queryParameters[parameterName]
      });
    }
    const r = generateRequest('get', path, body, queryParameters, form, config)
    return $axios.$get(r.url, r.config)
  },
  registration: (parameters = {}) => {
    const config = parameters.$config
    let path = 'api/stats/register'
    let body
    let queryParameters = {}
    let form = {}
    logFormData(parameters.form)
    if (parameters.$queryParameters) {
      Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
        queryParameters[parameterName] = parameters.$queryParameters[parameterName]
      });
    }
    const r = generateRequest('get', path, body, queryParameters, form, config)
    return $axios.$get(r.url, r.config)
  },
})
/* storage */
const storage = $axios => ({
  create: (parameters = {}) => {
    const config = parameters.$config
    let path = 'api/storage'
    let body
    let queryParameters = {}
    let form = {}
    logFormData(parameters.form)
    if (parameters['body'] !== undefined) {
      body = parameters['body']
    }
    if (parameters.$queryParameters) {
      Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
        queryParameters[parameterName] = parameters.$queryParameters[parameterName]
      });
    }
    const r = generateRequest('post', path, body, queryParameters, form, config)
    return $axios.$post(r.url, r.opts, r.config)
  },
  delete: (parameters = {}) => {
    const config = parameters.$config
    let path = 'api/storage/item'
    let body
    let queryParameters = {}
    let form = {}
    logFormData(parameters.form)
    if (parameters.$queryParameters) {
      Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
        queryParameters[parameterName] = parameters.$queryParameters[parameterName]
      });
    }
    const r = generateRequest('delete', path, body, queryParameters, form, config)
    return $axios.$delete(r.url, r.config)
  },
  get: (parameters = {}) => {
    const config = parameters.$config
    let path = 'api/storage'
    let body
    let queryParameters = {}
    let form = {}
    logFormData(parameters.form)
    if (parameters.$queryParameters) {
      Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
        queryParameters[parameterName] = parameters.$queryParameters[parameterName]
      });
    }
    const r = generateRequest('get', path, body, queryParameters, form, config)
    return $axios.$get(r.url, r.config)
  },
  getGroupsDrive: (parameters = {}) => {
    const config = parameters.$config
    let path = 'api/storage/groupsDrives'
    let body
    let queryParameters = {}
    let form = {}
    logFormData(parameters.form)
    if (parameters.$queryParameters) {
      Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
        queryParameters[parameterName] = parameters.$queryParameters[parameterName]
      });
    }
    const r = generateRequest('get', path, body, queryParameters, form, config)
    return $axios.$get(r.url, r.config)
  },
  getListDrives: (parameters = {}) => {
    const config = parameters.$config
    let path = 'api/storage/drives'
    let body
    let queryParameters = {}
    let form = {}
    logFormData(parameters.form)
    if (parameters.$queryParameters) {
      Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
        queryParameters[parameterName] = parameters.$queryParameters[parameterName]
      });
    }
    const r = generateRequest('get', path, body, queryParameters, form, config)
    return $axios.$get(r.url, r.config)
  },
  getUploadSession: (parameters = {}) => {
    const config = parameters.$config
    let path = 'api/storage/uploadSession'
    let body
    let queryParameters = {}
    let form = {}
    logFormData(parameters.form)
    if (parameters.$queryParameters) {
      Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
        queryParameters[parameterName] = parameters.$queryParameters[parameterName]
      });
    }
    const r = generateRequest('get', path, body, queryParameters, form, config)
    return $axios.$get(r.url, r.config)
  },
  initFolder: (parameters = {}) => {
    const config = parameters.$config
    let path = 'api/storage/initializeFolder'
    let body
    let queryParameters = {}
    let form = {}
    logFormData(parameters.form)
    if (parameters['body'] !== undefined) {
      body = parameters['body']
    }
    if (parameters.$queryParameters) {
      Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
        queryParameters[parameterName] = parameters.$queryParameters[parameterName]
      });
    }
    const r = generateRequest('post', path, body, queryParameters, form, config)
    return $axios.$post(r.url, r.opts, r.config)
  },
})
/* stripe */
const stripe = $axios => ({
  cancelSubscription: (parameters = {}) => {
    const config = parameters.$config
    let path = 'api/stripe/cancelSubscription'
    let body
    let queryParameters = {}
    let form = {}
    logFormData(parameters.form)
    if (parameters['body'] !== undefined) {
      body = parameters['body']
    }
    if (parameters.$queryParameters) {
      Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
        queryParameters[parameterName] = parameters.$queryParameters[parameterName]
      });
    }
    const r = generateRequest('post', path, body, queryParameters, form, config)
    return $axios.$post(r.url, r.opts, r.config)
  },
  checkout: (parameters = {}) => {
    const config = parameters.$config
    let path = 'api/stripe/checkout'
    let body
    let queryParameters = {}
    let form = {}
    logFormData(parameters.form)
    if (parameters['body'] !== undefined) {
      body = parameters['body']
    }
    if (parameters.$queryParameters) {
      Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
        queryParameters[parameterName] = parameters.$queryParameters[parameterName]
      });
    }
    const r = generateRequest('post', path, body, queryParameters, form, config)
    return $axios.$post(r.url, r.opts, r.config)
  },
  checkoutSuccess: (parameters = {}) => {
    const config = parameters.$config
    let path = 'api/stripe/checkoutSuccess'
    let body
    let queryParameters = {}
    let form = {}
    logFormData(parameters.form)
    if (parameters['body'] !== undefined) {
      body = parameters['body']
    }
    if (parameters.$queryParameters) {
      Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
        queryParameters[parameterName] = parameters.$queryParameters[parameterName]
      });
    }
    const r = generateRequest('post', path, body, queryParameters, form, config)
    return $axios.$post(r.url, r.opts, r.config)
  },
  createCustomer: (parameters = {}) => {
    const config = parameters.$config
    let path = 'api/stripe/customer'
    let body
    let queryParameters = {}
    let form = {}
    logFormData(parameters.form)
    if (parameters['body'] !== undefined) {
      body = parameters['body']
    }
    if (parameters.$queryParameters) {
      Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
        queryParameters[parameterName] = parameters.$queryParameters[parameterName]
      });
    }
    const r = generateRequest('post', path, body, queryParameters, form, config)
    return $axios.$post(r.url, r.opts, r.config)
  },
  createSubscription: (parameters = {}) => {
    const config = parameters.$config
    let path = 'api/stripe/subscription'
    let body
    let queryParameters = {}
    let form = {}
    logFormData(parameters.form)
    if (parameters['body'] !== undefined) {
      body = parameters['body']
    }
    if (parameters.$queryParameters) {
      Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
        queryParameters[parameterName] = parameters.$queryParameters[parameterName]
      });
    }
    const r = generateRequest('post', path, body, queryParameters, form, config)
    return $axios.$post(r.url, r.opts, r.config)
  },
  customerPortal: (parameters = {}) => {
    const config = parameters.$config
    let path = 'api/stripe/customerPortal'
    let body
    let queryParameters = {}
    let form = {}
    logFormData(parameters.form)
    if (parameters.$queryParameters) {
      Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
        queryParameters[parameterName] = parameters.$queryParameters[parameterName]
      });
    }
    const r = generateRequest('get', path, body, queryParameters, form, config)
    return $axios.$get(r.url, r.config)
  },
  getPastDueInvoice: (parameters = {}) => {
    const config = parameters.$config
    let path = 'api/stripe/getPastDueInvoice'
    let body
    let queryParameters = {}
    let form = {}
    logFormData(parameters.form)
    if (parameters.$queryParameters) {
      Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
        queryParameters[parameterName] = parameters.$queryParameters[parameterName]
      });
    }
    const r = generateRequest('get', path, body, queryParameters, form, config)
    return $axios.$get(r.url, r.config)
  },
  getPrices: (parameters = {}) => {
    const config = parameters.$config
    let path = 'api/stripe/prices'
    let body
    let queryParameters = {}
    let form = {}
    logFormData(parameters.form)
    if (parameters.$queryParameters) {
      Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
        queryParameters[parameterName] = parameters.$queryParameters[parameterName]
      });
    }
    const r = generateRequest('get', path, body, queryParameters, form, config)
    return $axios.$get(r.url, r.config)
  },
  getSubscription: (parameters = {}) => {
    const config = parameters.$config
    let path = 'api/stripe/subscription'
    let body
    let queryParameters = {}
    let form = {}
    logFormData(parameters.form)
    if (parameters.$queryParameters) {
      Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
        queryParameters[parameterName] = parameters.$queryParameters[parameterName]
      });
    }
    const r = generateRequest('get', path, body, queryParameters, form, config)
    return $axios.$get(r.url, r.config)
  },
  previewPrice: (parameters = {}) => {
    const config = parameters.$config
    let path = 'api/stripe/previewPrice'
    let body
    let queryParameters = {}
    let form = {}
    logFormData(parameters.form)
    if (parameters['body'] !== undefined) {
      body = parameters['body']
    }
    if (parameters.$queryParameters) {
      Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
        queryParameters[parameterName] = parameters.$queryParameters[parameterName]
      });
    }
    const r = generateRequest('post', path, body, queryParameters, form, config)
    return $axios.$post(r.url, r.opts, r.config)
  },
  previewSubscription: (parameters = {}) => {
    const config = parameters.$config
    let path = 'api/stripe/subscription/preview'
    let body
    let queryParameters = {}
    let form = {}
    logFormData(parameters.form)
    if (parameters['body'] !== undefined) {
      body = parameters['body']
    }
    if (parameters.$queryParameters) {
      Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
        queryParameters[parameterName] = parameters.$queryParameters[parameterName]
      });
    }
    const r = generateRequest('post', path, body, queryParameters, form, config)
    return $axios.$post(r.url, r.opts, r.config)
  },
  updateSubscription: (parameters = {}) => {
    const config = parameters.$config
    let path = 'api/stripe/updateSubscription'
    let body
    let queryParameters = {}
    let form = {}
    logFormData(parameters.form)
    if (parameters['body'] !== undefined) {
      body = parameters['body']
    }
    if (parameters.$queryParameters) {
      Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
        queryParameters[parameterName] = parameters.$queryParameters[parameterName]
      });
    }
    const r = generateRequest('post', path, body, queryParameters, form, config)
    return $axios.$post(r.url, r.opts, r.config)
  },
  webhook: (parameters = {}) => {
    const config = parameters.$config
    let path = 'api/stripe/webhook'
    let body
    let queryParameters = {}
    let form = {}
    logFormData(parameters.form)
    if (parameters['body'] !== undefined) {
      body = parameters['body']
    }
    if (parameters.$queryParameters) {
      Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
        queryParameters[parameterName] = parameters.$queryParameters[parameterName]
      });
    }
    const r = generateRequest('post', path, body, queryParameters, form, config)
    return $axios.$post(r.url, r.opts, r.config)
  },
})
/* task */
const task = $axios => ({
  create: (parameters = {}) => {
    const config = parameters.$config
    let path = 'api/task/{parentId?}'
    let body
    let queryParameters = {}
    let form = {}
    logFormData(parameters.form)
    if (parameters['body'] !== undefined) {
      body = parameters['body']
    }
    if (parameters['parentId']) {
      path = path.replace('{parentId?}', `${parameters['parentId']}`)
    } else {
      path = path.replace('{parentId?}', '')
    }
    if (parameters.$queryParameters) {
      Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
        queryParameters[parameterName] = parameters.$queryParameters[parameterName]
      });
    }
    const r = generateRequest('post', path, body, queryParameters, form, config)
    return $axios.$post(r.url, r.opts, r.config)
  },
  delete: (parameters = {}) => {
    const config = parameters.$config
    let path = 'api/task/{id}'
    let body
    let queryParameters = {}
    let form = {}
    logFormData(parameters.form)
    if (parameters['id'] === undefined) {
      return Promise.reject(new Error('Missing required parameter: id'))
    }
    path = path.replace('{id}', `${parameters['id']}`)
    if (parameters.$queryParameters) {
      Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
        queryParameters[parameterName] = parameters.$queryParameters[parameterName]
      });
    }
    const r = generateRequest('delete', path, body, queryParameters, form, config)
    return $axios.$delete(r.url, r.config)
  },
  get: (parameters = {}) => {
    const config = parameters.$config
    let path = 'api/task'
    let body
    let queryParameters = {}
    let form = {}
    logFormData(parameters.form)
    if (parameters.$queryParameters) {
      Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
        queryParameters[parameterName] = parameters.$queryParameters[parameterName]
      });
    }
    const r = generateRequest('get', path, body, queryParameters, form, config)
    return $axios.$get(r.url, r.config)
  },
  getChildren: (parameters = {}) => {
    const config = parameters.$config
    let path = 'api/task/{id}/getChildren'
    let body
    let queryParameters = {}
    let form = {}
    logFormData(parameters.form)
    if (parameters['id'] === undefined) {
      return Promise.reject(new Error('Missing required parameter: id'))
    }
    path = path.replace('{id}', `${parameters['id']}`)
    if (parameters.$queryParameters) {
      Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
        queryParameters[parameterName] = parameters.$queryParameters[parameterName]
      });
    }
    const r = generateRequest('get', path, body, queryParameters, form, config)
    return $axios.$get(r.url, r.config)
  },
  getOne: (parameters = {}) => {
    const config = parameters.$config
    let path = 'api/task/{id}'
    let body
    let queryParameters = {}
    let form = {}
    logFormData(parameters.form)
    if (parameters['id'] === undefined) {
      return Promise.reject(new Error('Missing required parameter: id'))
    }
    path = path.replace('{id}', `${parameters['id']}`)
    if (parameters.$queryParameters) {
      Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
        queryParameters[parameterName] = parameters.$queryParameters[parameterName]
      });
    }
    const r = generateRequest('get', path, body, queryParameters, form, config)
    return $axios.$get(r.url, r.config)
  },
  setStatus: (parameters = {}) => {
    const config = parameters.$config
    let path = 'api/task/{id}/status'
    let body
    let queryParameters = {}
    let form = {}
    logFormData(parameters.form)
    if (parameters['body'] !== undefined) {
      body = parameters['body']
    }
    if (parameters['id'] === undefined) {
      return Promise.reject(new Error('Missing required parameter: id'))
    }
    path = path.replace('{id}', `${parameters['id']}`)
    if (parameters.$queryParameters) {
      Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
        queryParameters[parameterName] = parameters.$queryParameters[parameterName]
      });
    }
    const r = generateRequest('put', path, body, queryParameters, form, config)
    return $axios.$put(r.url, r.opts, r.config)
  },
  update: (parameters = {}) => {
    const config = parameters.$config
    let path = 'api/task/{id}'
    let body
    let queryParameters = {}
    let form = {}
    logFormData(parameters.form)
    if (parameters['body'] !== undefined) {
      body = parameters['body']
    }
    if (parameters['id'] === undefined) {
      return Promise.reject(new Error('Missing required parameter: id'))
    }
    path = path.replace('{id}', `${parameters['id']}`)
    if (parameters.$queryParameters) {
      Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
        queryParameters[parameterName] = parameters.$queryParameters[parameterName]
      });
    }
    const r = generateRequest('put', path, body, queryParameters, form, config)
    return $axios.$put(r.url, r.opts, r.config)
  },
})
/* taxonomy */
const taxonomy = $axios => ({
  bloom: (parameters = {}) => {
    const config = parameters.$config
    let path = 'api/bloom'
    let body
    let queryParameters = {}
    let form = {}
    logFormData(parameters.form)
    if (parameters['body'] !== undefined) {
      body = parameters['body']
    }
    if (parameters.$queryParameters) {
      Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
        queryParameters[parameterName] = parameters.$queryParameters[parameterName]
      });
    }
    const r = generateRequest('post', path, body, queryParameters, form, config)
    return $axios.$post(r.url, r.opts, r.config)
  },
})
/* team */
const team = $axios => ({
  create: (parameters = {}) => {
    const config = parameters.$config
    let path = 'api/team'
    let body
    let queryParameters = {}
    let form = {}
    logFormData(parameters.form)
    if (parameters['body'] !== undefined) {
      body = parameters['body']
    }
    if (parameters.$queryParameters) {
      Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
        queryParameters[parameterName] = parameters.$queryParameters[parameterName]
      });
    }
    const r = generateRequest('post', path, body, queryParameters, form, config)
    return $axios.$post(r.url, r.opts, r.config)
  },
  delete: (parameters = {}) => {
    const config = parameters.$config
    let path = 'api/team/{id}'
    let body
    let queryParameters = {}
    let form = {}
    logFormData(parameters.form)
    if (parameters['id'] === undefined) {
      return Promise.reject(new Error('Missing required parameter: id'))
    }
    path = path.replace('{id}', `${parameters['id']}`)
    if (parameters.$queryParameters) {
      Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
        queryParameters[parameterName] = parameters.$queryParameters[parameterName]
      });
    }
    const r = generateRequest('delete', path, body, queryParameters, form, config)
    return $axios.$delete(r.url, r.config)
  },
  get: (parameters = {}) => {
    const config = parameters.$config
    let path = 'api/team'
    let body
    let queryParameters = {}
    let form = {}
    logFormData(parameters.form)
    if (parameters.$queryParameters) {
      Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
        queryParameters[parameterName] = parameters.$queryParameters[parameterName]
      });
    }
    const r = generateRequest('get', path, body, queryParameters, form, config)
    return $axios.$get(r.url, r.config)
  },
  getOne: (parameters = {}) => {
    const config = parameters.$config
    let path = 'api/team/{id}'
    let body
    let queryParameters = {}
    let form = {}
    logFormData(parameters.form)
    if (parameters['id'] === undefined) {
      return Promise.reject(new Error('Missing required parameter: id'))
    }
    path = path.replace('{id}', `${parameters['id']}`)
    if (parameters.$queryParameters) {
      Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
        queryParameters[parameterName] = parameters.$queryParameters[parameterName]
      });
    }
    const r = generateRequest('get', path, body, queryParameters, form, config)
    return $axios.$get(r.url, r.config)
  },
  update: (parameters = {}) => {
    const config = parameters.$config
    let path = 'api/team/{id}'
    let body
    let queryParameters = {}
    let form = {}
    logFormData(parameters.form)
    if (parameters['body'] !== undefined) {
      body = parameters['body']
    }
    if (parameters['id'] === undefined) {
      return Promise.reject(new Error('Missing required parameter: id'))
    }
    path = path.replace('{id}', `${parameters['id']}`)
    if (parameters.$queryParameters) {
      Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
        queryParameters[parameterName] = parameters.$queryParameters[parameterName]
      });
    }
    const r = generateRequest('put', path, body, queryParameters, form, config)
    return $axios.$put(r.url, r.opts, r.config)
  },
})
/* template */
const template = $axios => ({
  delete: (parameters = {}) => {
    const config = parameters.$config
    let path = 'api/template/{id}'
    let body
    let queryParameters = {}
    let form = {}
    logFormData(parameters.form)
    if (parameters['id'] === undefined) {
      return Promise.reject(new Error('Missing required parameter: id'))
    }
    path = path.replace('{id}', `${parameters['id']}`)
    if (parameters.$queryParameters) {
      Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
        queryParameters[parameterName] = parameters.$queryParameters[parameterName]
      });
    }
    const r = generateRequest('delete', path, body, queryParameters, form, config)
    return $axios.$delete(r.url, r.config)
  },
  get: (parameters = {}) => {
    const config = parameters.$config
    let path = 'api/template'
    let body
    let queryParameters = {}
    let form = {}
    logFormData(parameters.form)
    if (parameters.$queryParameters) {
      Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
        queryParameters[parameterName] = parameters.$queryParameters[parameterName]
      });
    }
    const r = generateRequest('get', path, body, queryParameters, form, config)
    return $axios.$get(r.url, r.config)
  },
  getOne: (parameters = {}) => {
    const config = parameters.$config
    let path = 'api/template/{id}'
    let body
    let queryParameters = {}
    let form = {}
    logFormData(parameters.form)
    if (parameters['id'] === undefined) {
      return Promise.reject(new Error('Missing required parameter: id'))
    }
    path = path.replace('{id}', `${parameters['id']}`)
    if (parameters.$queryParameters) {
      Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
        queryParameters[parameterName] = parameters.$queryParameters[parameterName]
      });
    }
    const r = generateRequest('get', path, body, queryParameters, form, config)
    return $axios.$get(r.url, r.config)
  },
  update: (parameters = {}) => {
    const config = parameters.$config
    let path = 'api/template/{id}'
    let body
    let queryParameters = {}
    let form = {}
    logFormData(parameters.form)
    if (parameters['body'] !== undefined) {
      body = parameters['body']
    }
    if (parameters['id'] === undefined) {
      return Promise.reject(new Error('Missing required parameter: id'))
    }
    path = path.replace('{id}', `${parameters['id']}`)
    if (parameters.$queryParameters) {
      Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
        queryParameters[parameterName] = parameters.$queryParameters[parameterName]
      });
    }
    const r = generateRequest('put', path, body, queryParameters, form, config)
    return $axios.$put(r.url, r.opts, r.config)
  },
})
/* tiers */
const tiers = $axios => ({
  getAcademyToken: (parameters = {}) => {
    const config = parameters.$config
    let path = 'api/tiers/academy-token'
    let body
    let queryParameters = {}
    let form = {}
    logFormData(parameters.form)
    if (parameters['body'] !== undefined) {
      body = parameters['body']
    }
    if (parameters.$queryParameters) {
      Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
        queryParameters[parameterName] = parameters.$queryParameters[parameterName]
      });
    }
    const r = generateRequest('post', path, body, queryParameters, form, config)
    return $axios.$post(r.url, r.opts, r.config)
  },
})
/* tutorialTracking */
const tutorialTracking = $axios => ({
  complete: (parameters = {}) => {
    const config = parameters.$config
    let path = 'api/tutorial-tracking/complete'
    let body
    let queryParameters = {}
    let form = {}
    logFormData(parameters.form)
    if (parameters['body'] !== undefined) {
      body = parameters['body']
    }
    if (parameters.$queryParameters) {
      Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
        queryParameters[parameterName] = parameters.$queryParameters[parameterName]
      });
    }
    const r = generateRequest('post', path, body, queryParameters, form, config)
    return $axios.$post(r.url, r.opts, r.config)
  },
  getByUser: (parameters = {}) => {
    const config = parameters.$config
    let path = 'api/tutorial-tracking'
    let body
    let queryParameters = {}
    let form = {}
    logFormData(parameters.form)
    if (parameters.$queryParameters) {
      Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
        queryParameters[parameterName] = parameters.$queryParameters[parameterName]
      });
    }
    const r = generateRequest('get', path, body, queryParameters, form, config)
    return $axios.$get(r.url, r.config)
  },
})
/* uploadCDN */
const uploadCDN = $axios => ({
  imageUpload: (parameters = {}) => {
    const config = parameters.$config
    let path = 'api/uploadImageCDN/{type}/{id}/{timestamp}'
    let body
    let queryParameters = {}
    if (parameters.form === undefined) {
      return Promise.reject(new Error('Missing required  parameter: form'))
    }
    let form = parameters.form
    if (parameters['body'] !== undefined) {
      body = parameters['body']
    }
    if (parameters['type'] === undefined) {
      return Promise.reject(new Error('Missing required parameter: type'))
    }
    path = path.replace('{type}', `${parameters['type']}`)
    if (parameters['id'] === undefined) {
      return Promise.reject(new Error('Missing required parameter: id'))
    }
    path = path.replace('{id}', `${parameters['id']}`)
    if (parameters['timestamp'] === undefined) {
      return Promise.reject(new Error('Missing required parameter: timestamp'))
    }
    path = path.replace('{timestamp}', `${parameters['timestamp']}`)
    if (parameters.$queryParameters) {
      Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
        queryParameters[parameterName] = parameters.$queryParameters[parameterName]
      });
    }
    const r = generateRequest('post', path, body, queryParameters, form, config)
    return $axios.$post(r.url, r.opts, r.config)
  },
  upload: (parameters = {}) => {
    const config = parameters.$config
    let path = 'api/uploadCDN/avatarType/{avatarType}/id/{id}'
    let body
    let queryParameters = {}
    if (parameters.form === undefined) {
      return Promise.reject(new Error('Missing required  parameter: form'))
    }
    let form = parameters.form
    if (parameters['body'] !== undefined) {
      body = parameters['body']
    }
    if (parameters['avatarType'] === undefined) {
      return Promise.reject(new Error('Missing required parameter: avatarType'))
    }
    path = path.replace('{avatarType}', `${parameters['avatarType']}`)
    if (parameters['id'] === undefined) {
      return Promise.reject(new Error('Missing required parameter: id'))
    }
    path = path.replace('{id}', `${parameters['id']}`)
    if (parameters.$queryParameters) {
      Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
        queryParameters[parameterName] = parameters.$queryParameters[parameterName]
      });
    }
    const r = generateRequest('post', path, body, queryParameters, form, config)
    return $axios.$post(r.url, r.opts, r.config)
  },
  workspaceUpload: (parameters = {}) => {
    const config = parameters.$config
    let path = 'api/workspaceCDN/{type}/{id}'
    let body
    let queryParameters = {}
    if (parameters.form === undefined) {
      return Promise.reject(new Error('Missing required  parameter: form'))
    }
    let form = parameters.form
    if (parameters['body'] !== undefined) {
      body = parameters['body']
    }
    if (parameters['type'] === undefined) {
      return Promise.reject(new Error('Missing required parameter: type'))
    }
    path = path.replace('{type}', `${parameters['type']}`)
    if (parameters['id'] === undefined) {
      return Promise.reject(new Error('Missing required parameter: id'))
    }
    path = path.replace('{id}', `${parameters['id']}`)
    if (parameters.$queryParameters) {
      Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
        queryParameters[parameterName] = parameters.$queryParameters[parameterName]
      });
    }
    const r = generateRequest('post', path, body, queryParameters, form, config)
    return $axios.$post(r.url, r.opts, r.config)
  },
})
/* user */
const user = $axios => ({
  createCustomIntegration: (parameters = {}) => {
    const config = parameters.$config
    let path = 'api/user/createCustomIntegration'
    let body
    let queryParameters = {}
    let form = {}
    logFormData(parameters.form)
    if (parameters['body'] !== undefined) {
      body = parameters['body']
    }
    if (parameters.$queryParameters) {
      Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
        queryParameters[parameterName] = parameters.$queryParameters[parameterName]
      });
    }
    const r = generateRequest('post', path, body, queryParameters, form, config)
    return $axios.$post(r.url, r.opts, r.config)
  },
  createIntegration: (parameters = {}) => {
    const config = parameters.$config
    let path = 'api/user/createIntegration'
    let body
    let queryParameters = {}
    let form = {}
    logFormData(parameters.form)
    if (parameters['body'] !== undefined) {
      body = parameters['body']
    }
    if (parameters.$queryParameters) {
      Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
        queryParameters[parameterName] = parameters.$queryParameters[parameterName]
      });
    }
    const r = generateRequest('post', path, body, queryParameters, form, config)
    return $axios.$post(r.url, r.opts, r.config)
  },
  deleteIntegration: (parameters = {}) => {
    const config = parameters.$config
    let path = 'api/user/deleteIntegration/{id}'
    let body
    let queryParameters = {}
    let form = {}
    logFormData(parameters.form)
    if (parameters['id'] === undefined) {
      return Promise.reject(new Error('Missing required parameter: id'))
    }
    path = path.replace('{id}', `${parameters['id']}`)
    if (parameters.$queryParameters) {
      Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
        queryParameters[parameterName] = parameters.$queryParameters[parameterName]
      });
    }
    const r = generateRequest('delete', path, body, queryParameters, form, config)
    return $axios.$delete(r.url, r.config)
  },
  getAccessTokenIntegration: (parameters = {}) => {
    const config = parameters.$config
    let path = 'api/user/getAccessTokenIntegration'
    let body
    let queryParameters = {}
    let form = {}
    logFormData(parameters.form)
    if (parameters.$queryParameters) {
      Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
        queryParameters[parameterName] = parameters.$queryParameters[parameterName]
      });
    }
    const r = generateRequest('get', path, body, queryParameters, form, config)
    return $axios.$get(r.url, r.config)
  },
  getIntegrations: (parameters = {}) => {
    const config = parameters.$config
    let path = 'api/user/getIntegration'
    let body
    let queryParameters = {}
    let form = {}
    logFormData(parameters.form)
    if (parameters.$queryParameters) {
      Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
        queryParameters[parameterName] = parameters.$queryParameters[parameterName]
      });
    }
    const r = generateRequest('get', path, body, queryParameters, form, config)
    return $axios.$get(r.url, r.config)
  },
  setNotificationSettings: (parameters = {}) => {
    const config = parameters.$config
    let path = 'api/user/setNotificationSettings'
    let body
    let queryParameters = {}
    let form = {}
    logFormData(parameters.form)
    if (parameters['body'] !== undefined) {
      body = parameters['body']
    }
    if (parameters.$queryParameters) {
      Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
        queryParameters[parameterName] = parameters.$queryParameters[parameterName]
      });
    }
    const r = generateRequest('post', path, body, queryParameters, form, config)
    return $axios.$post(r.url, r.opts, r.config)
  },
})
/* workspace */
const workspace = $axios => ({
  addAlertSetting: (parameters = {}) => {
    const config = parameters.$config
    let path = 'api/workspace/{id}/addAlertSetting'
    let body
    let queryParameters = {}
    let form = {}
    logFormData(parameters.form)
    if (parameters['body'] !== undefined) {
      body = parameters['body']
    }
    if (parameters['id'] === undefined) {
      return Promise.reject(new Error('Missing required parameter: id'))
    }
    path = path.replace('{id}', `${parameters['id']}`)
    if (parameters.$queryParameters) {
      Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
        queryParameters[parameterName] = parameters.$queryParameters[parameterName]
      });
    }
    const r = generateRequest('post', path, body, queryParameters, form, config)
    return $axios.$post(r.url, r.opts, r.config)
  },
  addMembersBulk: (parameters = {}) => {
    const config = parameters.$config
    let path = 'api/workspace/{id}/addMembersBulk'
    let body
    let queryParameters = {}
    let form = {}
    logFormData(parameters.form)
    if (parameters['body'] !== undefined) {
      body = parameters['body']
    }
    if (parameters['id'] === undefined) {
      return Promise.reject(new Error('Missing required parameter: id'))
    }
    path = path.replace('{id}', `${parameters['id']}`)
    if (parameters.$queryParameters) {
      Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
        queryParameters[parameterName] = parameters.$queryParameters[parameterName]
      });
    }
    const r = generateRequest('post', path, body, queryParameters, form, config)
    return $axios.$post(r.url, r.opts, r.config)
  },
  create: (parameters = {}) => {
    const config = parameters.$config
    let path = 'api/workspace'
    let body
    let queryParameters = {}
    let form = {}
    logFormData(parameters.form)
    if (parameters['body'] !== undefined) {
      body = parameters['body']
    }
    if (parameters.$queryParameters) {
      Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
        queryParameters[parameterName] = parameters.$queryParameters[parameterName]
      });
    }
    const r = generateRequest('post', path, body, queryParameters, form, config)
    return $axios.$post(r.url, r.opts, r.config)
  },
  createCustomIntegration: (parameters = {}) => {
    const config = parameters.$config
    let path = 'api/workspace/createCustomIntegration'
    let body
    let queryParameters = {}
    let form = {}
    logFormData(parameters.form)
    if (parameters['body'] !== undefined) {
      body = parameters['body']
    }
    if (parameters.$queryParameters) {
      Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
        queryParameters[parameterName] = parameters.$queryParameters[parameterName]
      });
    }
    const r = generateRequest('post', path, body, queryParameters, form, config)
    return $axios.$post(r.url, r.opts, r.config)
  },
  createIntegration: (parameters = {}) => {
    const config = parameters.$config
    let path = 'api/workspace/createIntegration'
    let body
    let queryParameters = {}
    let form = {}
    logFormData(parameters.form)
    if (parameters['body'] !== undefined) {
      body = parameters['body']
    }
    if (parameters.$queryParameters) {
      Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
        queryParameters[parameterName] = parameters.$queryParameters[parameterName]
      });
    }
    const r = generateRequest('post', path, body, queryParameters, form, config)
    return $axios.$post(r.url, r.opts, r.config)
  },
  deleteIntegration: (parameters = {}) => {
    const config = parameters.$config
    let path = 'api/workspace/deleteIntegration/{workspaceId}/{id}'
    let body
    let queryParameters = {}
    let form = {}
    logFormData(parameters.form)
    if (parameters['workspaceId'] === undefined) {
      return Promise.reject(new Error('Missing required parameter: workspaceId'))
    }
    path = path.replace('{workspaceId}', `${parameters['workspaceId']}`)
    if (parameters['id'] === undefined) {
      return Promise.reject(new Error('Missing required parameter: id'))
    }
    path = path.replace('{id}', `${parameters['id']}`)
    if (parameters.$queryParameters) {
      Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
        queryParameters[parameterName] = parameters.$queryParameters[parameterName]
      });
    }
    const r = generateRequest('delete', path, body, queryParameters, form, config)
    return $axios.$delete(r.url, r.config)
  },
  get: (parameters = {}) => {
    const config = parameters.$config
    let path = 'api/workspace'
    let body
    let queryParameters = {}
    let form = {}
    logFormData(parameters.form)
    if (parameters.$queryParameters) {
      Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
        queryParameters[parameterName] = parameters.$queryParameters[parameterName]
      });
    }
    const r = generateRequest('get', path, body, queryParameters, form, config)
    return $axios.$get(r.url, r.config)
  },
  getAccessTokenIntegration: (parameters = {}) => {
    const config = parameters.$config
    let path = 'api/workspace/getAccessTokenIntegration'
    let body
    let queryParameters = {}
    let form = {}
    logFormData(parameters.form)
    if (parameters.$queryParameters) {
      Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
        queryParameters[parameterName] = parameters.$queryParameters[parameterName]
      });
    }
    const r = generateRequest('get', path, body, queryParameters, form, config)
    return $axios.$get(r.url, r.config)
  },
  getDashboardSummary: (parameters = {}) => {
    const config = parameters.$config
    let path = 'api/workspace/get-dashboard-summary'
    let body
    let queryParameters = {}
    let form = {}
    logFormData(parameters.form)
    if (parameters.$queryParameters) {
      Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
        queryParameters[parameterName] = parameters.$queryParameters[parameterName]
      });
    }
    const r = generateRequest('get', path, body, queryParameters, form, config)
    return $axios.$get(r.url, r.config)
  },
  getIntegrations: (parameters = {}) => {
    const config = parameters.$config
    let path = 'api/workspace/getIntegration/{id}'
    let body
    let queryParameters = {}
    let form = {}
    logFormData(parameters.form)
    if (parameters['id'] === undefined) {
      return Promise.reject(new Error('Missing required parameter: id'))
    }
    path = path.replace('{id}', `${parameters['id']}`)
    if (parameters.$queryParameters) {
      Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
        queryParameters[parameterName] = parameters.$queryParameters[parameterName]
      });
    }
    const r = generateRequest('get', path, body, queryParameters, form, config)
    return $axios.$get(r.url, r.config)
  },
  getOne: (parameters = {}) => {
    const config = parameters.$config
    let path = 'api/workspace/{id}'
    let body
    let queryParameters = {}
    let form = {}
    logFormData(parameters.form)
    if (parameters['id'] === undefined) {
      return Promise.reject(new Error('Missing required parameter: id'))
    }
    path = path.replace('{id}', `${parameters['id']}`)
    if (parameters.$queryParameters) {
      Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
        queryParameters[parameterName] = parameters.$queryParameters[parameterName]
      });
    }
    const r = generateRequest('get', path, body, queryParameters, form, config)
    return $axios.$get(r.url, r.config)
  },
  grantUserPermission: (parameters = {}) => {
    const config = parameters.$config
    let path = 'api/workspace/{id}/member'
    let body
    let queryParameters = {}
    let form = {}
    logFormData(parameters.form)
    if (parameters['body'] !== undefined) {
      body = parameters['body']
    }
    if (parameters['id'] === undefined) {
      return Promise.reject(new Error('Missing required parameter: id'))
    }
    path = path.replace('{id}', `${parameters['id']}`)
    if (parameters.$queryParameters) {
      Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
        queryParameters[parameterName] = parameters.$queryParameters[parameterName]
      });
    }
    const r = generateRequest('post', path, body, queryParameters, form, config)
    return $axios.$post(r.url, r.opts, r.config)
  },
  reassignOwner: (parameters = {}) => {
    const config = parameters.$config
    let path = 'api/workspace/{id}/reassign/{reassignTarget}'
    let body
    let queryParameters = {}
    let form = {}
    logFormData(parameters.form)
    if (parameters['body'] !== undefined) {
      body = parameters['body']
    }
    if (parameters['id'] === undefined) {
      return Promise.reject(new Error('Missing required parameter: id'))
    }
    path = path.replace('{id}', `${parameters['id']}`)
    if (parameters['reassignTarget'] === undefined) {
      return Promise.reject(new Error('Missing required parameter: reassignTarget'))
    }
    path = path.replace('{reassignTarget}', `${parameters['reassignTarget']}`)
    if (parameters.$queryParameters) {
      Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
        queryParameters[parameterName] = parameters.$queryParameters[parameterName]
      });
    }
    const r = generateRequest('post', path, body, queryParameters, form, config)
    return $axios.$post(r.url, r.opts, r.config)
  },
  removeAlertSetting: (parameters = {}) => {
    const config = parameters.$config
    let path = 'api/workspace/{id}/alert/{alertId}'
    let body
    let queryParameters = {}
    let form = {}
    logFormData(parameters.form)
    if (parameters['id'] === undefined) {
      return Promise.reject(new Error('Missing required parameter: id'))
    }
    path = path.replace('{id}', `${parameters['id']}`)
    if (parameters['alertId'] === undefined) {
      return Promise.reject(new Error('Missing required parameter: alertId'))
    }
    path = path.replace('{alertId}', `${parameters['alertId']}`)
    if (parameters.$queryParameters) {
      Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
        queryParameters[parameterName] = parameters.$queryParameters[parameterName]
      });
    }
    const r = generateRequest('delete', path, body, queryParameters, form, config)
    return $axios.$delete(r.url, r.config)
  },
  removeAlertSettingRole: (parameters = {}) => {
    const config = parameters.$config
    let path = 'api/workspace/{id}/alert/{alertId}/removeRole'
    let body
    let queryParameters = {}
    let form = {}
    logFormData(parameters.form)
    if (parameters['body'] !== undefined) {
      body = parameters['body']
    }
    if (parameters['id'] === undefined) {
      return Promise.reject(new Error('Missing required parameter: id'))
    }
    path = path.replace('{id}', `${parameters['id']}`)
    if (parameters['alertId'] === undefined) {
      return Promise.reject(new Error('Missing required parameter: alertId'))
    }
    path = path.replace('{alertId}', `${parameters['alertId']}`)
    if (parameters.$queryParameters) {
      Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
        queryParameters[parameterName] = parameters.$queryParameters[parameterName]
      });
    }
    const r = generateRequest('put', path, body, queryParameters, form, config)
    return $axios.$put(r.url, r.opts, r.config)
  },
  removeUser: (parameters = {}) => {
    const config = parameters.$config
    let path = 'api/workspace/{id}/member/{memberId}'
    let body
    let queryParameters = {}
    let form = {}
    logFormData(parameters.form)
    if (parameters['body'] !== undefined) {
      body = parameters['body']
    }
    if (parameters['id'] === undefined) {
      return Promise.reject(new Error('Missing required parameter: id'))
    }
    path = path.replace('{id}', `${parameters['id']}`)
    if (parameters['memberId'] === undefined) {
      return Promise.reject(new Error('Missing required parameter: memberId'))
    }
    path = path.replace('{memberId}', `${parameters['memberId']}`)
    if (parameters.$queryParameters) {
      Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
        queryParameters[parameterName] = parameters.$queryParameters[parameterName]
      });
    }
    const r = generateRequest('post', path, body, queryParameters, form, config)
    return $axios.$post(r.url, r.opts, r.config)
  },
  startTrial: (parameters = {}) => {
    const config = parameters.$config
    let path = 'api/workspace/{id}/startTrial'
    let body
    let queryParameters = {}
    let form = {}
    logFormData(parameters.form)
    if (parameters['body'] !== undefined) {
      body = parameters['body']
    }
    if (parameters['id'] === undefined) {
      return Promise.reject(new Error('Missing required parameter: id'))
    }
    path = path.replace('{id}', `${parameters['id']}`)
    if (parameters.$queryParameters) {
      Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
        queryParameters[parameterName] = parameters.$queryParameters[parameterName]
      });
    }
    const r = generateRequest('put', path, body, queryParameters, form, config)
    return $axios.$put(r.url, r.opts, r.config)
  },
  update: (parameters = {}) => {
    const config = parameters.$config
    let path = 'api/workspace/{id}'
    let body
    let queryParameters = {}
    let form = {}
    logFormData(parameters.form)
    if (parameters['body'] !== undefined) {
      body = parameters['body']
    }
    if (parameters['id'] === undefined) {
      return Promise.reject(new Error('Missing required parameter: id'))
    }
    path = path.replace('{id}', `${parameters['id']}`)
    if (parameters.$queryParameters) {
      Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
        queryParameters[parameterName] = parameters.$queryParameters[parameterName]
      });
    }
    const r = generateRequest('put', path, body, queryParameters, form, config)
    return $axios.$put(r.url, r.opts, r.config)
  },
  updateAlertSetting: (parameters = {}) => {
    const config = parameters.$config
    let path = 'api/workspace/{id}/alert/{alertId}'
    let body
    let queryParameters = {}
    let form = {}
    logFormData(parameters.form)
    if (parameters['body'] !== undefined) {
      body = parameters['body']
    }
    if (parameters['id'] === undefined) {
      return Promise.reject(new Error('Missing required parameter: id'))
    }
    path = path.replace('{id}', `${parameters['id']}`)
    if (parameters['alertId'] === undefined) {
      return Promise.reject(new Error('Missing required parameter: alertId'))
    }
    path = path.replace('{alertId}', `${parameters['alertId']}`)
    if (parameters.$queryParameters) {
      Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
        queryParameters[parameterName] = parameters.$queryParameters[parameterName]
      });
    }
    const r = generateRequest('put', path, body, queryParameters, form, config)
    return $axios.$put(r.url, r.opts, r.config)
  },
  updateMember: (parameters = {}) => {
    const config = parameters.$config
    let path = 'api/workspace/{id}/member'
    let body
    let queryParameters = {}
    let form = {}
    logFormData(parameters.form)
    if (parameters['body'] !== undefined) {
      body = parameters['body']
    }
    if (parameters['id'] === undefined) {
      return Promise.reject(new Error('Missing required parameter: id'))
    }
    path = path.replace('{id}', `${parameters['id']}`)
    if (parameters.$queryParameters) {
      Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
        queryParameters[parameterName] = parameters.$queryParameters[parameterName]
      });
    }
    const r = generateRequest('put', path, body, queryParameters, form, config)
    return $axios.$put(r.url, r.opts, r.config)
  },
})
/* workspaceCustomer */
const workspaceCustomer = $axios => ({
  create: (parameters = {}) => {
    const config = parameters.$config
    let path = 'api/workspaceCustomer/{workspace}'
    let body
    let queryParameters = {}
    let form = {}
    logFormData(parameters.form)
    if (parameters['body'] !== undefined) {
      body = parameters['body']
    }
    if (parameters['workspace'] === undefined) {
      return Promise.reject(new Error('Missing required parameter: workspace'))
    }
    path = path.replace('{workspace}', `${parameters['workspace']}`)
    if (parameters.$queryParameters) {
      Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
        queryParameters[parameterName] = parameters.$queryParameters[parameterName]
      });
    }
    const r = generateRequest('post', path, body, queryParameters, form, config)
    return $axios.$post(r.url, r.opts, r.config)
  },
  get: (parameters = {}) => {
    const config = parameters.$config
    let path = 'api/workspaceCustomer'
    let body
    let queryParameters = {}
    let form = {}
    logFormData(parameters.form)
    if (parameters.$queryParameters) {
      Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
        queryParameters[parameterName] = parameters.$queryParameters[parameterName]
      });
    }
    const r = generateRequest('get', path, body, queryParameters, form, config)
    return $axios.$get(r.url, r.config)
  },
  getOne: (parameters = {}) => {
    const config = parameters.$config
    let path = 'api/workspaceCustomer/{id}'
    let body
    let queryParameters = {}
    let form = {}
    logFormData(parameters.form)
    if (parameters['id'] === undefined) {
      return Promise.reject(new Error('Missing required parameter: id'))
    }
    path = path.replace('{id}', `${parameters['id']}`)
    if (parameters.$queryParameters) {
      Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
        queryParameters[parameterName] = parameters.$queryParameters[parameterName]
      });
    }
    const r = generateRequest('get', path, body, queryParameters, form, config)
    return $axios.$get(r.url, r.config)
  },
  remove: (parameters = {}) => {
    const config = parameters.$config
    let path = 'api/workspaceCustomer/{id}'
    let body
    let queryParameters = {}
    let form = {}
    logFormData(parameters.form)
    if (parameters['id'] === undefined) {
      return Promise.reject(new Error('Missing required parameter: id'))
    }
    path = path.replace('{id}', `${parameters['id']}`)
    if (parameters.$queryParameters) {
      Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
        queryParameters[parameterName] = parameters.$queryParameters[parameterName]
      });
    }
    const r = generateRequest('delete', path, body, queryParameters, form, config)
    return $axios.$delete(r.url, r.config)
  },
  update: (parameters = {}) => {
    const config = parameters.$config
    let path = 'api/workspaceCustomer/{id}'
    let body
    let queryParameters = {}
    let form = {}
    logFormData(parameters.form)
    if (parameters['body'] !== undefined) {
      body = parameters['body']
    }
    if (parameters['id'] === undefined) {
      return Promise.reject(new Error('Missing required parameter: id'))
    }
    path = path.replace('{id}', `${parameters['id']}`)
    if (parameters.$queryParameters) {
      Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
        queryParameters[parameterName] = parameters.$queryParameters[parameterName]
      });
    }
    const r = generateRequest('put', path, body, queryParameters, form, config)
    return $axios.$put(r.url, r.opts, r.config)
  },
})


export default {
  admin,
  alert,
  apiCredential,
  authoringGeneration,
  authorization,
  breadcrumb,
  calendar,
  course,
  deliverable,
  edtakeDocument,
  favoriteEdtaker,
  inokufu,
  integration,
  learningActivity,
  learningContent,
  learningObject,
  lms,
  mechanism,
  mission,
  modalityRecommandations,
  module,
  objectRepository,
  phase,
  pinned,
  profile,
  project,
  projectItem,
  proposal,
  publicLink,
  remoteConfig,
  sequence,
  settings,
  snapshot,
  stats,
  storage,
  stripe,
  task,
  taxonomy,
  team,
  template,
  tiers,
  tutorialTracking,
  uploadCDN,
  user,
  workspace,
  workspaceCustomer,
}