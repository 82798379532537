import _ from 'lodash'
let ctx

const getAppNameFromPath = (path) => {
  if (path.match(/(\/ld)/gi)) { return 'ld' }
  if (path.match(/(\/pm)/gi)) { return 'pm' }
  if (path.match(/(\/mp)/gi)) { return 'mp' }

  return undefined
}

const getPageNameFromPath = (path) => {
  if (path === 'index') { return 'home' }
  if (path === 'w-workspace') { return 'dashboard' }
  if (path.match(/proposal/gi)) { return 'proposal' }

  return undefined
}

const getPageNameFromRoutePath = (route, last = true) => {
  const matches = route.match(/mechanism|course|module|sequence|project|customer|deliverable|task|mission|proposal|favorite-edtaker/gi)
  return matches ? matches.at(last ? -1 : 0).toLowerCase() : undefined
}

const pageNolink = [
  'deliverable',
  'task'
]

const calculateRoutes = async ($route, $edtake) => {
  const routesObject = []
  if ($route.name.startsWith('extends-')) {
    // 1st part
    const text = getPageNameFromRoutePath($route.fullPath, false)
    routesObject.push({ text, link: `${window.location.pathname.split(text)[0]}${text}` })
    const chunks = _.chunk($route.params.pathMatch.slice(3).split('/'), 2)
    const lastObject = getPageNameFromRoutePath($route.fullPath)
    const chunksLabel = chunks.map(c => ({ type: c[0], id: c[1] }))
    const breadcrumbLabels = await $edtake.breadcrumb.getElements({ body: { elements: [...chunksLabel, { type: lastObject, id: $route.params.id }] } })
    for (const chunk of chunks) {
      routesObject.push({ text: breadcrumbLabels.find(b => b.id === chunk[1]).label, link: `${window.location.pathname.split(chunk[1])[0]}${chunk[1]}` })
    }
    routesObject.push({ text: breadcrumbLabels.find(b => b.id === $route.params.id).label, link: `${window.location.pathname.split($route.params.id)[0]}${$route.params.id}` })
  } else if ($route.name.endsWith('-id')) {
    // 1st part
    const text = getPageNameFromRoutePath($route.fullPath, false)
    routesObject.push({ text, link: `${window.location.pathname.split(text)[0]}${text}` })
    // 2nd part
    const object = getPageNameFromRoutePath($route.fullPath)
    const breadcrumbLabels = await $edtake.breadcrumb.getElements({ body: { elements: [{ type: object, id: $route.params.id }] } })
    routesObject.push({ text: breadcrumbLabels.find(b => b.id === $route.params.id).label, link: `${window.location.pathname.split($route.params.id)[0]}${$route.params.id}` })
  } else {
    const appName = getAppNameFromPath($route.fullPath)
    if (appName) {
      const text = getPageNameFromRoutePath($route.fullPath)
      routesObject.push({ text, link: `${window.location.pathname.split(text)[0]}${text}` })
    } else {
      const pageName = getPageNameFromPath($route.name)
      if (pageName) {
        routesObject.push({ text: pageName, link: window.location.pathname })
      }
    }
  }
  return routesObject
}

const calculateApp = ($route) => {
  return getAppNameFromPath($route.fullPath)
}

const navigate = ({ $route, $router, workspaceId, targetApp, targetPath }) => {
  const currentAppName = getAppNameFromPath($route.fullPath)
  if (currentAppName === targetApp && $route.path.slice(-24).match(/^[0-9a-fA-F]{24}$/)) {
    $router.push(`${$route.path}${targetPath}`)
  } else {
    $router.push(`/${ctx.$enums.routePrefix.workspace}/${workspaceId}/${targetApp}${targetPath}`)
  }
}

const edtkRouter = {
  calculateApp,
  calculateRoutes,
  getPageNameFromRoutePath,
  pageNolink,
  navigate
}

export default (context, inject) => {
  ctx = context
  inject('edtkRouter', edtkRouter)
}
