/* eslint-disable no-console */
/* eslint-disable camelcase */

export const state = () => ({
  teams: [{
    members: [
      {
        workload: Number,
        isTeamLeader: Boolean,
        user: {}
      }
    ],
    avatar: String,
    name: String,
    description: String
  }]
})

export const getters = {
  get (state) {
    return state
  },
  getTeams: (state) => {
    return state.teams
  },
  managedTeams: (state, getters, rootState, rootGetters) => {
    const user = rootGetters['user/get']
    const admin = rootGetters['user/isAdmin']
    if (!state.teams || !user?._id) { return [] }
    if (admin) { return state.teams }
    return state.teams.filter((team) => {
      return team.members.find(member => member.user._id === user._id && member.teamLeader === true)
    })
  }
}

export const actions = {

  fetch ({ commit, rootGetters }, { $queryParameters = {} } = { $queryParameters: { populateChildrenLite: true } }) {
    const workspaceId = rootGetters['workspace/getCurrentId']
    const workspace = rootGetters['workspace/getWorkspace']
    const user = rootGetters['user/get']
    return new Promise((resolve, reject) => {
      this.$edtake.team.get({ $queryParameters: { workspaceId } })
        .then((d) => {
          const findUser = this.$_.find(workspace.members, (member) => { return user._id === member.user._id })
          if (findUser && findUser.superUser === false && this.$userRightsHelper.getUserRoleByWorkspace() !== 'admin') {
            const filteredTeam = []
            this.$_.forEach(d, (team) => {
              this.$_.filter(team.members, (members) => {
                if (members.user._id === user._id && members.teamLeader === true) {
                  filteredTeam.push(team)
                }
              })
            })
            d = filteredTeam
          }
          commit('SET_TEAMS', d)
          resolve(d)
        })
        .catch((err) => {
          console.error('ERROR', err)
          reject(err)
        })
    })
  },
  fetchOne ({ commit }, { id, $queryParameters = { populateChildrenLite: true } }) {
    return new Promise((resolve, reject) => {
      this.$edtake.team.getOne({ id, $queryParameters })
        .then((d) => {
          commit('SET_TEAM', d)
          resolve(d)
        })
        .catch((err) => {
          console.error('ERROR', err)
          reject(err)
        })
    })
  },
  clear ({ commit }) {
    commit('CLEAR')
  },
  update ({ commit }, { id, body }) {
    return new Promise((resolve, reject) => {
      this.$edtake.team.update({
        id,
        body
      })
        .then((d) => {
          commit('SET_TEAM', d)
          resolve(d)
        })
        .catch((err) => {
          console.error('ERROR', err)
          reject(err)
        })
    })
  },
  remove ({ commit }, { id, reassignTarget = undefined }) {
    return new Promise((resolve, reject) => {
      this.$edtake.team.delete({
        id,
        $queryParameters: { reassignTarget }
      }).then((d) => {
        commit('REMOVE_TEAM', id)
        resolve(d)
      })
        .catch((err) => {
          console.error('ERROR', err)
          reject(err)
        })
    })
  },
  create ({ rootGetters, commit }, { body }) {
    const workspace = rootGetters['workspace/getCurrentId']
    body.workspace = workspace
    return new Promise((resolve, reject) => {
      this.$edtake.team.create({
        body,
        $queryParameters: { populateChildrenLite: true }
      }).then((d) => {
        commit('CREATE_TEAM', d)
        this.$evt.log('TeamCreation')
        resolve(d)
      })
        .catch((err) => {
          console.error('ERROR', err)
          reject(err)
        })
    })
  }
}

export const mutations = {
  SET_TEAMS (state, value) {
    state.teams = value
  },
  SET_TEAM (state, value) {
    const team = state.teams.find(team => team._id.toString() === value._id.toString())
    if (team) { Object.assign(team, value) }
  },
  REMOVE_TEAM (state, value) {
    state.teams = state.teams.filter(team => team._id !== value)
  },
  CREATE_TEAM (state, value) {
    state.teams = [...state.teams, value]
  },
  CLEAR (state, value) {
    state.teams = []
  }
}
