<template>
  <CanvasEditor
    :element-to-edit="elementToEdit"
    :storyboard="storyboard"
    :scene-to-edit="sceneToEdit"
    :update-catalog="updateCatalog"
    @save="editSceneImage"
    v-on="$listeners"
  />
</template>

<script>
import CanvasEditor from './components/Editor'
export default {
  components: { CanvasEditor },
  props: {
    sceneToEdit: {
      type: Object,
      default: () => {}
    },
    storyboard: {
      type: Object,
      default: () => {}
    },
    updateCatalog: {
      type: Function,
      default: () => () => {}
    }
  },
  computed: {
    elementToEdit () {
      return { type: 'scene', canvas: this.sceneToEdit.properties.canvas }
    }
  },
  methods: {
    editSceneImage (payload) {
      this.$emit('editSceneProperty', { property: 'canvas', data: payload })
    }
  }
}
</script>
