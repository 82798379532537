export default async function ({ isHMR, app, store, route, params, error, redirect }) {
  const defaultLocale = app.i18n.fallbackLocale
  // If middleware is called from hot module replacement, ignore it
  if (isHMR) { return }
  const locale = route.query.lang || app.i18n.locale || window.navigator.language || defaultLocale
  if (!store.state.user.locales.includes(locale)) {
    return error({ message: 'This page could not be found.', statusCode: 404 })
  }

  if (store.state.user.locale !== locale) {
    await store.dispatch('user/updateProfile', { lang: locale })
  }
  // Mutate the store's locale once we understand which locale is being requested prior to each page render
  store.dispatch('configuration/init')
  // Set locale from the query string '?lang='**''
  app.i18n.locale = store.state.user.locale
  app.$dayjs.locale(store.state.user.locale)
  // Mutate the store's locale once we understand which locale is being requested prior to each page render
}
