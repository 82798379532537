export default function ({ store, redirect, route, $enums, $nuxt, $userRightsHelper }) {
  // we are splitting the route path to get user location
  // route.path = /w/:workspace/:application/:objectType/:objectId
  const routeArgs = route.path.split('/')
  const application = routeArgs[3]
  // We reroute the user to app landing page if he tries to access an object he doesn't have the feature activated for
  // ex: /w/:workspace/ld/course/:courseId => /w/:workspace/ld/course if the user does not have LD license activated
  if (application === 'ld' && routeArgs[5]) {
    if (!$userRightsHelper.hasFeatureAccess('learningDesign')) {
      return redirect(`/${routeArgs[1]}/${routeArgs[2]}/${routeArgs[3]}/${routeArgs[4]}`)
    }
  } else if (application === 'pm' && routeArgs[5]) {
    if (!$userRightsHelper.hasFeatureAccess('projectManagement')) {
      return redirect(`/${routeArgs[1]}/${routeArgs[2]}/${routeArgs[3]}/${routeArgs[4]}`)
    }
  } else if (application === 'mp' && routeArgs[5]) {
    if (!$userRightsHelper.hasFeatureAccess('mission')) {
      return redirect(`/${routeArgs[1]}/${routeArgs[2]}/${routeArgs[3]}/${routeArgs[4]}`)
    }
  }
}
