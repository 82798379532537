<template>
  <v-popover
    :disabled="!(allowEdition&&currentStatus)"
    trigger="manual"
    :open="isOpen"
    placement="bottom-right"
  >
    <v-tooltip v-if="bullet" top @click="allowEdition?isOpen=!isOpen:false">
      <template #activator="{ on, attrs }">
        <span
          :style="`width: 8px;height: 8px;border-radius: 2px;background: #FF0000;`"
          class="mr-1"
          v-bind="attrs"
          v-on="on"
          @click="allowEdition?isOpen=!isOpen:false"
        ></span>
      </template>
      {{ currentStatus.label }}
    </v-tooltip>
    <v-chip
      v-else
      label
      :color="currentStatus.color"
      class="font-weight-bold tooltip-target"
      :class="{pointer:allowEdition}"
      :x-small="xSmall"
      :small="!xSmall"
      outlined
      elevation="0"
      @click="allowEdition?isOpen=!isOpen:false"
    >
      {{ currentStatus.label }}
      <v-avatar
        v-if="allowEdition"
        right
        class="pointer"
        @click.stop="currentStatus.next.length > 1 ? isOpen=!isOpen : setStatus(currentStatus.next[0])"
      >
        <v-icon :small="xSmall">
          mdi-chevron-right
        </v-icon>
      </v-avatar>
    </v-chip>

    <template slot="popover">
      <v-list v-if="isOpen">
        <v-list-item
          v-for="(s, $index) in currentStatus.next"
          :key="$index"
          :active-class="`bg-${statusColor(s)}-25`"
          :input-value="s === status"
          data-test="test"
          @click="setStatus(s)"
        >
          <v-list-item-content class="px-2">
            <v-list-item-title :style="`color: ${statusColor(s)}`">
              <span v-if="isLearningObjectType">
                {{ $t(`learningObject.status.${s}`) }}
              </span>
              <span v-else>
                {{ type === 'task' ? $t(`task.status.${s}`) : s }}
              </span>
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </template>
  </v-popover>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import i18n from './i18n.js'
export default {
  name: 'StatusButton',
  props: {
    object: {
      type: Object
    },
    editable: {
      type: Boolean,
      required: false,
      default: false
    },
    small: {
      type: Boolean,
      required: false,
      default: false
    },
    xSmall: {
      type: Boolean,
      required: false,
      default: false
    },
    bullet: {
      type: Boolean,
      required: false,
      default: false
    },
    type: {
      type: String,
      enums: ['deliverable', 'course', 'module', 'project', 'task', 'mission', 'sequence', 'mechanism'],
      required: true
    },
    status: {
      type: String,
      required: true
    }
  },
  i18n,
  data () {
    return {
      isOpen: false,
      availableTypes: ['project', 'deliverable', 'task', 'course', 'module', 'sequence', 'mechanism'],
      learningObjectTypes: ['mechanism', 'course', 'module', 'sequence'],
      objectId: this.object ? this.object._id : null,
      currentStatus: { color: '#333333', label: '', next: [], raci: [] },
      currentWorspaceStatus: [],
      allStatuses: [],
      currentWorspaceStatusLabelByType: []
    }
  },
  computed: {
    allowEdition () {
      /* TODO OBJECT ID */
      return this.$nuxt.$userRightsHelper.userHasRoleOnItem(this.object, this.currentStatus.raci) && this.editable
    },
    isLearningObjectType () {
      return this.learningObjectTypes.includes(this.type)
    }
  },
  watch: {
    object: {
      deep: true,
      handler () {
        this.init()
      }
    }
  },
  beforeMount () {
    this.init()
  },
  methods: {
    ...mapActions({
      deliverableSetStatus: 'deliverable/setStatus',
      projectSetStatus: 'project/setStatus',
      moduleSetStatus: 'module/setStatus',
      courseSetStatus: 'course/setStatus',
      sequenceSetStatus: 'sequence/setStatus',
      taskSetStatus: 'task/setStatus',
      mechanismSetStatus: 'mechanism/setStatus'
    }),
    workspaceStatus (t, s) {
      return this.$nuxt.$store.getters['settings/getCurrentStatus'](t, s)
    },
    workspaceStatusLabelByType (t) {
      return this.$nuxt.$store.getters['settings/getCurrentStatusLabelByType'](t)
    },
    init () {
      if (this.isLearningObjectType) {
        this.currentStatus = {
          color: this.$enums.learningObject.statusColor[this.object.status],
          label: this.$t(`learningObject.status.${this.object.status}`),
          next: this.$enums.learningObject.nextStatus[this.object.status],
          raci: ['responsible', 'accountable']
        }
      } else {
        this.currentWorspaceStatus = this.workspaceStatus(this.type, this.status)
        this.allStatuses = this.workspaceStatus(this.type)
        this.currentWorspaceStatusLabelByType = this.workspaceStatusLabelByType(this.type)
        if (this.status === 'ARCHIVE') {
          this.currentStatus = { color: '#333333', label: this.$t('ARCHIVE'), next: [], raci: [] }
        } else if (this.availableTypes.includes(this.type)) {
          const statusLabels = this.currentWorspaceStatusLabelByType
          this.currentStatus = this.currentWorspaceStatus || { color: '#333333', label: 'error', next: statusLabels, raci: [] }
          if (this.type === 'task') {
            this.currentStatus.label = this.$t(`${this.type}.status.${this.currentStatus.label}`)
          }
        } else {
          this.currentStatus = { color: '#333333', label: this.$t(`${this.type}.status.${this.status}`), next: [], raci: [] }
        }
      }
    },
    statusColor (s) {
      const r = this.allStatuses.find(x => x.label.toLowerCase() === s.toLowerCase())
      return r ? r.color || '#333333' : '#333333'
    },
    setStatus (s) {
      const self = this
      let status
      if (this.isLearningObjectType) {
        status = this.$t(`learningObject.status.${s}`)
      } else {
        status = this.type === 'task' ? this.$t(`task.status.${s}`) : s
      }
      this.$swal({
        icon: 'question',
        title: '',
        html: self.$t('alert.changeStatus.' + self.type, { status }),
        showCancelButton: true,
        confirmButtonColor: '#4bca81',
        cancelButtonColor: '#c2c5cb',
        confirmButtonText: self.$t('alert.confirm'),
        cancelButtonText: self.$t('alert.cancel')
      }).then(async (r) => {
        if (r.value) {
          switch (self.type) {
            case 'deliverable':
              await self.deliverableSetStatus({ id: self.objectId, status: s })
              break
            case 'project':
              await self.projectSetStatus({ id: self.objectId, status: s })
              break
            case 'module':
              await self.moduleSetStatus({ id: self.objectId, status: s })
              break
            case 'course':
              await self.courseSetStatus({ id: self.objectId, status: s })
              break
            case 'sequence':
              await self.sequenceSetStatus({ id: self.objectId, status: s })
              break
            case 'task':
              await self.taskSetStatus({ id: self.objectId, status: s })
              break
            case 'mechanism':
              await self.mechanismSetStatus({ id: self.objectId, status: s })
              break
            default:
              break
          }
        }
      })
      this.isOpen = false
    }
  }
}
</script>
