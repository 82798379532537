const i18n = {
  messages: {
    fr: {
      trial: 'Votre période d\'essai se termine dans {days} jours',
      accessBilling: 'Gérer votre abonnement'
    },
    en: {
      trial: 'Your trial period ends in {days} days',
      accessBilling: 'Manage your subscription'
    }
  }
}

module.exports = i18n
