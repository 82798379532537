/* eslint-disable no-console */
/* eslint-disable camelcase */
import _ from 'lodash'

export const state = () => ({
  phases: []
})

export const getters = {
  get: (state, getters, rootState, rootGetters) => {
    const wsId = rootGetters['workspace/getCurrentId']
    return wsId ? _.filter(state.phases, (p) => { return p.workspace === wsId }) : state.phases
  },
  getRootProjects: (state, rootGetters) => {
    const wsId = rootGetters['workspace/getCurrentId']
    const p = wsId ? _.filter(state.phases, (p) => { return p.workspace === wsId }) : state.phases
    const allIds = _.map(p, o => o._id)
    return _.filter(p, (o) => { return !o.parentProject || !o.parentProject._id || (o.parentProject._id && !allIds.includes(o.parentProject._id)) })
  },
  inProgress: (state, getters, rootState, rootGetters) => {
    const currentStatuses = rootGetters['settings/getCurrentStatusByType']('phase')
    const phases = getters.getProjects
    return _.filter(phases, (o) => {
      const wsStatus = _.find(currentStatuses, x => x.label === o.status)
      return wsStatus?.isDone
    })
  },
  getOne: state => (id) => {
    return _.find(state.phases, (p) => { return p._id === id })
  }
}

export const actions = {
  clear ({ commit }) {
    commit('CLEAR')
  },
  fetch ({ state, dispatch, commit }, { $queryParameters = {} } = { $queryParameters: { populate: 'children,documents' } }) {
    return new Promise((resolve, reject) => {
      this.$edtake.phase.get({ $queryParameters })
        .then((d) => {
          commit('SET', d)
          if (d.children) {
            dispatch('projectItem/emitUpdateMutation', { arr: d.children }, { root: true })
          }
          resolve(d)
        })
        .catch((err) => {
          console.error('ERROR', err)
          reject(err)
        })
    })
  },
  fetchOne ({ state, commit, dispatch }, { id, $queryParameters = { populate: 'children,documents' } }) {
    return new Promise((resolve, reject) => {
      this.$edtake.phase.getOne({ id, $queryParameters })
        .then((d) => {
          commit('UPDATE_ONE', d)
          if (d.children) {
            dispatch('projectItem/emitUpdateMutation', { arr: d.children }, { root: true })
          }
          resolve(d)
        })
        .catch((err) => {
          console.error('ERROR', err)
          reject(err)
        })
    })
  },
  createProject ({ dispatch, commit, rootGetters }, { body, templateId, keepMembersTemplate }) {
    return new Promise((resolve, reject) => {
      this.$edtake.phase.createProject({
        templateId: templateId || '',
        $queryParameters: {
          keepMembers: !!keepMembersTemplate
        },
        body
      }).then((d) => {
        dispatch('_created', d)
        dispatch('_createdChildren', d)
        this.$evt.log('ProjectCreation', { type: body.creationType, fromTemplate: !!templateId, keepMembersTemplate })
        resolve(d)
      })
        .catch((err) => {
          reject(err)
        })
    })
  },
  update ({ dispatch, commit }, { id, body }) {
    return new Promise((resolve, reject) => {
      this.$edtake.phase.update({
        id,
        body
      })
        .then((d) => {
          commit('UPDATE_ONE', d)
          if (d.parentProject) {
            dispatch(`${d.parentProject.__t}/_updatedChildren`, d, { root: true })
          }
          resolve(d)
        })
        .catch((err) => {
          console.error('ERROR', err)
          reject(err)
        })
    })
  },
  reschedule ({ dispatch, commit }, { id, rescheduleCompleteProject, refDate, refDateValue }) {
    return new Promise((resolve, reject) => {
      this.$edtake.phase.reschedule({ id, body: { rescheduleCompleteProject, refDate, refDateValue } })
        .then(async (d) => {
          await dispatch('projectItem/emitUpdateMutation', { arr: d }, { root: true })
          this.$evt.log('ProjectReschedule')
          resolve(d)
        })
        .catch((err) => {
          console.error('ERROR', err)
          reject(err)
        })
    })
  },
  fetchChildren ({ state, commit, dispatch }, { id }) {
    return new Promise((resolve, reject) => {
      this.$edtake.phase.getChildren({ id })
        .then(async (d) => {
          await dispatch('projectItem/emitUpdateMutation', { arr: d }, { root: true })
          resolve(d)
        })
        .catch((err) => {
          console.error('ERROR', err)
          reject(err)
        })
    })
  },
  setStatus ({ commit, dispatch }, { id, status }) {
    return new Promise((resolve, reject) => {
      this.$edtake.phase.setStatus({
        id,
        body: { status }
      })
        .then((d) => {
          commit('UPDATE_ONE', d)
          if (d.parentProject) {
            dispatch(`${d.parentProject.__t}/_updatedChildren`, d, { root: true })
          }
          resolve(d)
        })
        .catch((err) => {
          console.error('ERROR', err)
          reject(err)
        })
    })
  },
  remove ({ dispatch, commit }, { id, item }) {
    return new Promise((resolve, reject) => {
      this.$edtake.phase.delete({ id })
        .then((d) => {
          commit('REMOVE', d)
          if (item.learningObject) {
            dispatch(`${item.learningObject.__t}/_removeProject`, item, { root: true })
          }
          resolve(d)
        })
        .catch((err) => {
          if (err.response && err.response.status === 403) {
            reject('NOT_AUTHORIZED')
          }
          reject(err)
        })
    })
  },
  archive ({ dispatch }, { id }) {
    return new Promise((resolve, reject) => {
      this.$edtake.phase.archive({ id })
        .then((d) => {
          this.$evt.log('ProjectArchive')
          resolve(d)
        })
        .catch((err) => {
          if (err.response.status === 403) {
            reject('NOT_AUTHORIZED')
          }
          reject(err)
        })
    })
  },
  toTemplate ({ dispatch, commit, rootGetters }, { id, body }) {
    const workspace = rootGetters['workspace/getCurrentId']
    body.workspace = workspace
    return new Promise((resolve, reject) => {
      this.$edtake.phase.toTemplate({
        id,
        body
      }).then((d) => {
        dispatch('template/_update', d, { root: true })
        this.$evt.log('ProjectToTemplate')
        resolve(d)
      }).catch((err) => {
        console.error('ERROR', err)
        reject(err)
      })
    })
  },
  fetchEstimations ({ commit }, { id }) {
    return new Promise((resolve, reject) => {
      this.$edtake.phase.getProjectAvgEstimations({ id })
        .then((d) => {
          resolve(d)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  fetchMeeting ({ commit }, { meetingType, id }) {
    return new Promise((resolve, reject) => {
      this.$edtake.phase[`get${meetingType}Meeting`]({ id })
        .then((d) => {
          this.$evt.log('ProjectMeeting', { type: meetingType })
          resolve(d)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  setMembers ({ commit }, d) {
    commit('SET_MEMBERS', d)
  },
  /* Dispatch from other store */
  _addDocument ({ commit }, d) {
    commit('ADD_DOCUMENT', d)
  },
  _removeDocument ({ commit }, d) {
    commit('REMOVE_DOCUMENT', d)
  },
  _createdChildren ({ commit }, d) {
    if (Array.isArray(d)) {
      _.forEach(d, e => commit('CREATED_CHILDREN', e))
    } else {
      commit('CREATED_CHILDREN', d)
    }
  },
  _created ({ commit }, d) {
    if (Array.isArray(d)) {
      _.forEach(d, e => commit('CREATE', e))
    } else {
      commit('CREATE', d)
    }
  },
  _update ({ commit }, d) {
    if (Array.isArray(d)) {
      _.forEach(d, e => commit('UPDATE_ONE', e))
    } else {
      commit('UPDATE_ONE', d)
    }
  },
  _updatedChildren ({ commit }, d) {
    if (Array.isArray(d)) {
      _.forEach(d, e => commit('UPDATED_CHILDREN', e))
    } else {
      commit('UPDATED_CHILDREN', d)
    }
  },
  _fetchIfNotExist ({ state, dispatch }, a) {
    const alreadyExist = a.every(d => state.phases.some(s => s._id === d._id))
    if (!alreadyExist) {
      dispatch('fetch')
    }
  }
}

export const mutations = {
  SET (state, value) {
    state.phases = value
  },
  SET_MEMBERS (state, value) {
    _.forEach(value.ids, (id, $index) => {
      const item = _.find(state.phases, item => item._id.toString() === id.toString())
      if (item) {
        Object.assign(item, { ...item, members: value.newObj[$index].members })
        state.phases = [...state.phases]
      }
    })
  },
  UPDATE_ONE (state, value) {
    const item = _.find(state.phases, item => item._id.toString() === value._id.toString())
    if (item) {
      Object.assign(item, value)
      state.phases = [...state.phases] // Force refresh
    } else {
      state.phases = [...state.phases, value]
    }
  },
  UPDATE_MANY (state, arr) {
    const expectedIds = _.map(arr, o => o._id)
    const filteredState = _.filter(state.phases, o => !expectedIds.includes(o._id))
    arr = _.map(arr, (updatedPI) => {
      let item = state.phases.find(item => (item._id && item._id.toString()) === (updatedPI._id && updatedPI._id.toString()))
      if (item) {
        Object.assign(item, updatedPI)
      } else {
        item = updatedPI
      }
      return item
    })
    state.phases = [...filteredState, ...arr] // Force refresh
  },
  REMOVE (state, arr) {
    state.phases = state.phases.filter((phase) => {
      return !arr.includes(phase._id)
    })
  },
  CREATE (state, value) {
    state.phases = [...state.phases, value]
  },
  CREATED_CHILDREN (state, value) {
    if (value.parentProject) {
      const item = _.find(state.phases, (item) => {
        const parentProjectId = value.parentProject._id ? value.parentProject._id : value.parentProject
        return (item._id.toString() === parentProjectId.toString())
      })
      if (item) {
        item.children = [...item.children, value]
      }
    }
  },
  UPDATED_CHILDREN (state, value) {
    if (value.parentProject) {
      const item = _.find(state.phases, item => item._id.toString() === value.parentProject._id.toString())
      if (item) {
        const children = _.find(item.children, child => child._id.toString() === value._id.toString())
        if (children) {
          Object.assign(children, value)
          state.phases = [...state.phases]
        }
      }
    }
  },
  CLEAR (state) {
    state.phases = []
  },
  ADD_DOCUMENT (state, value) {
    const item = _.find(state.phases, item => item._id.toString() === value.parent._id.toString())
    if (item) {
      item.documents = [...item.documents, value]
    }
  },
  REMOVE_DOCUMENT (state, value) {
    const item = _.find(state.phases, item => item._id.toString() === value.parent._id.toString())
    if (item) {
      item.documents = item.documents.filter(d => d._id !== value._id)
    }
  }
}
