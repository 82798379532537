<template lang="html">
  <v-app>
    <Maintenance />
    <v-main data-layout="settings">
      <FormErrorDialog />
      <transition
        name="nuxtTransition"
        appear
        appear-active-class="component-enter-active"
        leave-active-class="component-leave-active"
      >
        <nuxt />
      </transition>
      <Snackbar />
      <ApplicationUpdate />
      <ProposeChangePassword />
      <DisableSmallDevices />
      </div>
    </v-main>
  </v-app>
</template>

<script lang="js">
import { mapGetters } from 'vuex'
import ApplicationUpdate from '~/components/applicationUpdate'
import ProposeChangePassword from '~/components/common/ProposeChangePassword'
import Maintenance from '~/components/common/Maintenance'

export default {
  name: 'SettingsLayout',
  components: {
    ApplicationUpdate,
    ProposeChangePassword,
    Maintenance
  },
  data () {
    return {
      socket: null,
      socketStatus: {}
    }
  },
  head () {
    return {
      title: this.$nuxt.$te(`headTitle.${this.$nuxt.$route.name}`) ? this.$nuxt.$t(`headTitle.${this.$nuxt.$route.name}`) : 'edtake'
    }
  },
  computed: {
    ...mapGetters({
    })
  },
  mounted () {
    this.$socketManager.getSocket('edtake')
    this.$hotkeys(this.$t('hotkeys.creationBox'), (event) => {
      event.preventDefault()
      this.$nuxt.$emit('open-creationBox')
    })
    this.$hotkeys(this.$t('hotkeys.home'), (event) => {
      event.preventDefault()
      this.$router.push(`/${this.$enums.routePrefix.workspace}/${this.$route.params.workspace}`)
    })
  }
}
</script>
