const i18n = {
  messages: {
    fr: {
      search: 'Rechercher',
      display: 'Afficher',
      noData: 'Aucune donnée',
      percentage: ' Pourcentage de réussite',
      tOption: {
        all: 'Tous',
        course: 'Parcours',
        module: 'Module',
        sequence: 'Séquence',
        objectives: 'Objectifs',
        themes: 'Thématiques',
        mechanism: 'Dispositif'
      },
      durationOfLearningObject: {
        course: 'Durée du parcours',
        module: 'Durée du module',
        sequence: 'Durée de la séquence'
      },
      description: 'Description'
    },
    en: {
      search: 'Search',
      display: 'Display',
      noData: 'No data',
      percentage: 'Success percentage ',
      tOption: {
        all: 'All',
        course: 'Course',
        module: 'Module',
        sequence: 'Sequence',
        objectives: 'Goals',
        themes: 'Thematics',
        mechanism: 'Mechanism'
      },
      durationOfLearningObject: {
        course: 'Duration of course',
        module: 'Duration of module',
        sequence: 'Duration of sequence'
      },
      description: 'Description'

    }
  }
}
module.exports = i18n
