/* eslint-disable no-console */
/* eslint-disable camelcase */

export const state = () => ({
  tutorials: []
})

export const getters = {
  get (state) {
    return state.tutorials
  },
  getOne: state => (id) => {
    return state.tutorials.find(tutorial => tutorial._id === id)
  }
}

export const actions = {
  clear ({ commit }) {
    commit('CLEAR')
  },
  getByUser ({ commit }) {
    return new Promise((resolve, reject) => {
      this.$edtake.tutorialTracking.getByUser()
        .then((d) => {
          commit('SET', d)
          resolve(d)
        })
        .catch((err) => {
          console.error('ERROR', err)
        })
    })
  },
  complete ({ commit }, { body }) {
    return new Promise((resolve, reject) => {
      this.$edtake.tutorialTracking.complete({ body })
        .then((d) => {
          commit('SET', d)
          resolve(d)
        })
        .catch((err) => {
          console.error('ERROR', err)
        })
    })
  }
}

export const mutations = {
  SET (state, value) {
    state.tutorials = value
  },
  CLEAR (state, value) {
    state.tutorials = []
  }
}
