<template>
  <div>
    <CanvasToolbar
      ref="toolbar"
      :type-of-shape-to-edit="typeOfShapeToEdit"
      :selected-shape="selectedShape"
      @addShape="addShape"
      @openAssetEditor="openAssetEditor"
      @deleteShape="deleteShape"
      @updateShape="updateShape"
      @expandLibrary="expandLibrary"
      @stageZoomIn="stageZoomIn"
      @stageZoomOut="stageZoomOut"
      @moveUp="moveUp"
      @moveDown="moveDown"
      @increaseStrokeWidth="increaseStrokeWidth"
      @decreaseStrokeWidth="decreaseStrokeWidth"
      @save="save"
      @closeStage="closeStage"
    />
    <v-row id="stage-parent" :class="[elementToEdit.type === 'template' ? 'layout-template' : 'layout-scene']">
      <Library
        :expand="expand"
        :storyboard="storyboard"
        :type-to-edit="elementToEdit.type"
        :scene-to-edit="sceneToEdit"
        :new-order="newOrder"
        @addImage="addImage"
        @expandLibrary="expandLibrary"
        @applyTemplate="applyTemplate"
      />
      <v-stage
        id="stage"
        ref="stage"
        :config="configKonva"
        :style="{transform: 'scale('+ zoom +')'}"
        class="elevation-6 mx-auto my-auto"
        @mousedown="handleStageMouseDown"
        @touchstart="handleStageMouseDown"
        @dblclick="handleDisplayTextarea"
      >
        <v-layer ref="layer">
          <VDraw
            v-for="(item, $index) in shapesListToDisplay"
            :key="item.config.name"
            :item="shapesListToDisplay[$index]"
            :catalog="storyboard.catalog"
            @editShapePosition="editShapePosition"
            @transformShape="transformShape"
          />
          <v-transformer v-if="typeOfShapeToEdit !== 'text'" ref="transformer" :config="{ignoreStroke:true}" />
          <v-transformer
            v-if="typeOfShapeToEdit === 'text'"
            ref="transformer"
            :config="{
              enabledAnchors: ['middle-left', 'middle-right'],
              boundBoxFunc: (oldBox, newBox) => {
                newBox.width = Math.max(100, newBox.width)
                return newBox
              }
            }"
          />
        </v-layer>
      </v-stage>
    </v-row>
    <AssetEditor ref="assetEditor" :catalog="storyboard.catalog" :update-catalog="updateCatalog" @saveAsset="saveNewAsset" />
  </div>
</template>

<script>
import Vue from 'vue'
import VueKonva from 'vue-konva'
import _ from 'lodash'
import ObjectID from 'bson-objectid'
import AssetEditor from '../../AssetEditor'
import VDraw from './VDraw'
import CanvasToolbar from './Toolbar'
import Library from './Library'

Vue.use(VueKonva)

export default {
  components: { VDraw, CanvasToolbar, AssetEditor, Library },
  props: {
    storyboard: {
      type: Object,
      default: () => {}
    },
    elementToEdit: {
      type: Object,
      default: () => {}
    },
    sceneToEdit: {
      type: Object,
      default: () => {}
    },
    updateCatalog: {
      type: Function,
      default: () => () => {}
    }
  },
  data () {
    return {
      shapesList: [],
      initialPayload: [],
      selectedShape: { shapeId: undefined, config: {} },
      selectedShapeToCopy: undefined,
      typeOfShapeToEdit: undefined,
      zoom: 1,
      baseHeight: 550,
      baseWidth: 780,
      configKonva: {
        width: 780,
        height: 550,
        scale: { x: 1, y: 1 }
      },
      text: '',
      expand: true,
      squareFillColor: '#E3E3E3',
      circleFillColor: '#E3E3E3'
    }
  },
  i18n: {
    messages: {
      fr: {
        dialog: {
          title: 'Des modifications ont été apportées au visuel, souhaitez-vous les sauvegarder ?',
          save: 'Sauvegarder',
          notSave: 'Ne pas sauvegarder'
        }
      },
      en: {
        dialog: {
          title: 'Do you want to save the changes?',
          save: 'Save',
          notSave: 'Do not save'
        }
      }
    }
  },
  computed: {
    shapesListToDisplay () {
      const shapes = [...this.shapesList]
      const res = shapes.sort((a, b) => {
        return a.order - b.order
      })
      return res
    },
    changesDetected () {
      return !_.isEqual(this.shapesList, this.initialPayload)
    },
    newOrder () {
      return this.shapesList.length > 0 ? this.shapesList.map(e => e.order).reduce((a, b) => Math.max(a, b)) : 0
    },
    shapeData () {
      return this.shapesList.find(s => s.config.id === this.selectedShape.shapeId)
    },
    shapeIdx () {
      return this.shapesList.findIndex(s => s.config.id === this.selectedShape.shapeId)
    }
  },
  beforeMount () {
    this.shapesList = _.cloneDeep(this.elementToEdit.canvas)
    this.initialPayload = _.cloneDeep(this.elementToEdit.canvas)
    this.configKonva.width = this.baseWidth
    this.configKonva.height = this.baseHeight
    this.squareFillColor = '#E3E3E3'
    this.circleFillColor = '#E3E3E3'
  },
  mounted () {
    this.fitStageIntoParentContainer()
    window.addEventListener('resize', this.fitStageIntoParentContainer)
    window.addEventListener('keyup', this.keyup)
  },
  destroyed () {
    window.removeEventListener('resize', this.fitStageIntoParentContainer)
    window.removeEventListener('keyup', this.keyup)
  },
  methods: {
    async keyup (e) {
      if (e.ctrlKey && e.code === 'KeyC') {
        this.selectedShapeToCopy = _.cloneDeep(this.shapeData)
      }
      if ((e.ctrlKey && e.code === 'KeyV') && this.selectedShapeToCopy) {
        const newId = ObjectID().toHexString()
        const copy = _.cloneDeep(this.selectedShapeToCopy)
        copy.order = this.newOrder + 1
        copy.config.id = newId
        copy.config.name = `${copy.type}_${newId}`
        copy.config.x = copy.config.x + 10
        copy.config.y = copy.config.y + 10

        await this.shapesList.push(copy)
        await this.saveSelectedShape(copy)
        this.updateTransformer()

        this.selectedShapeToCopy.config.x = copy.config.x + 10
        this.selectedShapeToCopy.config.y = copy.config.y + 10
      }

      if (e.code === 'Delete') {
        this.deleteShape()
      }
    },
    openAssetEditor () {
      this.$refs.assetEditor.openAssetEditor('script')
    },
    saveNewAsset (assetPayload) {
      // en cas d'import d'images, le catalogue doit être mis à jour avant d'afficher le nouvel asset
      // la value de l'image de l'asset est stockée dans le catalogue
      this.$nuxt.$emit('saveNewAsset', assetPayload)
      assetPayload.images.forEach((image) => {
        this.addImage({ image: image.value, ratio: image.ratio })
      })
      this.$refs.assetEditor.closeAssetEditor()
    },
    addShape (type) {
      const newId = ObjectID().toHexString()
      let payload // attendu par canvas pour l'affichage
      if (type === 'arrow') {
        payload = {
          order: this.newOrder + 1,
          type: 'arrow',
          config: {
            id: newId,
            name: `arrow_${newId}`,
            x: 100,
            y: 100,
            width: 100,
            height: 0,
            points: [0, 0, 100, 0],
            pointerLength: 15,
            pointerWidth: 15,
            scaleX: 1,
            scaleY: 1,
            rotation: 0,
            fill: '#000000',
            stroke: '#000000',
            strokeWidth: 1,
            strokeScaleEnabled: false,
            draggable: true
          }
        }
      } else if (type === 'circle') {
        payload = {
          order: this.newOrder + 1,
          type: 'circle',
          config: {
            id: newId,
            name: `circle_${newId}`,
            x: 100,
            y: 100,
            radius: 50,
            rotation: 0,
            fill: this.circleFillColor,
            shadowBlur: 0,
            stroke: '#000000',
            strokeWidth: 1,
            strokeScaleEnabled: false,
            draggable: true
          }
        }
      } else if (type === 'rect') {
        payload = {
          order: this.newOrder + 1,
          type: 'rect',
          config: {
            id: newId,
            name: `rect_${newId}`,
            x: 100,
            y: 100,
            width: 100,
            height: 100,
            scaleX: 1,
            scaleY: 1,
            rotation: 0,
            fill: this.squareFillColor,
            shadowBlur: 0,
            stroke: '#000000',
            strokeWidth: 1,
            strokeScaleEnabled: false,
            draggable: true
          }
        }
      } else if (type === 'text') {
        payload = {
          order: this.newOrder + 1,
          type: 'text',
          config: {
            id: newId,
            name: `text_${newId}`,
            x: 100,
            y: 100,
            width: 200,
            text: 'Votre texte ici...',
            scaleX: 1,
            scaleY: 1,
            rotation: 0,
            fontSize: 20,
            fontFamily: 'Arial',
            fontStyle: 'normal',
            textDecoration: undefined,
            align: 'center',
            fill: '#000000',
            draggable: true
          }
        }
      } else {
        payload = {
          order: this.newOrder + 1,
          type: 'custom',
          config: {
            id: newId,
            name: `${type}_${newId}`,
            customType: type,
            x: 100,
            y: 100,
            width: 100,
            height: 120,
            scaleX: 1,
            scaleY: 1,
            rotation: 0,
            fill: '#FFFFFF',
            shadowBlur: 0,
            stroke: '#000000',
            strokeWidth: 1,
            strokeScaleEnabled: false,
            draggable: true
          }
        }
      }
      this.shapesList.push(payload)
    },
    addImage ({ image, ratio }) {
      const newId = ObjectID().toHexString()
      const payload = {
        order: this.newOrder + 1,
        type: 'image',
        config: {
          id: newId,
          name: `image_${newId}`,
          image,
          x: 10,
          y: 10,
          scaleX: ratio,
          scaleY: ratio,
          rotation: 0,
          draggable: true
        }
      }
      this.shapesList.push(payload)
    },
    updateShape ({ value, property }) {
      if (this.shapeData.type === 'text' && property === 'fontColor') {
        this.shapesList[this.shapeIdx].config.fill = value
      } else if (this.shapeData.type === 'rect' && property === 'fill') {
        this.shapesList[this.shapeIdx].config.fill = value
        this.squareFillColor = value
      } else if (this.shapeData.type === 'circle' && property === 'fill') {
        this.shapesList[this.shapeIdx].config.fill = value
        this.circleFillColor = value
      } else if (this.shapeData.type === 'arrow') {
        this.shapesList[this.shapeIdx].config.fill = value
        this.shapesList[this.shapeIdx].config.stroke = value
      } else {
        this.shapesList[this.shapeIdx].config[property] = value
      }
    },
    editTextShape (shapeId) {
      const idx = this.shapesList.findIndex(s => s.config.id === shapeId)
      this.shapesList[idx].config.text = this.text
    },
    editShapePosition ({ x, y }) {
      this.shapesList[this.shapeIdx].config = Object.assign(this.shapeData.config, { x, y })
    },
    transformShape (p) {
      let newConfig
      if (p.name.startsWith('text')) {
        newConfig = { width: p.width, rotation: p.rotation, x: p.x, y: p.y }
      } else {
        newConfig = { x: p.x, y: p.y, scaleX: p.scaleX, scaleY: p.scaleY, width: p.width, height: p.height, rotation: p.rotation, offsetX: p.offsetX, offsetY: p.offsetY, skewX: p.skewX, skewY: p.skewY }
      }
      this.shapesList[this.shapeIdx].config = Object.assign(this.shapeData.config, newConfig)
    },
    increaseStrokeWidth () {
      this.shapesList[this.shapeIdx].config.strokeWidth += 1
    },
    decreaseStrokeWidth () {
      this.shapeData.config.strokeWidth > 0 ? this.shapesList[this.shapeIdx].config.strokeWidth -= 1 : this.shapesList[this.shapeIdx].config.strokeWidth = 0
    },
    getClosest ({ number, greater }) {
      const filteredArray = greater ? this.shapesList.filter(i => i.order > number) : this.shapesList.filter(i => i.order < number)
      if (filteredArray.length > 0) {
        return filteredArray.reduce((a, b) => { return Math.abs(b.order - number) < Math.abs(a.order - number) ? b : a })
      } else {
        return false
      }
    },
    async moveUp () {
      const shapeToMoveDown = await this.getClosest({ number: this.shapeData.order, greater: true })
      if (shapeToMoveDown) {
        const shapeToMoveDownIndex = this.shapesList.findIndex(e => e.config.id === shapeToMoveDown.config.id)
        this.moveShapeOrder({ shapeToMoveDownIndex, lowerOrder: this.shapeData.order, shapeToMoveUpIndex: this.shapeIdx, higherOrder: shapeToMoveDown.order })
      }
    },
    async moveDown () {
      const shapeToMoveUp = await this.getClosest({ number: this.shapeData.order, greater: false })
      if (shapeToMoveUp) {
        const shapeToMoveUpIndex = this.shapesList.findIndex(e => e.config.id === shapeToMoveUp.config.id)
        this.moveShapeOrder({ shapeToMoveDownIndex: this.shapeIdx, lowerOrder: shapeToMoveUp.order, shapeToMoveUpIndex, higherOrder: this.shapeData.order })
      }
    },
    moveShapeOrder ({ shapeToMoveDownIndex, lowerOrder, shapeToMoveUpIndex, higherOrder }) {
      this.shapesList[shapeToMoveUpIndex].order = higherOrder
      this.shapesList[shapeToMoveDownIndex].order = lowerOrder
    },
    stageZoomIn () {
      this.zoom = this.zoom < 1.5 ? this.zoom + 0.1 : 1.5
    },
    stageZoomOut () {
      this.zoom = this.zoom > 0.2 ? this.zoom - 0.1 : 0.1
    },
    fitStageIntoParentContainer () {
      const container = document.querySelector('#stage-parent')
      // now we need to fit stage into parent container
      const containerHeight = container.offsetHeight

      // but we also make the full scene visible
      // so we need to scale all objects on canvas
      const scale = containerHeight / this.baseHeight

      this.configKonva.width = this.baseWidth * scale
      this.configKonva.height = this.baseHeight * scale
      this.configKonva.scale = Object.assign({}, { x: scale, y: scale })
    },
    clearStage () {
      this.selectedShape = { shapeId: undefined, config: {} }
      this.updateTransformer()
      this.typeOfShapeToEdit = undefined
    },
    exit () {
      this.$emit('close')
    },
    async reduceImageFileSize (base64Str, MAX_WIDTH = 450, MAX_HEIGHT = 450) {
      const resizedBase64 = await new Promise((resolve) => {
        const img = new Image()
        img.src = base64Str
        img.onload = () => {
          const canvas = document.createElement('canvas')
          let width = img.width
          let height = img.height

          if (width > height) {
            if (width > MAX_WIDTH) {
              height *= MAX_WIDTH / width
              width = MAX_WIDTH
            }
          } else if (height > MAX_HEIGHT) {
            width *= MAX_HEIGHT / height
            height = MAX_HEIGHT
          }
          canvas.width = width
          canvas.height = height
          const ctx = canvas.getContext('2d')
          ctx.drawImage(img, 0, 0, width, height)
          resolve(canvas.toDataURL())
        }
      })
      return resizedBase64
    },
    async save (payload) {
      this.clearStage()
      const stage = this.$refs.stage.getStage()
      const dataURL = stage.toDataURL()
      const resized = await this.reduceImageFileSize(dataURL)
      const canvas = _.cloneDeep(this.shapesList)
      this.$emit('save', { image: resized, canvas })
      if (payload === 'exit') {
        this.exit()
      } else {
        this.initialPayload = _.cloneDeep(this.shapesList)
      }
    },
    closeStage () {
      if (this.changesDetected) {
        this.save('exit')
      } else {
        this.exit()
      }
    },
    deleteShape () {
      if (this.selectedShape.shapeId !== undefined) {
        this.shapesList = this.shapesList.filter(s => s.config.id !== this.selectedShape.shapeId)
        this.selectedShape = { shapeId: undefined, config: {} }
        this.typeOfShapeToEdit = undefined
        this.updateTransformer()
      }
    },
    applyTemplate (payload) {
      this.shapesList = this.shapesList.concat(payload)
    },
    expandLibrary () {
      this.expand = !this.expand
    },
    saveSelectedShape (shape) {
      this.selectedShape = { shapeId: shape.config.id, config: shape.config }
      if (shape.type === 'text') {
        this.typeOfShapeToEdit = 'text'
      } else if (shape.type === 'image') {
        this.typeOfShapeToEdit = 'image'
      } else {
        this.typeOfShapeToEdit = 'shape'
      }
      return true
    },
    handleDisplayTextarea (e) {
      const { shapeId } = e.target.attrs
      const shape = this.shapesList.find(s => s.config.id === shapeId)
      if (shape) {
        if (shape.type === 'text') {
          this.selectedShape = { shapeId, config: shape.config }
          this.typeOfShapeToEdit = 'text'

          const transformerNode = this.$refs.transformer.getNode()
          const stage = transformerNode.getStage()
          const selectedNode = stage.findOne('#' + this.selectedShape.shapeId)

          selectedNode.hide()
          transformerNode.hide()

          const textPosition = selectedNode.getAbsolutePosition()
          const areaPosition = {
            x: textPosition.x,
            y: textPosition.y
          }

          const textarea = document.createElement('textarea')
          document.getElementById('stage').appendChild(textarea)
          textarea.value = shape.config.text
          textarea.style.position = 'absolute'
          textarea.style.top = areaPosition.y + 'px'
          textarea.style.left = areaPosition.x + 'px'
          textarea.style.width = shape.config.width + 'px'
          textarea.style.height = shape.config.height + 5 + 'px'
          textarea.style.fontSize = shape.config.fontSize + 'px'
          textarea.style.border = '1px dashed black'
          textarea.style.padding = '0px'
          textarea.style.margin = '0px'
          textarea.style.overflow = 'hidden'
          textarea.style.background = 'none'
          textarea.style.outline = 'none'
          textarea.style.resize = 'none'
          textarea.style.lineHeight = shape.config.lineHeight
          textarea.style.fontFamily = shape.config.fontFamily
          textarea.style.fontStyle = shape.config.fontStyle
          textarea.style.fontWeight = shape.config.fontStyle
          textarea.style.transformOrigin = 'left top'
          textarea.style.textAlign = shape.config.align
          textarea.style.color = shape.config.fill
          textarea.style.textDecoration = shape.config.textDecoration
          const rotation = shape.config.rotation
          let transform = ''
          if (rotation) {
            transform += 'rotateZ(' + rotation + 'deg)'
          }
          let px = 0
          // also we need to slightly move textarea on firefox
          // because it jumps a bit
          const isFirefox = navigator.userAgent.toLowerCase().includes('firefox')
          if (isFirefox) {
            px += 2 + Math.round(shape.config.fontSize / 20)
          }
          transform += 'translateY(-' + px + 'px)'

          textarea.style.transform = transform

          // reset height
          textarea.style.height = 'auto'
          // after browsers resized it we can set actual value
          textarea.style.height = textarea.scrollHeight + 3 + 'px'

          textarea.focus()

          const removeTextarea = () => {
            textarea.parentNode.removeChild(textarea)
            window.removeEventListener('click', handleOutsideClick)
            selectedNode.show()
            transformerNode.show()
            transformerNode.forceUpdate()
          }

          const handleOutsideClick = (e) => {
            if (e.target !== textarea) {
              this.text = textarea.value
              this.editTextShape(shapeId)
              removeTextarea()
            }
          }

          const setTextareaWidth = (newWidth) => {
            if (!newWidth) {
              newWidth = shape.config.placeholder.length * shape.config.fontSize
            }
            const isSafari = /^((?!chrome|android).)*safari/i.test(
              navigator.userAgent
            )
            const isFirefox = navigator.userAgent.toLowerCase().includes('firefox')
            if (isSafari || isFirefox) {
              newWidth = Math.ceil(newWidth)
            }
            const isEdge = document.documentMode || /Edge/.test(navigator.userAgent)
            if (isEdge) {
              newWidth += 1
            }
            textarea.style.width = newWidth + 'px'
          }

          textarea.addEventListener('keydown', (e) => {
            if (e.key === 'Escape') {
              removeTextarea()
            } else {
              setTextareaWidth(shape.config.width)
              textarea.style.height = 'auto'
              textarea.style.height = textarea.scrollHeight + shape.config.fontSize + 'px'
            }
          })

          setTimeout(() => window.addEventListener('click', handleOutsideClick))
        }
      }
      this.updateTransformer()
    },
    handleStageMouseDown (e) {
      // clicked on stage - clear selection
      if (e.target === e.target.getStage()) {
        this.typeOfShapeToEdit = undefined
        this.selectedShape = { shapeId: undefined, config: {} }
        this.updateTransformer()
        return
      }

      // clicked on transformer - do nothing
      const clickedOnTransformer = e.target.getParent().className === 'Transformer'
      if (clickedOnTransformer) {
        return
      }

      // find clicked shape by its id
      const { shapeId } = e.target.attrs

      const shape = this.shapesList.find(s => s.config.id === shapeId)
      if (shape) {
        this.saveSelectedShape(shape)
      } else {
        this.selectedShape = { shapeId: undefined, config: {} }
        this.typeOfShapeToEdit = undefined
      }
      this.updateTransformer()
    },
    updateTransformer () {
      // here we need to manually attach or detach Transformer node
      const transformerNode = this.$refs.transformer.getNode()
      const stage = transformerNode.getStage()
      const selectedNode = stage.findOne('#' + this.selectedShape.shapeId)
      // do nothing if selected node is already attached
      if (selectedNode === transformerNode.node()) {
        return
      }

      if (selectedNode) {
        // attach to another node
        transformerNode.nodes([selectedNode])
      } else {
        // remove transformer
        transformerNode.nodes([])
      }
      transformerNode.getLayer().batchDraw()
    }
  }
}

</script>

<style scoped>
  .layout-scene {
    width:100%;
    margin:0;
    padding: 60px 0;
    height: calc(100vh - 160px);
    max-height: calc(100vh - 160px);
  }
  .layout-template {
    width:100%;
    margin: 0;
    padding: 60px 0;
    height: calc(100vh - 160px);
    max-height: calc(100vh - 160px);
  }
</style>
