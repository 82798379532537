<template>
  <section>
    <span v-if="!editRight">
      <slot name="default" :componentData="value">
        {{ value }}
      </slot>
    </span>
    <span v-else>
      <v-radio-group :value="mutableModel" @change="updateValue">
        <v-radio
          v-for="n in mutableOptions"
          :key="n.id"
          :label="n.label"
          :value="n.id"
        ></v-radio>
      </v-radio-group>
    </span>
    <span
      v-if="$v.mutableModel.$error"
      class="form_error"
    >
      {{ $t('errors.input.INVALID') }} - {{ attrsInformation }}
    </span>
  </section>
</template>

<script>
import { required } from 'vuelidate/lib/validators'
import i18n from './i18n.js'
export default {
  name: 'EditableRadio',
  props: {
    editRight: {
      type: Boolean,
      required: true,
      default: false
    },
    value: {
      type: String,
      default: () => undefined
    },
    options: {
      type: Array,
      default: () => []
    },
    customValidation: {
      type: Object,
      default: () => {}
    }
  },
  validations () {
    return {
      mutableModel: {
        // Adding all custom validations to the mutableModel
        ...this.customValidation ? Object.keys(this.customValidation.controls).map((key) => { return this.customValidation.controls[key] }) : {},
        required: this.$attrs.required || this.$attrs.required === '' ? required : {}
      }
    }
  },
  data () {
    return {
      mutableModel: this.value,
      mutableOptions: Object.assign({}, this.options)
    }
  },
  i18n,
  computed: {
    attrsInformation () {
      const errorMessage = []
      if ((this.$attrs.required || this.$attrs.required === '') && !this.$v.mutableModel.required) {
        errorMessage.push(`${this.$t('required')}`)
      }
      Object.keys(this.customValidation ? this.customValidation.controls : {}).forEach((key) => {
        if (!this.$v.mutableModel[key]) {
          errorMessage.push(this.customValidation.errors[key])
        }
      })

      return errorMessage.join(' - ')
    }
  },
  methods: {
    updateValue (e) {
      this.mutableModel = e
      this.$v.mutableModel.$touch()
      if (!this.$v.mutableModel.$error) {
        this.$emit('input', this.mutableModel)
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.hover-highlight {
}
.hover-highlight:hover {
  cursor: pointer;
  border-bottom: dashed $blue 2px;
}
</style>
