const i18n = {
  messages: {
    fr: {
      addVerb: 'Ajouter un verbe',
      search: 'Rechercher',
      save: 'Enregistrer',
      newLine: 'Ajouter un objectif',
      main: 'Principal',
      second: 'Secondaire | Secondaire | Secondaires',
      noData: 'Aucun élément',
      tooltip: {
        previousObj: 'Le verbe {0} de niveau {1} est supérieur à l\'objectif précédent de niveau {2}',
        mainObj: 'Le verbe {0} de niveau {1} est supérieur à l\'objectif principal de niveau {2}'
      },
      maxNumberObjectivesReached: 'Le nombre maximum d\'objectifs est atteint',
      maxNumberObjectivesExceeded: 'Le nombre maximum d\'objectifs est dépassé'
    },
    en: {
      addVerb: 'Add verb',
      search: 'Search',
      save: 'Save',
      newLine: 'New objective',
      main: 'Main',
      second: 'Secondary | Secondary | Secondary',
      noData: 'No data',
      tooltip: {
        previousObj: 'The {0} verb of level  {1}  is superior to the previous objective of level {2}',
        mainObj: 'The {0} verb of level  {1}  is superior to the main objective of level {2}'
      },
      maxNumberObjectivesReached: 'The maximum number of objectives is reached',
      maxNumberObjectivesExceeded: 'The maximum number of objectives is exceeded'
    }
  }
}
module.exports = i18n
