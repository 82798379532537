<template>
  <div>
    <v-container fluid class="px-10">
      <v-row>
        <v-col class="pb-0">
          <v-row class="justify-space-between align-baseline">
            <v-col cols="5">
              <v-text-field
                v-model="searchAsset"
                :placeholder="$t('search')"
                prepend-inner-icon="mdi-magnify"
              ></v-text-field>
            </v-col>
            <v-col cols="7" class="h4 d-flex align-baseline px-10">
              <h4 class="primary--text font-weight-bold mr-5" style="white-space: nowrap;">
                {{ $t('filter') }} :
              </h4>
              <v-select
                v-model="assetTypeToDisplay"
                :items="searchList"
                item-text="label"
                item-value="id"
              ></v-select>
            </v-col>
          </v-row>
        </v-col>
        <v-col class="d-flex justify-end">
          <edtk-button
            :disabled="!editRight"
            small
            @click="openAssetEditor(null)"
          >
            {{ $t('btn.create') }}
          </edtk-button>
        </v-col>
      </v-row>
      <v-row class="justify-center pt-2 pb-4" style="height:calc(100vh - 210px); overflow-y: auto; overflow-x:hidden;">
        <div class="overviewGrid">
          <div
            v-for="asset in assetsToDisplay"
            :key="asset._id"
          >
            <v-hover v-slot="{ hover }">
              <v-card
                width="285"
                height="250"
                class="ma-0 pa-0"
                data-test="card-hover-scene-overview"
              >
                <v-img
                  height="200"
                  :src="asset.avatar ? asset.avatar.value : asset.image "
                  contain
                >
                </v-img>
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title class="font-weight-bold primary--text ma-0">
                      {{ asset.name }}
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <v-expand-transition>
                  <v-card v-if="hover" class="pa-0 ma-0 v-card--reveal" height="250">
                    <v-card-title class="primary--text font-weight-bold pt-2" style="height:35px; word-break: normal;">
                      {{ asset.name }}
                    </v-card-title>
                    <v-card-text class="my-2" style="height:155px; overflow-y: auto;">
                      {{ asset.description }}
                    </v-card-text>
                    <v-card-actions v-if="asset.avatar" class="d-flex justify-space-between" style="height:40px">
                      <edtk-button
                        :disabled="!editRight"
                        class="ma-2"
                        small
                        @click="openAssetEditor(asset._id)"
                      >
                        {{ $t('btn.edit') }}
                      </edtk-button>
                      <div class="d-flex align-center">
                        <v-tooltip bottom>
                          <template #activator="{ on, attrs }">
                            <span
                              v-bind="attrs"
                              v-on="on"
                            >
                              <edtk-button-icon
                                :disabled="!editRight"
                                color="secondary"
                                icon="content_copy"
                                icon-size="2xl"
                                @click="duplicateAsset(asset._id)"
                              >
                              </edtk-button-icon>
                            </span>
                          </template>
                          <span>{{ $t('duplicate') }}</span>
                        </v-tooltip>
                        <v-tooltip bottom>
                          <template #activator="{ on, attrs }">
                            <span
                              v-bind="attrs"
                              v-on="on"
                            >
                              <edtk-button-icon
                                :disabled="!editRight"
                                class="mr-2"
                                color="secondary"
                                icon="delete"
                                icon-size="2xl"
                                @click="deleteAsset(asset._id, asset.name)"
                              >
                              </edtk-button-icon>
                            </span>
                          </template>
                          <span>{{ $t('btn.delete') }}</span>
                        </v-tooltip>
                      </div>
                    </v-card-actions>
                    <v-card-actions v-else class="d-flex justify-space-between" style="height:40px">
                      <div class="d-flex align-center">
                        <edtk-button
                          :disabled="!editRight"
                          class="ma-1"
                          small
                          @click="editTemplate(asset)"
                        >
                          {{ $t('btn.edit') }}
                        </edtk-button>
                        <edtk-button
                          :disabled="!editRight"
                          class="ma-1"
                          small
                          type="outline"
                          @click="openTemplateEditor(asset)"
                        >
                          {{ $t('btn.editVisual') }}
                        </edtk-button>
                      </div>
                      <div class="d-flex align-center">
                        <v-tooltip bottom>
                          <template #activator="{ on, attrs }">
                            <span
                              v-bind="attrs"
                              v-on="on"
                            >
                              <edtk-button-icon
                                data-test="button-duplicate-template"
                                :disabled="!editRight"
                                color="secondary"
                                icon="content_copy"
                                icon-size="2xl"
                                @click="duplicateTemplate(asset)"
                              >
                              </edtk-button-icon>
                            </span>
                          </template>
                          <span>{{ $t('duplicate') }}</span>
                        </v-tooltip>
                        <v-tooltip bottom>
                          <template #activator="{ on, attrs }">
                            <span
                              v-bind="attrs"
                              v-on="on"
                            >
                              <edtk-button-icon
                                data-test="button-delete-template"
                                :disabled="!editRight"
                                class="mr-2"
                                color="secondary"
                                icon="delete"
                                icon-size="2xl"
                                @click="deleteTemplate(asset._id, asset.name)"
                              >
                              </edtk-button-icon>
                            </span>
                          </template>
                          <span>{{ $t('btn.delete') }}</span>
                        </v-tooltip>
                      </div>
                    </v-card-actions>
                  </v-card>
                </v-expand-transition>
              </v-card>
            </v-hover>
          </div>
        </div>
      </v-row>
    </v-container>
    <AssetEditor
      ref="assetEditor"
      :catalog="storyboard.catalog"
      :asset-to-edit="assetToEdit"
      :template-to-edit="templateToEdit"
      :update-catalog="updateCatalog"
      @saveAsset="saveNewAsset"
      @saveTemplate="saveTemplate"
    />
  </div>
</template>

<script>
import _ from 'lodash'
import ObjectID from 'bson-objectid'
import AssetEditor from '../AssetEditor'

export default {
  components: { AssetEditor },
  props: {
    storyboard: {
      type: Object,
      default: () => {}
    },
    updateCatalog: {
      type: Function,
      default: () => () => { 'missing "updateCatalog" function' }
    },
    editRight: {
      type: Boolean,
      required: true,
      default: false
    }
  },
  data () {
    return {
      searchAsset: null,
      assetTypeToDisplay: 'all',
      assetsType: ['character', 'background', 'object', 'other'],
      searchList: [
        { label: this.$t('all'), id: 'all' },
        { label: this.$t('templates'), id: 'templates' },
        { label: this.$t('character'), id: 'character' },
        { label: this.$t('background'), id: 'background' },
        { label: this.$t('object'), id: 'object' },
        { label: this.$t('other'), id: 'other' }
      ],
      assetToEdit: undefined,
      templateToEdit: undefined
    }
  },
  i18n: {
    messages: {
      fr: {
        all: 'Tous',
        character: 'Personnages',
        background: 'Environnements',
        object: 'Objets',
        other: 'Autres',
        templates: 'Modèles',
        search: 'Rechercher',
        btn: {
          create: 'Créer un élément',
          edit: 'Modifier',
          editVisual: 'Visuel',
          delete: 'Supprimer',
          cancel: 'Annuler',
          continue: 'Poursuivre'
        },
        deleteConfirm: 'Voulez-vous vraiment supprimer ',
        assets: 'Éléments',
        filter: 'Afficher',
        duplicate: 'Dupliquer',
        alertMessage: 'La scène "{scene}" utilise <b>{assetName}</b> dans au moins un dialogue | Les scènes "{scene}" utilisent <b>{assetName}</b> dans au moins un dialogue',
        selectCharacterTitle: 'Sélectionner un personnage pour remplacer <b>{assetName}</b> dans les dialogues concernés :',
        selectCharacterPlaceholder: 'Sélectionner un personnage',
        doNotReplace: 'Ne pas remplacer'
      },
      en: {
        all: 'All',
        character: 'Characters',
        background: 'Backgrounds',
        object: 'Objects',
        other: 'Others',
        templates: 'Templates',
        search: 'Search',
        btn: {
          create: 'Create asset',
          edit: 'Edit',
          editVisual: 'Edit visual',
          delete: 'Delete',
          cancel: 'Cancel',
          continue: 'To continue'
        },
        deleteConfirm: 'Do you really want to remove ',
        assets: 'Assets',
        filter: 'Filter',
        duplicate: 'Duplicate',
        alertMessage: 'Scene "{scene}" uses <b>{assetName}</b> in at least one dialogue. | Scenes "{scene}" use <b>{assetName}</b> in at least one dialogue',
        selectCharacterTitle: 'Select a character to replace <b>{assetName}</b> in the relevant dialogues :',
        selectCharacterPlaceholder: 'Select a character',
        doNotReplace: 'Do not replace'
      }
    }
  },
  computed: {
    assetsToDisplay () {
      let result = []
      if (this.assetTypeToDisplay === 'all') {
        result = _.concat(this.storyboard.templates, this.selectAssetType('character'), this.selectAssetType('background'), this.selectAssetType('object'), this.selectAssetType('other'))
      } else if (this.assetTypeToDisplay === 'templates') {
        result = this.storyboard.templates
      } else {
        result = this.selectAssetType(`${this.assetTypeToDisplay}`)
      }
      if (this.searchAsset) {
        const r = this.$_.filter(result, (m) => {
          return (m.name.toLowerCase().includes(this.searchAsset.toLowerCase()))
        })
        return r
      } else {
        return _.sortBy(result, a => _.toUpper(a.name))
      }
    },
    displaySceneNumbering () {
      return this.storyboard?.settings?.sceneNumbering
    }
  },
  methods: {
    openAssetEditor (assetId) {
      this.assetToEdit = this.storyboard.assets.find(a => a._id === assetId)
      this.$refs.assetEditor.openAssetEditor('asset')
    },
    saveNewAsset (assetPayload) {
      if (this.assetToEdit) {
        this.$emit('updateAsset', { _id: this.assetToEdit._id, name: assetPayload.name, description: assetPayload.description, images: assetPayload.images })
      } else {
        this.$nuxt.$emit('saveNewAsset', assetPayload)
      }
      this.$refs.assetEditor.closeAssetEditor()
    },
    selectAssetType (assetType) {
      const result = []
      const filteredAssets = this.storyboard.assets.filter(el => el.type === assetType)
      filteredAssets.forEach((i) => {
        const avatar = { _id: '', value: '' }
        if (i.images.length > 0) {
          const selectedDefaultImage = i.images.find(image => image.defaultImage)
          const defaultImage = selectedDefaultImage || i.images[0]
          const imageUrl = this.storyboard.catalog.find(it => it._id === defaultImage.value)
          avatar._id = defaultImage._id
          avatar.value = imageUrl ? imageUrl.value : null
        }
        result.push({ _id: i._id, type: i.type, name: i.name, description: i.description ? i.description : '', avatar })
      })
      return result
    },
    duplicateAsset (id) {
      const newId = ObjectID().toHexString()
      const assetToDuplicate = this.storyboard.assets.find(a => a._id === id)
      const index = this.storyboard.assets.findIndex(a => a._id === id)
      const payload = _.cloneDeep(assetToDuplicate)
      payload._id = newId
      payload.images = _.map(payload.images, (p) => {
        const newImageId = ObjectID().toHexString()
        return { ...p, _id: newImageId }
      })
      this.$emit('duplicateAsset', { index, data: payload })
    },
    deleteAsset (id, assetName) {
      const sceneUsingAsset = {}
      this.storyboard.scenes.forEach((scene, index) => {
        scene.script.forEach((line, idx) => {
          if (line.type !== 'note' && line.actionData.assetId === id) {
            const title = this.displaySceneNumbering ? `${scene.order} (${scene.properties.title})` : scene.properties.title
            if (!sceneUsingAsset[title]) {
              sceneUsingAsset[title] = []
            }
            sceneUsingAsset[title].push({ sceneIdx: index, lineIdx: idx })
          }
        })
      })
      const sceneUsingAssetTitle = Object.keys(sceneUsingAsset)
      let message
      if (sceneUsingAssetTitle.length) {
        message = `${this.$tc('alertMessage', sceneUsingAssetTitle.length, { scene: sceneUsingAssetTitle.join(', '), assetName })}.<br/><br/>${this.$t('deleteConfirm')} <b>${assetName}</b> ?`
      } else {
        message = this.$t('deleteConfirm') + `<b>${assetName}</b> ? `
      }

      this.$swal({
        title: '',
        html: message,
        icon: sceneUsingAssetTitle.length ? 'warning' : 'question',
        confirmButtonColor: '#4bca81',
        cancelButtonColor: '#c2c5cb',
        showCancelButton: true,
        confirmButtonText: this.$t('btn.delete'),
        cancelButtonText: this.$t('btn.cancel')
      }).then((result) => {
        if (result.isConfirmed) {
          if (sceneUsingAssetTitle.length) {
            const inputOptions = this.storyboard.assets.filter(a => a.type === 'character' && a._id !== id).reduce((a, v) => ({ ...a, [v._id]: v.name }), { doNotReplace: this.$t('doNotReplace') })
            this.$swal({
              icon: 'question',
              title: '',
              html: this.$t('selectCharacterTitle', { assetName }),
              input: 'select',
              inputOptions,
              inputPlaceholder: this.$t('selectCharacterPlaceholder'),
              confirmButtonColor: '#4bca81',
              cancelButtonColor: '#c2c5cb',
              showCancelButton: true,
              confirmButtonText: this.$t('btn.continue'),
              cancelButtonText: this.$t('btn.cancel')
            }).then((result) => {
              if (result.isConfirmed) {
                let newValue
                if (!result.value || result.value === 'doNotReplace') { newValue = undefined } else { newValue = result.value }
                this.$emit('bulkUpdateAssetId', { scenesToUpdate: sceneUsingAsset, newValue })
                this.$emit('deleteAsset', id)
              }
            })
          } else {
            this.$emit('deleteAsset', id)
          }
        }
      })
    },
    openTemplateEditor (template) {
      this.$emit('openTemplateEditor', template)
    },
    saveTemplate (template) {
      if (this.templateToEdit) {
        this.$emit('updateTemplate', _.omit(template, ['image', 'canvas']))
      } else {
        this.$emit('createTemplate', template)
      }
      this.$refs.assetEditor.closeAssetEditor()
      this.templateToEdit = undefined
    },
    duplicateTemplate (template) {
      const newId = ObjectID().toHexString()
      const payload = _.cloneDeep(template)
      payload._id = newId
      payload.canvas = _.map(payload.canvas, (p) => {
        const newShapeId = ObjectID().toHexString()
        const newName = p.type === 'custom' ? `${p.config.customType}_${newId}` : `${p.type}_${newId}`
        const newConfig = { ...p.config, id: newShapeId, name: newName }
        return { ...p, config: newConfig }
      })
      this.$emit('createTemplate', payload)
    },
    editTemplate (template) {
      this.templateToEdit = template
      this.$refs.assetEditor.openAssetEditor('template')
    },
    deleteTemplate (id, templateTitle) {
      this.$swal({
        title: '',
        html: this.$t('deleteConfirm') + `${templateTitle} ? `,
        icon: 'question',
        showCancelButton: true,
        confirmButtonColor: '#4bca81',
        cancelButtonColor: '#c2c5cb',
        confirmButtonText: this.$t('btn.delete'),
        cancelButtonText: this.$t('btn.cancel')
      }).then((result) => {
        if (result.isConfirmed) {
          this.$emit('deleteTemplate', id)
        }
      })
    }
  }
}
</script>
<style scoped>
.v-card--reveal {
  align-items: center;
  bottom: 0;
  justify-content: center;
  position: absolute;
  width: 100%;

}
@media screen and (min-width: 700px) {
  .overviewGrid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 20px;
    grid-auto-rows: minmax(100px, auto);
  }
}
@media screen and (min-width: 1000px) {
  .overviewGrid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 20px;
    grid-auto-rows: minmax(100px, auto);
  }
}
@media screen and (min-width: 1300px) {
  .overviewGrid {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 20px;
    grid-auto-rows: minmax(100px, auto);
  }
}
@media screen and (min-width: 1650px) {
  .overviewGrid {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    grid-gap: 20px;
    grid-auto-rows: minmax(100px, auto);
  }
}
@media screen and (min-width: 1920px) {
  .overviewGrid {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    grid-gap: 10px;
    grid-auto-rows: minmax(100px, auto);
  }
}
</style>
