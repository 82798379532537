const i18n = {
  messages: {
    fr: {
      title: 'Affichage sur ordinateur uniquement',
      text: 'L\'application est optimisée pour un usage sur navigateur d\'ordinateur. Merci de vous reconnecter depuis un ordinateur.'
    },
    en: {
      title: 'Display on computer only',
      text: 'The application is optimised for use on a computer browser. Please reconnect from a computer.'
    }
  }
}
module.exports = i18n
