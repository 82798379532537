/* eslint-disable no-console */
import _ from 'lodash'

export const state = () => ({
  proposals: []
})

export const getters = {
  get (state) {
    return state.proposals
  },
  getOne: state => (id) => {
    return _.find(state.proposals, (p) => { return p._id === id })
  },
  getWaiting (state) {
    const r = _.orderBy(_.filter(state.proposals, function (o) { return o.edtakerStatus !== 'REJECTED' && o.status === 'OPEN' }), 'startDate', 'desc')
    _.forEach(r, (p) => {
      if (p.edtakerStatus === 'ACCEPTED' && p.status === 'OPEN') {
        p.status = 'WAITING_CUSTOMER'
      }
    })
    return r
  }
}
export const actions = {
  fetch ({ commit }) {
    return new Promise((resolve, reject) => {
      this.$edtake.proposal.get()
        .then((d) => {
          commit('SET', d)
          resolve(d)
        })
        .catch((err) => {
          console.error('ERROR', err)
          reject(err)
        })
    })
  },
  fetchOne ({ commit }, { id }) {
    return new Promise((resolve, reject) => {
      this.$edtake.proposal.getOne({ id })
        .then((d) => {
          commit('UPDATE_ONE', d)
          resolve(d)
        })
        .catch((err) => {
          console.error('ERROR', err)
          reject(err)
        })
    })
  },
  update ({ commit }, { id, body }) {
    return new Promise((resolve, reject) => {
      this.$edtake.proposal.update({ id, body })
        .then(async (d) => {
          await commit('UPDATE_ONE', d)
          resolve(d)
        })
        .catch((err) => {
          console.error('ERROR', err)
          reject(err)
        })
    })
  },
  acceptProposal ({ commit }, { id }) {
    return new Promise((resolve, reject) => {
      this.$edtake.proposal.accept({ proposalId: id })
        .then(async (d) => {
          await commit('UPDATE_ONE', d)
          resolve(d)
        })
        .catch((err) => {
          console.error('ERROR', err)
          reject(err)
        })
    })
  },
  uploadOrderLineBill ({ commit }, { files, proposalId, orderId, lineId }) {
    return new Promise((resolve, reject) => {
      const form = new FormData()
      files.forEach((file, i) => {
        form.append(`file-${i}`, file.file, file.file.name)
      })
      const config = {
        headers: {
          'content-type': 'multipart/form-data'
        }
      }
      this.$edtake.proposal.uploadOrderLineBill({ form, $config: config, proposalId, orderId, lineId })
        .then((d) => {
          commit('UPDATE_ORDER_BILLING', { data: d, proposalId, orderId, lineId })
          resolve(d)
        })
        .catch((err) => {
          console.error('ERROR', err)
          reject(err)
        })
    })
  },
  declineProposal ({ commit }, { id, reason }) {
    return new Promise((resolve, reject) => {
      this.$edtake.proposal.decline({ proposalId: id, body: { reason } })
        .then(async (d) => {
          await commit('UPDATE_ONE', d)
          resolve(d)
        })
        .catch((err) => {
          console.error('ERROR', err)
          reject(err)
        })
    })
  },
  getDocument ({ commit }, { proposalId, documentId }) {
    return new Promise((resolve, reject) => {
      this.$edtake.proposal.getDocument({ proposalId, documentId })
        .then((d) => {
          resolve(d)
        })
        .catch((err) => {
          console.error('ERROR', err)
          reject(err)
        })
    })
  },
  _updateOne ({ commit }, payload) {
    commit('UPDATE_ONE', payload)
  },
  _updateDelivery ({ commit }, payload) {
    commit('UPDATE_DELIVERY', payload)
  }
}
export const mutations = {
  UPDATE_ONE (state, value) {
    const item = state.proposals.find(item => item._id.toString() === value._id.toString())
    if (item) {
      // In case backend return unpopulated mission
      if (typeof value.mission !== 'object') {
        value.mission = item.mission
      }
      Object.assign(item, value)
      state.proposals = [...state.proposals] // Force refresh
    } else {
      state.proposals = [...state.proposals, value]
    }
  },
  SET (state, value) {
    state.proposals = value
  },
  CLEAR (state) {
    state.proposals = []
  },
  UPDATE_DELIVERY (state, value) {
    const proposal = state.proposals.find(item => item.mission._id.toString() === value.missionId.toString())
    if (proposal) {
      const line = proposal.orders[0].lines.find(line => line.deliveries.some(delivery => delivery.delivery._id.toString() === value.deliveryId.toString()))
      const delivery = line.deliveries.find(delivery => delivery.delivery._id.toString() === value.deliveryId.toString())
      delivery.delivery = value.data
      state.proposals = [...state.proposals] // Force refresh
    }
  },
  UPDATE_ORDER_BILLING (state, value) {
    const proposal = state.proposals.find(item => item._id.toString() === value.proposalId.toString())
    if (proposal) {
      const order = proposal.orders.find(order => order._id.toString() === value.orderId.toString())
      if (order) {
        const line = order.lines.find(line => line._id.toString() === value.lineId.toString())
        line.edtakerBilling = value.data
        state.proposals = [...state.proposals] // Force refresh
      }
    }
  }
}
