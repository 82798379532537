import io from 'socket.io-client'
import { v4 as uuidv4 } from 'uuid'
import defs from './defs'

export default ({ store, app }, inject) => {
  if (!store.state.user.session) {
    store.commit('user/SET_SESSION', uuidv4())
  }
  const socketManager = {
    session: store.state.user.session,
    sockets: {}
  }
  socketManager.getSocket = (socketName) => {
    try {
      if (!defs[socketName]) {
        throw new Error('Unknown socket')
      }
      if (!store.state.remoteConfig.config) {
        throw new Error('Configuration not loaded')
      }
      if (!(app.$auth && app.$auth.user)) {
        throw new Error('User not authenticated')
      }
      if (!socketManager.sockets[socketName]) {
        const socketDefs = defs[socketName](app.$auth.strategy.token.get().split('Bearer ')[1], socketManager.session)

        if (!store.state.remoteConfig.config[socketDefs.host]) {
          throw new Error(`Unknown socket host : ${socketDefs.host}`)
        }

        const s = io(store.state.remoteConfig.config[socketDefs.host], socketDefs.options)

        // Init vuex treatment if any
        if (socketDefs.vuex) {
        // Actions
          if (socketDefs.vuex.actions) {
            socketDefs.vuex.actions.forEach((evt) => {
              s.on(evt.event, async (data) => {
                try {
                  await store.dispatch(evt.store, data)
                } catch (error) {
                  console.log(`Error on socket vuex treatment (actions) : ${evt}`)
                }
              })
            })
          }
          // Mutations
          if (socketDefs.vuex.mutations) {
            socketDefs.vuex.mutations.forEach((evt) => {
              s.on(evt.event, async (data) => {
                try {
                  await store.commit[evt.store](data)
                } catch (error) {
                  console.log(`Error on socket vuex treatment (mutations) : ${evt}`)
                }
              })
            })
          }
        }
        socketManager.sockets[socketName] = s
        if (socketName === 'edtake') {
          s.emit('join', { type: 'session', room: 'user' })
        }
      }

      return socketManager.sockets[socketName]
    } catch (error) {
      console.log('Error => ', error)
    }
  }

  socketManager.disconnect = () => {
    Object.keys(socketManager.sockets).forEach((s) => {
      socketManager.sockets[s].disconnect()
    })

    socketManager.session = uuidv4()
    socketManager.sockets = {}
  }

  inject('socketManager', socketManager)
}
