<template>
  <v-card flat class="d-flex flex-column align-center">
    <img src="/img/access_denied.png" alt="access_denied" width="600px" />
    <h2>
      {{ $t('accessDenied') }}
    </h2>
  </v-card>
</template>

<script>
import i18n from './i18n.js'
export default {
  name: 'NoAccess',
  i18n,
  data () {
    return {}
  }
}
</script>
