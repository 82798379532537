<template>
  <section>
    <div class="background10 pa-4 width-100 edtk-rounded-md">
      <span v-if="mutableModel.length > objectivesQuantityNumber" class="red--text d-flex align-center mb-2">
        <v-icon small color="red" class="mr-2">
          mdi-alert
        </v-icon>
        {{ $t('maxNumberObjectivesExceeded') }}
      </span>
      <h5 v-if="!mutableModel.length && !editNewObjective" class="font-italic text-center">
        {{ $t('noData') }}
      </h5>
      <span v-if="displayCategories && !mainObjectiveDisabled(objectType) && (mutableModel.length || editNewObjective)" class="h5 blue--text">
        {{ $t('main') }}
      </span>
      <slot v-if="!editRight" name="default" :component-data="value">
        <div
          v-for="(t,$index) in mutableModel"
          :key="`${$index}_${t}`"
        >
          <div
            class="d-flex align-center my-3"
          >
            <div v-if="useTaxonomyVerbs" :style="t && t.color ? `color:${t.color};` : ''" class="tooltip-target white pa-2 mr-2">
              <span v-if="mutableModel[$index].verb">{{ mutableModel[$index].verb }}</span>
              <v-icon v-else small>
                mdi-null
              </v-icon>
            </div>
            <div class="d-flex white pa-2 width-100">
              <div
                class="width-100 mr-2"
              >
                {{ useTaxonomyVerbs ? mutableModel[$index].text : mutableModel[$index] }}
              </div>
            </div>
            <v-tooltip v-if="useTaxonomyVerbs" color="warning" bottom>
              <template #activator="{ on, attrs }">
                <v-icon
                  small
                  class="ml-2"
                  style="min-width: 16px;"
                  color="warning"
                  v-bind="attrs"
                  v-on="on"
                >
                  {{ t.mainLevel > previousObjLevel($index) || t.mainLevel > mainObjLevel ? 'mdi-alert': '' }}
                </v-icon>
              </template>
              <span v-if="t.mainLevel > mainObjLevel">
                {{ $t('tooltip.mainObj', {'0': `${t.verb}`, '1': `${t.mainLevel}`, '2': `${mainObjLevel}` } ) }}
              </span>
              <span v-else>
                {{ $t('tooltip.previousObj', {'0': `${t.verb}`, '1': `${t.mainLevel}`, '2': `${previousObjLevel($index)}` } ) }}
              </span>
            </v-tooltip>
          </div>
          <span v-if="displayCategories && !mainObjectiveDisabled(objectType) && ((mutableModel.length > 1 && $index === 0) || (mutableModel.length === 1 && editNewObjective))" class="h5 blue--text">
            {{ $tc('second', mutableModel.length - 1) }}
          </span>
        </div>
      </slot>
      <section v-else>
        <draggable
          v-model="mutableModel"
          handle=".handleDragObjective"
          @end="emit(mutableModel)"
        >
          <div
            v-for="(t,$index) in mutableModel"
            :key="`${$index}_${t}`"
          >
            <v-hover v-slot="{ hover }">
              <div
                class="d-flex align-center my-3"
              >
                <i v-if="mutableModel.length > 1" class="mdi mdi-drag-vertical mr-1 brownish-grey--text blue--text-hover handle handleDragObjective" />
                <v-popover
                  v-if="useTaxonomyVerbs"
                  trigger="manual"
                  :open="isOpen === $index+1"
                  :container="containerData"
                  class="mr-2"
                  @hide="close"
                >
                  <div
                    class="tooltip-target pointer white pa-2"
                    :data-test="dataTest"
                    @click="isOpen === $index+1 ? isOpen = false : isOpen = $index+1"
                  >
                    <div :style="t && t.color ? `color:${t.color};` : ''" class="hover-highlight font-weight-bold">
                      {{ mutableModel[$index].verb || $t('addVerb') }}
                    </div>
                  </div>
                  <template slot="popover">
                    <section class="popover-section">
                      <div class="popover-header">
                        <span>
                          <v-icon small>mdi-magnify</v-icon>
                        </span>
                        <input
                          :ref="`searchInputBox-${$index+1}`"
                          v-model="search"
                          class="search-input"
                          :placeholder="$t('search')"
                        />
                      </div>
                      <v-divider></v-divider>
                      <v-list class="popover-list">
                        <v-hover
                          v-for="(item, i) in verbsBelowPreviousObjective($index)"
                          v-slot="{ hover }"
                          :key="i"
                        >
                          <v-list-item
                            :style="hover ? `background-color: ${item.color}10`: ''"
                            @click="(e) => updateValue(e, $index, 'verb')"
                          >
                            <v-list-item-content>
                              <v-list-item-title>
                                <span class="font-weight-bold" :style="item.color ? `color:${item.color};` : ''">{{ item.verb }}</span>
                              </v-list-item-title>
                            </v-list-item-content>
                          </v-list-item>
                        </v-hover>
                      </v-list>
                    </section>
                  </template>
                </v-popover>
                <div class="d-flex white pa-2 width-100">
                  <div
                    :ref="`tagText-${$index}`"
                    :key="value[$index]"
                    :class="{'active': states[$index] === 'edit'}"
                    class="width-100 mr-2 hover-highlight"
                    contenteditable="true"
                    @click="states[$index] = 'edit'"
                    @blur="(e) => updateValue(e, $index, 'text')"
                    @keyup="(e) => inputKeyup(e, $index)"
                    @keypress="preventBreakLine"
                  >
                    {{ useTaxonomyVerbs ? mutableModel[$index].text : mutableModel[$index] }}
                  </div>
                  <v-icon v-show="hover" small class="brownish-grey--text dull-red--text-hover pointer" @click="removeObjective($index)">
                    mdi-trash-can-outline
                  </v-icon>
                </div>
                <v-tooltip v-if="useTaxonomyVerbs" color="warning" attach bottom>
                  <template #activator="{ on, attrs }">
                    <v-icon
                      small
                      class="ml-2"
                      style="min-width: 16px;"
                      color="warning"
                      v-bind="attrs"
                      v-on="on"
                    >
                      {{ t.mainLevel > previousObjLevel($index) || t.mainLevel > mainObjLevel ? 'mdi-alert': '' }}
                    </v-icon>
                  </template>
                  <span v-if="t.mainLevel > mainObjLevel">
                    {{ $t('tooltip.mainObj', {'0': `${t.verb}`, '1': `${t.mainLevel}`, '2': `${mainObjLevel}` } ) }}
                  </span>
                  <span v-else>
                    {{ $t('tooltip.previousObj', {'0': `${t.verb}`, '1': `${t.mainLevel}`, '2': `${previousObjLevel($index)}` } ) }}
                  </span>
                </v-tooltip>
              </div>
            </v-hover>
            <span
              v-if="formError && $v.mutableModel.$each.$iter[$index] && $v.mutableModel.$each.$iter[$index].$invalid"
              class="form_error"
            >
              {{ $t('errors.input.INVALID') }}
            </span>
            <div v-if="displayCategories && !mainObjectiveDisabled(objectType) && ((mutableModel.length > 1 && $index === 0) || (mutableModel.length === 1 && editNewObjective))" class="h5 blue--text">
              {{ $tc('second', mutableModel.length - 1) }}
            </div>
          </div>
        </draggable>
        <div v-if="editNewObjective" class="my-3">
          <div class="d-flex align-center">
            <v-popover
              v-if="useTaxonomyVerbs"
              trigger="manual"
              :open="isOpen === 'newObjective'"
              :container="containerData"
              class="mr-2"
              @hide="close"
            >
              <div
                class="tooltip-target pointer white pa-2"
                @click="() => isOpen === 'newObjective' ? isOpen = false : isOpen = 'newObjective'"
              >
                <div :style="newObjective.color ? `color:${newObjective.color};` : ''" class="hover-highlight font-weight-bold">
                  {{ newObjective.verb || $t('addVerb') }}
                </div>
              </div>
              <template slot="popover">
                <section class="popover-section">
                  <div class="popover-header">
                    <span>
                      <v-icon small>mdi-magnify</v-icon>
                    </span>
                    <input
                      ref="searchInputBoxNewObj"
                      v-model="search"
                      class="search-input"
                      :placeholder="$t('search')"
                    />
                  </div>
                  <v-divider></v-divider>
                  <v-list class="popover-list">
                    <v-hover
                      v-for="(item, i) in verbsBelowPreviousObjective(mutableModel.length)"
                      v-slot="{ hover }"
                      :key="i"
                    >
                      <v-list-item
                        :style="hover ? `background-color: ${item.color}10`: ''"
                        @click="() => updateNewObjValue(item, 'verb')"
                      >
                        <v-list-item-content>
                          <v-list-item-title>
                            <span class="font-weight-bold" :style="item.color ? `color:${item.color};` : ''">{{ item.verb }}</span>
                          </v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                    </v-hover>
                  </v-list>
                </section>
              </template>
            </v-popover>
            <div class="d-flex white pa-2 width-100">
              <div
                ref="tagTextNewObj"
                class="mr-2 hover-highlight width-100"
                :class="{'active': newObjectiveEditState}"
                contenteditable="true"
                @blur="(e) => updateNewObjValue(e, 'text')"
                @click="() => newObjectiveEditState = true"
                @keypress="preventBreakLine"
              >
                {{ newObjective.text }}
              </div>
            </div>
            <edtkButton
              :label="$t('save')"
              type="filled"
              small
              class="mx-2"
              @click="addObjective"
            />
            <edtkButtonIcon
              icon="close"
              icon-size="4xl"
              color="secondary"
              @click="closeNewObjective"
            />
          </div>
          <span
            v-if="formError && $v.newObjective.$error"
            class="form_error"
          >
            {{ $t('errors.input.INVALID') }}
          </span>
        </div>
      </section>
    </div>
    <div class="buttons-container">
      <edtkButton
        v-if="editRight && !editNewObjective"
        :disabled="mutableModel.length >= objectivesQuantityNumber"
        :label="`+ ${newRowLabel}`"
        type="text"
        x-small
        class="my-2"
        @click="toEditNewObjective"
      />
      <v-tooltip v-if="mutableModel.length >= objectivesQuantityNumber" right>
        <template #activator="{ on, attrs }">
          <v-icon
            small
            color="brownish-grey"
            v-bind="attrs"
            v-on="on"
          >
            mdi-information-outline
          </v-icon>
        </template>
        <span>
          {{ mutableModel.length === objectivesQuantityNumber ? $t('maxNumberObjectivesReached') : $t('maxNumberObjectivesExceeded') }}
        </span>
      </v-tooltip>
    </div>
  </section>
</template>

<script>
import draggable from 'vuedraggable'
import { required, requiredIf } from 'vuelidate/lib/validators'
import { mapGetters } from 'vuex'
import i18n from './i18n.js'

export default {
  name: 'EditableObjectivesList',
  components: {
    draggable
  },
  props: {
    dataTest: {
      type: String
    },
    editRight: {
      type: Boolean,
      required: true,
      default: false
    },
    value: {
      type: Array,
      default: () => []
    },
    objectType: {
      type: String,
      required: false
    },
    newRowLabel: {
      type: String,
      default () { return this.$t('newLine') }
    },
    displayCategories: {
      type: Boolean,
      default: true
    },
    parentContainer: {
      type: Boolean,
      default: () => false
    },
    containerRef: {
      type: HTMLElement
    }
  },
  validations: {
    mutableModel: {
      $each: {
        verb: { required },
        text: { required }
      }
    },
    newObjective: {
      verb: {
        required: requiredIf(function () {
          return this.useTaxonomyVerbs && this.editNewObjective
        })
      },
      text: {
        required: requiredIf(function () {
          return this.useTaxonomyVerbs && this.editNewObjective
        })
      }
    }
  },
  data () {
    return {
      search: '',
      states: [],
      isOpen: false,
      mutableModel: [],
      editNewObjective: false,
      newObjective: {
        verb: '',
        text: '',
        mainLevel: '',
        color: ''
      },
      newObjectiveEditState: false,
      formError: false,
      containerData: undefined
    }
  },
  i18n,
  computed: {
    ...mapGetters({
      taxonomySettings: 'settings/getSettingsTaxonomy',
      useAssistedInstructionalDesign: 'settings/useAssistedInstructionalDesign',
      objectivesQuantity: 'settings/getSettingsObjectivesQuantity',
      mainObjectiveDisabled: 'settings/getSettingsMainObjectiveDisabled'
    }),
    useTaxonomyVerbs () {
      return this.$userRightsHelper.hasFeatureAccess(`${this.objectType}TaxonomyForcedUse`)
    },
    disableVerbsLevelConstraint () {
      return this.$userRightsHelper.hasFeatureAccess(`${this.objectType}TaxonomyVerbsLevelConstraint`)
    },
    classifiedValue () {
      if (this.useTaxonomyVerbs) {
        const res = []
        const r = this.$taxonomyHelper.classifyAll(this.value, this.taxonomySettings.verbMapping)
        r.forEach((i) => {
          if (i.bloomLabels.length) {
            const color = this.taxonomySettings.bloomColors[`level${i.bloomLabels[0].mainLevel}`]
            res.push({ verb: i.bloomLabels[0].verb, text: i.bloomLabels[0].text, color, mainLevel: i.bloomLabels[0].mainLevel })
          } else {
            res.push({ verb: null, text: i.inputString })
          }
        })
        return res
      } else {
        return []
      }
    },
    verbsList () {
      const searchText = this.search ? this.$_.deburr(this.search.toUpperCase()) : ''
      const r = this.taxonomySettings.verbMapping.map((v) => {
        return { verb: v.verb, mainLevel: v.mainLevel, color: this.taxonomySettings.bloomColors[`level${v.mainLevel}`], search: this.$_.deburr(v.verb.toUpperCase()) }
      })
      const sortedResult = this.$_.sortBy(r, ['verb'])
      return searchText === '' ? sortedResult : this.$_.filter(sortedResult, o => o.search.includes(searchText))
    },
    mainObjLevel () {
      return this.mutableModel[0].mainLevel
    },
    objectivesQuantityNumber () {
      if (this.useAssistedInstructionalDesign) {
        return 4
      } else {
        return this.objectivesQuantity(this.objectType)
      }
    }
  },
  watch: {
    value: {
      deep: true,
      handler () {
        if (this.useTaxonomyVerbs) {
          this.mutableModel = this.classifiedValue
        } else {
          this.mutableModel = this.value
        }
      }
    },
    isOpen (n) {
      if (n) {
        this.search = ''
        setTimeout(function () {
          this.focus()
        }.bind(this), 150)
      }
    },
    containerRef (n) {
      this.containerData = n
    }
  },
  mounted () {
    if (this.useTaxonomyVerbs) {
      this.mutableModel = this.classifiedValue
      this.$v.mutableModel.$touch()
      if (this.$v.mutableModel.$error) {
        this.formError = true
      }
    } else {
      this.mutableModel = this.value
    }

    if (this.parentContainer) {
      this.containerData = this.$parent.$el
    }
  },
  methods: {
    verbsBelowPreviousObjective (idx) {
      const previousVerbIdx = idx - 1
      if (!this.disableVerbsLevelConstraint && idx > 0 && this.mutableModel[previousVerbIdx] && this.mutableModel[previousVerbIdx].mainLevel) {
        const searchText = this.search ? this.$_.deburr(this.search.toUpperCase()) : ''
        const base = this.$_.filter(this.taxonomySettings.verbMapping, v => v.mainLevel <= this.mutableModel[previousVerbIdx].mainLevel)
        const r = base.map((v) => {
          return { verb: v.verb, mainLevel: v.mainLevel, color: this.taxonomySettings.bloomColors[`level${v.mainLevel}`], search: this.$_.deburr(v.verb.toUpperCase()) }
        })
        const sortedResult = this.$_.sortBy(r, ['verb'])
        return searchText === '' ? sortedResult : this.$_.filter(sortedResult, o => o.search.includes(searchText))
      } else {
        return this.verbsList
      }
    },
    previousObjLevel (idx) {
      return idx === 0 ? this.mutableModel[0].mainLevel : this.mutableModel[idx - 1].mainLevel
    },
    close () {
      this.isOpen = false
      this.search = ''
    },
    closeNewObjective () {
      this.editNewObjective = false
      this.newObjective = { verb: '', text: '', mainLevel: '', color: '' }
      this.newObjectiveEditState = false
      this.formError = false
      this.isOpen = false
    },
    emit (el) {
      if (this.useTaxonomyVerbs) {
        const payload = el.map((e) => {
          const verb = e.verb
          return verb ? verb.concat(' ', e.text) : e.text
        })
        this.$emit('input', [...payload])
      } else {
        this.$emit('input', [...el])
      }
    },
    updateValue (e, i, type) {
      const inputValue = this.$_.trim(e.target.innerText)
      if (!inputValue && !this.useTaxonomyVerbs) {
        this.removeObjective(i)
        return
      }
      this.states[i] = 'read'
      if (this.useTaxonomyVerbs && this.mutableModel[i][type] !== inputValue) {
        this.mutableModel[i][type] = inputValue
        this.emit(this.mutableModel)
        if (type === 'verb') {
          setTimeout(function () {
            this.focus()
          }.bind(this), 150)
        }
      } else if (!this.useTaxonomyVerbs && this.mutableModel[i] !== inputValue) {
        this.mutableModel[i] = inputValue
        this.emit(this.mutableModel)
      }
      this.isOpen = false
    },
    inputKeyup (e, i) {
      const isEnter = e.key === 'Enter' || e.keyCode === 13
      const isEscape = e.key === 'Escape' || e.keyCode === 27
      if (isEnter || isEscape) {
        this.states[i] = 'read'
        this.$nextTick(() => {
          this.$refs[`tagText-${i}`][0].blur()
        })
      }
    },
    preventBreakLine (e) {
      if (e.key === 'Enter' || e.keyCode === 13) {
        e.preventDefault()
      }
    },
    updateNewObjValue (item, type) {
      if (type === 'verb') {
        this.newObjective.verb = item.verb
        this.newObjective.color = item.color
        this.newObjective.mainLevel = item.mainLevel
        this.isOpen = false
        setTimeout(function () {
          this.focusTextNewObj()
        }.bind(this), 150)
      } else if (type === 'text') {
        this.newObjective.text = item.target.innerText
      }
      this.newObjectiveEditState = false
    },
    toEditNewObjective () {
      this.editNewObjective = true
      this.isOpen = 'newObjective'
      setTimeout(function () {
        this.focus()
      }.bind(this), 500)
    },
    addObjective () {
      this.$v.newObjective.$touch()
      if (this.$v.newObjective.$invalid) {
        this.formError = true
      } else {
        if (!this.mutableModel) { this.mutableModel = [] }
        const payload = this.useTaxonomyVerbs ? this.newObjective : this.newObjective.text
        this.mutableModel.push(payload)
        this.states[this.mutableModel.length - 1] = 'read'
        this.emit(this.mutableModel)
        this.closeNewObjective()
      }
    },
    removeObjective (i) {
      const r = this.$_.cloneDeep(this.mutableModel)
      r.splice(i, 1)
      this.mutableModel = r
      this.emit(this.mutableModel)
    },
    focus () {
      if (this.isOpen === 'newObjective') {
        this.$nextTick(() => this.useTaxonomyVerbs ? this.$refs.searchInputBoxNewObj.focus() : this.$refs.tagTextNewObj.focus())
      } else if (typeof this.isOpen === 'number') {
        this.$nextTick(() => this.$refs[`searchInputBox-${this.isOpen}`][0].focus())
      }
    },
    focusTextNewObj () {
      if (this.$refs.tagTextNewObj) {
        this.$nextTick(() => this.$refs.tagTextNewObj.focus())
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.tooltip-target {
  min-width: max-content;
  width: max-content;
}
.hover-highlight {
  display: block;
  border-bottom: dashed transparent 2px;
  &:hover {
    cursor: text;
    border-bottom: dashed $blue 2px;
  }
  &.active {
    border-bottom: solid $blue 1px;
  }
}
.search-input {
  border: none;
}
.popover-section {
  margin: -5px;
}
.popover-list {
  padding: 0;
  max-height: 300px;
  overflow: auto;
}
.popover-header {
  max-height: 100px;
  padding: 0 12px;
}
.background10 {
  background-color: #00558a10;
}
.width-100 {
  width: 100%;
}
</style>
