const i18n = {
  messages: {
    fr: {
      search: 'Rechercher',
      tabs: {
        projectItem: 'Gestion de projets',
        learningObject: 'Catalogue',
        mission: 'Missions'
      }
    },
    en: {
      search: 'Search',
      tabs: {
        projectItem: 'Projects management',
        learningObject: 'Learning objects',
        mission: 'Missions'
      }
    }
  }
}
module.exports = i18n
