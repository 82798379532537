<template>
  <div>
    <v-dialog
      v-if="dialog"
      v-model="dialog"
      persistent
      :width="dialogWidth"
      transition="scale-transition"
      @click:outside="closeAssetEditor"
    >
      <v-card class="ma-0 pa-0">
        <v-card-title>
          <span v-if="dialogScreen === 1" class="page-title font-weight-semibold ma-0 pa-0">{{ $t('headline.other') }}</span>
          <span v-else class="page-title font-weight-semibold ma-0 pa-0">{{ assetToEdit || templateToEdit ? $t('headline.update') : $t(`headline.${assetType}`) }}</span>
          <v-spacer></v-spacer>
          <edtk-button-icon
            data-test="button-overview-link-navbar"
            icon="close"
            icon-size="2xl"
            color="secondary"
            @click="closeAssetEditor"
          ></edtk-button-icon>
        </v-card-title>
        <v-divider></v-divider>
        <v-stepper v-model="dialogScreen">
          <v-stepper-header v-if="!assetToEdit && !templateToEdit" class="bg-white-smoke">
            <v-stepper-step
              class="py-0"
              :complete="dialogScreen > 1"
              step="1"
            >
              {{ $t('screenOneTitle') }}
            </v-stepper-step>
            <v-divider></v-divider>
            <v-stepper-step
              class="py-0"
              step="2"
            >
              {{ $t('screenTwoTitle') }}
            </v-stepper-step>
          </v-stepper-header>
          <v-stepper-items>
            <v-stepper-content step="1" class="bg-white-smoke pt-0">
              <v-sheet class="bg-white-smoke">
                <v-row justify="center" class="pb-2">
                  <!-- Personnage -->
                  <v-col cols="6">
                    <v-card
                      class="d-flex align-center ma-0 pa-1"
                      min-height="80px"
                      @click="saveAssetType('character')"
                    >
                      <v-col cols="4" class="text-center">
                        <img
                          src="~/assets/img/asset-character.svg"
                          alt="edtake"
                          height="100%"
                        />
                      </v-col>
                      <v-col cols="8">
                        <h4 class="blue--text">
                          {{ $t('character') }}
                        </h4>
                      </v-col>
                    </v-card>
                  </v-col>
                  <!-- Environnement -->
                  <v-col cols="6">
                    <v-card
                      class="d-flex align-center ma-0 pa-1"
                      min-height="80px"
                      @click="saveAssetType('background')"
                    >
                      <v-col cols="4" class="text-center">
                        <img
                          src="~/assets/img/asset-background.svg"
                          alt="edtake"
                          height="100%"
                        />
                      </v-col>
                      <v-col cols="8">
                        <h4 class="blue--text">
                          {{ $t('background') }}
                        </h4>
                      </v-col>
                    </v-card>
                  </v-col>
                  <!-- Objet -->
                  <v-col cols="6">
                    <v-card
                      class="d-flex align-center ma-0 pa-1"
                      min-height="80px"
                      @click="saveAssetType('object')"
                    >
                      <v-col cols="4" class="text-center">
                        <img
                          src="~/assets/img/asset-object.svg"
                          alt="edtake"
                          height="100%"
                        />
                      </v-col>
                      <v-col cols="8">
                        <h4 class="blue--text">
                          {{ $t('object') }}
                        </h4>
                      </v-col>
                    </v-card>
                  </v-col>
                  <!-- Modèle -->
                  <v-col v-if="scope != 'script'" cols="6">
                    <v-card
                      class="d-flex align-center ma-0 pa-1"
                      min-height="80px"
                      @click="saveAssetType('template')"
                    >
                      <v-col cols="4" class="text-center">
                        <img
                          src="~/assets/img/asset-template.svg"
                          alt="edtake"
                          height="100%"
                        />
                      </v-col>
                      <v-col cols="8">
                        <h4 class="blue--text">
                          {{ $t('template') }}
                        </h4>
                      </v-col>
                    </v-card>
                  </v-col>
                  <!-- Autres -->
                  <v-col cols="6">
                    <v-card
                      class="d-flex align-center ma-0 pa-1"
                      min-height="80px"
                      @click="saveAssetType('other')"
                    >
                      <v-col cols="4" class="text-center">
                        <img
                          src="~/assets/img/asset-other.svg"
                          alt="edtake"
                          height="100%"
                        />
                      </v-col>
                      <v-col cols="8">
                        <h4 class="blue--text">
                          {{ $t('other') }}
                        </h4>
                      </v-col>
                    </v-card>
                  </v-col>
                </v-row>
              </v-sheet>
            </v-stepper-content>
            <v-stepper-content step="2" class="bg-white-smoke pt-0">
              <v-sheet class="bg-white-smoke">
                <v-row class="pb-2">
                  <v-col v-if="scope != 'template'" cols="4" class="pt-0">
                    <h2 class="page-subtitle">
                      {{ $t('defaultImage') }}
                    </h2>
                    <v-hover v-slot="{ hover }">
                      <v-img
                        class="rounded elevation-3 white"
                        width="260"
                        :aspect-ratio="1.41"
                        contain
                        :src="defaultImage"
                      >
                        <v-fade-transition>
                          <v-overlay
                            v-if="hover"
                            absolute
                            opacity="0.6"
                          >
                            <edtk-button-icon
                              v-if="!defaultImage"
                              icon="add"
                              icon-size="6xl"
                              color="white"
                              @click="openVisualEditor"
                            ></edtk-button-icon>
                            <edtk-button
                              v-if="defaultImage"
                              :label="$t('btn.change')"
                              class="mx-2"
                              small
                              @click="openVisualEditor"
                            ></edtk-button>
                          </v-overlay>
                        </v-fade-transition>
                      </v-img>
                    </v-hover>
                  </v-col>
                  <v-col :cols="scope === 'template' ? 12 : 8" class="pt-0">
                    <h2 class="page-subtitle">
                      {{ $t('informations') }}
                    </h2>
                    <v-card class="pa-0 ma-0">
                      <v-card-text>
                        <h5 class="primary--text mb-2">
                          {{ $t('name') }}
                        </h5>
                        <EditableField
                          v-model="name"
                          data-test="input-create-asset-name"
                          type="text"
                          tag-type="h6"
                          :edit-right="true"
                          :placeholder="$t('assetName')"
                          large
                        />
                        <div v-if="formError && !$v.name.required" class="red--text">
                          {{ $t('rules.name') }}
                        </div>
                        <div v-if="formError && !$v.name.maxLength" class="red--text">
                          {{ $t('rules.nameLength') }}
                        </div>
                        <h5 class="primary--text my-2">
                          {{ $t('description') }}
                        </h5>
                        <EditableField
                          v-model="description"
                          data-test="input-create-asset-description"
                          type="textarea"
                          :edit-right="true"
                          :placeholder="$t('assetDescription')"
                          rows="4"
                          large
                        />
                      </v-card-text>
                    </v-card>
                  </v-col>
                </v-row>
                <v-row v-if="scope != 'template'">
                  <v-col>
                    <h2 class="page-subtitle">
                      {{ $t('secondaryVisuals') }}
                    </h2>
                    <div
                      class="d-flex flex-wrap"
                      data-test="list-create-asset-images"
                      style="min-height:130px"
                    >
                      <div
                        v-for="image in secondaryVisuals"
                        :key="image._id"
                        class="ma-1 pa-0"
                      >
                        <v-img
                          class="elevation-1 rounded"
                          width="180"
                          aspect-ratio="1.4"
                          contain
                          :src="image.value"
                        ></v-img>
                      </div>
                    </div>
                  </v-col>
                </v-row>
              </v-sheet>
            </v-stepper-content>
          </v-stepper-items>
        </v-stepper>
        <v-divider></v-divider>
        <v-card-actions v-if="dialogScreen === 2" class="d-flex justify-center">
          <edtk-button
            v-if="!assetToEdit && !templateToEdit"
            :label="$t('btn.return')"
            class="mx-2"
            type="outline"
            @click="() => dialogScreen = 1"
          ></edtk-button>
          <edtk-button
            class="mx-2"
            data-test="button-create-asset-save"
            :loading="loading"
            @click="checkinputs"
          >
            {{ assetToEdit || templateToEdit ? $t('btn.update') : $t('btn.save') }}
          </edtk-button>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-if="visualEditor"
      v-model="visualEditor"
      width="95vw"
    >
      <v-card class="pa-0 ma-0 bg-white-smoke" height="90vh">
        <v-card-title class="white py-1">
          <span class="page-title font-weight-semibold ma-0 pa-0">{{ $t('visualEditorTitle') }} </span>
          <v-spacer></v-spacer>
          <edtk-button-icon
            data-test="button-overview-link-navbar"
            icon="close"
            icon-size="3xl"
            color="secondary"
            @click.stop="closeVisualEditor"
          ></edtk-button-icon>
        </v-card-title>
        <v-divider></v-divider>
        <v-row class="pa-5" @click.stop="displayCurrentImage(undefined, undefined)">
          <v-col cols="8">
            <v-row>
              <v-col class="py-0">
                <h2 class="page-subtitle mt-0 pt-0">
                  {{ $t('usedVisuals') }}
                </h2>
                <v-sheet class="d-flex flex-wrap bg-white-smoke" style="height:27vh; overflow-y:auto;">
                  <div
                    v-for="image in assetImages"
                    :key="image._id"
                    class="ma-1"
                    data-test="list-create-asset-images"
                  >
                    <v-img
                      width="150"
                      class="rounded elevation-1 text-right"
                      aspect-ratio="1.4"
                      :src="image.value"
                      contain
                      @click.stop="displayCurrentImage(image._id, image.value)"
                    >
                      <v-icon v-if="image.defaultImage" small color="primary">
                        mdi-star
                      </v-icon>
                      <v-fade-transition>
                        <v-overlay
                          v-if="currentImage.id === image._id"
                          absolute
                          opacity="0.6"
                          class="text-center"
                        >
                          <edtk-button
                            v-if="!image.defaultImage"
                            small
                            class="mb-1 mx-auto"
                            @click.stop="updateImageDefault(image._id)"
                          >
                            {{ $t('default') }}
                          </edtk-button>
                          <edtk-button
                            type="tonal"
                            class="mx-auto"
                            small
                            @click.stop="deleteImage(image.assetId)"
                          >
                            {{ $t('remove') }}
                          </edtk-button>
                        </v-overlay>
                      </v-fade-transition>
                    </v-img>
                  </div>
                </v-sheet>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="4" class="pb-0 align-center">
            <v-img
              :src="currentImage.value"
              class="white elevation-3 rounded mx-auto mt-2 text-right"
              width="90%"
              :aspect-ratio="1.5"
              contain
            >
            </v-img>
          </v-col>
        </v-row>
        <v-row class="px-5" @click.stop="displayCurrentImage(undefined, undefined)">
          <v-col class="pt-0">
            <h2 class="page-subtitle mt-0 pt-0 d-flex align-center">
              {{ $t('library') }}
              <edtk-button
                type="outline"
                small
                class="ml-5"
              >
                <label for="file-input" class="cursor">
                  {{ $t('btn.upload') }}
                </label>
                <input
                  id="file-input"
                  data-test="button-import-image-navigate"
                  class="d-none"
                  type="file"
                  accept="image/*"
                  multiple
                  @change="uploadImages"
                >
              </edtk-button>
            </h2>
            <v-sheet class="d-flex flex-wrap bg-white-smoke" style="height:28vh; overflow:auto;">
              <div
                v-for="itemImage in catalog"
                :key="itemImage._id"
                class="ma-1"
              >
                <v-card class="ma-0 pa-1">
                  <v-img
                    width="150"
                    aspect-ratio="1.4"
                    :src="itemImage.value"
                    contain
                    @click.stop="displayCurrentImage(itemImage._id, itemImage.value)"
                  >
                  </v-img>
                  <v-fade-transition>
                    <v-overlay
                      v-if="currentImage.id === itemImage._id"
                      absolute
                      opacity="0.6"
                    >
                      <edtk-button
                        type="tonal"
                        class="mx-auto"
                        small
                        @click.stop="addImageFromCatalog(itemImage._id, itemImage.ratio)"
                      >
                        {{ $t('add') }}
                      </edtk-button>
                    </v-overlay>
                  </v-fade-transition>
                </v-card>
              </div>
            </v-sheet>
          </v-col>
        </v-row>
      </v-card>
      <v-overlay v-if="overlay" absolute>
        <v-progress-circular
          indeterminate
          size="64"
        ></v-progress-circular>
      </v-overlay>
    </v-dialog>
  </div>
</template>

<script>
import _ from 'lodash'
import { required, maxLength } from 'vuelidate/lib/validators'
import Aigle from 'aigle'
const ObjectID = require('bson-objectid')
Aigle.mixin(_)

export default {
  props: {
    assetToEdit: {
      type: Object,
      default: () => {}
    },
    templateToEdit: {
      type: Object,
      default: () => {}
    },
    catalog: {
      type: Array,
      default: () => []
    },
    updateCatalog: {
      type: Function,
      default: () => () => {}
    }
  },
  data () {
    return {
      dialog: false,
      loading: false,
      formError: false,
      name: '',
      description: '',
      images: [],
      items: [],
      stageConfig: {
        width: 780,
        height: 550
      },
      assetType: undefined,
      dialogScreen: 1,
      scope: undefined,
      visualEditor: false,
      tab: 'visual',
      currentImage: { id: undefined, value: undefined },
      overlay: false
    }
  },
  validations () {
    if (this.assetToEdit || this.templateToEdit) {
      return {
        name: {
          required,
          maxLength: maxLength(50)
        }
      }
    } else {
      return {
        name: {
          required,
          maxLength: maxLength(50)
        },
        assetType: {
          required
        }
      }
    }
  },
  i18n: {
    messages: {
      fr: {
        errorFileSize: 'Le fichier <b>{msg}</b> est trop volumineux. <br> Taille maximum autorisée : 3Mb | Les fichiers <b>{msg}</b> sont trop volumineux. <br> Taille maximum autorisée par fichier : 3Mb',
        other: 'Autre',
        character: 'Personnage',
        object: 'Objet',
        background: 'Environnement',
        template: 'Modèle',
        assetName: 'Ajouter un nom ici...',
        assetDescription: 'Ajouter une description ici...',
        search: 'Rechercher',
        defaultImage: 'Visuel principal',
        secondaryVisuals: 'Visuels secondaires',
        screenOneTitle: 'Type d\'élément',
        visuals: 'Visuels',
        usedVisuals: 'Visuels utilisés',
        screenTwoTitle: 'Détails de l\'élément',
        name: 'Nom',
        description: 'Description',
        informations: 'Informations',
        visualEditorTitle: 'Médiathèque',
        library: 'Images du storyboard',
        externalLibrary: 'Médiathèque externe',
        default: 'Principal',
        remove: 'Retirer',
        add: 'Ajouter',
        headline: {
          update: 'Modification d\'un élément',
          other: 'Création d\'un élément',
          character: 'Création d\'un personnage',
          object: 'Création d\'un objet',
          background: 'Création d\'un environnement',
          template: 'Création d\'un modèle'
        },
        btn: {
          upload: 'Importer',
          save: 'Créer l\'élément',
          change: 'Modifier',
          update: 'Enregistrer les modifications',
          return: 'Précédent'
        },
        rules: {
          name: 'Veuillez préciser un nom',
          nameLength: '50 caractères maximum'
        }
      },
      en: {
        errorFileSize: 'The file <b>{msg}</b> is too large. <br> Maximum size allowed : 3Mb | Files <b>{msg}</b> are too large. <br> Maximum size allowed per file : 3Mb',
        other: 'Other',
        character: 'Character',
        object: 'Object',
        background: 'Background',
        template: 'Template',
        assetName: 'Asset name',
        assetDescription: 'Asset description',
        search: 'Search',
        defaultImage: 'Default image',
        secondaryVisuals: 'Secondary visuals',
        screenOneTitle: 'Asset type choice',
        visuals: 'Visuals',
        usedVisuals: 'Used visuals',
        screenTwoTitle: 'Asset details',
        name: 'Name',
        description: 'Description',
        informations: 'Informations',
        visualEditorTitle: 'Import image',
        library: 'Library',
        externalLibrary: 'External library',
        default: 'Default',
        remove: 'Remove',
        add: 'Add',
        headline: {
          update: 'Update asset',
          other: 'Create new asset',
          character: 'Create new character',
          object: 'Create new object',
          background: 'Create new background',
          template: 'Create new template'
        },
        btn: {
          upload: 'Upload',
          save: 'Create asset',
          change: 'Change',
          update: 'Save changes',
          return: 'Return'
        },
        rules: {
          name: 'Name is required',
          nameLength: 'Name must be less than 50 characters'
        }
      }
    }
  },
  computed: {
    dialogWidth () {
      switch (this.scope) {
        case 'asset':
          return '800px'
        case 'template':
          return '800px'
        case 'visual':
          return '80vw'
        default:
          return '800px'
      }
    },
    assetImages () {
      const result = []
      this.images.forEach((image) => {
        const assetImage = this.catalog.find(a => a._id === image.value)
        if (assetImage) {
          result.push({ _id: image._id, value: assetImage.value, assetId: assetImage._id, defaultImage: image.defaultImage })
        }
      })
      return result
    },
    defaultImage () {
      const image = this.assetImages.find(i => i.defaultImage)
      return image ? image.value : null
    },
    secondaryVisuals () {
      return _.filter(this.assetImages, i => i.defaultImage === false)
    }
  },
  watch: {
    dialog: {
      handler (n, o) {
        if (n === true) {
          this.init()
        }
      }
    }
  },
  methods: {
    init () {
      if (this.scope === 'asset' && this.assetToEdit) {
        this.name = this.assetToEdit.name
        this.description = this.assetToEdit.description
        this.images = [...this.assetToEdit.images]
        this.scope = 'asset'
        this.dialogScreen = 2
      }
      if (this.scope === 'template' && this.templateToEdit) {
        this.name = this.templateToEdit.name
        this.description = this.templateToEdit.description
        this.scope = 'template'
        this.dialogScreen = 2
      }
    },
    checkinputs () {
      this.$v.$touch()
      if (this.$v.$invalid) {
        this.formError = true
      } else {
        if (this.scope === 'asset' || this.scope === 'script') {
          this.saveAsset()
        } else {
          this.saveTemplate()
        }
        this.formError = false
      }
    },
    openAssetEditor (scope, assetType) {
      if (assetType) {
        this.assetType = assetType
        this.dialogScreen = 2
      }
      this.scope = scope
      this.dialog = true
    },
    openVisualEditor () {
      this.visualEditor = true
    },
    closeVisualEditor () {
      this.visualEditor = false
      this.currentImage = { id: undefined, value: undefined }
    },
    displayCurrentImage (id, value) {
      this.currentImage = { id, value }
    },
    closeCurrentImage () {
      this.currentImage = { id: undefined, value: undefined }
    },
    saveAssetType (type) {
      if (this.scope !== 'script') {
        if (type === 'template') {
          this.scope = 'template'
        } else {
          this.scope = 'asset'
        }
      }
      this.assetType = type
      this.dialogScreen = 2
    },
    saveAsset () {
      const _id = this.assetToEdit ? this.assetToEdit._id : ObjectID().toHexString()
      const assetPayload = {
        _id,
        type: this.assetType,
        name: this.name,
        description: this.description,
        images: this.images
      }
      this.loading = true
      this.$emit('saveAsset', assetPayload)
    },
    saveTemplate () {
      const _id = this.templateToEdit ? this.templateToEdit._id : ObjectID().toHexString()
      const templatePayload = { _id, name: this.name, description: this.description, image: null, canvas: [] }
      this.$emit('saveTemplate', templatePayload)
    },
    addImageFromCatalog (id, ratio) {
      const newImageId = ObjectID().toHexString()
      this.images.push({ _id: newImageId, objectType: 'internal', value: id, name: 'imageName', ratio, defaultImage: this.images.length === 0 })
    },
    closeAssetEditor () {
      this.name = ''
      this.description = ''
      this.images = []
      this.items = []
      this.assetType = undefined
      this.formError = false
      this.dialog = false
      this.dialogScreen = 1
      this.scope = undefined
      this.visualEditor = false
      this.loading = false
    },
    async uploadImages (e) {
      const rejectedFiles = []
      const fileMaxSize = 3145728 // limit file size to 3MB
      await Aigle.resolve(e.target.files).eachSeries(async (file) => {
        if (file && file.size > fileMaxSize) {
          rejectedFiles.push(file.name)
        } else if (file && file.size < fileMaxSize) {
          const newImageId = ObjectID().toHexString()
          const newItemId = ObjectID().toHexString()
          this.overlay = true
          await this.updateCatalog({ _id: newItemId, image: file })
          const reader = new FileReader()
          reader.readAsDataURL(file)
          reader.onload = (e) => {
            const img = new Image()
            img.src = e.target.result
            const self = this
            img.onload = function () {
              let ratio = 1
              if (img.height >= self.stageConfig.height || img.width >= self.stageConfig.width) {
                const maxHeight = self.stageConfig.height * 0.75
                const maxWidth = self.stageConfig.width * 0.75
                const widthRatio = maxWidth / img.width
                const heightRatio = maxHeight / img.height
                ratio = Math.min(widthRatio, heightRatio)
              }
              self.images.push({ _id: newImageId, objectType: 'internal', value: newItemId, name: file.name, ratio, defaultImage: self.images.length === 0 })
            }
          }
        }
      })
      this.overlay = false

      if (rejectedFiles.length > 0) {
        this.$swal({
          icon: 'error',
          title: this.$t('errors.OUPS'),
          html: this.$tc('errorFileSize', rejectedFiles.length, { msg: rejectedFiles.join(' , ') })
        })
      }
      e.target.value = ''
    },
    updateImageDefault (id) {
      const result = []
      this.images.forEach((el) => {
        if (el._id === id) {
          result.push({ ...el, defaultImage: true })
        } else {
          result.push({ ...el, defaultImage: false })
        }
      })
      this.images = result
    },
    deleteImage (imageId) {
      this.images = this.images.filter(i => i.value !== imageId)
    }
  }
}
</script>

<style scoped>
  .scroll {
    overflow-y: scroll;
  }
  .cursor {
    cursor: pointer;
  }
</style>
