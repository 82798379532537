import Quill from 'quill'
const Uuid = require('uuid').v4

const Block = Quill.import('blots/block')
const BlockEmbed = Quill.import('blots/block/embed')

class ImageBlot extends BlockEmbed {
  static create (value) {
    const node = super.create()
    const key = Uuid()
    // au moment de l'insert ajoute dans la db un schema avec cette key
    // quand on va re set le content va chercher dans la db les données correspondant à l'id et l'insert
    console.log('key: ', key)
    node.setAttribute('data-id', key)
    node.setAttribute('alt', value.alt)
    node.setAttribute('src', value.url)
    return node
  }

  static value (node) {
    return {
      'data-id': node.getAttribute('data-id'),
      alt: node.getAttribute('alt'),
      url: node.getAttribute('src')
    }
  }
}

ImageBlot.blotName = 'image-add'
ImageBlot.className = 'image-add'
ImageBlot.tagName = 'img'

export default ImageBlot
