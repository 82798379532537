<template>
  <div>
    <v-rect
      v-if="item.type === 'rect'"
      :config="{
        ...shapeConfig,
        shapeId
      }"
      @dragmove="handleDragMove"
      @transformend="handleTransformEnd"
    />
    <v-circle
      v-if="item.type === 'circle'"
      :config="{
        ...shapeConfig,
        shapeId
      }"
      @dragmove="handleDragMove"
      @transformend="handleTransformEnd"
    />
    <v-text
      v-if="item.type === 'text'"
      :config="{
        ...shapeConfig,
        shapeId
      }"
      @dragmove="handleDragMove"
      @transform="handleTransformText"
    />
    <v-image
      v-if="item.type === 'image'"
      :config="{
        ...imageConfig,
        shapeId
      }"
      @dragmove="handleDragMove"
      @transformend="handleTransformEnd"
    />
    <VCustomShape v-if="item.type === 'custom'" :config="config" v-on="$listeners" />
    <v-arrow
      v-if="item.type === 'arrow'"
      :config="{
        ...shapeConfig,
        shapeId
      }"
      @dragmove="handleDragMove"
      @transformend="handleTransformEnd"
    />
  </div>
</template>

<script>
import _ from 'lodash'
import VCustomShape from './VCustomShape'

export default {
  components: { VCustomShape },
  props: {
    item: {
      type: Object,
      default: () => {}
    },
    catalog: {
      type: Array,
      default: () => []
    }
  },
  data () {
    return {
      blobImage: undefined,
      shapeId: this.item.config.id,
      config: this.item.config
    }
  },
  computed: {
    imageConfig () {
      return {
        ..._.omit(this.config, ['image']),
        image: this.blobImage
      }
    },
    shapeConfig () {
      return this.config
    }
  },
  created () {
    if ('image' in this.config) {
      const img = new Image()
      img.crossOrigin = 'Anonymous'
      const imgValue = this.catalog.find(i => i._id === this.config.image)
      img.src = imgValue.value
      img.onload = () => {
        this.blobImage = img
        this.$nextTick(() => { this.$emit('ready') })
      }
    } else {
      this.$emit('ready')
    }
  },
  methods: {
    handleTransformEnd (e) {
      const config = e.target.attrs
      this.$emit('transformShape', config)
    },
    handleTransformText (e) {
      const name = e.target.attrs.name
      const x = e.target.attrs.x
      const y = e.target.attrs.y
      const rotation = e.target.attrs.rotation
      const width = Math.max(e.target.attrs.width * e.target.attrs.scaleX, 100)
      e.target.scaleX(1)
      e.target.scaleY(1)
      this.$emit('transformShape', { name, width, rotation, x, y })
    },
    handleDragMove (e) {
      const x = e.target.attrs.x
      const y = e.target.attrs.y
      this.$emit('editShapePosition', { x, y })
    }
  }
}
</script>
