/* eslint-disable no-console */

export default function ({ $axios, redirect, store }) {
  const msg = ['Invalid credentials', 'Expired token', 'Invalid token']

  $axios.onRequest((config) => {
    if (config.url.includes('edtake-document')) {
      config.progress = false
    }
    if (store.state.workspace.current) {
      config.headers['X-Edtake-Workspace'] = store.state.workspace.current._id
    }
    if (store.state.authorization.jwt) {
      config.headers['X-Edtake-ClientToken'] = 'Bearer ' + store.state.authorization.jwt
    }
    if (store.state.user.locale) {
      config.headers.locale = store.state.user.locale
    }
    config.baseURL = window.location.origin
  })

  $axios.onError((error) => {
    if (error.response) {
      switch (error.response.status) {
        case 400:
          console.log('Err 400 => ', error.response.data)
          break
        case 401:
          console.log('Err 401 => ', error.response.data)
          break
        case 406:
          console.log('Err 406 AUTHENTICATION => ', error.response.data)
          redirect('/logout')
          break
        default:
          console.log('Error : ', error.response.data)
      }

      if (error.response.data && msg.includes(error.response.data.message)) {
        redirect('/logout?reconnect')
      }
    }
  })

  $axios.onResponse((response) => {
  })
}
