<template>
  <div style="height:calc(100vh - 175px); overflow-y:auto;" class="pb-8">
    <!-- SCRIPT DISPLAYER  -->
    <v-simple-table v-if="script.length > 0" class="table-row-border">
      <template #default>
        <thead class="bg-white">
          <tr>
            <th class="width-1">
              &nbsp;
            </th>
            <th class="text-left pl-0 width-5">
              &nbsp;
            </th>
            <th class="text-left width-20">
              {{ $t('header.character') }}
            </th>
            <th class="text-left width-70">
              {{ $t('header.description') }}
            </th>
            <th class="width-4">
              &nbsp;
            </th>
          </tr>
        </thead>
        <draggable
          v-model="cloneScript"
          :disabled="!editRight"
          tag="tbody"
          handle=".handleDragObjective"
          @end="draggableEnd"
        >
          <tr
            v-for="action in script"
            :key="action._id"
            data-test="tr-edit-script-list"
          >
            <td class="bg-white py-4 px-0 pb-2 text-center align-baseline">
              <v-icon small color="brownish-grey" class="handle handleDragObjective">
                mdi-drag-horizontal-variant
              </v-icon>
            </td>
            <td class="bg-white py-4 align-baseline">
              <v-icon v-if="action.type === 'dialogue'">
                mdi-chat-outline
              </v-icon>
              <v-icon v-else-if="action.type === 'note'">
                mdi-lightbulb-on-outline
              </v-icon>
              <v-icon v-else>
                mdi-chat-question-outline
              </v-icon>
            </td>
            <td
              v-if="action.type === 'dialogue' || action.type === 'question'"
              class="bg-white py-4 align-baseline"
            >
              <EditableField
                data-test="select-script-character"
                type="list"
                position="bottom"
                autocomplete
                :edit-right="editRight"
                :value="action.actionData.assetId"
                :options="characters"
                :placeholder="$t('noAsset')"
                large
                @input="editActionScript($event, action._id, 'assetId')"
              />
            </td>
            <td v-else class="bg-white selectAsset py-4" />
            <td v-if="action.type === 'question'" class="bg-white py-4 align-baseline">
              <EditableField
                type="textarea"
                :edit-right="editRight"
                :value="action.actionData.description"
                :placeholder="$t('header.question')"
                rows="4"
                large
                @input="editActionScript($event, action._id, 'description')"
              />
              <div>
                <span v-if="!responsesToShow.includes(action._id)" class="btn-display-responses cursor" @click="showResponses(action._id)">
                  {{ $t('displayResponses') }}
                  <v-icon small color="primary">
                    mdi-chevron-down
                  </v-icon>
                </span>
                <span v-if="responsesToShow.includes(action._id)" class="btn-display-responses cursor" @click="hideResponses(action._id)">
                  {{ $t('hideResponses') }}
                  <v-icon small color="primary">
                    mdi-chevron-up
                  </v-icon>
                </span>
              </div>
              <section v-if="responsesToShow.includes(action._id)" class="mt-4">
                <ResponseEditor
                  :options="action.actionData.options"
                  :scenes="scenes"
                  :current-scene-id="currentSceneId"
                  :action-id="action._id"
                  @addOption="addOptionFromScriptAction"
                  @editOptionLabel="editOptionLabelFromScript"
                  @editOptionRedirection="editOptionRedirectionFromScript"
                  @deleteOption="deleteOptionFromScriptAction"
                />
              </section>
            </td>
            <td v-else class="bg-white mb-0 py-4 align-baseline">
              <EditableField
                data-test="input-scene-description"
                type="textarea"
                :edit-right="editRight"
                :value="action.actionData.description"
                :placeholder="$t('header.description')"
                rows="4"
                large
                @input="editActionScript($event, action._id, 'description')"
              />
            </td>
            <td class="bg-white py-4 align-baseline">
              <edtk-button-icon
                v-show="editRight"
                data-test="button-script-delete"
                icon="delete"
                icon-size="xl"
                color="secondary"
                @click="deleteScriptEditor(action._id)"
              >
              </edtk-button-icon>
            </td>
          </tr>
        </draggable>
      </template>
    </v-simple-table>
    <!-- /SCRIPT DISPLAYER  -->
    <!-- SCRIPT LINE EDITOR  -->
    <v-simple-table v-show="displayNewScriptEditor" :class="[displayQuestionEditor ? 'question-editor-no-border-bottom' : 'table-row-border']">
      <template #default>
        <tbody>
          <tr>
            <td class="bg-white py-4 width-1">
              &nbsp;
            </td>
            <td class="bg-white py-4 width-5">
              <v-icon v-if="actionType === 'dialogue'">
                mdi-chat-outline
              </v-icon>
              <v-icon v-else-if="actionType === 'question'">
                mdi-chat-question-outline
              </v-icon>
              <v-icon v-else>
                mdi-lightbulb-on-outline
              </v-icon>
            </td>
            <td v-if="actionType === 'note'" class="bg-white width-20 py-4" />
            <td
              v-else
              class="bg-white width-20 py-4"
            >
              <EditableField
                v-if="characters.length > 1"
                :value="assetId"
                data-test="select-new-line-script-character"
                type="list"
                position="bottom"
                autocomplete
                :edit-right="true"
                :options="characters"
                :placeholder="$t('selectCharacter')"
                large
                parent-container
                @input="(e) => editAsset(e)"
              />
              <span v-else icon class="primary--text pointer" @click="openAssetEditor('script', 'character')">
                {{ $t('createCharacter') }} <v-icon color="primary" small>mdi-shape-square-plus mdi-rotate-90</v-icon>
              </span>
            </td>
            <td class="bg-white py-4 width-66">
              <EditableField
                v-model="description"
                data-test="textarea-new-line-script-description"
                type="textarea"
                :edit-right="true"
                :placeholder="actionType === 'question' ? $t('header.question') : $t('header.description')"
                rows="4"
                large
              />
            </td>
            <td class="bg-white pa-0 py-4 width-6">
              <edtk-button
                data-test="button-new-line-script-save"
                :disabled="actionType === 'dialogue' ? (!description || !assetId) : !description"
                small
                @click="addScriptEditor"
              >
                {{ $t('save') }}
              </edtk-button>
            </td>
            <td class="bg-white pa-0 py-4 width-2">
              <edtk-button-icon
                data-test="button-new-line-script-cancel"
                color="secondary"
                icon="close"
                icon-size="xl"
                @click="closeScriptEditor"
              >
              </edtk-button-icon>
            </td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
    <!-- /SCRIPT LINE EDITOR  -->
    <!-- RESPONSES EDITOR -->
    <v-simple-table v-show="displayQuestionEditor" class="question-editor-no-border-top">
      <template #default>
        <tbody>
          <tr>
            <td class="bg-white width-1">
              &nbsp;
            </td>
            <td class="bg-white width-5">
                &nbsp;
            </td>
            <td class="bg-white width-20">
                &nbsp;
            </td>
            <td class="bg-white width-70">
              <ResponseEditor
                :options="options"
                :scenes="scenes"
                :current-scene-id="currentSceneId"
                @addOption="addOption"
                @editOptionLabel="editOptionLabel"
                @editOptionRedirection="editOptionRedirection"
                @deleteOption="deleteOption"
              />
            </td>
            <td class="bg-white width-4">
              &nbsp;
            </td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
    <!-- /RESPONSES EDITOR -->
    <div v-if="editRight && !displayNewScriptEditor && !displayQuestionEditor" class="d-flex align-center">
      <span
        data-test="button-scene-script-add"
        class="ma-2 primary--text font-weight-medium cursor"
        @click="newScriptEditor('dialogue')"
      >
        {{ $t('addDialog') }}
      </span>
      /
      <span
        class="ma-2 primary--text font-weight-medium cursor"
        @click="newScriptEditor('question')"
      >
        {{ $t('addQuestion') }}
      </span>
      /
      <span
        data-test="button-scene-script-add"
        class="ma-2 primary--text font-weight-medium cursor"
        @click="newScriptEditor('note')"
      >
        {{ $t('addNote') }}
      </span>
    </div>
    <AssetEditor
      ref="assetEditor"
      :catalog="catalog"
      :update-catalog="updateCatalog"
      @saveAsset="saveNewAsset"
    />
  </div>
</template>

<script>
import _ from 'lodash'
import ObjectID from 'bson-objectid'
import draggable from 'vuedraggable'
import AssetEditor from '../../AssetEditor'
import ResponseEditor from './ResponseEditor'

export default {
  components: { draggable, AssetEditor, ResponseEditor },
  props: {
    assets: {
      type: Array,
      default: () => []
    },
    catalog: {
      type: Array,
      default: () => []
    },
    scenes: {
      type: Array,
      default: () => []
    },
    currentSceneId: {
      type: String
    },
    script: {
      type: Array,
      default: () => []
    },
    updateCatalog: {
      type: Function,
      default: () => () => {}
    },
    editRight: {
      type: Boolean,
      required: true,
      default: false
    }
  },
  data () {
    return {
      actionType: undefined,
      assetId: undefined,
      description: undefined,
      cloneScript: this.script,
      options: [],
      displayNewScriptEditor: false,
      displayQuestionEditor: false,
      displayResponseEditor: false,
      responsesToShow: []
    }
  },
  i18n: {
    messages: {
      fr: {
        header: {
          actionType: 'Type d\'action',
          asset: 'Elément concerné',
          character: 'Personnage',
          description: 'Réplique / Description',
          question: 'Libellé de la question'
        },
        selectCharacter: 'Sélectionner un personnage',
        selectAsset: 'Sélectionner un élément',
        dialogue: 'Dialogue',
        interaction: 'Activité',
        addDialog: 'Ajouter un dialogue',
        addNote: 'Ajouter une note',
        addQuestion: 'Ajouter une question',
        save: 'Enregistrer',
        cancel: 'Annuler',
        delete: 'Supprimer',
        createElement: 'Créer un élément',
        createCharacter: 'Créer un personnage',
        noAsset: 'Aucun élément sélectionné',
        displayResponses: 'Afficher les réponses',
        hideResponses: 'Masquer les réponses'
      },
      en: {
        header: {
          actionType: 'Action type',
          asset: 'Asset',
          character: 'Character',
          description: 'Line / Description',
          question: 'Question'
        },
        selectCharacter: 'Select character',
        selectAsset: 'Select asset',
        dialogue: 'Dialogue',
        interaction: 'Activity',
        addDialog: 'Add dialog',
        addNote: 'Add note',
        addQuestion: 'Add question',
        save: 'Save',
        cancel: 'Cancel',
        delete: 'Delete',
        createElement: 'Create element',
        createCharacter: 'Create character',
        noAsset: 'No asset selected',
        displayResponses: 'Show responses',
        hideResponses: 'Hide responses'
      }
    }
  },
  computed: {
    characters () {
      const characters = this.assets.filter(asset => asset.type === 'character')
      const res = _.map(characters, (character) => { return { id: character._id, label: character.name } })
      res.push({ id: 'createCharacter', label: this.$t('createCharacter'), action: true })
      return res
    }
  },
  watch: {
    script: {
      handler (n, o) {
        this.cloneScript = this.script
      }
    },
    assetId: {
      handler (n, o) {
        if (n === 'createCharacter') {
          this.openAssetEditor('script', 'character')
          this.assetId = undefined
        } else if (n === 'createElement') {
          this.openAssetEditor('script', undefined)
          this.assetId = undefined
        }
      }
    },
    currentSceneId: {
      handler (n, o) {
        this.closeScriptEditor()
        this.responsesToShow = []
      }
    }
  },
  methods: {
    sceneTitle (id) {
      const scene = this.scenes.find(s => s._id === id)
      return `${scene.order} - ${scene.properties.title}`
    },
    draggableEnd () {
      if (!_.isEqual(this.cloneScript, this.script)) {
        this.$nuxt.$emit('updateScriptOrder', this.cloneScript)
      }
    },
    newScriptEditor (actionType) {
      this.actionType = actionType
      if (actionType === 'question') {
        this.displayQuestionEditor = true
        this.addOption()
      }
      this.displayNewScriptEditor = true
    },
    addOption () {
      const _id = ObjectID().toHexString()
      this.options.push({ _id, label: undefined, goToScene: undefined })
      this.displayResponseEditor = true
    },
    addOptionFromScriptAction (scriptId) {
      this.$emit('editActionScriptOption', { data: undefined, actionType: 'addOption', scriptId })
    },
    editOptionLabel ({ data, actionId, optionId }) {
      const optionIdx = this.options.findIndex(o => o._id === optionId)
      this.options[optionIdx].label = data
    },
    editOptionLabelFromScript ({ data, actionId, optionId }) {
      this.$emit('editActionScriptOption', { data, actionType: 'editLabel', scriptId: actionId, optionId })
    },
    editOptionRedirection ({ data, actionId, optionId }) {
      const optionIdx = this.options.findIndex(o => o._id === optionId)
      this.options[optionIdx].goToScene = data
    },
    editOptionRedirectionFromScript ({ data, actionId, optionId }) {
      this.$emit('editActionScriptOption', { data, actionType: 'editGoToScene', scriptId: actionId, optionId })
    },
    deleteOption ({ actionId, optionId }) {
      this.options = this.options.filter(o => o._id !== optionId)
    },
    deleteOptionFromScriptAction ({ actionId, optionId }) {
      const action = this.script.find(a => a._id === actionId)
      const options = action.actionData.options.filter(o => o._id !== optionId)
      this.$emit('editActionScriptOption', { data: options, actionType: 'deleteOption', scriptId: actionId, optionId })
    },
    closeScriptEditor () {
      this.actionType = undefined
      this.assetId = undefined
      this.description = undefined
      this.displayNewScriptEditor = false
      this.displayQuestionEditor = false
      this.displayResponseEditor = false
      this.options = []
    },
    addScriptEditor () {
      if (this.actionType === 'question') {
        const options = this.options.filter((o) => {
          return o.label || o.goToScene
        })
        this.$emit('addScriptEditor', { type: this.actionType, description: this.description, assetId: this.assetId, options })
        this.closeScriptEditor()
      } else {
        this.$emit('addScriptEditor', { type: this.actionType, description: this.description, assetId: this.assetId, options: [] })
        this.closeScriptEditor()
      }
    },
    deleteScriptEditor (scriptId) {
      this.$emit('deleteScriptEditor', scriptId)
    },
    editActionScript (e, id, actionType) {
      const data = e
      if (data === 'createCharacter') {
        this.openAssetEditor('script', 'character')
      } else if (data === 'createElement') {
        this.openAssetEditor('script', undefined)
      } else {
        this.$emit('editActionScript', { data, actionType, id })
      }
    },
    editAsset (e) {
      if (e === 'createCharacter') {
        this.openAssetEditor('script', 'character')
      } else {
        this.assetId = e
      }
    },
    openAssetEditor (scope, assetType) {
      this.$refs.assetEditor.openAssetEditor(scope, assetType)
    },
    saveNewAsset (assetPayload) {
      this.$nuxt.$emit('saveNewAsset', assetPayload)
      this.$refs.assetEditor.closeAssetEditor()
    },
    showResponses (actionId) {
      this.responsesToShow.push(actionId)
    },
    hideResponses (actionId) {
      this.responsesToShow = this.responsesToShow.filter(a => a !== actionId)
    }
  }
}
</script>

<style scoped>
.selectAsset {
  width: 170px;
}
.v-select.selectAction {
  width: 110px;
}
.cursor {
  cursor: pointer;
}
.width-20 {
  width: 20%;
}
.question-border-bottom {
  border-bottom: 1px solid #E1E1E4;
}
.table-row-border {
  border-top: 1px solid #E1E1E4;
  border-right: 1px solid #E1E1E4;
  border-left: 1px solid #E1E1E4;
  border-bottom: 1px solid #E1E1E4;
}
.question-editor-no-border-bottom {
  border: 1px solid #E1E1E4;
  border-bottom: none;
}
.question-editor-no-border-top {
  border: 1px solid #E1E1E4;
  border-top: none;
}
.btn-display-responses {
  font-size: 12px;
  color: #00558A
}
.align-baseline {
  vertical-align: baseline;
}
.width-1 {
  width: 1%;
}
.width-2 {
  width: 2%;
}
.width-4 {
  width: 4%;
}
.width-5 {
  width: 5%;
}
.width-6 {
  width: 6%;
}
.width-70 {
  width: 70%;
}
</style>
