<template>
  <section>
    <edtk-button
      type="outline"
      color="tertiary"
      small
      @click="openMeeting"
    >
      <div class="d-flex align-center">
        <span class="mr-1" style="color:#a889bf;">{{ $t('startMeetingWithGlowbl') }}</span>
        <img :src="`/img/integrations/glowbl.svg`" style="height:18px; vertical-align:middle;" />
      </div>
    </edtk-button>

    <div v-show="false" ref="glowblForm"></div>
  </section>
</template>

<script>
import { mapActions } from 'vuex'
import i18n from './i18n.js'
export default {
  name: 'GlowblMeeting',
  props: {
    objectType: {
      type: String,
      enum: ['project', 'mission'],
      default: () => 'project'
    },
    objectId: {
      type: String,
      required: true
    }
  },
  i18n,
  data () {
    return {
    }
  },
  methods: {
    ...mapActions({
      projectMeeting: 'project/fetchMeeting'
    }),
    async openMeeting () {
      const r = await this.projectMeeting({ meetingType: 'Glowbl', id: this.objectId })
      if (r.data) {
        const form = document.createElement('form')
        form.target = '_blank'
        form.method = 'post' // or "post" if appropriate
        form.action = 'https://account.glowbl.com/auth/provider/lti'

        Object.keys(r.data.params).forEach((k) => {
          const mapInput = document.createElement('input')
          mapInput.type = 'hidden'
          mapInput.name = k
          mapInput.value = r.data.params[k]
          form.appendChild(mapInput)
        }
        )

        try {
          this.$refs.glowblForm.appendChild(form)
          form.submit()
          form.remove()
        } catch (error) {
          console.log(error)
        }
      }
    }
  }
}
</script>

<style>
</style>
