const i18n = {
  messages: {
    fr: {
      users: 'Utilisateurs',
      teams: 'Equipes',
      projectInfo: 'Informations du projet',
      learningObjectInfo: 'Détail de la ressource pédagogique',
      templateInfo: 'Informations du modèle',
      multipleCreation: 'Dans le cas d\'une création multiple, les titres sont générés automatiquement',
      linkedToLO: {
        question: 'Lier le projet à une ressource existante',
        creationPrefixLabel: 'Création',
        updatePrefixLabel: 'Mise à jour'
      },
      fromTemplate: {
        question: 'Comment voulez-vous créer votre projet ?',
        noTemplateTitle: 'Créer un projet vierge',
        noTemplateSubtitle: 'Construisez votre projet sur mesure',
        useTemplateTitle: 'Utiliser un modèle existant',
        useTemplateSubtitle: "Construisez votre projet à partir d'un modèle existant"
      },
      keepMembersfromTemplate: {
        question: 'Conserver les membres du modèle'
      },
      selectLO: "Sélectionnez l'objet existant",
      newProject: {
        name: 'Nom du projet',
        description: 'Description',
        members: 'Membres'
      },
      informations: 'Informations',
      activityType: 'Type d\'activité',
      typeOf: 'Type de ',
      newProjectMember: {
        responsible: 'Réalisateur(s)',
        accountable: 'Approbateur(s)',
        consulted: 'Consulté(s)',
        informed: 'Informé(s)'
      },
      newProjectMemberPlaceholder: {
        responsible: 'Ajouter un réalisateur',
        accountable: 'Ajouter un approbateur',
        consulted: 'Ajouter un consulté',
        informed: 'Ajouter un informé'
      },
      newLearningObject: {
        __t: 'Type d\'objet du catalogue',
        type: 'Type',
        name: 'Nom',
        description: 'Description',
        language: 'Langue'
      },
      tOption: {
        all: 'Tous',
        course: 'Parcours',
        module: 'Module',
        sequence: 'Séquence'
      },
      typeOptions: {
        instructional: 'Pédagogique',
        evaluation: 'Evaluation'
      },
      formLabel: {
        projectTemplate: 'modèle'
      },
      redirectionSubProject: {
        question: 'Votre sous-projet a bien été enregistré. Souhaitez-vous être redirigé vers la page de gestion du sous-projet ?'
      },
      redirectionProject: {
        question: 'Votre projet a bien été enregistré. Souhaitez-vous être redirigé vers la page de gestion du projet ?'
      }
    },
    en: {
      users: 'Users',
      teams: 'Teams',
      projectInfo: 'Project informations',
      learningObjectInfo: 'Catalogue object informations',
      templateInfo: 'Template informations',
      multipleCreation: 'In the case of a multiple creation, the titles are generated automatically',
      linkedToLO: {
        question: 'Link the project to an existing resource',
        creationPrefixLabel: 'Creation',
        updatePrefixLabel: 'Update'
      },
      fromTemplate: {
        question: 'How do you want to create your project?',
        noTemplateTitle: 'Create a blank project',
        noTemplateSubtitle: 'Build your own project',
        useTemplateTitle: 'Use an existing template',
        useTemplateSubtitle: 'Build your project from an existing template'
      },
      keepMembersfromTemplate: {
        question: 'Keep members from template'
      },
      selectLO: 'Select the resource',
      newProject: {
        name: 'Name',
        description: 'Description',
        members: 'Members'
      },
      informations: 'Informations',
      activityType: 'Activity type',
      typeOf: 'Type of ',
      newProjectMember: {
        responsible: 'Responsible',
        accountable: 'Accountable',
        consulted: 'Consulted',
        informed: 'Informed'
      },
      newProjectMemberPlaceholder: {
        responsible: 'Add responsible',
        accountable: 'Add accountable',
        consulted: 'Add consulted',
        informed: 'Add informed'
      },
      newLearningObject: {
        __t: 'Catalog object type',
        type: 'Type',
        name: 'Name',
        description: 'Description',
        language: 'Language'
      },
      tOption: {
        all: 'All',
        course: 'Course',
        module: 'Module',
        sequence: 'Sequence'
      },
      typeOptions: {
        instructional: 'Instructional',
        evaluation: 'Evaluation'
      },
      formLabel: {
        projectTemplate: 'template'
      },
      redirectionSubProject: {
        question: 'Your sub-project has been registered. Would you like to be redirected to the sub-project management page ?'
      },
      redirectionProject: {
        question: 'Your project has been registered. Would you like to be redirected to the project management page ?'
      }
    }
  }
}
module.exports = i18n
