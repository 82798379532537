const i18n = {
  messages: {
    fr: {
      home: 'Accueil',
      dashboard: 'Tableau de bord',
      ld: 'Conception pédagogique',
      pm: 'Gestion de projet',
      mp: 'Marketplace',
      mechanism: 'Dispositif',
      course: 'Parcours',
      module: 'Module',
      sequence: 'Séquence',
      project: 'Projet',
      customer: 'Commanditaire',
      deliverable: 'Livrable',
      task: 'Tâche',
      mission: 'Mission',
      'favorite-edtaker': 'Freelances favoris',
      proposal: 'Mes missions'
    },
    en: {
      home: 'Home',
      dashboard: 'Dashboard',
      ld: 'Learning resources',
      pm: 'Project management',
      mp: 'Marketplace',
      mechanism: 'Mechanism',
      course: 'Course',
      module: 'Module',
      sequence: 'Sequence',
      project: 'Project',
      customer: 'Customer',
      deliverable: 'Deliverable',
      task: 'Task',
      mission: 'Mission',
      'favorite-edtaker': 'Favorite freelance',
      proposal: 'My missions'
    }
  }
}
module.exports = i18n
