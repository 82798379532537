import Vue from 'vue'

import {
  edtkAccordion,
  edtkAvatar,
  edtkBanner,
  edtkButton,
  edtkButtonFab,
  edtkButtonIcon,
  edtkButtonMenu,
  edtkButtonSearch,
  edtkCard,
  edtkCheckbox,
  edtkChips,
  edtkCollapse,
  edtkColorPicker,
  edtkDatePicker,
  // edtkDialog,
  edtkDropdownButton,
  edtkIcon,
  edtkH5p,
  edtkLabel,
  edtkMultiselect,
  edtkNumber,
  edtkRadio,
  edtkRating,
  edtkRichTextEditor,
  edtkSelect,
  edtkSnackbar,
  edtkSwitch,
  edtkTabs,
  edtkText,
  edtkTimeline,
  edtkTimelineItem,
  edtkTreeGrid,
  edtkToken,
  edtkTooltip
} from '@edtake/shared-components'

import edtkDialog from '~/components/common/edtk-dialog'
import Dialog from '~/components/common/Dialog'
import FormErrorDialog from '~/components/common/FormErrorDialog'
import StatusButton from '~/components/common/StatusButton'
import Snackbar from '~/components/common/Snackbar'
import GenerateTemplateDialog from '~/components/common/GenerateTemplateDialog'
import NoAccess from '~/components/common/NoAccess'
import GlowblMeeting from '~/components/integration/tiers/glowbl/meetingBtn'
import Avatar from '~/components/common/avatar'
import AvatarWorkspace from '~/components/common/avatarWorkspace'
import DisableSmallDevices from '~/components/common/DisableSmallDevices'

import EditableField from '~/components/common/EditableField'

const components = {
  edtkAccordion,
  edtkAvatar,
  edtkBanner,
  edtkButton,
  edtkButtonFab,
  edtkButtonIcon,
  edtkButtonMenu,
  edtkButtonSearch,
  edtkH5p,
  edtkIcon,
  edtkLabel,
  edtkCard,
  edtkCheckbox,
  edtkChips,
  edtkCollapse,
  edtkColorPicker,
  edtkDatePicker,
  edtkDialog,
  edtkDropdownButton,
  edtkNumber,
  edtkMultiselect,
  edtkRadio,
  edtkRating,
  edtkRichTextEditor,
  edtkSelect,
  edtkSnackbar,
  edtkSwitch,
  edtkTabs,
  edtkText,
  edtkTimeline,
  edtkTimelineItem,
  edtkTreeGrid,
  edtkToken,
  edtkTooltip,
  Dialog,
  Snackbar,
  FormErrorDialog,
  EditableField,
  GenerateTemplateDialog,
  NoAccess,
  GlowblMeeting,
  StatusButton,
  Avatar,
  AvatarWorkspace,
  DisableSmallDevices
}

Object.entries(components).forEach(([name, component]) => {
  Vue.component(name, component)
})
