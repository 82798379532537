const _ = require('lodash')

const classifyAll = (inputStrings, customMap) => {
  const results = inputStrings.map((string) => {
    // Initialize entry for each input string
    const entry = {
      inputString: string,
      bloomLabels: []
    }
    // Match the Bloom's Taxonomy verb list
    customMap.forEach((mapping) => {
      const verb = _.deburr(mapping.verb).toLowerCase()
      const re = new RegExp(`\\b${verb}\\b`, 'i')
      const match = re.exec(_.deburr(string))
      if (match) {
        entry.bloomLabels.push({
          index: match.index,
          verb: string.substring(match.index, match.index + match[0].length),
          text: string.substring(match.index + match[0].length + 1, string.length),
          mainLevel: mapping.mainLevel,
          levels: mapping.levels
        })
      }
    })
    // Sort the bloom labels
    if (entry.bloomLabels.length > 1) {
      entry.bloomLabels.sort((first, second) => first.index - second.index)
    }
    return entry
  })
  return results
}

export default ctx => ({
  classifyAll
})
