const i18n = {
  messages: {
    fr: {
      display: 'Afficher',
      noTemplate: "Vous n'avez pas de modèle pour le moment",
      emptyCategory: 'Aucun modèle',
      tOption: {
        all: 'Tous',
        project: 'Projet',
        deliverable: 'Livrable',
        task: 'Tâche',
        richDocument: 'Document texte',
        storyboard: 'Storyboard'
      },
      contributor: 'Contributeur',
      category: 'Catégorie',
      internal: 'Modèles de l\'espace de travail',
      external: 'Modèles de la communauté',
      uncategorized: 'Non catégorisé'
    },
    en: {
      display: 'Display',
      noTemplate: "You don't have any template at the moment",
      emptyCategory: 'No template',
      tOption: {
        all: 'All',
        project: 'Project',
        deliverable: 'Deliverable',
        task: 'Task',
        richDocument: 'Rich document',
        storyboard: 'Storyboard'
      },
      contributor: 'Contributor',
      category: 'Category',
      internal: 'Workspace templates',
      external: 'Community templates',
      uncategorized: 'Not categorised'
    }
  }
}
module.exports = i18n
