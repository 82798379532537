/* eslint-disable no-console */
import _ from 'lodash'

// TODO: Store refactor - remove store
export const state = () => ({
})

export const getters = {
}

export const actions = {
  getChildren ({ commit }, { id }) {
    return new Promise((resolve, reject) => {
      this.$edtake.projectItem.getChildren({ id, $queryParameters: { populateChildren: true } })
        .then((d) => {
          resolve(d)
        })
        .catch((err) => {
          console.error('ERROR', err)
          reject(err)
        })
    })
  },
  delete ({ commit }, { id }) {
    return new Promise((resolve, reject) => {
      this.$edtake.projectItem.delete({ id })
        .then((d) => {
          resolve(d)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  deleteChildren ({ commit }, { id, childrenId }) {
    return new Promise((resolve, reject) => {
      this.$edtake.projectItem.deleteChildren({ id, childrenId })
        .then((d) => {
          resolve(d)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  getAllByTeam ({ commit }, { id, body }) {
    return new Promise((resolve, reject) => {
      this.$edtake.projectItem.getAllByTeam({ id, $queryParameters: { ...body } })
        .then((d) => {
          resolve(d)
        })
        .catch((err) => {
          console.error('ERROR', err)
          reject(err)
        })
    })
  },
  getState ({ commit }, { $queryParameters = {} } = { $queryParameters: { } }) {
    return new Promise((resolve, reject) => {
      this.$edtake.projectItem.getState({ $queryParameters })
        .then((d) => {
          resolve(d)
        })
        .catch((err) => {
          console.error('ERROR', err)
          reject(err)
        })
    })
  },
  getNoiselessPi ({ commit }, { id, body }) {
    return new Promise((resolve, reject) => {
      this.$edtake.projectItem.getNoiselessPi({ id, $queryParameters: { ...body } })
        .then((d) => {
          resolve(d)
        })
        .catch((err) => {
          console.error('ERROR', err)
          reject(err)
        })
    })
  },
  addMilestone ({ commit }, { id, body }) {
    return new Promise((resolve, reject) => {
      this.$edtake.projectItem.addMilestone({ id, body })
        .then((d) => {
          resolve(d)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  deleteMilestone ({ commit }, { id, idMilestone, parentType }) {
    return new Promise((resolve, reject) => {
      this.$edtake.projectItem.deleteMilestone({ id, idMilestone, parentType })
        .then((d) => {
          resolve(d)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  updateMilestone ({ commit }, { id, idMilestone, body }) {
    return new Promise((resolve, reject) => {
      this.$edtake.projectItem.updateMilestone({ id, idMilestone, body })
        .then((d) => {
          resolve(d)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  bulkUpdateOrder ({ commit, dispatch }, body) {
    return new Promise((resolve, reject) => {
      this.$edtake.projectItem.bulkUpdateOrder({ body })
        .then((d) => {
          dispatch('emitUpdateMutation', { arr: d })
          resolve(d)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  bulkUpdate ({ commit, dispatch }, { body, recalculate }) {
    return new Promise((resolve, reject) => {
      this.$edtake.projectItem.bulkUpdate({ body })
        .then((d) => {
          dispatch('emitUpdateMutation', { arr: d, recalculate })
          resolve(d)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  async emitUpdateMutation ({ commit, dispatch }, { arr, recalculate }) {
    const pro = _.filter(arr, (o) => { return o.__t === 'project' })
    const del = _.filter(arr, (o) => { return o.__t === 'deliverable' })
    const ta = _.filter(arr, (o) => { return o.__t === 'task' })
    const pa = _.filter(arr, (o) => { return o.__t === 'phase' })
    if (pro) { await commit('project/UPDATE_MANY', pro, { root: true }) }
    if (del) { await commit('deliverable/UPDATE_MANY', del, { root: true }) }
    if (ta) { await commit('task/UPDATE_MANY', ta, { root: true }) }
    if (pa) { await commit('phase/UPDATE_MANY', pa, { root: true }) }
    if (recalculate) {
      await dispatch('bulkManagement/_recalculateProjectItems', {}, { root: true })
    }
  },
  async fetchIfNotExist ({ dispatch }, arr) {
    const pro = _.filter(arr, (o) => { return o.__t === 'project' })
    const del = _.filter(arr, (o) => { return o.__t === 'deliverable' })
    const ta = _.filter(arr, (o) => { return o.__t === 'task' })
    const pa = _.filter(arr, (o) => { return o.__t === 'phase' })
    if (pro) { await dispatch('project/_fetchIfNotExist', pro, { root: true }) }
    if (del) { await dispatch('deliverable/_fetchIfNotExist', del, { root: true }) }
    if (ta) { await dispatch('task/_fetchIfNotExist', ta, { root: true }) }
    if (pa) { await dispatch('phase/_fetchIfNotExist', pa, { root: true }) }
  }
}

export const mutations = {
}
