<template>
  <v-row>
    <v-col cols="12" md="8" offset-md="2">
      <v-card class="pa-12">
        <h2 class="text-rem-16 font-weight-bold mb-10">
          <i class="mdi mdi-lock mr-2" />{{ $t('title') }}
        </h2>
        <div class="d-flex">
          <div class="mr-10">
            <p v-html="$t('text')"></p>
            <div class="mt-10 text-center d-flex justify-space-around">
              <edtkButton
                :label="$t('scheduleDemo')"
                type="filled"
                color="success"
                href="https://www.edtake.com/fr/formulaire-demonstration?utm=app.edtake.com"
                target="_blank"
              />
            </div>
          </div>
          <img src="~assets/img/emptyMission.svg" style="max-width:40%;" />
        </div>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import i18n from './i18n.js'
export default {
  name: 'FeatureMissionManagement',
  i18n
}
</script>

<style>
</style>
