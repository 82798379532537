<template>
  <div>
    <edtk-snackbar
      :value="snackbar.open"
      :type="snackbar.status ? 'success' : 'warning'"
      :message="snackbar.text || $t('saved')"
      data-test="snackbar-course-properties"
      closable
      @close="close()"
    ></edtk-snackbar>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import i18n from './i18n.js'

export default {
  name: 'Snackbar',
  i18n,
  computed: {
    ...mapGetters({
      snackbar: 'snackbar/getSnackbarOpen'
    })
  },
  methods: {
    ...mapActions({
      closeSnackbar: 'snackbar/setSnackbarClose'
    }),
    close () {
      this.closeSnackbar()
    }
  }
}
</script>
