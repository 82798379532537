const compositionRecommandation = (learningObjectType, activityType, objectives = ['']) => {
  const onObjectives = []
  const reverseObjectives = objectives.reverse()
  switch (learningObjectType) {
    case 'course':
      // Course activityType is in fact the type of skills
      switch (activityType) {
        case 'softSkill':
          reverseObjectives.forEach((o) => {
            onObjectives.push({ name: 'activityTypes.title.learning', objectives: [o], prefix: `${o} - `, activityType: 'learning' })
            onObjectives.push({ name: 'activityTypes.title.coaching', objectives: [o], prefix: `${o} - `, activityType: 'coaching' })
            onObjectives.push({ name: 'activityTypes.title.evaluation', objectives: [o], prefix: `${o} - `, activityType: 'evaluation' })
          })
          return [
            { name: 'activityTypes.title.diagnostic', activityType: 'diagnostic' },
            { name: 'activityTypes.title.harmonisation', activityType: 'harmonisation' },
            { name: 'activityTypes.title.introduction', activityType: 'introduction' },
            ...onObjectives,
            { name: 'activityTypes.title.feedback', activityType: 'feedback' },
            { name: 'activityTypes.title.synthesis', activityType: 'synthesis' },
            { name: 'activityTypes.title.postTrainingFollowUp15j', activityType: 'postTrainingFollowUp' },
            { name: 'activityTypes.title.postTrainingFollowUp30j', activityType: 'postTrainingFollowUp' },
            { name: 'activityTypes.title.postTrainingFollowUp60j', activityType: 'postTrainingFollowUp' }
          ]
        default:
          reverseObjectives.forEach((o) => {
            onObjectives.push({ name: 'activityTypes.title.learning', objectives: [o], prefix: `${o} - `, activityType: 'learning' })
            onObjectives.push({ name: 'activityTypes.title.evaluation', objectives: [o], prefix: `${o} - `, activityType: 'evaluation' })
          })
          return [
            { name: 'activityTypes.title.diagnostic', activityType: 'diagnostic' },
            { name: 'activityTypes.title.harmonisation', activityType: 'harmonisation' },
            { name: 'activityTypes.title.introduction', activityType: 'introduction' },
            ...onObjectives,
            { name: 'activityTypes.title.feedback', activityType: 'feedback' },
            { name: 'activityTypes.title.synthesis', activityType: 'synthesis' },
            { name: 'activityTypes.title.postTrainingFollowUp15j', activityType: 'postTrainingFollowUp' },
            { name: 'activityTypes.title.postTrainingFollowUp30j', activityType: 'postTrainingFollowUp' },
            { name: 'activityTypes.title.postTrainingFollowUp60j', activityType: 'postTrainingFollowUp' }
          ]
      }
    case 'module':
      switch (activityType) {
        case 'diagnostic':
          return [
            { name: 'activityTypes.title.introduction', activityType: 'introduction' },
            { name: 'activityTypes.title.evaluation', activityType: 'evaluation' }
          ]
        case 'harmonisation':
          return [
            { name: 'activityTypes.title.introduction', activityType: 'introduction' },
            { name: 'activityTypes.title.learning', activityType: 'learning' },
            { name: 'activityTypes.title.exercise', activityType: 'exercise' },
            { name: 'activityTypes.title.evaluation', activityType: 'evaluation' },
            { name: 'activityTypes.title.feedback', activityType: 'feedback' },
            { name: 'activityTypes.title.synthesis', activityType: 'synthesis' }
          ]
        case 'introduction':
          reverseObjectives.forEach((o) => {
            onObjectives.push({ name: 'activityTypes.title.learning', objectives: [o], prefix: `${o} - `, activityType: 'learning' })
            onObjectives.push({ name: 'activityTypes.title.exercise', objectives: [o], prefix: `${o} - `, activityType: 'exercise' })
            onObjectives.push({ name: 'activityTypes.title.evaluation', objectives: [o], prefix: `${o} - `, activityType: 'evaluation' })
          })
          return [
            { name: 'activityTypes.title.introduction', activityType: 'introduction' },
            ...onObjectives
          ]
        case 'awareness':
          reverseObjectives.forEach((o) => {
            onObjectives.push({ name: 'activityTypes.title.learning', objectives: [o], prefix: `${o} - `, activityType: 'learning' })
            onObjectives.push({ name: 'activityTypes.title.exercise', objectives: [o], prefix: `${o} - `, activityType: 'exercise' })
          })
          return [
            ...onObjectives
          ]
        case 'coaching':
          reverseObjectives.forEach((o) => {
            onObjectives.push({ name: 'activityTypes.title.coaching', objectives: [o], prefix: `${o} - `, activityType: 'coaching' })
            onObjectives.push({ name: 'activityTypes.title.experience', objectives: [o], prefix: `${o} - `, activityType: 'experience' })
            onObjectives.push({ name: 'activityTypes.title.evaluation', objectives: [o], prefix: `${o} - `, activityType: 'evaluation' })
          })
          return [
            { name: 'activityTypes.title.introduction', activityType: 'introduction' },
            ...onObjectives,
            { name: 'activityTypes.title.feedback', activityType: 'feedback' },
            { name: 'activityTypes.title.synthesis', activityType: 'synthesis' }
          ]
        case 'experience':
          reverseObjectives.forEach((o) => {
            onObjectives.push({ name: 'activityTypes.title.experience', objectives: [o], prefix: `${o} - `, activityType: 'experience' })
            onObjectives.push({ name: 'activityTypes.title.evaluation', objectives: [o], prefix: `${o} - `, activityType: 'evaluation' })
          })
          return [
            { name: 'activityTypes.title.introduction', activityType: 'introduction' },
            ...onObjectives,
            { name: 'activityTypes.title.synthesis', activityType: 'synthesis' }
          ]
        case 'evaluation':
          reverseObjectives.forEach((o) => {
            onObjectives.push({ name: 'activityTypes.title.evaluation', objectives: [o], prefix: `${o} - `, activityType: 'evaluation' })
          })
          return [
            { name: 'activityTypes.title.introduction', activityType: 'introduction' },
            ...onObjectives,
            { name: 'activityTypes.title.feedback', activityType: 'feedback' },
            { name: 'activityTypes.title.synthesis', activityType: 'synthesis' }
          ]
        case 'synthesis':
          return [
            { name: 'activityTypes.title.introduction', activityType: 'introduction' },
            { name: 'activityTypes.title.synthesis', activityType: 'synthesis' }
          ]
        case 'postTrainingFollowUp':
          return [
            { name: 'activityTypes.title.introduction', activityType: 'introduction' },
            { name: 'activityTypes.title.evaluation', activityType: 'evaluation' },
            { name: 'activityTypes.title.feedback', activityType: 'feedback' }
          ]
        default:
          reverseObjectives.forEach((o) => {
            onObjectives.push({ name: 'activityTypes.title.learning', objectives: [o], prefix: `${o} - `, activityType: 'learning' })
            onObjectives.push({ name: 'activityTypes.title.evaluation', objectives: [o], prefix: `${o} - `, activityType: 'evaluation' })
          })
          return [
            { name: 'activityTypes.title.introduction', activityType: 'introduction' },
            ...onObjectives,
            { name: 'activityTypes.title.feedback', activityType: 'feedback' },
            { name: 'activityTypes.title.synthesis', activityType: 'synthesis' }
          ]
      }

    default:
      break
  }
}

const compositionChecklist = (learningObjectType, activityType) => {
  switch (learningObjectType) {
    case 'course':
      // Course activityType is in fact the type of skills
      switch (activityType) {
        case 'softSkill':
          return [
            'diagnostic',
            'harmonisation',
            'introduction',
            'learning',
            'coaching',
            'evaluation',
            'feedback',
            'synthesis',
            'postTrainingFollowUp'
          ]
        default:
          return [
            'diagnostic',
            'harmonisation',
            'introduction',
            'learning',
            'evaluation',
            'feedback',
            'synthesis',
            'postTrainingFollowUp'
          ]
      }
    case 'module':
      switch (activityType) {
        case 'diagnostic':
          return [
            'introduction',
            'evaluation'
          ]
        case 'harmonisation':
          return [
            'introduction',
            'learning',
            'exercise',
            'evaluation',
            'feedback',
            'synthesis'
          ]
        case 'introduction':
          return [
            'introduction',
            'learning',
            'exercise',
            'evaluation'
          ]
        case 'coaching':
          return [
            'introduction',
            'coaching',
            'experience',
            'evaluation',
            'feedback',
            'synthesis'
          ]
        case 'exercise':
          return [
            'introduction',
            'exercise',
            'evaluation',
            'feedback'
          ]
        case 'experience':
          return [
            'introduction',
            'experience',
            'evaluation',
            'synthesis'
          ]
        case 'evaluation':
          return [
            'introduction',
            'evaluation',
            'feedback',
            'synthesis'
          ]
        case 'synthesis':
          return [
            'introduction',
            'synthesis'
          ]
        case 'postTrainingFollowUp':
          return [
            'introduction',
            'evaluation',
            'feedback'
          ]
        default:
          return [
            'introduction',
            'learning',
            'exercise',
            'experience',
            'evaluation',
            'feedback',
            'synthesis'
          ]
      }

    default:
      break
  }
}

export default ctx => ({
  compositionRecommandation,
  compositionChecklist
})
