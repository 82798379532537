/* eslint-disable no-console */
import _ from 'lodash'

const deliverablesSchema = {
  estimations: Object,
  _id: String,
  category: String,
  phase: String,
  createdAt: String,
  deleted: Boolean,
  name: String,
  description: String,
  status: String,
  archived: Boolean,
  tags: Array,
  parent: Object,
  parentType: String,
  workspace: String,
  members: Array,
  predecessors: Array,
  __t: String,
  milestones: Array,
  startDate: String,
  firstDraftDate: String,
  dueDate: String,
  __v: Number,
  id: String
}

export const state = () => ({
  deliverables: []
})

export const getters = {
  get (state) {
    return state.deliverables
  },
  getOne (state) {
    return (id) => { return state.deliverables.find(deliverable => deliverable._id === id) }
  }
}

export const actions = {
  clear ({ commit }) {
    commit('CLEAR')
  },
  fetch ({ dispatch, commit }, { $queryParameters = {} } = { $queryParameters: { $queryParameters: { populate: 'children' } } }) {
    return new Promise((resolve, reject) => {
      this.$edtake.deliverable.get({ $queryParameters })
        .then((d) => {
          commit('SET', d)
          if (d.children) {
            dispatch('projectItem/emitUpdateMutation', { arr: d.children }, { root: true })
          }
          resolve(d)
        })
        .catch((err) => {
          console.error('ERROR', err)
          reject(err)
        })
    })
  },
  fetchOne ({ dispatch, commit }, { id, $queryParameters = { populate: 'children' } }) {
    return new Promise((resolve, reject) => {
      this.$edtake.deliverable.getOne({ id, $queryParameters })
        .then((d) => {
          commit('UPDATE_ONE', d)
          if (d.children) {
            dispatch('projectItem/emitUpdateMutation', { arr: d.children }, { root: true })
          }
          resolve(d)
        })
        .catch((err) => {
          console.error('ERROR', err)
          reject(err)
        })
    })
  },
  create ({ dispatch, commit, rootGetters }, { body }) {
    return new Promise((resolve, reject) => {
      const workspace = rootGetters['workspace/getCurrentId']
      body.workspace = workspace
      this.$edtake.deliverable.create({
        body
      }).then((d) => {
        commit('CREATE', d)
        dispatch('project/_createdChildren', d, { root: true })
        resolve(d)
      })
        .catch((err) => {
          console.error('ERROR', err)
          reject(err)
        })
    })
  },
  bulkCreate ({ dispatch, commit, rootGetters }, { parentProject, body }) {
    return new Promise((resolve, reject) => {
      const workspace = rootGetters['workspace/getCurrentId']
      body.workspace = workspace
      this.$edtake.deliverable.bulkCreate({
        parentProject,
        body
      }).then((d) => {
        commit('CREATE_BULK', d)
        if (parentProject) {
          dispatch('project/_createdChildren', d, { root: true })
        }
        resolve(d)
      })
        .catch((err) => {
          console.error('ERROR', err)
          reject(err)
        })
    })
  },
  fetchChildren ({ dispatch }, { id }) {
    return new Promise((resolve, reject) => {
      this.$edtake.deliverable.getChildren({ id })
        .then(async (d) => {
          await dispatch('projectItem/emitUpdateMutation', { arr: d }, { root: true })
          resolve(d)
        })
        .catch((err) => {
          console.error('ERROR', err)
          reject(err)
        })
    })
  },
  fetchParent ({ state, commit }, { id }) {
    return new Promise((resolve, reject) => {
      this.$edtake.deliverable.getParents({ id, $queryParameters: { detailed: true } })
        .then((d) => {
          resolve(d)
        })
        .catch((err) => {
          console.error('ERROR', err)
          reject(err)
        })
    })
  },
  update ({ dispatch, commit }, { id, parentType, body }) {
    return new Promise((resolve, reject) => {
      delete body.chapters
      this.$edtake.deliverable.update({
        id,
        body
      })
        .then((d) => {
          commit('UPDATE_ONE', d)
          if (d.parentProject) {
            dispatch(`${d.parentProject.__t}/_updatedChildren`, d, { root: true })
          }
          resolve(d)
        })
        .catch((err) => {
          console.error('ERROR', err)
          reject(err)
        })
    })
  },
  setStatus ({ commit, dispatch }, { id, parentType, status }) {
    return new Promise((resolve, reject) => {
      this.$edtake.deliverable.setStatus({
        id,
        body: { status }
      })
        .then((d) => {
          commit('UPDATE_ONE', d)
          if (d.parentProject) {
            dispatch(`${d.parentProject.__t}/_updatedChildren`, d, { root: true })
          }
          resolve(d)
        })
        .catch((err) => {
          console.error('ERROR', err)
          reject(err)
        })
    })
  },
  remove ({ dispatch, commit }, { id, item }) {
    return new Promise((resolve, reject) => {
      this.$edtake.deliverable.delete({
        id
      }).then((d) => {
        commit('REMOVE', id)
        if (item.learningObject) {
          dispatch(`${item.learningObject.__t}/_removeProject`, item, { root: true })
        }
        resolve(d)
      })
        .catch((err) => {
          console.error('ERROR', err)
          reject(err)
        })
    })
  },
  archive ({ dispatch }, { id }) {
    return new Promise((resolve, reject) => {
      this.$edtake.deliverable.archive({ id })
        .then((d) => {
          resolve(d)
        })
        .catch((err) => {
          if (err.response.status === 403) {
            reject('NOT_AUTHORIZED')
          }
          reject(err)
        })
    })
  },
  toTemplate ({ dispatch, commit, rootGetters }, { id, body }) {
    const workspace = rootGetters['workspace/getCurrentId']
    body.workspace = workspace
    return new Promise((resolve, reject) => {
      this.$edtake.deliverable.toTemplate({
        id,
        body
      }).then((d) => {
        dispatch('template/_update', d, { root: true })
        resolve(d)
      }).catch((err) => {
        console.error('ERROR', err)
        reject(err)
      })
    })
  },
  setMembers ({ commit }, d) {
    commit('SET_MEMBERS', d)
  },
  /* Dispatch from other store */
  _addDocument ({ commit }, d) {
    commit('ADD_DOCUMENT', d)
  },
  _updateDocument ({ commit }, d) {
    commit('UPDATE_DOCUMENT', d)
  },
  _removeDocument ({ commit }, d) {
    commit('REMOVE_DOCUMENT', d)
  },
  _updatedChildren ({ commit }, d) {
    if (Array.isArray(d)) {
      _.forEach(d, e => commit('UPDATED_CHILDREN', e))
    } else {
      commit('UPDATED_CHILDREN', d)
    }
  },
  _update ({ commit }, d) {
    if (Array.isArray(d)) {
      _.forEach(d, e => commit('UPDATE_ONE', e))
    } else {
      commit('UPDATE_ONE', d)
    }
  },
  _fetchIfNotExist ({ state, dispatch }, a) {
    const alreadyExist = a.every(d => state.deliverables.some(s => s._id === d._id))
    if (!alreadyExist) {
      dispatch('fetch')
    }
  }
}

export const mutations = {
  SET (state, value) {
    state.deliverables = value
  },
  SET_MEMBERS (state, value) {
    _.forEach(value.ids, (id, $index) => {
      const item = state.deliverables.find(item => item._id.toString() === id.toString())
      if (item) {
        Object.assign(item, { ...item, members: value.newObj[$index].members })
        state.deliverables = [...state.deliverables]
      }
    })
  },
  UPDATE_ONE (state, value) {
    const item = state.deliverables.find(item => item._id.toString() === value._id.toString())
    if (item) {
      Object.assign(item, value)
      state.deliverables = [...state.deliverables] // Force refresh
    } else {
      state.deliverables = [...state.deliverables, value]
    }
  },
  UPDATE_MANY (state, arr) {
    const expectedIds = _.map(arr, o => o._id)
    const filteredState = _.filter(state.deliverables, o => !expectedIds.includes(o._id))
    arr = _.map(arr, (updatedPI) => {
      let item = state.deliverables.find(item => (item._id && item._id.toString()) === (updatedPI._id && updatedPI._id.toString()))
      if (item) {
        Object.assign(item, updatedPI)
      } else {
        item = updatedPI
      }
      return item
    })
    state.deliverables = [...filteredState, ...arr] // Force refresh
  },
  CREATE (state, value) {
    state.deliverables = [...state.deliverables, value]
  },
  CREATE_BULK (state, value) {
    state.deliverables = [...state.deliverables, ...value]
  },
  CLEAR (state) {
    state.deliverables = []
  },
  REMOVE (state, value) {
    state.deliverables = state.deliverables.filter(deliverable => deliverable._id !== value)
  },
  UPDATED_CHILDREN (state, value) {
    if (value.parentProject) {
      const item = _.find(state.deliverables, item => item._id.toString() === value.parentProject._id.toString())
      if (item) {
        const children = _.find(item.children, child => child._id.toString() === value._id.toString())
        if (children) {
          Object.assign(children, value)
          state.deliverables = [...state.deliverables]
        }
      }
    }
  },
  ADD_DOCUMENT (state, value) {
    const item = state.deliverables.find(item => item._id.toString() === value.parent._id.toString())
    console.log('🚀 ~ file: deliverable.js:325 ~ ADD_DOCUMENT ~ item:', item, item.documents)
    if (item) {
      item.documents = [...item.documents, value]
    }
  },
  UPDATE_DOCUMENT (state, value) {
    const item = state.deliverables.find((item) => {
      return item._id.toString() === value.parent._id.toString()
    })
    if (item) {
      const documentIdx = item.documents.findIndex(doc => doc._id === value._id)
      item.documents[documentIdx] = Object.assign({}, item.documents[documentIdx], value)
    }
  },
  REMOVE_DOCUMENT (state, value) {
    const item = state.deliverables.find(item => item._id.toString() === value.parent._id.toString())
    if (item) {
      item.documents = item.documents.filter(d => d._id !== value._id)
    }
  }
}
