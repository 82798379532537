const i18n = {
  messages: {
    fr: {
      deliverable: 'livrable | livrables',
      confirmPage: {
        iframeTitle: 'Demande enregistrée !',
        iframeParagraph: 'Nous avons bien enregistré votre demande.<br>Notre équipe revient vers vous très vite.',
        missionSaved: 'Offre de mission enregistrée !',
        toContinue: 'Votre offre de mission est actuellement au statut "Brouillon".<br>Vous allez pouvoir compléter/modifier les informations relatives aux prestations attendues avant de valider son envoi.'
      },
      formLabel: {
        employeesNumber: 'nombre d\'employés',
        job: 'métier',
        location: 'localisation',
        data: 'informations complémentaires',
        acceptedCgu: 'conditions générales d\'utilisation'
      },
      step1: {
        title: 'Création d\'une mission',
        description: 'Postez une offre de mission, nous nous chargeons de sélectionner les meilleurs talents pour répondre à vos besoins.'
      },
      step2: {
        title: 'Plus d\'informations',
        description: 'Dites-nous en un peu plus sur votre besoin. Plus votre offre est précise, meilleure sera notre sélection de profils.'
      },
      step3: {
        title: 'A propos de votre mission',
        description: 'Un briefing, un budget et un contexte général... Votre offre est bientôt complète.'
      },
      step4: {
        title: 'Bientôt fini!',
        description: 'Quelques informations vous concernant et nous pourrons prendre en charge votre demande.'
      },
      step5: {
        title: "C'est fini!"
      },
      errors: {
        maxSize: 'La taille du fichier doit être inférieur à 20 Mo.',
        invalidType: 'Type de fichier invalide'
      },
      size: {
        one: 'Moi uniquement',
        noData: 'Je ne sais pas'
      },
      seeMission: 'Consulter la mission',
      close: 'Fermer',
      search: 'Recherche',
      importDoc: 'Importer des documents.',
      dropOrBrowse: 'Glisser-déposer ou ',
      browse: 'parcourir',
      files: 'vos fichiers.',
      dropHere: 'Déposer ici',
      jobOptions: {
        'Project Management': { title: 'Chef de projet', description: 'Vous êtes à la recherche de support dans la gestion de votre projet de formation.' },
        Trainer: { title: 'Formateur', description: 'Formateur webinaire, présentiel ou bien formateur concepteur, vous cherchez un expert pouvant répondre à votre besoin de formation.' },
        'Graphic design': { title: 'Graphiste', description: 'Personnages, décors, illustrations, 2D, 3D, ... Vous souhaitez produire les éléments graphiques de votre formation. ' },
        'Instructional designer': { title: 'Ingénieur pédagogique', description: 'Vous souhaitez concevoir une formation, réaliser votre synopsis ou votre storyboard.' },
        'Creation using authoring tool': { title: 'Monteur sur outil auteur', description: 'Vous souhaitez réaliser le montage d\'une formation sur un outil auteur ou celui de votre LMS.' },
        Other: { title: 'Autre', description: 'Auteur, Acteur, Voix-off, Vidéaste, Administrateur LMS...' }
      },
      missionTypes: {
        DELIVERABLE: 'Un livrable',
        TIME: 'Une compétence'
      },
      jobQuestion: 'Quel métier recherchez-vous ?',
      missionTypeQuestion: ' Que recherchez-vous ?',
      deliverableTypeQuestion: 'Quel type de livrable recherchez-vous ?',
      missionTitle: 'Titre de la mission',
      missionTitlePlaceholder: 'Ajouter le titre de la mission',
      additionalQuestions: 'Quelques informations complémentaires...',
      numberOfRevisionsByDeliverable: 'Nombre d\'itérations par livrable',
      addNumberOfRevisionsByDeliverable: 'Renseigner un nombre d\'itérations',
      briefingTitle: 'Briefing de la mission',
      briefingPlaceholder: 'Briefing : Contexte de la mission, ...',
      budget: {
        title: 'Avez-vous un budget pour cette mission ?',
        titleDailyRate: 'Avez-vous un budget pour cette mission (taux journalier) ?',
        sentence: 'mon budget sera de:',
        askProposals: 'Je ne sais pas, faites moi des propositions.'
      },
      addAnswer: 'Votre réponse',
      addDuration: 'Renseigner une charge de travail',
      addLocation: 'Renseigner une localisation',
      addLanguage: 'Renseigner une langue',
      addLCMS: 'Sélectionner un LCMS',
      addAuthoringTool: 'Sélectionner un outil auteur',
      addDocuments: 'Ajouter des documents...',
      name: 'Nom',
      addName: 'Renseigner votre nom',
      addCompanyName: 'Renseigner le nom de l\'entreprise',
      companyType: 'Type d\'entreprise',
      addCompanyType: 'Sélectionner un type d\'entreprise',
      employeesNumber: 'Nombre d\'employés',
      addEmployeesNumber: 'Sélectionner un nombre d\'employés',
      firstname: 'Prénom',
      addFirstname: 'Renseigner votre prénom',
      job: 'Métier',
      addJob: 'Renseigner votre métier',
      email: 'Email',
      addEmail: 'Renseigner votre email',
      phoneNumber: 'Numéro de téléphone',
      addPhoneNumber: 'Ajouter un numéro de téléphone',
      aboutCompany: 'A propos de votre entreprise...',
      aboutUser: 'A propos de vous...',
      aboutJob: 'A propos du métier...',
      generalTerms: {
        start: 'J\'ai lu et j\'accepte les ',
        link: 'conditions générales d\'utilisation du service'
      },
      primarySkillAndLevel: 'Quel type de compétence et quel niveau d\'expérience recherchez vous ?',
      skillLevel: 'Quel niveau d\'expérience attendez-vous ?',
      secondarySkills: 'Avez-vous besoin de compétences secondaires ?',
      deliveryDate: 'Date de livraison',
      startDate: 'Date de début',
      endDate: 'Date de fin',
      duration: 'Charge de travail en jours',
      location: 'Localisation',
      pickFromCurrentProject: 'Quels livrables du projet courant souhaitez-vous sous-traiter ?',
      noDeliverableOption: 'Aucun livrable pour ce métier',
      otherDeliverableNoData: 'Aucun livrable du projet en cours ne peut être ajouté',
      deliverables: {
        Synopsis: 'Synopsis',
        'Storyboard/Script': 'Storyboard/Script',
        'Game design': 'Game design',
        'Training design': 'Ingénierie de formation',
        'Psychometric Analysis': 'Analyse psychométrique',
        'Certification Management': 'Gestion de certification',
        'Webinar training': 'Formation webinaire',
        'Face-to-face training': 'Formation en présentiel',
        'Training video record': 'Enregistrement capsule vidéo',
        '2D character design': 'Personnage 2D',
        '2D environment design': 'Environnement 2D',
        Illustration: 'Illustration',
        '3D character design': 'Personnage 3D',
        '3D environment design': 'Environnement 3D',
        '3D object design': 'Objet 3D',
        'Mounting without template': 'Montage sans modèle',
        'Mounting with template': 'Montage à partir d\'un modèle',
        'Mounting translated version': 'Montage traduction',
        questions: {
          trainingModality: {
            label: 'Quelle est la modalité pédagogique ?'
          },
          trainingDuration: {
            label: 'Quelle est la durée de la formation attendue ?'
          },
          trainingTarget: {
            label: 'Quel est le public cible de la formation ?'
          },
          trainingMechanism: {
            label: 'Quel est le type de dispositif ?'
          },
          certification: {
            label: 'Quelle est la certification ciblée ?'
          },
          trainingConception: {
            label: 'Faut-il prévoir de concevoir la formation ?'
          },
          sessionDuration: {
            label: 'Quelle est la durée de la session de formation ?'
          },
          sessionTarget: {
            label: 'Quel est le public cible de la session de formation ?'
          },
          sessionAttendees: {
            label: 'Combien d\'apprenants par session ?'
          },
          sessionQty: {
            label: 'Combien de session de formation ?'
          },
          characterQty: {
            label: 'Combien de personnages ?'
          },
          graphicalStyle: {
            label: 'Avez-vous une idée du style graphique ?'
          },
          characterToBeAnimated: {
            label: 'Faut-il prévoir un personnage animable ?'
          },
          environmentQty: {
            label: 'Combien de décors ?'
          },
          environmentToBeAnimated: {
            label: 'Faut-il prévoir un décor animable ?'
          },
          illustrationQty: {
            label: 'Combien d\'illustrations ?'
          },
          illustrationToBeAnimated: {
            label: 'Faut-il prévoir une illustration animable ?'
          },
          objectQty: {
            label: 'Combien d\'objets ?'
          },
          objectToBeAnimated: {
            label: 'Faut-il prévoir un objet animable ?'
          },
          hasVoices: {
            label: 'Y aura-t-il des voix ?'
          },
          hasAnimations: {
            label: 'Y aura-t-il des éléments animés ?'
          },
          interactionQty: {
            label: 'Combien y aura-t-il d\'interactions à créer ? (quiz, sondages, ...)'
          }
        }
      },
      freelance: 'Freelance',
      company: 'Entreprise',
      trainingOrganization: 'Organisme de formation',
      agency: 'Agence'
    },
    en: {
      deliverable: 'deliverable | deliverables',
      confirmPage: {
        iframeTitle: 'Your request has been registered !',
        iframeParagraph: 'We have registered your request. <br>Our team will get back to you very soon.',
        missionSaved: 'Registered mission offer !',
        toContinue: 'Your job offer is currently in "Draft" status.<br>You will be able to complete/modify the information relating to the expected services before validating its sending.'
      },
      formLabel: {
        employeesNumber: 'employees number',
        job: 'job',
        location: 'location',
        data: 'additional information',
        acceptedCgu: 'terms and conditions of the service'
      },
      step1: {
        title: 'Creation of a mission',
        description: 'Post a job offer and we will select the best talent to meet your needs.'
      },
      step2: {
        title: 'More information',
        description: 'Tell us a little more about your needs. The more precise your offer, the better our selection of profiles will be.'
      },
      step3: {
        title: 'About the job offer',
        description: 'A briefing, a budget and a general context... Your offer is almost complete.'
      },
      step4: {
        title: 'Almost done!',
        description: 'Some information about you and we can take care of your request.'
      },
      step5: {
        title: 'That\'s it!'
      },
      errors: {
        maxSize: 'The file size should be below 20 MB.',
        invalidType: 'Invalid file type'
      },
      size: {
        one: 'Only me',
        noData: 'I don\'t know'
      },
      seeMission: 'View the mission',
      close: 'Fermer',
      search: 'Search',
      importDoc: 'Upload documents.',
      dropOrBrowse: 'Drop here or',
      browse: 'browse',
      files: 'your files.',
      dropHere: 'Drop Here',
      jobOptions: {
        'Project Management': { title: 'Project manager', description: 'You are looking for support in managing your training project.' },
        Trainer: { title: 'Trainer', description: 'Webinar trainer, face-to-face trainer or designer trainer, you are looking for an expert who can meet your training needs.' },
        'Graphic design': { title: 'Graphic designer', description: 'Characters, sets, illustrations, 2D, 3D,... You wish to produce the graphic elements of your training.' },
        'Instructional designer': { title: 'Educational engineer', description: 'You want to design a training, make your synopsis or your storyboard.' },
        'Creation using authoring tool': { title: 'Editor on authoring tool', description: 'You want to edit your training on an authoring tool or on your LMS.' },
        Other: { title: 'Other', description: 'Author, Actor, Voice-over, Videographer, LMS Administrator...' }
      },
      missionTypes: {
        DELIVERABLE: 'A finished deliverable',
        TIME: 'A skill'
      },
      jobQuestion: 'What job are you looking for?',
      missionTypeQuestion: ' What are you looking for?',
      deliverableTypeQuestion: 'What kind of deliverable are you looking for ?',
      additionalQuestions: 'Some additional information...',
      numberOfRevisionsByDeliverable: 'Number of iterations per deliverable',
      addNumberOfRevisionsByDeliverable: 'Add number of iterations',
      missionTitle: 'Mission title',
      missionTitlePlaceholder: 'Add mission title',
      briefingTitle: 'Mission briefing',
      briefingPlaceholder: 'Briefing: Context of the mission, ...',
      budget: {
        title: 'Do you know the budget amount for this assignment ?',
        titleDailyRate: 'Do you know the budget amount for this assignment (daily rate) ?',
        sentence: 'my budget will be:',
        askProposals: 'I don\'t know, give me some suggestions'
      },
      addAnswer: 'Add answer',
      addDuration: 'Add duration',
      addLocation: 'Add location',
      addLanguage: 'Add language',
      addLCMS: 'Add LCMS',
      addAuthoringTool: 'Add authoring tool',
      addDocuments: 'You want to add documents...',
      name: 'Name',
      addName: 'Add name',
      addCompanyName: 'Add company name',
      companyType: 'Company type',
      addCompanyType: 'Add company type',
      employeesNumber: 'Employees number',
      addEmployeesNumber: 'Add employees number',
      firstname: 'Firstname',
      addFirstname: 'Add firstname',
      job: 'Job',
      addJob: 'Add job',
      email: 'Email',
      addEmail: 'Add email',
      phoneNumber: 'Phone number',
      addPhoneNumber: 'Add phone number',
      aboutJob: 'About the job...',
      aboutCompany: 'About your company...',
      aboutUser: 'About you...',
      generalTerms: {
        start: 'I have read and accept the ',
        link: 'terms and conditions of the service'
      },
      primarySkillAndLevel: 'What type of skills and level of experience are you looking for ?',
      skillLevel: 'What level of experience do you want for this job ?',
      secondarySkills: 'Do you need secondary skills?',
      deliveryDate: 'Delivery date',
      startDate: 'Start date',
      endDate: 'End date',
      duration: 'Duration in days',
      location: 'Location',
      pickFromCurrentProject: 'What deliverables from the current project do you want to subcontract ?',
      noDeliverableOption: 'No deliverables for this job',
      otherDeliverableNoData: 'No deliverables from the current project can be added',
      deliverables: {
        Synopsis: 'Synopsis',
        'Storyboard/Script': 'Storyboard/Script',
        'Game design': 'Game design',
        'Training design': 'Training design',
        'Psychometric Analysis': 'Psychometric Analysis',
        'Certification Management': 'Certification Management',
        'Webinar training': 'Webinar training',
        'Face-to-face training': 'Formation en présentiel',
        'Training video record': 'Record training',
        '2D character design': '2D character design',
        '2D environment design': '2D environment design',
        Illustration: 'Illustration',
        '3D character design': '3D character design',
        '3D environment design': '3D environment design',
        '3D object design': '3D object design',
        'Mounting without template': 'Mounting without template',
        'Mounting with template': 'Mounting with template',
        'Mounting translated version': 'Mounting translated version',
        questions: {
          trainingModality: {
            label: 'What is the training modality?'
          },
          trainingDuration: {
            label: 'What is the expected duration of the training?'
          },
          trainingTarget: {
            label: 'Who is the target audience for the training?'
          },
          trainingMechanism: {
            label: 'What is the type of mechanism? (face-to-face, blended, hybrid)'
          },
          certification: {
            label: 'What certification is being targeted?'
          },
          trainingConception: {
            label: 'Should we plan to design the training?'
          },
          sessionDuration: {
            label: 'How long is the training session?'
          },
          sessionTarget: {
            label: 'Who is the target audience for the training session?'
          },
          sessionAttendees: {
            label: 'How many learners per session?'
          },
          sessionQty: {
            label: 'How many training sessions?'
          },
          characterQty: {
            label: 'How many characters?'
          },
          graphicalStyle: {
            label: 'Do you have any idea of the graphic style?'
          },
          characterToBeAnimated: {
            label: 'Is it necessary to have an animated character?'
          },
          environmentQty: {
            label: 'How many environments?'
          },
          environmentToBeAnimated: {
            label: 'Is it necessary to have an animated environment?'
          },
          illustrationQty: {
            label: 'How many illustrations ?'
          },
          illustrationToBeAnimated: {
            label: 'Is it necessary to have an animated illustration?'
          },
          objectQty: {
            label: 'How many objects ?'
          },
          objectToBeAnimated: {
            label: 'Is it necessary to have an animated object?'
          },
          hasVoices: {
            label: 'Will there be voices?'
          },
          hasAnimations: {
            label: 'Will there be animated elements?'
          },
          interactionQty: {
            label: 'How many interactions will there be to create? (quizzes, surveys, etc.)'
          }
        }
      },
      freelance: 'Freelance',
      company: 'Company',
      trainingOrganization: 'Training organisation',
      agency: 'Agency'
    }
  }
}
module.exports = i18n
