import countWordHelper from './count-word'

const sceneDuration = (script, wordsPerMinute, averageTimePerActivity) => {
  const questions = script.filter((line) => {
    return line.type === 'question' && line.actionData.options.length
  })
  const numberOfWords = countWordHelper.numberOfWords(script, false)
  const additionalTimeForQuestions = questions.length * averageTimePerActivity
  const wordsPerSecond = wordsPerMinute / 60
  const duration = numberOfWords / wordsPerSecond
  return duration + additionalTimeForQuestions
}

const storyboardDuration = (scenes, averageTimeBetweenScenes) => {
  const duration = scenes.reduce((acc, curr, index) => {
    let durationToAdd = curr.properties.duration
    if (index !== scenes.length - 1) { durationToAdd += averageTimeBetweenScenes }
    return acc + durationToAdd
  }, 0)
  return duration
}

export default {
  sceneDuration,
  storyboardDuration
}
