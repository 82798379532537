const i18n = {
  messages: {
    fr: {
      title: 'Je souhaite...',
      mechanism: 'dispositif',
      course: 'parcours',
      module: 'module',
      pickResource: 'Sélectionner la ressource concernée :',
      projectPurpose: 'Ce projet a pour but de concevoir/produire les ressources du {type}',
      // cf. CreationBox/index.vue > computed > title: scope to lower case to generate dialog title
      learningobject: {
        new: 'Concevoir une ressource d\'apprentissage',
        description: 'Du cahier des charges à la distribution de la ressource, en passant par le cadrage et la conception pédagogique; concevez vos Parcours, Modules, Séquences de formation.',
        formTitle: "Nouvelle ressource d'apprentissage"
      },
      project: {
        new: 'Démarrer un nouveau projet',
        description: 'Production d\'une ressource d\'apprentissage, mise à jour de contenu ou organisation de sessions de formation; gérez et pilotez vos projets efficacement.',
        formTitle: 'Nouveau projet'
      },
      mission: {
        new: 'Poster une offre de mission',
        description: "Un edtaker s'occupe de la réalisation d'un livrable ou de tâches. Vous êtes libre de définir un budget fixe pour la réalisation de la mission ou gérer un budget par jour travaillé.",
        formTitle: 'Nouvelle mission'
      },
      sub: {
        create: {
          title: 'Création d\'une nouvelle ressource d\'apprentissage',
          description: 'Projet de conception, production et distribution.'
        },
        update: {
          title: 'Mise à jour  d\'une ressource d\'apprentissage',
          description: 'Mise à jour du contenu, traduction, ...'
        },
        other: {
          title: 'Autre type de projet',
          description: 'Projet marketing ou commercial, nouvelle session de formation, ...'
        }
      }
    },
    en: {
      title: 'I want to...',
      mechanism: 'mechanism',
      course: 'course',
      module: 'module',
      pickResource: 'Select the resource concerned:',
      projectPurpose: 'This project aims to design/produce the resources of the {type}',
      // cf. CreationBox/index.vue > computed > title: scope to lower case to generate dialog title
      learningobject: {
        new: 'Design a learning object',
        description: 'From the specifications to the distribution of the learning object, through the framing and the pedagogical design; design your Courses, Modules, Training Sequences.',
        formTitle: 'New learning object'
      },
      project: {
        new: 'Start a new project',
        description: 'Produce a learning object, update content or organize training sessions; manage and pilot your projects efficiently.',
        formTitle: 'New project'
      },
      mission: {
        new: 'Post a mission offer',
        description: 'An edtaker takes care of the realization of a deliverable or tasks. You are free to define a fixed budget for the realization of the mission or to manage a budget per day worked.',
        formTitle: 'New Mission'
      },
      sub: {
        create: {
          title: 'Learning object creation',
          description: 'Design, production and distribution project.'
        },
        update: {
          title: 'Update a learning object',
          description: 'Update content, translation, ...'
        },
        other: {
          title: 'Other project',
          description: 'Marketing or sales project, new training session, ...'
        }
      }
    }
  }
}
module.exports = i18n
