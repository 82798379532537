const i18n = {
  messages: {
    fr: {
      required: 'Cocher est requis'

    },
    en: {
      required: 'Require tcheck'

    }
  }
}
module.exports = i18n
