<template>
  <v-popover
    :disabled="!editRight"
    trigger="manual"
    :open="isOpen"
    :placement="position"
    :container="containerData"
    @hide="close"
  >
    <section
      v-if="editRight"
      class="pointer hover-highlight"
      :data-test="dataTest"
      @click="isOpen =! isOpen"
    >
      <slot v-if="!isOpen" name="default" :component-data="mutableModel">
        <div v-if="placeholderIcon">
          <v-icon color="primary">
            mdi-calendar
          </v-icon>
        </div>
        <span v-else-if="mutableModel">{{ dateDisplay }} </span>
        <span v-else>
          {{ $t('unscheduled') }}
        </span>
      </slot>
      <div v-else>
        <div v-if="placeholderIcon">
          <v-icon color="primary">
            mdi-calendar
          </v-icon>
        </div>
        <span v-else-if="mutableModel">{{ dateDisplay }}</span>
        <span v-else>
          {{ $t('unscheduled') }}
        </span>
      </div>
    </section>

    <span v-else>
      <slot name="default" :component-data="mutableModel">
        <div v-if="placeholderIcon">
          <v-icon color="primary">mdi-calendar</v-icon>
        </div>
        <span v-else-if="mutableModel">
          {{ dateDisplay }}
        </span>
        <span v-else>
          {{ $t('unscheduled') }}
        </span>
      </slot>
    </span>

    <template slot="popover">
      <section v-if="isOpen">
        <h5 v-if="title" class="blue--text text-center pt-2">
          {{ title }}
        </h5>
        <v-date-picker
          class="ma-0"
          :picker-date.sync="pickerDateFocus"
          :locale="$i18n.locale"
          :value="$dayjs(mutableModel).format('YYYY-MM-DD')"
          :min="minDate"
          :max="maxDate"
          no-title
          :type="display"
          @change="updateValue"
        >
        <edtkButton
          :disabled="todayNotAllowed"
          :label="$t('today')"
          type="filled"
          small
          class="mx-auto"
          @click="selectToday()"
        />
        </v-date-picker>
      </section>
    </template>
    <span
      v-if="$v.mutableModel.$error"
      class="form_error"
    >
      {{ $t('errors.input.INVALID') }} - {{ attrsInformation }}
    </span>
  </v-popover>
</template>

<script>
import { required } from 'vuelidate/lib/validators'
import i18n from './i18n.js'
export default {
  name: 'EditableDatepicker',
  props: {
    dataTest: {
      type: String
    },
    editRight: {
      type: Boolean,
      required: true,
      default: false
    },
    value: {
      type: [String, Date],
      default: () => undefined
    },
    containerRef: {
      type: HTMLElement
    },
    parentContainer: {
      type: Boolean,
      default: () => false
    },
    position: {
      type: [String, undefined],
      default: () => 'auto'
    },
    title: {
      type: String,
      default: () => ''
    },
    customValidation: {
      type: Object,
      default: () => {}
    },
    display: {
      type: String,
      default: undefined
    },
    dateDisplayFormat: {
      type: String,
      default: undefined
    },
    dateFocus: {
      type: [String, Date],
      default: () => undefined
    },
    placeholderIcon: {
      type: Boolean,
      default: () => false
    }
  },
  data () {
    return {
      isOpen: false,
      containerData: undefined,
      mutableModel: this.value,
      pickerDateFocus: this.value ? this.$dayjs(this.value).format('YYYY-MM-DD') : this.$dayjs(this.dateFocus).format('YYYY-MM-DD')
    }
  },
  computed: {
    dateDisplay () {
      return this.$d(this.$dayjs(this.mutableModel).toDate(), this.dateDisplayFormat)
    },
    attrsInformation () {
      const errorMessage = []
      if ((this.$attrs.required || this.$attrs.required === '') && !this.$v.mutableModel.required) {
        errorMessage.push(`${this.$t('required')}`)
      }
      Object.keys(this.customValidation ? this.customValidation.controls : {}).forEach((key) => {
        if (!this.$v.mutableModel[key]) {
          errorMessage.push(this.customValidation.errors[key])
        }
      })
      return errorMessage.join(' - ')
    },
    minDate () {
      return this.$attrs.min ? this.toISOFormat(this.$attrs.min) : undefined
    },
    maxDate () {
      return this.$attrs.max ? this.toISOFormat(this.$attrs.max) : undefined
    },
    todayNotAllowed () {
      const todayDate = this.$dayjs().format('YYYYMMDD')
      if (this.minDate && todayDate < this.$dayjs(this.$attrs.min).format('YYYYMMDD')) {
        return true
      } else if (this.maxDate && todayDate > this.$dayjs(this.$attrs.max).format('YYYYMMDD')) {
        return true
      }
      return false
    }
  },
  watch: {
    value (n) {
      this.mutableModel = n
    },
    containerRef (n) {
      this.containerData = n
    },
    dateFocus (n) {
      this.pickerDateFocus = this.mutableModel ? this.$dayjs(this.mutableModel).format('YYYY-MM-DD') : this.$dayjs(n).format('YYYY-MM-DD')
    }
  },
  mounted () {
    if (this.parentContainer) {
      this.containerData = this.$parent.$el
    }
  },
  validations () {
    return {
      mutableModel: {
        ...this.customValidation ? Object.keys(this.customValidation.controls).map((key) => { return this.customValidation.controls[key] }) : {},
        required: this.$attrs.required || this.$attrs.required === '' ? required : {}
      }
    }
  },
  i18n,
  methods: {
    updateValue (e) {
      this.mutableModel = e
      this.$v.mutableModel.$touch()
      if (!this.$v.mutableModel.$error) { this.$emit('input', this.$dayjs(e).format('YYYY-MM-DDT00:00:00.000') + 'Z') }
      this.close()
    },
    async selectToday () {
      await this.updateValue(this.$dayjs().format('YYYY-MM-DD'))
    },
    toISOFormat (date) {
      return this.$dayjs(date).format('YYYY-MM-DDT00:00:00.000') + 'Z'
    },
    close () {
      this.$v.mutableModel.$touch()
      this.isOpen = false
    }
  }
}
</script>
<style scoped>
.hover-highlight:hover {
  font-weight: 600;
}
</style>
