<template>
  <main class="result-container">
    <div v-if="Object.keys(tabsSubFilters).includes(tab)" class="result-item-subfilter-container">
      <div class="list-item-container">
        <div v-if="loading" class="d-flex align-center justify-center h-100">
          <Loading />
        </div>
        <MagicBoxAlgoliaNoResult v-else-if="items.length === 0" />
        <main v-else>
          <div v-for="subFilter in (activeFilter ? [activeFilter] : tabsSubFilters[tab])" :key="subFilter">
            <div v-if="items.some(i => i.__t === subFilter)" class="group-separator text-bold px-6 py-3">
              {{ $t(`objectType.${subFilter}`) }}
            </div>
            <div v-for="item in items.filter(i => i.__t === subFilter)" :key="item.objectID">
              <MagicBoxAlgoliaListItem :item="item" :filter="subFilter" />
            </div>
          </div>
        </main>
      </div>
      <aside class="pa-4">
        <p class="text-bold brownish-grey--text">
          {{ $t('filterByType') }}
        </p>
        <v-list-item-group
          v-model="activeFilter"
          color="primary"
          active-class="font-weight-bold"
        >
          <v-list-item
            v-for="subFilter in tabsSubFilters[tab]"
            :key="subFilter"
            :value="subFilter"
            class="filter-item my-2"
          >
            {{ $t(`objectType.${subFilter}`) }}
          </v-list-item>
        </v-list-item-group>
      </aside>
    </div>
    <div v-else class="list-item-container">
      <div v-if="loading" class="d-flex align-center justify-center h-100">
        <Loading />
      </div>
      <MagicBoxAlgoliaNoResult v-else-if="items.length === 0" />
      <div v-for="item in items" v-else :key="item.objectID">
        <MagicBoxAlgoliaListItem :item="item" />
      </div>
    </div>
  </main>
</template>
<script>

import MagicBoxAlgoliaListItem from '../ListItem'
import MagicBoxAlgoliaNoResult from '../NoResult'
import i18n from './i18n.js'
import Loading from '~/components/common/loading'

export default {
  name: 'MagicBoxAlgoliaList',
  components: {
    MagicBoxAlgoliaListItem,
    MagicBoxAlgoliaNoResult,
    Loading
  },
  i18n,
  props: {
    items: {
      type: Array,
      required: true,
      default: () => []
    },
    tab: {
      type: String,
      required: true,
      default: () => undefined
    },
    loading: {
      type: Boolean,
      required: false,
      default: () => false
    }
  },
  data () {
    return {
      tabsSubFilters: {
        projectItem: ['project', 'deliverable'/* , 'task' */],
        learningObject: ['course', 'module', 'sequence']
      },
      activeFilter: undefined
    }
  },
  watch: {
    activeFilter (n, o) {
      if (n !== o) {
        this.$emit('filterUpdated', n)
      }
    }
  },
  methods: {}
}
</script>
<style lang="scss" scoped>
$dialog-width: 720px;
$aside-width: 190px;
.list-item-container {
  height: 600px;
  overflow-y: scroll;
  overflow-x: hidden;
}

.result-item-subfilter-container {
  height: 100%;
  display: grid;
  grid-template-columns: #{$dialog-width - $aside-width} $aside-width;

  .group-separator {
    background: #f8f8f8;
    color: $brownish-grey
  }
}

aside {
  border-left: solid $gray-light 2px;

  .filter-item {
    min-height: 28px;
    border-radius: 4px;
    &::before,
    &::after {
      border-radius: 4px;
    }
  }
}
</style>
