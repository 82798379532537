<template>
  <div class="object-container">
    <v-card
      v-for="(l) in templatesData"
      :key="l._id"
      class="d-flex flex-column justify-space-between mx-1 pb-4"
      :class="{
        selectedBackground: checkPicked && pickedItem && (pickedItem._id === l._id || pickedItem === l._id),
        'hoverable': !editMode
      }"
      :ripple="!editMode"
      @click="cardClick(l)"
    >
      <section>
        <div class="d-flex align-center justify-space-between">
          <div class="d-flex align-center">
            <WorkspaceAvatar v-if="publicTemplate" class="mr-2" size="24" :workspace="l.workspace" />
            <span class="text-rem-10 blue--text pa-2" style="word-break:normal">
              {{ l.name }}
            </span>
          </div>
          <v-icon
            v-if="checkPicked && pickedItem && (pickedItem._id === l._id || pickedItem === l._id) "
            small
            color="primary"
          >
            mdi-check
          </v-icon>
        </div>
        <v-tooltip
          :disabled="!l.description"
          bottom
          max-width="300px"
        >
          <template #activator="{ on, attrs }">
            <span
              v-bind="attrs"
              class="two-lines px-2 pt-2 brownish-grey--text"
              v-on="on"
              v-html="l.description"
            ></span>
          </template>
          <span v-html="l.description"></span>
        </v-tooltip>
      </section>
      <section class="d-flex align-center px-2">
        <div v-if="l.budget" class="brownish-grey--text mr-2">
          <v-icon small>
            mdi-cash-multiple
          </v-icon>
          <span>{{ $n(l.budget, 'currency') }}</span>
        </div>
        <div v-if="l.duration" class="brownish-grey--text ml-2">
          <v-icon small>
            mdi-timer-outline
          </v-icon>
          <span>{{ l.duration }} {{ $t('days') }} </span>
        </div>
      </section>
      <v-card-actions v-if="editMode" class="justify-space-between align-center">
        <edtk-button
          type="outline"
          small
          @click="() => $emit('update', l)"
        >
          {{ $t('update') }}
        </edtk-button>
        <v-icon class="pointer" @click="() => $emit('delete', l._id)">
          mdi-trash-can-outline
        </v-icon>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import i18n from './i18n.js'
import WorkspaceAvatar from '~/components/common/workspaceAvatar'

export default {
  components: {
    WorkspaceAvatar
  },
  props: {
    templatesData: {
      type: Array,
      default: () => []
    },
    checkPicked: {
      type: Boolean,
      default: () => true
    },
    publicTemplate: {
      type: Boolean,
      default: () => false
    },
    pickedItem: {
      type: [Object, String]
    },
    editMode: {
      type: Boolean,
      default: () => false
    }
  },
  i18n,
  data () {
    return {}
  },
  methods: {
    cardClick (l) {
      if (!this.editMode) {
        this.$emit('pick', l)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  .object-container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 6px;
  }
  .selectedBackground {
    background-color: rgba(0, 85, 138, 0.1);
  }
  .v-card--link:before {
    background: transparent;
  }
  .two-lines {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    white-space: normal;
  }
  .two-lines :deep(p) {
    margin-bottom: 0 !important;
  }
  .sheetBorder {
  border: 1px solid rgb(225, 225, 228);
  border-radius: 5px;
  }
</style>
