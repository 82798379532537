/* eslint-disable prefer-promise-reject-errors */
/* eslint-disable no-console */
/* eslint-disable camelcase */

export const state = () => ({
  source: undefined,
  jwt: undefined,
  edtakeClientToken: undefined,
  algoliaSecureKey: undefined
})

export const getters = {
  getSource (state) {
    return state.source
  },
  getJWT (state) {
    return state.jwt
  },
  getAlgoliaSecureKey (state) {
    return state.algoliaSecureKey
  }
}

export const actions = {
  fetchAlgoliaSecureKey ({ commit }) {
    return new Promise((resolve, reject) => {
      this.$edtake.authorization.algoliaSecureKey()
        .then((d) => {
          commit('SET_ALGOLIA_SECURE_KEY', d)
          resolve(d)
        })
        .catch((err) => {
          console.log('ERROR', err)
          reject(err)
        })
    })
  },
  setJWT ({ commit }, { identityProvider, jwt }) {
    commit('SET_AUTH_SOURCE', identityProvider)
    commit('SET_AUTH_JWT', jwt)
    return true
  },
  async disconnect ({ dispatch }) {
    await dispatch('remoteConfig/init', {}, { root: true })
    await dispatch('course/clear', {}, { root: true })
    await dispatch('module/clear', {}, { root: true })
    await dispatch('mission/clear', {}, { root: true })
    await dispatch('membership/clear', {}, { root: true })
    await dispatch('workspace/clear', {}, { root: true })
    await dispatch('user/clear', {}, { root: true })

    await this.$auth.logout()
  },
  clear ({ commit }) {
    return commit('CLEAR')
  },
  validationEmbedSDK ({ commit, dispatch }, { body }) {
    return new Promise((resolve, reject) => {
      this.$edtake.authorization.validationEmbedSDK({ body })
        .then((d) => {
          resolve(d)
        })
        .catch((err) => {
          console.error('ERROR', err)
          reject('AN_ERROR_OCCURED')
        })
    })
  }
}

export const mutations = {
  SET_AUTH_SOURCE (state, value) {
    state.source = value
  },
  SET_AUTH_JWT (state, value) {
    state.jwt = value
  },
  SET_ALGOLIA_SECURE_KEY (state, value) {
    state.algoliaSecureKey = value
  },
  SET_EDTAKE_CLIENT_TOKEN (state, value) {
    state.edtakeClientToken = value
  },
  CLEAR (state, value) {
    state.source = undefined
    state.jwt = undefined
  }
}
