const i18n = {
  messages: {
    fr: {
      confirm: 'Enregistrer',
      required: 'Texte requis'

    },
    en: {
      confirm: 'Update',
      required: 'Require text'

    }
  }
}
module.exports = i18n
