<template>
</template>

<script>
import i18n from './i18n.js'
export default {
  name: 'ProposeChangePassword',
  i18n,
  data () {
    return {}
  },
  mounted () {
    if (this.$store.state.user?.user?.onboarding?.proposeChangePassword &&
    this.$store.state.user.user.onboarding.newUserOnboarding) {
      this.$swal({
        icon: 'info',
        title: this.$t('title'),
        text: this.$t('text'),
        showCancelButton: true,
        confirmButtonColor: '#4bca81',
        cancelButtonColor: '#c2c5cb',
        confirmButtonText: this.$t('confirmBtn'),
        cancelButtonText: this.$t('cancelBtn')
      }).then(async (result) => {
        await this.$store.dispatch('user/updateProfile', { 'onboarding.proposeChangePassword': false })
        if (result.value) {
          const aadb2cChangePasswordEndpoint = await this.$store.getters['remoteConfig/getAadb2cChangePasswordEndpoint']
          return window.location.replace(aadb2cChangePasswordEndpoint)
        }
      })
    }
  }
}
</script>
