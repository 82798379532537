<template>
  <section>
    <slot v-if="!editRight" name="default" :component-data="value">
      {{ currency && value ? $n(value, 'currency') : value }}
    </slot>
    <template v-else>
      <span v-if="!editMode" :class="noHover ? '' : 'hover-highlight'" @click="editMode = true">
        <slot name="default" :component-data="value">
          {{ mutableModel || mutableModel === 0 ? currency ? $n(parseFloat(mutableModel), 'currency') : mutableModel : placeholder }}
        </slot>
      </span>
      <input
        v-else
        ref="inputNumber"
        class="number-input"
        :value="mutableModel"
        type="number"
        v-bind="$attrs"
        @blur="updateValue"
        @keyup="inputKeyup"
      />
    </template>
    <span
      v-if="$v.mutableModel.$error"
      class="form_error"
    >
      {{ $t('errors.input.INVALID') }} - {{ attrsInformation }}
    </span>
  </section>
</template>

<script>
import {
  required,
  minValue,
  maxValue
} from 'vuelidate/lib/validators'
import i18n from './i18n.js'
export default {
  name: 'EditableNumber',
  props: {
    noHover: {
      type: Boolean,
      default: false
    },
    dataTest: {
      type: String
    },
    editRight: {
      type: Boolean,
      required: true,
      default: false
    },
    value: {
      type: Number,
      default: () => undefined
    },
    position: {
      type: String,
      default: () => 'auto'
    },
    placeholder: {
      type: String,
      default: () => ''
    },
    customValidation: {
      type: Object,
      default: () => {}
    },
    currency: {
      type: Boolean,
      default: false
    },
    autoChange: {
      type: Boolean,
      default: true
    }
  },
  validations () {
    const stepValue = (value) => {
      const y = value - (this.$attrs.min || 0)
      const x = (Math.round(y * 100) % Math.round(this.$attrs.step * 100)) / 100
      return x === 0
    }
    return {
      mutableModel: {
        // Adding all custom validations to the mutableModel
        ...this.customValidation ? Object.keys(this.customValidation.controls).map((key) => { return this.customValidation.controls[key] }) : {},
        required: this.$attrs.required || this.$attrs.required === '' ? required : {},
        minValue: this.$attrs.min !== undefined ? minValue(this.$attrs.min) : {},
        maxValue: this.$attrs.max !== undefined ? maxValue(this.$attrs.max) : {},
        stepValue: this.$attrs.step && this.mutableModel ? stepValue : {}
      }
    }
  },
  data () {
    return {
      editMode: false,
      mutableModel: this.value
    }
  },
  computed: {
    attrsInformation () {
      const errorMessage = []
      if ((this.$attrs.required || this.$attrs.required === '') && !this.$v.mutableModel.required) {
        errorMessage.push(`${this.$t('required')}`)
        return errorMessage.join(' - ')
      }
      if (this.$attrs.min !== undefined && !this.$v.mutableModel.minValue) {
        errorMessage.push(`${this.$t('minValue')} ${this.$attrs.min}`)
      }
      if (this.$attrs.max !== undefined && !this.$v.mutableModel.maxValue) {
        errorMessage.push(`${this.$t('maxValue')} ${this.$attrs.max}`)
      }
      if (this.$attrs.step && !this.$v.mutableModel.stepValue) {
        errorMessage.push(`${this.$t('step')} : ${this.$attrs.step}`)
      }
      Object.keys(this.customValidation ? this.customValidation.controls : {}).forEach((key) => {
        if (!this.$v.mutableModel[key]) {
          errorMessage.push(this.customValidation.errors[key])
        }
      })
      return errorMessage.join(' - ')
    }
  },
  watch: {
    editMode (n) {
      if (n) {
        this.$nextTick(() => this.$refs.inputNumber.focus())
      }
    },
    value (n, o) {
      this.mutableModel = this.value
    }
  },
  i18n,
  methods: {
    focus () {
      this.editMode = true
    },
    updateValue (e) {
      let newValue
      this.editMode = false
      if (this.mutableModel !== Number(e.target.value)) {
        if (e.target.value) {
          newValue = Number(e.target.value)
        } else {
          newValue = undefined
        }
        this.$v.mutableModel.$touch()
        this.$nextTick(() => {
          if (!this.$v.mutableModel.$error) {
            this.$emit('input', newValue)
          }
        })
        if (this.autoChange) { this.mutableModel = newValue }
      }
    },
    inputKeyup (e) {
      if (e.key === 'Enter' || e.keyCode === 13) {
        this.updateValue(e)
      }
    }
  }
}
</script>
<style lang="scss" scoped>
/* Redefine size for input */
.inline {
  .number-input {
    width: max-content;
    max-width: 100%;
  }
  &::after {
    content: "\a0";
  }
}
.small {
  .number-input {
    min-width: 50px;
    width: max-content;
    max-width: 50px;
  }
}
.medium {
  .number-input {
    min-width: 100px;
    width: max-content;
    max-width: 100px;
  }
}
.large {
  .number-input {
    min-width: 100px;
    width: 100%;
    max-width: 100px;
  }
}

.number-input {
  width: inherit;
  padding-bottom: 5px;
  border-bottom: dashed transparent 2px;
  height: inherit;
  font-weight: inherit;
  font-size: inherit;
  line-height: inherit;
}
.hover-highlight {
  display: block;
  height: 28px;
}
.hover-highlight:hover {
  cursor: pointer;
  border-bottom: dashed $blue 2px;
}
</style>
