<template>
  <div>
    <v-dialog
      v-model="opened"
      :width="dialogWidth"
      height="80vh"
      transition="scale-transition"
      overlay-opacity="0.7"
      light
    >
      <Mission
        v-if="scope === 'Mission'"
        :project="project"
        :lms-options="lmsOptions"
        :authoring-tools-options="authoringToolsOptions"
        :current-workspace-id="currentWorkspaceId"
        :statuses="statuses"
        :languages="workspaceLanguages"
        @close="close"
      />
      <v-card v-else class="ma-0 pa-0">
        <!-- HEADER START  -->
        <v-card-title class="justify-space-between">
          <span :class="[scope === undefined ? 'pb-0' : 'py-0', 'page-title font-weight-semibold ml-2 my-1']">
            {{ title }}
          </span>
          <edtk-button-icon
            data-test="button-magic-close"
            icon="close"
            icon-size="4xl"
            color="secondary"
            @click="close"
          ></edtk-button-icon>
        </v-card-title>
        <!-- HEADER END  -->
        <v-divider></v-divider>
        <v-card-text class="pa-0">
          <!-- LINK SUB PROJECT WITH LEARNING OBJECT START -->
          <div v-if="scope === undefined && parentProject && LoIsNotASequence && LOcomposition.length" class="px-6 py-2 my-4">
            <section v-if="!displayComposition">
              <h4>
                {{ $t('projectPurpose', {type: $t(`${parentProject.learningObject.__t}`)}) }} : <span class="blue--text font-weight-bold">{{ parentProject.learningObject.name }}</span> ?
              </h4>
              <div class="d-flex justify-center align-center mt-8">
                <edtk-button class="mx-2" @click="displayComposition = true">
                  {{ $t('buttons.YES') }}
                </edtk-button>
                <edtk-button class="mx-2" @click="scope = 'Project'">
                  {{ $t('buttons.NO') }}
                </edtk-button>
              </div>
            </section>
            <section v-else>
              <h4 class="blue--text font-weight-bold mb-2">
                {{ $t('pickResource') }}
              </h4>
              <Picker v-model="pickedResource" :type="parentProject.learningObject.__t" :options="LOcomposition" multiple />
              <div class="d-flex justify-center align-center mt-2">
                <edtk-button :disabled="!pickedResource.length" @click="createSubProjectWithLO">
                  {{ $t('buttons.NEXT') }}
                </edtk-button>
              </div>
            </section>
          </div>
          <!-- LINK SUB PROJECT WITH LEARNING OBJECT END -->
          <div v-else-if="scope === undefined" justify="center" class="pa-4">
            <!-- Project -->
            <v-card
              v-if="$userRightsHelper.hasFeatureAccess('project')"
              v-show="$userRightsHelper.hasFeatureAccess('project')"
              class="fill-height hoverable"
              @click="scope = 'Project'"
            >
              <v-row class="fill-height">
                <v-col cols="4" class="text-center" align-self="center">
                  <img
                    src="~/assets/img/newProject.png"
                    alt="edtake"
                    width="100%"
                  />
                </v-col>
                <v-col cols="8">
                  <h4 class="blue--text mt-2">
                    {{ $t('project.new') }}
                  </h4>
                  <p class="mt-1">
                    {{ $t('project.description') }}
                  </p>
                </v-col>
              </v-row>
            </v-card>
            <!-- LearningObject -->
            <v-card
              class="fill-height hoverable"
              @click="openLearningObjectCreationDialog"
            >
              <v-row class="fill-height">
                <v-col cols="4" class="text-center" align-self="center">
                  <img
                    src="~/assets/img/newLearningObject.png"
                    alt="edtake"
                    width="100%"
                  />
                </v-col>
                <v-col cols="8">
                  <h4 class="blue--text mt-2">
                    {{ $t('learningobject.new') }}
                  </h4>
                  <p class="mt-1">
                    {{ $t('learningobject.description') }}
                  </p>
                </v-col>
              </v-row>
            </v-card>
            <!-- Mission -->
            <v-card
              v-if="$userRightsHelper.hasFeatureAccess('mission')"
              v-show="$userRightsHelper.hasFeatureAccess('mission')"
              data-test="button-create-mission"
              class="fill-height hoverable"
              @click="scope = 'Mission'"
            >
              <v-row class="fill-height">
                <v-col cols="4" class="text-center" align-self="center">
                  <img
                    src="~/assets/img/newMission.png"
                    alt="edtake"
                    width="100%"
                  />
                </v-col>
                <v-col cols="8">
                  <h4 class="blue--text mt-2">
                    {{ $t('mission.new') }}
                  </h4>
                  <p class="mt-1">
                    {{ $t('mission.description') }}
                  </p>
                </v-col>
              </v-row>
            </v-card>
          </div>

          <div v-else-if="scope === 'Project' && subScope === undefined" justify="center" class="pa-5">
            <!-- Create -->
            <v-card
              class="hoverable"
              data-tour-id="project-creation-type-create"
              @click="subScope = 'Create'"
            >
              <v-row>
                <v-col cols="4" class="text-center" align-self="center">
                  <img
                    src="~/assets/img/project-create.svg"
                    height="120px"
                  />
                </v-col>
                <v-col cols="8">
                  <h4 class="blue--text mt-2">
                    {{ $t('sub.create.title') }}
                  </h4>
                  <p class="mt-1">
                    {{ $t('sub.create.description') }}
                  </p>
                </v-col>
              </v-row>
            </v-card>
            <!-- Update -->
            <v-card
              v-if="countLO > 0"
              class="hoverable"
              data-tour-id="project-creation-type-update"
              @click="subScope = 'Update'"
            >
              <v-row>
                <v-col cols="4" class="text-center" align-self="center">
                  <img
                    src="~/assets/img/project-update.svg"
                    height="120px"
                  />
                </v-col>
                <v-col cols="8">
                  <h4 class="blue--text mt-2">
                    {{ $t('sub.update.title') }}
                  </h4>
                  <p class="mt-1">
                    {{ $t('sub.update.description') }}
                  </p>
                </v-col>
              </v-row>
            </v-card>
            <!-- Other -->
            <v-card
              class="hoverable"
              data-tour-id="project-creation-type-other"
              @click="subScope = 'Other'"
            >
              <v-row>
                <v-col cols="4" class="text-center" align-self="center">
                  <img
                    src="~/assets/img/project-other.svg"
                    height="120px"
                  />
                </v-col>
                <v-col cols="8">
                  <h4 class="blue--text mt-2">
                    {{ $t('sub.other.title') }}
                  </h4>
                  <p class="mt-1">
                    {{ $t('sub.other.description') }}
                  </p>
                </v-col>
              </v-row>
            </v-card>
          </div>
          <component :is="scope" v-else ref="scopeComponent" v-bind="currentProperties" @close="close" />
        </v-card-text>
      </v-card>
    </v-dialog>
    <LearningObjectCreationDialog v-model="learningObjectCreationOpened" @close="learningObjectCreationOpened = false" />
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import i18n from './i18n.js'
import Mission from '~/components/mission/creation'
import Project from '~/components/projectItem/creation'
import LearningObjectCreationDialog from '~/components/learningObject/creation'
import Picker from '~/components/learningObject/components/Picker'

export default {
  name: 'CreationBox',
  components: {
    Mission,
    Project,
    LearningObjectCreationDialog,
    Picker
  },
  data () {
    return {
      countLO: undefined,
      opened: false,
      subScope: undefined,
      scope: undefined,
      learningObject: undefined,
      parentProject: undefined,
      displayComposition: false,
      pickedResource: [],
      creationFromLOPage: false,
      learningObjectCreationOpened: false,
      project: undefined
    }
  },
  computed: {
    ...mapGetters({
      lmsOptions: 'settings/getSettingsLms',
      authoringToolsOptions: 'settings/getSettingsAuthoringTools',
      currentWorkspaceId: 'workspace/getCurrentId',
      statuses: 'settings/getCurrentStatusBase',
      workspaceLanguages: 'workspace/getSettingsLanguages',
      courses: 'course/get',
      modules: 'module/get',
      sequences: 'sequence/get',
      mechanismComposition: 'mechanism/getChildren',
      courseComposition: 'course/getComposition',
      moduleComposition: 'module/getComposition'
    }),
    dialogWidth () {
      if (this.scope) {
        switch (this.scope) {
          case 'Mission':
            return '80vw'
          case 'LearningObject':
            return this.$mq === 'xl' ? '40vw' : '60vw'
          case 'Project':
            return this.subScope ? this.$mq === 'xl' ? '70vw' : '90vw' : '600px'
          default:
            return '700px'
        }
      } else if (this.parentProject && this.LoIsNotASequence) {
        return this.$mq === 'xl' ? '40vw' : '60vw'
      } else {
        return '700px'
      }
    },
    title () {
      return this.scope === undefined ? this.parentProject ? this.$t('project.formTitle') : this.$t('title') : this.$t(`${this.scope.toLowerCase()}.formTitle`)
    },
    currentProperties () {
      const r = {}
      if (this.scope === 'Project') {
        r.learningObjectList = this.learningObject
        r.parentProject = this.parentProject ? this.parentProject.project : undefined
        r.creationFromLOPage = this.creationFromLOPage
      }
      if (this.subScope) {
        r.subScope = this.subScope
      }
      return r
    },
    LOcomposition () {
      const LOchildren = this[`${this.parentProject.learningObject.__t}Composition`](this.parentProject.learningObject._id)
      return LOchildren.map(child => child.child)
    },
    LoIsNotASequence () {
      if (this.parentProject && this.parentProject.learningObject) {
        return this.parentProject.learningObject.__t !== 'sequence'
      } else {
        return false
      }
    }
  },
  watch: {
    opened (n) {
      if (n === false) {
        this.scope = 'span'
      }
    },
    $route () {
      this.opened = false
    },
    scope () {
      this.subScope = undefined
    }
  },
  created () {
    this.$nuxt.$on('open-creationBox', e => this.open(e))
  },
  beforeDestroy () {
    // this.$hotkeys.deleteScope('MagicBox');
  },
  methods: {
    async open (e) {
      this.countLO = await this.$edtake.stats.count({ objectType: 'learningObject' })
      this.creationFromLOPage = e?.creationFromLOPage
      this.opened = true
      this.scope = e?.scope || undefined
      if (this.scope === 'Mission') {
        this.project = e?.project || undefined
      }
      if (e && e.learningObject) {
        this.learningObject = e.learningObject
      } else {
        this.learningObject = undefined
      }

      if (e && e.parentProject) {
        this.parentProject = e.parentProject
        if (!this.LoIsNotASequence || !this.LOcomposition.length) {
          this.scope = 'Project'
        } else {
          await this.$store.dispatch(`${e.parentProject.learningObject.__t}/fetchChildren`, { id: e.parentProject.learningObject._id })
        }
      } else {
        this.parentProject = undefined
      }
    },
    createSubProjectWithLO () {
      const learningObjectDetails = []
      this.pickedResource.forEach((resource) => {
        const LO = this.$_.find(this.LOcomposition, l => l._id === resource)
        learningObjectDetails.push(LO)
      })
      this.learningObject = learningObjectDetails
      this.scope = 'Project'
      this.displayComposition = false
      this.pickedResource = []
    },
    close () {
      this.opened = false
      this.displayComposition = false
      this.pickedResource = []
    },
    openLearningObjectCreationDialog () {
      this.learningObjectCreationOpened = true
      this.close()
    }
  },
  i18n
}
</script>
<style css scoped>
.magic {
  border-radius: 0.8rem;
}
</style>
