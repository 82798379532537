<template>
  <div class="px-10">
    <v-row class="pa-2 mt-4">
      <div
        class="overviewGrid"
      >
        <v-card v-if="displayDuration" max-width="250px">
          <v-list-item>
            <v-list-item-avatar size="60">
              <v-icon
                color="primary"
                x-large
              >
                mdi-clock-outline
              </v-icon>
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title>{{ $t('duration') }}</v-list-item-title>
              <v-list-item-subtitle>{{ storyboardDuration }}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-card>
        <v-card v-if="displayBudget" max-width="250px">
          <v-list-item>
            <v-list-item-avatar size="60">
              <v-icon
                color="primary"
                x-large
              >
                mdi-microphone
              </v-icon>
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title>{{ $t('budget') }}</v-list-item-title>
              <v-list-item-subtitle>{{ storyboardBudget }}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-card>
      </div>
    </v-row>
    <v-row class="pa-2">
      <h2 class="page-subtitle">
        {{ $t('characters') }}
      </h2>
      <div
        class="overviewGrid"
      >
        <v-card
          v-for="character in characterList"
          :key="character.asset"
          max-width="250px"
        >
          <v-list-item>
            <v-list-item-avatar size="60">
              <v-icon
                v-if="!character.image.value"
                class="grey lighten-2"
                dark
                x-large
              >
                mdi-account-outline
              </v-icon>
              <v-img v-else :src="character.image.value"></v-img>
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title>{{ character.asset }}</v-list-item-title>
              <v-list-item-subtitle>
                <div>
                  {{ character.number }} {{ $tc('spokenWord', character.number) }}
                </div>
                <v-tooltip bottom>
                  <template #activator="{ on, attrs }">
                    <div
                      v-if="displayBudget"
                      class="mt-2"
                      v-bind="attrs"
                      v-on="on"
                    >
                      <v-icon small>
                        mdi-microphone
                      </v-icon>
                      {{ $n(parseFloat(character.budget), 'currency') }}
                    </div>
                  </template>
                  <span>{{ $t('voiceOverBudget') }}</span>
                </v-tooltip>
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-card>
      </div>
    </v-row>
  </div>
  <!-- DIALOG TABLE
        <v-data-table
          class="mb-5 mt-1 mx-1 elevation-1 white"
          hide-default-footer
          :headers="headersDialogue"
          :items="dialogueList"
          :items-per-page="-1"
        >
          <template #[`item.images`]="{ item }">
            <v-row class="pa-4">
              <v-col
                v-for="image in item.images"
                :key="image._id"
                cols="6"
                class="py-0 px-0"
              >
                <v-img
                  class="mr-1 my-1"
                  width="50"
                  :src="image.value"
                />
              </v-col>
              <span v-if="item.images.length === 0" class="caption font-weight-light font-italic">
                {{ $t('noImage') }}
              </span>
            </v-row>
          </template>
          <template #[`item.description`]="{ item }">
            <v-row>
              <v-col>
                {{ item.description }}
              </v-col>
            </v-row>
          </template>
        </v-data-table> -->
</template>

<script>
import _ from 'lodash'
import summary from '../_utilities/summary-helper'

export default {
  props: {
    storyboard: {
      type: Object,
      default: () => {}
    }
  },
  data () {
    return {
      // headersDialogue: [
      //   { text: this.$t('headers.sceneTitle'), value: 'sceneTitle', align: 'left', width: '25%' },
      //   { text: this.$t('headers.character'), value: 'asset', align: 'left', width: '15%' },
      //   { text: this.$t('headers.images'), value: 'images', align: 'left', width: '15%' },
      //   { text: this.$t('headers.line'), value: 'description', align: 'left', width: '45%' }
      // ]
      // headersInteraction: [
      //   { text: this.$t('headers.sceneTitle'), value: 'sceneTitle', align: 'left', width: '25%' },
      //   { text: this.$t('headers.description'), value: 'description', align: 'left', width: '75%' }
      // ]
    }
  },
  i18n: {
    messages: {
      fr: {
        spokenWord: 'mot prononcé | mots prononcés',
        noImage: 'Aucune image disponible',
        characters: 'Personnages',
        duration: 'Durée estimée',
        budget: 'Budget estimé',
        voiceOverBudget: 'Budget estimé voix-off'
        // headers: {
        //   sceneTitle: 'Scène',
        //   asset: 'Élément',
        //   character: 'Personnage',
        //   images: 'Image(s)',
        //   line: 'Réplique',
        //   description: 'Description'
        // }
      },
      en: {
        spokenWord: 'spoken word | spoken words',
        noImage: 'No image',
        characters: 'Characters',
        duration: 'Estimated duration',
        budget: 'Estimated budget',
        voiceOverBudget: 'Voice-over estimated budget'
        // headers: {
        //   sceneTitle: 'Scene',
        //   asset: 'Asset',
        //   character: 'Character',
        //   images: 'Images',
        //   line: 'Line',
        //   description: 'Description'
        // }
      }
    }
  },
  computed: {
    scenes () {
      return _.sortBy(this.storyboard.scenes, [o => o.order])
    },
    // dialogueList () {
    //   return summary.scriptSummary('dialogue', true, this.storyboard.assets, this.storyboard.catalog)
    // },
    // interactionList () {
    //   return summary.scriptSummary('interaction', false, this.storyboard.assets, this.storyboard.catalog)
    // },
    characterList () {
      const res = []
      const list = summary.assetSummary(this.storyboard.assets, this.storyboard.catalog, this.storyboard.scenes, true)
      Object.keys(list).forEach((character) => {
        if (character) {
          let budget
          if (this.displayBudget) {
            budget = (list[character].numberOfWords * this.storyboard.settings.voiceOver.costPerWord) + this.storyboard.settings.voiceOver.costPerCharacter
          }
          res.push({ asset: character, number: list[character].numberOfWords, image: list[character].image || { value: null }, budget })
        }
      })
      return res
    },
    displayDuration () {
      return this.storyboard?.settings?.duration?.displayDuration
    },
    storyboardDuration () {
      return this.$dayjs.duration(this.storyboard.duration, 'seconds').format('mm:ss')
    },
    displayBudget () {
      return this.storyboard?.settings?.voiceOver?.displayVoiceOverBudget
    },
    storyboardBudget () {
      return this.$n(parseFloat(this.storyboard.voiceOverBudget), 'currency')
    }
  },
  methods: {}
}
</script>

<style scoped>
@media screen and (min-width: 610px) {
  .overviewGrid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 4px;
    grid-auto-rows: minmax(100px, auto);
  }
}
@media screen and (min-width: 875px) {
  .overviewGrid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 4px;
    grid-auto-rows: minmax(100px, auto);
  }
}
@media screen and (min-width: 1105px) {
  .overviewGrid {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 4px;
    grid-auto-rows: minmax(100px, auto);
  }
}
@media screen and (min-width: 1375px) {
  .overviewGrid {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    grid-gap: 4px;
    grid-auto-rows: minmax(100px, auto);
  }
}
@media screen and (min-width: 1650px) {
  .overviewGrid {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    grid-gap: 4px;
    grid-auto-rows: minmax(100px, auto);
  }
}
@media screen and (min-width: 1920px) {
  .overviewGrid {
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    grid-gap: 10px;
    grid-auto-rows: minmax(100px, auto);
  }
}
</style>
