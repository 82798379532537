import { sha256 } from 'js-sha256'

export default ({ store }, inject) => {
  const chat = {
    initialized: false,
    userInitialized: false
  }

  chat.init = function () {
    try {
      if (!chat.initialized) {
        if (!store.state.remoteConfig.config) {
          return
          // throw new Error('Configuration not loaded')
        }
        if (!store.state.remoteConfig.config.CHAT_KEY) {
          return
          // throw new Error('No event log id found in configuration')
        }

        window.chatwootSettings = {
          hideMessageBubble: true,
          position: 'right', // This can be left or right
          useBrowserLanguage: true, // Set widget language from user's browser
          type: 'standard', // [standard, expanded_bubble]
          darkMode: 'auto' // [light, auto]
        }

        window.chatwootSDK.run({
          websiteToken: store.state.remoteConfig.config.CHAT_KEY,
          baseUrl: 'https://chat.edtake.cloud'
        })

        chat.initialized = true
      }
    } catch (error) {
      console.error('error', error)
    }
  }

  chat.initUser = function (email) {
    chat.init()
    if (!chat.initialized) { return }

    const storeUser = store.state.user.user
    const chatUser = { email }
    const customAttributes = {}

    if (storeUser && storeUser.email) {
      chatUser.email = storeUser.email
      chatUser.name = `${storeUser.firstname} ${storeUser.lastname}`
      chatUser.avatar_url = storeUser.avatar
      chatUser.email = storeUser.email
      chatUser.phone_number = storeUser.phone

      customAttributes.isEdtaker = storeUser.isEdtaker
    }

    if (!chatUser.email) { return }

    const chatwootUserHash = sha256.hmac(
      'mj6baemdo5SGboY4R3JK18kv',
      chatUser.email
    )
    chatUser.identifier_hash = chatwootUserHash

    window.$chatwoot.setUser(chatUser.email, chatUser)

    window.$chatwoot.setCustomAttributes(customAttributes)
  }

  chat.open = async function () {
    await chat.initUser()
    if (!chat.initialized) { return }
    window.$chatwoot.toggle('open')
    // window.productFruits.api.button.hideButton()
  }

  chat.close = function () {
    chat.init()
    if (!chat.initialized) { return }
    window.$chatwoot.toggle('close')
    window.productFruits.api.button.showButton()
  }

  window.addEventListener('productfruits_button_ext_widget_init', () => {
    chat.open()
    window.productFruits.api.button.showButton()
  })

  inject('chat', chat)
}
