import _ from 'lodash'
import countWordHelper from './count-word'

const scriptSummary = (actionType, displayImage, assets, catalog, scenes) => {
  const result = []

  scenes.forEach((scene) => {
    const sceneData = scene.script.filter(item => item.type === actionType)

    sceneData.forEach((action) => {
      const asset = assets.find(asset => asset._id === action.actionData.assetId)
      const assetImages = []
      if (displayImage && asset) {
        asset.images.forEach((image) => {
          const assetImage = catalog.find(item => item._id === image.value)
          assetImages.push({ _id: image._id, value: assetImage ? assetImage.value : null, default: image.defaultImage })
        })
      }
      result.push({
        sceneTitle: scene.properties.title,
        actionType: action.type,
        asset: asset ? asset.name : '',
        assetType: asset ? asset.type : undefined,
        images: assetImages,
        description: action.actionData.description,
        options: action.actionData.options
      })
    })
  })
  return result
}

const assetSummary = (assets, catalog, scenes, displayImage) => {
  const data = {}
  const dialogues = scriptSummary('dialogue', displayImage, assets, catalog, scenes)
  const questions = scriptSummary('question', displayImage, assets, catalog, scenes)
  const totalLines = dialogues.concat(questions)

  totalLines.forEach((line) => {
    let spokenWords = 0
    let totalWord = ''
    if (line.description) {
      const cleanLine = countWordHelper.removePunctuation(line.description)
      totalWord = totalWord.concat(' ', cleanLine)
    }
    if (line.options.length) {
      line.options.forEach((o) => {
        if (o.label) {
          const label = countWordHelper.removePunctuation(o.label)
          totalWord = totalWord.concat(' ', label)
        }
      })
    }
    const splitTotalWord = totalWord.split(' ').filter(word => word !== '')
    spokenWords = splitTotalWord.length

    if (!data[line.asset]) {
      if (displayImage) {
        const image = _.filter(line.images, image => image.default)
        data[line.asset] = { numberOfWords: 0, image: image[0] }
      } else {
        data[line.asset] = { numberOfWords: 0 }
      }
    }
    data[line.asset].numberOfWords += spokenWords
  })
  return data
}

export default {
  scriptSummary,
  assetSummary
}
