/* eslint-disable prefer-promise-reject-errors */
/* eslint-disable no-console */
/* eslint-disable camelcase */
import _ from 'lodash'

const userSchema = {
  edtaker: Object,
  _id: String,
  firstname: String,
  lastname: String,
  email: String,
  phone: String,
  mailNotification: Boolean,
  approval: String,
  acceptedCgu: Boolean,
  languages: Array,
  authoringTools: Array,
  lcms: Array,
  tags: Array,
  description: String,
  roles: String,
  skills: Array,
  integrations: Array,
  userNotificationSettings: Object,
  createdAt: Date,
  seenAt: Date,
  updatedAt: Date,
  deleted: Boolean,
  __v: Number,
  isEdtaker: Boolean,
  name: String,
  id: String
}
export const state = () => ({
  user: {}, // see user schema
  locales: ['en', 'fr'],
  locale: 'fr',
  integrationsUserList: [],
  temporaryAccess: false,
  session: undefined
})

export const getters = {
  get (state) {
    return state.user
  },
  getLocale (state) {
    return state.locale
  },
  getLang (state) {
    return state.lang
  },
  isEdtaker (state) {
    return state.user && state.user.isEdtaker
  },
  getIntegrationsUserList (state) {
    return state.integrationsUserList
  },
  getIntegrationsUserListByType (state) {
    return _.groupBy(state.integrationsUserList, 'integration.type')
  },
  isAdmin (state, getters, rootState, rootGetters) {
    const ws = rootGetters['workspace/getCurrent']
    if (!ws) { return false }

    const userAsMember = ws.members.find(member => member.user._id.toString() === state.user._id.toString() || member.user.toString() === state.user._id.toString())
    const { admin } = userAsMember || {}

    return admin
  },
  isGuest (state, getters, rootState, rootGetters) {
    const ws = rootGetters['workspace/getCurrent']
    if (!ws) { return false }

    const userAsMember = ws.members.find(member => member.user._id.toString() === state.user._id.toString() || member.user.toString() === state.user._id.toString())
    const { guest } = userAsMember || {}

    return guest
  },
  isFeatureAllowedForUser: (state, getters, rootState, rootGetters) => (feature) => {
    const ws = rootGetters['workspace/getCurrent']
    if (!ws) { return false }

    const userAsMember = ws.members.find(member => member.user._id.toString() === state.user._id.toString() || member.user.toString() === state.user._id.toString())
    const { guest, admin } = userAsMember || {}

    const hasLearningDesign = userAsMember?.license ? userAsMember.license.features.learningDesign : false
    const hasProjectManagement = userAsMember?.license ? userAsMember.license.features.projectManagement : false
    const hasMarketplace = userAsMember?.license ? userAsMember.license.features.marketplace : false

    switch (feature) {
      // Learning Objects access
      case 'learningDesign':
        return hasLearningDesign || guest
      case 'course':
        return hasLearningDesign
      case 'courseDuplication':
        return hasLearningDesign && !guest
      case 'module':
        return hasLearningDesign
      case 'modulesImport':
        return hasLearningDesign && !guest
      case 'modulesDuplication':
        return hasLearningDesign && !guest
      case 'sequence':
        return hasLearningDesign
      case 'sequencesImport':
        return hasLearningDesign && !guest
      case 'sequencesDuplication':
        return hasLearningDesign && !guest
      case 'taxonomy':
        return hasLearningDesign

      // Project Management
      case 'projectManagement':
        return hasProjectManagement || guest
      case 'project':
        return hasProjectManagement && !guest
      case 'advancedProject':
        return hasProjectManagement && !guest

      // Document Management
      case 'documentManagement':
        return true

      // Team Management
      case 'teamManagement':
        return hasLearningDesign && !guest && (rootGetters['team/managedTeams']?.length > 0 || admin)

      // Marketplace
      case 'mission':
        return hasMarketplace && !guest

      // API false for now
      case 'apiAccess':
        return false && !guest

      // Workspace
      case 'workspaceAdvancedIntegrations':
        return admin
      case 'workspaceLicenseManagement':
        return admin
      case 'workspaceConfigurationAccess':
        return admin || (!guest && (hasLearningDesign || hasProjectManagement || hasMarketplace))
      case 'workspaceDashboard':
        return hasLearningDesign || hasProjectManagement || hasMarketplace || guest
      default:
        return true
    }
  }
}

export const actions = {
  disconnectedByOtherUser ({ commit, dispatch }, msg) {
    dispatch('authorization/disconnect', {}, { root: true })
    commit('SET_KICKED', msg.kick)
  },
  clear ({ commit }) {
    commit('CLEAR')
  },
  academyToken ({ commit }) {
    return new Promise((resolve, reject) => {
      this.$edtake.tiers.getAcademyToken()
        .then((d) => {
          this.$evt.log('AcademyOpen')
          resolve(d)
        })
        .catch((err) => {
          console.error('ERROR', err)
          reject('AN_ERROR_OCCURED')
        })
    })
  },
  fetchProfile ({ commit }) {
    return new Promise((resolve, reject) => {
      this.$edtake.profile.get()
        .then((d) => {
          commit('SET_USER', d)
          this.$evt.initUser()
          this.$chat.initUser()
          resolve(d)
        })
        .catch((err) => {
          console.error('ERROR', err)
          reject('AN_ERROR_OCCURED')
        })
    })
  },
  getSdkInfo ({ commit }, { $queryParameters = {} } = { $queryParameters: {} }) {
    return new Promise((resolve, reject) => {
      this.$edtake.profile.getSdkInfo({ $queryParameters })
        .then((d) => {
          resolve(d)
        })
        .catch((err) => {
          console.error('ERROR', err)
          reject('AN_ERROR_OCCURED')
        })
    })
  },
  updateProfile ({ commit }, body) {
    return new Promise((resolve, reject) => {
      delete body.roles
      delete body.portfolio
      delete body._id
      delete body.password
      delete body.userType
      delete body.createdAt
      delete body.updatedAt
      delete body.__v
      delete body.approved
      this.$edtake.profile.update({
        body
      })
        .then((d) => {
          commit('SET_USER', d)
          resolve()
        })
        .catch((err) => {
          console.error('ERROR', err)
          reject('AN_ERROR_OCCURED')
        })
    })
  },
  // Store refactor - can not test properly
  createIntegration ({ commit }, body) {
    return new Promise((resolve, reject) => {
      this.$edtake.user.createIntegration({
        body
      })
        .then((d) => {
          resolve(d)
        })
        .catch((err) => {
          console.error('ERROR', err)
        })
    })
  },
  //  Store refactor - can not test properly
  createCustomIntegration ({ commit }, body) {
    return new Promise((resolve, reject) => {
      this.$edtake.user.createCustomIntegration({
        body
      })
        .then((d) => {
          resolve(d)
        })
        .catch((err) => {
          console.error('ERROR', err)
        })
    })
  },
  // : Store refactor - can not test properly
  deleteIntegration ({ commit }, { id }) {
    return new Promise((resolve, reject) => {
      this.$edtake.user.deleteIntegration({ id })
        .then((d) => {
          resolve(d)
        })
        .catch((err) => {
          console.error('ERROR', err)
        })
    })
  },
  getUserIntegration ({ commit }) {
    return new Promise((resolve, reject) => {
      this.$edtake.user.getIntegrations()
        .then((d) => {
          commit('SET_INTEGRATIONS_USER_LIST', d)
          resolve(d)
        })
        .catch((err) => {
          console.error('ERROR', err)
          reject(err)
        })
    })
  },
  setNotificationSettings ({ state, commit }, body) {
    return new Promise((resolve, reject) => {
      this.$edtake.user.setNotificationSettings({ body })
        .then((d) => {
          commit('SET_NOTIFICATION_SETTINGS', d)
          this.$evt.log('NotificationSettingsUpdate')
          resolve(d)
        })
        .catch((err) => {
          console.error('ERROR', err)
          reject(err)
        })
    })
  },
  /* Dispatch from other store */
  _update ({ commit }, d) {
    commit('SET_USER', d)
  }

}

export const mutations = {
  SET_USER (state, payload) {
    state.user = { ...payload }
    state.locale = payload.lang
  },
  CLEAR (state, value) {
    state.user = {}
    state.locale = 'fr'
    state.integrationsUserList = []
    state.temporaryAccess = false
    state.kicked = false
    state.sesstion = undefined
  },
  SET_INTEGRATIONS_USER_LIST (state, integrationsUser) {
    state.integrationsUserList = integrationsUser
  },
  SET_TEMPORARY_ACCESS (state, value) {
    state.temporaryAccess = value
  },
  SET_NOTIFICATION_SETTINGS (state, value) {
    state.user = Object.assign({}, state.user, { userNotificationSettings: value }) // force refresh
  },
  SET_SESSION (state, value) {
    state.session = value
  },
  SET_KICKED (state, value) {
    state.kicked = value
  }
}
