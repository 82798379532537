const i18n = {
  messages: {
    fr: {
      unscheduled: '--/--/----',
      required: 'Champs requis'
    },
    en: {
      unscheduled: '--/--/----',
      required: 'Required field'
    }
  }
}
module.exports = i18n
