<template>
  <v-navigation-drawer
    style="background-color: #EEEEEE;"
    height="calc(100vh - 50px)"
  >
    <v-list dense>
      <draggable
        v-model="cloneScenes"
        :disabled="!editRight"
        @end="draggableEnd"
      >
        <v-list-item
          v-for="scene in scenes"
          :key="scene._id"
          :class="[currentSceneId === scene._id ? 'activeScene' : '', 'ma-1 py-0 px-1']"
          data-test="list-item-new-slide-navbar"
        >
          <v-list-item-icon v-if="displaySceneNumbering" :class="[currentSceneId === scene._id ? 'primary--text' : 'brownish-grey--text', 'mx-0']">
            {{ scene.order }}
          </v-list-item-icon>
          <v-list-item-content
            data-test="list-item-display-slide-navbar"
            @click="editScene(scene._id)"
          >
            <v-hover v-slot="{ hover }">
              <v-img
                :class="[hover ? 'elevation-4' : 'elevation-1', 'white rounded pointer']"
                :src="scene.properties.image"
                :aspect-ratio="1.4"
                cover
              >
                <v-overlay v-if="editRight && hover" absolute opacity="0" class="align-end justify-end">
                  <v-tooltip bottom>
                    <template #activator="{ on, attrs }">
                      <span
                        v-bind="attrs"
                        v-on="on"
                      >
                        <edtk-button
                          data-test="button-overview-link-navbar"
                          class="ma-3"
                          x-small
                          @click.stop="deleteScene(scene._id, scene.properties.title)"
                        >
                          <div class="d-flex align-center">
                            <span class="material-symbols-rounded edtk-text-lg">delete</span>
                          </div>
                        </edtk-button>
                      </span>
                    </template>
                    <span>{{ $t('deleteScene') }}</span>
                  </v-tooltip>
                </v-overlay>
              </v-img>
            </v-hover>
          </v-list-item-content>
        </v-list-item>
      </draggable>
    </v-list>

    <template v-if="editRight" #append>
      <v-card flat align="center" style="background-color: #EEEEEE;" class="py-2 ma-0">
        <edtk-button
          data-test="button-new-slide-navbar"
          class="my-1"
          small
          @click="addNewScene"
        >
          {{ $t('addNewSlide') }}
        </edtk-button>
      </v-card>
    </template>
  </v-navigation-drawer>
</template>

<script>
import draggable from 'vuedraggable'
import _ from 'lodash'

export default {
  components: { draggable },
  props: {
    displaySceneNumbering: {
      type: Boolean
    },
    currentSceneId: {
      type: String,
      default: () => ''
    },
    scenes: {
      type: Array,
      default: () => []
    },
    editRight: {
      type: Boolean,
      required: true,
      default: false
    }
  },
  data () {
    return {
      activeScene: 'activeScene',
      hoverImage: 'hoverImage',
      cloneScenes: this.scenes
    }
  },
  i18n: {
    messages: {
      fr: {
        overview: "Vue d'ensemble",
        addNewSlide: 'Créer une scène',
        deleteScene: 'Supprimer la scène'
      },
      en: {
        overview: 'Overview',
        addNewSlide: 'New scene',
        deleteScene: 'Delete the scene'
      }
    }
  },
  watch: {
    scenes: {
      handler (n, o) {
        this.cloneScenes = this.scenes
      }
    }
  },
  methods: {
    draggableEnd () {
      if (!_.isEqual(this.cloneScenes, this.scenes)) {
        this.$nuxt.$emit('updateSceneOrder', this.cloneScenes)
      }
    },
    editScene (sceneId) {
      this.$emit('update:currentSceneId', sceneId)
    },
    addNewScene () {
      this.$emit('addNewScene')
    },
    deleteScene (sceneId, sceneTitle) {
      this.$emit('deleteScene', { sceneId, sceneTitle })
    }
  }
}
</script>

<style scoped>
  .activeScene {
    background-color: rgba(0, 85, 138, 0.2);
    border-radius: 2%;
  }
</style>
