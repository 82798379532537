export const state = () => ({
  licenses: undefined
})

export const getters = {
  getLicenses: (state) => {
    return state.licenses
  }
}

export const actions = {
  LOSearchByType (context, params) {
    return new Promise((resolve, reject) => {
      this.$edtake.inokufu.LOSearchByType(params)
        .then((d) => {
          resolve(d)
        })
        .catch((err) => {
          console.error('ERROR', err)
          reject(err)
        })
    })
  },
  fetchLicenses ({ commit }) {
    return new Promise((resolve, reject) => {
      this.$edtake.inokufu.getLicenses()
        .then((d) => {
          resolve(d)
          commit('SET_LICENSES', d.content)
        })
        .catch((err) => {
          console.error('ERROR', err)
          reject(err)
        })
    })
  }
}

export const mutations = {
  SET_LICENSES (state, value) {
    state.licenses = value
  }
}
