<template>
  <div>
    <div v-if="$fetchState.pending" class="breadcrumb-container">
      <v-skeleton-loader type="heading" width="54"></v-skeleton-loader>
      <v-skeleton-loader type="text" width="100" style="top: 3px;" class="mr-8"></v-skeleton-loader>
      <v-skeleton-loader type="text" width="60" style="top: 3px;" class="mr-8"></v-skeleton-loader>
      <v-skeleton-loader type="text" width="120" style="top: 3px;" class="mr-8"></v-skeleton-loader>
    </div>
    <div v-else class="breadcrumb-container">
      <div v-if="app" class="breadcrumb-app">
        <edtk-icon :icon="$enums.materialIcons[app]" :icon-color="iconColor" icon-size="2xl" />
      </div>
      <div v-if="isLong" class="breadcrumb-links">
        <div class="breadcrumb-links-item">
          <span v-if="$edtkRouter.pageNolink.includes(routes[0].text)" class="mx-3 edtk-body-medium">
            {{ $t(routes[0].text) }}
          </span>
          <nuxt-link v-else :to="routes[0].link" class="breadcrumb-links-item-text edtk-body-medium">
            {{ $t(routes[0].text) }}
          </nuxt-link>
          <span class="breadcrumb-links-item-divider edtk-body-large">/</span>
        </div>
        <div class="breadcrumb-links-item">
          <edtk-dropdown-button
            :items="dropDownList"
            color="secondary"
            icon="more_horiz"
            icon-size="2xl"
            @select="openLink"
          />
          <span class="breadcrumb-links-item-divider edtk-body-large">/</span>
        </div>
        <div class="breadcrumb-links-item">
          <nuxt-link :to="routes[routes.length - 2].link" class="breadcrumb-links-item-text edtk-body-medium">
            {{ routes[routes.length - 2].text }}
          </nuxt-link>
          <span class="breadcrumb-links-item-divider edtk-body-large">/</span>
        </div>
        <div class="breadcrumb-links-item">
          <div class="breadcrumb-links-item-text edtk-label-large">
            {{ routes[routes.length - 1].text }}
          </div>
        </div>
      </div>
      <div v-else class="breadcrumb-links">
        <div v-for="(item, $index) in routes" :key="$index" class="breadcrumb-links-item">
          <span v-if="$edtkRouter.pageNolink.includes(item.text)" class="mx-3 edtk-body-medium">
            {{ $t(item.text) }}
          </span>
          <nuxt-link v-else-if="$index+1 !== routes.length" :to="item.link" class="breadcrumb-links-item-text edtk-body-medium">
            {{ $t(item.text) }}
          </nuxt-link>
          <div v-else class="breadcrumb-links-item-text edtk-label-large">
            {{ $t(item.text) }}
          </div>
          <span v-if="$index+1 !== routes.length" class="breadcrumb-links-item-divider edtk-body-large">/</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import i18n from './i18n.js'

export default {
  name: 'Breadcrumb',
  i18n,
  data () {
    return {
      app: undefined,
      routes: [],
      isLong: false,
      dropDownList: []
    }
  },
  async fetch () {
    this.app = this.$edtkRouter.calculateApp(this.$route)
    this.routes = await this.$edtkRouter.calculateRoutes(this.$route, this.$edtake)
    if (this.routes.length > 4) {
      this.isLong = true
      this.dropDownList = this.routes.reduce((acc, route, i, arr) => {
        return i > 0 && i < arr.length - 2 ? [...acc, { text: route.text, value: route.link }] : acc
      }, [])
    } else {
      this.isLong = false
      this.dropDownList = []
    }
  },
  computed: {
    iconColor () {
      switch (this.app) {
        case 'ld':
          return 'navigation-one'
        case 'pm':
          return 'navigation-two'
        case 'mp':
          return 'navigation-three'
        default:
          return 'primary'
      }
    }
  },
  watch: {
    $route () {
      this.$fetch()
    }
  },
  methods: {
    openLink (link) {
      this.$router.push(link)
    }
  }
}
</script>
<style lang="scss" scoped>
.breadcrumb-container {
  display: flex;
  justify-items: center;
  align-items: center;

  .breadcrumb-app {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 32px;
    height: 32px;
  }

  .breadcrumb-links {
    display: flex;
    justify-items: center;
    align-items: center;

    &-item {
      display: flex;
      justify-items: center;
      align-items: center;

      &-text {
        cursor: pointer;
        margin: auto 12px;
        color: var(--secondary-40);

        &:hover {
          color: var(--primary-40);
        }
      }

      &-divider {
        color: var(--neutral-80);
      }

      &:last-of-type {
        .breadcrumb-links-item-text {
          cursor: initial;
          color: var(--neutral-10);

          &:hover {
            color: var(--neutral-10);
          }
        }
      }
    }
  }
}
</style>
