export const state = () => ({
  newVersion: undefined,
  deferredInstallation: undefined
})

export const getters = {
  getNewVersion (state) {
    return state.newVersion
  },
  deferredInstallation (state) {
    return state.deferredInstallation
  }
}

export const actions = {
  setDeferredInstallation ({ commit }, payload) {
    commit('SET_DEFERRED', payload)
  },
  /* Dispatch from other store */
  _newVersion ({ commit }, payload) {
    commit('NEW_VERSION', payload)
  }
}

export const mutations = {
  NEW_VERSION (state, value) {
    state.newVersion = true
  },
  SET_DEFERRED (state, payload) {
    state.deferredInstallation = payload
  }
}
