<template>
  <edtk-dialog
    v-model="maintenance"
    persistent
    size="xl"
  >
    <template slot="content">
      <v-row class="w-100 mb-5" align="center" no-gutters>
        <v-col cols="12">
          <h1 class="text-center">
            <img src="/img/logo-edtake.svg" alt="edtake" width="250px" />
          </h1>
        </v-col>
      </v-row>
      <v-row class="text-center mt-5" align="center" no-gutters>
        <v-col cols="12">
          <h2 class="blue--text">
            {{ $t('maintenance') }}
          </h2>
          <img src="/img/programming.png" alt="edtake" width="60%" />
        </v-col>
      </v-row>
    </template>
  </edtk-dialog>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  name: 'Maintenance',
  i18n: {
    messages: {
      fr: {
        maintenance: 'Nous sommes actuellement en maintenance afin de vous proposer la meilleure expérience possible. \n\nVeuillez réessayer ultérieurement.'
      },
      en: {
        maintenance: 'We are currently under maintenance in order to offer you the best possible experience.. \n\nPlease try again later.'
      }
    }
  },
  computed: {
    ...mapGetters({
      maintenance: 'remoteConfig/getMaintenanceState'
    })
  }

}
</script>
