import { jitsuClient } from '@jitsu/sdk-js'

// Save utms in localStorage
function saveUtms () {
  let savedUtms = {}
  const urlParams = new URLSearchParams(window.location.search)
  urlParams.forEach((value, key) => {
    if (key.match(/utm_/gi)) {
      savedUtms[key.split('_')[1]] = value
    }
  })
  // Merge with existing stored utms
  if (localStorage.edtake_utms) {
    const storedUtms = JSON.parse(localStorage.edtake_utms)
    savedUtms = { ...storedUtms, ...savedUtms }
  }
  localStorage.edtake_utms = JSON.stringify(savedUtms)
}

export default ({ store }, inject) => {
  const evt = {
    initialized: false,
    userInitialized: false
  }

  // Load utms from localStorage and save them in edtk_utm
  evt.loadUtms = function (properties, event) {
    if (event === 'index' || !evt.userInitialized) { return }
    const utm = localStorage.edtake_utms ? JSON.parse(localStorage.edtake_utms) : {}
    if (utm && Object.keys(utm).length > 0) {
      // save utms in edtk_utm to prevent overwriting
      properties.edtk_utm = utm
      localStorage.removeItem('edtake_utms')
    }
  }

  evt.init = function () {
    try {
      if (!evt.initialized || !evt.jitsu) {
        if (!store.state.remoteConfig.config) {
          return
          // throw new Error('Configuration not loaded')
        }
        if (!store.state.remoteConfig.config.EVT_KEY && store.state.remoteConfig.config.EVT_KEY === 'none') {
          return
          // throw new Error('No event log id found in configuration')
        }
        if (!store.state.remoteConfig.config.EVT_HOST && store.state.remoteConfig.config.EVT_HOST !== 'none') {
          return
          // throw new Error('No event log host found in configuration')
        }

        evt.jitsu = jitsuClient({
          key: store.state.remoteConfig.config.EVT_KEY,
          tracking_host: store.state.remoteConfig.config.EVT_HOST,
          privacy_policy: 'strict'
        })

        evt.initialized = true
      }
    } catch (error) {
      console.error(error)
    }
  }
  evt.initUser = function () {
    try {
      if (!evt.jitsu) { return }
      if (evt.userInitialized) { return }
      if (store.state.user.user._id) {
        const user = store.state.user.user
        evt.jitsu.id({
          internal_id: user._id,
          email: user.email,
          firstname: user.firstname,
          lastname: user.lastname,
          isEdtaker: user.isEdtaker,
          edtakeUsage: user.onboarding?.edtakeUsage,
          crmData: user.onboarding?.crmData,
          userSource: user.onboarding?.userSource,
          skills: user.skills?.map(skill => skill.name),
          authoringTools: user.authoringTools,
          lcms: user.lcms
        })
        evt.userInitialized = true
        return
      }
      // If user is not logged in, we save utms
      // This is because utms are lost when user logs in with mail or on mail signup
      saveUtms()
    } catch (error) {
    }
  }
  evt.log = function (event, properties = {}) {
    try {
      if (!event) { return }
      evt.init()
      evt.initUser()
      if (!evt.initialized || !evt.jitsu) { return }
      evt.loadUtms(properties, event)
      evt.jitsu.track(event, { ws: store.state.workspace.current ? store.state.workspace.current._id : undefined, ...properties })
    } catch (error) {
    }
  }
  // Not used, page events seems to not be working with actual jitsu version
  evt.page = function (event, properties = {}) {
    try {
      if (!event) { return }
      evt.init()
      evt.initUser()
      if (!evt.initialized || !evt.jitsu) { return }
      evt.jitsu.page(event, { ws: store.state.workspace.current ? store.state.workspace.current._id : undefined, ...properties })
    } catch (error) {
    }
  }

  inject('evt', evt)
}
