<template>
  <section>
    <edtkButtonSearch
      :label="$t('search')"
      @click="opened = true"
    />
    <v-dialog
      v-model="opened"
      width="720px"
      transition="scale-transition"
    >
      <ais-instant-search
        :search-client="searchClient"
        :index-name="indexNames[0]"
      >
        <ais-configure
          :filters="searchFilter"
        />
        <v-card class="ma-0 pa-0">
          <v-card-title class="pt-6 d-flex justify-space-between">
            <ais-search-box>
              <template #default="{ refine }">
                <div class="d-flex justify-center align-center">
                  <v-icon class="mr-3" color="browish-grey">
                    mdi-magnify
                  </v-icon>
                  <input
                    ref="searchInputBox"
                    v-model="searchQuery"
                    type="search"
                    :placeholder="$t('search')"
                    class="search-input text-h3"
                    @input="refineSearch(refine, $event.currentTarget.value)"
                  >
                </div>
              </template>
            </ais-search-box>
            <edtk-button-icon data-test="button-magic-close" icon="close" icon-size="4xl" @click="opened = false">
            </edtk-button-icon>
          </v-card-title>
          <div v-if="!context" class="tabs-container">
            <v-tabs v-model="tab">
              <v-tab
                v-for="t in indexNames"
                :key="t"
                :href="'#'+t"
                @click="tab = t"
              >
                {{ $t(`tabs.${$stringHelper.upperSnakeCasetoCamel(t)}`) }}
              </v-tab>
              <v-tabs-items v-model="tab">
                <v-tab-item v-for="index in indexNames" :key="index" :value="index" :transition="false">
                  <ais-index :index-name="index">
                    <ais-hits>
                      <template #default="{ items }">
                        <MagicBoxAlgoliaList :items="items" :loading="refineLoading" :tab="$stringHelper.upperSnakeCasetoCamel(tab)" @filterUpdated="(x) => subFilter = x" />
                      </template>
                    </ais-hits>
                  </ais-index>
                </v-tab-item>
              </v-tabs-items>
            </v-tabs>
          </div>
        </v-card>
      </ais-instant-search>
    </v-dialog>
  </section>
</template>
<script>
import 'instantsearch.css/themes/satellite-min.css'
import Vue from 'vue'
import InstantSearch from 'vue-instantsearch'
import algoliasearch from 'algoliasearch/lite'
import { createNullCache } from '@algolia/cache-common'
import { mapActions, mapGetters } from 'vuex'
import { debounce } from 'lodash'
import i18n from './i18n.js'
import MagicBoxAlgoliaList from './List'
Vue.use(InstantSearch)

export default {
  name: 'MagicBoxAlgolia',
  components: {
    MagicBoxAlgoliaList
  },
  i18n,
  props: {
    context: {
      type: String,
      required: false,
      default: () => undefined
    }
  },
  data () {
    return {
      searchQuery: '',
      refineLoading: false,
      opened: false,
      indexNames: ['PROJECT_ITEM', 'LEARNING_OBJECT', 'MISSION'],
      tab: this.context || 'projectItem',
      subFilter: undefined,
      edtakeDocumentTypeIcons: {
        projectItem: 'mdi-sitemap',
        learningObject: 'mdi-format-list-bulleted',
        mission: 'mdi-briefcase'
      }
    }
  },
  computed: {
    ...mapGetters({
      algoliaSecureKey: 'authorization/getAlgoliaSecureKey',
      currentWorkspace: 'workspace/getCurrentId',
      getAlgoliaAppId: 'remoteConfig/getAlgoliaAppId'
    }),
    searchClient () {
      return algoliasearch(
        this.getAlgoliaAppId,
        this.algoliaSecureKey,
        {
          responsesCache: createNullCache(),
          requestsCache: createNullCache()
        }
      )
    },
    searchFilter () {
      return `NOT __t:task AND NOT archived:true AND workspace:${this.currentWorkspace} AND edtakeDocumentType:${this.$stringHelper.upperSnakeCasetoCamel(this.tab)} ${this.subFilter ? `AND __t:${this.subFilter}` : ''}`
    }
  },
  watch: {
    opened (n) {
      if (n) {
        setTimeout(function () {
          this.focus()
        }.bind(this), 500)
      }
    },
    $route () {
      this.opened = false
    },
    tab (n, o) {
      if (n !== o) {
        this.subFilter = undefined
      }
    }
  },
  mounted () {
    this.$hotkeys(this.$t('hotkeys.magicBox'), (event) => {
      event.preventDefault()
      this.opened = true
    })
  },
  methods: {
    ...mapActions({
      openSnackbar: 'snackbar/setSnackbarOpen'
    }),
    focus () {
      this.$nextTick(() => this.$refs.searchInputBox.focus())
    },
    refineDebounce: debounce((refine, value, self) => {
      refine(value)
      self.refineLoading = false
    }, 1000),
    refineSearch (refine, value) {
      this.refineLoading = true
      this.refineDebounce(refine, value, this)
    }
  }
}
</script>
<style lang="scss" scoped>
// .open-magic-box {
//   width: 200px;
//   border: solid 1px $gray-light;
//   border-radius: 4px;
//   cursor: pointer;
//   color: $brownish-grey;

//   &:hover > * {
//     color: $primary;
//   }
// }

.tabs-container {
  :deep(.v-tabs-bar) {
    margin-bottom: 0 !important;
  }
}

.search-input {
  width: 600px;
  border: none;
}
</style>
