const i18n = {
  messages: {
    fr: {
      newLine: 'Nouvel element'
    },
    en: {
      newLine: 'New element'
    }
  }
}
module.exports = i18n
