<template>
  <v-sheet class="editionCard" :class="displayExperience ? 'justify-space-between' : 'justify-center'">
    <EditableField
      :value="value.skill"
      type="list"
      autocomplete
      parent-container
      :options="translatedSkillsOptions"
      :edit-right="editRight"
      :placeholder="$t('addSkill')"
      @input="(e) => $emit('updateSkill', e)"
    />
    <EditableField
      v-if="displayExperience"
      :value="value.skillExperience"
      type="list"
      autocomplete
      :options="translatedSkillExperienceOptions"
      :edit-right="editRight"
      :placeholder="$t('addLevel')"
      @input="(e) => $emit('updateExperience', e)"
    />
  </v-sheet>
</template>

<script>
import { mapGetters } from 'vuex'
import i18n from './i18n'
export default {
  props: {
    value: {
      type: Object,
      default: () => { return { skill: undefined, skillExperience: undefined } }
    },
    displayExperience: {
      type: Boolean,
      default: true
    },
    editRight: {
      type: Boolean,
      default: true
    }
  },
  data () {
    return {
    }
  },
  i18n,
  computed: {
    ...mapGetters({
      translatedSkillsOptions: 'configuration/getSkillsAsOptions',
      translatedSkillExperienceOptions: 'configuration/getSkillsExperienceAsOptions'
    })
  },
  methods: {
  }
}
</script>

<style scoped>
.editionCard {
  display: flex;
  align-items: center;
  padding: 20px;
  gap: 10px;
  height: 100%;
  min-height: 64px;
  background: #FCFCFF;
  border: 1px solid #E2E2E5;
  border-radius: 4px;
}
.name {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #1A1C1E;
}
</style>
