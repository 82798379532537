<template>
  <v-card class="bg-white-smoke ma-0 pa-0" min-height="100vh">
    <v-toolbar flat>
      <v-toolbar-title class="d-flex ma-0 py-0 px-8 align-center primary--text" style="width:50%">
        <img
          :src="require(`~/assets/img/${$enums.edtakeIcons[dataMutable.contentData.application]}`)"
          :alt="`${dataMutable.contentData.application}`"
          style="max-width: 48px; max-height: 48px;"
          class="mr-5"
        />
        <EditableField
          v-model="dataMutable.name"
          type="text"
          class="font-weight-bold h3 page-title ma-0 py-0"
          :placeholder="$t('column.label')"
          :edit-right="true"
          position="auto"
          large
          @input="$emit('updateDocumentTitle', dataMutable.name)"
        />
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <div class="d-flex align-center justify-end">
        <edtkButton
          v-if="['figma','canva','whimsical'].includes(dataMutable.contentData.application)"
          type="filled"
          :label="$t('edit')"
          :href="editUrl"
          target="_blank"
        />
        <edtkButtonIcon
          icon="close"
          icon-size="4xl"
          color="secondary"
          class="ml-2"
          @click="close()"
        />
      </div>
    </v-toolbar>
    <iframe ref="iframeRef" :src="embedUrl" frameborder="0" class="iframeClass" :allowfullscreen="['figma','canva','whimsical'].includes(dataMutable.contentData.application)"></iframe>
  </v-card>
</template>
<script>
import i18n from './i18n.js'

export default {
  name: 'IframeEditor',
  components: {},
  props: {
    data: {
      type: Object,
      default: () => {}
    }
  },
  i18n,
  data () {
    return {
      dataMutable: this.data ? Object.assign({}, this.data) : undefined
    }
  },
  computed: {
    editUrl () {
      if (this.dataMutable.contentData.application === 'canva') {
        const parse = this.dataMutable.contentData.applicationData.url.split('design/')[1].split('/')
        const id = parse[0]
        const contextId = parse[1] !== 'watch' ? '/' + parse[1] : ''
        return `https://www.canva.com/design/${id}${contextId}/edit?utm_content=DAE8oId8FU8&utm_campaign=designshare&utm_medium=link2&utm_source=sharebutton`
      }
      if (this.dataMutable.contentData.application === 'whimsical') {
        const id = this.dataMutable.contentData.applicationData.url.split('/embed/')[1]
        return `https://www.whimsical.com/${id}`
      }
      return this.dataMutable.contentData.applicationData.url
    },
    embedUrl () {
      if (this.dataMutable.contentData.application === 'figma') {
        return 'https://www.figma.com/embed?embed_host=share&url=' + encodeURI(this.dataMutable.contentData.applicationData.url)
      }
      if (this.dataMutable.contentData.application === 'canva') {
        const id = this.dataMutable.contentData.applicationData.url.split('design/')[1].split('/')[0]
        return `https://www.canva.com/design/${id}/view?embed`
      }
      return this.dataMutable.contentData.applicationData.url
    }
  },
  watch: {
    data: {
      deep: true,
      handler (n) {
        this.dataMutable = n ? Object.assign({}, n) : undefined
      }
    }
  },
  methods: {
    close () {
      this.$emit('close')
    },
    onLoad (e) {
      console.log(e)
    }
  }
}
</script>

<style lang="scss" scoped>
.iframeClass {
  position: absolute;
  top: 64px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  overflow: hidden;
  overflow-x: hidden;
  overflow-y: hidden;
  width: 100%;
  height: calc(100% - 64px);
}
</style>
