<template>
  <v-card class="bg-white-smoke ma-0 pa-0" height="100vh">
    <v-toolbar v-show="!currentSceneId && !templateEdition" flat class="px-3">
      <img
        src="~/assets/img/edtake-icon-fill-storyboard.svg"
        alt="storyboard"
        class="mr-5"
        style="max-width: 40px; max-height: 40px;"
      />
      <v-toolbar-title>
        <EditableField
          type="text"
          :value="storyboard.name"
          :edit-right="editRight"
          required
          class="h2 page-title ma-0 py-0"
          :placeholder="$t('title')"
          position="auto"
          large
          @input="updateStoryboardTitle"
        >
          <template #default>
            <h2 class="page-title ma-0 py-0">
              {{ storyboard.name }}
            </h2>
          </template>
        </EditableField>
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <div class="d-flex align-center justify-end">
        <GenerateTemplateDialog display-button :object-id="edtakeDocumentId" object-type="storyboard" />
        <edtk-button-icon
          icon="close"
          icon-size="3xl"
          color="secondary"
          class="ml-2"
          @click="closeStoryboard"
        >
        </edtk-button-icon>
      </div>
    </v-toolbar>
    <v-divider></v-divider>
    <v-tabs v-show="!currentSceneId && !templateEdition" v-model="tabkey" color="primary" class="px-10 mb-2">
      <v-tab key="overview">
        {{ $t('overviewTab') }}
      </v-tab>
      <v-tab key="summary">
        {{ $t('summaryTab') }}
      </v-tab>
      <v-tab key="library" data-test="tab-library">
        {{ $t('libraryTab') }}
      </v-tab>
      <v-tab key="importExport" data-test="tab-import-export">
        {{ $t('importExportTab') }}
      </v-tab>
    </v-tabs>
    <v-tabs-items v-if="!currentSceneId && !templateEdition" v-model="tabkey">
      <v-tab-item
        key="overview"
      >
        <Overview
          :storyboard="storyboard"
          :current-scene-id.sync="currentSceneId"
          :edit-right="editRight"
          @addNewScene="addNewScene"
          @duplicateScene="duplicateScene"
          @updateSceneOrder="updateSceneOrder"
          @deleteScene="deleteScene"
        />
      </v-tab-item>
      <v-tab-item
        key="summary"
      >
        <Summary :storyboard="storyboard" />
      </v-tab-item>
      <v-tab-item
        key="library"
      >
        <Library
          :storyboard="storyboard"
          :update-catalog="updateCatalog"
          :edit-right="editRight"
          @updateTemplate="updateTemplate"
          @openTemplateEditor="openTemplateEditor"
          @createTemplate="createTemplate"
          @deleteTemplate="deleteTemplate"
          @updateAsset="updateAsset"
          @duplicateAsset="duplicateAsset"
          @deleteAsset="deleteAsset"
          @bulkUpdateAssetId="bulkUpdateAssetId"
        />
      </v-tab-item>
      <v-tab-item
        key="importExport"
      >
        <v-row class="px-10 pb-1">
          <v-col cols="12" md="6">
            <h2 class="page-subtitle">
              {{ $t('importSubtitle') }}
            </h2>
            <v-card>
              <v-row>
                <v-col cols="3" class="pa-3" align-self="center">
                  <img
                    src="~/assets/img/import-illustration.svg"
                    alt="edtake"
                    width="100%"
                  />
                </v-col>
                <v-col cols="9" class="d-flex flex-column justify-space-around">
                  <div>
                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-title class="h6 primary--text mb-2">
                          {{ $t('downloadModel') }}
                        </v-list-item-title>
                        <span class="subtitle-2 brownish-grey--text">{{ $t('downloadTemplateText') }}</span>
                      </v-list-item-content>
                      <v-list-item-action>
                        <edtk-button
                          small
                          :label="$t('download')"
                          @click="downloadModel"
                        >
                        </edtk-button>
                      </v-list-item-action>
                    </v-list-item>
                  </div>
                  <v-divider></v-divider>
                  <div>
                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-title class="h6 primary--text mb-2">
                          {{ $t('importSubtitle') }}
                        </v-list-item-title>
                        <span class="subtitle-2 brownish-grey--text">{{ $t('importText') }}</span>
                      </v-list-item-content>
                      <v-list-item-action>
                        <Import
                          v-if="editRight"
                          :scenes="storyboard.scenes"
                          :storyboard-name="storyboard.name"
                          @save="applyChangesFromImportedStoryboard"
                        />
                      </v-list-item-action>
                    </v-list-item>
                  </div>
                </v-col>
              </v-row>
            </v-card>
          </v-col>
          <v-col cols="12" md="6">
            <h2 class="page-subtitle">
              {{ $t('exportSubtitle') }}
            </h2>
            <v-row>
              <v-col cols="12" md="6">
                <v-card class="pa-0 ma-0">
                  <v-list-item>
                    <v-list-item-avatar class="mr-2 ml-0">
                      <v-icon large color="green darken-3">
                        mdi-microsoft-excel
                      </v-icon>
                    </v-list-item-avatar>
                    <v-list-item-content class="blue--text h1">
                      <v-list-item-title>{{ $t('exportExcel') }}</v-list-item-title>
                    </v-list-item-content>
                    <v-list-item-action>
                      <Export
                        ref="exportXlsx"
                        :storyboard="storyboard"
                        export-type="xlsx"
                      />
                    </v-list-item-action>
                  </v-list-item>
                </v-card>
              </v-col>
              <v-col cols="12" md="6">
                <v-card class="pa-0 ma-0">
                  <v-list-item>
                    <v-list-item-avatar class="mr-2 ml-0">
                      <v-icon large color="red darken-2">
                        mdi-file-pdf-box
                      </v-icon>
                    </v-list-item-avatar>
                    <v-list-item-content class="blue--text h1">
                      <v-list-item-title>{{ $t('exportPdf') }}</v-list-item-title>
                    </v-list-item-content>
                    <v-list-item-action>
                      <!-- diagram component inside export component need to be rebuild before generating image -->
                      <Export
                        v-if="tabkey === 3"
                        :storyboard="storyboard"
                        export-type="pdf"
                      />
                    </v-list-item-action>
                  </v-list-item>
                </v-card>
              </v-col>
              <v-col cols="12" md="6">
                <v-card class="pa-0 ma-0">
                  <v-list-item>
                    <v-list-item-avatar class="mr-2 ml-0">
                      <v-icon large color="orange darken-2">
                        mdi-microsoft-powerpoint
                      </v-icon>
                    </v-list-item-avatar>
                    <v-list-item-content class="blue--text h1">
                      <v-list-item-title>{{ $t('exportPptx') }}</v-list-item-title>
                    </v-list-item-content>
                    <v-list-item-action>
                      <Export
                        :storyboard="storyboard"
                        export-type="pptx"
                      />
                    </v-list-item-action>
                  </v-list-item>
                </v-card>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-tab-item>
    </v-tabs-items>
    <SceneEdition
      v-if="currentSceneId"
      :storyboard="storyboard"
      :current-scene-id.sync="currentSceneId"
      :update-catalog="updateCatalog"
      :edit-right="editRight"
      @addNewScene="addNewScene"
      @editSceneProperty="editSceneProperty"
      @deleteScene="deleteScene"
      @deleteSceneImage="deleteSceneImage"
      @editActionScript="editActionScript"
      @addScriptEditor="addScriptEditor"
      @deleteScriptEditor="deleteScriptEditor"
      @editActionScriptOption="editActionScriptOption"
    />
    <TemplateEditor
      v-if="templateEdition"
      :element-to-edit="elementToEdit"
      :storyboard="storyboard"
      @save="updateTemplate"
      @close="closeTemplateEditor"
    />
    <v-card
      v-for="scene in storyboard.scenes"
      v-show="false"
      :key="scene._id"
    >
      <CanvasDisplayer ref="canvasDisplayer" :scene="scene" :temporary-catalog="storyboard.catalog" @updateSceneImage="updateSceneImage" />
    </v-card>
    <v-card
      v-for="template in storyboard.templates"
      v-show="false"
      :key="template._id"
    >
      <CanvasDisplayer ref="templateCanvasDisplayer" :template="template" :temporary-catalog="storyboard.catalog" @updateTemplate="updateTemplate" />
    </v-card>
  </v-card>
</template>

<script>
import _ from 'lodash'
import ObjectID from 'bson-objectid'
import { mapGetters } from 'vuex'
import Library from './components/library'
import CanvasDisplayer from './components/canvas/components/Displayer'
import TemplateEditor from './components/canvas/components/Editor'
import Overview from './components/Overview'
import SceneEdition from './components/sceneEdition'
import Summary from './summary'
import Export from './export'
import Import from './components/ImportXlsx'
import durationHelper from './_utilities/duration-calculation'
import budgetHelper from './_utilities/voice-over-calculation'

const { Aigle } = require('aigle')

Aigle.mixin(_)

export default {
  components: {
    SceneEdition,
    Overview,
    CanvasDisplayer,
    TemplateEditor,
    Summary,
    Library,
    Export,
    Import
  },
  props: {
    data: {
      type: Object,
      default: () => {}
    },
    documentName: {
      type: String,
      default: () => undefined
    },
    edtakeDocumentId: {
      type: String,
      required: false
    },
    uploadPicture: {
      // attend une fonction 'promisify' qui retourne l'URL de l'image aprés son storage
      type: Function,
      default: () => () => {
        alert('Missing "uploadPicture" function')
      }
    },
    saveStoryboard: {
      // attend une fonction 'promisify' pour valider l'enregistrement dans la db
      type: Function,
      default: () => () => {
        alert('Missing "saveStoryboard" function')
      }
    },
    editRight: {
      type: Boolean,
      required: true,
      default: false
    }
  },
  data () {
    return {
      storyboard: {},
      initialPayload: {},
      currentSceneId: undefined,
      templateEdition: false,
      templateToEdit: { _id: undefined, name: undefined, description: undefined, image: null, canvas: [] },
      tabkey: 0,
      tabkeyRef: {
        0: 'overview',
        1: 'summary',
        2: 'library'
      },
      openGenerateTemplate: false,
      contextGenerateTemplate: undefined
    }
  },
  i18n: {
    messages: {
      fr: {
        title: 'Titre du storyboard',
        overviewTab: 'Scènes',
        summaryTab: 'Synthèse',
        libraryTab: 'Bibliothèque',
        importExportTab: 'Import / Export',
        exportExcel: 'Export Excel',
        exportPdf: 'Export PDF',
        exportPptx: 'Export PowerPoint',
        importSubtitle: 'Importer vos données',
        importText: 'Importez le fichier Excel que vous avez édité.',
        downloadTemplateText: "Nous vous générons un fichier Excel qui comprend les clés des scènes et des dialogues. Éditez la colonne 'description' pour importer des modifications.",
        exportSubtitle: 'Exporter vos données',
        downloadModel: 'Télécharger le modèle',
        download: 'Télécharger',
        saveAlert: 'Storyboard sauvegardé!',
        generateTemplate: 'Enregistrer en tant que modèle',
        deleteScene: 'Supprimer la scène',
        cancel: 'Annuler',
        deleteConfirm: 'Voulez-vous vraiment supprimer : ',
        untitledScene: '<em>scène sans titre</em>',
        alertMessage: 'La scène "{scene}" utilise {name} pour une redirection | Les scènes "{scene}" utilisent {name} pour une redirection'
      },
      en: {
        title: 'Storyboard title',
        overviewTab: 'Scenes',
        summaryTab: 'Summary',
        libraryTab: 'Library',
        importExportTab: 'Import / Export',
        exportExcel: 'Excel export',
        exportPdf: 'PDF export',
        exportPptx: 'PowerPoint export',
        importSubtitle: 'Import data',
        importText: 'Import the Excel file you have edited.',
        downloadTemplateText: "We generate an Excel file for you that includes the scene and dialogue keys. Edit the 'description' column to import the changes.",
        exportSubtitle: 'Export data',
        downloadModel: 'Download template',
        download: 'Download',
        saveAlert: 'Storyboard successfully saved!',
        generateTemplate: 'Save as template',
        deleteScene: 'Delete the scene',
        cancel: 'Cancel',
        deleteConfirm: 'Are you sure you want to delete : ',
        untitledScene: '<em>untitled scene</em>'
      }
    }
  },
  computed: {
    ...mapGetters({
      edtakeDocuments: 'edtakeDocument/get'
    }),
    changesDetected () {
      if (!_.isEmpty(this.initialPayload)) {
        // détecte les modifications :
        // omit des images qui sont supprimées au save et regénérées au mounted pour limiter la taille du document dans la db
        const sb = _.cloneDeep(this.storyboard)
        const initPayload = _.cloneDeep(this.initialPayload)
        // On name change, do not save contentData, just emit event
        if (sb.name !== initPayload.name) {
          return
        }
        sb.scenes = _.map(sb.scenes, d => _.omit(d, ['properties.image']))
        initPayload.scenes = _.map(initPayload.scenes, d => _.omit(d, ['properties.image']))
        sb.templates = _.map(sb.templates, d => _.omit(d, ['image']))
        initPayload.templates = _.map(initPayload.templates, d => _.omit(d, ['image']))
        return !_.isEqual(sb, initPayload)
      } else {
        return false
      }
    },
    currentSceneIdx () {
      return this.storyboard.scenes.findIndex(e => e._id === this.currentSceneId)
    },
    elementToEdit () {
      return { type: 'template', canvas: this.templateToEdit.canvas }
    },
    wordsPerMinute () {
      return this.storyboard?.settings?.duration?.wordsPerMinute
    },
    averageTimeBetweenScenes () {
      return this.storyboard?.settings?.duration?.averageTimeBetweenScenes
    },
    averageTimePerActivity () {
      return this.storyboard?.settings?.duration?.averageTimePerActivity
    },
    costPerWord () {
      return this.storyboard?.settings?.voiceOver?.costPerWord
    },
    costPerCharacter () {
      return this.storyboard?.settings?.voiceOver?.costPerCharacter
    },
    sceneNumbering () {
      return this.storyboard?.settings?.sceneNumbering
    }
  },
  watch: {
    changesDetected (n) {
      if (n) {
        this.save()
      }
    },
    wordsPerMinute (n) {
      if (n) {
        this.updateStoryboardDuration()
      }
    },
    averageTimeBetweenScenes (n) {
      if (n) {
        this.updateStoryboardDuration()
      }
    },
    averageTimePerActivity (n) {
      if (n) {
        this.updateStoryboardDuration()
      }
    },
    costPerWord (n) {
      if (n) {
        this.updateStoryboardBudget()
      }
    }
  },
  beforeMount () {
    this.storyboard = _.cloneDeep(this.data)
    this.storyboard.name = this.documentName
    this.initPayload()
  },
  mounted () {
    this.generateStagePreview()
  },
  created () {
    this.$nuxt.$on('updateScriptOrder', e => this.updateScriptOrder(e))
    this.$nuxt.$on('updateSceneOrder', e => this.updateSceneOrder(e))

    // Be careful if you want to remove this. Scene refresh could not work anymore 😢
    this.$nuxt.$on('saveNewAsset', e => this.saveNewAsset(e))
  },
  destroyed () {
    this.$nuxt.$off('updateScriptOrder')
    this.$nuxt.$off('updateSceneOrder')
    this.$nuxt.$off('saveNewAsset')
  },
  methods: {
    initPayload (reload = false) {
      this.initialPayload = _.cloneDeep(this.storyboard)
      if (reload) {
        this.generateStagePreview()
      }
      if (this.storyboard.duration === -1) {
        this.updateStoryboardDuration()
      }
      if (this.storyboard.voiceOverBudget === -1) {
        this.updateStoryboardBudget()
      }
    },
    getSceneDuration (script) {
      return durationHelper.sceneDuration(script, this.wordsPerMinute, this.averageTimePerActivity)
    },
    getStoryboardDuration () {
      return durationHelper.storyboardDuration(this.storyboard.scenes, this.averageTimeBetweenScenes)
    },
    getSceneBudget (script) {
      return budgetHelper.sceneBudget(script, this.costPerWord)
    },
    getStoryboardBudget () {
      return budgetHelper.storyboardBudget(this.storyboard.assets, this.storyboard.scenes, this.costPerCharacter)
    },
    updateStoryboardDuration () {
      this.storyboard.scenes.forEach((scene, idx) => {
        this.storyboard.scenes[idx].properties.duration = this.getSceneDuration(scene.script)
      })
      this.storyboard.duration = this.getStoryboardDuration()
    },
    updateStoryboardBudget () {
      this.storyboard.scenes.forEach((scene, idx) => {
        this.storyboard.scenes[idx].properties.voiceOverBudget = this.getSceneBudget(scene.script)
      })
      this.storyboard.voiceOverBudget = this.getStoryboardBudget()
    },
    generateTemplate () {
      this.openGenerateTemplate = true
      this.contextGenerateTemplate = this.edtakeDocumentId
    },
    closeGenerateTemplate () {
      this.openGenerateTemplate = false
      this.contextGenerateTemplate = undefined
    },
    // génération des images des scènes et des templates au mounted ou reload
    generateStagePreview () {
      this.$nextTick(() => {
        if (this.storyboard.scenes.length > 0) {
          for (let i = 0; i < this.$refs.canvasDisplayer.length; i++) { this.$refs.canvasDisplayer[i].setSaveStage() }
        }
        if (this.storyboard.templates.length > 0) {
          for (let i = 0; i < this.$refs.templateCanvasDisplayer.length; i++) { this.$refs.templateCanvasDisplayer[i].setSaveTemplateStage() }
        }
      })
    },
    async save () {
      const scenes = []
      this.storyboard.scenes.forEach((scene) => {
        const newProperties = { ...scene.properties, image: null }
        scenes.push({ ...scene, properties: newProperties })
      })
      const templates = []
      this.storyboard.templates.forEach((template) => {
        templates.push({ ...template, image: null })
      })
      const docId = this.storyboard._id
      await this.saveStoryboard({ docId, storyboard: { ...this.storyboard, scenes, templates, updatedAt: Date.now() } })
        .then(() => {
          this.initPayload()
        })
        .catch((error) => {
          console.log(error)
        })
    },
    async updateCatalog (item) {
      const downloadURL = await this.uploadPicture(item.image)
      this.addNewItem({ _id: item._id, value: downloadURL })
    },
    clear () {
      this.currentSceneId = undefined
      this.templateEdition = false
    },
    closeStoryboard () {
      this.clear()
      this.$emit('closeStoryboard')
    },
    downloadModel () {
      this.$evt.log('StoryboardDownloadXlsxModel')
      this.$refs.exportXlsx.onexport()
    },
    applyChangesFromImportedStoryboard (payload) {
      const changes = _.filter(payload, function (o) { return o.status === 'Modified' })
      changes.forEach((i) => {
        const sceneIdx = this.storyboard.scenes.findIndex(s => s._id === i.sceneId)
        const actionIdx = this.storyboard.scenes[sceneIdx].script.findIndex(a => a._id === i.actionId)
        if (i.actionType === 'question' && i.optionId) {
          const optionIdx = this.storyboard.scenes[sceneIdx].script[actionIdx].actionData.options.findIndex(o => o._id === i.optionId)
          this.storyboard.scenes[sceneIdx].script[actionIdx].actionData.options[optionIdx].label = i.description
        } else {
          this.storyboard.scenes[sceneIdx].script[actionIdx].actionData.description = i.description
        }
        this.storyboard.scenes[sceneIdx].script[actionIdx].updatedAt = Date.now()
      })
      this.updateStoryboardDuration()
      this.updateStoryboardBudget()
    },
    updateStoryboardTitle (e) {
      this.storyboard.name = e
      this.$emit('updateDocumentTitle', this.storyboard.name)
    },
    addNewItem (payload) {
      this.storyboard.catalog.push({ ...payload, updatedAt: Date.now() })
    },
    duplicateScene (payload) {
      this.storyboard.scenes.push(payload)
      this.storyboard.duration = this.getStoryboardDuration()
      this.storyboard.voiceOverBudget = this.getStoryboardBudget()
    },
    addNewScene () {
      const _id = ObjectID().toHexString()
      const order = this.storyboard.scenes.length > 0 ? this.storyboard.scenes.map(e => e.order).reduce((a, b) => Math.max(a, b)) : 0
      const payload = {
        order: order + 1,
        _id,
        properties: {
          title: '',
          image: null,
          canvas: [],
          description: '',
          graphicNotes: '',
          editingNotes: '',
          duration: 0,
          voiceOverBudget: 0
        },
        script: []
      }
      this.storyboard.scenes.push(payload)
      this.currentSceneId = payload._id
    },
    updateSceneOrder (data) {
      const res = []
      data.forEach((element, index) => {
        const newOrder = index + 1
        res.push({ ...element, order: newOrder })
      })
      this.storyboard.scenes = res
    },
    editSceneProperty ({ property, data }) {
      if (property === 'canvas') {
        this.storyboard.scenes[this.currentSceneIdx].properties.image = data.image
        this.storyboard.scenes[this.currentSceneIdx].properties.canvas = data.canvas
      } else {
        this.storyboard.scenes[this.currentSceneIdx].properties[`${property}`] = data
      }
    },
    updateSceneImage (payload) {
      const sceneIndex = this.storyboard.scenes.findIndex(s => s._id === payload.sceneId)
      this.storyboard.scenes[sceneIndex].properties.image = payload.image
    },
    deleteSceneImage () {
      this.storyboard.scenes[this.currentSceneIdx].properties.image = null
      this.storyboard.scenes[this.currentSceneIdx].properties.canvas = []
    },
    deleteScene ({ sceneId, sceneTitle }) {
      const name = sceneTitle ? `<b>${sceneTitle}</b>` : this.$t('untitledScene')
      let message = ''
      // check if the scene is linked to another via goToScene option
      const linkedScenes = {}
      this.storyboard.scenes.forEach((scene, idx) => {
        scene.script.forEach((line, index) => {
          const isUsedInGoToScene = line.actionData.options.find(o => o.goToScene === sceneId)
          if (isUsedInGoToScene) {
            const title = this.sceneNumbering ? `${scene.order} - ${scene.properties.title}` : scene.properties.title
            if (!linkedScenes[title]) {
              linkedScenes[title] = []
            }
            linkedScenes[title].push({ sceneIdx: idx, lineIdx: index, options: line.actionData.options })
          }
        })
      })
      const linkedScenesTitles = Object.keys(linkedScenes)
      if (linkedScenesTitles.length) {
        message = `${this.$tc('alertMessage', linkedScenesTitles.length, { scene: linkedScenesTitles.join(', '), name })}.<br/>${this.$t('deleteConfirm')} ${name} ?`
      } else {
        message = this.$t('deleteConfirm') + `${name} ? `
      }
      this.$swal({
        title: '',
        html: message,
        icon: linkedScenes.length ? 'warning' : 'question',
        showCancelButton: true,
        confirmButtonColor: '#4bca81',
        cancelButtonColor: '#c2c5cb',
        confirmButtonText: this.$t('deleteScene'),
        cancelButtonText: this.$t('cancel')
      }).then((result) => {
        if (result.isConfirmed) {
          if (this.currentSceneId === sceneId) {
            this.currentSceneId = undefined
          }

          Object.values(linkedScenes).forEach((scene) => {
            scene.forEach((line) => {
              const options = line.options.map(o => o.goToScene === sceneId ? { ...o, goToScene: undefined } : o)
              this.storyboard.scenes[line.sceneIdx].script[line.lineIdx].actionData.options = options
            })
          })

          this.storyboard.scenes = this.storyboard.scenes.filter(scene => scene._id !== sceneId)
          this.updateSceneOrder(this.storyboard.scenes)
          this.storyboard.duration = this.getStoryboardDuration()
          this.storyboard.voiceOverBudget = this.getStoryboardBudget()
        }
      })
    },
    // SCRIPT
    addScriptEditor ({ type, description, assetId, options }) {
      const newActionId = ObjectID().toHexString()
      const currentScene = this.storyboard.scenes.find(i => i._id === this.currentSceneId)
      const order = currentScene.script.length > 0 ? currentScene.script.map(e => e.order).reduce((a, b) => Math.max(a, b)) : 0
      const newScript = { order: order + 1, _id: newActionId, type, actionData: { assetId, description, options }, updatedAt: Date.now() }
      this.storyboard.scenes[this.currentSceneIdx].script.push(newScript)
      this.storyboard.scenes[this.currentSceneIdx].properties.duration = this.getSceneDuration(this.storyboard.scenes[this.currentSceneIdx].script)
      this.storyboard.duration = this.getStoryboardDuration()
      this.storyboard.scenes[this.currentSceneIdx].properties.voiceOverBudget = this.getSceneBudget(this.storyboard.scenes[this.currentSceneIdx].script)
      this.storyboard.voiceOverBudget = this.getStoryboardBudget()
    },
    updateScriptOrder (data) {
      const res = []
      data.forEach((element, index) => {
        const newOrder = index + 1
        res.push({ ...element, order: newOrder })
      })
      this.storyboard.scenes[this.currentSceneIdx].script = res
    },
    deleteScriptEditor (scriptId) {
      const currentScene = this.storyboard.scenes.find(i => i._id === this.currentSceneId)
      this.storyboard.scenes[this.currentSceneIdx].script = currentScene.script.filter(script => script._id !== scriptId)
      this.storyboard.scenes[this.currentSceneIdx].properties.duration = this.getSceneDuration(this.storyboard.scenes[this.currentSceneIdx].script)
      this.storyboard.duration = this.getStoryboardDuration()
      this.storyboard.scenes[this.currentSceneIdx].properties.voiceOverBudget = this.getSceneBudget(this.storyboard.scenes[this.currentSceneIdx].script)
      this.storyboard.voiceOverBudget = this.getStoryboardBudget()
    },
    editActionScript ({ data, actionType, id }) {
      const idx = this.storyboard.scenes[this.currentSceneIdx].script.findIndex(s => s._id === id)
      switch (actionType) {
        case 'assetId':
          this.storyboard.scenes[this.currentSceneIdx].script[idx].actionData.assetId = data
          break
        case 'description':
          this.storyboard.scenes[this.currentSceneIdx].script[idx].actionData.description = data
          break
        default:
          console.log(`Error with action type: ${actionType}.`)
      }
      this.storyboard.scenes[this.currentSceneIdx].script[idx].updatedAt = Date.now()
      this.storyboard.scenes[this.currentSceneIdx].properties.duration = this.getSceneDuration(this.storyboard.scenes[this.currentSceneIdx].script)
      this.storyboard.duration = this.getStoryboardDuration()
      this.storyboard.scenes[this.currentSceneIdx].properties.voiceOverBudget = this.getSceneBudget(this.storyboard.scenes[this.currentSceneIdx].script)
      this.storyboard.voiceOverBudget = this.getStoryboardBudget()
    },
    bulkUpdateAssetId ({ scenesToUpdate, newValue }) {
      Object.values(scenesToUpdate).forEach((scene) => {
        scene.forEach((line) => {
          this.storyboard.scenes[line.sceneIdx].script[line.lineIdx].actionData.assetId = newValue
        })
      })
    },
    editActionScriptOption ({ data, actionType, scriptId, optionId }) {
      const idx = this.storyboard.scenes[this.currentSceneIdx].script.findIndex(s => s._id === scriptId)
      if (actionType === 'addOption') {
        const _id = ObjectID().toHexString()
        this.storyboard.scenes[this.currentSceneIdx].script[idx].actionData.options.push({ _id, label: undefined, goToScene: undefined })
      } else {
        const optionIdx = this.storyboard.scenes[this.currentSceneIdx].script[idx].actionData.options.findIndex(o => o._id === optionId)
        if (actionType === 'editGoToScene') {
          this.storyboard.scenes[this.currentSceneIdx].script[idx].actionData.options[optionIdx].goToScene = data
        } else if (actionType === 'editLabel') {
          this.storyboard.scenes[this.currentSceneIdx].script[idx].actionData.options[optionIdx].label = data
        } else if (actionType === 'deleteOption') {
          this.storyboard.scenes[this.currentSceneIdx].script[idx].actionData.options = data
        }
        this.storyboard.scenes[this.currentSceneIdx].properties.duration = this.getSceneDuration(this.storyboard.scenes[this.currentSceneIdx].script)
        this.storyboard.duration = this.getStoryboardDuration()
        this.storyboard.scenes[this.currentSceneIdx].properties.voiceOverBudget = this.getSceneBudget(this.storyboard.scenes[this.currentSceneIdx].script)
        this.storyboard.voiceOverBudget = this.getStoryboardBudget()
      }
    },
    saveNewAsset (payload) {
      this.storyboard.assets.push(payload)
    },
    updateAsset (payload) {
      const idx = this.storyboard.assets.findIndex(asset => asset._id === payload._id)
      const asset = this.storyboard.assets.find(asset => asset._id === payload._id)
      this.storyboard.assets[idx] = Object.assign(asset, _.omit(payload, ['_id']))
    },
    duplicateAsset (payload) {
      this.storyboard.assets.splice(payload.index + 1, 0, payload.data)
    },
    deleteAsset (id) {
      this.storyboard.assets = this.storyboard.assets.filter(asset => asset._id !== id)
    },
    createTemplate (payload) {
      this.storyboard.templates.push(payload)
      this.openTemplateEditor(payload)
    },
    openTemplateEditor (template) {
      this.templateToEdit = template
      this.templateEdition = true
    },
    closeTemplateEditor () {
      this.templateEdition = false
    },
    updateTemplate ({ _id, image, canvas, name, description }) {
      const templateId = _id || this.templateToEdit._id
      const templateIndex = this.storyboard.templates.findIndex(template => template._id === templateId)
      if (image) {
        this.storyboard.templates[templateIndex].image = image
      }
      if (canvas) {
        this.storyboard.templates[templateIndex].canvas = canvas
      }
      if (name) {
        this.storyboard.templates[templateIndex].name = name
      }
      if (description) {
        this.storyboard.templates[templateIndex].description = description
      }
    },
    deleteTemplate (id) {
      this.storyboard.templates = this.storyboard.templates.filter(template => template._id !== id)
    }
  }
}
</script>
