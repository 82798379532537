<template lang="html">
  <v-app>
    <Maintenance />
    <WaitingForPayment v-if="WAITING_FOR_PAYMENT_BAND" />
    <TrialBand :workspace="workspace" />
    <v-main data-layout="settings">
      <div
        v-if="workspace.license.subscriptionStatus && (workspace.license.subscriptionStatus !== $enums.stripe.subscriptionStatus.inactive)"
      >
        <FormErrorDialog />
        <transition
          name="nuxtTransition"
          appear
          appear-active-class="component-enter-active"
          leave-active-class="component-leave-active"
        >
          <nuxt />
        </transition>
        <Snackbar />
        <ApplicationUpdate />
        <ProposeChangePassword />
        <DisableSmallDevices />
      </div>
      <featureNotActivated v-else />
    </v-main>
  </v-app>
</template>

<script lang="js">
import { mapGetters } from 'vuex'
import ApplicationUpdate from '~/components/applicationUpdate'
import featureNotActivated from '~/components/featureNotActivated/workspace'
import ProposeChangePassword from '~/components/common/ProposeChangePassword'
import WaitingForPayment from '~/components/workspace/waitingForPaymentBand'
import TrialBand from '~/components/workspace/trialBand'
import Maintenance from '~/components/common/Maintenance'

export default {
  name: 'SettingsLayout',
  components: {
    ApplicationUpdate,
    featureNotActivated,
    ProposeChangePassword,
    WaitingForPayment,
    TrialBand,
    Maintenance
  },
  middleware: ['workspace'],
  data () {
    return {
      socket: null,
      socketStatus: {}
    }
  },
  head () {
    return {
      title: this.$nuxt.$te(`headTitle.${this.$nuxt.$route.name}`) ? this.$nuxt.$t(`headTitle.${this.$nuxt.$route.name}`) : 'edtake'
    }
  },
  computed: {
    ...mapGetters({
      workspace: 'workspace/getCurrent',
      WAITING_FOR_PAYMENT_BAND: 'remoteConfig/getWaitingForPaymentBand'
    })
  },
  mounted () {
    this.$socketManager.getSocket('edtake')
    this.$hotkeys(this.$t('hotkeys.creationBox'), (event) => {
      event.preventDefault()
      this.$nuxt.$emit('open-creationBox')
    })
    this.$hotkeys(this.$t('hotkeys.home'), (event) => {
      event.preventDefault()
      this.$router.push(`/${this.$enums.routePrefix.workspace}/${this.$route.params.workspace}`)
    })
  }
}
</script>
