const i18n = {
  messages: {
    fr: {
      filterByType: 'Filtrer par type',
      objectType: {
        projectItem: 'Gestion de projets',
        learningObject: 'Catalogue',
        mission: 'Missions',
        project: 'Projets',
        deliverable: 'Livrables',
        task: 'Tâches',
        course: 'Parcours',
        module: 'Modules',
        sequence: 'Séquences'
      }
    },
    en: {
      filterByType: 'Filter by type',
      objectType: {
        projectItem: 'Projects management',
        learningObject: 'Learning objects',
        mission: 'Missions',
        project: 'Projects',
        deliverable: 'Deliverables',
        task: 'Tasks',
        course: 'Courses',
        module: 'Modules',
        sequence: 'Sequences'
      }
    }
  }
}
module.exports = i18n
