<template>
  <v-expand-transition>
    <v-card
      v-if="expand"
      class="pa-0 ma-0"
      height="calc(100vh - 100px)"
      width="300px"
    >
      <v-card-title class="primary--text">
        {{ $t('library') }}
        <edtk-button-icon
          icon="close"
          icon-size="xl"
          class="ml-auto"
          color="secondary"
          @click="expandLibrary"
        >
        </edtk-button-icon>
      </v-card-title>
      <v-divider />
      <v-row class="ma-0 text-center">
        <v-tooltip bottom>
          <template #activator="{ on, attrs }">
            <v-col :class="[tabToDisplay === 'character' ? 'activeTab' : '', 'tab cursor']" v-bind="attrs" v-on="on" @click="() => tabToDisplay = 'character'">
              <v-icon :class="[tabToDisplay === 'character' ? 'activeIcon' : '']">
                mdi-emoticon-happy-outline
              </v-icon>
            </v-col>
          </template>
          <span>{{ $t('assetType.character') }}</span>
        </v-tooltip>

        <v-divider vertical />
        <v-tooltip bottom>
          <template #activator="{ on, attrs }">
            <v-col :class="[tabToDisplay === 'background' ? 'activeTab' : '', 'tab cursor']" v-bind="attrs" v-on="on" @click="() => tabToDisplay = 'background'">
              <v-icon :class="[tabToDisplay === 'background' ? 'activeIcon' : '']">
                mdi-image-filter-hdr
              </v-icon>
            </v-col>
          </template>
          <span>{{ $t('assetType.background') }}</span>
        </v-tooltip>
        <v-divider vertical />
        <v-tooltip bottom>
          <template #activator="{ on, attrs }">
            <v-col :class="[tabToDisplay === 'object' ? 'activeTab' : '', 'tab cursor']" v-bind="attrs" v-on="on" @click="() => tabToDisplay = 'object'">
              <v-icon :class="[tabToDisplay === 'object' ? 'activeIcon' : '']">
                mdi-car-hatchback
              </v-icon>
            </v-col>
          </template>
          <span>{{ $t('assetType.object') }}</span>
        </v-tooltip>
        <v-divider vertical />
        <v-tooltip bottom>
          <template #activator="{ on, attrs }">
            <v-col :class="[tabToDisplay === 'other' ? 'activeTab' : '', 'tab cursor']" v-bind="attrs" v-on="on" @click="() => tabToDisplay = 'other'">
              <v-icon :class="[tabToDisplay === 'other' ? 'activeIcon' : '']">
                mdi-package-variant
              </v-icon>
            </v-col>
          </template>
          <span>{{ $t('assetType.other') }}</span>
        </v-tooltip>
        <v-divider vertical />
        <v-tooltip bottom>
          <template #activator="{ on, attrs }">
            <v-col :class="[tabToDisplay === 'template' ? 'activeTab' : '', 'tab cursor']" v-bind="attrs" v-on="on" @click="() => tabToDisplay = 'template'">
              <v-icon :class="[tabToDisplay === 'template' ? 'activeIcon' : '']">
                mdi-content-duplicate
              </v-icon>
            </v-col>
          </template>
          <span>{{ $t('templates') }}</span>
        </v-tooltip>
      </v-row>
      <v-divider />
      <v-row>
        <v-col>
          <div
            v-for="assetType in assetsToDisplay"
            v-show="tabToDisplay === assetType.name "
            :key="assetType.name"
          >
            <v-row class="px-4">
              <v-col>
                <v-switch
                  v-if="typeToEdit === 'scene'"
                  v-model="fromScript"
                  dense
                  color="primary"
                  :label="$t('sceneFilter')"
                />
              </v-col>
            </v-row>
            <v-card flat :class="[typeToEdit === 'template' ? '' : 'pt-1', 'scroll ma-0 pa-0']" height="calc(100vh - 355px)">
              <v-expansion-panels
                multiple
                focusable
                flat
              >
                <v-expansion-panel
                  v-for="asset in assetType.data"
                  :key="asset._id"
                >
                  <v-expansion-panel-header>
                    {{ asset.name }}
                    <template #actions>
                      <v-icon color="primary">
                        $expand
                      </v-icon>
                    </template>
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <div class="d-flex align-content-start flex-wrap mt-2">
                      <img
                        v-for="image in asset.images"
                        :key="image._id"
                        width="31%"
                        class="cursor assetLibraryImage"
                        :src="image.value"
                        @click="addImage({image: image.imageId, ratio: image.ratio})"
                      >
                    </div>
                  </v-expansion-panel-content>
                  <v-divider />
                </v-expansion-panel>
              </v-expansion-panels>
            </v-card>
          </div>
          <div v-show="tabToDisplay === 'template'">
            <v-list max-height="calc(100vh - 300px)" class="text-left scroll">
              <v-list-item
                v-for="template in templatesList"
                :key="template._id"
                class="cursor"
              >
                <v-list-item-content
                  @click="editSelectedTemplate({id: template._id, canva: template.canvas})"
                >
                  <v-img
                    :src="template.image"
                    :class="[selectedTemplate.id === template._id ? 'activeTemplate' : 'templateImage']"
                  />
                  <v-list-item-title v-text="template.name" />
                </v-list-item-content>
              </v-list-item>
            </v-list>
            <edtk-button v-if="selectedTemplate.id" class="mx-auto my-1" small @click="applyTemplate">
              {{ $t('applyTemplateButton') }}
            </edtk-button>
          </div>
        </v-col>
      </v-row>
    </v-card>
  </v-expand-transition>
</template>

<script>
import _ from 'lodash'
import ObjectID from 'bson-objectid'

export default {
  props: {
    expand: {
      type: Boolean,
      default: false
    },
    storyboard: {
      type: Object,
      default: () => {}
    },
    typeToEdit: {
      type: String,
      default: () => ''
    },
    sceneToEdit: {
      type: Object,
      default: () => {}
    },
    newOrder: {
      type: Number
    }
  },
  data () {
    return {
      tabToDisplay: 'character',
      fromScript: false,
      selectedTemplate: { id: null, canva: [] },
      activeTab: 'activeTab',
      activeTemplate: 'activeTemplate',
      stageConfig: { // defaults height & width of the stage in Konva
        width: 780,
        height: 550
      }
    }
  },
  i18n: {
    messages: {
      fr: {
        templates: 'Modèles',
        library: 'Ma bibliothèque',
        sceneFilter: 'Eléments de la scène',
        applyTemplateButton: 'Appliquer',
        assetType: {
          character: 'Personnages',
          background: 'Environnements',
          object: 'Objets',
          other: 'Autres'
        }
      },
      en: {
        templates: 'Templates',
        library: 'My library',
        sceneFilter: 'Assets in the scene',
        applyTemplateButton: 'Apply',
        assetType: {
          character: 'Characters',
          background: 'Backgrounds',
          object: 'Objects',
          other: 'Others'
        }
      }
    }
  },
  computed: {
    assetsToDisplay () {
      return {
        characters: {
          name: 'character',
          data: this.selectAssetType('character')
        },
        backgrounds: {
          name: 'background',
          data: this.selectAssetType('background')
        },
        objects: {
          name: 'object',
          data: this.selectAssetType('object')
        },
        others: {
          name: 'other',
          data: this.selectAssetType('other')
        }
      }
    },
    assetsInScript () {
      const sceneAssets = _.uniq(_.map(this.sceneToEdit.script, 'actionData.assetId'))
      const limitedAvAssets = this.storyboard.assets.filter(asset => !sceneAssets.includes(asset._id))
      // identify assets named in the script or scene description
      limitedAvAssets.forEach((asset) => {
        const description = this.sceneToEdit.properties.description ? this.sceneToEdit.properties.description.toLowerCase() : null
        if (description && description.includes(asset.name.toLowerCase())) {
          sceneAssets.push(asset._id)
        } else {
          this.sceneToEdit.script.forEach((action) => {
            const actionDescription = action.actionData.description ? action.actionData.description.toLowerCase() : null
            if (actionDescription && actionDescription.includes(asset.name.toLowerCase())) {
              sceneAssets.push(asset._id)
            }
          })
        }
      })
      const assets = []
      sceneAssets.forEach((id) => {
        if (id) {
          const asset = this.storyboard.assets.find(asset => asset._id === id)
          if (asset) {
            assets.push(asset)
          }
        }
      })
      return assets
    },
    templatesList () {
      const templates = _.sortBy(this.storyboard.templates, 'name')
      return templates.filter(template => template.canvas.length > 0)
    }
  },
  beforeMount () {
    this.fromScript = this.typeToEdit === 'scene'
  },
  methods: {
    expandLibrary () {
      this.$emit('expandLibrary')
    },
    selectAssetType (assetType) {
      const filteredAssets = this.fromScript ? this.assetsInScript.filter(el => el.type === assetType) : this.storyboard.assets.filter(el => el.type === assetType)
      return this.selectAssetImages(filteredAssets)
    },
    selectAssetImages (filteredAssets) {
      const result = []
      filteredAssets.forEach((asset) => {
        const images = []
        asset.images.forEach((image) => {
          const assetImage = this.storyboard.catalog.find(a => a._id === image.value)
          if (assetImage) {
            images.push({ _id: image._id, value: assetImage.value, imageId: assetImage._id, ratio: assetImage.ratio })
          }
        })
        result.push(({ _id: asset._id, name: asset.name, images }))
      })
      return result
    },
    editSelectedTemplate (payload) {
      this.selectedTemplate = payload
    },
    applyTemplate () {
      let order = this.newOrder + 1
      let payload = _.cloneDeep(this.selectedTemplate.canva)
      payload = _.map(payload, (p) => {
        const newId = ObjectID().toHexString()
        const newName = p.type === 'custom' ? `${p.config.customType}_${newId}` : `${p.type}_${newId}`
        const newConfig = { ...p.config, id: newId, name: newName }
        return { ...p, order: order++, config: newConfig }
      })
      this.$emit('applyTemplate', payload)
      this.selectedTemplate = { id: null, canva: [] }
    },
    addImage (payload) {
      const img = new Image()
      const imgValue = this.storyboard.catalog.find(i => i._id === payload.image)
      img.src = imgValue.value
      let ratio = 1
      const self = this
      img.onload = function () {
        if (img.height >= self.stageConfig.height || img.width >= self.stageConfig.width) {
          const maxHeight = self.stageConfig.height * 0.75
          const maxWidth = self.stageConfig.width * 0.75
          const widthRatio = maxWidth / img.width
          const heightRatio = maxHeight / img.height
          ratio = Math.min(widthRatio, heightRatio)
        }
        self.$emit('addImage', { ...payload, ratio })
      }
    }
  }
}
</script>
<style scoped>
  .scroll {
   overflow-y: auto
  }
  .cursor {
    cursor: pointer;
  }
  .activeIcon {
    color: rgba(0, 85, 138);
  }
  .activeTab {
    border-bottom: 3px solid rgba(0, 85, 138);
  }
  .activeTemplate {
    border: 3px solid rgba(0, 85, 138, 0.7);
    border-radius: 3%;
  }
  .tab:hover {
    background-color: rgba(0, 85, 138, 0.2);
  }
  .assetLibraryImage {
    margin:5px 1%;
    object-fit:contain;
  }
  .templateImage {
    border: 2px solid lightgray;
    border-radius: 3%;
  }
</style>
