const i18n = {
  messages: {
    fr: {
      ARCHIVE: 'Archive',
      status: {
        deliverable: {
          CANCELED: 'Annulé',
          DONE: 'Terminé',
          DRAFT: 'Brouillon',
          IN_PROGRESS: 'En cours',
          PAUSE: 'Pause',
          TO_CORRECT: 'A corriger',
          TO_DO: 'A faire',
          TO_REVIEW: 'A valider',
          WAITING_ASSIGNEE: "En attente d'assignation"
        },
        module: {
          CADRAGE: 'Cadrage',
          CONCEPTION: 'Conception',
          PRODUCTION: 'Production',
          VALIDATION: 'Validation',
          CLOSURE: 'Clôture',
          ARCHIVE: 'Archivage'
        },
        course: {
          CADRAGE: 'Cadrage',
          CONCEPTION: 'Conception',
          PRODUCTION: 'Production',
          VALIDATION: 'Validation',
          CLOSURE: 'Clôture',
          ARCHIVE: 'Archivage'
        },
        mechanism: {
          CADRAGE: 'Cadrage',
          CONCEPTION: 'Conception',
          PRODUCTION: 'Production',
          VALIDATION: 'Validation',
          CLOSURE: 'Clôture',
          ARCHIVE: 'Archivage'
        }
      },
      alert: {
        changeStatus: {
          project: 'Passer le projet au statut <b>"{status}"</b> ?',
          sequence: 'Passer la séquence au statut <b>"{status}"</b> ?',
          deliverable: 'Passer le livrable au statut <b>"{status}"</b> ?',
          module: 'Passer le module au statut <b>"{status}"</b> ?',
          course: 'Passer le projet au statut <b>"{status}"</b> ?',
          task: 'Passer la tâche au statut <b>"{status}"</b> ?',
          mechanism: 'Passer le dispositif au statut <b>"{status}"</b> ?'
        },
        confirm: 'Confirmer',
        cancel: 'Annuler',
        save: {
          success: {
            title: 'Modifications enregistrées',
            text: 'Vos modifications ont bien été enregistrées.'
          },
          error: {
            title: "Echec de l'enregistrement",
            text:
              'Une erreur est survenue.<br>Veuillez réessayer ultérieurement.'
          }
        }
      }
    },
    en: {
      ARCHIVE: 'Archive',
      status: {
        deliverable: {
          CANCELED: 'Canceled',
          DONE: 'Done',
          DRAFT: 'Draft',
          IN_PROGRESS: 'In progress',
          PAUSE: 'Pause',
          TO_CORRECT: 'To correct',
          TO_DO: 'To do',
          TO_REVIEW: 'To review',
          WAITING_ASSIGNEE: 'Waiting assignee'
        },
        module: {
          CADRAGE: 'Cadrage',
          CONCEPTION: 'Design',
          PRODUCTION: 'Production',
          VALIDATION: 'Validation',
          CLOSURE: 'Closure',
          ARCHIVE: 'Archive'
        },
        course: {
          CADRAGE: 'Cadrage',
          CONCEPTION: 'Design',
          PRODUCTION: 'Production',
          VALIDATION: 'Validation',
          CLOSURE: 'Closure',
          ARCHIVE: 'Archive'
        },
        mechanism: {
          CADRAGE: 'Cadrage',
          CONCEPTION: 'Design',
          PRODUCTION: 'Production',
          VALIDATION: 'Validation',
          CLOSURE: 'Closure',
          ARCHIVE: 'Archive'
        }
      },
      alert: {
        changeStatus: {
          project: 'Move the project to status <b>"{status}"</b> ?',
          sequence: 'Move the sequence to status <b>"{status}"</b> ?',
          deliverable: 'Move the deliverable to status <b>"{status}"</b> ?',
          module: 'Move the module to status <b>"{status}"</b> ?',
          course: 'Move the course to status <b>"{status}"</b> ?',
          task: 'Move the task to status <b>"{status}"</b> ?',
          mechanism: 'Move the mechanism to status <b>"{status}"</b> ?'
        },
        confirm: 'Delete',
        cancel: 'Cancel',
        save: {
          success: {
            title: 'Changes saved',
            text: 'Your changes have been saved.'
          },
          error: {
            title: 'Failure to save',
            text: 'An error occurred.<br>Please try again later.'
          }
        }
      }
    }
  }
}
module.exports = i18n
