<template>
</template>

<script>
import i18n from './i18n.js'
export default {
  name: 'DisableSmallDevices',
  i18n,
  mounted () {
    let vw = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0)
    if (vw < 1024) {
      this.$swal({
        icon: 'info',
        title: this.$t('title'),
        text: this.$t('text'),
        showCancelButton: false,
        showConfirmButton: false,
        showCloseButton: false,
        backdrop: `
          #fff
        `,
        allowOutsideClick: false,
        allowEscapeKey: false,
      })
    }
  }
}
</script>
