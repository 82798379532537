<template lang="html">
  <v-app>
    <v-main data-layout="public">
      <FormErrorDialog />
      <DisableSmallDevices />
      <nuxt />
    </v-main>
  </v-app>
</template>

<script lang="js">
export default {
  name: 'PublicLayout',
  auth: false,
  middleware: ['configuration'],
  i18n: {
    messages: {
      fr: {},
      en: {}
    }
  },
  data () {
    return {
    }
  },
  head () {
    return {
      title: this.$nuxt.$te(`headTitle.${this.$nuxt.$route.name}`) ? this.$nuxt.$t(`headTitle.${this.$nuxt.$route.name}`) : 'edtake'
    }
  }
}
</script>
