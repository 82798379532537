var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.item.type === 'rect')?_c('v-rect',{attrs:{"config":{
      ..._vm.shapeConfig,
      shapeId: _vm.shapeId
    }},on:{"dragmove":_vm.handleDragMove,"transformend":_vm.handleTransformEnd}}):_vm._e(),_vm._v(" "),(_vm.item.type === 'circle')?_c('v-circle',{attrs:{"config":{
      ..._vm.shapeConfig,
      shapeId: _vm.shapeId
    }},on:{"dragmove":_vm.handleDragMove,"transformend":_vm.handleTransformEnd}}):_vm._e(),_vm._v(" "),(_vm.item.type === 'text')?_c('v-text',{attrs:{"config":{
      ..._vm.shapeConfig,
      shapeId: _vm.shapeId
    }},on:{"dragmove":_vm.handleDragMove,"transform":_vm.handleTransformText}}):_vm._e(),_vm._v(" "),(_vm.item.type === 'image')?_c('v-image',{attrs:{"config":{
      ..._vm.imageConfig,
      shapeId: _vm.shapeId
    }},on:{"dragmove":_vm.handleDragMove,"transformend":_vm.handleTransformEnd}}):_vm._e(),_vm._v(" "),(_vm.item.type === 'custom')?_c('VCustomShape',_vm._g({attrs:{"config":_vm.config}},_vm.$listeners)):_vm._e(),_vm._v(" "),(_vm.item.type === 'arrow')?_c('v-arrow',{attrs:{"config":{
      ..._vm.shapeConfig,
      shapeId: _vm.shapeId
    }},on:{"dragmove":_vm.handleDragMove,"transformend":_vm.handleTransformEnd}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }