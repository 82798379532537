/* eslint-disable no-console */
/* eslint-disable camelcase */
import _ from 'lodash'

export const state = () => ({
  missions: [],
  users: [],
  workspaces: [],
  currentWorkspace: undefined,
  issuers: [],
  isAdmin: false,
  staff: []
})
export const getters = {
  getMission: state => (id) => {
    return _.find(state.missions, (m) => { return m._id === id })
  },
  getMissions: (state) => {
    return state.missions
  },
  getWorkspaces: (state) => {
    return state.workspaces
  },
  getCurrentWorkspace: (state) => {
    return state.currentWorkspace
  },
  getUsers: (state) => {
    return state.users
  },
  getIssuers: (state) => {
    return state.issuers
  },
  getIsAdmin: (state) => {
    return state.isAdmin
  },
  getStaff: (state) => {
    return state.staff
  }
}

export const actions = {
  isAdmin ({ commit }) {
    return new Promise((resolve, reject) => {
      this.$edtake.admin.isAdmin()
        .then((d) => {
          commit('SET_ISADMIN', d)
          resolve(d)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  fetchMissions ({ commit }, { $queryParameters = {} } = { $queryParameters: {} }) {
    return new Promise((resolve, reject) => {
      this.$edtake.admin.getMissions({ $queryParameters })
        .then((d) => {
          commit('SET_MISSION', d)
          resolve(d)
        })
        .catch((err) => {
          console.error('ERROR', err)
          reject(err)
        })
    })
  },
  fetchProposals ({ commit }, { ownerId }) {
    return new Promise((resolve, reject) => {
      this.$edtake.admin.getProposals({ ownerId })
        .then((d) => {
          resolve(d)
        })
        .catch((err) => {
          console.error('ERROR', err)
          reject(err)
        })
    })
  },
  fetchIssuers ({ commit }, { $queryParameters = {} } = { $queryParameters: {} }) {
    return new Promise((resolve, reject) => {
      this.$edtake.admin.getIssuers({ $queryParameters })
        .then((d) => {
          commit('SET_ISSUERS', d)
          resolve(d)
        })
        .catch((err) => {
          console.error('ERROR', err)
          reject(err)
        })
    })
  },
  fetchOneMission ({ commit }, { id }) {
    return new Promise((resolve, reject) => {
      this.$edtake.admin.getOneMission({ id })
        .then((d) => {
          commit('UPDATE_MISSION', d)
          resolve(d)
        })
        .catch((err) => {
          console.error('ERROR', err)
          reject(err)
        })
    })
  },
  fetchWorkspaces ({ commit }, { $queryParameters = {} } = { $queryParameters: { type: { ne: 'freelance' } } }) {
    return new Promise((resolve, reject) => {
      this.$edtake.admin.getWorkspace({ $queryParameters })
        .then((d) => {
          commit('SET_WORKSPACES', d)
          resolve(d)
        })
        .catch((err) => {
          console.error('ERROR', err)
          reject(err)
        })
    })
  },
  fetchOneWorkspace ({ commit }, { id }) {
    return new Promise((resolve, reject) => {
      this.$edtake.admin.getOneWorkspace({ id })
        .then((d) => {
          commit('SET_WORKSPACE', d)
          commit('workspace/SET_WORKSPACE', d, { root: true })
          resolve(d)
        })
        .catch((err) => {
          console.error('ERROR', err)
          reject(err)
        })
    })
  },
  fetchUsers ({ commit }, { $queryParameters = {} } = { $queryParameters: { isEdtaker: true } }) {
    return new Promise((resolve, reject) => {
      this.$edtake.admin.getUsers({ $queryParameters })
        .then((d) => {
          commit('SET_USERS', d)
          resolve(d)
        })
        .catch((err) => {
          console.error('ERROR', err)
          reject(err)
        })
    })
  },
  fetchOneUser ({ commit }, { id }) {
    return new Promise((resolve, reject) => {
      this.$edtake.admin.getOneUser({ id })
        .then((d) => {
          commit('UPDATE_USER', d)
          resolve(d)
        })
        .catch((err) => {
          console.error('ERROR', err)
          reject(err)
        })
    })
  },
  createProposals ({ commit }, { mission, edtakers }) {
    return new Promise((resolve, reject) => {
      this.$edtake.admin.createProposals({
        body: {
          mission,
          edtakers
        }
      })
        .then((d) => {
          commit('UPDATE_MISSION_PROPOSALS', { mission, d })
          resolve(d)
        })
        .catch((err) => {
          console.error('ERROR', err)
          reject(err)
        })
    })
  },
  updateMission ({ commit, dispatch }, { id, body }) {
    return new Promise((resolve, reject) => {
      this.$edtake.admin.updateMission({
        id,
        body
      })
        .then((d) => {
          commit('UPDATE_MISSION', d)
          resolve(d)
        })
        .catch((err) => {
          console.error('ERROR', err)
          reject(err)
        })
    })
  },
  updateProposal ({ commit, dispatch }, { id, missionId, body }) {
    return new Promise((resolve, reject) => {
      this.$edtake.admin.updateProposal({
        id,
        body
      })
        .then((d) => {
          commit('UPDATE_MISSION_PROPOSAL', { missionId, d })
          resolve(d)
        })
        .catch((err) => {
          console.error('ERROR', err)
          reject(err)
        })
    })
  },
  createIssuer ({ commit, dispatch }, { body }) {
    return new Promise((resolve, reject) => {
      this.$edtake.admin.createIssuer({
        body
      })
        .then((d) => {
          commit('CREATE_ISSUER', d)
          resolve(d)
        })
        .catch((err) => {
          console.error('ERROR', err)
          reject(err)
        })
    })
  },
  updateIssuer ({ commit, dispatch }, { id, body }) {
    return new Promise((resolve, reject) => {
      this.$edtake.admin.updateIssuer({
        id,
        body
      })
        .then((d) => {
          commit('UPDATE_ISSUERS', d)
          resolve(d)
        })
        .catch((err) => {
          console.error('ERROR', err)
          reject(err)
        })
    })
  },
  deleteIssuer ({ commit }, { id }) {
    return new Promise((resolve, reject) => {
      this.$edtake.admin.deleteIssuer({ id })
        .then((d) => {
          commit('REMOVE_ISSUER', id)
          resolve(d)
        })
        .catch((err) => {
          console.error('ERROR', err)
          reject(err)
        })
    })
  },
  getUserByCriteria ({ commit, dispatch }, { body }) {
    return new Promise((resolve, reject) => {
      this.$edtake.admin.getUserByCriteria({
        body
      })
        .then((d) => {
          resolve(d)
        })
        .catch((err) => {
          console.error('ERROR', err)
          reject(err)
        })
    })
  },
  createWorkspace ({ commit, dispatch }, { body }) {
    return new Promise((resolve, reject) => {
      this.$edtake.admin.createWorkspaces({
        body
      })
        .then((d) => {
          resolve(d)
        })
        .catch((err) => {
          console.error('ERROR', err)
          reject(err)
        })
    })
  },
  addMemberWorkspace ({ commit }, { body }) {
    return new Promise((resolve, reject) => {
      this.$edtake.admin.addStaffWorkspace({
        body
      }).then((d) => {
        commit('UPDATE_WORKSPACE', d)
        resolve(d)
      })
        .catch((err) => {
          reject(err)
        })
    })
  },
  activateWorkspace ({ commit, dispatch }, { id }) {
    return new Promise((resolve, reject) => {
      this.$edtake.admin.activateWorkspace({
        id
      })
        .then((d) => {
          commit('UPDATE_WORKSPACE', d)
          resolve(d)
        })
        .catch((err) => {
          console.error('ERROR', err)
          reject(err)
        })
    })
  },
  updateWorkspace ({ commit, dispatch }, { id, body }) {
    return new Promise((resolve, reject) => {
      this.$edtake.admin.updateWorkspace({
        id,
        body
      })
        .then((d) => {
          commit('UPDATE_WORKSPACE', d)
          resolve(d)
        })
        .catch((err) => {
          console.error('ERROR', err)
          reject(err)
        })
    })
  },
  deleteProposals ({ commit }, { id, body }) {
    return new Promise((resolve, reject) => {
      this.$edtake.admin.deleteProposals({
        id,
        body
      })
        .then((d) => {
          commit('DELETE_MISSION_PROPOSALS', { id })
          resolve(d)
        })
        .catch((err) => {
          console.error('ERROR', err)
          reject(err)
        })
    })
  },
  getMissionsByRealisationAssignee ({ commit }, { id }) {
    return new Promise((resolve, reject) => {
      this.$edtake.admin.getMissionsByAssignee({ id })
        .then((d) => {
          resolve(d)
        })
        .catch((err) => {
          console.error('ERROR', err)
          reject(err)
        })
    })
  },
  cancelReview ({ commit }, { missionId, deliveryId }) {
    return new Promise((resolve, reject) => {
      this.$edtake.admin.cancelReview({
        missionId,
        deliveryId
      })
        .then((d) => {
          resolve(d)
        })
        .catch((err) => {
          console.error('ERROR', err)
          reject(err)
        })
    })
  },
  addUserToStaff ({ commit }, { body }) {
    return new Promise((resolve, reject) => {
      this.$edtake.admin.addUserToStaff({
        body
      })
        .then((d) => {
          commit('UPDATE_STAFF', d)
          resolve(d)
        })
        .catch((err) => {
          console.error('ERROR', err)
          reject(err)
        })
    })
  },
  fetchStaff ({ commit }) {
    return new Promise((resolve, reject) => {
      this.$edtake.admin.getStaff()
        .then((d) => {
          commit('SET_STAFF', d)
          resolve(d)
        })
        .catch((err) => {
          console.error(err)
          reject(err)
        })
    })
  },
  removeUserFromStaff ({ commit }, { id }) {
    return new Promise((resolve, reject) => {
      this.$edtake.admin.removeUserFromStaff({ id })
        .then((d) => {
          commit('REMOVE_STAFF', id)
          resolve(d)
        })
        .catch((err) => {
          console.error(err)
          reject(err)
        })
    })
  }
}
export const mutations = {
  SET_MISSION (state, value) {
    state.missions = value
  },
  SET_ISSUERS (state, value) {
    state.issuers = value
  },
  SET_WORKSPACES (state, value) {
    state.workspaces = value
  },
  SET_WORKSPACE (state, value) {
    state.currentWorkspace = value
  },
  SET_USERS (state, value) {
    state.users = value
  },
  SET_STAFF (state, value) {
    state.staff = value
  },
  SET_ISADMIN (state, value) {
    state.isAdmin = value
  },
  UPDATE_MISSION (state, value) {
    const item = state.missions.find(item => item._id.toString() === value._id.toString())
    if (item) {
      Object.assign(item, value)
      state.missions = [...state.missions] // Force refresh
    } else {
      state.missions = [...state.missions, value]
    }
  },
  UPDATE_MISSION_PROPOSAL (state, value) {
    const item = state.missions.find(item => item._id.toString() === value.missionId.toString())
    if (item) {
      const proposal = item.proposals.find(item => item._id.toString() === value.d._id.toString())
      if (proposal) {
        Object.assign(proposal, value.d)
        state.missions = [...state.missions] // Force refresh
      }
    }
  },
  UPDATE_MISSION_PROPOSALS (state, value) {
    const item = state.missions.find(item => item._id.toString() === value.mission.toString())
    if (item) {
      item.proposals = value.d
      state.missions = [...state.missions] // Force refresh
    }
  },
  DELETE_MISSION_PROPOSALS (state, value) {
    const item = state.missions.find(item => item._id.toString() === value.id.toString())
    if (item) {
      item.proposals = null
      state.missions = [...state.missions] // Force refresh
    }
  },
  CREATE_ISSUER (state, value) {
    state.issuers = [...state.issuers, value]
  },
  UPDATE_ISSUERS (state, value) {
    const item = state.issuers.find(item => item._id.toString() === value._id.toString())
    if (item) {
      Object.assign(item, value)
      state.issuers = [...state.issuers] // Force refresh
    } else {
      state.issuers = [...state.issuers, value]
    }
  },
  REMOVE_ISSUER (state, value) {
    state.issuers = state.issuers.filter(issuer => issuer._id !== value)
  },
  UPDATE_USER (state, value) {
    const item = state.users.find(item => item._id.toString() === value._id.toString())
    if (item) {
      Object.assign(item, value)
      state.users = [...state.users] // Force refresh
    } else {
      state.users = [...state.users, value]
    }
  },
  UPDATE_WORKSPACE (state, value) {
    const item = state.workspaces.find(item => item._id.toString() === value._id.toString())
    if (item) {
      Object.assign(item, value)
      state.workspaces = [...state.workspaces] // Force refresh
    } else {
      state.workspaces = [...state.workspaces, value]
    }
  },
  UPDATE_STAFF (state, value) {
    state.staff = [...state.staff, value]
  },
  REMOVE_STAFF (state, value) {
    state.staff = state.staff.filter(staff => staff.user._id !== value)
  }
}
