<template>
  <v-container class="py-3 fill-height">
    <v-row class="mb-5" align="center" no-gutters>
      <v-col cols="12">
        <h1 class="text-center">
          <img src="/img/logo-edtake.svg" alt="edtake" width="250px" />
        </h1>
      </v-col>
    </v-row>
    <v-row class="text-center mt-5" align="center" no-gutters>
      <v-col cols="12" md="6" offset-md="3">
        <div v-if="error.statusCode === 404">
          <h2 class="blue--text">
            {{ $t("errors.404") }}
          </h2>
          <img src="/img/not_found.png" alt="edtake" width="100%" />
        </div>
        <div v-else>
          <h2 class="blue--text" v-html="$t('errors.AN_ERROR_OCCURED')"></h2>
          <img src="/img/error_occured.png" alt="edtake" width="100%" />
        </div>
      </v-col>
    </v-row>
    <v-row align="center" no-gutters>
      <v-col cols="12" class="mt-5">
        <edtkButton
          :label="$t('menu.HOME')"
          type="filled"
          class="my-4 mx-auto"
          @click="$router.push('/')"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  layout: 'public',
  props: {
    error: {
      type: Object
    }
  }
}
</script>
