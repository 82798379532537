const i18n = {
  messages: {
    fr: {
      step: 'Étape {current} sur {max}'
    },
    en: {
      step: 'Step {current} of {max}'
    }
  }
}
module.exports = i18n
