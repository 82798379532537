import _ from 'lodash'
import dayjs from 'dayjs'

const generateTasks = function (teamPI, team) {
  const allTasks = _.map(teamPI, (pi) => {
    const newPi = _.cloneDeep(pi)
    newPi.members = _.filter(newPi.members, member => member.memberType === 'user' &&
    team.members.find(tm => member.user ? member.user._id === tm.user._id : false))
    let task = {
      Name: newPi.name,
      StartTime: new Date(newPi.startDate),
      EndTime: new Date(newPi.dueDate),
      workload: 0,
      workloadPerUser: 0,
      workloadPerUserDaily: 0,
      workloadPerDay: 0,
      groupId: 1,
      __t: newPi.__t,
      members: newPi.members,
      teamAsMember: pi.members.find(m => m.user ? m.user._id === team._id : false),
      projectItem: pi,
      userID: []
    }
    if (pi.estimations) {
      const businessDay = dayjs(pi.dueDate).businessDiff(dayjs(pi.startDate))
      task = {
        ...task,
        workload: pi.estimations.firstDraftWorkload,
        workloadPerDay: businessDay ? pi.estimations.firstDraftWorkload / team.members.length / businessDay : 0,
        workloadPerUser: task.members.length ? pi.estimations.firstDraftWorkload / task.members.length : 0,
        workloadPerUserDaily: task.members.length && businessDay ? pi.estimations.firstDraftWorkload / task.members.length / businessDay : 0
      }
    }
    const assignedMembers = task.members.map(m => m.user._id)
    task.userID = assignedMembers.length ? assignedMembers : ['1']
    return task
  })
  return allTasks
}

const generateTaskCalendar = function (tasks) {
  const calendar = {}

  tasks.forEach((task) => {
    let year = dayjs(task.projectItem.startDate).year()
    const dueYear = dayjs(task.projectItem.dueDate).year()
    do {
      if (!(year.toString() in calendar)) {
        calendar[year.toString()] = Array.from({ length: dayjs().set('year', year).endOf('y').diff(dayjs().set('year', year).startOf('y'), 'day') + 2 }, () => ([]))
      }
    }
    while (year++ < dueYear)
    const firstDay = dayjs(task.projectItem.startDate)
    const lastDay = dayjs(task.projectItem.dueDate)
    for (let day = firstDay; lastDay.add(1, 'day').diff(day, 'day') > 0; day = day.add(1, 'day')) {
      if (dayjs(day).isBusinessDay()) {
        calendar[day.year().toString()][day.dayOfYear()].push(task)
      }
    }
  })
  return calendar
}
const generateTaskCalendarWorkpsace = function (tasks, members) {
  const calendar = {}
  tasks.forEach((task) => {
    const businessDay = dayjs(task.dueDate).businessDiff(dayjs(task.startDate))
    if (task.estimations) {
      task = {
        ...task,
        workloadPerDay: businessDay ? task.estimations.firstDraftWorkload / members.length / businessDay : 0,
        workloadPerUser: task.members.length ? task.estimations.firstDraftWorkload / task.members.length : 0,
        workloadPerUserDaily: task.members.length && businessDay ? task.estimations.firstDraftWorkload / task.members.length / businessDay : 0
      }
    } else {
      task = {
        ...task,
        workloadPerDay: 0,
        workloadPerUserDaily: 0,
        workloadPerUser: 0
      }
    }
    let year = dayjs(task.startDate).year()
    const dueYear = dayjs(task.dueDate).year()
    do {
      if (!(year.toString() in calendar)) {
        calendar[year.toString()] = Array.from({ length: dayjs().set('year', year).endOf('y').diff(dayjs().set('year', year).startOf('y'), 'day') + 2 }, () => ([]))
      }
    }
    while (year++ < dueYear)
    const firstDay = dayjs(task.startDate)
    const lastDay = dayjs(task.dueDate)
    for (let day = firstDay; lastDay.add(1, 'day').diff(day, 'day') > 0; day = day.add(1, 'day')) {
      if (dayjs(day).isBusinessDay()) {
        calendar[day.year().toString()][day.dayOfYear()].push(task)
      }
    }
  })
  return calendar
}

export default ctx => ({
  generateTasks,
  generateTaskCalendar,
  generateTaskCalendarWorkpsace
})
