<template lang="html">
  <v-app>
    <v-main data-layout="subscribe">
      <FormErrorDialog />
      <DisableSmallDevices />
      <nuxt />
    </v-main>
  </v-app>
</template>

<script lang="js">

export default {
  name: 'SubscriptionLayout',
  auth: false,
  middleware: ['configuration', 'workspace'],
  head () {
    return {
      title: this.$nuxt.$te(`headTitle.${this.$nuxt.$route.name}`) ? this.$nuxt.$t(`headTitle.${this.$nuxt.$route.name}`) : 'edtake'
    }
  }
}
</script>
