/* eslint-disable no-console */
import _ from 'lodash'

const moduleSchema = {
  _id: String,
  type: String,
  children: Array,
  deleted: Boolean,
  name: String,
  status: String,
  objectives: Array,
  themes: Array,
  translations: Array,
  archived: Boolean,
  tags: Array,
  workspace: String,
  version: Number,
  restrictAccess: String,
  createdAt: String,
  updatedAt: String,
  __t: String,
  members: Array,
  __v: Number,
  id: String
}

export const state = () => ({
  modules: []
})

export const getters = {
  get: (state, getters, rootState, rootGetters) => {
    const wsId = rootGetters['workspace/getCurrentId']
    return wsId ? _.filter(state.modules, (m) => { return m.workspace === wsId }) : state.modules
  },
  getOne (state) {
    return (id) => { return state.modules.find(module => module._id === id) }
  },
  getMany: state => (ids) => {
    const res = []
    _.forEach(ids, (id) => {
      const o = state.modules.find(module => module._id === id)
      if (o) {
        res.push(o)
      }
    })
    return res
  },
  // Store refactor - linkedCourses not declared, may be deprecated, check and remove
  getModuleLinkedCourses: state => (id) => {
    const mod = _.find(state.linkedCourses, (m) => { return m.moduleId === id })
    return mod ? _.filter(mod.objectCourses, (m) => { return m !== 'mono-module' }) : []
  },
  getComposition: state => (id) => {
    const o = state.modules.find(module => module._id === id)
    return o ? o.children : undefined
  },
  resourceCountChildren: (state, getters) => (id) => {
    const module = getters.getOne(id)
    return module && module.children ? module.children.length : undefined
  }
}

export const actions = {
  clear ({ commit }) {
    commit('CLEAR')
  },
  fetch ({ dispatch, commit }, { $queryParameters = {} } = { $queryParameters: {} }) {
    return new Promise((resolve, reject) => {
      this.$edtake.module.get({ $queryParameters })
        .then((d) => {
          commit('SET', d)
          resolve(d)
        })
        .catch((err) => {
          console.error('ERROR', err)
          reject(err)
        })
    })
  },
  fetchOne ({ dispatch, commit }, { id, $queryParameters = {} }) {
    return new Promise((resolve, reject) => {
      this.$edtake.module.getOne({ id, $queryParameters })
        .then((d) => {
          commit('UPDATE_ONE', d)
          if (d.projects) {
            dispatch('project/_update', d.projects, { root: true })
          }
          if (d.deliverables) {
            dispatch('deliverable/_update', d.deliverables, { root: true })
          }
          if (d.tasks) {
            dispatch('task/_update', d.tasks, { root: true })
          }
          resolve(d)
        })
        .catch((err) => {
          console.error('ERROR', err)
          reject(err)
        })
    })
  },
  fetchRecommendation ({ commit }, { id, recommendationType }) {
    return new Promise((resolve, reject) => {
      this.$edtake.module.getRecommendations({ id, recommendationType })
        .then((d) => {
          resolve(d)
        })
        .catch((err) => {
          console.error('ERROR', err)
          reject(err)
        })
    })
  },
  remove ({ commit }, { id }) {
    return new Promise((resolve, reject) => {
      this.$edtake.module.delete({ id })
        .then((d) => {
          commit('REMOVE', id)
          resolve()
        })
        .catch((err) => {
          if (err.response.status === 403) {
            reject('NOT_AUTHORIZED')
          }
          reject(err)
        })
    })
  },
  create ({ dispatch, commit, rootGetters }, { body }) {
    const workspace = rootGetters['workspace/getCurrentId']
    body.workspace = workspace
    return new Promise((resolve, reject) => {
      this.$edtake.module.create({
        body
      }).then((d) => {
        commit('CREATE', d)
        this.$evt.log('LearningObjectCreation', { type: 'module' })
        resolve(d)
      })
        .catch((err) => {
          if (err.response && err.response.data === this.$enums.httpErrorBodies.upgrade_license) {
            reject('license.moduleOutOfCredit')
          }
          console.error('ERROR', err)
          reject(err)
        })
    })
  },
  update ({ dispatch, commit }, { id, body }) {
    return new Promise((resolve, reject) => {
      this.$edtake.module.update({
        id,
        body
      })
        .then((d) => {
          commit('UPDATE_ONE', d)
          resolve(d)
        })
        .catch((err) => {
          console.error('ERROR', err)
          reject(err)
        })
    })
  },
  updateLmsData ({ dispatch, commit }, { id, body }) {
    return new Promise((resolve, reject) => {
      this.$edtake.module.updateLmsData({
        id,
        body
      })
        .then((d) => {
          commit('UPDATE_ONE', d)
          resolve(d)
        })
        .catch((err) => {
          console.error('ERROR', err)
          reject(err)
        })
    })
  },
  clearLmsData ({ dispatch, commit }, { id }) {
    return new Promise((resolve, reject) => {
      this.$edtake.module.clearLmsData({
        id
      })
        .then((d) => {
          commit('UPDATE_ONE', d)
          resolve(d)
        })
        .catch((err) => {
          console.error('ERROR', err)
          reject(err)
        })
    })
  },
  lmsDataStatus ({ dispatch, commit }, { id }) {
    return new Promise((resolve, reject) => {
      this.$edtake.module.lmsDataStatus({
        id
      })
        .then((d) => {
          resolve(d)
        })
        .catch((err) => {
          console.error('ERROR', err)
          reject(err)
        })
    })
  },
  updateAuthoringToolData ({ dispatch, commit }, { id, body }) {
    return new Promise((resolve, reject) => {
      this.$edtake.module.updateAuthoringToolData({
        id,
        body
      })
        .then((d) => {
          commit('UPDATE_ONE', d)
          resolve(d)
        })
        .catch((err) => {
          console.error('ERROR', err)
          reject(err)
        })
    })
  },
  clearAuthoringToolData ({ dispatch, commit }, { id }) {
    return new Promise((resolve, reject) => {
      this.$edtake.module.clearAuthoringToolData({
        id
      })
        .then((d) => {
          commit('UPDATE_ONE', d)
          resolve(d)
        })
        .catch((err) => {
          console.error('ERROR', err)
          reject(err)
        })
    })
  },
  fetchChildren ({ commit }, { id }) {
    return new Promise((resolve, reject) => {
      this.$edtake.module.getChildren({ id, $queryParameters: { populateChildren: true } })
        .then((d) => {
          commit('SET_CHILDREN', { id, children: d })
          commit('sequence/UPDATE_MANY', d.map(link => link.child), { root: true })
          resolve(d)
        })
        .catch((err) => {
          console.error('ERROR', err)
          reject(err)
        })
    })
  },
  archive ({ commit }, { id }) {
    return new Promise((resolve, reject) => {
      this.$edtake.module.archive({ id })
        .then((d) => {
          commit('UPDATE_ONE', d)
          resolve(d)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  setStatus ({ commit }, { id, status }) {
    return new Promise((resolve, reject) => {
      this.$edtake.module.setStatus({
        id,
        body: { status }
      })
        .then((d) => {
          commit('UPDATE_ONE', d)
          resolve(d)
        })
        .catch((err) => {
          console.error('ERROR', err)
          reject(err)
        })
    })
  },
  addSequence ({ commit }, { id, body, duplicate }) {
    return new Promise((resolve, reject) => {
      this.$edtake.module.addSequence({
        id,
        body,
        $queryParameters: { duplicate }
      })
        .then((d) => {
          commit('UPDATE_ONE', d.obj)
          resolve(d)
        })
        .catch((err) => {
          console.error('ERROR', err)
          reject(err)
        })
    })
  },
  removeSequence ({ commit }, { id, body }) {
    return new Promise((resolve, reject) => {
      this.$edtake.module.removeSequence({
        id,
        body
      })
        .then((d) => {
          commit('UPDATE_ONE', d)
          resolve(d)
        })
        .catch((err) => {
          console.error('ERROR', err)
          reject(err)
        })
    })
  },
  setMembers ({ commit }, d) {
    commit('SET_MEMBERS', d)
  },
  _setChildren ({ commit }, { id, children }) {
    commit('SET_CHILDREN', { id, children })
  },
  /* Dispatch from other store */
  _addDocument ({ commit }, d) {
    commit('ADD_DOCUMENT', d)
  },
  _removeDocument ({ commit }, d) {
    commit('REMOVE_DOCUMENT', d)
  },
  _updateDocument ({ commit }, d) {
    commit('UPDATE_DOCUMENT', d)
  },
  _addProject ({ commit }, d) {
    commit('ADD_PROJECT', d)
  },
  _removeProject ({ commit }, d) {
    commit('REMOVE_PROJECT', d)
  },
  _update ({ commit }, d) {
    commit('UPDATE_ONE', d)
  }
}

export const mutations = {
  SET (state, value) {
    state.modules = value
  },
  SET_MEMBERS (state, value) {
    _.forEach(value.ids, (id, $index) => {
      const item = state.modules.find(item => item._id.toString() === id.toString())
      if (item) {
        Object.assign(item, { ...item, members: value.newObj[$index].members })
        state.modules = [...state.modules]
      }
    })
  },
  SET_CHILDREN (state, { id, children }) {
    const item = _.find(state.modules, module => module._id === id)
    item.children = children
    state.modules = [...state.modules]
  },
  UPDATE_ONE (state, value) {
    const item = state.modules.find(item => (item._id && item._id.toString()) === (value._id && value._id.toString()))
    if (item) {
      Object.assign(item, value)
      state.modules = [...state.modules] // Force refresh
    } else {
      state.modules = [...state.modules, value]
    }
  },
  UPDATE_MANY (state, arr) {
    const expectedIds = _.map(arr, o => o._id)
    const filteredState = _.filter(state.modules, o => !expectedIds.includes(o._id))
    arr = _.map(arr, (updatedPI) => {
      let item = state.modules.find(item => (item._id && item._id.toString()) === (updatedPI._id && updatedPI._id.toString()))
      if (item) {
        Object.assign(item, updatedPI)
      } else {
        item = updatedPI
      }
      return item
    })
    state.modules = [...filteredState, ...arr] // Force refresh
  },
  REMOVE (state, value) {
    state.modules = state.modules.filter(module => module._id !== value)
  },
  CREATE (state, value) {
    state.modules = [...state.modules, value]
  },
  CLEAR (state) {
    state.modules = []
  },
  ADD_DOCUMENT (state, value) {
    const item = state.modules.find(item => item._id.toString() === value.parent._id.toString())
    if (item) {
      item.documents = [...item.documents, value]
    }
  },
  UPDATE_DOCUMENT (state, value) {
    const item = state.modules.find((item) => {
      return item._id.toString() === value.parent._id.toString()
    })
    if (item) {
      const documentIdx = item.documents.findIndex(doc => doc._id === value._id)
      item.documents[documentIdx] = Object.assign({}, item.documents[documentIdx], value)
    }
  },
  REMOVE_DOCUMENT (state, value) {
    const item = state.modules.find(item => item._id.toString() === value.parent._id.toString())
    if (item) {
      item.documents = item.documents.filter(d => d._id !== value._id)
    }
  },
  ADD_PROJECT (state, value) {
    const item = state.modules.find(item => item._id.toString() === value.learningObject._id.toString())
    if (item) {
      item.projects = [...item.projects, value]
    }
  },
  REMOVE_PROJECT (state, value) {
    const item = state.modules.find(item => item._id.toString() === value.learningObject._id.toString())
    if (item) {
      item.projects = item.projects.filter(d => d._id !== value._id)
    }
  }
}
