<template>
  <div v-if="loading" class="object-container">
    <div
      v-for="(i) in loadingCardsNumber"
      :key="i"
    >
      <div>
        <v-skeleton-loader class="ml-2 mb-2" type="card" height="98"></v-skeleton-loader>
      </div>
    </div>
  </div>
  <div v-else class="w-100">
    <Picker
      :value="value"
      :type="type"
      :options="options"
      :multiple="multiple"
      :current-ressource="currentRessource"
      :from-mechanism="fromMechanism"
      @input="(e) => $emit('input', e)"
    />
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import Picker from '../Picker'

export default {
  components: {
    Picker
  },
  props: {
    value: {
      type: [String, Array, undefined],
      required: true,
      default: () => 'undefined'
    },
    type: {
      type: String,
      required: false,
      default: () => undefined
    },
    currentRessource: {
      type: Array,
      required: false,
      default: () => []
    },
    options: {
      type: Array,
      required: false
    },
    multiple: {
      type: Boolean,
      default: false
    },
    fromMechanism: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      loading: false
    }
  },
  async fetch () {
    this.loading = true
    if (this.type) {
      await this[`${this.type}sFetch`]()
    } else {
      const resourcesToFetch = [this.modulesFetch(), this.sequencesFetch()]
      if (this.$userRightsHelper.hasFeatureAccess('course')) {
        resourcesToFetch.push(this.coursesFetch())
      }
      if (!this.fromMechanism && this.$userRightsHelper.hasFeatureAccess('mechanism')) {
        resourcesToFetch.push(this.mechanismsFetch())
      }
      await Promise.all(resourcesToFetch)
    }
    this.loading = false
  },
  computed: {
    loadingCardsNumber () {
      return this.options ? this.options.length : 18
    }
  },
  methods: {
    ...mapActions({
      coursesFetch: 'course/fetch',
      modulesFetch: 'module/fetch',
      sequencesFetch: 'sequence/fetch',
      mechanismsFetch: 'mechanism/fetch'
    })
  }
}
</script>
<style lang="scss" scoped>
.object-container {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 6px;
}
</style>
