const i18n = {
  messages: {
    fr: {
      today: 'Aujourd\'hui',
      unscheduled: '--/--/----',
      required: 'Champs requis'
    },
    en: {
      today: 'Today',
      unscheduled: '--/--/----',
      required: 'Required field'
    }
  },
  dateTimeFormats: {
    fr: {
      month: {
        year: 'numeric',
        month: 'long'
      }
    },
    en: {
      month: {
        year: 'numeric',
        month: 'long'
      }
    }
  }
}
module.exports = i18n
