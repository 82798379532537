<template>
  <div>
    <edtk-button
      small
    >
      <label for="excel-file-input" class="cursor">
        {{ $t('import') }}
      </label>
      <input
        id="excel-file-input"
        class="d-none"
        type="file"
        accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
        @change="uploadFile"
      >
    </edtk-button>
    <v-dialog
      v-model="importDialog"
      persistent
      transition="dialog-bottom-transition"
    >
      <v-card class="ma-0 pa-0">
        <v-card-title class="pl-16">
          <h2 class="page-title ma-0 pa-0">
            {{ $t('title') }}
          </h2>
          <v-spacer></v-spacer>
          <edtk-button
            small
            icon="table"
            color="success"
            :label="$t('excelExportBtn')"
            @click="onexport"
          >
          </edtk-button>
          <edtk-button-icon
            icon="close"
            icon-size="3xl"
            class="ml-4"
            color="secondary"
            @click="cancelImportedRecap"
          >
          </edtk-button-icon>
        </v-card-title>
        <v-card-text class="pb-0">
          <v-simple-table fixed-header height="calc(90vh - 136px)" class="mt-4 mx-10">
            <template #default>
              <thead>
                <tr>
                  <th class="grey lighten-4 text-center" style="width: 15%">
                    {{ $t('headers.sceneTitle') }}
                  </th>
                  <th class="grey lighten-4 text-center" style="width: 10%">
                    {{ $t('actionType') }}
                  </th>
                  <th class="grey lighten-4 text-center" style="width: 15%">
                    {{ $t('headers.asset') }}
                  </th>
                  <th class="grey lighten-4 text-center" style="width: 30%">
                    {{ $t('headers.description') }}
                  </th>
                  <th class="grey lighten-4 text-center" style="width: 10%">
                    {{ $t('status') }}
                  </th>
                  <th class="grey lighten-4 text-center" style="width: 30%">
                    {{ $t('comment') }}
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(item, index) in importedRecap"
                  :key="index"
                >
                  <td class="text-center">
                    {{ item.sceneTitle }}
                  </td>
                  <td class="text-center">
                    {{ item.actionType }}
                  </td>
                  <td class="text-center">
                    {{ item.asset }}
                  </td>
                  <td>{{ item.description }}</td>
                  <td class="text-center">
                    <v-chip
                      style="width:100%;"
                      class="justify-center"
                      :color="item.status === 'Error' ? 'red': item.status === 'Modified' ? 'green' : 'blue' "
                      label
                      outlined
                    >
                      {{ $t(`${item.status}`) }}
                    </v-chip>
                  </td>
                  <td>{{ item.comment }}</td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-card-text>
        <v-card-actions class="d-flex justify-center">
          <edtk-button
            :label="$t('applyChanges')"
            @click="applyChangesFromImportedStoryboard"
          ></edtk-button>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import _ from 'lodash'
import XLSX from 'xlsx'

export default {
  props: {
    scenes: {
      type: Array,
      default: () => []
    },
    storyboardName: {
      type: String,
      default: () => ''
    }
  },
  data () {
    return {
      importDialog: false,
      importedRecap: []
    }
  },
  i18n: {
    messages: {
      fr: {
        import: 'Importer',
        title: 'Rapport d\'import de données',
        headers: {
          sceneTitle: 'Scène',
          asset: 'Élément',
          description: 'Description'
        },
        excelExportBtn: 'Exporter le rapport',
        actionType: 'Action',
        status: 'Statut',
        comment: 'Détail',
        applyChanges: 'Appliquer les modifications',
        Error: 'Erreur',
        Unchanged: 'Non modifié',
        Modified: 'Modifié',
        unknownSceneId: 'ID de la scène erroné: {element}',
        unknownActionId: 'ID de l\'action erroné: {element}',
        duplicateActionId: 'Duplication de l\'ID de l\'action: {element}, l\'ID de l\'action ne peut être dupliqué',
        wrongActionType: 'Type d\'action erroné',
        unknownAssetId: 'ID de l\'élément erroné: {element}',
        unknownOptionId: 'ID de la réponse erroné: {element}',
        invalidColumns: 'Le fichier n\'est pas conforme au modèle'
      },
      en: {
        import: 'Import',
        title: 'Data import report',
        headers: {
          sceneTitle: 'Scene',
          asset: 'Asset',
          description: 'Description'
        },
        excelExportBtn: 'Export report',
        actionType: 'Action',
        status: 'Status',
        comment: 'Comment',
        applyChanges: 'Apply changes',
        Error: 'Error',
        Unchanged: 'Unchanged',
        Modified: 'Modified',
        unknownSceneId: 'Unknown scene ID: {element}',
        unknownActionId: 'Unknown action ID: {element}',
        duplicateActionId: 'Duplicate action ID: {element}, action ID cannot be duplicated',
        wrongActionType: 'Wrong actionType',
        unknownAssetId: 'Unknown asset ID: {element}',
        unknownOptionId: 'Unknown response ID: {element}',
        invalidColumns: 'The file does not conform to the template'
      }
    }
  },
  methods: {
    checkImportedFile (payload) {
      const res = []
      const headers = ['sceneId', 'sceneTitle', 'actionId', 'actionType', 'assetId', 'asset', 'optionId', 'description']
      const payloadHeaders = this.$_.flatten(payload.map(h => Object.keys(h)))
      const checkInvalidHeaders = this.$_.difference(headers, this.$_.uniq(payloadHeaders))
      if (checkInvalidHeaders.length) {
        this.$swal({
          icon: 'error',
          title: this.$t('errors.OUPS'),
          html: this.$t('invalidColumns')
        })
        return
      }
      // check duplicates actionId
      const countByActionId = _.countBy(payload, (a) => { return a.actionId })

      payload.forEach((element) => {
        const scene = this.scenes.find(s => s._id === element.sceneId)
        // check if sceneid exists in storyboard
        if (!scene) {
          res.push({ ...element, status: 'Error', comment: this.$t('unknownSceneId', { element: `${element.sceneId}` }) })
          return
        }
        // check if actionid exists in scene
        const action = scene.script.find(a => a._id === element.actionId)
        if (!action) {
          res.push({ ...element, status: 'Error', comment: this.$t('unknownActionId', { element: `${element.actionId}` }) })
          return
        }
        // check if actionId is duplicated in scene
        if (countByActionId[element.actionId] > 1 && element.actionType !== 'question') {
          res.push({ ...element, status: 'Error', comment: this.$t('duplicateActionId', { element: `${element.actionId}` }) })
          return
        }
        // check if actionType has not been modified in action
        const type = _.isEqual(action.type, element.actionType)
        if (!type) {
          res.push({ ...element, status: 'Error', comment: this.$t('wrongActionType') })
          return
        }
        // check if assetId has not been modified in action
        const assetId = _.isEqual(action.actionData.assetId, element.assetId)
        if (element.actionType === 'dialogue' && !assetId) {
          res.push({ ...element, status: 'Error', comment: this.$t('unknownAssetId', { element: `${element.assetId}` }) })
          return
        }

        // check questions and options
        const optionsLength = action.actionData.options.length
        const option = action.actionData.options.find(o => o._id === element.optionId)
        if (element.actionType === 'question') {
          if (element.optionId && !option) {
            res.push({ ...element, status: 'Error', comment: this.$t('unknownOptionId', { element: `${element.optionId}` }) })
            return
          }
          if (countByActionId[element.actionId] > optionsLength + 1) {
            res.push({ ...element, status: 'Error', comment: this.$t('duplicateActionId', { element: `${element.actionId}` }) })
            return
          }

          if (!element.optionId && action.actionData.assetId && !assetId) {
            res.push({ ...element, status: 'Error', comment: this.$t('unknownAssetId', { element: `${element.assetId}` }) })
            return
          }
        }
        // Apply regex /\r?\n|\r/g to remove lineBreak before to compare existing desc with imported desc
        // because when the file is open in Excel, Excel add \r to each line break and distorts the comparison
        element.description = element.description.replace(/\r\n/g, '\n')
        element.description = element.description.replace(/\r/g, '\n')
        let description
        if (element.actionType === 'question' && element.optionId) {
          description = _.isEqual(option.label, element.description)
        } else {
          description = _.isEqual(action.actionData.description, element.description)
        }
        // check if description has been modified in action
        if (description) {
          res.push({ ...element, status: 'Unchanged', comment: '' })
        } else {
          res.push({ ...element, status: 'Modified', comment: '' })
        }
      })
      this.importedRecap = res
      this.importDialog = true
    },
    uploadFile (e) {
      const file = e.target.files[0]
      const reader = new FileReader()
      const self = this
      reader.onload = function async (e) {
        const data = new Uint8Array(e.target.result)
        const workbook = XLSX.read(data, { type: 'array' })
        const firstWorksheet = workbook.Sheets[workbook.SheetNames[0]]
        const resultdata = XLSX.utils.sheet_to_json(firstWorksheet)
        self.checkImportedFile(resultdata)
      }
      e.target.value = ''
      reader.readAsArrayBuffer(file)
    },
    applyChangesFromImportedStoryboard () {
      this.$emit('save', this.importedRecap)
      this.cancelImportedRecap()
    },
    cancelImportedRecap () {
      this.importDialog = false
      this.importedRecap = []
    },
    onexport () {
      // export json to Worksheet of Excel
      // only array possible
      const list = this.importedRecap.filter(a => a.status !== 'Unchanged')
      const worksheet = XLSX.utils.json_to_sheet(list)

      // A workbook is the name given to an Excel file
      const wb = XLSX.utils.book_new() // make Workbook of Excel

      // add Worksheet to Workbook
      // Workbook contains one or more worksheets
      XLSX.utils.book_append_sheet(wb, worksheet, 'Export')

      // export Excel file
      XLSX.writeFile(wb, `${this.storyboardName} - report.xlsx`) // name of the file
    }
  }
}
</script>
