const i18n = {
  messages: {
    fr: {
      startMeetingWithGlowbl: 'Démarrer une réunion'
    },
    en: {
      startMeetingWithGlowbl: 'Start meeting with'
    }
  }
}
module.exports = i18n
