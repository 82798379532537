// TODO: Store refactor - Should this be a store ? maybe should be in a plugin/lib

// Get paths of an object array
const paths = (root) => {
  const paths = []
  const nodes = [{
    obj: root,
    path: []
  }]
  while (nodes.length > 0) {
    const n = nodes.pop()
    if (n.obj !== undefined && n.obj !== null) {
      Object.keys(n.obj).forEach((k) => {
        if (k && k !== null && typeof n.obj[k] === 'object') {
          const path = n.path.concat(k)
          paths.push(path)
          nodes.unshift({
            obj: n.obj[k],
            path
          })
        }
      })
    }
  }
  return paths
}

export const state = () => ({
  errorList: []
})

export const getters = {
  getErrorList (state) {
    return state.errorList
  }
}

export const actions = {
  generateErrorList ({ commit, state }, { validationObject, translation, translationPath, usePopin }) {
    let errorList = []
    const checked = []
    const errorArray = []
    const path = paths(validationObject)
    const labelToTest = ['skill', 'skillLevel', 'skillExperience', 'start', 'end']
    // sort path how are not starting by $
    const res = path.filter(arr => !arr.some(str => /\$/.test(str) && !/\$each/.test(str)))
    const ret = []
    // link array path in string path
    this.$_.forEach(res, el => ret.push(el.join('.')))

    this.$_.forEach(ret, (el, i) => {
      // get only the last word, label
      // const label = el.replace(/.*(?<=\.)/, '') // => NOT ok on safari : https://caniuse.com/js-regexp-lookbehind
      const splited = el.split('.')
      const label = splited[splited.length - 1]

      if (this.$_.get(validationObject, ret[i]).required !== undefined && this.$_.get(validationObject, ret[i]).required === false && !checked.includes(el)) {
        checked.push(el)
        errorArray.push({
          errorType: 'REQUIRED',
          field: translation(`${translationPath}.${label}`)
        })
      }
      if (this.$_.get(validationObject, ret[i]).minLength !== undefined && this.$_.get(validationObject, ret[i]).minLength === false && !checked.includes(el)) {
        const nb = this.$_.get(validationObject, ret[i]).$params.minLength.min
        checked.push(el)
        errorArray.push({
          errorType: 'MIN_LENGTH',
          field: translation(`${translationPath}.${label}`),
          constraint: nb
        })
      }
      if (this.$_.get(validationObject, ret[i]).maxLength !== undefined && this.$_.get(validationObject, ret[i]).maxLength === false && !checked.includes(el)) {
        const nb = this.$_.get(validationObject, ret[i]).$params.maxLength.max
        checked.push(el)
        errorArray.push({
          errorType: 'MAX_LENGTH',
          field: translation(`${translationPath}.${label}`),
          constraint: nb
        })
      }
      if (this.$_.get(validationObject, ret[i]).sameAs !== undefined && this.$_.get(validationObject, ret[i]).sameAs === false && !checked.includes(el)) {
        const sameAs = this.$_.get(validationObject, ret[i]).$params.sameAs.eq
        checked.push(el)
        errorArray.push({
          errorType: 'SAMEAS',
          field: translation(`${translationPath}.${label}`),
          constraint: translation(`${translationPath}.${sameAs}`)
        })
      }
      if (this.$_.get(validationObject, ret[i]).isAfterStart !== undefined && this.$_.get(validationObject, ret[i]).isAfterStart === false && !checked.includes(el)) {
        checked.push(el)
        errorArray.push({
          errorType: 'IS_AFTER_START',
          field: translation(`${translationPath}.${label}`)
        })
      }
      if (this.$_.get(validationObject, ret[i]).isAfterFirstDraft !== undefined && this.$_.get(validationObject, ret[i]).isAfterFirstDraft === false && !checked.includes(el)) {
        checked.push(el)
        errorArray.push({
          errorType: 'IS_AFTER__DIRST_DRAFT',
          field: translation(`${translationPath}.${label}`)
        })
      }
      if (this.$_.get(validationObject, ret[i]).minValue !== undefined && this.$_.get(validationObject, ret[i]).minValue === false && !checked.includes(el)) {
        const nb = this.$_.get(validationObject, ret[i]).$params.minValue.min
        checked.push(el)
        errorArray.push({
          errorType: 'MIN_VALUE_LENGTH',
          field: translation(`${translationPath}.${label}`),
          constraint: nb
        })
      }
      if (this.$_.get(validationObject, ret[i]).maxValue !== undefined && this.$_.get(validationObject, ret[i]).maxValue === false && !checked.includes(el)) {
        const nb = this.$_.get(validationObject, ret[i]).$params.maxValue.max
        checked.push(el)
        errorArray.push({
          errorType: 'MAX_VALUE_LENGTH',
          field: translation(`${translationPath}.${label}`),
          constraint: nb
        })
      }
      if (this.$_.get(validationObject, ret[i]).url !== undefined && this.$_.get(validationObject, ret[i]).url === false && !checked.includes(el)) {
        checked.push(el)
        errorArray.push({
          errorType: 'URL',
          field: translation(`${translationPath}.${label}`)
        })
      }
      if (this.$_.get(validationObject, ret[i]).email !== undefined && this.$_.get(validationObject, ret[i]).email === false && !checked.includes(el)) {
        checked.push(el)
        errorArray.push({
          errorType: 'EMAIL',
          field: translation(`${translationPath}.${label}`)
        })
      }
      if (this.$_.get(validationObject, ret[i]).mustBeTrue !== undefined && this.$_.get(validationObject, ret[i]).mustBeTrue === false && !checked.includes(el)) {
        checked.push(el)
        errorArray.push({
          errorType: 'MUST_BE_TRUE',
          field: translation(`${translationPath}.${label}`)
        })
      }
      if (this.$_.get(validationObject, ret[i]).$each !== undefined && this.$_.get(validationObject, ret[i]).$invalid && !checked.includes(el)) {
        this.$_.forEach(this.$_.get(validationObject, ret[i]).$each.$iter, (el, i) => {
          this.$_.forEach(labelToTest, (it) => {
            if (el[it] !== undefined && el[it].required === false && !checked.includes(it)) {
              errorArray.push({
                errorType: 'REQUIRED',
                field: translation(`${translationPath}.${it}`)
              })
              checked.push(it)
            }
          })
        })
      }
    })
    if (validationObject.email !== undefined && !validationObject.email.email && !checked.includes('email')) {
      if (!validationObject.email.$email && !checked.includes('email')) {
        checked.push('email')
        errorArray.push({
          errorType: 'EMAIL',
          field: 'email'
        })
      }
    }

    errorList = this.$_.map(errorArray, (e) => {
      return (translation(`errors.formError.errorMessage.${e.errorType}`, e))
    })
    errorList = this.$_.uniq(errorList)

    if (usePopin === true) {
      commit('SET_ERROR_LIST', errorList)
    }
  }
}

export const mutations = {
  SET_ERROR_LIST (state, errorList) {
    state.errorList = errorList
  },
  EMPTY_ERROR_LIST (state, emptyList) {
    state.errorList = emptyList
  }
}
