<template>
  <section class="rich-editor">
    <slot v-if="!editRight" name="default" :component-data="value">
      <p v-html="value"></p>
    </slot>
    <template v-else>
      <div v-if="!editMode" class="hover-highlight" @click="editMode = true">
        <slot name="default" :component-data="value">
          <p v-if="value && value.length > 0" v-html="value"></p>
          <p v-else>
            {{ placeholder }}
          </p>
        </slot>
      </div>
      <div :id="`quill-container-${objectId}`">
        <quill-editor
          v-show="editMode"
          ref="quillEditor"
          :content="mutableModel"
          :options="editorOption"
          class="hover-highlight active"
          @change="updateValue($event)"
        />
      </div>
    </template>
    <span
      v-if="$v.mutableModel.$error || $v.text.$error"
      class="form_error"
    >
      {{ $t('errors.input.INVALID') }} - {{ attrsInformation }}
    </span>
  </section>
</template>

<script>
import Vue from 'vue'
import VueQuillEditor, { Quill } from 'vue-quill-editor'
import { required } from 'vuelidate/lib/validators'
import i18n from './i18n.js'
import 'quill/dist/quill.core.css' // import styles
import 'quill/dist/quill.snow.css' // for snow theme
import 'quill/dist/quill.bubble.css' // for bubble theme
import QuillPlainText from '~/plugins/quill-plainText.js'
Quill.register('modules/clipboard', QuillPlainText, true) // skip blur on paste
Vue.use(VueQuillEditor /* { default global options } */)

export default {
  name: 'EditableRichEditor',
  props: {
    // eslint-disable-next-line vue/prop-name-casing
    'data-test': {
      type: String
    },
    editRight: {
      type: Boolean,
      required: true,
      default: false
    },
    value: {
      type: String,
      default: () => undefined
    },
    placeholder: {
      type: String,
      default: () => ''
    },
    objectId: {
      type: String,
      default: () => ''
    },
    customValidation: {
      type: Object,
      default: () => {}
    }
  },
  validations () {
    return {
      mutableModel: {
        // Adding all custom validations to the mutableModel
        ...this.customValidation ? Object.keys(this.customValidation.controls).map((key) => { return this.customValidation.controls[key] }) : {}
      },
      text: {
        required: this.$attrs.required || this.$attrs.required === '' ? required : {}
      }
    }
  },
  data () {
    return {
      editorOption: {
        theme: 'bubble',
        modules: {
          toolbar: [
            ['bold', 'italic', 'underline', 'strike'],
            ['link'],
            [{ list: 'ordered' }, { list: 'bullet' }],
            [{ header: [1, 2, 3, 4, 5, 6, false] }],
            [{ color: [] }, { background: [] }]
          ]
        },
        bounds: `#quill-container-${this.objectId}`,
        placeholder: this.placeholder
      },
      editMode: false,
      mutableModel: this.value,
      text: ''
    }
  },
  i18n,
  computed: {
    attrsInformation () {
      const errorMessage = []
      if ((this.$attrs.required || this.$attrs.required === '') && !this.$v.text.required) {
        errorMessage.push(`${this.$t('required')}`)
      }
      Object.keys(this.customValidation ? this.customValidation.controls : {}).forEach((key) => {
        if (!this.$v.mutableModel[key]) {
          errorMessage.push(this.customValidation.errors[key])
        }
      })

      return errorMessage.join(' - ')
    }
  },
  watch: {
    value (n, o) {
      this.mutableModel = this.value
      this.text = this.value
    },
    editMode (n) {
      if (n === true) {
        setTimeout(function () {
          this.focus()
        }.bind(this), 150)
      }
    }
  },
  beforeMount () {
    this.mutableModel = this.value
    this.text = this.value
  },
  mounted () {
    if (this.$refs.quillEditor) {
      this.$refs.quillEditor.quill.getModule('toolbar').container.addEventListener('mousedown', (e) => {
        e.preventDefault()
      })
      // Force quill to blur when clicking on buttons
      this.$refs.quillEditor.$el.children[0].children[0].onblur = () => {
        this.save()
      }
    }
  },
  beforeDestroy () {
    if (this.$refs.quillEditor) {
      this.$refs.quillEditor.quill.getModule('toolbar').container.removeEventListener('mousedown', (e) => {
        e.preventDefault()
      })
    }
  },
  methods: {
    save () {
      this.editMode = false
      if (this.value !== this.mutableModel) {
        if (!this.$v.mutableModel.$error && !this.$v.text.$invalid) {
          this.$emit('input', this.mutableModel)
        }
      }
    },
    updateValue ({ quill, html, text }) {
      this.text = text
      this.mutableModel = html
      this.$v.mutableModel.$touch()
      this.$v.text.$touch()
    },
    focus () {
      this.$nextTick(() => this.$refs.quillEditor.$el.children[0].children[0].focus())
    }
  }
}
</script>
<style lang="scss" scoped>
.hover-highlight {
  display: block;
  border-bottom: dashed transparent 2px;
  border-radius: 0;
  &:hover {
    cursor: text;
    border-bottom: dashed $blue 2px;
  }
  &.active {
    border-bottom: solid $blue 1px;
    margin-bottom: 5px;
  }
}
.rich-editor :deep(p) {
  margin-bottom: 0 !important;
}
</style>
