const i18n = {
  messages: {
    fr: {
      closeWithoutSave: 'Fermer sans sauvegarder ?',
      close: 'Fermer',
      saveAndClose: 'Sauvegarder et fermer',
      edit: 'Editer'
    },
    en: {
      closeWithoutSave: 'Close without saving ?',
      close: 'Close',
      saveAndClose: 'Save and close',
      edit: 'Edit'
    }
  }
}
module.exports = i18n
