const i18n = {
  messages: {
    fr: {
      name: 'Nom du modèle',
      description: 'Description du modèle',
      category: 'Catégorie',
      overwriteTemplate: 'Ecraser un modèle existant',
      title: 'Enregistrer comme modèle',
      recalculateEstimation: 'Recalculer les estimations des livrables',
      keepMembers: 'Garder les members',
      keepMilestones: 'Garder les jalons',
      alert: {
        successCreateTemplate: 'Ajouté à votre espace de travail'
      },
      formLabel: {
        templateId: 'Modèle'
      }
    },
    en: {
      title: 'Save as template',
      name: 'Template name',
      description: 'Template description',
      category: 'Categorie',
      overwriteTemplate: 'Overwrite existing template',
      recalculateEstimation: 'Recalculate deliverables estimations',
      keepMembers: 'Keep members',
      keepMilestones: 'Keep miletones',
      alert: {
        successCreateTemplate: 'Added to workspace'
      },
      formLabel: {
        templateId: 'Template'
      }
    }
  }
}
module.exports = i18n
