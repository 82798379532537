const i18n = {
  messages: {
    fr: {
      required: 'Champ requis',
      search: 'Rechercher',
      add: 'Ajouter',
      searchOrAdd: 'Rechercher ou ajouter',
      actionsList: {
        dialogue: 'Dialogue',
        interaction: 'Activité'
      },
      assetType: {
        character: 'Personnage',
        background: 'Environnement',
        object: 'Objet',
        other: 'Autre'
      },
      noOptions: 'Veuillez renseigner ce champ dans la configuration du workspace',
      noData: 'Aucune donnée'
    },
    en: {
      required: 'Required field',
      search: 'Search',
      add: 'Add',
      searchOrAdd: 'Search or add',
      actionsList: {
        dialogue: 'Dialogue',
        interaction: 'Activity'
      },
      assetType: {
        character: 'Character',
        background: 'Background',
        object: 'Object',
        other: 'Other'
      },
      noOptions: 'Please fill in this field in the workspace configuration',
      noData: 'No data'
    }
  }
}
module.exports = i18n
