<template>
  <div ref="visualization" :class="[toExport ? 'hide-container' : 'height-container']"></div>
</template>

<script>
import { Network } from 'vis-network'

export default {
  name: 'Network',
  props: {
    toExport: {
      type: Boolean,
      default: false
    },
    pdfType: {
      type: String,
      default: () => undefined
    },
    scenes: {
      type: Array,
      default: () => []
    },
    events: {
      type: Array,
      default: () => [
        'click',
        'doubleClick',
        'oncontext',
        'hold',
        'release',
        'select',
        'selectNode',
        'selectEdge',
        'deselectNode',
        'deselectEdge',
        'dragStart',
        'dragging',
        'dragEnd',
        'hoverNode',
        'blurNode',
        'hoverEdge',
        'blurEdge',
        'zoom',
        'showPopup',
        'hidePopup',
        'startStabilizing',
        'stabilizationProgress',
        'stabilizationIterationsDone',
        'stabilized',
        'resize',
        'initRedraw',
        'beforeDrawing',
        'afterDrawing',
        'animationFinished',
        'configChange'
      ]
    }
  },
  data: () => ({
    visData: {
      nodes: null,
      edges: null
    },
    options: {
      interaction: {
        zoomView: false,
        dragView: false
      },
      nodes: {
        shape: 'box',
        shapeProperties: {
          borderRadius: 2
        },
        size: 10,
        borderWidth: 0.5,
        borderWidthSelected: 2,
        color: {
          border: '#00548A',
          background: 'white',
          highlight: {
            border: '#00548A',
            background: 'white'
          }
        },
        widthConstraint: { maximum: 120 },
        labelHighlightBold: false,
        margin: 12,
        font: {
          color: '#00548A',
          align: 'center',
          size: 12
        }
      }
    }
  }),
  created () {
    // This should be a Vue data property, but Vue reactivity kinda bugs Vis.
    // See here for more: https://github.com/almende/vis/issues/2524
    this.network = null
  },
  mounted () {
    const container = this.$refs.visualization
    this.visData.nodes = this.getNodes()
    this.visData.edges = this.getEdges()
    const options = { ...this.options }
    if (this.toExport) {
      if (this.pdfType === 'landscape') {
        options.width = '1122' // 1122 px = 297mm
        options.height = '794' // 794px = 210mm
      } else {
        options.width = '794' // 794px = 210mm
        options.height = '1122' // 1122 px = 297mm
      }
    }
    this.network = new Network(container, this.visData, options)
    this.events.forEach((eventName) => {
      this.network.on(eventName, (props) => {
        this.$emit(eventName, props)
      })
    })
    if (this.toExport) {
      this.network.on('afterDrawing', (ctx) => {
        const dataURL = ctx.canvas.toDataURL()
        this.$emit('getDiagramImage', { pdfType: this.pdfType, data: dataURL })
      })
    }
  },
  beforeDestroy () {
    this.network.destroy()
  },
  methods: {
    getNodes () {
      let y = 0
      const nodes = []
      this.scenes.forEach((scene) => {
        nodes.push({
          id: scene.order,
          label: scene.properties.title,
          x: 0,
          y,
          fixed: true
        })
        y += 100
      })
      return nodes
    },
    getEdges () {
      const edges = []
      this.scenes.forEach((scene) => {
        edges.push({
          from: scene.order,
          to: scene.order + 1,
          arrows: 'to',
          smooth: { type: 'vertical' }
        })
        const questions = scene.script.filter(line => line.type === 'question')
        questions.forEach((line) => {
          line.actionData.options.forEach((option) => {
            if (option.goToScene) {
              const sceneToGo = this.scenes.find(
                scene => scene._id === option.goToScene
              )
              if (sceneToGo.order !== scene.order + 1) {
                edges.push({
                  from: scene.order,
                  to: sceneToGo.order,
                  arrows: 'to',
                  smooth: { type: 'curvedCW', roundness: 0.7 }
                })
              }
            }
          })
        })
      })
      return this.$_.uniqWith(edges, this.$_.isEqual)
    },
    destroy () {
      this.network.destroy()
    },
    getNode (id) {
      return this.visData.nodes.get(id)
    },
    getEdge (id) {
      return this.visData.edges.get(id)
    },
    setOptions (options) {
      this.network.setOptions(options)
    },
    on (event, callback) {
      this.network.on(event, callback)
    },
    off (event, callback) {
      this.network.off(event, callback)
    },
    once (event, callback) {
      this.network.once(event, callback)
    },
    canvasToDom (p) {
      return this.network.canvasToDOM(p)
    },
    domToCanvas (p) {
      return this.network.DOMtoCanvas(p)
    },
    redraw () {
      this.network.redraw()
    },
    setSize (w, h) {
      this.network.setSize(w, h)
    },
    cluster (options) {
      this.network.cluster(options)
    },
    clusterByConnection (nodeId, options) {
      this.network.clusterByConnection(nodeId, options)
    },
    clusterByHubsize (hubsize, options) {
      this.network.clusterByHubsize(hubsize, options)
    },
    clusterOutliers (options) {
      this.network.clusterOutliers(options)
    },
    findNode (id) {
      return this.network.findNode(id)
    },
    getClusteredEdges (baseEdgeId) {
      return this.network.clustering.getClusteredEdges(baseEdgeId)
    },
    getBaseEdge (clusteredEdgeId) {
      return this.network.clustering.getBaseEdge(clusteredEdgeId)
    },
    getBaseEdges (clusteredEdgeId) {
      return this.network.clustering.getBaseEdges(clusteredEdgeId)
    },
    updateEdge (startEdgeId, options) {
      this.network.clustering.updateEdge(startEdgeId, options)
    },
    updateClusteredNode (clusteredNodeId, options) {
      this.network.clustering.updateClusteredNode(clusteredNodeId, options)
    },
    isCluster (nodeId) {
      return this.network.isCluster(nodeId)
    },
    getNodesInCluster (clusterNodeId) {
      return this.network.getNodesInCluster(clusterNodeId)
    },
    openCluster (nodeId, options) {
      this.network.openCluster(nodeId, options)
    },
    getSeed () {
      return this.network.getSeed()
    },
    enableEditMode () {
      this.network.enableEditMode()
    },
    disableEditMode () {
      this.network.disableEditMode()
    },
    addNodeMode () {
      this.network.addNodeMode()
    },
    editNode () {
      this.network.editNode()
    },
    addEdgeMode () {
      this.network.addEdgeMode()
    },
    editEdgeMode () {
      this.network.editEdgeMode()
    },
    deleteSelected () {
      this.network.deleteSelected()
    },
    getPositions (nodeIds) {
      return this.network.getPositions(nodeIds)
    },
    storePositions () {
      this.network.storePositions()
    },
    moveNode (nodeId, x, y) {
      this.network.moveNode(nodeId, x, y)
    },
    getBoundingBox (nodeId) {
      return this.network.getBoundingBox(nodeId)
    },
    getConnectedNodes (nodeId, direction) {
      return this.network.getConnectedNodes(nodeId, direction)
    },
    getConnectedEdges (nodeId) {
      return this.network.getConnectedEdges(nodeId)
    },
    startSimulation () {
      this.network.startSimulation()
    },
    stopSimulation () {
      this.network.stopSimulation()
    },
    stabilize (iterations) {
      this.network.stabilize(iterations)
    },
    getSelection () {
      return this.network.getSelection()
    },
    getSelectedNodes () {
      return this.network.getSelectedNodes()
    },
    getSelectedEdges () {
      return this.network.getSelectedEdges()
    },
    getNodeAt (p) {
      return this.network.getNodeAt(p)
    },
    getEdgeAt (p) {
      return this.network.getEdgeAt(p)
    },
    selectNodes (nodeIds, highlightEdges) {
      this.network.selectNodes(nodeIds, highlightEdges)
    },
    selectEdges (edgeIds) {
      this.network.selectEdges(edgeIds)
    },
    setSelection (selection, options) {
      this.network.setSelection(selection, options)
    },
    unselectAll () {
      this.network.unselectAll()
    },
    getScale () {
      return this.network.getScale()
    },
    getViewPosition () {
      return this.network.getViewPosition()
    },
    fit (options) {
      this.network.fit(options)
    },
    focus (nodeId, options) {
      this.network.focus(nodeId, options)
    },
    moveTo (options) {
      this.network.moveTo(options)
    },
    releaseNode () {
      this.network.releaseNode()
    },
    getOptionsFromConfigurator () {
      return this.network.getOptionsFromConfigurator()
    }
  }
}
</script>

<style scoped>
  .hide-container {
    height: 0;
    width: 0;
    overflow: hidden;
  }
  .height-container {
    height: 100%
  }
</style>
