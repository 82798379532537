import Quill from 'quill'

const Embed = Quill.import('blots/embed')

class ProcLink extends Embed {
  static create (value) {
    const node = super.create(value)
    // give it some margin
    node.setAttribute('style', 'text-decoration: underline;background-color : lightgreen;')
    console.log(value.value)
    node.setAttribute('data-proc', value.value)
    node.setAttribute('type', 'button')
    node.setAttribute('onclick', `location.href='${value.url}';`)

    node.innerHTML = value.text
    return node
  }
}

ProcLink.blotName = 'proc-link'
ProcLink.className = 'proc-link'
ProcLink.tagName = 'button'

export default ProcLink
