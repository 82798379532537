<template>
  <v-card class="bg-white-smoke ma-0 pa-0" min-height="100vh">
    <v-toolbar flat class="px-3">
      <img
        src="~/assets/img/edtake-icon-fill-rich-document.svg"
        alt="rich editor"
        style="max-width: 40px; max-height: 40px;"
        class="mr-5"
      />
      <v-toolbar-title>
        <EditableField
          v-model="dataMutable.name"
          type="text"
          class="h2 page-title ma-0 py-0"
          :placeholder="$t('title')"
          :edit-right="true"
          position="auto"
          large
          @input="$emit('updateDocumentTitle', dataMutable.name)"
        />
      </v-toolbar-title>
      <edtk-button-icon
        class="ml-auto"
        icon="close"
        icon-size="3xl"
        color="secondary"
        @click="close()"
      >
      </edtk-button-icon>
    </v-toolbar>
    <QuillRichEditor ref="quillRichEditor" :data.sync="dataMutable" :upload-picture="uploadPicture" :save-rich-editor="saveRichEditor" class="quillRichEditor" />
  </v-card>
</template>
<script>
import i18n from './i18n.js'

import QuillRichEditor from '~/components/edtakeDocument/quillEditor'

export default {
  name: 'RichEditor',
  components: {
    QuillRichEditor
  },
  props: {
    data: {
      type: Object,
      default: () => {}
    },
    uploadPicture: {
      type: Function,
      default: () => () => {}
    },
    saveRichEditor: {
      type: Function,
      default: () => () => {}
    }
  },
  i18n,
  data () {
    return {
      dataMutable: this.data ? Object.assign({}, this.data) : undefined
    }
  },
  computed: {},
  watch: {
    data: {
      deep: true,
      handler (n) {
        this.dataMutable = (n && typeof n === 'object') ? Object.assign({}, n) : undefined
      }
    }
  },
  async beforeDestroy () {
    await this.$refs.quillRichEditor.isChanges()
  },
  methods: {
    async close () {
      await this.$refs.quillRichEditor.isChanges()
      this.$emit('close')
    }
  }
}
</script>
<style css scoped>
.quillRichEditor {
  width: 100%;
}
</style>
