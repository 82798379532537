<template>
  <section>
    <span class="stepper-title">
      {{ $t('step', {current, max}) }}
    </span>
    <div class="steps mt-4 d-flex justify-space-between">
      <div v-for="(step, index) in max" :key="index" class="step" :class="{'filled': current >= index+1}"></div>
    </div>
  </section>
</template>

<script>
import i18n from './i18n'
export default {
  props: {
    current: {
      type: Number,
      required: true
    },
    max: {
      type: Number,
      required: true
    }
  },
  i18n,
  data () {
    return {}
  }
}
</script>
<style lang="scss" scoped>
.stepper-title {
  height: 20px;
  font-size: 14px;
  font-family: $default-font !important;
}
.steps {
  gap: 8px;
  height: 4px;
  width: 100%;
}
.step {
  width: 100%;
  border-radius: 4px;
  background: linear-gradient(to left, $gray-light 50%, $blue 50%) right;
  /* 1px error with 200px */
  background-size: 201%;
  transition: .4s ease-out;

  &.filled {
    background-position: left;
  }
}
</style>
