<template>
  <section class="editable" @click="onClick()">
    <slot name="default">
      <v-icon
        :class="iconStyle"
      >
        {{ mutableModel ? 'mdi-checkbox-marked-circle-outline' : 'mdi-checkbox-blank-circle-outline' }}
      </v-icon>
    </slot>
    <span
      v-if="$v.mutableModel.$error"
      class="form_error"
    >
      {{ $t('errors.input.INVALID') }} - {{ attrsInformation }}
    </span>
  </section>
</template>
<script>
import { required } from 'vuelidate/lib/validators'

export default {
  props: {
    editRight: {
      type: Boolean,
      required: true,
      default: false
    },
    autoChange: {
      type: Boolean,
      default: true
    },
    value: {
      type: Boolean,
      required: true
    },
    customValidation: {
      type: Object,
      default: () => {}
    },
    checkedColor: {
      type: String,
      default: 'green'
    }
  },
  validations () {
    return {
      mutableModel: {
        ...this.customValidation ? Object.keys(this.customValidation.controls).map((key) => { return this.customValidation.controls[key] }) : {},
        required: this.$attrs.required || this.$attrs.required === '' ? required : {}
      }
    }
  },
  data () {
    return {
      mutableModel: this.value
    }
  },
  computed: {
    attrsInformation () {
      const errorMessage = []
      if ((this.$attrs.required || this.$attrs.required === '') && !this.$v.mutableModel.required) {
        errorMessage.push(`${this.$t('required')}`)
      }
      Object.keys(this.customValidation ? this.customValidation.controls : {}).forEach((key) => {
        if (!this.$v.mutableModel[key]) {
          errorMessage.push(this.customValidation.errors[key])
        }
      })
      return errorMessage.join(' - ')
    },
    iconStyle () {
      const style = {
        pointer: this.editRight,
        'brownish-grey--text': !this.mutableModel
      }
      style[`${this.checkedColor}--text`] = !!this.mutableModel
      return style
    }
  },
  watch: {
    value (n) {
      this.mutableModel = n
    },
    mutableModel (n) {
      this.$emit('change', n)
    }
  },
  methods: {
    onClick () {
      this.$emit('click')
      if (this.editRight) {
        this.mutableModel = !this.mutableModel
        this.$v.mutableModel.$touch()
        if (!this.$v.mutableModel.$error) { this.$emit('input', this.mutableModel) }
        if (!this.autoChange) {
          this.mutableModel = !this.mutableModel
        }
      }
    }
  }
}
</script>
