<template>
  <div class="d-flex flex-column pa-0">
    <div class="labelBox">
      <v-icon v-if="icon" size="14" color="primary">
        {{ icon }}
      </v-icon>
      <span class="labelTitle">
        {{ label }}
      </span>
    </div>
    <EditableField
      :type="fieldType"
      :value="value"
      :min="minValue"
      :max="maxValue"
      :options="options"
      :placeholder="placeholder"
      :edit-right="editRight"
      parent-container
      clearable
      @input="(e) => $emit('input', e)"
    />
  </div>
</template>

<script>
export default {
  props: {
    icon: {
      type: String,
      required: false
    },
    label: {
      type: String,
      required: false
    },
    minValue: {
      type: [String, Date, Number],
      required: false
    },
    maxValue: {
      type: [String, Date, Number],
      required: false
    },
    fieldType: {
      type: String,
      required: false
    },
    value: {
      type: [String, Array, Number, undefined],
      required: false
    },
    options: {
      type: Array,
      required: false
    },
    placeholder: {
      type: String,
      default: () => ''
    },
    editRight: {
      type: Boolean,
      default: true
    }
  },
  data () {
    return {}
  }
}
</script>
<style scoped>
.labelTitle {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #00558A;
}
.labelBox {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;
  gap: 8px;
  margin-bottom: 8px;;
}
</style>
