export default function (userJwt, userSession) {
  return {
    host: 'SOCKET_HOST',
    options: {
      transports: ['websocket'],
      withCredentials: true,
      query: userJwt ? `token=${userJwt}&session=${userSession}` : undefined
    },
    vuex: {
      actions: [
        { event: 'updateWorkspace', store: 'workspace/updateWithSocket' },
        { event: 'updateRemoteConfig', store: 'remoteConfig/updateWithSocket' },
        { event: 'sessionKicked', store: 'user/disconnectedByOtherUser' },
        { event: 'updateAlert', store: 'alert/wsUpdate' }
      ],
      mutations: []
    }
  }
}
