/* eslint-disable no-empty-pattern */
export const state = () => ({
  edtakeDocuments: []
})

export const getters = {
  get (state) {
    return state.edtakeDocuments
  }
}

export const actions = {
  fetch ({ commit, rootGetters }, { $queryParameters = {} } = { $queryParameters: {} }) {
    const workspace = rootGetters['workspace/getCurrentId']
    return new Promise((resolve, reject) => {
      this.$edtake.edtakeDocument.get({ $queryParameters: { workspace } })
        .then((d) => {
          commit('SET_EDTAKE_DOCUMENTS', d)
          resolve(d)
        })
        .catch((err) => {
          console.error('ERROR', err)
          reject(err)
        })
    })
  },
  fetchOne ({ commit }, { id, $queryParameters = {} }) {
    return new Promise((resolve, reject) => {
      this.$edtake.edtakeDocument.getOne({ id, $queryParameters })
        .then((d) => {
          commit('SET_EDTAKE_DOCUMENT', d)
          resolve(d)
        })
        .catch((err) => {
          console.error('ERROR', err)
          reject(err)
        })
    })
  },
  clear ({ commit }) {
    commit('CLEAR')
  },
  update ({ commit, dispatch }, { id, body, type = '' }) {
    return new Promise((resolve, reject) => {
      this.$edtake.edtakeDocument.update({
        id,
        body,
        $queryParameters: { type }
      })
        .then((d) => {
          commit('SET_EDTAKE_DOCUMENT', d)
          if (d) {
            if (d.parentType === 'learningObject' || d.parentType === 'projectItem') {
              dispatch(`${d.parent.__t}/_updateDocument`, d, { root: true })
            } else if (d.parentType === 'mission') {
              dispatch('mission/_updateDocument', d, { root: true })
            }
          }
          resolve(d)
        })
        .catch((err) => {
          console.error('ERROR', err)
          reject(err)
        })
    })
  },
  updateContentData ({ commit }, { id, body, type = '' }) {
    return new Promise((resolve, reject) => {
      this.$edtake.edtakeDocument.updateContentData({
        id,
        body,
        $queryParameters: { type }
      })
        .then((d) => {
          commit('SET_EDTAKE_DOCUMENT', d)
          resolve(d)
        })
        .catch((err) => {
          console.error('ERROR', err)
          reject(err)
        })
    })
  },
  remove ({ commit, dispatch }, { id }) {
    return new Promise((resolve, reject) => {
      this.$edtake.edtakeDocument.delete({
        id
      }).then((d) => {
        commit('REMOVE_EDTAKE_DOCUMENT', id)
        if (d) {
          if (d.parentType === 'learningObject' || d.parentType === 'projectItem') {
            dispatch(`${d.parent.__t}/_removeDocument`, d, { root: true })
          } else if (d.parentType === 'mission') {
            dispatch('mission/_removeDocument', d, { root: true })
          }
        }
        resolve(d)
      })
        .catch((err) => {
          console.error('ERROR', err)
          reject(err)
        })
    })
  },
  create ({ rootGetters, commit, dispatch }, { body }) {
    const workspace = rootGetters['workspace/getCurrentId']
    body.workspace = workspace
    return new Promise((resolve, reject) => {
      this.$edtake.edtakeDocument.create({
        body
      }).then((d) => {
        commit('CREATE_EDTAKE_DOCUMENT', d)
        if (d) {
          if (d.parentType === 'learningObject' || d.parentType === 'projectItem') {
            dispatch(`${d.parent.__t}/_addDocument`, d, { root: true })
          } else if (d.parentType === 'mission') {
            dispatch('mission/_addDocument', d, { root: true })
          }
        }
        resolve(d)
      })
        .catch((err) => {
          console.error('ERROR', err)
          reject(err)
        })
    })
  },
  toTemplate ({ dispatch }, { id, body }) {
    return new Promise((resolve, reject) => {
      this.$edtake.edtakeDocument.toTemplate({
        id,
        body
      }).then((d) => {
        dispatch('template/_update', d, { root: true })
        dispatch('fetchOne', { id })
        resolve(d)
      }).catch((err) => {
        console.error('ERROR', err)
        reject(err)
      })
    })
  },
  createFromTemplate ({ commit }, { body, id }) {
    return new Promise((resolve, reject) => {
      this.$edtake.edtakeDocument.createFromTemplate({
        id,
        body
      }).then((d) => {
        commit('CREATE_EDTAKE_DOCUMENT', d)
        resolve(d)
      })
        .catch((err) => {
          console.error('ERROR', err)
          reject(err)
        })
    })
  },
  /* ACTIONS SERVICES */
  async uploadImage ({ }, item) {
    const form = new FormData()
    form.append('file', item.image, item.image.name)
    const config = {
      headers: {
        'content-type': 'multipart/form-data'
      }
    }
    const response = await this.$edtake.uploadCDN.imageUpload({ item, type: item.type, id: item.id, timestamp: true, form, $config: config })
    if (response) {
      return response.url
    }
    return undefined
  },
  async uploadDoc ({ }, item) {
    const form = new FormData()
    form.append('file', item.file.file, item.file.file.name)
    const config = {
      headers: {
        'content-type': 'multipart/form-data'
      }
    }
    const response = await this.$edtake.uploadCDN.workspaceUpload({ item, type: item.type, id: item.id, form, $config: config })
    if (response) {
      return response.url
    }
    return undefined
  }
}

// TODO: Store refactor - rename to new norm
export const mutations = {
  SET_EDTAKE_DOCUMENTS (state, value) {
    state.edtakeDocuments = value
  },
  SET_EDTAKE_DOCUMENT (state, value) {
    const edtakeDocument = state.edtakeDocuments.find(edtakeDocument => edtakeDocument._id.toString() === value._id.toString())
    // reassign entire object because array is not reactive when updating its content
    if (edtakeDocument) {
      Object.assign(edtakeDocument, value)
      state.edtakeDocuments = [...state.edtakeDocuments]
    } else {
      state.edtakeDocuments = [...state.edtakeDocuments, value]
    }
  },
  REMOVE_EDTAKE_DOCUMENT (state, value) {
    state.edtakeDocuments = state.edtakeDocuments.filter(edtakeDocument => edtakeDocument._id !== value)
  },
  CREATE_EDTAKE_DOCUMENT (state, value) {
    state.edtakeDocuments = [...state.edtakeDocuments, value]
  },
  CLEAR (state, value) {
    state.edtakeDocuments = []
  }
}
