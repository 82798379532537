const i18n = {
  messages: {
    fr: {
      objectType: {
        projectItem: 'Projets',
        learningObject: 'Catalogue',
        mission: 'Missions',
        project: 'Projets',
        deliverable: 'Livrables',
        task: 'Tâches',
        course: 'Parcours',
        module: 'Modules',
        sequence: 'Séquences'
      },
      tooltip: {
        link: 'Copier l\'URL',
        openInNew: 'Ouvrir dans un nouvel onglet'
      },
      copiedToClipboard: 'Copié dans le presse-papier!'
    },
    en: {
      objectType: {
        projectItem: 'Projects management',
        learningObject: 'Learning objects',
        mission: 'Missions',
        project: 'Projects',
        deliverable: 'Deliverables',
        task: 'Tasks',
        course: 'Courses',
        module: 'Modules',
        sequence: 'Sequences'
      },
      tooltip: {
        link: 'Copy URL',
        openInNew: 'Open in a new tab'
      },
      copiedToClipboard: 'Copied to clipboard!'
    }
  }
}
module.exports = i18n
