import {
  required,
  requiredIf,
  numeric,
  decimal,
  minValue,
  maxValue,
  minLength,
  email
} from 'vuelidate/lib/validators'
const mustBeTrue = value => value === true
const validations = () => {
  return {
    deliverables: {
      required: requiredIf(function () {
        return this.mission.type === 'DELIVERABLE'
      }),
      minLength: minLength(1)
    },
    deliverableAnswers: {
      required: requiredIf(function () {
        return !this.project && this.deliverables.length && this.mission.type === 'DELIVERABLE' && this.professionRequested !== 'Other'
      }),
      $each: {
        data: {
          required: requiredIf(function (e) {
            return e.required
          })
        }
      }
    },
    primarySkill: {
      skill: {
        required: requiredIf(function () {
          return this.mission.type === 'TIME'
        })
      },
      skillExperience: {
        required: requiredIf(function () {
          return this.mission.type === 'TIME'
        })
      }
    },
    mission: {
      title: {
        required: requiredIf(function () {
          return !this.iframeDisplay
        })
      },
      briefing: { required },
      type: { required },
      location: {
        required: requiredIf(function () {
          return this.mission.type === 'TIME'
        })
      },
      startDate: {
        required: requiredIf(function () {
          return this.mission.type === 'TIME'
        })
      },
      endDate: { required },
      budget: {
        required: requiredIf(function () {
          return !this.mission.isBudgetFromEdtaker
        }),
        numeric,
        minValue: value => value !== null ? minValue(0) : true
      },
      numberOfRevisionsByDeliverable: {
        required: requiredIf(function () {
          return this.mission.type === 'DELIVERABLE'
        }),
        numeric,
        minValue: minValue(1)
      },
      duration: {
        required: requiredIf(function () {
          return this.mission.type === 'TIME'
        }),
        decimal,
        minValue: minValue(0.5),
        maxValue () {
          return maxValue(this.businessDay)
        }
      }
    },
    workspace: {
      type: { required },
      company: {
        name: { required },
        employeesNumber: { required }
      }
    },
    user: {
      firstname: { required },
      lastname: { required },
      job: { required },
      email: { required, email },
      acceptedCgu: { mustBeTrue }
    },
    step2: [
      'deliverables',
      'deliverableAnswers',
      'primarySkill.skill',
      'primarySkill.skillExperience',
      'mission.startDate',
      'mission.endDate',
      'mission.location',
      'mission.duration',
      'mission.numberOfRevisionsByDeliverable'
    ],
    step3: [
      'mission.title',
      'mission.briefing',
      'mission.budget'
    ],
    step4: [
      'workspace.type',
      'workspace.company.name',
      'workspace.company.employeesNumber',
      'user.firstname',
      'user.lastname',
      'user.job',
      'user.email',
      'user.acceptedCgu'
    ]
  }
}

export default validations
