<template>
  <v-popover
    :disabled="!editRight"
    trigger="manual"
    :open="isOpen"
    :placement="position"
    :container="containerData"
    class="d-flex"
    @hide="close"
  >
    <section
      :class="{ pointer: editRight }"
      :data-test="dataTest"
      @click="isOpen =! isOpen"
    >
      <slot v-if="!editRight || !isOpen" name="default" :componentData="mutableModel">
        <div v-if="mutableModel" :style="`width: ${size}px; height: ${size}px; border-radius: 50%; background: ${mutableModel}`"></div>
        <div v-else>
          <v-icon small>
            mdi-pencil
          </v-icon>
        </div>
      </slot>
      <div v-else>
        <div v-if="mutableModel" :style="`width: ${size}px; height: ${size}px; border-radius: 50%; background: ${mutableModel}`"></div>
        <div v-else>
          <v-icon small>
            mdi-pencil
          </v-icon>
        </div>
      </div>
    </section>
    <span
      v-if="$v.mutableModel.$error"
      class="form_error"
    >
      {{ $t('errors.input.INVALID') }} - {{ attrsInformation }}
    </span>
    <template slot="popover">
      <section>
        <h5 v-if="title" class="blue--text text-center pt-2 pb-1">
          {{ title }}
        </h5>
        <v-color-picker
          :value="mutableModel"
          @update:color="updateValue"
        ></v-color-picker>
        <div class="text-center">
        <edtkButton
          :label="$t('confirm')"
          type="filled"
          @click="confirmValue"
        />
        </div>
      </section>
    </template>
  </v-popover>
</template>

<script>
import { required } from 'vuelidate/lib/validators'
import i18n from './i18n.js'
export default {
  name: 'EditableColor',
  props: {
    dataTest: {
      type: String
    },
    editRight: {
      type: Boolean,
      required: true,
      default: false
    },
    value: {
      type: String,
      default: () => undefined
    },
    containerRef: {
      type: HTMLElement
    },
    parentContainer: {
      type: Boolean,
      default: () => false
    },
    position: {
      type: String,
      default: () => 'auto-start'
    },
    title: {
      type: String,
      default: () => ''
    },
    size: {
      type: [String, Number],
      default: 30
    },
    customValidation: {
      type: Object,
      default: () => {}
    }
  },
  validations () {
    return {
      mutableModel: {
        ...this.customValidation ? Object.keys(this.customValidation.controls).map((key) => { return this.customValidation.controls[key] }) : {},
        required: this.$attrs.required || this.$attrs.required === '' ? required : {}
      }
    }
  },
  data () {
    return {
      isOpen: false,
      containerData: undefined,
      res: undefined,
      mutableModel: this.value
    }
  },
  computed: {
    attrsInformation () {
      const errorMessage = []
      if ((this.$attrs.required || this.$attrs.required === '') && !this.$v.mutableModel.required) {
        errorMessage.push(`${this.$t('required')}`)
      }
      Object.keys(this.customValidation ? this.customValidation.controls : {}).forEach((key) => {
        if (!this.$v.mutableModel[key]) {
          errorMessage.push(this.customValidation.errors[key])
        }
      })
      return errorMessage.join(' - ')
    }
  },
  watch: {
    value (n) {
      this.mutableModel = n
    },
    containerRef (n) {
      this.containerData = n
    }
  },
  mounted () {
    if (this.parentContainer) {
      this.containerData = this.$parent.$el
    }
  },
  i18n,
  methods: {
    updateValue (e) {
      this.res = e.hex
      this.$v.mutableModel.$touch()
    },
    confirmValue () {
      this.mutableModel = this.res
      if (!this.$v.mutableModel.$error) { this.$emit('input', this.res) }
      this.close()
    },
    close () {
      this.$v.mutableModel.$touch()
      this.isOpen = false
    }
  }
}
</script>
