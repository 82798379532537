const i18n = {
  messages: {
    fr: {
      save: 'Enregistrer',
      newLine: 'Ajouter un thème',
      main: 'Principal',
      second: 'Secondaire | Secondaire | Secondaires',
      noData: 'Aucun élément'
    },
    en: {
      save: 'Save',
      newLine: 'New theme',
      main: 'Main',
      second: 'Secondary | Secondary | Secondary',
      noData: 'No data'
    }
  }
}
module.exports = i18n
