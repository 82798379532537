var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('CanvasToolbar',{ref:"toolbar",attrs:{"type-of-shape-to-edit":_vm.typeOfShapeToEdit,"selected-shape":_vm.selectedShape},on:{"addShape":_vm.addShape,"openAssetEditor":_vm.openAssetEditor,"deleteShape":_vm.deleteShape,"updateShape":_vm.updateShape,"expandLibrary":_vm.expandLibrary,"stageZoomIn":_vm.stageZoomIn,"stageZoomOut":_vm.stageZoomOut,"moveUp":_vm.moveUp,"moveDown":_vm.moveDown,"increaseStrokeWidth":_vm.increaseStrokeWidth,"decreaseStrokeWidth":_vm.decreaseStrokeWidth,"save":_vm.save,"closeStage":_vm.closeStage}}),_vm._v(" "),_c('v-row',{class:[_vm.elementToEdit.type === 'template' ? 'layout-template' : 'layout-scene'],attrs:{"id":"stage-parent"}},[_c('Library',{attrs:{"expand":_vm.expand,"storyboard":_vm.storyboard,"type-to-edit":_vm.elementToEdit.type,"scene-to-edit":_vm.sceneToEdit,"new-order":_vm.newOrder},on:{"addImage":_vm.addImage,"expandLibrary":_vm.expandLibrary,"applyTemplate":_vm.applyTemplate}}),_vm._v(" "),_c('v-stage',{ref:"stage",staticClass:"elevation-6 mx-auto my-auto",style:({transform: 'scale('+ _vm.zoom +')'}),attrs:{"id":"stage","config":_vm.configKonva},on:{"mousedown":_vm.handleStageMouseDown,"touchstart":_vm.handleStageMouseDown,"dblclick":_vm.handleDisplayTextarea}},[_c('v-layer',{ref:"layer"},[_vm._l((_vm.shapesListToDisplay),function(item,$index){return _c('VDraw',{key:item.config.name,attrs:{"item":_vm.shapesListToDisplay[$index],"catalog":_vm.storyboard.catalog},on:{"editShapePosition":_vm.editShapePosition,"transformShape":_vm.transformShape}})}),_vm._v(" "),(_vm.typeOfShapeToEdit !== 'text')?_c('v-transformer',{ref:"transformer",attrs:{"config":{ignoreStroke:true}}}):_vm._e(),_vm._v(" "),(_vm.typeOfShapeToEdit === 'text')?_c('v-transformer',{ref:"transformer",attrs:{"config":{
            enabledAnchors: ['middle-left', 'middle-right'],
            boundBoxFunc: (oldBox, newBox) => {
              newBox.width = Math.max(100, newBox.width)
              return newBox
            }
          }}}):_vm._e()],2)],1)],1),_vm._v(" "),_c('AssetEditor',{ref:"assetEditor",attrs:{"catalog":_vm.storyboard.catalog,"update-catalog":_vm.updateCatalog},on:{"saveAsset":_vm.saveNewAsset}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }