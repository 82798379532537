import { orderBy } from 'lodash'

export const state = () => ({
  marketplace: {},
  projectManagement: {},
  learningDesign: {},
  lastUpdate: undefined
})

export const getters = {
  /* MARKETPLACE */
  getSettingsMarketplace (state) {
    return state.marketplace
  },
  getPublic: (state) => {
    return state.marketplace.public
  },
  getNumberOfRevisionsByDeliverable (state) {
    return state.marketplace.numberOfRevisionsByDeliverable
  },
  getMissionDefaultReviewDuration (state) {
    return state.marketplace.missionDefaultReviewDuration
  },
  /* LEARNING DESIGN */
  getSettingsLearningDesign (state) {
    return state.learningDesign
  },
  getSettingsModuleType: (state) => {
    return state.learningDesign.moduleTypes
  },
  getSettingsSequenceType: (state) => {
    return state.learningDesign.sequenceTypes
  },
  getSettingsLearningActivityType: (state) => {
    return state.learningDesign.learningActivityTypes
  },
  getSettingsAuthoringTools: (state) => {
    return state.learningDesign.authoringTools
  },
  getSettingsTaxonomy: (state) => {
    return state.learningDesign.taxonomy
  },
  getSettingsLms: (state) => {
    return state.learningDesign.lms
  },
  useAssistedInstructionalDesign (state) {
    return state.learningDesign.useAssistedInstructionalDesign
  },
  getSettingsObjectivesQuantity: state => (type) => {
    if (!['course', 'module', 'sequence'].includes(type)) {
      return undefined
    }
    switch (type) {
      case 'course':
        return state.learningDesign.coursesObjectivesQuantity
      case 'module':
        return state.learningDesign.modulesObjectivesQuantity
      case 'sequence':
        return state.learningDesign.sequencesObjectivesQuantity
    }
  },
  getSettingsMainObjectiveDisabled: state => (type) => {
    if (!['course', 'module', 'sequence'].includes(type)) {
      return undefined
    }
    switch (type) {
      case 'course':
        return state.learningDesign.coursesMainObjectiveManagementDisabled
      case 'module':
        return state.learningDesign.modulesMainObjectiveManagementDisabled
      case 'sequence':
        return state.learningDesign.sequencesMainObjectiveManagementDisabled
    }
  },
  /* PROJECT MANAGEMENT */
  getSettingsProjectManagement (state) {
    return state.projectManagement
  },
  getCurrentStatusBase: state => (type) => {
    let statuses
    switch (type) {
      case 'deliverable':
        statuses = state.projectManagement.deliverableStatuses
        break
      case 'project':
        statuses = state.projectManagement.projectStatuses
        break
      case 'task':
        statuses = {
          options: [
            {
              label: 'to do',
              color: '#707070',
              raci: ['accountable', 'responsible'],
              next: ['in progress']
            },
            {
              label: 'in progress',
              color: '#00558a',
              raci: ['accountable', 'responsible'],
              next: ['done', 'to do']
            },
            {
              label: 'done',
              color: '#35b840',
              raci: ['accountable', 'responsible'],
              next: ['in progress']
            }
          ]
        }
        break
      default:
        statuses = {
          options: [
            {
              label: 'cadrage',
              raci: ['responsible', 'accountable']
            },
            {
              label: 'conception',
              raci: ['responsible', 'accountable']
            },
            {
              label: 'production',
              raci: ['responsible', 'accountable']
            }, {
              label: 'validation',
              raci: ['responsible', 'accountable']
            }, {
              label: 'closure',
              raci: ['responsible', 'accountable']
            },
            {
              label: 'archive',
              raci: ['responsible', 'accountable']
            }
          ]
        }
        break
    }
    return statuses
  },
  getCurrentProjectStatusDoneLabels: (state, getters) => {
    const statuses = getters.getCurrentStatusBase('project')
    return statuses.options.reduce((acc, s) => s.isDone ? [...acc, s.label] : acc, [])
  },
  getCurrentDeliverableStatusDoneLabels: (state, getters) => {
    const statuses = getters.getCurrentStatusBase('deliverable')
    return statuses.options.reduce((acc, s) => s.isDone ? [...acc, s.label] : acc, [])
  },
  getCurrentStatus: (state, getters) => (type, label) => {
    const statuses = getters.getCurrentStatusBase(type)
    return label ? statuses.options.find(x => x.label.toLowerCase() === label.toLowerCase()) || undefined : statuses.options
  },
  getCurrentOrderedStatus: (state, getters) => (type) => {
    const statuses = getters.getCurrentStatusBase(type)
    return orderBy(statuses.options, (o) => { return o.order })
  },
  getCurrentStatusLabelByType: (state, getters) => (type) => {
    const statuses = getters.getCurrentStatusBase(type)
    return statuses.options.map(x => x.label) || undefined
  },
  getCurrentStatusByType: (state, getters) => (type) => {
    const statuses = getters.getCurrentStatusBase(type)
    return statuses.options || undefined
  },
  getAllStatus: state => () => {
    return [...state.projectManagement.projectStatuses.options, ...state.projectManagement.deliverableStatuses.options]
  },
  getCurrentStageByType: state => (type, status) => {
    let statuses
    switch (type) {
      case 'deliverable':
        statuses = state.projectManagement.deliverableStatuses.options.find(o => o.label === status)
        break
      case 'project':
        statuses = state.projectManagement.projectStatuses.options.find(o => o.label === status)
        break
      default:
        statuses = {}
        break
    }
    return statuses || undefined
  },
  getCurrentStatusProgressCalculModeByType: state => (type) => {
    let statuses
    switch (type) {
      case 'deliverable':
        statuses = state.projectManagement.deliverableStatuses
        break
      case 'project':
        statuses = state.projectManagement.projectStatuses
        break
      default:
        statuses = { progressCalculMode: '' }
        break
    }
    return statuses.progressCalculMode || undefined
  },
  allowResponsibleToEditProject (state) {
    return state.projectManagement.allowResponsibleToEditProject
  }
}

export const actions = {
  async fetchAll ({ dispatch, state }) {
    // TODO : Gérer le refresh par la socket #EDTK-75
    if (!state.marketplace || !state.projectManagement || !state.learningDesign || !state.lastUpdate || this.$dayjs().diff(state.lastUpdate, 'minute') >= 1) {
      await Promise.all([dispatch('fetch', 'marketplace'),
        dispatch('fetch', 'projectManagement'),
        dispatch('fetch', 'learningDesign')])
    }
  },
  fetch ({ commit }, type) {
    return new Promise((resolve, reject) => {
      this.$edtake.settings.get({ type })
        .then((d) => {
          commit('SET_SETTINGS', { type, value: d })
          resolve(d)
        })
        .catch((err) => {
          console.log('ERROR', err)
          reject(err)
        })
    })
  },
  update ({ commit }, { type, body }) {
    return new Promise((resolve, reject) => {
      this.$edtake.settings.update({ type, body }).then((d) => {
        commit('SET_SETTINGS', { type, value: d })
        resolve(d)
      })
        .catch((err) => {
          reject(err)
        })
    })
  },
  /* MARKETPLACE */
  /* LEARNING DESIGN */
  /* PROJECT MANAGEMENT */
  updateSettingStatuses ({ commit }, { body }) {
    return new Promise((resolve, reject) => {
      this.$edtake.settings.updateSettingStatuses({ body }).then((d) => {
        commit('SET_SETTINGS', { type: 'projectManagement', value: d })
        resolve(d)
      }).catch((err) => {
        reject(err)
      })
    })
  },
  applySettings ({ commit }, payload) {
    payload.forEach((p) => {
      commit('SET_SETTINGS', { type: p.__t, value: p })
    })
  }
}

export const mutations = {
  SET_SETTINGS (state, { type, value }) {
    state[type] = value
    state.lastUpdate = this.$dayjs()
  }
}
