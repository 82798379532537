<template>
  <v-row style="height: max-content;" class="ma-0">
    <v-col
      v-for="item in missionOptions"
      :key="item.id"
      cols="12"
      md="6"
      class="py-0 mb-4 px-2"
    >
      <v-hover v-slot="{ hover }" :disabled="deliverableDisabled && item.id === 'DELIVERABLE'">
        <v-sheet
          outlined
          :class="[
            deliverableDisabled && item.id === 'DELIVERABLE' ? '' : 'pointer',
            hover || missionType === item.id ? 'sheetBorderActive' : 'sheetBorder'
          ]"
          class="pa-5"
          style="position: relative"
          height="100%"
          @click="updateMissionType(item.id)"
        >
          <div class="mb-2">
            <span class="itemTitle">{{ item.name }}</span>
          </div>
          <p class="mb-0 itemDescription">
            {{ item.description }}
          </p>
          <v-icon
            style="position: absolute; top: 10px; right: 10px;"
            small
            color="primary"
          >
            {{ missionType === item.id ? 'mdi-check' : '' }}
          </v-icon>
          <v-overlay
            absolute
            color="#FFFFFF"
            opacity="0.5"
            :value="deliverableDisabled && item.id === 'DELIVERABLE'"
          >
          </v-overlay>
        </v-sheet>
      </v-hover>
      <div v-if="deliverableDisabled && item.id === 'DELIVERABLE'" class="mt-2 itemDescription">
        {{ project ? $t("otherDeliverableNoData") : $t("noDeliverableOption") }}
      </div>
    </v-col>
  </v-row>
</template>

<script>
import i18n from '../i18n'
export default {
  props: {
    deliverableDisabled: {
      type: Boolean
    },
    missionType: {
      type: String,
      default: () => ''
    },
    project: {
      type: Object,
      required: false,
      default: () => undefined
    }
  },
  i18n,
  data () {
    return {
      missionOptions: [
        { id: 'DELIVERABLE', name: this.$t('missionTypes.DELIVERABLE'), description: this.$t('mission.types.DELIVERABLE_DESC') },
        { id: 'TIME', name: this.$t('missionTypes.TIME'), description: this.$t('mission.types.TIME_DESC') }
      ]
    }
  },
  methods: {
    updateMissionType (type) {
      this.$emit('updateMissionType', type)
    }
  }
}
</script>

<style scoped>
  .sheetBorder {
    border: 1px solid rgba(226, 226, 229, 1);
    border-radius: 4px;
  }
  .sheetBorderActive {
    border: 1px solid #00558A;
    border-radius: 4px;
  }
  .itemTitle {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: #1A1C1E;
  }
  .itemDescription {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: #76777A;
  }
</style>
