<template>
  <v-toolbar
    dense
    absolute
    width="100%"
    outlined
    elevation="0"
  >
    <v-tooltip bottom>
      <template #activator="{ on, attrs }">
        <span
          v-bind="attrs"
          v-on="on"
        >
          <edtk-button-icon
            icon="local_library"
            icon-fill
            icon-size="4xl"
            color="black"
            @click="expandLibrary"
          >
          </edtk-button-icon>
        </span>
      </template>
      <span>{{ $t('library') }}</span>
    </v-tooltip>
    <v-divider vertical />
    <v-menu
      transition="scale-transition"
      origin="center center"
    >
      <template #activator="{ on: menu, attrs }">
        <v-tooltip bottom>
          <template #activator="{ on: tooltip }">
            <span
              v-bind="attrs"
              v-on="{...tooltip, ...menu}"
            >
              <edtk-button-icon
                data-test="button-scene-image-square"
                icon="shapes"
                icon-size="4xl"
                color="black"
              >
              </edtk-button-icon>
            </span>
          </template>
          <span>{{ $t('tooltip.addShape') }} </span>
        </v-tooltip>
      </template>
      <v-list>
        <v-list-item
          v-for="shape in shapes"
          :key="shape.name"
          @click="addShape(shape.name)"
        >
          <v-list-item-title>
            <v-icon>{{ shape.icon }}</v-icon>
          </v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>

    <v-divider vertical />
    <v-tooltip bottom>
      <template #activator="{ on, attrs }">
        <span
          v-bind="attrs"
          v-on="on"
        >
          <edtk-button-icon
            icon="image"
            icon-size="4xl"
            color="black"
            @click="openAssetEditor"
          >
          </edtk-button-icon>
        </span>
      </template>
      <span>{{ $t('tooltip.createNewAsset') }}</span>
    </v-tooltip>

    <v-divider vertical />

    <v-tooltip bottom>
      <template #activator="{ on, attrs }">
        <span
          v-bind="attrs"
          v-on="on"
        >
          <edtk-button-icon
            data-test="button-add-text-Scene-click"
            icon="match_case"
            icon-size="5xl"
            color="black"
            @click="addShape('text')"
          >
          </edtk-button-icon>
        </span>
      </template>
      <span>{{ $t('tooltip.addText') }}</span>
    </v-tooltip>
    <v-divider vertical />
    <v-tooltip bottom>
      <template #activator="{ on, attrs }">
        <span
          v-bind="attrs"
          v-on="on"
        >
          <edtk-button-icon
            data-test="button-add-text-Scene-click"
            icon="flip_to_front"
            icon-size="4xl"
            color="black"
            @click="moveUp"
          >
          </edtk-button-icon>
        </span>
      </template>
      <span>{{ $t('tooltip.bringForward') }}</span>
    </v-tooltip>
    <v-divider vertical />
    <v-tooltip bottom>
      <template #activator="{ on, attrs }">
        <span
          v-bind="attrs"
          v-on="on"
        >
          <edtk-button-icon
            data-test="button-add-text-Scene-click"
            icon="flip_to_back"
            icon-size="4xl"
            color="black"
            @click="moveDown"
          >
          </edtk-button-icon>
        </span>
      </template>
      <span>{{ $t('tooltip.sendBackward') }}</span>
    </v-tooltip>
    <v-divider vertical />
    <v-tooltip bottom>
      <template #activator="{ on, attrs }">
        <span
          v-bind="attrs"
          v-on="on"
        >
          <edtk-button-icon
            data-test="button-add-text-Scene-click"
            icon="delete"
            icon-size="4xl"
            color="black"
            @click="deleteShape"
          >
          </edtk-button-icon>
        </span>
      </template>
      <span>{{ $t('tooltip.delete') }}</span>
    </v-tooltip>
    <!-- FONT FAMILY, STYLE & COLOR -->
    <v-divider vertical />
    <div v-show="typeOfShapeToEdit === 'text'" class="pa-2">
      <v-select
        class="selectFontFamily"
        :items="dropdown_font"
        :value="selectedShape.config.fontFamily"
        solo
        dense
        flat
        hide-details
        @input="updateShape($event, 'fontFamily', 'font')"
      />
    </div>
    <v-divider v-show="typeOfShapeToEdit === 'text'" vertical />
    <div v-show="typeOfShapeToEdit === 'text'" class="pa-2">
      <v-select
        class="selectFontSize"
        :items="dropdown_edit"
        :value="selectedShape.config.fontSize"
        solo
        dense
        flat
        hide-details
        @input="updateShape($event, 'fontSize', 'font')"
      />
    </div>
    <v-divider
      v-show="typeOfShapeToEdit === 'text'"
      vertical
    />
    <v-tooltip bottom>
      <template #activator="{ on, attrs }">
        <span
          v-bind="attrs"
          v-on="on"
        >
          <edtk-button
            v-show="typeOfShapeToEdit === 'text'"
            type="text"
            color="black"
          >
            <label for="fontColor" class="d-flex flex-column">
              <span class="material-symbols-rounded" :style="{color: selectedShape.config.fill || '#000000'}">format_color_text</span>
              <input
                id="fontColor"
                type="color"
                name="fontColor"
                :value="selectedShape.config.fill || '#000000'"
                @input="updateShape($event, 'fontColor', 'color')"
              >
            </label>
          </edtk-button>
        </span>
      </template>
      <span>{{ $t('tooltip.fontColor') }}</span>
    </v-tooltip>
    <v-btn-toggle
      v-if="typeOfShapeToEdit === 'text'"
      dense
      group
      exclusive
      :value="selectedShape.config.fontStyle"
      @change="updateShape($event, 'fontStyle', 'font')"
    >
      <v-divider vertical />
      <v-tooltip bottom>
        <template #activator="{ on, attrs }">
          <v-btn v-bind="attrs" :value="'bold'" v-on="on">
            <v-icon>mdi-format-bold</v-icon>
          </v-btn>
        </template>
        <span>{{ $t('fontStyleTooltip.bold') }}</span>
      </v-tooltip>
      <v-tooltip bottom>
        <template #activator="{ on, attrs }">
          <v-btn v-bind="attrs" :value="'italic'" v-on="on">
            <v-icon>mdi-format-italic</v-icon>
          </v-btn>
        </template>
        <span>{{ $t('fontStyleTooltip.italic') }}</span>
      </v-tooltip>
    </v-btn-toggle>
    <!-- TEXT DECORATION -->
    <v-btn-toggle
      v-if="typeOfShapeToEdit === 'text'"
      dense
      group
      exclusive
      :value="selectedShape.config.textDecoration"
      @change="updateShape($event, 'textDecoration', 'font')"
    >
      <v-divider vertical />
      <v-tooltip bottom>
        <template #activator="{ on, attrs }">
          <v-btn
            v-bind="attrs"
            :value="'underline'"
            v-on="on"
          >
            <v-icon>mdi-format-underline</v-icon>
          </v-btn>
        </template>
        <span>{{ $t('textDecorationTooltip.underline') }}</span>
      </v-tooltip>
      <v-tooltip bottom>
        <template #activator="{ on, attrs }">
          <v-btn
            v-bind="attrs"
            :value="'line-through'"
            v-on="on"
          >
            <v-icon>mdi-format-strikethrough</v-icon>
          </v-btn>
        </template>
        <span>{{ $t('textDecorationTooltip.linethrough') }}</span>
      </v-tooltip>
    </v-btn-toggle>
    <!-- FONT ALIGN -->
    <v-btn-toggle
      v-if="typeOfShapeToEdit === 'text'"
      dense
      group
      mandatory
      :value="selectedShape.config.align"
      @change="updateShape($event, 'align', 'font')"
    >
      <v-divider vertical />
      <v-tooltip bottom>
        <template #activator="{ on, attrs }">
          <v-btn
            v-bind="attrs"
            :value="'left'"
            v-on="on"
          >
            <v-icon>mdi-format-align-left</v-icon>
          </v-btn>
        </template>
        <span>{{ $t('fontAlignTooltip.left') }}</span>
      </v-tooltip>
      <v-tooltip bottom>
        <template #activator="{ on, attrs }">
          <v-btn
            v-bind="attrs"
            :value="'center'"
            v-on="on"
          >
            <v-icon>mdi-format-align-center</v-icon>
          </v-btn>
        </template>
        <span>{{ $t('fontAlignTooltip.center') }}</span>
      </v-tooltip>
      <v-tooltip bottom>
        <template #activator="{ on, attrs }">
          <v-btn
            v-bind="attrs"
            :value="'right'"
            v-on="on"
          >
            <v-icon>mdi-format-align-right</v-icon>
          </v-btn>
        </template>
        <span>{{ $t('fontAlignTooltip.right') }}</span>
      </v-tooltip>
    </v-btn-toggle>
    <!-- SHAPE COLOR -->
    <v-tooltip bottom>
      <template #activator="{ on, attrs }">
        <span
          v-bind="attrs"
          v-on="on"
        >
          <edtk-button
            v-show="typeOfShapeToEdit === 'shape'"
            type="text"
            color="black"
          >
            <label for="shapeColor" class="shapeColor-btn">
              <span class="material-symbols-rounded">format_color_fill</span>
              <input
                id="shapeColor"
                type="color"
                name="shapeColor"
                :value="selectedShape.config.fill"
                @input="updateShape($event, 'fill', 'color')"
              >
            </label>
          </edtk-button>
        </span>
      </template>
      <span>{{ $t('tooltip.fillColor') }}</span>
    </v-tooltip>
    <!-- STROKE WIDTH & COLOR -->
    <v-divider v-show="typeOfShapeToEdit === 'shape'" vertical></v-divider>
    <div class="pl-5 d-flex align-center">
      <v-tooltip bottom>
        <template #activator="{ on, attrs }">
          <span
            v-show="typeOfShapeToEdit === 'shape'"
            v-bind="attrs"
            v-on="on"
          >
            <label for="strokeColor" class="shapeColor-btn">
              <span class="material-symbols-rounded">border_color</span>
              <input
                id="strokeColor"
                type="color"
                name="strokeColor"
                :value="selectedShape.config.stroke"
                @input="updateShape($event, 'stroke', 'color')"
              >
            </label>
          </span>
        </template>
        <span>{{ $t('tooltip.strokeColor') }}</span>
      </v-tooltip>
      <v-tooltip bottom>
        <template #activator="{ on, attrs }">
          <span
            v-bind="attrs"
            v-on="on"
          >
            <edtk-button-icon
              v-show="typeOfShapeToEdit === 'shape'"
              icon="add"
              icon-size="4xl"
              color="black"
              @click="increaseStrokeWidth"
            >
            </edtk-button-icon>
          </span>
        </template>
        <span>{{ $t('strokeWidthTooltip.increase') }}</span>
      </v-tooltip>
      <v-tooltip bottom>
        <template #activator="{ on, attrs }">
          <span
            v-bind="attrs"
            v-on="on"
          >
            <edtk-button-icon
              v-show="typeOfShapeToEdit === 'shape'"
              icon="remove"
              icon-size="4xl"
              color="black"
              class="ml-n5"
              @click="decreaseStrokeWidth"
            >
            </edtk-button-icon>
          </span>
        </template>
        <span>{{ $t('strokeWidthTooltip.decrease') }}</span>
      </v-tooltip>
    </div>
    <v-divider v-show="typeOfShapeToEdit === 'shape'" vertical></v-divider>
    <v-spacer />
    <v-divider vertical />
    <!-- STAGE ZOOM-->
    <v-tooltip bottom>
      <template #activator="{ on, attrs }">
        <span
          v-bind="attrs"
          v-on="on"
        >
          <edtk-button-icon
            icon="zoom_in"
            icon-size="4xl"
            color="black"
            @click="stageZoomIn"
          >
          </edtk-button-icon>
        </span>
      </template>
      <span>{{ $t('tooltip.zoomIn') }}</span>
    </v-tooltip>
    <v-divider vertical />
    <v-tooltip bottom>
      <template #activator="{ on, attrs }">
        <span
          v-bind="attrs"
          v-on="on"
        >
          <edtk-button-icon
            icon="zoom_out"
            icon-size="4xl"
            color="black"
            @click="stageZoomOut"
          >
          </edtk-button-icon>
        </span>
      </template>
      <span>{{ $t('tooltip.zoomOut') }}</span>
    </v-tooltip>
    <v-divider vertical />
    <!-- CLOSE CANVAS EDITOR -->
    <v-tooltip bottom>
      <template #activator="{ on, attrs }">
        <span
          v-bind="attrs"
          v-on="on"
        >
          <edtk-button-icon
            icon="close"
            icon-size="4xl"
            color="black"
            @click="closeStage"
          >
          </edtk-button-icon>
        </span>
      </template>
      <span>{{ $t('tooltip.close') }}</span>
    </v-tooltip>
  </v-toolbar>
</template>

<script>
export default {
  props: {
    typeOfShapeToEdit: {
      type: String,
      default: () => ''
    },
    selectedShape: {
      type: Object,
      default: () => {}
    }
  },
  data () {
    return {
      dropdown_font: ['Arial', 'Calibri', 'Courier', 'Verdana', 'Serif', 'Sans-serif', 'Monospace', 'Cursive', 'Fantasy'],
      dropdown_edit: [8, 10, 12, 14, 16, 18, 20, 22, 24, 26, 28, 30, 32, 34, 36, 38, 40, 44, 48, 52, 56, 60, 68, 76, 92, 96],
      shapes: [
        { name: 'rect', icon: 'mdi-square-outline' },
        { name: 'circle', icon: 'mdi-circle-outline' },
        { name: 'arrow', icon: 'mdi-arrow-right' },
        { name: 'bubbleLeft', icon: 'mdi-message-outline' },
        { name: 'bubbleRight', icon: 'mdi-message-reply-outline' }
      ]
    }
  },
  i18n: {
    messages: {
      fr: {
        library: 'Ma bibliothèque',
        tooltip: {
          zoomIn: 'Zoomer',
          zoomOut: 'Réduire le zoom',
          save: 'Enregistrer',
          close: 'Fermer',
          bringForward: 'Avancer d\'un plan',
          sendBackward: 'Reculer d\'un plan',
          delete: 'Supprimer',
          fontColor: 'Couleur du texte',
          fillColor: 'Couleur de remplissage',
          strokeColor: 'Couleur de bordure',
          addShape: 'Ajouter une forme',
          createNewAsset: 'Créer un nouvel élément',
          addText: 'Ajouter du texte'
        },
        fontStyleTooltip: {
          bold: 'Gras',
          italic: 'Italique'
        },
        textDecorationTooltip: {
          underline: 'Souligné',
          linethrough: 'Rayé'
        },
        fontAlignTooltip: {
          left: 'Aligner à gauche',
          center: 'Centrer',
          right: 'Aligner à droite'
        },
        strokeWidthTooltip: {
          increase: 'Augmenter l\'épaisseur',
          decrease: 'Diminuer l\'épaisseur'
        }
      },
      en: {
        library: 'My library',
        tooltip: {
          zoomIn: 'Zoom In',
          zoomOut: 'Zoom Out',
          save: 'Save',
          close: 'Close',
          bringForward: 'Bring forward',
          sendBackward: 'Send backward',
          delete: 'Delete',
          fontColor: 'Font color',
          fillColor: 'Fill color',
          strokeColor: 'Stroke color',
          addShape: 'Add shape',
          createNewAsset: 'Cretae new element',
          addText: 'Add text'
        },
        fontStyleTooltip: {
          bold: 'Bold',
          italic: 'Italic'
        },
        textDecorationTooltip: {
          underline: 'Underline',
          linethrough: 'Line through'
        },
        fontAlignTooltip: {
          left: 'Left',
          center: 'Center',
          right: 'Right'
        },
        strokeWidthTooltip: {
          increase: 'Increase stroke width',
          decrease: 'Decrease stroke width'
        }
      }
    }
  },
  methods: {
    openAssetEditor () {
      this.$emit('openAssetEditor')
    },
    stageZoomIn () {
      this.$emit('stageZoomIn')
    },
    stageZoomOut () {
      this.$emit('stageZoomOut')
    },
    save () {
      this.$emit('save')
    },
    closeStage () {
      this.$emit('closeStage')
    },
    addShape (shapeType) {
      this.$emit('addShape', shapeType)
    },
    expandLibrary () {
      this.$emit('expandLibrary')
    },
    updateShape (e, property, type) {
      const value = type === 'color' ? e.target.value : e
      this.$emit('updateShape', { value, property })
    },
    increaseStrokeWidth () {
      this.$emit('increaseStrokeWidth')
    },
    decreaseStrokeWidth () {
      this.$emit('decreaseStrokeWidth')
    },
    moveUp () {
      if (this.selectedShape.shapeId !== undefined) {
        this.$emit('moveUp')
      }
    },
    moveDown () {
      if (this.selectedShape.shapeId !== undefined) {
        this.$emit('moveDown')
      }
    },
    deleteShape () {
      this.$emit('deleteShape')
    }
  }
}
</script>

<style scoped>
  .v-select.selectFontFamily {
    width: 150px;
  }
  .v-select.selectFontSize {
    width: 80px;
  }
  #fontColor {
    margin-top: 0px;
    height: 0px;
    width: 0px;
  }
  .shapeColor-btn {
    display: flex;
    align-items: center;
    gap: 12px;
  }
  #shapeColor, #strokeColor {
    border: none;
    border-radius: 50%;
    height: 30px;
    width: 30px;
  }
</style>
