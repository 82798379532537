const i18n = {
  messages: {
    fr: {
      search: 'Rechercher',
      add: 'Ajouter',
      searchOrAdd: 'Rechercher ou ajouter',
      required: 'Etiquette requise',
      noOptions: 'Veuillez renseigner ce champ dans la configuration du workspace'
    },
    en: {
      search: 'Search',
      add: 'Add',
      searchOrAdd: 'Search or add',
      required: 'Required tag',
      noOptions: 'Please fill in this field in the workspace configuration'
    }
  }
}
module.exports = i18n
