const i18n = {
  messages: {
    fr: {
      waitingForPayment: 'Le renouvellement de votre abonnement est en attente de regularisation',
      accessInvoice: 'Accéder à ma facture'
    },
    en: {
      waitingForPayment: 'You still have an overdue bill to pay!',
      accessInvoice: 'Access my invoice'
    }
  }
}

module.exports = i18n
