<template>
  <section>
    <slot v-if="!editRight" name="default" :componentData="value">
      {{ value }}
    </slot>
    <template v-else>
      <p v-if="!editMode" style="white-space:pre-line; margin-bottom: 0;" :class="{ 'hover-highlight': editRight }" @mousedown="editMode = true">
        <slot name="default" :componentData="value">
          <span>{{ !value || value.length === 0 ? placeholder : value }}</span>
        </slot>
      </p>
      <span v-else>
        <textarea
          ref="EditableTextAreaInput"
          v-model="mutableModel"
          :placeholder="placeholder"
          :class="{ 'hover-highlight': true, 'active': editMode }"
          :rows="rows"
          @mousedown="editMode = true"
          @blur="UpdateMutableModel"
          @keypress="checkLength"
        ></textarea>
        <span v-if="editMode && maxLength" class="caption font-italic">{{ `${$t('maxLengthAlert')} ${maxLength}` }}</span>
      </span>
    </template>
    <span
      v-if="$v.mutableModel.$error"
      class="form_error"
    >
      {{ $t('errors.input.INVALID') }} - {{ attrsInformation }}
    </span>
  </section>
</template>

<script>
import { required } from 'vuelidate/lib/validators'
import i18n from './i18n.js'
export default {
  name: 'EditableTextarea',
  props: {
    dataTest: {
      type: String
    },
    editRight: {
      type: Boolean,
      required: true,
      default: false
    },
    rows: {
      type: String,
      default: () => ''
    },
    placeholder: {
      type: String,
      default: () => ''
    },
    value: {
      type: String,
      default: () => undefined
    },
    maxLength: {
      type: Number,
      default: () => 0
    },
    customValidation: {
      type: Object,
      default: () => {}
    }
  },
  validations () {
    return {
      mutableModel: {
        // Adding all custom validations to the mutableModel
        ...this.customValidation ? Object.keys(this.customValidation.controls).map((key) => { return this.customValidation.controls[key] }) : {},
        required: this.$attrs.required || this.$attrs.required === '' ? required : {}
      }
    }
  },
  data () {
    return {
      editMode: false,
      mutableModel: this.value
    }
  },
  i18n,
  computed: {
    attrsInformation () {
      const errorMessage = []
      if ((this.$attrs.required || this.$attrs.required === '') && !this.$v.mutableModel.required) {
        errorMessage.push(`${this.$t('required')}`)
      }
      Object.keys(this.customValidation ? this.customValidation.controls : {}).forEach((key) => {
        if (!this.$v.mutableModel[key]) {
          errorMessage.push(this.customValidation.errors[key])
        }
      })

      return errorMessage.join(' - ')
    }
  },
  watch: {
    value (n, o) {
      this.mutableModel = this.value
    },
    editMode (n) {
      if (n === true) {
        setTimeout(function () {
          this.focus()
        }.bind(this), 150)
      }
    }
  },
  methods: {
    UpdateMutableModel (e) {
      this.editMode = false
      this.$v.mutableModel.$touch()
      if (!this.$v.mutableModel.$error) {
        this.$emit('input', this.mutableModel)
      }
    },
    checkLength (e) {
      if (this.maxLength) {
        if (e.target.value.length >= this.maxLength) {
          e.preventDefault()
        }
      }
    },
    focus () {
      this.$nextTick(() => this.$refs.EditableTextAreaInput.focus())
    }
  }
}
</script>
<style lang="scss" scoped>
textarea {
  width: 100%;
}
.hover-highlight {
  display: block;
  border-bottom: dashed transparent 2px;
  border-radius: 0;
  &:hover {
    cursor: text;
    border-bottom: dashed $blue 2px;
  }
  &.active {
    border-bottom: solid $blue 1px;
    margin-bottom: 5px;
  }
}
</style>
