const i18n = {
  messages: {
    fr: {
      saveAlert: 'Sauvegarde...'
    },
    en: {
      saveAlert: 'Save...'
    }
  }
}
module.exports = i18n
