export const state = () => ({
  config: {},
  lastUpdate: undefined
})

export const getters = {
  getRemoteConfig (state) {
    return state.config
  },
  getMaintenanceState (state) {
    return state.config.MAINTENANCE
  },
  getStorageHost (state) {
    return state.config.API_STORAGE_HOST
  },
  getAppIdGoogle (state) {
    return state.config.APP_ID_GOOGLE
  },
  getDeveloperKeyGoogle (state) {
    return state.config.DEVELOPER_KEY_GOOGLE
  },
  getMicrosoftClientId (state) {
    return state.config.MICROSOFT_CLIENT_ID
  },
  getPizzlyHost (state) {
    return state.config.PIZZLY_HOST
  },
  getAadb2cChangePasswordEndpoint (state) {
    return state.config.AADB2C_CHANGE_PASSWORD_ENDPOINT
  },
  getAadb2cCreateAccountEndpoint (state) {
    return state.config.AADB2C_CREATE_ACCOUNT_ENDPOINT
  },
  getSelectSubscription (state) {
    return state.config.stripe.SELECT_SUBSCRIPTION
  },
  getModifySubscription (state) {
    return state.config.stripe.MODIFY_SUBSCRIPTION
  },
  getWaitingForPaymentBand (state) {
    return state.config.stripe.WAITING_FOR_PAYMENT_BAND
  },
  getSoftwareOnlyByContact (state) {
    return state.config.stripe.SOFTWARE_ONLY_BY_CONTACT
  },
  getSalesAppointmentLink (state) {
    return state.config.stripe.SALES_APPOINTMENT_LINK
  },
  getAlgoliaAppId (state) {
    return state.config.ALGOLIA_APP_ID
  },
  getInokufuResearchTypes (state) {
    return state.config.INOKUFU_RESEARCH_TYPES
  },
  getH5PHost (state) {
    return state.config.H5P_HOST
  }
}

export const actions = {
  init ({ commit, state }) {
    if (!state.config || !state.lastUpdate || this.$dayjs().diff(state.lastUpdate, 'minute') >= 30) {
      return new Promise((resolve, reject) => {
        this.$edtake.remoteConfig.getConfig()
          .then((d) => {
            commit('SET_REMOTE_CONFIG', d)
            try {
              this.$evt.init()
            } catch (e) {
              console.error('ERROR', e)
            }
            try {
              this.$chat.init()
            } catch (e) {
              console.error('ERROR', e)
            }
            resolve(d)
          })
          .catch((err) => {
            console.error('ERROR', err)
            reject(err)
          })
      })
    } else {
      return state.config
    }
  },
  updateWithSocket ({ commit }, config) {
    commit('SET_REMOTE_CONFIG', config.config.data.object)
  }
}

export const mutations = {
  SET_REMOTE_CONFIG (state, config) {
    state.config = config
    state.lastUpdate = this.$dayjs()
  }
}
