const i18n = {
  messages: {
    fr: {
      search: 'Rechercher',
      required: 'Une langue est requise',
      add: 'Ajouter',
      noOptions: 'Veuillez renseigner ce champ dans la configuration du workspace'
    },
    en: {
      required: 'A language is required',
      search: 'Search',
      add: 'Add',
      noOptions: 'Please fill in this field in the workspace configuration'
    }
  }
}
module.exports = i18n
