const i18n = {
  messages: {
    fr: {
      title: {
        time: '+ Ajouter une compétence secondaire',
        deliverable: '+ Ajouter un livrable'
      },
      addName: 'Ajouter un nom',
      selectCategory: 'Sélectionner une catégorie',
      selectSecondarySkill: 'Sélectionner une compétence secondaire'
    },
    en: {
      title: {
        time: '+ Add secondary skill',
        deliverable: '+ Add deliverable'
      },
      addName: 'Add name',
      selectCategory: 'Select category',
      selectSecondarySkill: 'Select secondary skill'
    }
  }
}

export default i18n
