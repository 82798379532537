<template>
  <v-popover
    :disabled="!editRight"
    trigger="manual"
    :open="isOpen"
    :placement="position"
    :container="containerData"
    @hide="close"
  >
    <section
      :class="{ 'pointer': editRight, 'hover-highlight': editRight }"
      :data-test="dataTest"
      @click="isOpen =! isOpen"
    >
      <slot v-if="!editRight" name="default" :component-data="value">
        <span v-if="value.length > 0">{{ dateRangeText }}</span>
        <span v-else>{{ $t('unscheduled') }}</span>
      </slot>
      <div v-else>
        <slot v-if="!isOpen" name="default" :component-data="value">
          <span v-if="value.length > 0">{{ dateRangeText }}</span>
          <span v-else>{{ $t('unscheduled') }}</span>
        </slot>
        <div v-else>
          <span v-if="value.length > 0">{{ dateRangeText }}</span>
          <span v-else>{{ $t('unscheduled') }}</span>
        </div>
      </div>
    </section>

    <template slot="popover">
      <section>
        <h5 v-if="title" class="blue--text text-center pt-2 pb-1">
          {{ title }}
        </h5>
        <v-date-picker
          v-model="mutableModel"
          class="ma-0"
          :locale="$i18n.locale"
          :min="minDate"
          :max="maxDate"
          range
          no-title
          :type="pickerType"
          @change="updateValue"
        >
        </v-date-picker>
      </section>
    </template>
    <span
      v-if="$v.mutableModel.$error"
      class="form_error"
    >
      {{ $t('errors.input.INVALID') }} - {{ attrsInformation }}
    </span>
  </v-popover>
</template>

<script>
import { required } from 'vuelidate/lib/validators'

import i18n from './i18n.js'
export default {
  name: 'EditableDatepickerRange',
  props: {
    dataTest: {
      type: String
    },
    editRight: {
      type: Boolean,
      required: true,
      default: false
    },
    value: {
      type: Array,
      default: () => []
    },
    containerRef: {
      type: HTMLElement
    },
    parentContainer: {
      type: Boolean,
      default: () => false
    },
    position: {
      type: [String, undefined],
      default: () => 'auto'
    },
    title: {
      type: String,
      default: () => ''
    },
    displayDate: {
      type: String,
      default: () => 'short',
      validator: v => ['month', 'short', 'long'].includes(v)
    },
    pickerType: {
      type: String,
      default: () => 'date',
      validator: v => ['date', 'month'].includes(v)
    },
    customValidation: {
      type: Object,
      default: () => {}
    }
  },
  validations () {
    return {
      mutableModel: {
        ...this.customValidation ? Object.keys(this.customValidation.controls).map((key) => { return this.customValidation.controls[key] }) : {},
        required: this.$attrs.required || this.$attrs.required === '' ? required : {}
      }
    }
  },
  data () {
    return {
      mutableModel: this.value && this.value.length ? [this.$dayjs(this.value[0]).format('YYYY-MM-DD'), this.$dayjs(this.value[1]).format('YYYY-MM-DD')] : [],
      isOpen: false,
      containerData: undefined,
      res: undefined
    }
  },
  computed: {
    dateRangeText () {
      return this.mutableModel ? [this.$d(this.$dayjs(this.mutableModel[0]), this.displayDate), this.$d(this.$dayjs(this.mutableModel[1]), this.displayDate)].join(' ~ ') : undefined
    },
    minDate () {
      return this.$attrs.min ? this.toISOFormat(this.$attrs.min) : undefined
    },
    maxDate () {
      return this.$attrs.max ? this.toISOFormat(this.$attrs.max) : undefined
    },
    attrsInformation () {
      const errorMessage = []
      if ((this.$attrs.required || this.$attrs.required === '') && !this.$v.mutableModel.required) {
        errorMessage.push(`${this.$t('required')}`)
      }
      Object.keys(this.customValidation ? this.customValidation.controls : {}).forEach((key) => {
        if (!this.$v.mutableModel[key]) {
          errorMessage.push(this.customValidation.errors[key])
        }
      })
      return errorMessage.join(' - ')
    }
  },
  watch: {
    value (n) {
      this.mutableModel = n && n.length ? [this.$dayjs(n[0]).format('YYYY-MM-DD'), this.$dayjs(n[1]).format('YYYY-MM-DD')] : []
    },
    containerRef (n) {
      this.containerData = n
    }
  },
  mounted () {
    if (this.parentContainer) {
      this.containerData = this.$parent.$el
    }
  },
  i18n,
  methods: {
    updateValue (e) {
      this.$v.mutableModel.$touch()
      if (!this.$v.mutableModel.$error) { this.$emit('input', [this.toISOFormat(this.mutableModel[0]), this.toISOFormat(this.mutableModel[1])]) }
      this.close()
    },
    toISOFormat (date) {
      return this.$dayjs(date).format('YYYY-MM-DDT00:00:00.000') + 'Z'
    },
    close () {
      this.isOpen = false
      this.$v.mutableModel.$touch()
    }
  }
}
</script>
<style scoped>
.hover-highlight:hover {
  font-weight: 600;
}
</style>
