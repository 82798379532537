const i18n = {
  messages: {
    fr: {
      documentEdtake: 'Document Edtake'
    },
    en: {
      documentEdtake: 'Edtake Document'
    }
  }
}
module.exports = i18n
