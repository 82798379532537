export const state = () => ({
  projectItem: [],
  learningObject: []
})

export const getters = {
  getProjectItem (state) {
    return state.projectItem
  },
  getProjectItemByItem: state => (item) => {
    return state.projectItem.find(i => i._id === item._id)
  },
  getLearningObject (state) {
    return state.learningObject
  },
  getLearningObjectByItem: state => (item) => {
    return state.learningObject.find(i => i._id === item._id)
  }
}

export const actions = {
  clear ({ commit }) {
    commit('CLEAR')
  },
  toggleProjectItemByItem ({ commit }, item) {
    commit('TOGGLE_PROJECT_ITEM', item)
    this.$evt.log('BulkEdition', { type: 'projectItem' })
  },
  toggleLearningObjectByItem ({ commit }, item) {
    commit('TOGGLE_LEARNING_OBJECT', item)
    this.$evt.log('BulkEdition', { type: 'learningObject' })
  },
  _recalculateProjectItems ({ state, commit }) {
    state.projectItem.forEach((item) => {
      if (!this.$userRightsHelper.userHasRoleOnItem(item, ['accountable', 'responsible'])) {
        commit('TOGGLE_PROJECT_ITEM', item)
      }
    })
  },
  _recalculateLearningObject ({ state, commit }) {
    state.learningObject.forEach((item) => {
      if (!this.$userRightsHelper.userHasRoleOnItem(item, ['accountable', 'responsible'])) {
        commit('TOGGLE_LEARNING_OBJECT', item)
      }
    })
  }
}

export const mutations = {
  CLEAR (state) {
    state.projectItem = []
    state.learningObject = []
  },
  TOGGLE_PROJECT_ITEM (state, value) {
    if (state.projectItem.some(i => i._id === value._id)) {
      state.projectItem = state.projectItem.filter(i => i._id !== value._id)
    } else {
      state.projectItem.push(value)
    }
  },
  TOGGLE_LEARNING_OBJECT (state, value) {
    if (state.learningObject.some(i => i._id === value._id)) {
      state.learningObject = state.learningObject.filter(i => i._id !== value._id)
    } else {
      state.learningObject.push(value)
    }
  }
}
