const i18n = {
  messages: {
    fr: {
      title: 'Votre espace de travail est en cours de création',
      text: 'Votre demande de création d\'espace de travail a bien été enregistrée.<br/>Vous allez être rapidement contacté par notre équipe commerciale pour valider son activation.',
      scheduleDemo: 'Réserver une démo'
    },
    en: {
      title: 'Your workspace is on his way...',
      text: 'Your request to create a new workspace has been registered.<br/>You will be quickly contacted by our sales team to validate its activation.',
      scheduleDemo: 'Book a demo'
    }
  }
}
module.exports = i18n
