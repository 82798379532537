export default async function ({ store, redirect, route, $enums, $nuxt }) {
  let wsId
  if (route.params.workspace) {
    wsId = route.params.workspace
  }

  /* If no WS change, no need to reload WS and settings */
  let current = store.getters['workspace/getCurrent']

  if (!current || current._id !== wsId) {
    await store.dispatch('workspace/fetch', { }, { root: true })
    if (!wsId) {
      current = store.getters['workspace/getCurrent']
    } else {
      current = await store.dispatch('workspace/fetchOne', { id: wsId, force: true }, { root: true })
    }
  } else if (/^\/w/.test(route.path)) {
    current = await store.dispatch('workspace/fetchOne', { id: wsId }, { root: true })
  }

  const SELECT_SUBSCRIPTION = store.getters['remoteConfig/getSelectSubscription']
  if (/^\/w/.test(route.path) && SELECT_SUBSCRIPTION && current) {
    const subscribeUrl = `/${$enums.routePrefix.workspace}/${wsId}/subscribe`
    const startTrialUrl = `/${$enums.routePrefix.workspace}/${wsId}/startTrial`
    const subcribeRedirectionUrl = current?.license?.allowFreeTrialStart ? startTrialUrl : subscribeUrl
    if (current.license.subscriptionStatus === $enums.stripe.subscriptionStatus.inactive && route.path !== subcribeRedirectionUrl) {
      // This means that a new customer will be created, even if it is a resubscribe.
      // For now, the stripe pricing table always create a new customer and we cannot link a new subscription to an existing customer.
      return redirect(subcribeRedirectionUrl)
    } else if (current.license.subscriptionStatus !== $enums.stripe.subscriptionStatus.inactive && route.path === subcribeRedirectionUrl) {
      return redirect(`/${$enums.routePrefix.workspace}/${wsId}`)
    }
  }
}
