export const state = () => ({
  open: undefined,
  text: undefined,
  status: true
})

export const getters = {
  getSnackbarOpen (state) {
    return state
  }
}

export const actions = {
  setSnackbarOpen ({ commit }, payload) {
    return new Promise((resolve) => {
      commit('CLEAR')
      commit('SET_OPENED', payload)
      resolve()
    })
  },
  setSnackbarClose ({ commit }) {
    return new Promise((resolve) => {
      commit('CLEAR')
      resolve()
    })
  }
}

export const mutations = {
  SET_OPENED (state, payload) {
    state.open = true
    state.text = payload ? payload.text : undefined
    state.status = payload ? payload.status : true
  },

  CLEAR (state) {
    state.open = undefined
    state.text = undefined
    state.status = true
  }
}
