import Vue from 'vue'
import VueI18n from 'vue-i18n'
import { L10n, loadCldr, setCulture, setCurrencyCode } from '@syncfusion/ej2-base'

import messages from '~/locales'
Vue.use(VueI18n)

// https://github.com/nuxt-community/vuetify-module/issues/152#issuecomment-529256769
Vue.mixin({
  watch: {
    '$i18n.locale' (newLocale) {
      this.$vuetify.lang.current = newLocale
    }
  }
})

export default ({ app, store }) => {
  app.vuetify.framework.lang.current = store.state.user.locale

  L10n.load(require('@syncfusion/ej2-locale/src/fr.json'))
  L10n.load(require('@syncfusion/ej2-locale/src/en-GB.json'))

  function cldr (l) {
    switch (l) {
      case 'fr':
        L10n.load(require('@syncfusion/ej2-locale/src/fr.json'))
        loadCldr(
          require('~/locales/cldr/fr/ca-gregorian.json'),
          require('~/locales/cldr/fr/numbers.json'),
          require('~/locales/cldr/fr/currencies.json'),
          require('~/locales/cldr/fr/timeZoneNames.json'),
          require('~/locales/cldr/numberingSystems.json'),
          require('~/locales/cldr/weekData.json')
        )
        setCulture('fr')
        setCurrencyCode('EUR')
        break

      case 'en':
        L10n.load(require('@syncfusion/ej2-locale/src/en-GB.json'))
        loadCldr(
          require('~/locales/cldr/en-GB/ca-gregorian.json'),
          require('~/locales/cldr/en-GB/numbers.json'),
          require('~/locales/cldr/en-GB/currencies.json'),
          require('~/locales/cldr/en-GB/timeZoneNames.json'),
          require('~/locales/cldr/numberingSystems.json'),
          require('~/locales/cldr/weekData.json')
        )
        setCulture('en-GB')
        setCurrencyCode('EUR')
        break

      default:
        L10n.load(require('@syncfusion/ej2-locale/src/en-GB.json'))
        loadCldr(
          require('~/locales/cldr/en-GB/ca-gregorian.json'),
          require('~/locales/cldr/en-GB/numbers.json'),
          require('~/locales/cldr/en-GB/currencies.json'),
          require('~/locales/cldr/en-GB/timeZoneNames.json'),
          require('~/locales/cldr/numberingSystems.json'),
          require('~/locales/cldr/weekData.json')
        )
        setCulture('en-GB')
        setCurrencyCode('EUR')
        break
    }
  }

  cldr('fr')

  app.i18n = new VueI18n({
    locale: store.state.user.locale,
    silentTranslationWarn: true,
    silentFallbackWarn: true,
    fallbackLocale: 'fr',
    messages: { ...messages },
    datetimeFormats: {
      fr: {
        month: {
          year: 'numeric',
          month: '2-digit'
        },
        short: {
          year: 'numeric',
          month: '2-digit',
          day: '2-digit'
        },
        day: {
          day: '2-digit'
        },
        weekday: {
          weekday: 'long'
        },
        long: {
          year: 'numeric',
          month: '2-digit',
          day: '2-digit',
          hour: '2-digit',
          minute: '2-digit',
          second: '2-digit'
        }
      },
      en: {
        month: {
          year: 'numeric',
          month: '2-digit'
        },
        short: {
          year: 'numeric',
          month: '2-digit',
          day: '2-digit'
        },
        day: {
          day: '2-digit'
        },
        weekday: {
          weekday: 'long'
        },
        long: {
          year: 'numeric',
          month: '2-digit',
          day: '2-digit',
          hour: '2-digit',
          minute: '2-digit',
          second: '2-digit'
        }
      }
    },
    numberFormats: {
      en: {
        currency: {
          style: 'currency', currency: 'EUR'
        },
        percent: {
          style: 'percent'
        },
        number: {
          style: 'decimal'
        }
      },
      fr: {
        currency: {
          style: 'currency', currency: 'EUR'
        },
        percent: {
          style: 'percent'
        },
        number: {
          style: 'decimal'
        }
      }
    }
  })

  app.cldr = cldr
}
