<template>
  <v-popover
    :disabled="!editRight"
    trigger="manual"
    :open="isOpen"
    :placement="position"
    :container="containerData"
    @hide="close"
  >
    <section
      v-if="editRight"
      class="tooltip-target pointer"
      :data-test="dataTest"
      @click="isOpen =! isOpen"
    >
      <slot v-if="!isOpen" name="default" :component-data="value">
        <div class="hover-highlight">
          <span v-if="!mutableModel || mutableModel.length === 0">{{ placeholder }}</span>
          <v-chip-group
            v-else
            active-class="primary--text"
            column
          >
            <v-chip
              v-for="language in mutableModel"
              :key="language"
              close
              :small="!($attrs['large-chip'] || $attrs['large-chip'] === '')"
              outlined
              color="primary"
              :class="{'font-weight-regular': true}"
              @click:close="removeTag(language)"
            >
              {{ $t(`languages.${language}`) }}
            </v-chip>
          </v-chip-group>
        </div>
      </slot>
      <div v-else class="hover-highlight">
        <span v-if="!mutableModel || mutableModel.length === 0">{{ placeholder }}</span>
        <v-chip-group
          v-else
          active-class="primary--text"
          column
        >
          <v-chip
            v-for="language in mutableModel"
            :key="language"
            close
            :small="!($attrs['large-chip'] || $attrs['large-chip'] === '')"
            outlined
            color="primary"
            :class="{'font-weight-regular': true}"
            @click:close="removeTag(language)"
          >
            {{ $t(`languages.${language}`) }}
          </v-chip>
        </v-chip-group>
      </div>
    </section>
    <slot v-else name="default" :component-data="value">
      <v-chip
        v-for="(t, $index) in mutableModel"
        :key="$index"
        label
        outlined
        color="primary"
        class="mb-2 mr-2"
      >
        {{ $t(`languages.${t}`) }}
      </v-chip>
    </slot>
    <span
      v-if="$v.mutableModel.$error"
      class="form_error"
    >
      {{ $t('errors.input.INVALID') }} - {{ attrsInformation }}
    </span>
    <template slot="popover">
      <section v-if="options.length" class="popover-section">
        <div class="popover-header">
          <div>
            <h5 v-if="title" class="blue--text text-center py-2 mx-2">
              {{ title }}
            </h5>
            <v-icon small class="mr-2">
              mdi-magnify
            </v-icon>
            <input
              ref="searchInputBox"
              v-model="search"
              class="search-input"
              :placeholder="$t('search')"
            />
          </div>
        </div>
        <v-divider></v-divider>
        <v-list class="popover-list">
          <v-list-item
            v-for="(item, i) in filteredOptions"
            :key="i"
            @click="selectValue(item.label)"
          >
            <v-list-item-content class="px-2">
              <v-list-item-title>
                <span>{{ $t(`languages.${item.label}`) }}</span>
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </section>
      <section v-else>
        <div class="popover-default-message brownish-grey--text text-center py-2 mx-2">
          {{ $t('noOptions') }}
        </div>
      </section>
    </template>
  </v-popover>
</template>

<script>
import { required } from 'vuelidate/lib/validators'
import i18n from './i18n.js'

export default {
  name: 'EditableLanguageMultiple',
  props: {
    'data-test': {
      type: String
    },
    containerRef: {
      type: HTMLElement
    },
    parentContainer: {
      type: Boolean,
      default: () => false
    },
    editRight: {
      type: Boolean,
      required: true,
      default: false
    },
    value: {
      type: Array,
      default: () => []
    },
    options: {
      type: Array,
      default: () => []
    },
    position: {
      type: [String, undefined],
      default: () => 'auto'
    },
    placeholder: {
      type: String,
      default: () => ''
    },
    title: {
      type: String,
      default: () => ''
    },
    customValidation: {
      type: Object,
      default: () => {}
    }
  },
  validations () {
    return {
      mutableModel: {
        // Adding all custom validations to the mutableModel
        ...this.customValidation ? Object.keys(this.customValidation.controls).map((key) => { return this.customValidation.controls[key] }) : {},
        required: this.$attrs.required || this.$attrs.required === '' ? required : {}
      }
    }
  },
  data () {
    return {
      search: '',
      containerData: undefined,
      mutableModel: this.value,
      isOpen: false,
      res: undefined
    }
  },
  i18n,
  computed: {
    filteredOptions () {
      const self = this
      const searchText = this.search ? this.$_.deburr(this.search.toUpperCase()) : ''
      const filteredValue = this.$_.filter(this.options, x => !this.mutableModel.includes(x))
      const r = this.$_.map(filteredValue, (o) => { return { label: o, search: self.$_.deburr((this.$t(`languages.${o}`)).toUpperCase()) } })
      r.sort((a, b) => (a.search > b.search) ? 1 : -1)
      return searchText === '' ? r : this.$_.filter(r, o => o.search.includes(searchText))
    },
    attrsInformation () {
      const errorMessage = []
      if ((this.$attrs.required || this.$attrs.required === '') && !this.$v.mutableModel.required) {
        errorMessage.push(`${this.$t('required')}`)
      }
      Object.keys(this.customValidation ? this.customValidation.controls : {}).forEach((key) => {
        if (!this.$v.mutableModel[key]) {
          errorMessage.push(this.customValidation.errors[key])
        }
      })

      return errorMessage.join(' - ')
    }
  },
  watch: {
    value (n) {
      this.mutableModel = n
    },
    containerRef (n) {
      this.containerData = n
    },
    isOpen (n) {
      if (n === true) {
        this.search = ''
        setTimeout(function () {
          this.focus()
        }.bind(this), 150)
      }
    }
  },
  mounted () {
    if (this.parentContainer) {
      this.containerData = this.$parent.$el
    }
  },
  methods: {
    removeTag (language) {
      this.mutableModel = this.$_.filter(this.mutableModel, x => (x !== language))
      this.$v.mutableModel.$touch()
      if (!this.$v.mutableModel.$error) { this.$emit('input', this.mutableModel) }
    },
    selectValue (e) {
      this.$v.mutableModel.$touch()
      if (!this.$v.mutableModel.$error) { this.$emit('input', this.$_.concat(this.mutableModel, e)) }
    },
    close () {
      this.$v.mutableModel.$touch()
      this.isOpen = false
      this.search = ''
    },
    focus () {
      if (this.options.length) {
        this.$nextTick(() => this.$refs.searchInputBox.focus())
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.hover-highlight {
  min-width: 100px;
  display: block;
  border-bottom: dashed transparent 2px;
  &:hover {
    cursor: pointer;
    border-bottom: dashed $blue 2px;
  }
}
.search-input {
  border: none;
}
.popover-section {
  margin: -5px;
}
.popover-list {
  max-height: 300px;
  overflow: auto;
}
.popover-header {
  max-height: 100px;
  padding: 0 12px;
}
.popover-default-message {
  max-width: 200px;
  padding: 0 12px;
}
</style>
