import _ from 'lodash'

export default ctx => ({
  /**
  * Calculate the progression of a project item
  *
  * @param { {} } pi - project item
  * @return { {progress : Number, expected: Number, done: Number}} progress calculation
  */
  progressCalculation: (pi) => {
    if (pi.__t === 'task') {
      const progressByStatus = {
        'to do': 0,
        'in progress': 50,
        done: 100
      }
      return {
        progress: progressByStatus[pi.status],
        expected: 0,
        done: 0
      }
    }
    const statusesSettingsOptions = ctx.store.getters['settings/getCurrentStatusByType'](pi.__t)
    const statusesSettingsProgressCalculMode = ctx.store.getters['settings/getCurrentStatusProgressCalculModeByType'](pi.__t)
    if (statusesSettingsProgressCalculMode === 'children') {
      const allStatusesOptions = ctx.store.getters['settings/getAllStatus']()
      let doneStatuses = _.map(
        _.filter(allStatusesOptions, (s) => { return s.isDone }),
        (o) => { return o.label }
      )
      doneStatuses.push('ARCHIVE')
      doneStatuses = _.uniq(doneStatuses)

      const childrenNb = pi.children ? pi.children.length : 0
      const childrenDone = _.filter(pi.children, (d) => {
        return doneStatuses.includes(d.status) || d.status === 'done'
      })
      const childrenDoneNb = childrenDone.length

      const isDone = doneStatuses.includes(pi.status)
      if (isDone) {
        return {
          progress: 100,
          expected: childrenNb,
          done: childrenDoneNb
        }
      }

      return {
        progress: Math.round((childrenDoneNb / childrenNb) * 100) || 0,
        expected: childrenNb,
        done: childrenDoneNb
      }
    } else if (statusesSettingsProgressCalculMode === 'statusPercentage') {
      const currentStatus = _.find(statusesSettingsOptions, s => s.label === pi.status)
      return {
        progress: currentStatus ? currentStatus.globalPercentage : 0,
        expected: undefined,
        done: undefined
      }
    }
  }
})
