<template lang="html">
  <v-app>
    <v-app-bar
      clipped-left
      flat
      fixed
      app
      color="white"
      z-index="2000"
    >
      <img
        class="logo ml-4"
        src="~assets/img/logo-edtake.svg"
      />
    </v-app-bar>
    <v-main data-layout="onboarding">
      <transition
        name="nuxtTransition"
        appear
        appear-active-class="component-enter-active"
        leave-active-class="component-leave-active"
      >
        <nuxt />
      </transition>
      <ProposeChangePassword />
      <DisableSmallDevices />
    </v-main>
  </v-app>
</template>

<script lang="js">
import { mapGetters, mapActions } from 'vuex'
import ProposeChangePassword from '~/components/common/ProposeChangePassword'

export default {
  name: 'OnboardingLayout',
  components: {
    ProposeChangePassword
  },
  middleware: ['workspace'],
  data () {
    return {
    }
  },
  computed: {
    ...mapGetters({
      user: 'user/get'
    })
  },
  beforeMount () {
    this.init()
  },
  methods: {
    ...mapActions({
      fetchTeams: 'team/fetch'
    }),
    init () {
      this.fetchTeams()
    }
  }
}
</script>

<style lang="scss">
.logo {
  height: 28px;
  object-fit: contain;
  text-align: center;
  margin-top: 18px;
  margin-bottom: 18px;
}
</style>
