export const state = () => ({
  opened: false
})

export const getters = {
  status (state) {
    return state.opened
  }
}

export const actions = {
  set ({ commit }, value) {
    commit('SET', value)
  }
}

export const mutations = {
  SET (state, value) {
    state.opened = value
  }
}
