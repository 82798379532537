import upload from './upload'

const iconsComponents = {
  upload
}

const icons = { values: {} }
Object.keys(iconsComponents).forEach((key) => {
  icons.values[key] = { component: iconsComponents[key] }
})

export default icons
