<template>
  <v-popover
    :disabled="!editRight"
    trigger="manual"
    :open="isOpen"
    :placement="position"
    :container="containerData"
    @hide="close"
  >
    <section
      v-if="editRight"
      class="tooltip-target pointer"
      :data-test="dataTest"
      @click.stop="isOpen =! isOpen"
    >
      <slot v-if="!isOpen || !showInput" name="default" :component-data="value">
        <v-hover v-slot="{ hover }">
          <div class="hover-highlight d-flex align-center" :style="parentWidth">
            <span :style="childWidth" class="d-inline-block text-nowrap ellipsis">
              {{ !mutableModel || mutableModel.length === 0 ? placeholder : optionType === 'object' ? optionValue ? optionValue.label : placeholder : translateIfKeyExist(mutableModel) }}
            </span>
            <v-icon
              v-show="hover && clearable && mutableModel"
              small
              class="ml-1"
              color="primary"
              @click.stop.stop="clear"
            >
              mdi-close
            </v-icon>
          </div>
        </v-hover>
      </slot>
      <div v-else class="hover-highlight">
        {{ !mutableModel || mutableModel.length === 0 ? placeholder : optionType === 'object' ? optionValue ? optionValue.label : placeholder : translateIfKeyExist(mutableModel) }}
      </div>
    </section>

    <slot v-else name="default" :component-data="value">
      {{ optionType === 'object' ? optionValue ? optionValue.label : '' : translateIfKeyExist(mutableModel) }}
    </slot>
    <span
      v-if="$v.mutableModel.$error"
      class="form_error"
    >
      {{ $t('errors.input.INVALID') }} - {{ attrsInformation }}
    </span>
    <template slot="popover">
      <section v-if="!allowCustom && !options.length" class="popover-section">
        <div class="popover-default-message brownish-grey--text text-center py-2 mx-2">
          {{ noOptionMessage || $t('noOptions') }}
        </div>
      </section>
      <section v-else class="popover-section">
        <div class="popover-header">
          <h5 v-if="title" class="blue--text text-center py-2 mx-2">
            {{ title }}
          </h5>
          <div v-if="autocomplete">
            <span>
              <v-icon small>mdi-magnify</v-icon>
            </span>
            <input
              ref="searchInputBox"
              v-model="search"
              class="search-input"
              :placeholder="$t(placeholderSentence)"
            />
          </div>
        </div>
        <v-divider v-if="title || autocomplete"></v-divider>
        <v-chip v-if="allowCustom && search !== ''" class="pointer ma-2" @click.stop="addCustom">
          {{ $t('add') }} "{{ search }}"
        </v-chip>
        <v-list v-if="filteredOptions.length" class="popover-list" :max-height="listMaxHeight">
          <div v-for="(category, i) in categories" :key="i">
            <v-divider v-if="i !== 0"></v-divider>
            <v-subheader v-if="category !== 'primary'">
              {{ category }}
            </v-subheader>
            <v-list-item
              v-for="(item, j) in filteredOptions.filter(o => o.category === category)"
              :key="j"
              :disabled="item.disabled"
              @click.stop="selectValue(item)"
            >
              <v-list-item-content>
                <v-list-item-title>
                  <span :class="[item.action ? 'blue--text' : '']">
                    <v-icon v-if="item.action" class="mr-2" color="primary" small>
                      mdi-plus
                    </v-icon>{{ translateIfKeyExist(item.label) }}
                  </span>
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </div>
        </v-list>
        <div v-else class="popover-default-message brownish-grey--text text-center py-2 mx-2">
          {{ noOptionMessage || $t('noData') }}
        </div>
      </section>
    </template>
  </v-popover>
</template>

<script>
import { required } from 'vuelidate/lib/validators'
import i18n from './i18n.js'
export default {
  name: 'EditableList',
  props: {
    dataTest: {
      type: String
    },
    editRight: {
      type: Boolean,
      required: true,
      default: false
    },
    options: {
      type: Array,
      default: () => []
    },
    autocomplete: {
      type: Boolean,
      default: () => false
    },
    allowCustom: {
      type: Boolean,
      default: false
    },
    value: {
      type: [Array, String, Number],
      default: () => undefined
    },
    containerRef: {
      type: HTMLElement
    },
    parentContainer: {
      type: Boolean,
      default: () => false
    },
    translationKey: {
      type: String,
      default: () => undefined
    },
    position: {
      type: String,
      default: () => 'auto'
    },
    placeholder: {
      type: String,
      default: () => ''
    },
    showInput: {
      type: Boolean,
      default: true
    },
    title: {
      type: String,
      default: () => ''
    },
    clearable: {
      type: Boolean,
      default: false
    },
    clearValue: {
      type: [String, undefined],
      default: undefined
    },
    customValidation: {
      type: Object,
      default: () => {}
    },
    sortByOrder: {
      type: Boolean,
      default: false
    },
    noOptionMessage: {
      type: String,
      default: () => undefined
    },
    maxWidth: {
      type: Number,
      default: () => undefined
    },
    listMaxHeight: {
      type: Number,
      default: () => 300
    }
  },
  validations () {
    return {
      mutableModel: {
        // Adding all custom validations to the mutableModel
        ...this.customValidation ? Object.keys(this.customValidation.controls).map((key) => { return this.customValidation.controls[key] }) : {},
        required: this.$attrs.required || this.$attrs.required === '' ? required : {}
      }
    }
  },
  data () {
    return {
      search: '',
      mutableModel: this.value,
      isOpen: false,
      containerData: undefined,
      placeholderSentence: this.allowCustom ? this.options.length > 0 ? 'searchOrAdd' : 'add' : 'search',
      parentWidth: { width: this.maxWidth ? `${this.maxWidth + 10}px` : '100%' },
      childWidth: { width: this.maxWidth ? `${this.maxWidth}px` : '100%' }
    }
  },
  i18n,
  computed: {
    /* TODO refaire le composant */
    attrsInformation () {
      const errorMessage = []
      if ((this.$attrs.required || this.$attrs.required === '') && !this.$v.mutableModel.required) {
        errorMessage.push(`${this.$t('required')}`)
      }
      Object.keys(this.customValidation ? this.customValidation.controls : {}).forEach((key) => {
        if (!this.$v.mutableModel[key]) {
          errorMessage.push(this.customValidation.errors[key])
        }
      })

      return errorMessage.join(' - ')
    },
    optionType () {
      return this.options.length > 0 ? typeof this.options[0] : undefined
    },
    optionValue () {
      return this.$_.find(this.options, o => o.id === this.translateIfKeyExist(this.mutableModel))
    },
    filteredOptions () {
      const self = this
      const searchText = this.search ? this.$_.deburr(this.search.toUpperCase()) : ''
      let filteredValue

      if (!this.mutableModel || typeof this.mutableModel === 'number') {
        filteredValue = this.options
      } else {
        filteredValue = this.$_.filter(this.options, (x) => {
          const comparison = this.optionType === 'object' ? x.id : x
          return !(this.mutableModel === comparison)
        })
      }
      let r = this.$_.map(filteredValue, (o) => {
        if (typeof o === 'string') {
          return { id: o, label: o, search: self.$_.deburr(o.toUpperCase()), category: 'primary' }
        } else if (typeof o === 'number') {
          return { id: o, label: o, search: self.$_.deburr(o), category: 'primary' }
        } else {
          return { id: o.id, label: o.label || '', action: o.action, search: o.label ? self.$_.deburr(o.label.toUpperCase()) : '', category: o.category || 'primary', disabled: o.disabled || false, order: o.order }
        }
      })
      if (this.sortByOrder) {
        // storyboard/branching scenario >>> to display scenes by order
        r = r.sort((a, b) => a.order - b.order)
      } else {
        r = r.sort((a, b) => (a.action || (a.search > b.search)) ? 1 : (a.search < b.search) ? -1 : 0)
      }

      return searchText === '' ? r : r.filter(o => o.search.includes(searchText))
    },
    categories () {
      const categories = this.$_.reduce(this.filteredOptions, (acc, o) => {
        if (!this.$_.find(acc, category => o.category === category)) {
          return [...acc, o.category]
        }
        return acc
      }, ['primary'])
      return categories
    }
  },
  watch: {
    value (n) {
      this.mutableModel = n
    },
    containerRef (n) {
      this.containerData = n
    },
    isOpen (n) {
      if (n === true && this.autocomplete) {
        this.search = ''
        setTimeout(function () {
          this.focus()
        }.bind(this), 150)
      }
    }
  },
  mounted () {
    if (this.parentContainer) {
      this.containerData = this.$parent.$el
    }
  },
  methods: {
    addCustom () {
      this.$v.mutableModel.$touch()
      if (!this.$v.mutableModel.$error) {
        this.$emit('input', this.search)
      }
      this.search = ''
    },
    selectValue (item) {
      if (item.action) {
        this.$emit('input', item.id)
      } else {
        this.mutableModel = item.id
        this.$v.mutableModel.$touch()
        if (!this.$v.mutableModel.$error) { this.$emit('input', item.id) }
      }
      this.close()
    },
    clear () {
      this.$v.mutableModel.$touch()
      if (!this.$v.mutableModel.$error) {
        this.$emit('input', this.clearValue)
      }
    },
    translateIfKeyExist (item) {
      return this.translationKey ? this.$t(`${this.translationKey}.${item}`) : item
    },
    close () {
      this.$v.mutableModel.$touch()
      this.isOpen = false
      this.search = ''
    },
    focus () {
      if (this.options.length) {
        this.$nextTick(() => this.$refs.searchInputBox.focus())
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.tooltip-target {
  min-width: 20px;
}
.hover-highlight {
  display: block;
  border-bottom: dashed transparent 2px;
  &:hover {
    cursor: pointer;
    border-bottom: dashed $blue 2px;
  }
}
.search-input {
  border: none;
}
.popover-section {
  margin: -5px;
}
.popover-list {
  padding: 0;
  overflow: auto;
}
.popover-header {
  max-height: 100px;
  padding: 0 12px;
}
.popover-default-message {
  max-width: 200px;
  padding: 0 12px;
}
</style>
