<template>
  <v-item-group
    :value="value"
    :multiple="multiple"
    @change="(e) => updateValue(e)"
  >
    <v-row style="height: max-content;" class="ma-0">
      <v-col
        v-for="item in items"
        :key="item.id"
        cols="12"
        :md="cols"
        class="py-0 mb-4 px-2"
      >
        <v-item v-slot="{ active, toggle }" :value="item.id || item._id">
          <v-hover v-slot="{ hover }">
            <v-sheet
              outlined
              :class="[hover || active ? 'sheetBorderActive' : 'sheetBorder', withDescription ? '' : 'd-flex justify-center align-center' ]"
              class="pa-5 pointer"
              style="position: relative"
              height="100%"
              @click="toggle"
            >
              <div
                :class="withDescription ? 'mb-2' : 'text-center'"
              >
                <span class="itemTitle">
                  {{ item.label || item.name }}
                </span>
              </div>
              <p v-if="withDescription" class="mb-0 itemDescription">
                {{ item.description }}
              </p>
              <v-icon class="iconPlacement" small color="primary">
                {{ active ? 'mdi-check' : '' }}
              </v-icon>
            </v-sheet>
          </v-hover>
        </v-item>
      </v-col>
    </v-row>
  </v-item-group>
</template>
<script>

export default {
  props: {
    value: {
      type: [String, Array, Number, undefined],
      required: false
    },
    items: {
      type: Array,
      default: () => []
    },
    multiple: {
      default: false,
      type: Boolean
    },
    withDescription: {
      type: Boolean,
      default: false
    },
    cols: {
      type: Number,
      default: () => 12
    }
  },
  data () {
    return {
    }
  },
  computed: {
  },
  methods: {
    updateValue (e) {
      this.$emit('input', e)
    }
  }
}
</script>
<style lang="scss" scoped>
  .sheetHover {
    border: 1px solid #00558A;
  }
  .sheetBorder {
    border: 1px solid rgba(226, 226, 229, 1);
    border-radius: 4px;
  }
  .sheetBorderActive {
    border: 1px solid #00558A;
    border-radius: 4px;
  }
  .itemTitle {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: #1A1C1E;
  }
  .itemDescription {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: #76777A;
  }
  .iconPlacement {
    position: absolute;
    top: 10px;
    right: 10px;
  }
</style>
