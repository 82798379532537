<template>
  <v-sheet :class="edit ? 'editionCard' : 'dashedBorder'">
    <span v-if="!edit" class="pointer" @click="edit = true">
      {{ $t(`title.${type}`) }}
    </span>
    <div v-if="edit && type === 'deliverable'" class="d-flex justify-space-between" style="width: 100%;">
      <EditableField
        v-model="newDeliverable.name"
        type="text"
        :placeholder="$t('addName')"
        :edit-right="true"
      />
      <EditableField
        v-model="newDeliverable.category"
        type="list"
        :options="deliverablesCategories"
        style="font-size: 12px;"
        :placeholder="$t('selectCategory')"
        :edit-right="true"
      />
    </div>
    <div v-if="edit && type === 'time'">
      <EditableField
        v-model="secondarySkill"
        type="list"
        autocomplete
        parent-container
        :options="translatedSkillsOptions"
        :edit-right="true"
        :placeholder="$t('selectSecondarySkill')"
      />
    </div>
    <div v-if="edit" class="text-right">
      <v-icon small color="#A7A7A7" class="pointer mr-2" @click="close">
        mdi-close
      </v-icon>
      <v-icon
        small
        color="primary"
        class="pointer"
        :disabled="type === 'time' ? !secondarySkill : (!newDeliverable.name || !newDeliverable.category)"
        @click="submit"
      >
        mdi-check
      </v-icon>
    </div>
  </v-sheet>
</template>

<script>
import { mapGetters } from 'vuex'
import i18n from './i18n'

export default {
  props: {
    type: {
      type: String
    }
  },
  i18n,
  data () {
    return {
      edit: false,
      newDeliverable: {
        name: undefined,
        category: undefined
      },
      secondarySkill: undefined
    }
  },
  computed: {
    ...mapGetters({
      translatedSkillsOptions: 'configuration/getSkillsAsOptions'
    }),
    deliverablesCategories () {
      return this.$enums.deliverable.category.map((c) => {
        return { id: c, label: this.$t(`deliverable.category.${c}`) }
      })
    }
  },
  methods: {
    close () {
      this.edit = false
      this.newDeliverable = {
        name: undefined,
        category: undefined
      }
      this.secondarySkill = undefined
    },
    submit () {
      const payload = this.type === 'time' ? this.secondarySkill : this.newDeliverable
      this.$emit('submit', payload)
      this.close()
    }
  }
}
</script>

<style scoped>
.dashedBorder {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 20px;
  gap: 10px;
  height:100%;
  min-height: 64px;
  border: 1px dashed #E2E2E5;
  border-radius: 4px;
}
.dashedBorder span {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #76777A;
}
.editionCard {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0px 20px;
  gap: 1px;
  height:100%;
  min-height: 64px;
  background: #FCFCFF;
  border: 1px solid #E2E2E5;
  border-radius: 4px;
}
</style>
