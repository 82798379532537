/* eslint-disable no-console */
/* eslint-disable camelcase */
import _ from 'lodash'

const mechanismSchema = {
  _id: String,
  public: String,
  globalObjective: String,
  trainingContraints: Object,
  targets: Array,
  createdAt: String,
  updatedAt: String,
  name: String,
  description: String,
  status: String,
  objectives: Array,
  themes: Array,
  translations: Array,
  archived: Boolean,
  tags: Array,
  workspace: String,
  version: Number,
  restrictAccess: String,
  members: Array,
  __v: Number,
  __t: String,
  projects: Array,
  documents: Array
}

export const state = () => ({
  mechanisms: []
})

export const getters = {
  get (state) {
    return state.mechanisms
  },
  getOne: state => (id) => {
    return state.mechanisms.find(mechanism => mechanism._id === id)
  },
  getChildren: state => (id) => {
    const mechanism = state.mechanisms.find(mechanism => mechanism._id === id)
    return mechanism ? mechanism.children : undefined
  }
}

export const actions = {
  clear ({ commit }) {
    commit('CLEAR')
  },
  fetch ({ dispatch, commit }, { $queryParameters = {} } = { $queryParameters: {} }) {
    return new Promise((resolve, reject) => {
      this.$edtake.mechanism.get({ $queryParameters })
        .then((d) => {
          commit('SET', d)
          resolve(d)
        })
        .catch((err) => {
          console.error('ERROR', err)
          reject(err)
        })
    })
  },
  fetchOne ({ state, commit, dispatch }, { id, $queryParameters = {} }) {
    return new Promise((resolve, reject) => {
      this.$edtake.mechanism.getOne({ id, $queryParameters })
        .then((d) => {
          commit('UPDATE_ONE', d)
          if (d.projects) {
            dispatch('project/_update', d.projects, { root: true })
          }
          if (d.deliverables) {
            dispatch('deliverable/_update', d.deliverables, { root: true })
          }
          if (d.tasks) {
            dispatch('task/_update', d.tasks, { root: true })
          }
          resolve(d)
        })
        .catch((err) => {
          console.error('ERROR', err)
          reject(err)
        })
    })
  },
  fetchChildren ({ commit }, { id }) {
    return new Promise((resolve, reject) => {
      this.$edtake.mechanism.getChildren({ id })
        .then((d) => {
          commit('SET_CHILDREN', { id, children: d })
          resolve(d)
        })
        .catch((err) => {
          console.error('ERROR', err)
          reject(err)
        })
    })
  },
  create ({ dispatch, commit, rootGetters }, { body }) {
    const workspace = rootGetters['workspace/getCurrentId']
    body.workspace = workspace
    return new Promise((resolve, reject) => {
      this.$edtake.mechanism.create({
        body
      }).then((d) => {
        commit('CREATE', d)
        resolve(d)
        this.$evt.log('LearningObjectCreation', { type: 'mechanism' })
      })
        .catch((err) => {
          if (err.response.data === this.$enums.httpErrorBodies.upgrade_license) {
            reject(new Error('license.mechanismOutOfCredit'))
          }
          reject(err)
        })
    })
  },
  update ({ dispatch, commit }, { id, body }) {
    return new Promise((resolve, reject) => {
      this.$edtake.mechanism.update({
        id,
        body
      })
        .then((d) => {
          commit('UPDATE_ONE', d)
          resolve(d)
        })
        .catch((err) => {
          console.error('ERROR', err)
          reject(err)
        })
    })
  },
  remove ({ commit }, { id }) {
    return new Promise((resolve, reject) => {
      this.$edtake.mechanism.delete({ id })
        .then((d) => {
          commit('REMOVE', id)
          resolve(d)
        })
        .catch((err) => {
          if (err.response.status === 403) {
            reject(new Error('NOT_AUTHORIZED'))
          }
        })
    })
  },
  addTarget ({ commit }, { id, body }) {
    return new Promise((resolve, reject) => {
      this.$edtake.mechanism.addTarget({
        id,
        body
      })
        .then((d) => {
          commit('UPDATE_ONE', d)
          resolve(d)
        })
        .catch((err) => {
          console.error('ERROR', err)
          reject(err)
        })
    })
  },
  updateTarget ({ commit }, { id, targetId, body }) {
    return new Promise((resolve, reject) => {
      this.$edtake.mechanism.updateTarget({
        id,
        targetId,
        body
      })
        .then((d) => {
          commit('UPDATE_ONE', d)
          resolve(d)
        })
        .catch((err) => {
          console.error('ERROR', err)
          reject(err)
        })
    })
  },
  removeTarget ({ commit, dispatch }, { id, targetId }) {
    return new Promise((resolve, reject) => {
      this.$edtake.mechanism.removeTarget({
        id,
        targetId
      })
        .then((d) => {
          commit('UPDATE_ONE', d)
          dispatch('fetchChildren', { id })
          resolve(d)
        })
        .catch((err) => {
          console.error('ERROR', err)
          reject(err)
        })
    })
  },
  addLearningObjectToTarget ({ dispatch, commit }, { id, body, duplicate }) {
    return new Promise((resolve, reject) => {
      this.$edtake.mechanism.addLearningObjectToTarget({
        id,
        body,
        $queryParameters: { duplicate }
      })
        .then((d) => {
          commit('UPDATE_ONE', d.obj)
          dispatch('fetchChildren', { id })
          resolve(d)
        })
        .catch((err) => {
          console.error('ERROR', err)
          reject(err)
        })
    })
  },
  removeLearningObjectToTarget ({ commit, dispatch }, { id, targetId, learningObjectId }) {
    return new Promise((resolve, reject) => {
      this.$edtake.mechanism.removeLearningObjectToTarget({
        id,
        targetId,
        learningObjectId
      })
        .then((d) => {
          commit('UPDATE_ONE', d)
          dispatch('fetchChildren', { id })
          resolve(d)
        })
        .catch((err) => {
          console.error('ERROR', err)
          reject(err)
        })
    })
  },
  setStatus ({ commit, dispatch }, { id, status }) {
    return new Promise((resolve, reject) => {
      this.$edtake.mechanism.setStatus({
        id,
        body: { status }
      })
        .then((d) => {
          commit('UPDATE_ONE', d)
          resolve(d)
        })
        .catch((err) => {
          console.error('ERROR', err)
          reject(err)
        })
    })
  },
  archive ({ commit }, { id }) {
    return new Promise((resolve, reject) => {
      this.$edtake.mechanism.archive({ id })
        .then((d) => {
          commit('UPDATE_ONE', d)
          resolve(d)
        })
        .catch((err) => {
          if (err.response.status === 403) {
            reject(new Error('NOT_AUTHORIZED'))
          }
          reject(err)
        })
    })
  },
  setMembers ({ commit }, d) {
    commit('SET_MEMBERS', d)
  },
  /* Dispatch from other store */
  _addDocument ({ commit }, d) {
    commit('ADD_DOCUMENT', d)
  },
  _updateDocument ({ commit }, d) {
    commit('UPDATE_DOCUMENT', d)
  },
  _removeDocument ({ commit }, d) {
    commit('REMOVE_DOCUMENT', d)
  },
  _addProject ({ commit }, d) {
    commit('ADD_PROJECT', d)
  },
  _removeProject ({ commit }, d) {
    commit('REMOVE_PROJECT', d)
  },
  _update ({ commit }, d) {
    commit('UPDATE_ONE', d)
  }
}

export const mutations = {
  SET (state, value) {
    state.mechanisms = value
  },
  SET_MEMBERS (state, value) {
    _.forEach(value.ids, (id, $index) => {
      const item = state.mechanisms.find(item => item._id.toString() === id.toString())
      if (item) {
        Object.assign(item, { ...item, members: value.newObj[$index].members })
        state.mechanisms = [...state.mechanisms]
      }
    })
  },
  SET_CHILDREN (state, { id, children }) {
    const item = _.find(state.mechanisms, mechanism => mechanism._id === id)
    item.children = children
    state.mechanisms = [...state.mechanisms]
  },
  UPDATE_ONE (state, value) {
    const item = state.mechanisms.find(item => item._id.toString() === value._id.toString())
    if (item) {
      Object.assign(item, value)
      state.mechanisms = [...state.mechanisms] // Force refresh
    } else {
      state.mechanisms = [...state.mechanisms, value]
    }
  },
  REMOVE (state, value) {
    state.mechanisms = state.mechanisms.filter(mechanism => mechanism._id !== value)
  },
  CREATE (state, value) {
    state.mechanisms = [...state.mechanisms, value]
  },
  UPDATE_MANY (state, arr) {
    const expectedIds = _.map(arr, o => o._id)
    const filteredState = _.filter(state.mechanisms, o => !expectedIds.includes(o._id))
    arr = _.map(arr, (updatedPI) => {
      let item = state.mechanisms.find(item => (item._id && item._id.toString()) === (updatedPI._id && updatedPI._id.toString()))
      if (item) {
        Object.assign(item, updatedPI)
      } else {
        item = updatedPI
      }
      return item
    })
    state.mechanisms = [...filteredState, ...arr] // Force refresh
  },
  CLEAR (state, value) {
    state.mechanisms = []
  },
  ADD_DOCUMENT (state, value) {
    const item = state.mechanisms.find(item => item._id.toString() === value.parent._id.toString())
    if (item) {
      item.documents = [...item.documents, value]
    }
  },
  UPDATE_DOCUMENT (state, value) {
    const item = state.mechanisms.find((item) => {
      return item._id.toString() === value.parent._id.toString()
    })
    if (item) {
      const documentIdx = item.documents.findIndex(doc => doc._id === value._id)
      item.documents[documentIdx] = Object.assign({}, item.documents[documentIdx], value)
    }
  },
  REMOVE_DOCUMENT (state, value) {
    const item = state.mechanisms.find(item => item._id.toString() === value.parent._id.toString())
    if (item) {
      item.documents = item.documents.filter(d => d._id !== value._id)
    }
  },
  ADD_PROJECT (state, value) {
    const item = state.mechanisms.find(item => item._id.toString() === value.learningObject._id.toString())
    if (item) {
      item.projects = [...item.projects, value]
    }
  },
  REMOVE_PROJECT (state, value) {
    const item = state.mechanisms.find(item => item._id.toString() === value.learningObject._id.toString())
    if (item) {
      item.projects = item.projects.filter(d => d._id !== value._id)
    }
  }
}
