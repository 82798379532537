<template>
  <span>
    <span
      v-if="displayButton"
      class="pointer brownish-grey--text dull-red--text-hover"
      @click="open"
    >
      {{ $t('title') }}
    </span>
    <edtk-dialog
      size="lg"
      :value="!!opened"
      :title="$t('title')"
      @close="close()"
    >
      <template slot="content">
        <div class="d-flex flex-column mb-3" style="gap: 8px;">
          <edtk-text
            v-model="editable.name"
            :label="$t('name')"
            :placeholder="$t('name')"
            :editable="true"
            :on-error="tryValidate && $v.editable.name.$invalid"
            :error-message="$t('errors.input.REQUIRED')"
          />
        </div>
        <div class="d-flex flex-column mb-3 w-100" style="gap: 8px;">
          <edtk-rich-text-editor
            v-model="editable.description"
            :label="$t('description')"
            :placeholder="$t('description')"
            :editable="true"
          ></edtk-rich-text-editor>
          <edtk-select
            v-model="editable.category"
            :options="optionsCategory"
            :placeholder="$t('category')"
            :label="$t('category')"
            :editable="objectType !== 'deliverable'"
          />
          <div class="text-rem-10 font-weight-bold blue--text">
            {{ $t('overwriteTemplate') }}
          </div>
          <div class="d-flex">
            <v-card :class="[ overwriteTemplate ? '': 'selectedBackground', 'mx-2 my-4 pa-0']" @click="() => overwriteTemplate = false">
              <v-card-title class="blue--text d-flex justify-space-between">
                <span class="font-weight-bold">{{ $t('buttons.NO') }}</span>
              </v-card-title>
            </v-card>
            <v-card :class="[ overwriteTemplate ? 'selectedBackground': '', 'mx-2 my-4 pa-0']" @click="() => overwriteTemplate = true">
              <v-card-title class="blue--text d-flex justify-space-between">
                <span class="font-weight-bold">{{ $t('buttons.YES') }}</span>
              </v-card-title>
            </v-card>
          </div>
          <TemplatePicker v-if="overwriteTemplate" v-model="editable.templateId" :type="[objectType]" workpace-template-only return-id />
        </div>
      </template>
      <template slot="actions">
        <edtk-button
          :label="$t('buttons.CANCEL')"
          type="outline"
          class="mx-1"
          @click="close()"
        />
        <edtk-button
          :label="$t('buttons.SAVE')"
          type="filled"
          class="mx-1"
          @click="submit"
        />
      </template>
    </edtk-dialog>
  </span>
</template>

<script>
import { required } from 'vuelidate/lib/validators'
import { mapActions, mapGetters } from 'vuex'
import requiredIf from 'vuelidate/lib/validators/requiredIf'
import i18n from './i18n.js'
import TemplatePicker from '~/components/template/Picker'

export default {
  name: 'GenerateTemplateDialog',
  components: {
    TemplatePicker
  },
  props: {
    objectId: {
      type: String,
      required: true
    },
    objectType: {
      type: String,
      enums: ['project', 'deliverable', 'task', 'storyboard'],
      required: true
    },
    objectCategory: {
      type: String,
      default: () => undefined
    },
    displayButton: {
      type: Boolean,
      default: () => false
    }
  },
  i18n,
  validations: {
    editable: {
      name: { required },
      templateId: {
        required: requiredIf(function () {
          return this.overwriteTemplate
        })
      }
    }
  },
  data () {
    return {
      opened: undefined,
      editable: {
        name: undefined,
        description: '',
        category: this.objectCategory,
        tags: [],
        templateId: undefined
      },
      overwriteTemplate: false,
      tryValidate: false
    }
  },
  fetch () {
    this.templatesFetch()
  },
  computed: {
    ...mapGetters({
      templates: 'template/get',
      getCategoryByFilterType: 'template/getCategoryByFilterType'
    }),
    optionsCategory () {
      let res = []
      const categories = this.getCategoryByFilterType(this.objectType)
      res = this.$_.map(categories, (el) => {
        return { value: el, label: this.$nuxt.$te(`deliverable.category.${el}`) ? this.$t(`deliverable.category.${el}`) : el }
      })
      return res
    }
  },
  methods: {
    ...mapActions({
      projectToTemplate: 'project/toTemplate',
      templatesFetch: 'template/fetch',
      deliverableToTemplate: 'deliverable/toTemplate',
      edtakeDocumentToTemplate: 'edtakeDocument/toTemplate',
      checkErrors: 'errorForm/generateErrorList'
    }),
    async submit () {
      if (this.$v.$invalid) {
        this.tryValidate = true
        // const $t = this.$t.bind(this)
        // this.checkErrors({ validationObject: this.$v.editable, translation: $t, translationPath: 'formLabel', usePopin: true })
      } else {
        this.tryValidate = false
        switch (this.objectType) {
          case 'project':
            this.projectToTemplate({ id: this.objectId, body: { ...this.editable, type: this.objectType } }).then(() => {
              this.$swal({
                icon: 'success',
                title: '',
                html: this.$t('alert.successCreateTemplate')
              })
              this.close()
            })
            break
          case 'deliverable':
            await this.deliverableToTemplate({ id: this.objectId, body: { ...this.editable, type: this.objectType } }).then(() => {
              this.$swal({
                icon: 'success',
                title: '',
                html: this.$t('alert.successCreateTemplate')
              })
              this.close()
            })
            break
          case 'storyboard':
            await this.edtakeDocumentToTemplate({ id: this.objectId, body: { ...this.editable, type: this.objectType } }).then(() => {
              this.$swal({
                icon: 'success',
                title: '',
                html: this.$t('alert.successCreateTemplate')
              })
              this.close()
            })
            break
          default:
            break
        }
      }
    },
    open (e) {
      this.opened = true
    },
    close () {
      this.opened = undefined
      this.editable = {
        name: undefined,
        description: '',
        category: this.objectCategory,
        tags: [],
        templateId: undefined
      }
    }
  }
}
</script>
<style scoped>
.selectedBackground {
  background-color: rgba(0, 85, 138, 0.1);
}
</style>
