/* eslint-disable no-console */
import { orderBy } from 'lodash'
import enums from '../plugins/enums.plugin'

/* export const plugins = [enums] */

export const state = () => ({
  workspaces: [],
  current: undefined,
  noWorkspace: false,
  integrationsWorkspaceList: [],
  lastUpdate: undefined
})

export const getters = {
  getWorkspaces (state) {
    return orderBy(state.workspaces, (wo) => { return wo.name })
  },
  getWorkspace (state) {
    return state.current
  },
  getWorkspaceId (state) {
    return state.current._id
  },
  getNoWorkspace (state) {
    return state.noWorkspace
  },
  getWorkspaceObjectRepository (state) {
    return state.current.objectRepository
  },
  getCurrent (state) {
    return state.current
  },
  getCurrentMembers (state) {
    return state.current.members
  },
  getCurrentMembersWithSoftwareLicense (state) {
    return state.current.members.filter(m => m.license.type && m.license.type !== 'MARKETPLACE')
  },
  getCurrentGuests (state) {
    return state.current.members.filter(m => m.guest)
  },
  getCurrentId (state) {
    return state.current ? state.current._id : undefined
  },
  userIsEdtakeStaff (state, getters, rootState, rootGetters) {
    const user = rootGetters['user/get']
    const member = state.current.members.find((m) => {
      return m.user._id === user._id
    })
    return member.edtakeStaff
  },
  userCanManageOrEdit (state, getters, rootState, rootGetters) {
    const user = rootGetters['user/get']
    const member = state.current.members.find((m) => {
      return m.user._id === user._id
    })
    return member.admin
  },
  userCanManage (state, getters, rootState, rootGetters) {
    const user = rootGetters['user/get']
    const member = state.current.members.find((m) => {
      return m.user._id === user._id
    })
    return member.admin
  },
  /* SETTINGS */
  getSettingsLanguages: (state) => {
    return state.current ? state.current.languages : enums.languages
  },
  getSettingsIntegrations: (state) => {
    return state.current.settings.integrations
  },
  getSettingsAlerts: (state) => {
    return state.current.settings.alerts
  },
  getLicense: (state) => {
    return state.current.license
  },
  getIntegrationsWorkspaceList (state) {
    return state.integrationsWorkspaceList
  },
  isSubscriptionUpdatable (state) {
    let isUpdatable = false
    if (state.current.subscriptionType === 'stripe') {
      isUpdatable = true
    }
    return isUpdatable
  },
  isFeatureActivated: (state, getters, rootState, rootGetters) => (feature) => {
    const ws = state.current
    if (!ws) { return false }
    const license = ws.license

    const settingsLearningDesign = rootGetters['settings/getSettingsLearningDesign']
    const settingsProjectManagement = rootGetters['settings/getSettingsProjectManagement']

    // TODO: better check for SaaS
    const isSaasEnabled = license.type && license.type !== ''
    // const isSaasFreemium = license.type === 'FREEMIUM'
    const isSaasPremium = isSaasEnabled && ['PREMIUM', 'CUSTOM'].includes(license.type)
    const isMarketplaceEnabled = license.mp > 0 || license.ldpm > 0

    switch (feature) {
      // License base
      case 'software':
        return isSaasEnabled
      case 'marketplace':
        return isMarketplaceEnabled

      // Learning Objects access
      case 'learningDesign':
        return isSaasEnabled
      case 'mechanism':
        return isSaasEnabled && settingsLearningDesign.activateMechanismsManagement
      case 'course':
        return isSaasEnabled && settingsLearningDesign.activateCoursesManagement
      case 'courseDuplication':
        return isSaasEnabled && settingsLearningDesign.activateCoursesDuplication
      case 'courseTaxonomyForcedUse':
        return isSaasEnabled && settingsLearningDesign.coursesTaxonomy.forcedUse
      case 'courseTaxonomyVerbsLevelConstraint':
        return isSaasEnabled && settingsLearningDesign.coursesTaxonomy.verbsLevelConstraint
      case 'module':
        return isSaasEnabled
      case 'modulesImport':
        return isSaasEnabled && settingsLearningDesign.activateModulesImport
      case 'modulesDuplication':
        return isSaasEnabled && settingsLearningDesign.activateModulesDuplication
      case 'moduleTaxonomyForcedUse':
        return isSaasEnabled && settingsLearningDesign.modulesTaxonomy.forcedUse
      case 'moduleTaxonomyVerbsLevelConstraint':
        return isSaasEnabled && settingsLearningDesign.modulesTaxonomy.verbsLevelConstraint
      case 'sequence':
        return isSaasEnabled
      case 'sequencesImport':
        return isSaasEnabled && settingsLearningDesign.activateSequencesImport
      case 'sequencesDuplication':
        return isSaasEnabled && settingsLearningDesign.activateSequencesDuplication
      case 'sequenceTaxonomyForcedUse':
        return isSaasEnabled && settingsLearningDesign.sequencesTaxonomy.forcedUse
      case 'sequenceTaxonomyVerbsLevelConstraint':
        return isSaasEnabled && settingsLearningDesign.sequencesTaxonomy.verbsLevelConstraint

      // Project Management
      case 'projectManagement':
        return isSaasEnabled
      case 'advancedProjectManagement':
        return isSaasEnabled && isSaasPremium && settingsProjectManagement.useAdvancedProjectManagement

      // Document Management
      case 'documentManagement':
        return isSaasEnabled

      // Team Management
      case 'teamManagement':
        return isSaasEnabled && settingsProjectManagement.activateTeamsManagement

      // Marketplace
      case 'mission':
        return isMarketplaceEnabled

      // API
      case 'apiAccess':
        return isSaasPremium

      // Workspace
      case 'workspaceAdvancedIntegrations':
        return isSaasEnabled && isSaasPremium
      case 'workspaceLicenseManagement':
        return isSaasEnabled && license.type !== 'CUSTOM'

      default:
        return true
    }
  },
  getFreeTrialOfferWorkspace (state) {
    return state.workspaces.find(w => w.license?.allowFreeTrialStart)
  }
}

export const actions = {
  clear ({ commit }) {
    commit('CLEAR')
  },
  clearCurrent ({ commit }) {
    commit('CLEAR_CURRENT')
  },
  workspaceInit ({ commit, dispatch }, workspace) {
    dispatch('userSettings/set', { settings: 'workspace', value: workspace._id }, { root: true })
    commit('SET_WORKSPACE', workspace)
    dispatch('settings/fetchAll', null, { root: true })
    dispatch('authorization/fetchAlgoliaSecureKey', null, { root: true })
  },
  fetch ({ commit, dispatch, rootGetters, state }) {
    return new Promise((resolve, reject) => {
      this.$edtake.workspace.get()
        .then(async (d) => {
          commit('SET_WORKSPACES', d)
          if (!state.current) {
            const userSettingsWorkspace = rootGetters['userSettings/get']('workspace')
            const workspaceId = typeof userSettingsWorkspace === 'object' ? userSettingsWorkspace._id : userSettingsWorkspace
            const currentWorkspace = d.find(ws => ws._id === workspaceId)
            if (userSettingsWorkspace && currentWorkspace) {
              await dispatch('fetchOne', { id: workspaceId })
            } else if (d.length > 0 && d[0].license.subscriptionStatus !== this.$enums.stripe.subscriptionStatus.inactive) {
              await dispatch('fetchOne', { id: d[0]._id })
            } else {
              commit('NO_WORKSPACE')
            }
          }
          resolve(d)
        })
        .catch((err) => {
          console.log('ERROR', err)
          reject(err)
        })
    })
  },
  // Force is used on workspace switch
  fetchOne ({ commit, dispatch, state }, { id, force = false }) {
    // TODO : Gérer le refresh par la socket #EDTK-75
    if (force || !state.current || !state.lastUpdate || this.$dayjs().diff(state.lastUpdate, 'minute') >= 2) {
      return new Promise((resolve, reject) => {
        this.$edtake.workspace.getOne({ id })
          .then(async (d) => {
            if (d.license.subscriptionStatus !== this.$enums.stripe.subscriptionStatus.inactive) {
              await dispatch('workspaceInit', d)
            } else {
              commit('NO_WORKSPACE')
            }
            resolve(d)
          })
          .catch((err) => {
            console.log('ERROR', err)
            reject(err)
          })
      })
    } else {
      return state.current
    }
  },
  fetchDashboardSummary ({ commit, dispatch }) {
    return new Promise((resolve, reject) => {
      this.$edtake.workspace.getDashboardSummary()
        .then((d) => {
          resolve(d)
        })
        .catch((err) => {
          console.log('ERROR', err)
          reject(err)
        })
    })
  },
  fetchWorkspaceIntegration ({ state, commit }) {
    return new Promise((resolve, reject) => {
      this.$edtake.workspace.getIntegrations({ id: state.current._id })
        .then((d) => {
          commit('SET_INTEGRATIONS_WORKSPACE_LIST', d)
          resolve(d)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  workspaceAddMember ({ state, commit }, { body }) {
    return new Promise((resolve, reject) => {
      this.$edtake.workspace.grantUserPermission({ id: state.current._id, body })
        .then((d) => {
          commit('SET_MEMBERS', d)
          resolve(d)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  workspaceAddMembersBulk ({ state, commit }, { body }) {
    return new Promise((resolve, reject) => {
      this.$edtake.workspace.addMembersBulk({ id: state.current._id, body })
        .then((d) => {
          commit('SET_MEMBERS', d)
          resolve(d)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  workspaceRemoveMember ({ state, commit }, { memberId, reassignTarget = undefined }) {
    return new Promise((resolve, reject) => {
      this.$edtake.workspace.removeUser({
        id: state.current._id,
        memberId,
        body: { reassignTarget }
      })
        .then((d) => {
          commit('SET_MEMBERS', d)
          resolve(d)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  reassignOwner ({ state, commit }, { reassignTarget = undefined }) {
    return new Promise((resolve, reject) => {
      this.$edtake.workspace.reassignOwner({
        id: state.current._id,
        reassignTarget
      })
        .then((d) => {
          commit('SET_WORKSPACE', d)
          resolve(d)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  updateMember ({ state, commit }, { body }) {
    return new Promise((resolve, reject) => {
      this.$edtake.workspace.updateMember({
        id: state.current._id,
        body
      }).then((d) => {
        commit('SET_MEMBERS', d)
        resolve(d)
      }).catch((err) => {
        reject(err)
      })
    })
  },
  update ({ state, commit }, { body }) {
    return new Promise((resolve, reject) => {
      this.$edtake.workspace.update({
        id: state.current._id,
        body
      }).then((d) => {
        commit('SET_WORKSPACE', d)
        resolve(d)
      })
        .catch((err) => {
          reject(err)
        })
    })
  },
  create ({ commit, dispatch }, { body }) {
    return new Promise((resolve, reject) => {
      this.$edtake.workspace.create({
        body
      }).then((d) => {
        commit('CREATE_WORKSPACE', d.workspace)
        dispatch('user/_update', d.user, { root: true })
        resolve(d.workspace)
      })
        .catch((err) => {
          console.error('ERROR', err)
          reject(err)
        })
    })
  },
  deleteIntegration ({ state, commit, getters }, { id }) {
    return new Promise((resolve, reject) => {
      const config = { headers: { Authorization: getters.formattedApiToken } }
      this.$edtake.workspace.deleteIntegration({ id, workspaceId: state.current._id }, {
        $config: config
      }).then((d) => {
        resolve(d)
      })
        .catch((err) => {
          console.error('ERROR', err)
        })
    })
  },
  createIntegration ({ state, commit, getters }, body) {
    return new Promise((resolve, reject) => {
      const config = { headers: { Authorization: getters.formattedApiToken } }
      body.id = state.current._id
      this.$edtake.workspace.createIntegration({
        body, $config: config
      })
        .then((d) => {
          commit('SET_WORKSPACE', d)
          resolve(d)
        })
        .catch((err) => {
          console.error('ERROR', err)
        })
    })
  },
  createCustomIntegration ({ state, commit, getters }, body) {
    return new Promise((resolve, reject) => {
      const config = { headers: { Authorization: getters.formattedApiToken } }
      body.id = state.current._id
      this.$edtake.workspace.createCustomIntegration({
        body, $config: config
      })
        .then((d) => {
          commit('SET_WORKSPACE', d)
          resolve(d)
        })
        .catch((err) => {
          console.error('ERROR', err)
          reject(err)
        })
    })
  },
  addAlertSetting ({ state, commit }, { body }) {
    return new Promise((resolve, reject) => {
      this.$edtake.workspace.addAlertSetting({
        id: state.current._id,
        body
      })
        .then((d) => {
          commit('SET_SETTINGS', d.settings)
          resolve(d)
        })
        .catch((err) => {
          console.error('ERROR', err)
        })
    })
  },
  updateAlertSetting ({ state, commit }, { id, body }) {
    return new Promise((resolve, reject) => {
      this.$edtake.workspace.updateAlertSetting({
        id: state.current._id,
        alertId: id,
        body
      })
        .then((d) => {
          commit('SET_SETTINGS', d.settings)
          resolve(d)
        })
        .catch((err) => {
          console.error('ERROR', err)
        })
    })
  },
  startTrial ({ state, commit }, { id }) {
    return new Promise((resolve, reject) => {
      this.$edtake.workspace.startTrial({
        id
      })
        .then((d) => {
          commit('SET_WORKSPACE', d)
          resolve(d)
        })
        .catch((err) => {
          console.error('ERROR', err)
        })
    })
  },
  removeAlertSettingRole ({ state, commit }, { id, body }) {
    return new Promise((resolve, reject) => {
      this.$edtake.workspace.removeAlertSettingRole({
        id: state.current._id,
        alertId: id,
        body
      })
        .then((d) => {
          commit('SET_SETTINGS', d.settings)
          resolve(d)
        })
        .catch((err) => {
          console.error('ERROR', err)
        })
    })
  },
  removeAlertSetting ({ state, commit }, { id }) {
    return new Promise((resolve, reject) => {
      this.$edtake.workspace.removeAlertSetting({
        id: state.current._id,
        alertId: id
      })
        .then((d) => {
          commit('SET_SETTINGS', d.settings)
          resolve(d)
        })
        .catch((err) => {
          console.error('ERROR', err)
        })
    })
  }
}

export const mutations = {
  CREATE_WORKSPACE (state, value) {
    state.workspaces = [...state.workspaces, value]
  },
  SET_WORKSPACES (state, value) {
    state.workspaces = value
  },
  SET_WORKSPACE (state, value) {
    state.current = value
    state.noWorkspace = false
    state.lastUpdate = this.$dayjs()
    const item = state.workspaces.find(item => item._id.toString() === value._id.toString())
    if (item) {
      Object.assign(item, value)
    }
  },
  NO_WORKSPACE (state, value) {
    state.current = undefined
    state.noWorkspace = true
  },
  SET_CURRENT (state, value) {
    if (!state.current) {
      state.current = value
      state.lastUpdate = this.$dayjs()
    }
  },
  SET_MEMBERS (state, value) {
    state.current.members = value
  },
  SET_SETTINGS (state, value) {
    state.current.settings = value
  },
  // Used to force update of workspace (after subscription for example)
  SET_LASTUPDATE (state, value) {
    state.lastUpdate = value
  },
  CLEAR (state) {
    state.workspaces = []
    state.current = undefined
  },
  CLEAR_CURRENT (state) {
    state.current = undefined
  },
  SET_INTEGRATIONS_WORKSPACE_LIST (state, integrationsWorkspace) {
    state.integrationsWorkspaceList = integrationsWorkspace
  }
}
