<template>
  <v-popover
    :disabled="!editRight"
    trigger="manual"
    :open="isOpen"
    :placement="position"
    :container="containerData"
    @hide="close"
  >
    <section
      class="tooltip-target"
      :data-test="dataTest"
      @click="isOpen =! isOpen"
    >
      <slot v-if="!editRight || !isOpen" name="default" :component-data="value">
        <span v-if="!value || value.length === 0" :class="`${editRight ? 'hover-highlight' : ''}`">{{ placeholder }}</span>
        <span v-else :class="`${editRight ? 'hover-highlight' : ''}`" class="rich-editor" v-html="value"></span>
      </slot>
      <div v-else>
        <span v-if="!value || value.length === 0" :class="`${editRight ? 'hover-highlight' : ''}`">{{ placeholder }}</span>
        <span v-else :class="`${editRight ? 'hover-highlight' : ''}`" class="rich-editor" v-html="value"></span>
      </div>
    </section>
    <span
      v-if="$v.mutableModel.$error"
      class="form_error"
    >
      {{ $t('errors.input.INVALID') }} - {{ attrsInformation }}
    </span>
    <template slot="popover">
      <section>
        <h5 v-if="title" class="blue--text text-center pt-2 pb-1">
          {{ title }}
        </h5>
        <quill-editor
          ref="EditableRichEditorQuillEditor"
          v-model="mutableModel"
          :options="editorOption"
          @change="updateValue($event)"
        />
        <div class="text-center mt-2">
          <edtkButton
            :label="$t('confirm')"
            type="filled"
            @click="confirmValue"
          />
        </div>
      </section>
    </template>
  </v-popover>
</template>

<script>
import Vue from 'vue'
import VueQuillEditor, { Quill } from 'vue-quill-editor'
import { required } from 'vuelidate/lib/validators'
import i18n from './i18n.js'
import 'quill/dist/quill.core.css' // import styles
import 'quill/dist/quill.snow.css' // for snow theme
import 'quill/dist/quill.bubble.css' // for bubble theme
import QuillPlainText from '~/plugins/quill-plainText.js'
Quill.register('modules/clipboard', QuillPlainText, true) // skip blur on paste
Vue.use(VueQuillEditor /* { default global options } */)

export default {
  name: 'EditableRichEditor',
  props: {
    'data-test': {
      type: String
    },
    editRight: {
      type: Boolean,
      required: true,
      default: false
    },
    value: {
      type: String,
      default: () => undefined
    },
    containerRef: {
      type: HTMLElement
    },
    parentContainer: {
      type: Boolean,
      default: () => false
    },
    position: {
      type: [String, undefined],
      default: () => 'auto'
    },
    placeholder: {
      type: String,
      default: () => ''
    },
    title: {
      type: String,
      default: () => ''
    },
    customValidation: {
      type: Object,
      default: () => {}
    }
  },
  validations () {
    return {
      mutableModel: {
        // Adding all custom validations to the mutableModel
        ...this.customValidation ? Object.keys(this.customValidation.controls).map((key) => { return this.customValidation.controls[key] }) : {},
        required: this.$attrs.required || this.$attrs.required === '' ? required : {}
      }
    }
  },
  data () {
    return {
      editorOption: {
        modules: {
          toolbar: [
            ['bold', 'italic', 'underline', 'strike'],
            ['link'],
            [{ list: 'ordered' }, { list: 'bullet' }],
            [{ header: [1, 2, 3, 4, 5, 6, false] }],
            [{ color: [] }, { background: [] }]

          ]
        }
      },
      isOpen: false,
      containerData: undefined,
      mutableModel: this.value
    }
  },
  i18n,
  computed: {
    attrsInformation () {
      const errorMessage = []
      if ((this.$attrs.required || this.$attrs.required === '') && !this.$v.mutableModel.required) {
        errorMessage.push(`${this.$t('required')}`)
      }
      Object.keys(this.customValidation ? this.customValidation.controls : {}).forEach((key) => {
        if (!this.$v.mutableModel[key]) {
          errorMessage.push(this.customValidation.errors[key])
        }
      })

      return errorMessage.join(' - ')
    }
  },
  watch: {
    value (n, o) {
      this.mutableModel = this.value
    },
    isOpen (n) {
      if (n === true) {
        setTimeout(function () {
          this.focus()
        }.bind(this), 150)
      }
    },
    containerRef (n) {
      this.containerData = n
    }
  },
  mounted () {
    if (this.parentContainer) {
      this.containerData = this.$parent.$el
    }
  },
  beforeMount () {
    this.mutableModel = this.value
  },
  methods: {
    updateValue ({ quill, html, text }) {
      this.mutableModel = html
      this.$v.mutableModel.$touch()
    },
    confirmValue () {
      this.$v.mutableModel.$touch()
      if (!this.$v.mutableModel.$error) {
        this.$emit('input', this.mutableModel)
      }
      this.close()
    },
    close () {
      this.$v.mutableModel.$touch()
      this.isOpen = false
    },
    focus () {
      this.$nextTick(() => this.$refs.EditableRichEditorQuillEditor.$el.children[1].children[0].focus())
    }
  }
}
</script>
<style lang="scss" scoped>
.hover-highlight {
  display: block;
  border-bottom: dashed transparent 2px;
  &:hover {
    cursor: text;
    border-bottom: dashed $blue 2px;
  }
}
.rich-editor :deep(p) {
  margin-bottom: 0 !important;
}
</style>
