const i18n = {
  messages: {
    fr: {
      availableUpdate: 'Une mise à jour est disponible',
      update: 'Mettre à jour'
    },
    en: {
      availableUpdate: 'An update is available',
      update: 'Update'
    }
  }
}
module.exports = i18n
