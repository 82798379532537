export const state = () => ({
  learningContents: [],
  current: {}
})

export const getters = {
  getLearningContents: (state) => {
    return state.learningContents.sort((a, b) => a.order - b.order)
  },
  getCurrent: (state) => {
    return state.current
  }
}

export const actions = {
  fetchLearningContents ({ commit, dispatch }, { parentId }) {
    return new Promise((resolve, reject) => {
      this.$edtake.learningContent.get({ parentId })
        .then(async (d) => {
          const learningContents = d
          const learningContentsWithOptions = await dispatch('applyCustomProperties', { learningContents })
          const sortedLearningContents = learningContentsWithOptions.sort((a, b) => a.order - b.order)
          commit('SET_LEARNINGCONTENTS', sortedLearningContents)
          resolve(d)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  setCurrent ({ commit }, { id }) {
    commit('SET_CURRENT', id)
  },
  create ({ commit, dispatch }, { body }) {
    return new Promise((resolve, reject) => {
      this.$edtake.learningContent.create({ body })
        .then(async (d) => {
          const learningContents = [d]
          const learningContentsWithOptions = await dispatch('applyCustomProperties', { learningContents })
          commit('CREATE_LEARNINGCONTENT', learningContentsWithOptions[0])
          resolve(d)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  update ({ commit, dispatch }, { id, body }) {
    return new Promise((resolve, reject) => {
      this.$edtake.learningContent.update({ id, body })
        .then(async (d) => {
          const learningContents = [d]
          const learningContentsWithOptions = await dispatch('applyCustomProperties', { learningContents })
          commit('UPDATE', learningContentsWithOptions[0])
          resolve(d)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  uploadDocument ({ commit, dispatch }, item) {
    const form = new FormData()
    form.append('file', item.file, item.file.name)
    const config = {
      headers: {
        'content-type': 'multipart/form-data'
      }
    }
    return new Promise((resolve, reject) => {
      this.$edtake.learningContent.uploadFile({ form, $config: config, id: item.id })
        .then(async (d) => {
          const learningContents = [d]
          const learningContentsWithOptions = await dispatch('applyCustomProperties', { learningContents })
          commit('UPDATE', learningContentsWithOptions[0])
          resolve(d)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  reorder ({ commit, dispatch }, { body }) {
    return new Promise((resolve, reject) => {
      this.$edtake.learningContent.updateOrder({ body })
        .then(async (d) => {
          const learningContents = d
          const learningContentsWithOptions = await dispatch('applyCustomProperties', { learningContents })
          const sortedLearningContents = learningContentsWithOptions.sort((a, b) => a.order - b.order)

          commit('UPDATE_ORDER_LEARNINGCONTENTS', sortedLearningContents)
          resolve(d)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  resetCurrent ({ commit }) {
    commit('RESET_CURRENT')
  },
  delete ({ commit, dispatch }, { parentId, id }) {
    return new Promise((resolve, reject) => {
      this.$edtake.learningContent.delete({ parentId, id })
        .then(async (d) => {
          const learningContents = d
          const learningContentsWithOptions = await dispatch('applyCustomProperties', { learningContents })
          commit('SET_LEARNINGCONTENTS', learningContentsWithOptions)
          resolve(d)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  // This action apply custom properties on learningContents, as we can't access enums pluggin in mutations
  applyCustomProperties ({ rootGetters }, { learningContents }) {
    const learningContentsOptions = this.$enums.learningObject.learningActivity.defaultTypes
    const learningActivityTypes = rootGetters['settings/getSettingsLearningActivityType']
    const learningContentsWithOptions = learningContents.map((content) => {
      if (content.__t === 'customContent') {
        const customTypeOptions = learningActivityTypes.customs.find(activity => activity._id.toString() === content.customTypeId.toString())
        return {
          ...content,
          customProperties: {
            type: customTypeOptions.name,
            icon: 'handyman',
            intentionIsLearning: customTypeOptions.intention === 'learning'
          }
        }
      } else {
        const match = learningContentsOptions.find(item => item.name === content.__t)
        if (match) {
          return {
            ...content,
            customProperties: {
              icon: match.icon,
              intentionIsLearning: match.intention === 'learning'
            }
          }
        }
      }
      return content
    })
    return learningContentsWithOptions
  }
}

export const mutations = {
  SET_LEARNINGCONTENTS (state, value) {
    state.learningContents = value
    if (state.learningContents.length) {
      state.current = state.learningContents[0]
    } else {
      state.current = {}
    }
  },
  SET_CURRENT (state, value) {
    const learningContent = state.learningContents.find(content => content._id.toString() === value.toString())
    if (learningContent) {
      state.current = learningContent
    } else {
      state.current = state.learningContents[0]
    }
  },
  CREATE_LEARNINGCONTENT (state, value) {
    const learningContents = [...state.learningContents, value]
    // Reorder of the array when a learningContent is added between 2 existing activities
    state.learningContents = learningContents.sort((a, b) => {
      if (a.order !== b.order) {
        return a.order - b.order
      } else {
        return new Date(b.createdAt) - new Date(a.createdAt)
      }
    }).map((learningContent, index) => {
      if (learningContent.order !== index + 1) {
        learningContent.order = index + 1
      }
      return learningContent
    })
    state.current = value
  },
  UPDATE (state, value) {
    const learningContent = state.learningContents.find(item => item._id.toString() === value._id.toString())
    if (learningContent) {
      Object.assign(learningContent, value)
      state.learningContents = [...state.learningContents]
    } else {
      state.learningContents = [...state.learningContents, value]
    }
  },
  UPDATE_ORDER_LEARNINGCONTENTS (state, value) {
    state.learningContents = value
  },
  RESET_CURRENT (state) {
    if (state.learningContents.length) {
      state.current = state.learningContents[0]
    } else {
      state.current = {}
    }
  }
}
