export default function ({ store, redirect, route, $enums }) {
  if (/^\/w\/ld/.test(route.path)) {
    const current = store.getters['workspace/getCurrent']
    const endOfPath = route.path.match(/\/w\/ld\/(.+)/)[1]
    if (current && current._id) {
      return redirect(`/${$enums.routePrefix.workspace}/${current._id}/ld/${endOfPath}`)
    }
  } else if (/^\/w\/pm/.test(route.path)) {
    const current = store.getters['workspace/getCurrent']
    const endOfPath = route.path.match(/\/w\/pm\/(.+)/)[1]
    if (current && current._id) {
      return redirect(`/${$enums.routePrefix.workspace}/${current._id}/pm/${endOfPath}`)
    }
  } else if (/^\/w\/mp/.test(route.path)) {
    const current = store.getters['workspace/getCurrent']
    const endOfPath = route.path.match(/\/w\/mp\/(.+)/)[1]
    if (current && current._id) {
      return redirect(`/${$enums.routePrefix.workspace}/${current._id}/mp/${endOfPath}`)
    }
  }

  return store.dispatch('bulkManagement/clear', {}, { root: true })
}
