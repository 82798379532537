const isValidUrl = (urlString) => {
  try {
    return Boolean(new URL(urlString))
  } catch (e) {
    return false
  }
}

const parseJwt = (token) => {
  const base64Url = token.split('.')[1]
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/')
  const jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function (c) {
    return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
  }).join(''))

  return JSON.parse(jsonPayload)
}

const upperSnakeCasetoCamel = (s) => {
  return s.toLowerCase().replace(/([-_][a-z])/ig, (x) => {
    return x.toUpperCase()
      .replace('-', '')
      .replace('_', '')
  })
}

export default ctx => ({
  isValidUrl,
  parseJwt,
  upperSnakeCasetoCamel
})
