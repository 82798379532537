const i18n = {
  messages: {
    fr: {
      title: 'Changement de mot de passe',
      text: 'Votre mot de passe a été généré par edtake, souhaitez vous le modifier ?',
      confirmBtn: 'Changer mon mot de passe',
      cancelBtn: 'Garder mon mot de passe'
    },
    en: {
      title: 'Change your password',
      text: 'Your password has been generated by edtake, do you want to change it ?',
      confirmBtn: 'Change my password',
      cancelBtn: 'Keep my password'
    }
  }
}
module.exports = i18n
