const i18n = {
  messages: {
    fr: {
      required: 'Notation requise'

    },
    en: {
      required: 'Require rating'

    }
  }
}
module.exports = i18n
