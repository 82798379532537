<template>
  <div>
    <v-tooltip bottom>
      <template #activator="{ on, attrs }">
        <span
          style="position: absolute; top: 25px; right: 50px; z-index: 1000;"
          v-bind="attrs"
          v-on="on"
        >
          <edtk-button-icon
            class="mr-5"
            small
            color="primary"
            icon="fullscreen"
            icon-size="5xl"
            @click="displayFullscreen"
          >
          </edtk-button-icon>
        </span>
      </template>
      <span>{{ $t('displayFullscreen') }} </span>
    </v-tooltip>
    <v-tooltip bottom>
      <template #activator="{ on, attrs }">
        <span
          v-bind="attrs"
          style="position: absolute; top: 25px; right: 10px; z-index: 1000;"
          v-on="on"
        >
          <edtk-button-icon
            class="mr-5"
            small
            color="primary"
            icon="close"
            icon-size="5xl"
            @click="closePresentation"
          >
          </edtk-button-icon>
        </span>
      </template>
      <span>{{ $t('closePresentation') }} </span>
    </v-tooltip>

    <div id="reveal" class="reveal">
      <div class="slides">
        <section>{{ storyboard.name }} </section>
        <section v-for="scene in scenesToDisplay" :id="scene.id" :key="scene.id">
          <section class="text-left">
            <span class="text-h4 font-weight-light">
              {{ scene.title || $t('noTitle') }}
            </span>
            <v-img v-if="!scene.image" class="r-stretch elevation-4">
              <v-overlay absolute color="grey lighten-2">
                <v-icon x-large color="grey lighten-1">
                  mdi-camera-off
                </v-icon>
                <span class="grey--text text--lighten-1">{{ $t('noVisual') }}</span>
              </v-overlay>
            </v-img>
            <v-img v-if="scene.image" class="r-stretch elevation-4" :src="scene.image" />
          </section>
          <section v-for="action in scene.script" :key="action.id">
            <v-img v-if="!scene.image" class="r-stretch elevation-4" />
            <v-img v-if="scene.image" class="r-stretch elevation-4" style="opacity:0.5" :src="scene.image" />
            <v-overlay color="grey lighten-1" absolute>
              <div class="d-flex justify-center align-start">
                <v-avatar v-if="action.type === 'note'" color="white">
                  <v-icon color="primary" large>
                    mdi-lightbulb-on-outline
                  </v-icon>
                </v-avatar>
                <v-badge
                  v-else
                  overlap
                  bordered
                  avatar
                  light
                  color="primary"
                >
                  <template #badge>
                    <v-avatar>
                      <v-icon v-if="action.type === 'dialogue'">
                        mdi-chat-outline
                      </v-icon>
                      <v-icon v-if="action.type === 'question'">
                        mdi-chat-question-outline
                      </v-icon>
                    </v-avatar>
                  </template>
                  <v-avatar
                    size="60"
                    :color="!action.assetImage ? 'white' : ''"
                  >
                    <img v-if="action.assetImage" :src="action.assetImage">
                    <v-icon v-if="!action.assetImage" color="primary" x-large>
                      mdi-account-outline
                    </v-icon>
                  </v-avatar>
                </v-badge>
                <div>
                  <v-card
                    style="width: 400px; minHeight:100px;"
                    class="ml-3 rounded-xl pa-0"
                    color="white"
                  >
                    <v-card-text class="pb-0 d-flex">
                      <span class="primary--text text-h6">{{ action.assetName }}</span>
                    </v-card-text>
                    <v-card-text class="black--text text-subtitle-1 text-left pt-0">
                      {{ action.description }}
                    </v-card-text>
                  </v-card>
                  <v-card
                    v-for="(option, idx) in action.options"
                    :key="idx"
                    class="ml-auto rounded-lg d-flex flex-column justify-center pa-0"
                    style="width: 300px; minHeight:60px;"
                    color="white"
                    :href="`#/${option.goToScene}`"
                  >
                    <edtk-button-icon
                      v-if="option.redirection"
                      icon="arrow_split"
                      style="position: absolute; top: 5px; right: 5px; z-index: 1000;"
                    >
                    </edtk-button-icon>
                    <v-card-text class="black--text text-subtitle-1 text-left pr-5">
                      {{ option.label }}
                    </v-card-text>
                  </v-card>
                </div>
              </div>
            </v-overlay>
          </section>
        </section>
      </div>
    </div>
    <v-card
      v-for="scene in storyboard.scenes"
      v-show="false"
      :key="scene._id"
    >
      <CanvasDisplayer ref="canvasDisplayer" :scene="scene" :temporary-catalog="storyboard.catalog" @updateSceneImage="updateSceneImage" />
    </v-card>
  </div>
</template>

<script>
import Reveal from 'reveal.js'
import 'reveal.js/dist/reveal.css'
import screenfull from 'screenfull'
import { Aigle } from 'aigle'
import _ from 'lodash'
import CanvasDisplayer from '../components/canvas/components/Displayer'

Aigle.mixin(_)

export default {
  components: { CanvasDisplayer },
  props: {
    storyboard: {
      type: Object,
      default: () => {}
    }
  },
  data () {
    return {
      data: undefined
    }
  },
  computed: {
    scenesToDisplay () {
      const result = []
      this.data.scenes.forEach((scene, sceneIdx) => {
        const script = []
        scene.script.forEach((action, actionIdx) => {
          const assetObject = this.storyboard.assets.find(i => i._id === action.actionData.assetId)
          const assetImage = assetObject && assetObject.images[0] ? this.storyboard.catalog.find(el => el._id === assetObject.images[0].value) : null
          const options = action.actionData.options.map((option) => {
            // to notice if response option is a redirection to another scene and display icon
            const redirection = option.goToScene
            // if option doesn't have goToScene value > it means go to next action or next scene if it's the last action of the script
            // in reveal index is offset by 1 point from the index of an element in an array
            // link to slides is based on their slide index or id: https://revealjs.com/links/
            // to link a slide with index, I need to add 2 points to go to the next scene (sceneIdx + 2) and 1 point to stay in the same scene
            // idem for vertical slides (horizontal slide index / vertical slide index) >> `${sceneIdx + 1}/${actionIdx + 2}`
            const goToScene = option.goToScene || (actionIdx === scene.script.length - 1 ? sceneIdx + 2 : `${sceneIdx + 1}/${actionIdx + 2}`)
            return { ...option, redirection, goToScene }
          })
          script.push({
            id: action._id,
            assetName: assetObject ? assetObject.name : null,
            assetImage: assetImage ? assetImage.value : null,
            description: action.actionData.description,
            type: action.type,
            options
          })
        })
        result.push({ id: scene._id, title: scene.properties.title, image: scene.properties.image, script })
      })
      return result
    }
  },
  i18n: {
    messages: {
      fr: {
        displayFullscreen: 'Mode plein écran',
        closePresentation: 'Fermer la présentation',
        noTitle: 'Scène sans titre',
        noVisual: 'Image non disponible'
      },
      en: {
        displayFullscreen: 'Display fullscreen',
        closePresentation: 'Close presentation',
        noTitle: 'No title',
        noVisual: 'No visual'
      }
    }
  },
  beforeMount () {
    this.data = _.cloneDeep(this.storyboard)
  },
  async mounted () {
    await this.generateStagePreview()
    const deck = new Reveal()
    deck.initialize({
      embedded: true,
      progress: false
    })
    deck.on('fragmentshown', (event) => {
      event.fragment.classList.remove('fragment')
    })
  },
  methods: {
    async generateStagePreview () {
      await Aigle.forEach(this.$refs.canvasDisplayer, async (item, i) => {
        await this.$refs.canvasDisplayer[i].setSaveStage()
      })
    },
    updateSceneImage (payload) {
      const sceneIndex = this.data.scenes.findIndex(s => s._id === payload.sceneId)
      this.data.scenes[sceneIndex].properties.image = payload.image
    },
    displayFullscreen () {
      const element = document.getElementById('reveal')
      if (screenfull.isEnabled) {
        screenfull.request(element)
      }
    },
    closePresentation () {
      this.$emit('close')
    }
  }
}
</script>
<style>
@import './edtake-slides.css';
.reveal{
  height: 100vh;
  width: 100vw;
}
.display-script-overflow {
  overflow: auto;
  max-height: 600px;
  word-wrap: normal;
}
.dimbg img {
  opacity: 0.5 !important;
}
</style>
