<template>
  <v-snackbar bottom right timeout="-1" :value="updateExists" color="primary">
    {{ $t('availableUpdate') }}
    <template #action="{ attrs }">
        <edtkButton
          :label="$t('update')"
          type="text"
          color="white"
          v-bind="attrs"
          @click="refreshApp"
        />
    </template>
  </v-snackbar>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
import i18n from './i18n.js'
export default {
  data () {
    return {
      updateExists: false
    }
  },
  i18n,
  computed: {
    ...mapGetters({
      newVersion: 'serviceWorker/getNewVersion'
    })
  },
  watch: {
    newVersion (n) {
      this.newUpdate(n)
    }
  },
  mounted () {
    this.newUpdate(this.newVersion)
  },
  methods: {
    ...mapActions({
      swNewVersion: 'serviceWorker/_newVersion'
    }),
    newUpdate (n) {
      if (n) {
        this.newUpdate()
        this.updateExists = true
      }
    },
    // Called when the user accepts the update
    refreshApp () {
      this.updateExists = false
      window.location.reload()
    }
  }
}
</script>
  </v-snackbar>
</template>
