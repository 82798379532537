import summaryHelper from './summary-helper'
import countWordHelper from './count-word'

const sceneBudget = (script, costPerWord) => {
  const numberOfWords = countWordHelper.numberOfWords(script, true)
  const voiceOverBudget = numberOfWords * costPerWord
  return voiceOverBudget
}

const storyboardBudget = (assets, scenes, costPerCharacter) => {
  const totalScenesbudget = scenes.reduce((acc, curr) => {
    const budgetToAdd = curr.properties.voiceOverBudget
    return acc + budgetToAdd
  }, 0)

  const charactersList = summaryHelper.assetSummary(assets, [], scenes, false)
  const charactersBudget = Object.values(charactersList).length * costPerCharacter
  return totalScenesbudget + charactersBudget
}

export default {
  sceneBudget,
  storyboardBudget
}
