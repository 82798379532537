/* eslint-disable no-console */
import _ from 'lodash'

export const state = () => ({
  activities: []
})

export const getters = {
  getOne: state => (id) => {
    return state.activities.find(activitie => activitie._id === id)
  },
  get: (state, getters, rootState, rootGetters) => {
    const wsId = rootGetters['workspace/getCurrentId']
    return wsId ? _.filter(state.activities, (s) => { return s.workspace === wsId }) : state.activities
  }
}

export const actions = {
  update ({ dispatch, commit }, { id, body }) {
    return new Promise((resolve, reject) => {
      this.$edtake.learningActivity.update({
        id,
        body
      })
        .then((d) => {
          commit('UPDATE_ONE', d)
          resolve(d)
        })
        .catch((err) => {
          console.error('ERROR', err)
          reject(err)
        })
    })
  },
  /* Dispatch from other store */
  _update ({ commit }, d) {
    if (Array.isArray(d)) {
      _.forEach(d, e => commit('UPDATE_ONE', e))
    } else {
      commit('UPDATE_ONE', d)
    }
  }
}
export const mutations = {
  UPDATE_MANY (state, arr) {
    const expectedIds = _.map(arr, o => o._id)
    const filteredState = _.filter(state.activities, o => !expectedIds.includes(o._id))
    state.activities = [...filteredState, ...arr] // Force refresh
  },
  UPDATE_ONE (state, value) {
    const item = state.activities.find(item => (item._id && item._id.toString()) === (value._id && value._id.toString()))
    if (item) {
      Object.assign(item, value)
      state.activities = [...state.activities] // Force refresh
    } else {
      state.activities = [...state.activities, value]
    }
  }

}
