const i18n = {
  messages: {
    fr: {
      workspaces: 'Espaces de travail',
      profile: 'Profil',
      company: 'Entreprise',
      academy: 'edtake academy',
      help: 'Aide',
      disconnect: 'Déconnexion',
      trialing: 'Il vous reste {days} jours d\'essai gratuit pour cet espace de travail',
      selectWorkspace: 'Sélectionnez un espace de travail',
      startSubscription: 'Démarrer mon abonnement',
      menu: {
        home: 'Accueil',
        myMissions: 'Mes missions',
        sharedWithMe: 'Partagé avec moi',
        dashboard: 'Dashboard',
        favorites: 'Favoris',
        catalog: 'Conception pédagogique',
        mechanisms: 'Dispositifs',
        courses: 'Parcours',
        modules: 'Modules',
        sequences: 'Séquences',
        projectManagement: 'Gestion de projet',
        myProjects: 'Mes projets',
        myWorkspaceCustomers: 'Commanditaires',
        marketplace: 'Marketplace',
        missions: 'Missions',
        favoriteEdtaker: 'Freelances favoris',
        help: 'Aide'
      },
      workspace: {
        configuration: 'Configuration',
        switch: "Changer d'espace"
      }
    },
    en: {
      workspaces: 'Workspaces',
      profile: 'Profile',
      company: 'Company',
      academy: 'edtake academy',
      help: 'Help',
      disconnect: 'Logout',
      trialing: 'You have {days} days left of free trial for this workspace',
      selectWorkspace: 'Select a workspac',
      startSubscription: 'Start my subscription',
      menu: {
        home: 'Home',
        myMissions: 'My missions',
        sharedWithMe: 'Shared with me',
        dashboard: 'Dashboard',
        favorites: 'Favorites',
        catalog: 'Learning design',
        mechanisms: 'Mechanisms',
        courses: 'Courses',
        modules: 'Modules',
        sequences: 'Sequences',
        projectManagement: 'Project management',
        myProjects: 'My projects',
        myWorkspaceCustomers: 'Customers',
        marketplace: 'Marketplace',
        missions: 'Missions',
        favoriteEdtaker: 'Favourite freelancers',
        help: 'Help'
      },
      workspace: {
        configuration: 'Settings',
        switch: 'Switch workspace'
      }
    }
  }
}
module.exports = i18n
