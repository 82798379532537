const i18n = {
  messages: {
    fr: {
      addSkill: 'Ajouter une compétence principale',
      addLevel: 'Ajouter un niveau d\'expérience'
    },
    en: {
      addSkill: 'Add a main skill',
      addLevel: 'Add an experience level'
    }
  }
}

export default i18n
