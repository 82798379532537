<template>
  <section>
    <slot v-if="!editRight" name="default" :component-data="value">
      {{ value }}
    </slot>
    <template v-else>
      <component
        :is="tagType"
        v-if="!editMode"
        :key="value"
        :class="{ 'hover-highlight': true }"
        @mousedown="focus"
      >
        <slot name="default" :component-data="value">
          {{ mutableModel || placeholder }}
        </slot>
      </component>
      <component
        :is="tagType"
        v-else
        :key="value + 'edit'"
        ref="tagText"
        class="hover-highlight active"
        contenteditable="true"
        @blur="onBlur"
        @keyup="inputKeyup"
        @keypress="checkInputs"
      >
        {{ mutableModel }}
      </component>
      <span v-if="editMode && maxLength" class="caption font-italic">{{ `${$t('maxLengthAlert')} ${maxLength}` }}</span>
    </template>
    <span
      v-if="$v.mutableModel.$error"
      class="form_error"
    >
      {{ $t('errors.input.INVALID') }} - {{ attrsInformation }}
    </span>
  </section>
</template>

<script>
import { required } from 'vuelidate/lib/validators'
import i18n from './i18n.js'
export default {
  name: 'EditableText',
  props: {
    dataTest: {
      type: String
    },
    editRight: {
      type: Boolean,
      required: true,
      default: false
    },
    tagType: {
      type: String,
      default: () => 'span'
    },
    value: {
      type: String,
      default: () => undefined
    },
    placeholder: {
      type: String,
      default: () => ''
    },
    maxLength: {
      type: Number
    },
    customValidation: {
      type: Object,
      default: () => {}
    }
  },
  validations () {
    return {
      mutableModel: {
        // Adding all custom validations to the mutableModel
        ...this.customValidation ? Object.keys(this.customValidation.controls).map((key) => { return this.customValidation.controls[key] }) : {},
        required: this.$attrs.required || this.$attrs.required === '' ? required : {}
      }
    }
  },
  data () {
    return {
      editMode: false,
      mutableModel: this.value
    }
  },
  i18n,
  computed: {
    attrsInformation () {
      const errorMessage = []
      if ((this.$attrs.required || this.$attrs.required === '') && !this.$v.mutableModel.required) {
        errorMessage.push(`${this.$t('required')}`)
      }
      Object.keys(this.customValidation ? this.customValidation.controls : {}).forEach((key) => {
        if (!this.$v.mutableModel[key]) {
          errorMessage.push(this.customValidation.errors[key])
        }
      })

      return errorMessage.join(' - ')
    }
  },
  watch: {
    // Commented because it was causing a bug in firefox, may be necessary elsewhere but did not find where for now.
    // If you want to use it, please test it in firefox and chrome.
    // editMode (n) {
    //   if (n) {
    //     this.$nextTick(() => this.$refs.tagText.focus())
    //   }
    // },
    value (n, o) {
      this.mutableModel = this.value
    }
  },
  methods: {
    onBlur (e) {
      this.$parent.$emit('blur')
      this.UpdateMutableModel(e)
    },
    UpdateMutableModel (e) {
      this.editMode = false
      if (this.mutableModel !== e.target.innerText) {
        this.mutableModel = e.target.innerText
        this.$v.mutableModel.$touch()
        if (!this.$v.mutableModel.$error) {
          this.$emit('input', this.mutableModel)
        }
      }
    },
    inputKeyup (e) {
      this.$parent.$emit('keyup', e)
      if (e.key === 'Enter' || e.keyCode === 13 || e.key === 'Escape' || e.keyCode === 27) {
        this.$nextTick(() => this.$refs.tagText.blur())
        this.editMode = false
      }
    },
    checkInputs (e) {
      if (this.maxLength) {
        if (e.target.innerText.length >= this.maxLength) {
          e.preventDefault()
        }
      }
      if (e.key === 'Enter' || e.keyCode === 13) {
        e.preventDefault()
      }
    },
    focus () {
      this.editMode = true
      setTimeout(() => {
        this.$refs.tagText.focus()
      }, 10)
    }
  }
}
</script>
<style lang="scss" scoped>
.hover-highlight {
  display: block;
  border-bottom: dashed transparent 2px;
  &:hover {
    cursor: text;
    border-bottom: dashed $blue 2px;
  }
  &.active {
    border-bottom: solid $blue 1px;
  }
}
</style>
