const enums = {
  routePrefix: {
    workspace: 'w'
  },
  appPrefix: {
    projectManagement: 'pm',
    marketplace: 'mp',
    learningDesign: 'ld',
    mapping: {
      mechanism: 'ld',
      course: 'ld',
      module: 'ld',
      sequence: 'ld',
      project: 'pm',
      deliverable: 'pm',
      task: 'pm',
      mission: 'mp'
    }
  },
  onboarding: {
    edtakeUsage: ['learningDesign', 'projectManagement', 'marketplace'],
    workspaceTypes: [
      { value: 'freelance', icon: 'account_circle' },
      { value: 'company', icon: 'apartment' },
      { value: 'trainingOrganization', icon: 'school' },
      { value: 'agency', icon: 'supervisor_account' }
    ]
  },
  mission: {
    hosts: ['EDTAKE_CORE', 'EDTAKE_FACTORY'],
    searchStatus: ['DRAFT', 'EDTAKE_TO_VALIDATE', 'SEARCHING_EDTAKER', 'PROPOSALS', 'WAITING_START', 'IN_PROGRESS', 'TO_VALIDATE', 'CLOSED', 'CANCELED'],
    proposalUserStatus: ['PENDING', 'ACCEPTED', 'REJECTED'],
    proposalStatus: ['OPEN', 'CLOSED', 'CANCELED'],
    status: ['OPEN', 'CLOSED', 'CANCELED'],
    closureReason: ['', 'ACCEPTED OTHER PROPOSAL', 'CANCELATION', 'REJECTION']
  },
  delivery: {
    statuses: ['PENDING', 'IN_PROGRESS', 'DONE'],
    status: {
      PENDING: 'PENDING',
      IN_PROGRESS: 'IN_PROGRESS',
      DONE: 'DONE'
    },
    iteration: {
      statuses: ['TO_REVIEW', 'DONE'],
      status: {
        TO_REVIEW: 'TO_REVIEW',
        DONE: 'DONE'
      }
    }
  },
  phase: {
    statuses: ['PENDING', 'DONE'],
    status: {
      PENDING: 'PENDING',
      DONE: 'DONE'
    }
  },
  user: {
    types: [
      'COMPANY',
      'EDTAKER_FREE',
      'EDTAKER_AGENCY'
    ],
    edtakerTypes: [
      'EDTAKER_FREE',
      'EDTAKER_AGENCY'
    ],
    industries: [
      'FOOD',
      'WOOD_PAPER',
      'CHEMISTRY',
      'COMMUNICATION',
      'CONSULTING',
      'COMPUTING',
      'METALLURGY',
      'CORPORATE_SERVICES',
      'BANK_INSURANCE',
      'CONSTRUCTION',
      'TRADING',
      'ELECTRONIC',
      'PHARMACEUTICAL',
      'MACHINERY',
      'CLOTHES',
      'LOGISTICS'
    ],
    roles: [
      'USER',
      'APPLICATION',
      'EDTAKE',
      'EDTAKE_ADMIN'
    ]
  },
  company: {
    status: [
      'TO VALIDATE',
      'ACTIVE',
      'INACTIVE'
    ],
    memberAccess: [
      'COMPANY_MANAGER',
      'PROJECT_MANAGER',
      'USER'
    ],
    memberStatus: [
      'ACTIVE',
      'INACTIVE'
    ],
    memberTypes: [
      'INTERNAL',
      'EXTERNAL'
    ]
  },
  lead: {
    status: [
      'PENDING',
      'OPEN',
      'ACCEPTED',
      'REJECTED',
      'CLOSED'
    ]
  },
  deliverable: {
    phase: [
      'CADRAGE',
      'CONCEPTION',
      'PRODUCTION',
      'VALIDATION'
    ],
    category: [
      'Management',
      'Pedagogy',
      'Writing',
      '2D Graphic Design',
      '2D Animation',
      '3D Graphic Design',
      '3D Animation',
      'Video',
      'Sound & Music',
      'Authoring tool',
      'Translation',
      'Marketing',
      'Web & Mobile Design',
      'Development',
      'LMS',
      'Test',
      'Training',
      'Pitchboy',
      'Other'
    ],
    byCategory: {
      Management: {
        deliverables: false
      },
      Pedagogy: {
        deliverables: [
          'instructional design',
          'instructional objectives',
          'synopsis',
          'sequences & interactions',
          'game design'
        ]
      },
      Writing: {
        deliverables: [
          'storyboard/script'
        ]
      },
      'LMS Support': {
        deliverables: false
      },
      '2D Graphic Design': {
        deliverables: [
          '2d graphic charter',
          '2d character design',
          '2d character production',
          '2d character rigging',
          'motion design',
          '2d environment design',
          '2d object design',
          'brief photos',
          'photos',
          'presentation design',
          'illustration'
        ]
      },
      '2D Animation': {
        deliverables: [
          '2d character animation',
          '2d object animation',
          '2d environment animation'
        ]
      },
      '3D Graphic Design': {
        deliverables: [
          '3d graphic charter',
          '3d character design',
          '3d environment design',
          '3d object design',
          '3d character modeling',
          '3d character rigging',
          '3d environment modeling',
          '3d object modeling',
          'vr & ar design'
        ]
      },
      '3D Animation': {
        deliverables: [
          '3d character animation',
          '3d object animation',
          '3d environment animation'
        ]
      },
      'Authoring tool': {
        deliverables: [
          'mounting beta version',
          'mounting initial version',
          'mounting translated version',
          'mounting on authoring tool'
        ]
      },
      'Sound & Music': {
        deliverables: [
          'voice production',
          'sound effects',
          'voice post-production'
        ]
      },
      Video: {
        deliverables: [
          'voice doubling',
          'voice-over',
          'film editing',
          'video subtitle'
        ]
      },
      Marketing: {
        deliverables: false
      },
      Translation: {
        deliverables: [
          'text translation'
        ]
      },
      'Web & Mobile Design': {
        deliverables: false
      },
      Development: {
        deliverables: false
      },
      Training: {
        deliverables: [
          'training evaluation'
        ]
      }
    },
    status: [
      'DRAFT',
      'WAITING_ASSIGNEE',
      'TO_DO',
      'IN_PROGRESS',
      'TO_REVIEW',
      'TO_CORRECT',
      'DONE',
      'CANCELED',
      'PAUSE'
    ]
  },
  skills: [{
    skill: 'Management',
    subskills: [
      'Project Management',
      'Product Management'
    ]
  }, {
    skill: 'Pedagogy',
    subskills: [
      'Instructional designer',
      'Game Design',
      'Psychometric Analysis',
      'Certification Management',
      'Training designer'
    ]
  },
  {
    skill: 'Writing',
    subskills: [
      'Editing & Proofreading',
      'Content Writing',
      'Creative Writing',
      'Copywriting',
      'Grant Writing',
      'Technical Writing',
      'Business Writing'
    ]
  }, {
    skill: 'LMS Support',
    subskills: [
      'LMS Hosting',
      'LMS Administration'
    ]
  },
  {
    skill: '2D Graphic Design',
    subskills: [
      'Brand Identity Design',
      '2D Character Design',
      '2D Object Design',
      '2D Environment Design',
      'Presentation Design',
      'Art Direction',
      'Motion Graphics Design',
      'Cartoonist',
      'Illustration'
    ]
  },
  {
    skill: '2D Animation',
    subskills: [
      '2D Character Rigging',
      '2D Character Animation',
      '2D Object Animation',
      '2D Environment Animation'
    ]
  },
  {
    skill: '3D Graphic Design',
    subskills: [
      '3D Character Design',
      '3D Object Design',
      '3D Environment Design',
      'UV Mapping',
      '3D Character Modeling',
      '3D Object Modeling',
      '3D Environment Modeling',
      '3D Level Design',
      'VR & AR Design'
    ]
  },
  {
    skill: '3D Animation',
    subskills: [
      '3D Character Rigging',
      '3D Character Animation',
      '3D Object Animation',
      '3D Environment Animation'
    ]
  },
  {
    skill: 'Authoring tool',
    subskills: [
      'Creation using authoring tool',
      'MOOC/SPOC Editing'
    ]
  },
  {
    skill: 'Sound & Music',
    subskills: [
      'Voice Talent',
      'Musician',
      'Music Composition',
      'Music Production',
      'Audio Production',
      'Audio Editing/Post-Production'
    ]
  },
  {
    skill: 'Video',
    subskills: [
      'Actor',
      'Videographer',
      'Scriptwriting',
      'Video Production',
      'Video Editing/Post-Production',
      'Voice Doubling',
      'Subtitling'
    ]
  },
  {
    skill: 'Marketing',
    subskills: [
      'Brand Strategy',
      'Content Strategy',
      'Marketing Strategy',
      'Social Media Strategy',
      'Social Media Marketing',
      'Community Management',
      'Email Marketing',
      'Marketing Automation',
      'Digital Marketing',
      'Campaign Management',
      'Search Engine Optimization',
      'Search Engine Marketing'
    ]
  },
  {
    skill: 'Translation',
    subskills: [
      'Legal Translation',
      'Technical Translation',
      'Translation',
      'Language Localization',
      'Medical Translation'
    ]
  },
  {
    skill: 'Web & Mobile Design',
    subskills: [
      'Prototyping',
      'Mobile Design',
      'Web Design',
      'UX/UI Design'
    ]
  },
  {
    skill: 'Development',
    subskills: [
      'Software Development',
      'Scripting & Automation',
      'Mobile App Development',
      'Mobile Game Development',
      'AR/VR Development',
      'Database Development',
      'Back-End Development',
      'CMS Customization',
      'Front-End Development',
      'Full Stack Development',
      'CMS Development',
      'Game Development'
    ]
  },
  {
    skill: 'Training',
    subskills: [
      'Trainer',
      'Webinar training',
      'Face-to-face training',
      'Training Analytics',
      'Training Evaluation'
    ]
  }
  ],
  authoring: [
    '360Learning',
    'Adapt',
    'Articulate',
    'Beedeez',
    'Captivate',
    'Cross Knowledge',
    'Docebo',
    'Dominknow',
    'Easygenerator',
    'Edoceo',
    'Elucidat',
    'Gomo',
    'H5P',
    'Itycom',
    'Kumullus',
    'Lectora',
    'Mohive',
    'OuiLearn',
    'RiseUp',
    'SAP SuccessFactors Learning',
    'Sparted',
    'Storyline',
    'Teach on Mars',
    'VTS'
  ],
  lms: [
    '360Learning',
    'Cornerstone',
    'Cross Knowledge',
    'Digital University',
    'Docebo',
    'FuturSkill',
    'Moodle',
    'MindOnSite',
    'RiseUp',
    'SAP SuccessFactors Learning',
    'Syfadis',
    'Talentsoft',
    'Teach on Mars'
  ],
  tags: [
    '.NET Framework',
    '123Design',
    '360Learning',
    '3D Coat',
    '3D Slash',
    '3Ds Max',
    'Adapt',
    'Adobe Animate',
    'Adobe Director',
    'Adobe Illustrator',
    'Adobe InDesign',
    'Adobe Photoshop',
    'Adobe Premiere Elements',
    'Adobe Premiere Pro',
    'Affinity Designer',
    'After Effects',
    'Android',
    'Angular JS',
    'Apple Final Cut',
    'Apple iMovie',
    'Apple Motion',
    'Articulate',
    'ArtRage',
    'Artweaver 7',
    'ASP.NET',
    'Autodesk',
    'Autodesk Motion Builder',
    'Babylon JS',
    'Back Ink',
    'Beedeez',
    'Blender',
    'Brash Monkey',
    'C#',
    'CakePHP',
    'Captivate',
    'Carrara',
    'CATIA',
    'CelAction 2D',
    'Cheetah 3D',
    'Cinema 4D',
    'Clip Studio Paint',
    'Conerstone',
    'Cordova',
    'CorelDraw',
    'CreaToon',
    'CSS',
    'DaVinci Resolve',
    'Daz Studio',
    'DesignSpark',
    'Digital University',
    'Dominknow',
    'Dragon frame',
    'Easygenerator',
    'Edoceo',
    'Elucidat',
    'FlipBook',
    'FreeCAD',
    'Fusion 8',
    'FuturSkill',
    'GIMP',
    'Gomo',
    'Google Slide',
    'H5P',
    'Hexagon',
    'HitFilm Express',
    'Houdini',
    'HTML',
    'Inventor',
    'Ionic',
    'iOS',
    'Itycom',
    'Javascript',
    'K3D',
    'Krita',
    'Kumullus',
    'Laravel',
    'Lectora',
    'Lightwave 3D',
    'Lightworks',
    'Magix Movie Edit',
    'Maya',
    'Microsoft Office',
    'Mixamo',
    'Mocha AE',
    'Modo',
    'Mohive',
    'Moho',
    'Moodle',
    'MindOnSite',
    'MongoDB',
    'Motion Capture',
    'Movavi',
    'Mudbox',
    'MySQL',
    'Nero Video',
    'Node JS',
    'Nuke',
    'OpenSCAD',
    'Openshot',
    'Opentoonz',
    'OuiLearn',
    'Paintstorm Studio',
    'Pencil2D',
    'Phonegap',
    'PHP',
    'Pinnacle Studio',
    'Poser',
    'Powerpoint',
    'Powtoon',
    'Prezi',
    'Procreate',
    'Python',
    'React JS',
    'React Native',
    'Rebelle',
    'Rhino 3D',
    'RiseUp',
    'Rocket 3F',
    'SAP BW',
    'SAP CATS',
    'SAP CLM',
    'SAP CO-OM',
    'SAP CO-PA',
    'SAP CO-PC',
    'SAP CO-PCA',
    'SAP CS',
    'SAP EH&S',
    'SAP Environment Management',
    'SAP FI',
    'SAP IM',
    'SAP LES',
    'SAP MM',
    'SAP PA',
    'SAP PD',
    'SAP PLM',
    'SAP PM',
    'SAP PP',
    'SAP PS',
    'SAP QM',
    'SAP Risk Management',
    'SAP SD',
    'SAP SuccessFactors Learning',
    'SAP TR',
    'SAP WM',
    'SCORM',
    'Sculptris',
    'Shape Designer',
    'Shotcut',
    'SketchUP',
    'SolidWorks',
    'Sparted',
    'Spine',
    'Splice',
    'Spriter',
    'SQL',
    'SQL Server',
    'Stop Motion',
    'Storyline',
    'Strata',
    'Substance 3D',
    'Symphony',
    'Synfig Studio',
    'Syntheyes',
    'Talentsoft',
    'Teach on Mars',
    'The Trapcode Suite',
    'Three JS',
    'TinkerCAD',
    'Toon Boom Harmony',
    'Tupi',
    'Ultimate Unwrap 3D',
    'Unfold 3D',
    'Unity 3D',
    'Unreal Engine',
    'UV Layout',
    'UV mapper',
    'VideoPad',
    'VTS',
    'Vue JS',
    'Vue Native',
    'Wings 3D',
    'Wordpress',
    'Zbrush'
  ],
  learningObject: {
    status: ['cadrage', 'conception', 'production', 'review', 'ready', 'distribution'],
    nextStatus: {
      cadrage: ['conception'],
      conception: ['cadrage', 'production'],
      production: ['conception', 'review'],
      review: ['production', 'ready'],
      ready: ['review', 'distribution'],
      distribution: ['ready']
    },
    statusColor: {
      cadrage: '#543762',
      conception: '#FAA126',
      production: '#FF6767',
      review: '#8B8EE5',
      ready: '#4FBFB6',
      distribution: '#4FBFB6'
    },
    course: {},
    module: {
      activityTypes: [
        'diagnostic',
        'harmonisation',
        'introduction',
        'awareness',
        'learning',
        'coaching',
        'exercise',
        'experience',
        'evaluation',
        'feedback',
        'synthesis',
        'postTrainingFollowUp'
      ],
      defaultTypes: [
        'eLearning specific',
        'eLearning on shelf',
        'eDoing',
        'mooc on shelf',
        'online course',
        'video learning',
        'rapid learning',
        'mobile learning',
        'webinar',
        'virtual classroom',
        'serious game',
        'virtual reality',
        'face-to-face',
        'mentoring',
        'other'
      ]
    },
    sequence: {
      activityTypes: [
        'introduction',
        'learning',
        'coaching',
        'exercise',
        'experience',
        'evaluation',
        'feedback',
        'synthesis',
        'postTrainingFollowUp'
      ],
      defaultTypes: [
        'eLearning specific',
        'eLearning on shelf',
        'eDoing',
        'mooc on shelf',
        'online course',
        'video learning',
        'rapid learning',
        'mobile learning',
        'webinar',
        'virtual classroom',
        'serious game',
        'virtual reality',
        'face-to-face',
        'mentoring',
        'other'
      ]
    },
    learningActivity: {
      defaultTypes: [
        { name: 'readDocument', intention: 'learning', estimatedDuration: 30, icon: 'export_notes' },
        { name: 'watchVideo', intention: 'learning', estimatedDuration: 30, icon: 'smart_display' },
        { name: 'giveFeedback', intention: 'feedback', estimatedDuration: 60, icon: 'rate_review' },
        { name: 'listenPodcast', intention: 'learning', estimatedDuration: 30, icon: 'podcasts' },
        { name: 'selectMCQ', intention: 'exercise', estimatedDuration: 30, icon: 'library_add_check' },
        { name: 'selectSCQ', intention: 'exercise', estimatedDuration: 30, icon: 'check_box' },
        { name: 'selectTrueFalse', intention: 'exercise', estimatedDuration: 30, icon: 'toggle_off' },
        { name: 'fillInTheBlanks', intention: 'exercise', estimatedDuration: 60, icon: 'fit_width' },
        { name: 'playRole', intention: 'experimentation', estimatedDuration: 60, icon: 'playing_cards' },
        { name: 'resolveSimpleExercise', intention: 'exercise', estimatedDuration: 30, icon: 'short_text' },
        { name: 'resolveComplexExercise', intention: 'exercise', estimatedDuration: 60, icon: 'notes' },
        { name: 'associateProposals', intention: 'exercise', estimatedDuration: 60, icon: 'leak_add' },
        { name: 'readRichText', intention: 'learning', estimatedDuration: 30, icon: 'article' },
        { name: 'reorderProposals', intention: 'exercise', estimatedDuration: 30, icon: 'low_priority' },
        { name: 'writeTextInput', intention: 'exercise', estimatedDuration: 60, icon: 'reorder' }
      ],
      intentions: [
        'introduction',
        'awareness',
        'learning',
        'coaching',
        'exercise',
        'experimentation',
        'evaluation',
        'feedback',
        'synthesis',
        'postTrainingFollowUp'
      ]
    },
    // TODO: default activities types
    activityTypes: [
      'diagnostic',
      'harmonisation',
      'introduction',
      'learning',
      'coaching',
      'exercise',
      'experience',
      'evaluation',
      'synthesis',
      'postTrainingFollowUp'
    ]
  },
  // TODO: clean sequenceModality unused
  sequenceModality: [
    'eLearning specific',
    'eLearning on shelf',
    'eDoing',
    'mooc on shelf',
    'online course',
    'video learning',
    'rapid learning',
    'mobile learning',
    'webinar',
    'virtual classroom',
    'serious game',
    'virtual reality',
    'face-to-face',
    'mentoring',
    'other'
  ],
  course: {
    status: [
      'CADRAGE',
      'CONCEPTION',
      'PRODUCTION',
      'VALIDATION',
      'CLOSURE',
      'ARCHIVE'
    ],
    mechanism: [
      'eLearning',
      'mono module',
      'mooc',
      'spoc',
      'blended learning',
      'other'
    ],
    trainingConstraints: {
      skillType: [
        'softSkill',
        'companyTechnics',
        'companyJob',
        'transversalCompanyRequired',
        'transversalRequired',
        'transversal',
        'hardSkill',
        'initiation',
        'languages'
      ],
      publicSize: ['lt100', 'lt500', 'gt500', 'gt1000'],
      publicAvailability: ['strong', 'weak'],
      deploymentTiming: ['1m', '2m', '3m', '6m'],
      deviceAccess: ['yes', 'partially', 'no'],
      bandwidth: ['512kbps', '1,5Mbps', '2,5Mbps'],
      deviceSound: ['yes', 'no'],
      deviceStandard: ['yes', 'no'],
      trainingTracking: ['yes', 'no'],
      lmsRequired: ['yes', 'no'],
      trainingContentUpdate: ['yes', 'no']
    },
    module: {
      type: [
        'eLearning specific',
        'eLearning on shelf',
        'eDoing',
        'mooc on shelf',
        'online course',
        'video learning',
        'rapid learning',
        'mobile learning',
        'webinar',
        'virtual classroom',
        'serious game',
        'virtual reality',
        'face-to-face',
        'mentoring',
        'other'
      ],
      status: [
        'CADRAGE',
        'CONCEPTION',
        'PRODUCTION',
        'VALIDATION',
        'CLOSURE',
        'ARCHIVE'
      ]
    }
  },
  traineeProgress: ['sequential', 'free'],
  countries: [
    'AFG',
    'ALB',
    'DZA',
    'AND',
    'AGO',
    'ATG',
    'ARG',
    'ARM',
    'AUS',
    'AUT',
    'AZE',
    'BHS',
    'BHR',
    'BGD',
    'BRB',
    'BLR',
    'BEL',
    'BLZ',
    'BEN',
    'BTN',
    'BOL',
    'BIH',
    'BWA',
    'BRA',
    'BRN',
    'BGR',
    'BFA',
    'BDI',
    'CPV',
    'KHM',
    'CMR',
    'CAN',
    'CAF',
    'TCD',
    'CHL',
    'CHN',
    'COL',
    'COM',
    'COG',
    'COK',
    'CRI',
    'HRV',
    'CUB',
    'CYP',
    'CZE',
    'CIV',
    'PRK',
    'COD',
    'DNK',
    'DJI',
    'DMA',
    'DOM',
    'ECU',
    'EGY',
    'SLV',
    'GNQ',
    'ERI',
    'EST',
    'SWZ',
    'ETH',
    'FRO',
    'FJI',
    'FIN',
    'FRA',
    'GAB',
    'GMB',
    'GEO',
    'DEU',
    'GHA',
    'GRC',
    'GRD',
    'GTM',
    'GIN',
    'GNB',
    'GUY',
    'HTI',
    'HND',
    'HUN',
    'ISL',
    'IND',
    'IDN',
    'IRN',
    'IRQ',
    'IRL',
    'ISR',
    'ITA',
    'JAM',
    'JPN',
    'JOR',
    'KAZ',
    'KEN',
    'KIR',
    'KWT',
    'KGZ',
    'LAO',
    'LVA',
    'LBN',
    'LSO',
    'LBR',
    'LBY',
    'LTU',
    'LUX',
    'MDG',
    'MWI',
    'MYS',
    'MDV',
    'MLI',
    'MLT',
    'MHL',
    'MRT',
    'MUS',
    'MEX',
    'FSM',
    'MCO',
    'MNG',
    'MNE',
    'MAR',
    'MOZ',
    'MMR',
    'NAM',
    'NRU',
    'NPL',
    'NLD',
    'NZL',
    'NIC',
    'NER',
    'NGA',
    'NIU',
    'MKD',
    'NOR',
    'OMN',
    'PAK',
    'PLW',
    'PAN',
    'PNG',
    'PRY',
    'PER',
    'PHL',
    'POL',
    'PRT',
    'QAT',
    'KOR',
    'MDA',
    'ROU',
    'RUS',
    'RWA',
    'KNA',
    'LCA',
    'VCT',
    'WSM',
    'SMR',
    'STP',
    'SAU',
    'SEN',
    'SRB',
    'SYC',
    'SLE',
    'SGP',
    'SVK',
    'SVN',
    'SLB',
    'SOM',
    'ZAF',
    'SSD',
    'ESP',
    'LKA',
    'SDN',
    'SUR',
    'SWE',
    'CHE',
    'SYR',
    'TJK',
    'THA',
    'TLS',
    'TGO',
    'TKL',
    'TON',
    'TTO',
    'TUN',
    'TUR',
    'TKM',
    'TUV',
    'UGA',
    'UKR',
    'ARE',
    'GBR',
    'TZA',
    'USA',
    'URY',
    'UZB',
    'VUT',
    'VEN',
    'VNM',
    'YEM',
    'ZMB',
    'ZWE'
  ],
  languages: [
    'ar',
    'be',
    'bg',
    'bn',
    'br',
    'bs',
    'co',
    'cs',
    'da',
    'de',
    'el',
    'en',
    'eo',
    'es',
    'et',
    'ff',
    'fi',
    'fr',
    'he',
    'hi',
    'hr',
    'hu',
    'hy',
    'id',
    'is',
    'it',
    'ja',
    'jv',
    'ko',
    'lt',
    'lv',
    'mg',
    'nl',
    'no',
    'pl',
    'pt',
    'ro',
    'ru',
    'sa',
    'sk',
    'sq',
    'sr',
    'su',
    'sv',
    'sw',
    'th',
    'tr',
    'ty',
    'uk',
    'vi',
    'wa',
    'zh'
  ],
  statusStage: ['Cadrage', 'Conception', 'Production', 'Review', 'Delivery', 'Done'],
  RACI: [
    'responsible',
    'accountable',
    'consulted',
    'informed'
  ],
  workspace: {
    settings: {
      progressCalculMode: ['children', 'statusPercentage']
    }
  },
  mdi: {
    tree: 'mdi-file-tree',
    course: 'mdi-map-marker-path',
    module: 'mdi-archive',
    deliverable: 'mdi-package-variant-closed',
    phase: 'mdi-table-sync',
    task: 'mdi-format-list-checks',
    learningActivity: 'mdi-gesture-tap',
    project: 'mdi-sitemap',
    sequence: 'mdi-wrench',
    chevronDown: 'mdi-chevron-down',
    chevronUp: 'mdi-chevron-up',
    chevronRight: 'mdi-chevron-right',
    chevronLeft: 'mdi-chevron-left',
    arrowUp: 'mdi-arrow-up',
    target: 'mdi-target-account',
    mechanism: 'mdi-folder-table-outline',
    arrowDown: 'mdi-arrow-down'
  },
  edtakeIcons: {
    'draft.io': 'draftio.jpg',
    figma: 'figma.svg',
    canva: 'canva.svg',
    whimsical: 'whimsical.svg',
    storyboard: 'edtake-icon-outline-storyboard.svg',
    richDocument: 'edtake-icon-outline-rich-document.svg'
  },
  materialIcons: {
    ld: 'home_storage',
    pm: 'view_timeline',
    mp: 'storefront',
    project: 'snippet_folder',
    deliverable: 'package',
    task: 'list',
    mechanism: 'snippet_folder',
    course: 'conversion_path',
    module: 'inventory_2',
    sequence: 'widgets',
    arrowBack: 'arrow_back_ios_new',
    mission: 'assignment',
    proposal: 'card_travel'
  },
  pedagogicalApproach: [
    'digital',
    'on-site',
    'blended',
    'augmented-on-site'
  ],
  lmsTypes: {
    riseup: {
      module: ['internal', 'partner'],
      sequence: ['online', 'physical'],
      learningActivity: ['video', 'document', 'quiz', 'text', 'exam', 'iframe', 'survey', 'webcast', 'scorm', 'ubicast', 'screencast_omatic', 'fileupload']
    }
  },
  alertsKeys: {
    ld: {
      course: [{ key: 'noDescription', hasDelay: false }, { key: 'noRealisationAssignee', hasDelay: false }],
      module: [{ key: 'noDescription', hasDelay: false }, { key: 'noRealisationAssignee', hasDelay: false }],
      sequence: [{ key: 'noDescription', hasDelay: false }, { key: 'noRealisationAssignee', hasDelay: false }]
    },
    pm: {
      project: [{ key: 'noDescription', hasDelay: true }, { key: 'noRealisationAssignee', hasDelay: true }, { key: 'startDateBeforeChildrenStartDate', hasDelay: false }, { key: 'dueDateBeforeChildrenDueDate', hasDelay: false }, { key: 'latePredecessor', hasDelay: false }, { key: 'predecessorDueDateLaterThanStartDate', hasDelay: false }],
      deliverable: [{ key: 'noDescription', hasDelay: true }, { key: 'noRealisationAssignee', hasDelay: true }, { key: 'dueDateBeforeChildrenDueDate', hasDelay: false }, { key: 'impossibleWorkload', hasDelay: false }, { key: 'startDateBeforeChildrenStartDate', hasDelay: false }, { key: 'latePredecessor', hasDelay: false }, { key: 'predecessorDueDateLaterThanStartDate', hasDelay: false }],
      task: [{ key: 'latePredecessor', hasDelay: false }, { key: 'predecessorDueDateLaterThanStartDate', hasDelay: false }]
    }
  },
  stripe: {
    subscriptionStatus: {
      active: 'ACTIVE',
      waiting_for_payment: 'WAITING_FOR_PAYMENT',
      inactive: 'INACTIVE',
      trialing: 'TRIALING'
    }
  },
  httpErrorBodies: {
    upgrade_license: 'Please upgrade your license to access this feature.',
    user_not_found: 'User not found.',
    duplicated_email: 'Email already exists.',
    duplicated_user: 'User already exists.',
    admin_required: 'At least one admin is required.'
  }
}

export default enums
