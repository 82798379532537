export default async function ({ store, redirect, route, app }) {
  /* Ignored routes for authenticated middleware */
  if (
    /^\/login/.test(route.path) ||
    /^\/logout/.test(route.path) ||
    /^\/auth/.test(route.path) ||
    /^\/api/.test(route.path) ||
    /^\/public/.test(route.path) ||
    /^\/sdk-proxy/.test(route.path) ||
    /^\/.auth/.test(route.path) ||
    /^\/signup/.test(route.path)
  ) {
    return
  }

  if (Object.keys(store.getters['user/get']).length === 0) {
    if (app.$auth && app.$auth.user && (app.$auth.user.email || app.$auth.user.emails)) {
      await store.dispatch('user/fetchProfile')
    } else {
      app.$auth.logout()
    }
  }

  /* Special redirection use cases */
  if (!Object.keys(route.query).includes('userOnboarding') && !store?.state?.user?.user?.onboarding?.newUserOnboarding) {
    return redirect('/?userOnboarding')
  }
  /* Special redirection use case to handle stripe return */
  if (Object.keys(route.query).includes('userOnboarding') && store?.state?.user?.user?.onboarding?.newUserOnboarding) {
    return redirect('/')
  }
}
