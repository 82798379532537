const i18n = {
  messages: {
    fr: {
      minValue: 'Cette valeur doit être supérieure ou égale à ',
      maxValue: 'Cette valeur doit être inférieure ou égale à ',
      step: 'Intervalle autorisé ',
      required: 'Nombre requis'
    },
    en: {
      minValue: 'This value must be greater than or equal to ',
      maxValue: 'This value must be less than or equal to ',
      step: 'Allowed step ',
      required: 'Require a number'
    }
  }
}
module.exports = i18n
