/* eslint-disable prefer-promise-reject-errors */
/* eslint-disable no-console */
/* eslint-disable camelcase */

const snapshotSchema = {
  _id: String,
  name: String,
  private: Boolean,
  resourceType: String,
  resourceId: String,
  createdBy: String,
  createdAt: String,
  archived: Boolean,
  content: Object
}

export const actions = {
  fetch ({ dispatch, commit }, { resourceId, resourceType }) {
    return new Promise((resolve, reject) => {
      this.$edtake.snapshot.get({ $queryParameters: { resourceId, resourceType } })
        .then((d) => {
          resolve(d)
        })
        .catch((err) => {
          console.error('ERROR', err)
          reject('AN_ERROR_OCCURED')
        })
    })
  },
  fetchOne ({ dispatch, commit }, { snapshotId }) {
    return new Promise((resolve, reject) => {
      this.$edtake.snapshot.getOne({ id: snapshotId })
        .then((d) => {
          resolve(d)
        })
        .catch((err) => {
          console.error('ERROR', err)
          reject('AN_ERROR_OCCURED')
        })
    })
  },
  getChanges ({ dispatch, commit }, { snapshotId }) {
    return new Promise((resolve, reject) => {
      this.$edtake.snapshot.getChanges({ id: snapshotId })
        .then((d) => {
          resolve(d)
        })
        .catch((err) => {
          console.error('ERROR', err)
          reject('AN_ERROR_OCCURED')
        })
    })
  },
  create ({ dispatch, commit }, { body }) {
    return new Promise((resolve, reject) => {
      this.$edtake.snapshot.create({ body })
        .then((d) => {
          resolve(d)
        })
        .catch((err) => {
          console.error('ERROR', err)
          reject('AN_ERROR_OCCURED')
        })
    })
  },
  remove ({ dispatch, commit }, { snapshotId }) {
    return new Promise((resolve, reject) => {
      this.$edtake.snapshot.delete({ id: snapshotId })
        .then((d) => {
          resolve(d)
        })
        .catch((err) => {
          console.error('ERROR', err)
          reject('AN_ERROR_OCCURED')
        })
    })
  }
}
