/* eslint-disable no-console */
/* eslint-disable camelcase */
import _ from 'lodash'
const missionSchema = {
  _id: String,
  languages: Array,
  authoringTools: Array,
  tags: Array,
  duration: Number,
  iterationQty: Number,
  edtakeToManageMission: Boolean,
  deleted: Boolean,
  owner: Object,
  company: String,
  title: String,
  briefing: String,
  type: String,
  deliverables: Array,
  location: String,
  startDate: String,
  endDate: String,
  budget: Number,
  lcms: String,
  skills: Array,
  status: String,
  edtakerBudget: Number,
  createdAt: String,
  updatedAt: String,
  __v: Number,
  workspace: Object,
  members: Array,
  milestones: Array
}
export const state = () => ({
  missions: []
})
export const getters = {
  get: (state, getters, rootState, rootGetters) => {
    const wsId = rootGetters['workspace/getCurrentId']
    return wsId ? _.filter(state.missions, (p) => { return p?.workspace === wsId || p.workspace?._id === wsId }) : state.missions
  },
  getOne: state => (id) => {
    return _.find(state.missions, (m) => { return m._id === id })
  },
  // Store refactor - can't test it properly, wait for new page
  getWaiting: (state, getters, rootState, rootGetters) => {
    const wsId = rootGetters['workspace/getCurrentId']
    const statuses = ['DRAFT', 'EDTAKE_TO_VALIDATE', 'SEARCHING_EDTAKER', 'PROPOSALS', 'WAITING_START']
    if (wsId) {
      return _.orderBy(_.filter(state.missions, function (o) { return o.workspace === wsId && statuses.includes(o.status) }), 'startDate', 'desc')
    } else {
      return _.orderBy(_.filter(state.missions, function (o) { return statuses.includes(o.status) }), 'startDate', 'desc')
    }
  },
  //  Store refactor - not used - keep
  getOpened (state, getters, rootState, rootGetters) {
    const wsId = rootGetters['workspace/getCurrentId']
    const statuses = ['IN_PROGRESS', 'TO_VALIDATE']

    if (wsId) {
      return _.orderBy(_.filter(state.missions, function (o) { return o.workspace === wsId && statuses.includes(o.status) }), 'startDate', 'desc')
    } else {
      return _.orderBy(_.filter(state.missions, function (o) { return statuses.includes(o.status) }), 'startDate', 'desc')
    }
  },
  //  Store refactor - not used - keep
  getClosed (state, getters, rootState, rootGetters) {
    const wsId = rootGetters['workspace/getCurrentId']
    const statuses = ['CLOSED', 'CANCELED']

    if (wsId) {
      return _.orderBy(_.filter(state.missions, function (o) { return o.workspace === wsId && statuses.includes(o.status) }), 'startDate', 'desc')
    } else {
      return _.orderBy(_.filter(state.missions, function (o) { return statuses.includes(o.status) }), 'startDate', 'desc')
    }
  },
  //  Store refactor - not used - keep
  getWaitingProposals (state, getters, rootState, rootGetters) {
    // TODO : Filter on waiting proposals
    const wsId = rootGetters['workspace/getCurrentId']
    return wsId ? _.filter(state.missions, (p) => { return p.workspace === wsId }) : state.missions
  }
}

export const actions = {
  clear ({ commit }) {
    commit('CLEAR')
  },
  update ({ commit }, { id, body }) {
    return new Promise((resolve, reject) => {
      this.$edtake.mission.update({
        id,
        body
      })
        .then((d) => {
          commit('UPDATE_ONE', d)
          resolve(d)
        })
        .catch((err) => {
          console.error('ERROR', err)
          reject(err)
        })
    })
  },
  fetch ({ commit }, { $queryParameters = {} } = { $queryParameters: {} }) {
    return new Promise((resolve, reject) => {
      this.$edtake.mission.get({ $queryParameters })
        .then((d) => {
          commit('SET', d)
          resolve(d)
        })
        .catch((err) => {
          console.error('ERROR', err)
          reject(err)
        })
    })
  },
  fetchOne ({ commit }, { id }) {
    return new Promise((resolve, reject) => {
      this.$edtake.mission.getOne({ id })
        .then((d) => {
          commit('UPDATE_ONE', d)
          resolve(d)
        })
        .catch((err) => {
          console.error('ERROR', err)
          reject(err)
        })
    })
  },
  create ({ commit, rootGetters }, body) {
    const workspace = rootGetters['workspace/getCurrentId']
    return new Promise((resolve, reject) => {
      this.$edtake.mission.createByWorkspace({
        workspace,
        body
      }).then((d) => {
        commit('CREATE', d)
        this.$evt.log('MissionCreate', { type: d.type, source: d.source })
        resolve(d)
      }).catch((err) => {
        if (err.response && err.response.data === this.$enums.httpErrorBodies.upgrade_license) {
          reject('license.missionOutOfCredit')
        }
        console.error('ERROR', err)
        reject('AN_ERROR_OCCURED')
      })
    })
  },
  createForNewUser (context, body) {
    return new Promise((resolve, reject) => {
      this.$edtake.mission.createForNewUser({
        body
      }).then((d) => {
        resolve(d)
      }).catch((err) => {
        if (err.response && err.response.data === this.$enums.httpErrorBodies.duplicated_user) {
          reject('EMAIL_ALREADY_USED')
        } else {
          console.error('ERROR', err)
          reject(err)
        }
      })
    })
  },
  //  Store refactor - can't test it properly, wait for new page
  acceptEdtakerProposal ({ commit }, { missionId, proposalId }) {
    return new Promise((resolve, reject) => {
      this.$edtake.mission.acceptEdtakerProposal({ missionId, proposalId })
        .then((d) => {
          commit('UPDATE_ONE', d)
          resolve(d)
        })
        .catch((err) => {
          console.error('ERROR', err)
          reject(err)
        })
    })
  },
  //  Store refactor - can't test it properly, wait for new page
  declineEdtakerProposal ({ commit }, { missionId, proposalId, reason }) {
    return new Promise((resolve, reject) => {
      this.$edtake.mission.declineEdtakerProposal({ missionId, proposalId, body: { reason } })
        .then((d) => {
          commit('UPDATE_ONE', d)
          resolve(d)
        })
        .catch((err) => {
          console.error('ERROR', err)
          reject(err)
        })
    })
  },
  deliveryIterate ({ dispatch }, { files, body, missionId, deliveryId }) {
    return new Promise((resolve, reject) => {
      const form = new FormData()
      files.forEach((file, i) => {
        form.append(`file-${i}`, file.file, file.file.name)
      })
      form.append('message', body.message)
      const config = {
        headers: {
          'content-type': 'multipart/form-data'
        }
      }
      this.$edtake.mission.deliveryIterate({ form, $config: config, missionId, deliveryId })
        .then((d) => {
          dispatch('proposal/_updateDelivery', { data: d, missionId, deliveryId }, { root: true })
          dispatch('_updateDelivery', { data: d, missionId, deliveryId })
          resolve(d)
        })
        .catch((err) => {
          console.error('ERROR', err)
          reject(err)
        })
    })
  },
  deliveryReview ({ dispatch }, { files, body, missionId, deliveryId }) {
    return new Promise((resolve, reject) => {
      const form = new FormData()
      files.forEach((file, i) => {
        form.append(`file-${i}`, file.file, file.file.name)
      })
      form.append('message', body.message)
      const config = {
        headers: {
          'content-type': 'multipart/form-data'
        }
      }
      this.$edtake.mission.deliveryReview({ form, $config: config, missionId, deliveryId })
        .then((d) => {
          dispatch('proposal/_updateDelivery', { data: d, missionId, deliveryId }, { root: true })
          dispatch('_updateDelivery', { data: d, missionId, deliveryId })
          resolve(d)
        })
        .catch((err) => {
          console.error('ERROR', err)
          reject(err)
        })
    })
  },
  deliveryValidate ({ dispatch }, { missionId, deliveryId }) {
    return new Promise((resolve, reject) => {
      this.$edtake.mission.deliveryValidate({ missionId, deliveryId })
        .then((d) => {
          dispatch('proposal/_updateDelivery', { data: d, missionId, deliveryId }, { root: true })
          dispatch('_updateDelivery', { data: d, missionId, deliveryId })
          resolve(d)
        })
        .catch((err) => {
          console.error('ERROR', err)
          reject(err)
        })
    })
  },
  getDocument ({ commit }, { missionId, documentId }) {
    return new Promise((resolve, reject) => {
      this.$edtake.mission.getDocument({ missionId, documentId })
        .then((d) => {
          resolve(d)
        })
        .catch((err) => {
          console.error('ERROR', err)
          reject(err)
        })
    })
  },
  _addDocument ({ commit }, d) {
    commit('ADD_DOCUMENT', d)
  },
  _removeDocument ({ commit }, d) {
    commit('REMOVE_DOCUMENT', d)
  },
  _updateDelivery ({ commit }, payload) {
    commit('UPDATE_DELIVERY', payload)
  }
}
export const mutations = {
  SET (state, value) {
    state.missions = value
  },
  UPDATE_ONE (state, value) {
    const item = state.missions.find(item => item._id.toString() === value._id.toString())
    if (item) {
      Object.assign(item, value)
      state.missions = [...state.missions] // Force refresh
    } else {
      state.missions = [...state.missions, value]
    }
  },
  CREATE (state, value) {
    state.missions = [...state.missions, value]
  },
  CLEAR (state) {
    state.missions = []
  },

  //  Store refactor - can't test it properly, wait for new page
  ADD_DOCUMENT (state, value) {
    const item = state.missions.find(item => item._id.toString() === value.parent._id.toString())
    if (item) {
      item.documents = [...item.documents, value]
    }
  },
  //  Store refactor - can't test it properly, wait for new page
  REMOVE_DOCUMENT (state, value) {
    const item = state.missions.find(item => item._id.toString() === value.parent._id.toString())
    if (item) {
      item.documents = item.documents.filter(d => d._id !== value._id)
    }
  },
  UPDATE_DELIVERY (state, value) {
    const mission = state.missions.find(item => item._id.toString() === value.missionId.toString())
    if (mission) {
      const line = mission.acceptedProposal.orders[0].lines.find(line => line.deliveries.some(delivery => delivery.delivery._id.toString() === value.deliveryId.toString()))
      const delivery = line.deliveries.find(delivery => delivery.delivery._id.toString() === value.deliveryId.toString())
      delivery.delivery = value.data
      state.missions = [...state.missions] // Force refresh
    }
  }
}
