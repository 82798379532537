<template lang="html">
  <v-app>
    <Maintenance />
    <Navigation>
      <v-main data-layout="default">
        <FormErrorDialog />
        <nuxt />
        <Snackbar />
        <ProposeChangePassword />
        <DisableSmallDevices />
      </v-main>
    </Navigation>
  </v-app>
</template>

<script lang="js">
import { mapGetters } from 'vuex'
import Navigation from '~/components/partials/Navigation'
import ProposeChangePassword from '~/components/common/ProposeChangePassword'
import Maintenance from '~/components/common/Maintenance'

export default {
  name: 'DefaultLayout',
  components: {
    Navigation,
    ProposeChangePassword,
    Maintenance
  },
  middleware: ['workspace'],
  i18n: {
    messages: {
      fr: {
        back: 'Retour'
      },
      en: {
        back: 'Back'
      }
    }
  },
  data () {
    return {
      socket: null,
      socketStatus: {},
      openEdtakeNewsDialog: true
    }
  },
  head () {
    return {
      title: this.$nuxt.$te(`headTitle.${this.$nuxt.$route.name}`) ? this.$nuxt.$t(`headTitle.${this.$nuxt.$route.name}`) : 'edtake'
    }
  },

  computed: {
    ...mapGetters({
      user: 'user/get'
    })
  },
  mounted () {
    this.$socketManager.getSocket('edtake')
  }
}
</script>
