<template>
  <div>
    <v-row>
      <v-stage
        ref="stage"
        :config="configKonva"
      >
        <v-layer ref="layer">
          <VDraw
            v-for="(item, $index) in shapesListToDisplay"
            :key="item.config.id"
            :item="shapesListToDisplay[$index]"
            :catalog="temporaryCatalog"
            @ready="childrenReadyCounter++"
          />
        </v-layer>
      </v-stage>
    </v-row>
  </div>
</template>

<script>
import Vue from 'vue'
import VueKonva from 'vue-konva'
import VDraw from './VDraw'

Vue.use(VueKonva)

export default {
  components: { VDraw },
  props: {
    scene: {
      type: Object,
      default: () => {}
    },
    template: {
      type: Object,
      default: () => {}
    },
    temporaryCatalog: {
      type: Array,
      default: () => []
    }
  },
  data () {
    return {
      configKonva: {
        width: 780,
        height: 550
      },
      shapesList: this.scene ? this.scene.properties.canvas : this.template.canvas,
      doSaveStage: false,
      doSaveTemplateStage: false,
      childrenReadyCounter: 0
    }
  },
  computed: {
    shapesListToDisplay () {
      const shapes = [...this.shapesList]
      const res = shapes.sort((a, b) => {
        return a.order - b.order
      })
      return res
    }
  },
  watch: {
    doSaveStage (n) {
      if (n) {
        this.checkSaveStage()
      }
    },
    doSaveTemplateStage (n) {
      if (n) {
        this.checkSaveStage()
      }
    },
    childrenReadyCounter (n) {
      if (n) {
        this.checkSaveStage()
      }
    }
  },
  methods: {
    setSaveStage () {
      this.doSaveStage = true
    },
    setSaveTemplateStage () {
      this.doSaveTemplateStage = true
    },
    saveStage () {
      const stage = this.$refs.stage.getStage()
      const dataURL = stage.toDataURL()
      this.$emit('updateSceneImage', { sceneId: this.scene._id, image: dataURL })
    },
    checkSaveStage () {
      if (this.doSaveStage && this.shapesList.length > 0 && (this.childrenReadyCounter === this.shapesList.length)) {
        this.saveStage()
        this.doSaveStage = false
      }
      if (this.doSaveTemplateStage && this.shapesList.length > 0 && (this.childrenReadyCounter === this.shapesList.length)) {
        this.saveTemplateStage()
        this.doSaveTemplateStage = false
      }
    },
    saveTemplateStage () {
      const stage = this.$refs.stage.getStage()
      const dataURL = stage.toDataURL()
      this.$emit('updateTemplate', { _id: this.template._id, image: dataURL })
    }
  }
}

</script>
