/* eslint-disable no-console */
import _ from 'lodash'

// TODO: Store refactor - remove store
export const state = () => ({
})

export const getters = {
}

export const actions = {
  setChildOrder ({ dispatch }, { learningObject, body }) {
    return new Promise((resolve, reject) => {
      this.$edtake.learningObject.setChildOrder({ id: learningObject._id, body })
        .then((d) => {
          if (d) {
            const newOrder = []
            d.forEach((link) => {
              newOrder[link.order - 1] = learningObject.children.find(cLink => cLink.child._id === link.child)
            })
            dispatch(`${learningObject.__t}/_setChildren`, { id: learningObject._id, children: newOrder }, { root: true })
          }
          resolve(d)
        })
        .catch((err) => {
          console.error('ERROR', err)
          reject(err)
        })
    })
  },
  setChildrenOrder ({ dispatch }, { learningObject, body }) {
    return new Promise((resolve, reject) => {
      this.$edtake.learningObject.setChildrenOrder({ id: learningObject._id, body })
        .then((d) => {
          if (d) {
            const newOrder = []
            d.forEach((link) => {
              newOrder[link.order - 1] = learningObject.children.find(cLink => cLink.child._id === link.child)
            })
            dispatch(`${learningObject.__t}/_setChildren`, { id: learningObject._id, children: newOrder }, { root: true })
          }
          resolve(d)
        })
        .catch((err) => {
          console.error('ERROR', err)
          reject(err)
        })
    })
  },
  bulkUpdate ({ commit, dispatch }, { body, recalculate }) {
    return new Promise((resolve, reject) => {
      this.$edtake.learningObject.bulkUpdate({ body })
        .then((d) => {
          dispatch('emitUpdateMutation', { arr: d, recalculate })
          resolve(d)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  async emitUpdateMutation ({ commit, dispatch }, { arr, recalculate }) {
    const mec = _.filter(arr, (o) => { return o.__t === 'mechanism' })
    const pro = _.filter(arr, (o) => { return o.__t === 'course' })
    const del = _.filter(arr, (o) => { return o.__t === 'module' })
    const ta = _.filter(arr, (o) => { return o.__t === 'sequence' })
    if (mec) { await commit('mechanism/UPDATE_MANY', mec, { root: true }) }
    if (pro) { await commit('course/UPDATE_MANY', pro, { root: true }) }
    if (del) { await commit('module/UPDATE_MANY', del, { root: true }) }
    if (ta) { await commit('sequence/UPDATE_MANY', ta, { root: true }) }
    if (recalculate) {
      await dispatch('bulkManagement/_recalculateLearningObject', {}, { root: true })
    }
  }
}

export const mutations = {
}
