import _ from 'lodash'
import enums from '~/plugins/utils/enums'

// TODO: Store refactor - This store seems to never be updated outside of init, should not exist
export const state = () => ({
  countries: enums.countries,
  tCountries: [],
  integrationsList: [],
  skills: enums.skills,
  tSkills: [],
  tSkillsLevel: [],
  tSkillsExperience: [],
  industries: enums.industries,
  tIndustries: [],
  languages: enums.languages,
  tLanguages: [],
  tags: _.sortBy(_.uniq(_.concat(enums.tags, enums.authoring, enums.lms))),
  tTags: [],
  tLocations: [],
  moduleTypes: enums.course.module.type,
  tModuleTypes: [],
  sequenceModality: enums.sequenceModality,
  tsequenceModality: [],
  tUserTypes: [],
  tGraphicalCharters: [],
  deliverablePhases: enums.deliverable.phase,
  tDeliverablePhases: [],
  traineeProgress: enums.traineeProgress,
  tTraineeProgress: [],
  pedagogicalApproach: enums.pedagogicalApproach,
  tPedagogicalApproach: [],
  trainingConstraints: enums.course.trainingConstraints,
  tTrainingConstraints: {
    skillType: [],
    publicSize: [],
    publicAvailability: [],
    deploymentTiming: [],
    deviceAccess: [],
    bandwidth: [],
    deviceStandard: [],
    deviceSound: [],
    trainingTracking: [],
    lmsRequired: [],
    trainingContentUpdate: []
  },
  SELECT_SUBSCRIPTION: undefined,
  MODIFY_SUBSCRIPTION: undefined,
  WAITING_FOR_PAYMENT_BAND: undefined,
  SOFTWARE_ONLY_BY_CONTACT: undefined
})

export const getters = {
  getCountriesAsOptions (state) {
    return state.tCountries
  },
  getSkillsAsOptions (state) {
    return state.tSkills
  },
  getSkillsLevelAsOptions (state) {
    return state.tSkillsLevel
  },
  getSkillsExperienceAsOptions (state) {
    return state.tSkillsExperience
  },
  getLanguagesAsOptions (state) {
    return state.tLanguages.sort((a, b) => a.label.localeCompare(b.label))
  },
  // Store refactor Not used
  getModuleTypesAsOptions (state) {
    return state.tModuleTypes.sort((a, b) => a.label.localeCompare(b.label))
  },
  getSequenceModalityAsOptions (state) {
    return state.tSequenceModality.sort((a, b) => a.label.localeCompare(b.label))
  },
  getDeliverablePhasesAsOptions (state) {
    return state.tDeliverablePhases.sort((a, b) => a.label.localeCompare(b.label))
  },
  getTraineeProgressAsOptions (state) {
    return state.tTraineeProgress.sort((a, b) => a.label.localeCompare(b.label))
  },
  getPedagogicalApproachAsOptions (state) {
    return state.tPedagogicalApproach.sort((a, b) => a.label.localeCompare(b.label))
  },
  getTagsAsOptions (state) {
    return state.tTags.sort((a, b) => a.label.localeCompare(b.label))
  },
  getLocationsAsOptions (state) {
    return state.tLocations
  },
  getUserTypesAsOptions (state) {
    return state.tUserTypes
  },
  getGraphicalChartersAsOptions (state) {
    return state.tGraphicalCharters
  },
  getCountries (state) {
    return state.countries
  },
  getSkills (state) {
    return state.skills
  },
  // Store refactor Not used
  getIndustries (state) {
    return state.industries
  },
  getTrainingContraintsAsOptions: state => (type) => {
    return state.tTrainingConstraints[type]
  },
  getIntegrationsWorkspaceListAsOptions (state) {
    return state.integrationsList.filter(el => el.scope === 'workspace')
  },
  getIntegrationsUserListAsOptions (state) {
    return state.integrationsList.filter(el => el.scope === 'user')
  }
}

export const actions = {
  init ({ commit, state }) {
    const initLanguages = () => {
      if (state.languages) {
        const languages = state.languages.map((el) => {
          return {
            id: el,
            label: this.app.i18n.t('languages.' + el)
          }
        })
        commit('SET_LANGUAGES', languages)
      }
    }
    const initModuleTypes = () => {
      if (state.moduleTypes) {
        const moduleTypes = state.moduleTypes.map((el) => {
          return {
            id: el,
            label: this.app.i18n.t('course.module.type.' + el)
          }
        })
        commit('SET_MODULE_TYPES', moduleTypes)
      }
    }
    const initSequenceModality = () => {
      if (state.sequenceModality) {
        const sequenceModality = state.sequenceModality.map((el) => {
          return {
            id: el,
            label: this.app.i18n.t('sequenceModality.' + el)
          }
        })
        commit('SET_SEQUENCE_MODALITY', sequenceModality)
      }
    }
    const initDeliverablePhases = () => {
      if (state.deliverablePhases) {
        const deliverablePhases = state.deliverablePhases.map((el) => {
          return {
            id: el,
            label: this.app.i18n.t('course.module.status.' + el)
          }
        })
        commit('SET_DELIVERABLE_PHASES', deliverablePhases)
      }
    }
    const initTrainingConstraints = () => {
      if (state.trainingConstraints) {
        const trainingConstraintsType = [
          'skillType',
          'publicSize',
          'publicAvailability',
          'deploymentTiming',
          'deviceAccess',
          'bandwidth',
          'deviceStandard',
          'deviceSound',
          'trainingTracking',
          'lmsRequired',
          'trainingContentUpdate'
        ]
        trainingConstraintsType.forEach((type) => {
          const trainingConstraints = state.trainingConstraints[type].map((el) => {
            return {
              id: el,
              label: this.app.i18n.t('course.trainingConstraints.' + el)
            }
          })
          const tTrainingConstraints = type === 'skillType' ? trainingConstraints.sort((a, b) => a.label.localeCompare(b.label)) : trainingConstraints
          commit('SET_TRAINING_CONSTRAINTS', { trainingConstraintsType: type, tTrainingConstraints })
        })
      }
    }
    const initTraineeProgress = () => {
      if (state.traineeProgress) {
        const traineeProgress = state.traineeProgress.map((el) => {
          return {
            id: el,
            label: this.app.i18n.t('course.traineeProgress.' + el)
          }
        })
        commit('SET_TRAINEE_PROGRESS', traineeProgress)
      }
    }
    const initPedagogicalApproach = () => {
      if (state.pedagogicalApproach) {
        const pedagogicalApproach = state.pedagogicalApproach.map((el) => {
          return {
            id: el,
            label: this.app.i18n.t('pedagogicalApproach.' + el)
          }
        })
        commit('SET_PEDAGOGICAL_APPROACH', pedagogicalApproach)
      }
    }
    const initCountries = () => {
      if (state.countries) {
        const countries = []
        state.countries.forEach((el) => {
          countries.push({
            id: el,
            group: this.app.i18n.t('countries.ALL_COUNTRIES'),
            label: this.app.i18n.t('countries.' + el)
          })
        })
        countries.sort(a =>
          a.group === this.app.i18n.t('countries.QUICK_ACCESS') ? -1 : 1
        )
        commit('SET_COUNTRIES', countries)
      }
    }
    const initSkills = () => {
      if (state.skills) {
        const skills = []
        state.skills.forEach((el) => {
          el.subskills.forEach((sel) => {
            skills.push({
              id: sel,
              category: this.app.i18n.t('skills.' + el.skill),
              label: this.app.i18n.t('skills.' + sel)
            })
          })
        })
        commit('SET_SKILLS', skills)
      }
    }
    const initSkillsLevel = () => {
      const skillsLevel = [
        { id: 1, label: this.app.i18n.t('skills.levels.1') },
        { id: 2, label: this.app.i18n.t('skills.levels.2') },
        { id: 3, label: this.app.i18n.t('skills.levels.3') },
        { id: 4, label: this.app.i18n.t('skills.levels.4') }
      ]
      commit('SET_SKILLS_LEVEL', skillsLevel)
    }
    const initSkillsExperience = () => {
      const skillsExperience = [
        { id: 1, label: this.app.i18n.t('skills.experiences.1') },
        { id: 2, label: this.app.i18n.t('skills.experiences.2') },
        { id: 3, label: this.app.i18n.t('skills.experiences.3') },
        { id: 4, label: this.app.i18n.t('skills.experiences.4') }
      ]
      commit('SET_SKILLS_EXPERIENCE', skillsExperience)
    }
    const initIndustries = () => {
      if (state.industries) {
        const industries = state.industries.map((el) => {
          return {
            id: el,
            label: this.app.i18n.t('industries.' + el)
          }
        })
        commit('SET_INDUSTRIES', industries)
      }
    }
    const initTags = () => {
      if (state.tags) {
        const tags = state.tags.map((el) => {
          return {
            id: el,
            label: this.app.i18n.t(el)
          }
        })
        commit('SET_TAGS', tags)
      }
    }
    const initLocations = () => {
      const locations = [
        { id: 'REMOTE_ONLY', label: this.app.i18n.t('mission.location.REMOTE_ONLY') },
        { id: 'PART_TIME_ON_SITE', label: this.app.i18n.t('mission.location.PART_TIME_ON_SITE') },
        { id: 'ON_SITE', label: this.app.i18n.t('mission.location.ON_SITE') }
      ]
      commit('SET_LOCATIONS', locations)
    }
    const initUserTypes = () => {
      const userTypes = [
        { id: 'COMPANY', label: this.app.i18n.t('userProfile.COMPANY') },
        { id: 'EDTAKER_FREE', label: this.app.i18n.t('userProfile.EDTAKER_FREE') },
        { id: 'EDTAKER_AGENCY', label: this.app.i18n.t('userProfile.EDTAKER_AGENCY') }
      ]
      commit('SET_USER_TYPES', userTypes)
    }
    const initGraphicalCharters = () => {
      const graphicalCharters = [
        { id: 'PROVIDED', label: this.app.i18n.t('mission.graphicalCharter.PROVIDED') },
        { id: 'PROVIDED', label: this.app.i18n.t('mission.graphicalCharter.NOT_PROVIDED') }
      ]
      commit('SET_GRAPHICAL_CHARTERS', graphicalCharters)
    }
    initLanguages()
    initModuleTypes()
    initSequenceModality()
    initDeliverablePhases()
    initTrainingConstraints()
    initTraineeProgress()
    initCountries()
    initSkills()
    initSkillsLevel()
    initSkillsExperience()
    initIndustries()
    initTags()
    initLocations()
    initUserTypes()
    initGraphicalCharters()
    initPedagogicalApproach()
  },
  async initIntegrations ({ commit, state }) {
    const integrations = await this.$edtake.integration.get()
    commit('SET_INTEGRATIONS_LIST', integrations)
  }
}

export const mutations = {
  SET_LANGUAGES (state, tLanguages) {
    state.tLanguages = tLanguages
  },
  SET_MODULE_TYPES (state, tModuleTypes) {
    state.tModuleTypes = tModuleTypes
  },
  SET_SEQUENCE_MODALITY (state, tSequenceModality) {
    state.tSequenceModality = tSequenceModality
  },
  SET_DELIVERABLE_PHASES (state, tDeliverablePhases) {
    state.tDeliverablePhases = tDeliverablePhases
  },
  SET_TRAINEE_PROGRESS (state, tTraineeProgress) {
    state.tTraineeProgress = tTraineeProgress
  },
  SET_PEDAGOGICAL_APPROACH (state, tPedagogicalApproach) {
    state.tPedagogicalApproach = tPedagogicalApproach
  },
  SET_COUNTRIES (state, tCountries) {
    state.tCountries = tCountries
  },
  SET_SKILLS (state, tSkills) {
    state.tSkills = tSkills
  },
  SET_SKILLS_LEVEL (state, tSkillsLevel) {
    state.tSkillsLevel = tSkillsLevel
  },
  SET_SKILLS_EXPERIENCE (state, tSkillsExperience) {
    state.tSkillsExperience = tSkillsExperience
  },
  SET_INDUSTRIES (state, tIndustries) {
    state.tIndustries = tIndustries
  },
  SET_TAGS (state, tTags) {
    state.tTags = tTags
  },
  SET_LOCATIONS (state, tLocations) {
    state.tLocations = tLocations
  },
  SET_USER_TYPES (state, tUserTypes) {
    state.tUserTypes = tUserTypes
  },
  SET_GRAPHICAL_CHARTERS (state, tGraphicalCharters) {
    state.tGraphicalCharters = tGraphicalCharters
  },
  SET_TRAINING_CONSTRAINTS (state, { trainingConstraintsType, tTrainingConstraints }) {
    Object.assign(state.tTrainingConstraints[trainingConstraintsType], tTrainingConstraints)
  },
  SET_INTEGRATIONS_LIST (state, integrations) {
    state.integrationsList = integrations
  }
}
