<template>
  <div>
    <v-toolbar v-if="!sceneImageEditor" dense flat class="px-3">
      <h2 class="page-title my-0 py-0">
        {{ $t('sceneEditorTitle') }}
      </h2>
      <v-spacer></v-spacer>
      <v-tooltip bottom>
        <template #activator="{ on, attrs }">
          <span
            v-bind="attrs"
            v-on="on"
          >
            <edtk-button-icon
              data-test="button-overview-link-navbar"
              icon="close"
              icon-size="4xl"
              color="secondary"
              @click="displayOverview"
            >
            </edtk-button-icon>
          </span>
        </template>
        <span>{{ $t('overview') }}</span>
      </v-tooltip>
    </v-toolbar>
    <v-divider v-if="!sceneImageEditor"></v-divider>
    <div v-if="!sceneImageEditor" class="d-flex">
      <Navigation
        :scenes="storyboard.scenes"
        :current-scene-id="currentSceneId"
        :display-scene-numbering="displaySceneNumbering"
        :edit-right="editRight"
        v-on="$listeners"
      />
      <div class="scene-edition">
        <div class="d-flex blue--text h1 mb-0 mt-4 pb-0" style="font-size: 26px !important; font-weight: 700;">
          <span style="min-width: max-content;" class="mr-2">
            {{ sceneToEdit.order }} -
          </span>
          <EditableField
            :value="sceneToEdit.properties.title"
            :placeholder="$t('sceneTitleLabel')"
            type="text"
            large
            :edit-right="editRight"
            @input="editSceneTitle"
          />
        </div>
        <div class="tabs-container">
          <v-tabs v-model="tabkey" color="primary">
            <v-tab key="properties">
              {{ $t('propertiesTab') }}
            </v-tab>
            <v-tab key="script">
              {{ $t('scriptTab') }}
            </v-tab>
            <v-tab key="summary" data-test="tab-slide-script-summary">
              {{ $t('summaryTab') }}
            </v-tab>
          </v-tabs>
          <v-tabs-items v-model="tabkey">
            <v-tab-item key="properties" style="height:calc(100vh - 170px); overflow-y: auto; overflow-x:hidden;">
              <Properties
                :scene-properties="sceneToEdit.properties"
                :edit-right="editRight"
                @openSceneImageEditor="openSceneImageEditor"
                v-on="$listeners"
              />
            </v-tab-item>
            <v-tab-item
              key="script"
            >
              <Script
                :current-scene-id="currentSceneId"
                :scenes="storyboard.scenes"
                :assets="storyboard.assets"
                :catalog="storyboard.catalog"
                :script="script"
                :update-catalog="updateCatalog"
                :edit-right="editRight"
                v-on="$listeners"
              />
            </v-tab-item>
            <v-tab-item
              key="summary"
            >
              <Summary
                :catalog="storyboard.catalog"
                :scene-to-edit="sceneToEdit"
                :assets="storyboard.assets"
                :settings="storyboard.settings"
              />
            </v-tab-item>
          </v-tabs-items>
        </div>
      </div>
    </div>
    <SceneImageEditor
      v-if="sceneImageEditor"
      :update-catalog="updateCatalog"
      :scene-to-edit="sceneToEdit"
      :storyboard="storyboard"
      @close="closeSceneImageEditor"
      v-on="$listeners"
    />
  </div>
</template>

<script>
import _ from 'lodash'
import SceneImageEditor from '../canvas/SceneImageEditor'
import Navigation from './Navigation'
import Properties from './components/Properties'
import Script from './components/Script'
import Summary from './components/Summary'

export default {
  components: { Navigation, Properties, Script, Summary, SceneImageEditor },
  props: {
    storyboard: {
      type: Object,
      default: () => {}
    },
    currentSceneId: {
      type: String,
      default: () => ''
    },
    updateCatalog: {
      type: Function,
      default: () => () => {}
    },
    editRight: {
      type: Boolean,
      required: true,
      default: false
    }
  },
  data () {
    return {
      tabkey: 0,
      tabkeyRef: {
        0: 'script',
        1: 'sceneSummary'
      },
      sceneImageEditor: false
    }
  },
  computed: {
    sceneToEdit () {
      return this.storyboard.scenes.find(i => i._id === this.currentSceneId)
    },
    script () {
      const list = [...this.sceneToEdit.script]
      return _.sortBy(list, [o => o.order])
    },
    displaySceneNumbering () {
      return this.storyboard?.settings?.sceneNumbering
    }
  },
  i18n: {
    messages: {
      fr: {
        sceneTitleLabel: 'Ajouter un titre ici...',
        sceneEditorTitle: 'Editeur de scènes',
        propertiesTab: 'Informations Générales',
        scriptTab: 'Script',
        summaryTab: 'Synthèse',
        overview: 'Fermer l\'éditeur de scènes'
      },
      en: {
        sceneTitleLabel: 'Add scene title here...',
        sceneEditorTitle: 'Scene editor',
        propertiesTab: 'General informations',
        scriptTab: 'Script',
        summaryTab: 'Summary',
        overview: 'Close scene editor'
      }
    }
  },
  methods: {
    editSceneTitle (e) {
      this.$emit('editSceneProperty', { property: 'title', data: e })
    },
    openSceneImageEditor () {
      this.sceneImageEditor = true
    },
    closeSceneImageEditor () {
      this.sceneImageEditor = false
    },
    displayOverview () {
      this.$emit('update:currentSceneId', undefined)
    }
  }
}
</script>

<style>
  .scene-edition {
    width: 100%;
    padding: 0 40px;
  }
  .scene-edition .tabs-container {
    width: 100%;
  }
</style>
