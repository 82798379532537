<template>
  <v-card class="ma-0 pa-0" height="80vh" flat tile>
    <v-card-text class="bg-white-smoke pa-0" style="height: 73vh; overflow-y: auto;">
      <v-stepper v-model="step">
        <v-stepper-header>
          <v-stepper-step class="py-2" step="1" :complete="step > 1">
            {{ $t('projectInfo') }}
          </v-stepper-step>
          <v-divider></v-divider>
          <v-stepper-step
            class="py-2"
            step="2"
            :complete="step > 2"
          >
            {{ $t(`${steppersToDisplay[1]}`) }}
          </v-stepper-step>
          <v-divider v-if="steppersToDisplay.length === 3"></v-divider>
          <v-stepper-step v-if="steppersToDisplay.length === 3" class="py-2" step="3">
            {{ $t('templateInfo') }}
          </v-stepper-step>
        </v-stepper-header>
        <v-stepper-items>
          <v-stepper-content class="pa-0" step="1">
            <div v-if="step === 1" ref="popoverEditable" class="parent">
              <div class="raciListAside">
                <v-card class="d-flex flex-column justify-space-between ma-0 pa-6" height="100%" style="overflow-y: auto;">
                  <v-row>
                    <v-col>
                      <edtk-multiselect
                        v-model="newProjectMember.accountable"
                        data-tour-id="project-creation-accountable"
                        :options="objectMembers"
                        :label="$t('newProjectMember.accountable')"
                        :placeholder="$t('newProjectMemberPlaceholder.accountable')"
                        :editable="true"
                      />
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col>
                      <edtk-multiselect
                        v-model="newProjectMember.responsible"
                        data-tour-id="project-creation-responsible"
                        :options="objectMembers"
                        :label="$t('newProjectMember.responsible')"
                        :placeholder="$t('newProjectMemberPlaceholder.responsible')"
                        :editable="true"
                      />
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col>
                      <edtk-multiselect
                        v-model="newProjectMember.consulted"
                        data-tour-id="project-creation-consulted"
                        :options="objectMembers"
                        :label="$t('newProjectMember.consulted')"
                        :placeholder="$t('newProjectMemberPlaceholder.consulted')"
                        :editable="true"
                      />
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col>
                      <edtk-multiselect
                        v-model="newProjectMember.informed"
                        data-tour-id="project-creation-informed"
                        :options="objectMembersIncludedGuests"
                        :label="$t('newProjectMember.informed')"
                        :placeholder="$t('newProjectMemberPlaceholder.informed')"
                        :editable="true"
                      />
                    </v-col>
                  </v-row>
                </v-card>
              </div>
              <div class="detailsParent">
                <v-card v-if="learningObjectList.length > 1" class="my-0 pa-4 noTitleDescriptionCard" height="100%">
                  <div style="width: 100%; height: 100%" class="d-flex justify-center align-center">
                    <h4 class="grey--text font-italic">
                      {{ $t('multipleCreation') }}
                    </h4>
                  </div>
                </v-card>
                <v-card v-else class="my-0 pa-4 titleDescriptionCard" height="100%">
                  <div class="titleContainer h4 blue--text font-weight-medium mt-10">
                    <edtk-text
                      v-model="newProject.name"
                      data-tour-id="project-creation-name"
                      :label="$t('newProject.name')"
                      :placeholder="$t('newProject.name')"
                      :editable="true"
                    />
                  </div>
                  <div style="min-height: 150px;" class="descriptionContainer mt-10">
                    <edtk-rich-text-editor
                      v-model="newProject.description"
                      data-tour-id="project-creation-description"
                      :label="$t('newProject.description')"
                      :placeholder="$t('newProject.description')"
                      full-height
                      :editable="true"
                    ></edtk-rich-text-editor>
                  </div>
                </v-card>
                <v-card class=" my-0 pa-4 startDateContainer d-flex justify-space-around align-center flex-wrap" height="100%">
                  <div>
                    <img
                      src="~/assets/img/project-start-date.svg"
                      alt="edtake"
                      height="80"
                    />
                  </div>
                  <div>
                    <edtk-date-picker
                      v-model="newProject.startDate"
                      data-tour-id="project-creation-startDate"
                      :label="$t('mission.START_DATE')"
                      :locale="$i18n.locale"
                      :editable="true"
                    />
                  </div>
                </v-card>
                <v-card class=" my-0 pa-4 endDateContainer d-flex justify-space-around align-center flex-wrap" height="100%">
                  <div>
                    <img
                      src="~/assets/img/project-due-date.svg"
                      alt="edtake"
                      height="80"
                    />
                  </div>
                  <div>
                    <edtk-date-picker
                      v-model="newProject.dueDate"
                      data-tour-id="project-creation-dueDate"
                      :min="dueDateMinValue"
                      :label="$t('mission.END_DATE')"
                      :locale="$i18n.locale"
                      :editable="true"
                    />
                  </div>
                </v-card>
              </div>
            </div>
          </v-stepper-content>
          <v-stepper-content v-if="steppersToDisplay.length === 3" class="pa-0" step="2">
            <div v-if="step === 2" class="stepper2parent">
              <div v-if="subScope === 'Other'" class="switchSide">
                <v-card class="ma-0 pa-4 text-center" flat>
                  <span class="text-rem-11 blue--text font-weight-bold">
                    {{ $t('linkedToLO.question') }}
                  </span>
                  <div class="d-flex justify-center">
                    <v-card :class="[ linkedToLO ? '': 'selectedBackground', 'mx-2 my-4 pa-0']" @click="() => linkedToLO = false">
                      <v-card-title class="blue--text d-flex justify-space-between">
                        <span class="font-weight-bold">{{ $t('buttons.NO') }}</span>
                      </v-card-title>
                    </v-card>
                    <v-card :class="[ linkedToLO ? 'selectedBackground': '', 'mx-2 my-4 pa-0']" @click="() => linkedToLO = true">
                      <v-card-title class="blue--text d-flex justify-space-between">
                        <span class="font-weight-bold">{{ $t('buttons.YES') }}</span>
                      </v-card-title>
                    </v-card>
                  </div>
                </v-card>
              </div>
              <div
                v-if="linkedToLO"
                :class="[subScope === 'Other' ? 'selectObjectContentSide' : 'selectObjectContent', 'ml-2 px-5']"
              >
                <LearningObjectPicker v-model="learningObjectId" />
              </div>
              <div v-if="subScope === 'Create'" class="newObjectContainer">
                <LearningObjectCreation
                  :new-learning-object="newLearningObject"
                  @updateNewLearningObject="updateNewLearningObject"
                />
              </div>
            </div>
          </v-stepper-content>
          <v-stepper-content class="pa-0" :step="steppersToDisplay.length">
            <div v-if="step === 3 || step === 2" class="stepper2parent">
              <div :class="[fromTemplate ? 'switchSide-members' : 'switchSide', 'px-1']">
                <h6 class="page-subtitle" style="font-size:1rem;">
                  {{ $t('fromTemplate.question') }}
                </h6>
                <v-sheet
                  rounded
                  elevation="1"
                  :class="[fromTemplate ? '': 'selectedBackground', 'mb-2 py-5 pr-2 pointer']"
                  @click="clearProjectTemplate"
                >
                  <v-row class="align-center">
                    <v-col cols="4" class="pr-1 text-right">
                      <img
                        src="~/assets/img/project-noTemplate.svg"
                        alt="edtake"
                        height="60"
                      />
                    </v-col>
                    <v-col cols="8" class="pl-1 align-center">
                      <span class="blue--text">
                        {{ $t('fromTemplate.noTemplateTitle') }}
                      </span>
                      <p class="brownish-grey--text text-rem-08">
                        {{ $t('fromTemplate.noTemplateSubtitle') }}
                      </p>
                    </v-col>
                  </v-row>
                </v-sheet>
                <v-sheet
                  rounded
                  elevation="1"
                  :class="[fromTemplate ? 'selectedBackground': '', 'mb-2 py-5 pr-2 pointer']"
                  @click="() => fromTemplate = true"
                >
                  <v-row class="align-center">
                    <v-col cols="4" class="pr-1 text-right">
                      <img
                        src="~/assets/img/project-useTemplate.svg"
                        alt="edtake"
                        height="60"
                      />
                    </v-col>
                    <v-col cols="8" class="pl-1">
                      <span class="blue--text">
                        {{ $t('fromTemplate.useTemplateTitle') }}
                      </span>
                      <p class="brownish-grey--text text-rem-08">
                        {{ $t('fromTemplate.useTemplateSubtitle') }}
                      </p>
                    </v-col>
                  </v-row>
                </v-sheet>
                <v-card v-if="fromTemplate && (projectTemplate ? !projectTemplate.isPublic : true)" class="ma-0 mt-5 pa-4">
                  <span class="cardTitle blue--text font-weight-bold">
                    {{ $t('keepMembersfromTemplate.question') }}
                  </span>
                  <div class="d-flex">
                    <v-sheet
                      outlined
                      rounded
                      elevation="1"
                      :class="[ keepMembersfromTemplate ? '': 'selectedBackground', 'mx-2 mt-4 py-2 px-5 pointer']"
                      @click="() => keepMembersfromTemplate = false"
                    >
                      <span class="blue--text text-rem-08">{{ $t('buttons.NO') }}</span>
                    </v-sheet>
                    <v-sheet
                      outlined
                      rounded
                      elevation="1"
                      :class="[ keepMembersfromTemplate ? 'selectedBackground': '', 'mx-2 mt-4 py-2 px-5 pointer']"
                      @click="() => keepMembersfromTemplate = true"
                    >
                      <span class="blue--text text-rem-08">{{ $t('buttons.YES') }}</span>
                    </v-sheet>
                  </div>
                </v-card>
              </div>
              <section
                v-if="fromTemplate"
                class="selectObjectContentSide ml-5 pa-1"
              >
                <v-card class="ma-0 pa-0">
                  <TemplatePicker v-model="projectTemplate" :type="['project']" :max-height="54" />
                </v-card>
              </section>
            </div>
          </v-stepper-content>
        </v-stepper-items>
      </v-stepper>
    </v-card-text>
    <v-divider></v-divider>
    <v-card-actions class="justify-center align-center">
      <div v-if="step === 1">
        <edtk-button
          class="mx-2 mt-1"
          :label="$t('buttons.NEXT')"
          @click="changeStep(step, step + 1)"
        ></edtk-button>
      </div>
      <div
        v-if="steppersToDisplay.length === 3 && step === 2"
        class="d-flex align-center justify-center"
      >
        <edtk-button
          type="outline"
          class="mx-2 mt-1"
          :label="$t('buttons.PREVIOUS')"
          @click="changeStep(step, step - 1)"
        ></edtk-button>
        <edtk-button
          class="mx-2 mt-1"
          :label="$t('buttons.NEXT')"
          @click="changeStep(step, step + 1)"
        ></edtk-button>
      </div>
      <div
        v-if="step === 3 || (steppersToDisplay.length === 2 && step === 2)"
        class="d-flex align-center justify-center"
      >
        <edtk-button
          :disabled="loadingAction"
          type="outline"
          class="mx-2 mt-1"
          :label="$t('buttons.PREVIOUS')"
          @click="changeStep(step, step - 1)"
        ></edtk-button>
        <edtk-button
          :loading="loadingAction"
          :disabled="loadingAction"
          class="mx-2 mt-1"
          :label="$t('buttons.SUBMIT')"
          @click="submit"
        ></edtk-button>
      </div>
    </v-card-actions>
  </v-card>
</template>

<script>
import { required } from 'vuelidate/lib/validators'
import requiredIf from 'vuelidate/lib/validators/requiredIf'
import { mapActions, mapGetters } from 'vuex'
import i18n from './i18n.js'
import LearningObjectCreation from '~/components/learningObject/creation/fromProject'
import TemplatePicker from '~/components/template/Picker'
import LearningObjectPicker from '~/components/learningObject/components/ResourcesPicker'

export default {
  name: 'CreateProject',
  components: {
    LearningObjectCreation,
    TemplatePicker,
    LearningObjectPicker
  },
  i18n,
  props: {
    subScope: {
      type: String,
      required: true,
      default: () => ''
    },
    parentProject: {
      type: Object,
      required: false,
      default: () => {}
    },
    learningObjectList: {
      type: Array,
      required: false,
      default: () => []
    },
    creationFromLOPage: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      step: 1,
      loadingAction: false,
      projectTemplate: undefined,
      keepMembersfromTemplate: false,
      fromTemplate: true,
      newProject: {
        name: undefined,
        description: undefined,
        startDate: undefined,
        dueDate: undefined,
        members: [],
        parentProject: this.parentProject ? this.parentProject._id : undefined
      },
      newProjectMember: {
        accountable: undefined,
        responsible: undefined,
        consulted: undefined,
        informed: undefined
      },
      linkedToLO: true,
      learningObjectId: undefined,
      newLearningObject: {
        __t: undefined,
        name: undefined,
        description: undefined,
        type: undefined,
        activityType: undefined,
        language: undefined
      },
      countLO: undefined
    }
  },
  validations: {
    newProjectMember: {
      accountable: { required }
    },
    newProject: {
      name: {
        required: requiredIf(function () {
          return this.learningObjectList.length < 1
        })
      },
      startDate: { required }
    },
    projectTemplate: {
      required: requiredIf(function () {
        return this.fromTemplate && (this.steppersToDisplay.length === 3 ? this.step === 3 : this.step === 2)
      })
    },
    learningObjectId: {
      required: requiredIf(function () {
        return this.linkedToLO && !this.learningObjectList.length
      })
    },
    newLearningObject: {
      __t: {
        required: requiredIf(function () {
          return this.subScope === 'Create' && !this.learningObjectList.length
        })
      },
      name: {
        required: requiredIf(function () {
          return this.subScope === 'Create' && !this.learningObjectList.length
        })
      },
      type: {
        required: requiredIf(function () {
          return this.subScope === 'Create' && !this.learningObjectList.length && (this.newLearningObject.__t === 'module' || this.newLearningObject.__t === 'sequence')
        })
      }
    },
    step1: ['newProject.name', 'newProject.startDate', 'newProjectMember.accountable'],
    step2: ['newLearningObject.__t', 'newLearningObject.name', 'newLearningObject.type', 'learningObjectId', 'projectTemplate'],
    step3: ['projectTemplate']
  },
  computed: {
    ...mapGetters({
      user: 'user/get',
      workspaceMembers: 'workspace/getCurrentMembersWithSoftwareLicense',
      workspaceGuests: 'workspace/getCurrentGuests',
      workspaceTeams: 'team/getTeams',
      currentWorkspaceId: 'workspace/getCurrentId',
      currentModuleTypes: 'settings/getSettingsModuleType',
      currentSequenceTypes: 'settings/getSettingsSequenceType',
      workspaceLanguages: 'workspace/getSettingsLanguages'
    }),
    objectMembers () {
      const members = this.$_.reduce(this.workspaceMembers, (acc, m) => {
        return [...acc, { label: m.user.name, value: m.user._id, type: 'user', category: this.$t('users') }]
      }, [])
      const teamAsMembers = this.$_.reduce(this.workspaceTeams, (acc, m) => {
        return [...acc, { label: m.name, value: m._id, type: 'team', category: this.$t('teams') }]
      }, [])
      return [...members, ...teamAsMembers]
    },
    objectMembersIncludedGuests () {
      const members = this.$_.reduce([...this.workspaceMembers, ...this.workspaceGuests], (acc, m) => {
        return [...acc, { label: m.user.name, value: m.user._id, type: 'user', category: this.$t('users') }]
      }, [])
      const teamAsMembers = this.$_.reduce(this.workspaceTeams, (acc, m) => {
        return [...acc, { label: m.name, value: m._id, type: 'team', category: this.$t('teams') }]
      }, [])
      return [...members, ...teamAsMembers]
    },
    steppersToDisplay () {
      if (this.learningObjectList.length || (this.subScope === 'Other' && !this.countLO)) {
        return ['projectInfo', 'templateInfo']
      } else {
        return ['projectInfo', 'learningObjectInfo', 'templateInfo']
      }
    },
    dueDateMinValue () {
      return this.newProject.startDate ? this.$dayjs(this.newProject.startDate).toDate() : this.$dayjs().toDate()
    }
  },
  watch: {
    'newProject.startDate' (newValue, oldValue) {
      if (
        this.$dayjs(newValue, 'YYYY-MM-DD').isAfter(
          this.$dayjs(this.newProject.dueDate, 'YYYY-MM-DD')
        )
      ) {
        this.newProject.dueDate = undefined
      }
    }
  },
  async mounted () {
    this.countLO = await this.$edtake.stats.count({ objectType: 'learningObject' })
    if (this.parentProject) {
      this.newProject.startDate = this.parentProject.startDate
      this.newProject.dueDate = this.parentProject.dueDate
      this.editMembersFromProject()
    } else {
      this.newProjectMember.accountable = [this.user._id]
    }
    if ((this.subScope === 'Create' && !this.learningObjectList.length) || (this.subScope === 'Other' && !this.countLO)) {
      this.linkedToLO = false
      if (this.workspaceLanguages.length === 1) {
        this.newLearningObject.language = this.workspaceLanguages[0]
      }
    } else if (this.learningObjectList.length) {
      if (this.learningObjectList.length === 1) {
        const LOname = this.learningObjectList[0]?.name
        this.newProject.name = this.generateNewProjectName(this.subScope, LOname)
      }
      this.linkedToLO = true
    } else {
      this.linkedToLO = true
    }
  },
  methods: {
    ...mapActions({
      checkErrors: 'errorForm/generateErrorList',
      createProject: 'project/createProject'
    }),
    editMembersFromProject () {
      const res = {
        accountable: [],
        responsible: [],
        consulted: [],
        informed: []
      }
      this.$_.forEach(this.parentProject.members, (m) => {
        if (m.accountable) {
          res.accountable.push(m.user._id)
        }
        if (m.responsible) {
          res.responsible.push(m.user._id)
        }
        if (m.consulted) {
          res.consulted.push(m.user._id)
        }
        if (m.informed) {
          res.informed.push(m.user._id)
        }
      })
      this.newProjectMember = res
    },
    updateNewLearningObject ({ key, value }) {
      this.newLearningObject[key] = value
      if ((key === '__t' && value === 'module') && this.currentModuleTypes.length === 1) {
        this.newLearningObject.type = this.currentModuleTypes[0]
      }
      if ((key === '__t' && value === 'sequence') && this.currentSequenceTypes.length === 1) {
        this.newLearningObject.type = this.currentSequenceTypes[0]
      }
    },
    submit () {
      const self = this
      this.$v.$touch()
      if (this.$v.$invalid) {
        const $t = this.$t.bind(this)
        this.checkErrors({
          validationObject: this.steppersToDisplay.length === 2 ? this.$v.step2 : this.$v.step3,
          translation: $t,
          translationPath: 'formLabel',
          usePopin: true
        })
      } else {
        const items = []
        this.loadingAction = true
        const createObj = {
          creationType: this.subScope.toLowerCase()
        }
        createObj.projectItem = this.newProject
        if (!this.learningObjectList.length) {
          const learningObjectId = this.learningObjectId
          if (this.subScope === 'Create') {
            createObj.learningObject = this.newLearningObject
          } else if (this.subScope === 'Update' || (this.subScope === 'Other' && this.linkedToLO)) {
            createObj.learningObjectId = learningObjectId
          }
          createObj.notifyMembers = true
          items.push(createObj)
        } else {
          this.learningObjectList.forEach((lo) => {
            const createObjDuplicated = this.$_.cloneDeep(createObj)
            if (this.learningObjectList.length > 1) {
              createObjDuplicated.projectItem.name = this.generateNewProjectName(this.subScope, lo.name)
            }
            createObjDuplicated.learningObjectId = lo._id
            createObj.notifyMembers = false
            items.push(createObjDuplicated)
          })
        }
        this.createProject({
          templateId: this.projectTemplate?._id,
          keepMembersTemplate: this.projectTemplate && this.projectTemplate.isPublic ? false : this.keepMembersfromTemplate,
          body: { items }
        }).then((p) => {
          if (this.parentProject && this.learningObjectList.length <= 1) {
            this.$swal({
              icon: 'question',
              title: '',
              html: this.$t('redirectionSubProject.question'),
              showCancelButton: true,
              confirmButtonColor: '#4bca81',
              cancelButtonColor: '#c2c5cb',
              confirmButtonText: this.$t('buttons.YES'),
              cancelButtonText: this.$t('buttons.NO')
            }).then((r) => {
              if (r.value && p[0]?._id) {
                this.$edtkRouter.navigate({ $route: this.$route, $router: this.$router, workspaceId: this.currentWorkspaceId, targetApp: this.$enums.appPrefix.projectManagement, targetPath: `/project/${p[0]._id}` })
              } else {
                this.$emit('close')
              }
            })
          } else if (this.parentProject && this.learningObjectList.length) {
            this.$swal({
              icon: 'success',
              title: this.$t('messages.CONFIRMATION'),
              html: this.$t('messages.CONFIRM_MULTIPLE_PROJECT_CREATION')
            })
            this.$emit('close')
          } else if (this.creationFromLOPage) {
            this.$swal({
              icon: 'question',
              title: '',
              html: this.$t('redirectionProject.question'),
              showCancelButton: true,
              confirmButtonColor: '#4bca81',
              cancelButtonColor: '#c2c5cb',
              confirmButtonText: this.$t('buttons.YES'),
              cancelButtonText: this.$t('buttons.NO')
            }).then((r) => {
              if (r.value && p[0]?._id) {
                window.open(`/${this.$enums.routePrefix.workspace}/${this.currentWorkspaceId}/${this.$enums.appPrefix.projectManagement}/${p[0].__t}/${p[0]._id}`, '_blank')
              }
              this.$nuxt.$emit('refresh', this.learningObjectList[0]._id)
              this.$emit('close')
            })
          } else {
            this.$swal({
              icon: 'success',
              title: this.$t('messages.CONFIRMATION'),
              html: this.$t('messages.CONFIRM_PROJECT_CREATION')
            })
            this.$edtkRouter.navigate({ $route: this.$route, $router: this.$router, workspaceId: this.currentWorkspaceId, targetApp: this.$enums.appPrefix.projectManagement, targetPath: `/project/${p[0]._id}` })
          }
          this.$emit('close')
          self.loadingAction = false
        }).catch((err) => {
          self.loadingAction = false
          this.$swal({
            icon: 'error',
            title: this.$t('errors.OUPS'),
            html: this.$t('errors.' + err)
          })
        })
      }
    },
    changeStep (oldValue, newValue) {
      const doChangeStep = (n) => {
        const skipBySubScope = {
          Create: [],
          Update: [],
          Other: []
        }
        if (skipBySubScope[this.subScope].includes(n)) {
          if (n > oldValue) {
            doChangeStep(n + 1)
          } else {
            doChangeStep(n - 1)
          }
        } else {
          this.step = n
        }
      }
      if (newValue > oldValue) {
        if (oldValue === 1) {
          this.calculatNewProjectMembers()
          if (this.$v.step1.$invalid) {
            const $t = this.$t.bind(this)
            this.checkErrors({ validationObject: this.$v.step1, translation: $t, translationPath: 'formLabel', usePopin: true })
            return
          }
        }
        if (oldValue === 2 && this.$v.step2.$invalid) {
          const $t = this.$t.bind(this)
          this.checkErrors({ validationObject: this.$v.step2, translation: $t, translationPath: 'formLabel', usePopin: true })
          return
        }
      }
      doChangeStep(newValue)
    },
    calculatNewProjectMembers () {
      const res = []
      this.$_.forEach(Object.keys(this.newProjectMember), (raci) => {
        this.$_.forEach(this.newProjectMember[raci], (m) => {
          const existIndex = this.$_.findIndex(res, r => r.user === m)
          if (existIndex !== -1) {
            res[existIndex][raci] = true
          } else {
            const memberOption = this.objectMembersIncludedGuests.find(option => option.value.toString() === m.toString())
            res.push({
              user: m,
              memberType: memberOption?.type,
              accountable: raci === 'accountable',
              responsible: raci === 'responsible',
              consulted: raci === 'consulted',
              informed: raci === 'informed'
            })
          }
        })
      })
      this.newProject.members = res
    },
    clearProjectTemplate () {
      this.fromTemplate = false
      this.projectTemplate = undefined
    },
    generateNewProjectName (scope, name) {
      switch (scope) {
        case 'Create':
          return `${this.$t('linkedToLO.creationPrefixLabel')} - ${name}`
        case 'Update':
          return `${this.$t('linkedToLO.updatePrefixLabel')} - ${name}`
        default:
          return name
      }
    }
  }
}
</script>
<style scoped>
.cardTitle {
  font-size: 0.9rem;
}
.parent {
  height: 62vh;
  overflow-y: auto;
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-template-rows: 1fr;
  grid-column-gap: 10px;
  padding: 0 20px 40px;
}
.stepper2parent {
  height: 62vh;
  overflow-y: auto;
  display: grid;
  grid-template-columns: 1fr 3fr;
  padding: 0 20px;
  grid-template-rows: 1fr;
}
.switchSide {
  grid-area: 1 / 1 / 4 / 2;
}
.switchSide-members {
  grid-area: 1 / 1 / 6 / 2;
}
.selectedBackground {
  background-color: rgba(0, 85, 138, 0.1);
  border: 0.5px solid rgb(0, 85, 138);
}
.selectObjectContentSide {
  grid-area: 1 / 2 / 2 / 3;
  overflow-y: auto;
  display: grid;
}
.selectObjectContent {
  grid-area: 1 / 1 / 6 / 3;
  overflow-y: auto;
  display: grid;
  grid-template-rows: 40px 40px 1fr;
}
.newObjectContainer {
  grid-area: 1 / 1 / 6 / 3;
  overflow-y: auto;
}
.detailsParent {
  grid-area: 1 / 1 / 2 / 5;
  overflow-y: auto;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: repeat(4, 1fr);
  grid-row-gap: 20px;
  padding: 2px 1px;
}
.noTitleDescriptionCard {
  grid-area: 1 / 1 / 4 / 5;
}
.titleDescriptionCard {
  grid-area: 1 / 1 / 4 / 5;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: 40px repeat(2, 1fr);
}
.titleContainer {
  grid-area: 1 / 1 / 2 / 5;
  align-self: center;
}
.descriptionContainer {
  grid-area: 2 / 1 / 4 / 5;
  overflow-y: auto;
}
.raciListAside {
  grid-area: 1 / 5 / 2 / 7;
  overflow-y: auto;
  padding: 2px 1px;
}
.accountableContainer {
  grid-area: 1 / 1 / 2 / 2;
}
.responsibleContainer {
  grid-area: 2 / 1 / 3 / 2;
}
.consultedContainer {
  grid-area: 3 / 1 / 4 / 2;
}
.informedContainer {
  grid-area: 4 / 1 / 5 / 2;
}
.startDateContainer {
  grid-area: 4 / 1 / 5 / 3;
}
.endDateContainer {
  grid-area: 4 / 3 / 5 / 5;
}
</style>
