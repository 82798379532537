<template>
  <v-dialog
    v-model="mutableModel"
    :persistent="persistent"
    scrollable
    :width="calculatedSize"
    :style="`z-index:9999; min-height: ${minHeight};${size === 'full'? 'max-height: calc(100vh - 40px)' : 'max-height: 90vh'}`"
    @input="$emit('close')"
  >
    <div class="edtk-dialog-bis">
      <header v-if="title">
        <h4 class="edtk-title-small">
          {{ title }}
        </h4>
        <edtkButtonIcon
          v-if="closable"
          class="ml-auto"
          icon="close"
          icon-size="4xl"
          color="secondary"
          @click="$emit('close')"
        />
      </header>
      <main>
        <div v-if="$slots['content']" class="content">
          <slot name="content"></slot>
        </div>
        <div v-if="$slots['fluid']" class="fluid">
          <slot name="fluid"></slot>
        </div>
      </main>
      <footer v-if="$slots['actions']">
        <slot name="actions"></slot>
      </footer>
    </div>
  </v-dialog>
</template>

<script>
export default {
  name: 'EdtkDialog',
  props: {
    value: {
      type: Boolean,
      default: false
    },
    size: {
      type: String,
      default: () => 'md',
      enums: ['sm', 'md', 'lg', 'xl', 'full']
    },
    title: {
      type: String,
      default: () => ''
    },
    minHeight: {
      type: String,
      default: () => 'auto'
    },
    closable: {
      type: Boolean,
      default: true
    },
    persistent: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      mutableModel: undefined
    }
  },
  computed: {
    calculatedSize () {
      switch (this.size) {
        case 'sm':
          return '360px'
        case 'md':
          return '480px'
        case 'lg':
          return '640px'
        case 'xl':
          return '960px'
        case 'full':
          return '1400px'
        default:
          return '480px'
      }
    }
  },
  watch: {
    value (n) {
      this.mutableModel = n
    }
  },
  mounted () {
    this.mutableModel = this.value
  },
  methods: {
    close () {
      this.$emit('input', false)
      this.$emit('close')
    }
  }
}
</script>
<style lang="scss" scoped>
.edtk-dialog-bis {
  border-radius: 8px;
  border: none;
  background: var(--neutral-100);
  overflow: visible;
  display: flex;
  flex-direction: column;
  align-items: center;

  > header {
    background: var(--neutral-100);
    display: flex;
    width: 100%;
    padding: 4px 8px;
    align-items: center;
    gap: 10px;
    align-self: stretch;
    border-bottom: 1px solid var(--neutral-90);

    h4 {
      padding: 8px;
      flex-grow: 1;
    }
  }

  > main {
    background: var(--neutral-100);
    width: 100%;
    overflow-y: auto;
    flex-grow: 1;

    .content {
      display: flex;
      width: 100%;
      padding: 12px 16px 20px 16px;
      flex-direction: column;
      align-items: flex-start;
      align-self: stretch;
      flex-grow: 1;
      overflow-x: hidden;
      overflow-y: auto;
    }

    .fluid {
      width: 100%;
      padding: 0;
      align-self: stretch;
    }
  }

  > footer {
    background: var(--neutral-100);
    display: flex;
    width: 100%;
    padding: 12px 16px;
    justify-content: flex-end;
    align-items: center;
    gap: 8px;
    border-top: 1px solid var(--neutral-90);
  }
}
</style>
