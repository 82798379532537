export default function ({ store, redirect, route, $enums, $nuxt }) {
  if (/^\/w/.test(route.path) && !route.params.workspace) {
    const current = store.getters['workspace/getCurrent']
    if (current && current._id) {
      return redirect(`/${$enums.routePrefix.workspace}/${current._id}`)
    } else {
      return redirect('/')
    }
  }
}
