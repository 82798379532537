export default ({ app }) => {
  app.router.onError((error) => {
    console.log('router.onError', error)
    const messages = [
      'Importing a module script failed', // safari
      'Failed to fetch dynamically imported module' // edge & chrome
    ]
    if (messages.some(message => error?.message.includes(message))) {
      (() => {
        // if experience is not good, add some delay so that user can have a message in notification.
        app.router.go(0)
      })()
    }
  })
}
