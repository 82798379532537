/* eslint-disable prefer-promise-reject-errors */
/* eslint-disable no-console */
/* eslint-disable camelcase */
import _ from 'lodash'

const templateSchema = {
  _id: String,
  name: String,
  picture: String,
  type: String,
  category: String,
  subCategory: String,
  isPublic: Boolean,
  description: String,
  tags: [String],
  workspace: String,
  content: Object
}

export const state = () => ({
  templates: [],
  lastUpdate: undefined
})

export const getters = {
  get (state) {
    return state.templates
  },
  lastUpdate (state) {
    return state.lastUpdate
  },
  getOne: state => (id) => {
    return _.find(state.templates, (template) => { return template._id === id })
  },
  getCategoryByFilterType: state => (type) => {
    let res = []
    res = _.reduce(state.templates, (acc, template) => {
      return template.type === type && template.category ? [...acc, template.category] : acc
    }, [])
    return _.uniq(res)
  }
}

export const actions = {
  fetch ({ commit }, { $queryParameters = {} } = { $queryParameters: {} }) {
    return new Promise((resolve, reject) => {
      this.$edtake.template.get({ $queryParameters })
        .then((d) => {
          commit('SET', d)
          resolve(d)
        })
        .catch((err) => {
          console.error('ERROR', err)
          reject('AN_ERROR_OCCURED')
        })
    })
  },
  fetchOne ({ commit }, { id }) {
    return new Promise((resolve, reject) => {
      this.$edtake.template.getOne({ id })
        .then((d) => {
          resolve(d)
          commit('UPDATE_ONE', d)
        })
        .catch((err) => {
          console.error('ERROR', err)
          reject('AN_ERROR_OCCURED')
        })
    })
  },
  update ({ commit }, { id, body }) {
    return new Promise((resolve, reject) => {
      this.$edtake.template.update({ id, body })
        .then((d) => {
          commit('UPDATE_ONE', d)
          resolve(d)
        })
        .catch((err) => {
          console.error('ERROR', err)
          reject('AN_ERROR_OCCURED')
        })
    })
  },
  remove ({ dispatch, commit }, id) {
    return new Promise((resolve, reject) => {
      this.$edtake.template.delete({ id })
        .then(() => {
          commit('REMOVE', id)
          resolve()
        })
        .catch((err) => {
          console.error('ERROR', err)
          reject('AN_ERROR_OCCURED')
        })
    })
  },
  /* Dispatch from other store */
  _update ({ commit }, d) {
    if (Array.isArray(d)) {
      _.forEach(d, e => commit('UPDATE_ONE', e))
    } else {
      commit('UPDATE_ONE', d)
    }
  }
}

export const mutations = {
  SET (state, value) {
    state.templates = value
    state.lastUpdate = Date.now()
  },
  UPDATE_ONE (state, value) {
    const item = state.templates.find(item => (item._id && item._id.toString()) === (value._id && value._id.toString()))
    if (item) {
      Object.assign(item, value)
      state.templates = [...state.templates] // Force refresh
    } else {
      state.templates = [...state.templates, value]
    }
  },
  UPDATE_MANY (state, arr) {
    const expectedIds = _.map(arr, o => o._id)
    const filteredState = _.filter(state.templates, o => !expectedIds.includes(o._id))
    state.templates = [...filteredState, ...arr] // Force refresh
  },
  REMOVE (state, value) {
    state.templates = state.templates.filter(template => template._id !== value)
  },
  CLEAR (state) {
    state.templates = []
    state.lastUpdate = Date.now()
  }
}
