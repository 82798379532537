const bubbleLeft = {
  name: 'bubbleLeft',
  fn: (ctx, shape) => {
    ctx.beginPath()
    ctx.moveTo(0, 0)
    ctx.lineTo(100, 0)
    ctx.lineTo(100, 100)
    ctx.lineTo(25, 100)
    ctx.lineTo(15, 120)
    ctx.lineTo(15, 100)
    ctx.lineTo(0, 100)
    ctx.closePath()

    // special Konva.js method
    ctx.fillStrokeShape(shape)
  },
  scaledPoints: (ctx, scaleX, scaleY, lineWidth) => {
    ctx.moveTo(lineWidth, lineWidth)
    ctx.lineTo(100 * scaleX, lineWidth)
    ctx.lineTo(100 * scaleX, 100 * scaleY)
    ctx.lineTo(25 * scaleX, 100 * scaleY)
    ctx.lineTo(15 * scaleX, 120 * scaleY)
    ctx.lineTo(15 * scaleX, 100 * scaleY)
    ctx.lineTo(lineWidth, 100 * scaleY)
    ctx.closePath()
  }
}

const bubbleRight = {
  name: 'bubbleRight',
  fn: (ctx, shape) => {
    ctx.beginPath()
    ctx.moveTo(0, 0)
    ctx.lineTo(100, 0)
    ctx.lineTo(100, 100)
    ctx.lineTo(85, 100)
    ctx.lineTo(85, 120)
    ctx.lineTo(75, 100)
    ctx.lineTo(0, 100)
    ctx.closePath()

    // special Konva.js method
    ctx.fillStrokeShape(shape)
  },
  scaledPoints: (ctx, scaleX, scaleY, lineWidth) => {
    ctx.moveTo(lineWidth, lineWidth)
    ctx.lineTo(100 * scaleX, lineWidth)
    ctx.lineTo(100 * scaleX, 100 * scaleY)
    ctx.lineTo(85 * scaleX, 100 * scaleY)
    ctx.lineTo(85 * scaleX, 120 * scaleY)
    ctx.lineTo(75 * scaleX, 100 * scaleY)
    ctx.lineTo(lineWidth, 100 * scaleY)
    ctx.closePath()
  }
}

export default (ctx, inject) => {
  inject('customShapes', [bubbleLeft, bubbleRight])
}
