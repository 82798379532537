const i18n = {
  messages: {
    fr: {
      emptyResult: 'Aucun résultat ne correspond à votre recherche'
    },
    en: {
      emptyResult: 'No results matching your search'
    }
  }
}
module.exports = i18n
