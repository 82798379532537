/* eslint-disable */
import defaults from './utils/defaults'
import enums from './utils/enums'
import countryMapping from './utils/countryMapping'
import alertHelper from './utils/alertHelper'
import progressHelper from './utils/progressHelper'
import userRightsHelper from './utils/userRightsHelper'
import learningObjectHelper from './utils/learningObjectHelper'
import kpiHelper from './utils/kpiHelper'
import dateHelper from './utils/dateHelper'
import stringHelper from './utils/stringHelper'
import rainbowHelper from './utils/rainbowHelper'
import taxonomyHelper, { classifyAll } from './utils/taxonomyHelper'
import projectItemHelper from './utils/projectItemHelper'
import tabsHelper from './utils/tabsHelper'

export default (ctx, inject) => {
  inject('rainbow', rainbowHelper)
  inject('defaults', defaults)
  inject('enums', enums)
  inject('countryMapping', countryMapping)
  inject('alertHelper', alertHelper(ctx))
  inject('kpiHelper', kpiHelper(ctx))
  inject('progressHelper', progressHelper(ctx))
  inject('userRightsHelper', userRightsHelper(ctx))
  inject('learningObjectHelper', learningObjectHelper(ctx))
  inject('dateHelper', dateHelper(ctx))
  inject('stringHelper', stringHelper(ctx))
  inject('taxonomyHelper', taxonomyHelper(ctx))
  inject('projectItemHelper', projectItemHelper(ctx))
  inject('tabsHelper', tabsHelper(ctx))
}