<template>
  <div>
    <div
      class="d-flex align-center justify-center"
      :class="{'no-avatar': !workspace.avatar}"
      :style="`width: ${size}px;height: ${size}px;border-radius: 4px`"
    >
      <img
        v-if="workspace.avatar"
        :width="size"
        :src="workspace.avatar"
        :alt="`${workspace.name}`"
        style="border-radius: 4px"
      >
      <span v-else class="edtk-label-small white--text text-uppercase">{{ workspace.name[0] + workspace.name[1] }}</span>
    </div>
  </div>
</template>
<script>
export default {
  name: 'AvatarWorkspace',
  props: {
    workspace: {
      type: Object,
      required: true
    },
    size: {
      type: Number,
      default: () => { return 30 }
    }
  }
}
</script>
<style lang="scss" scoped>
.no-avatar {
  background-color: var(--primary-40);
}
</style>
