import dayjs from 'dayjs'
import dayjsBusinessDays from 'dayjs-business-days'
import 'dayjs/locale/fr'
import AdvancedFormat from 'dayjs/plugin/advancedFormat'
import CustomParseFormat from 'dayjs/plugin/customParseFormat'
import isoWeek from 'dayjs/plugin/isoWeek'
import weekDay from 'dayjs/plugin/weekday'
import relativeTime from 'dayjs/plugin/relativeTime'
import isBetween from 'dayjs/plugin/isBetween'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'
import dayOfYear from 'dayjs/plugin/dayOfYear'
import duration from 'dayjs/plugin/duration'
import localizedFormat from 'dayjs/plugin/localizedFormat'

dayjs.extend(dayOfYear)
dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.locale('fr')
dayjs.extend(isBetween)
dayjs.extend(AdvancedFormat)
dayjs.extend(dayjsBusinessDays)
dayjs.extend(CustomParseFormat)
dayjs.extend(isoWeek)
dayjs.extend(weekDay)
dayjs.extend(relativeTime)
dayjs.extend(duration)
dayjs.extend(localizedFormat)

export default ({ app }, inject) => {
  inject('dayjs', dayjs)
}
