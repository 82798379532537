<template>
  <v-container class="py-3 fill-height bg-white">
    <v-row class="w-100 mb-5" align="center" no-gutters>
      <v-col cols="12">
        <h1 class="text-center">
          <img src="/img/logo-edtake.svg" alt="edtake" width="250px" />
        </h1>
      </v-col>
    </v-row>
    <v-row class="text-center mt-5" align="center" no-gutters>
      <v-col cols="12">
        <h2 class="blue--text" v-html="$t('maintenance')"></h2>
        <img src="/img/programming.png" alt="edtake" width="100%" />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: 'MaintenanceLayout',
  auth: false,
  i18n: {
    messages: {
      fr: {
        maintenance: 'Nous sommes actuellement en maintenance afin de vous proposer la meilleure expérience possible. <br /><br />Veuillez réessayer ultérieurement.'
      },
      en: {
        maintenance: 'We are currently under maintenance in order to offer you the best possible experience.. <br /><br />Please try again later.'
      }
    }
  }
}
</script>
