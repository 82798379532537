<template lang="html">
  <v-app>
    <v-main data-layout="iframe-workspace">
      <FormErrorDialog />
      <transition
        name="nuxtTransition"
        appear
        appear-active-class="component-enter-active"
        leave-active-class="component-leave-active"
      >
        <nuxt />
      </transition>
      <Snackbar />
      <ApplicationUpdate />
    </v-main>
  </v-app>
</template>

<script lang="js">
import ApplicationUpdate from '~/components/applicationUpdate'

export default {
  name: 'IframeLayout',
  components: {
    ApplicationUpdate
  },
  data () {
    return {}
  }
}
</script>
