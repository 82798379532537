const i18n = {
  messages: {
    fr: {
      newBlock: 'Créer une nouvelle ressource',
      submit: 'Créer',
      activityType: 'Type d\'activité',
      typeOf: 'Type de {name}',
      newLearningObject: {
        type: 'Ajouter un type',
        name: 'Nom',
        description: 'Ajouter une description...',
        language: 'Ajouter une langue'
      },
      all: 'Tous',
      course: {
        label: 'Parcours',
        description: "Itinéraire organisé d'acquisition de connaissances ou de compétences dans un domaine donné. Le parcours est constitué de modules.",
        placeholder: 'Nom du parcours'
      },
      module: {
        label: 'Module',
        description: "Unité autonome d'une action de formation qui répond à un ensemble d'objectifs pédagogiques.",
        placeholder: 'Nom du module'
      },
      sequence: {
        label: 'Séquence',
        description: "Ensemble de connaissances ou de savoir-faire structuré par le formateur, en vue de contribuer à l'atteinte d'un objectif pédagogique.",
        placeholder: 'Nom de la séquence'
      },
      mechanism: {
        label: 'Dispositif',
        description: "Système construit pour répondre à une situation de formation, en vue d'être utilisé d'une manière générique.",
        placeholder: 'Nom du dispositif'
      }
    },
    en: {
      newBlock: 'Add new resource',
      submit: 'Create',
      activityType: 'Activity type',
      typeOf: 'Type of ',
      newLearningObject: {
        type: 'Add a type',
        name: 'Name',
        description: 'Add a description...',
        language: 'Add a language'
      },
      all: 'All',
      course: {
        label: 'Course',
        description: 'Organized itinerary for acquiring knowledge or skills in a given field. The pathway is made up of modules.',
        placeholder: 'Course name'
      },
      module: {
        label: 'Module',
        description: 'A training module corresponds to an autonomous unit of a training action, i.e. one that meets a set of instructional objectives that must be addressed together.',
        placeholder: 'Module name'
      },
      sequence: {
        label: 'Sequence',
        description: 'The instructional sequence is a set of knowledge or know-how structured by the trainer, in order to contribute to the achievement of a instructional objective.',
        placeholder: 'Sequence name'
      },
      mechanism: {
        label: 'Mechanism',
        description: 'A device is a system that is built to respond to a training situation, in order to be used in a generic way.',
        placeholder: 'Mechanism name'
      }
    }
  }
}
module.exports = i18n
