<template>
  <v-popover
    :disabled="!editRight"
    trigger="manual"
    :open="isOpen"
    :placement="position"
    :container="containerData"
    @hide="close"
  >
    <section
      v-if="editRight"
      class="tooltip-target pointer"
      :data-test="dataTest"
      @click="isOpen =! isOpen"
    >
      <slot v-if="!isOpen" name="default" :component-data="value">
        <div class="hover-highlight">
          <span v-if="!mutableModel || mutableModel.length === 0">{{ placeholder }}</span>
          <v-chip-group
            v-else
            active-class="primary--text"
            column
          >
            <v-chip
              v-for="tag in mutableModel"
              :key="tag"
              close
              :small="!($attrs['large-chip'] || $attrs['large-chip'] === '')"
              outlined
              color="primary"
              :class="{'font-weight-regular': true}"
              @click:close="removeTag(tag)"
            >
              {{ optionType === 'object' && $_.find(options, o => o.id === tag) ? $_.find(options, o => o.id === tag).label : tag }}
            </v-chip>
          </v-chip-group>
        </div>
        <span
          v-if="$v.mutableModel.$error"
          class="form_error"
        >
          {{ $t('errors.input.INVALID') }} - {{ attrsInformation }}
        </span>
      </slot>
      <div v-else>
        <div class="hover-highlight">
          <span v-if="!mutableModel || mutableModel.length === 0">{{ placeholder }}</span>
          <v-chip-group
            v-else
            active-class="primary--text"
            column
          >
            <v-chip
              v-for="tag in mutableModel"
              :key="tag"
              close
              :small="!($attrs['large-chip'] || $attrs['large-chip'] === '')"
              outlined
              color="primary"
              :class="{'font-weight-regular': true}"
              @click:close="removeTag(tag)"
            >
              {{ optionType === 'object' && $_.find(options, o => o.id === tag) ? $_.find(options, o => o.id === tag).label : tag }}
            </v-chip>
          </v-chip-group>
        </div>
      </div>
    </section>
    <slot v-else name="default" :component-data="value">
      <v-chip
        v-for="(t, $index) in mutableModel"
        :key="$index"
        label
        outlined
        color="primary"
        class="mb-2 mr-2"
      >
        {{ optionType === 'object' && $_.find(options, o => o.id === t) ? $_.find(options, o => o.id === t).label : t }}
      </v-chip>
    </slot>

    <template slot="popover">
      <div class="v-application">
        <section v-if="!allowCustom && !options.length" class="popover-section">
          <div class="popover-default-message brownish-grey--text text-center py-2 mx-2">
            {{ $t('noOptions') }}
          </div>
        </section>
        <section v-else class="popover-section">
          <div class="popover-header">
            <h5 v-if="title" class="blue--text text-center py-2 mx-2">
              {{ title }}
            </h5>
            <div>
              <span>
                <v-icon small>mdi-magnify</v-icon>
              </span>
              <input
                ref="searchInputBox"
                v-model="search"
                class="search-input"
                :placeholder="$t(placeholderSentence)"
                @keypress="fireEventOnEnterKey"
              />
            </div>
          </div>
          <v-divider></v-divider>
          <v-list class="popover-list">
            <v-list-item v-if="allowCustom && search !== ''" class="px-2">
              <v-chip
                :small="!($attrs['large-chip'] || $attrs['large-chip'] === '')"
                outlined
                color="primary"
                class="font-weight-regular"
                @click="addCustom"
              >
                {{ $t('add') }} "{{ search }}"
              </v-chip>
            </v-list-item>
            <div v-for="(category, i) in categories" :key="i">
              <v-subheader v-if="category !== 'primary'">
                {{ category }}
              </v-subheader>
              <v-list-item
                v-for="(item, j) in filteredOptions.filter(o => o.category === category)"
                :key="j"
                :disabled="item.disabled"
                @click="selectValue(item.id)"
              >
                <v-list-item-content>
                  <v-list-item-title>
                    <span :class="[item.action ? 'blue--text' : '']">
                      <v-icon v-if="item.action" class="mr-2" color="primary" small>
                        mdi-plus
                      </v-icon>{{ item.label }}
                    </span>
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-divider v-if="i === categories.length && filteredOptions.filter(o => o.category === category).length > 0"></v-divider>
            </div>
          </v-list>
        </section>
      </div>
    </template>
  </v-popover>
</template>

<script>
import { required } from 'vuelidate/lib/validators'
import i18n from './i18n.js'

export default {
  name: 'EditableTags',
  props: {
    'data-test': {
      type: String
    },
    containerRef: {
      type: HTMLElement
    },
    parentContainer: {
      type: Boolean,
      default: () => false
    },
    editRight: {
      type: Boolean,
      required: true,
      default: false
    },
    allowCustom: {
      type: Boolean,
      default: false
    },
    options: {
      type: Array,
      default: () => []
    },
    value: {
      type: Array,
      default: () => []
    },
    position: {
      type: [String, undefined],
      default: () => 'auto'
    },
    placeholder: {
      type: String,
      default: () => ''
    },
    title: {
      type: String,
      default: () => ''
    },
    customValidation: {
      type: Object,
      default: () => {}
    }
  },
  validations () {
    return {
      mutableModel: {
        // Adding all custom validations to the mutableModel
        ...this.customValidation ? Object.keys(this.customValidation.controls).map((key) => { return this.customValidation.controls[key] }) : {},
        required: this.$attrs.required || this.$attrs.required === '' ? required : {}
      }
    }
  },
  data () {
    return {
      search: '',
      mutableModel: this.value,
      isOpen: false,
      res: undefined,
      containerData: undefined,
      placeholderSentence: this.allowCustom ? this.options.length > 0 ? 'searchOrAdd' : 'add' : 'search'
    }
  },
  i18n,
  computed: {
    attrsInformation () {
      const errorMessage = []
      if ((this.$attrs.required || this.$attrs.required === '') && !this.$v.mutableModel.required) {
        errorMessage.push(`${this.$t('required')}`)
      }
      Object.keys(this.customValidation ? this.customValidation.controls : {}).forEach((key) => {
        if (!this.$v.mutableModel[key]) {
          errorMessage.push(this.customValidation.errors[key])
        }
      })

      return errorMessage.join(' - ')
    },
    optionType () {
      return this.options.length > 0 ? typeof this.options[0] : undefined
    },
    filteredOptions () {
      const self = this
      const searchText = this.search ? this.$_.deburr(this.search.toUpperCase()) : ''
      const filteredValue = this.$_.filter(this.options, x => !this.mutableModel.includes(this.optionType === 'object' ? x.id : x))
      const r = this.$_.map(filteredValue, (o) => {
        if (typeof o === 'string') {
          return { id: o, label: o, search: self.$_.deburr(o.toUpperCase()), category: 'primary' }
        } else {
          return { id: o.id, label: o.label || '', search: o.label ? self.$_.deburr(o.label.toUpperCase()) : '', category: o.category || 'primary' }
        }
      })
      return searchText === '' ? r : this.$_.filter(r, o => o.search.includes(searchText))
    },
    categories () {
      const categories = this.$_.reduce(this.filteredOptions, (acc, o) => {
        if (!this.$_.find(acc, category => o.category === category)) {
          return [...acc, o.category]
        }
        return acc
      }, ['primary'])
      return categories
    }
  },
  watch: {
    value (n) {
      this.mutableModel = n
    },
    containerRef (n) {
      this.containerData = n
    },
    isOpen (n) {
      if (n === true) {
        this.search = ''
        setTimeout(function () {
          this.focus()
        }.bind(this), 150)
      }
    }
  },
  mounted () {
    if (this.parentContainer) {
      this.containerData = this.$parent.$el
    }
  },
  methods: {
    removeTag (tag) {
      this.mutableModel = this.$_.filter(this.mutableModel, x => (x !== tag))
      this.$v.mutableModel.$touch()
      if (!this.$v.mutableModel.$error) { this.$emit('input', this.mutableModel) }
    },
    addCustom () {
      if (!this.allowCustom) { return }
      this.$v.mutableModel.$touch()
      if (!this.$v.mutableModel.$error) {
        this.$emit('input', this.$_.concat(this.mutableModel, this.search))
      }
      this.search = ''
    },
    fireEventOnEnterKey (e) {
      if (e.key === 'Enter' || e.keyCode === 13) {
        this.addCustom()
      }
    },
    selectValue (e) {
      this.$v.mutableModel.$touch()
      if (!this.$v.mutableModel.$error) { this.$emit('input', this.$_.concat(this.mutableModel, e)) }
    },
    close () {
      if (this.isOpen) {
        this.isOpen = false
        this.search = ''
      }
    },
    focus () {
      if (this.options.length) {
        this.$nextTick(() => this.$refs.searchInputBox.focus())
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.hover-highlight {
  min-width: 100px;
  display: block;
  border-bottom: dashed transparent 2px;
  &:hover {
    cursor: text;
    border-bottom: dashed $blue 2px;
  }
}
.search-input {
  border: none;
}
.popover-section {
  margin: -5px;
}
.popover-list {
  max-height: 300px;
  overflow: auto;
}
.popover-header {
  max-height: 100px;
  padding: 0 12px;
}
.popover-default-message {
  max-width: 200px;
  padding: 0 12px;
}
</style>
