<template>
  <section v-if="subscription && subscription.trialDaysRemaining > 0">
    <div class="d-flex justify-center align-center white--text band">
      <span>
        {{ $t('trial', {days: subscription.trialDaysRemaining}) }}
      </span>
      <edtkButton
        :label="$t('accessBilling')"
        type="filled"
        small
        class="band-button"
        @click="routeToBilling"
      />
    </div>
  </section>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
import i18n from './i18n'
export default {
  i18n,
  props: {
    workspace: {
      type: Object,
      required: true
    }
  },
  computed: {
    ...mapGetters({
      subscription: 'stripe/getSubscription'
    })
  },
  async mounted () {
    await this.fetchSubscription()
  },
  methods: {
    ...mapActions({
      fetchSubscription: 'stripe/fetchSubscription'
    }),
    routeToBilling () {
      this.$router.push(`/w/${this.workspace._id}/configuration?config-tab=billing`)
    }
  }
}
</script>
<style scoped>
.no-uppercase {
  text-transform: unset !important;
}

.band {
  width: 100%;
  height: 29px;
  position: relative;
  font-size: 15px;
  background-color: var(--primary);
}

.band-button {
  right: 0;
  position: absolute;
}

</style>
