export const state = () => ({
  gantt: {},
  workspace: undefined
})

export const getters = {
  get: state => (settings) => {
    return settings ? state[settings] : undefined
  }
}

export const actions = {
  set ({ commit }, { settings, value }) {
    commit('SET', { settings, value })
  }
}

export const mutations = {
  SET (state, { settings, value }) {
    state[settings] = value
  }
}
