import dayjs from 'dayjs'
import _ from 'lodash'

const alertLevels = {
  deliverable: {
    noRealisationAssignee: [
      {
        delay: 14,
        level: 'info',
        raci: ['accountable']
      },
      {
        delay: 7,
        level: 'warning',
        raci: ['accountable']
      },
      {
        delay: 3,
        level: 'error',
        raci: ['accountable']
      }
    ],
    noDescription: [
      {
        delay: 7,
        level: 'info',
        raci: ['accountable', 'responsible']
      },
      {
        delay: 3,
        level: 'warning',
        raci: ['accountable', 'responsible']
      },
      {
        delay: 1,
        level: 'error',
        raci: ['accountable', 'responsible']
      }
    ],
    milestone: [
      {
        delay: 7,
        level: 'info',
        raci: ['accountable', 'responsible']
      },
      {
        delay: 3,
        level: 'warning',
        raci: ['accountable', 'responsible']
      },
      {
        delay: 1,
        level: 'error',
        raci: ['accountable', 'responsible']
      }
    ]
  },
  module: {
    noRealisationAssignee: [
      {
        delay: 14,
        level: 'info',
        raci: ['accountable']
      },
      {
        delay: 7,
        level: 'warning',
        raci: ['accountable']
      },
      {
        delay: 3,
        level: 'error',
        raci: ['accountable']
      }
    ],
    noDescription: [
      {
        delay: 7,
        level: 'info',
        raci: ['accountable', 'responsible']
      },
      {
        delay: 3,
        level: 'warning',
        raci: ['accountable', 'responsible']
      },
      {
        delay: 1,
        level: 'error',
        raci: ['accountable', 'responsible']
      }
    ],
    milestone: [
      {
        delay: 7,
        level: 'info',
        raci: ['accountable', 'responsible']
      },
      {
        delay: 3,
        level: 'warning',
        raci: ['accountable', 'responsible']
      },
      {
        delay: 1,
        level: 'error',
        raci: ['accountable', 'responsible']
      }
    ]
  },
  course: {
    noRealisationAssignee: [
      {
        delay: 14,
        level: 'info',
        raci: ['accountable']
      },
      {
        delay: 7,
        level: 'warning',
        raci: ['accountable']
      },
      {
        delay: 3,
        level: 'error',
        raci: ['accountable']
      }
    ],
    noDescription: [
      {
        delay: 7,
        level: 'info',
        raci: ['accountable', 'responsible']
      },
      {
        delay: 3,
        level: 'warning',
        raci: ['accountable', 'responsible']
      },
      {
        delay: 1,
        level: 'error',
        raci: ['accountable', 'responsible']
      }
    ],
    milestone: [
      {
        delay: 7,
        level: 'info',
        raci: ['accountable', 'responsible']
      },
      {
        delay: 3,
        level: 'warning',
        raci: ['accountable', 'responsible']
      },
      {
        delay: 1,
        level: 'error',
        raci: ['accountable', 'responsible']
      }
    ]
  },
  task: {}
}

const alertToLevel = function (alert) {
  return alertLevels[alert]
}

const projectItemAlerts = function (projectItem, user) {
  const type = projectItem.__t
  const alerts = []
  const roles = _.find(projectItem.members, (m) => { return m.user && m.user._id === user })

  _.forEach(Object.keys(alertLevels[type]), (k) => {
    const c = controls[k](projectItem, alertLevels[type][k], roles, user)
    if (c) {
      alerts.push({
        errorType: k,
        objectType: type,
        objectName: projectItem.name,
        objectId: projectItem._id,
        error: c
      })
    }
  })

  return alerts
}

const controls = {
  noRealisationAssignee: (o, conf, roles) => {
    const check = _.find(o.members, m => m.responsible)

    if (check) {
      return undefined
    }

    const daysToStartDate = diffDateOnConf(dayjs(o.startDate), conf, roles)

    return daysToStartDate || undefined
  },
  noDescription: (o, conf, roles) => {
    const check = o.description !== undefined && o.description && o.description.length > 0

    if (check) { return undefined }

    const daysToStartDate = diffDateOnConf(dayjs(o.startDate), conf, roles)

    return daysToStartDate || undefined
  },
  milestone: (o) => {
    return undefined
  }
}

function diffDateOnConf (refDate, conf, roles) {
  const arrDelay = _.uniq(_.map(conf, c => c.delay))
  let r
  _.forEach(arrDelay.sort(function (a, b) { return a - b }), (d) => {
    if (refDate.diff(dayjs(), 'day') < d) {
      const ctrl = _.find(conf, c => c.delay === d)
      let checkUser = false
      _.forEach(ctrl.raci, (role) => {
        checkUser = checkUser || (roles && roles[role] === true)
      })

      if (checkUser) {
        r = ctrl
        return false
      }
    }
  })
  return r
}

export default ctx => ({
  projectItemAlerts,
  alertLevels,
  alertToLevel
})
