<template>
  <div class="px-5">
    <h4 class="page-subtitle py-0" style="align-self: center;">
      {{ $t('createNewLO.question') }}
    </h4>
    <v-row>
      <v-col
        v-for="t in tOptionsDesc"
        :key="t.id"
        cols="12"
        md="3"
        class="px-0 pb-10 pt-5"
      >
        <v-card
          height="100%"
          :class="[newLearningObject.__t === t.id ? 'selectedBackground' : '', 'my-0']"
          @click="updateNewLearningObject({key: '__t', value: t.id})"
        >
          <v-card-title class="justify-space-between align-center">
            <div class="d-flex align-center">
              <v-icon size="16" color="primary" class="mr-4">
                {{ $enums.mdi[t.id] }}
              </v-icon>
              <h4 class="primary--text font-weight-medium">
                {{ $t(`${t.id}.label`) }}
              </h4>
            </div>
            <v-icon v-show="newLearningObject.__t === t.id" size="21" color="primary">
              mdi-check
            </v-icon>
          </v-card-title>
          <v-card-subtitle class="brownish-grey--text mt-0">
            {{ $t(`${t.id}.description`) }}
          </v-card-subtitle>
        </v-card>
      </v-col>
    </v-row>

    <section v-if="newLearningObject.__t">
      <h4
        class="page-subtitle py-0"
        style="align-self: center;"
      >
        {{ $t('createNewLO.informations') }}
      </h4>
      <v-row>
        <v-col cols="12" md="9" class="pa-0">
          <v-card class="pa-4 mr-4">
            <edtk-text
              :value="newLearningObject.name"
              :label="$t(`${newLearningObject.__t}.placeholder`)"
              :placeholder="$t(`${newLearningObject.__t}.placeholder`)"
              :editable="true"
              @input="(e) => updateNewLearningObject({key: 'name', value: e})"
            />
            <div
              id="description"
              :style="
                newLearningObject.__t === 'module' || newLearningObject.__t === 'sequence'
                  ? 'min-height: 240px;'
                  : 'min-height: 150px;'"
            >
              <edtk-rich-text-editor
                :value="newLearningObject.description"
                :label="$t('newLearningObject.description')"
                :placeholder="$t('newLearningObject.description')"
                full-height
                :editable="true"
                @input="(e) => updateNewLearningObject({key: 'description', value: e})"
              ></edtk-rich-text-editor>
            </div>
          </v-card>
        </v-col>
        <v-col cols="12" md="3" class="pa-0">
          <v-card class="py-5 px-6 ml-0">
            <edtk-select
              :value="newLearningObject.language"
              :options="workspaceLanguages.map((e) => ({ value: e, label: $t(`languages.${e}`) }))"
              :label="$t('buttons.LANG')"
              :placeholder="$t('newLearningObject.language')"
              class="w-100"
              :editable="true"
              @input="(e) => updateNewLearningObject({key: 'language', value: e})"
            />
          </v-card>
          <v-card v-if="newLearningObject.__t === 'module' || newLearningObject.__t === 'sequence'" class="py-5 px-6 ml-0">
            <edtk-select
              :value="newLearningObject.type"
              :options="typeOptions"
              :label="$t('typeOf', { name: $t(`${newLearningObject.__t}.label`).toLowerCase() })"
              :placeholder="$t('newLearningObject.type')"
              :editable="true"
              @input="(e) => updateNewLearningObject({key: 'type', value: e})"
            />
          </v-card>
          <v-card v-if="newLearningObject.__t === 'module' || newLearningObject.__t === 'sequence'" class="py-5 px-6 ml-0">
            <edtk-select
              :value="newLearningObject.activityType"
              :options="translatedActivityTypeOptions"
              :label="$t('activityType')"
              :placeholder="$t('newLearningObject.type')"
              :editable="true"
              @input="(e) => updateNewLearningObject({key: 'activityType', value: e})"
            />
          </v-card>
        </v-col>
      </v-row>
    </section>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import i18n from './i18n'

export default {
  props: {
    newLearningObject: {
      type: Object
    }
  },
  data () {
    return {}
  },
  i18n,
  computed: {
    ...mapGetters({
      currentModuleTypes: 'settings/getSettingsModuleType',
      currentSequenceTypes: 'settings/getSettingsSequenceType',
      features: 'workspace/isFeatureActivated',
      workspaceLanguages: 'workspace/getSettingsLanguages'
    }),
    courseManagement () {
      return this.$userRightsHelper.hasFeatureAccess('course')
    },
    mechanismManagement () {
      return this.$userRightsHelper.hasFeatureAccess('mechanism')
    },
    tOptionsDesc () {
      let o = []
      if (this.mechanismManagement) {
        o.push({ id: 'mechanism', label: this.$t('mechanism.label'), description: this.$t('mechanism.description') })
      }
      if (this.courseManagement) {
        o.push({ id: 'course', label: this.$t('course.label'), description: this.$t('course.description') })
      }
      o = [
        ...o,
        { id: 'module', label: this.$t('module.label'), description: this.$t('module.description') },
        { id: 'sequence', label: this.$t('sequence.label'), description: this.$t('sequence.description') }
      ]
      return o
    },
    typeOptions () {
      let res = []
      if (this.newLearningObject.__t === 'module') {
        res = this.$_.map(this.currentModuleTypes, (e) => {
          return { value: e, label: this.$nuxt.$te(`course.module.type.${e}`) ? this.$t(`course.module.type.${e}`) : e }
        })
      } else if (this.newLearningObject.__t === 'sequence') {
        res = this.$_.map(this.currentSequenceTypes, (e) => {
          return { value: e, label: this.$nuxt.$te(`course.module.type.${e}`) ? this.$t(`course.module.type.${e}`) : e }
        })
      }
      return this.$_.sortBy(res, [r => r.label.normalize('NFD').replace(/[\u0300-\u036F]/g, '').toLowerCase()])
    },
    translatedActivityTypeOptions () {
      let res = []
      if (this.newLearningObject.__t === 'module' || this.newLearningObject.__t === 'sequence') {
        res = this.$_.map(this.$enums.learningObject[this.newLearningObject.__t].activityTypes, (e) => {
          return { value: e, label: this.$t(`activityTypes.${e}`) }
        })
      }
      return this.$_.sortBy(res, [r => r.label.normalize('NFD').replace(/[\u0300-\u036F]/g, '').toLowerCase()])
    }
  },
  methods: {
    updateNewLearningObject (payload) {
      this.$emit('updateNewLearningObject', payload)
    }
  }
}
</script>

<style scoped>
.selectedBackground {
  background-color: rgba(0, 85, 138, 0.1);
}
</style>
